import { Loader } from '@components'
import { ReactNode } from 'react'
import tw from 'tailwind-styled-components'

type DetailsContainerProps = {
  children: ReactNode
  className?: string
  loading?: boolean
}

export const DetailsContainer = ({
  children,
  className = '',
  loading = false,
}: DetailsContainerProps) => {
  const content = loading ? <Loader /> : children
  return <Container className={className}>{content}</Container>
}

const Container = tw.div`
  w-full
  flex
  relative
  h-[calc(100%-242px)]
`
