import { Button, Tabs } from '@components'
import { zodResolver } from '@hookform/resolvers/zod'
import { find, isEqual } from 'lodash-es'
import React, { useEffect, useState } from 'react'
import { useFieldArray, useForm, useWatch } from 'react-hook-form'

import type { Item, ItemFormsProps, ItemsFormProps, StopItem } from '../types'
import { itemsSchema } from '../types'
import { AvailableItem, AvailableItems } from './AvailableItems'
import { InputRow } from './InputRow'
import { ItemDropOffForm } from './ItemDropOffForm'
import { ItemForm } from './ItemForm'
import { ReviewBanner } from './ReviewBanner'
import { useStopLabels } from './ReviewView'
import { Section } from './Section'

export const ItemsForm = ({
  control,
  setValue,
  routeControl,
  getItemDeleter,
  getItemAdder,
}: ItemsFormProps) => {
  const stops = useWatch({
    control: routeControl,
    name: 'stops',
  })

  const itemsStops = useWatch({
    control,
    name: 'stops',
  })

  useEffect(() => {
    if (stops) {
      setValue('stops', stops)
    }
  }, [])

  const [activeTab, setActiveTab] = useState(0)
  const stopLabels = useStopLabels(stops)
  const [tabs] = useState(stopLabels)
  const currentStop = (itemsStops ?? [])[activeTab]
  const availableItems = useAvailableItems({ itemsStops })

  return (
    <div className='flex flex-col gap-6'>
      <Tabs {...{ activeTab, setActiveTab, tabs }} />
      <ReviewBanner />
      <div className='flex gap-4 justify-between'>
        <Section
          badgeCount={currentStop?.items?.length}
          bodyClassName='flex flex-col gap-4'
          className='w-full'
          title={'Load Items'}
        >
          <ItemForms {...{ control, activeTab, getItemDeleter, setItemsValue: setValue }} />
        </Section>
        <AvailableItems className='mt-[34px]' items={availableItems} />
      </div>
      <InputRow className='items-center justify-start'>
        <Button sm preIcon='plus-circle' type='secondary' onClick={getItemAdder(currentStop)}>
          Add New Item
        </Button>
      </InputRow>
    </div>
  )
}

const ItemForms = ({ control, activeTab, getItemDeleter, setItemsValue }: ItemFormsProps) => {
  const itemsStops = useWatch({
    control: control,
    name: 'stops',
  })

  return itemsStops.map((stop: StopItem, stopIdx: number) =>
    stopIdx !== activeTab ? (
      <React.Fragment key={stop.id} />
    ) : stop.stopType === 1 ? (
      stop?.items?.map((item: Item, itemIdx: number) => (
        <ItemForm
          key={item.id}
          {...{
            control,
            stopIdx,
            item,
            itemTotal: stop.items.length,
            itemIdx,
            getItemDeleter,
          }}
        />
      ))
    ) : (
      stop?.items?.map((item: Item, itemIdx: number) => (
        <ItemDropOffForm
          key={item.id}
          {...{
            control,
            stop,
            stopIdx,
            item,
            itemCount: stop.items.length,
            itemIdx,
            getItemDeleter,
            setItemsValue: setItemsValue,
          }}
        />
      ))
    ),
  )
}

export const useItemsForm = () => {
  const {
    formState: { isValid: itemsFormIsValid },
    control: itemsControl,
    setValue: setItemsValue,
  } = useForm({
    mode: 'all',
    resolver: zodResolver(itemsSchema),
  })

  const { replace: replaceItemStops } = useFieldArray({
    control: itemsControl,
    name: 'stops',
  })

  const itemsStops: StopItem[] = useWatch({
    control: itemsControl,
    name: 'stops',
  })

  const [allItemsDropped, setAllItemsDropped] = useState(false)

  useEffect(() => {
    const { pickUpItemCounts, dropOffItemCounts } = getItemCounts({ itemsStops })
    setAllItemsDropped(isEqual(pickUpItemCounts, dropOffItemCounts))
  }, [itemsStops])

  const getItemDeleter =
    (item: Item, isDropOff = false) =>
    () => {
      const stops = itemsStops

      const removeItemFromStop = (stop: StopItem) => ({
        ...stop,
        items: stop.items.filter((anItem: Item) => anItem.itemMappingKey !== item.itemMappingKey),
      })

      const updateStop = !isDropOff
        ? removeItemFromStop
        : (stop: StopItem) => (stop.stopType === 1 ? stop : removeItemFromStop(stop))

      const replacementStops = [...stops].map(updateStop)

      replaceItemStops(replacementStops)
    }

  const getItemAdder = (stop: StopItem) => () => {
    const stops = itemsStops
    const stopIndex = stops.findIndex(s => s.id === stop.id)
    const stopType = stopIndex === 0 ? 1 : stopIndex === stops.length - 1 ? 2 : 3
    const newItem = {
      id: Math.ceil(Math.random() * 1000000000),
      name: '',
      weight: '',
      quantity: 0,
      length: '',
      width: '',
      height: '',
      notes: '',
      stopType,
      itemMappingKey: Math.random().toString(36).substr(2, 6),
    }

    const replacementItems = [...(stop?.items ?? []), newItem]
    const replacementStop = { ...stop, items: replacementItems }
    const replacementStops = [...stops]
    replacementStops[stopIndex] = replacementStop
    replaceItemStops(replacementStops)
  }

  return {
    itemsFormIsValid,
    itemsControl,
    setItemsValue,
    itemsStops,
    getItemDeleter,
    getItemAdder,
    allItemsDropped,
  }
}

export const getItemCounts = ({ itemsStops }: { itemsStops: StopItem[] }) => {
  const [pickUpItems, dropOffItems]: Item[][] = getItemsFromStops(itemsStops ?? [])

  const getItemCounts = (items: Item[]) =>
    items.reduce(
      (counts: Record<string, number>, item: Item) =>
        counts[item.itemMappingKey]
          ? { ...counts, [item.itemMappingKey]: counts[item.itemMappingKey] + item.quantity }
          : { ...counts, [item.itemMappingKey]: item.quantity },
      {},
    )

  const pickUpItemCounts = getItemCounts(pickUpItems)
  const dropOffItemCounts = getItemCounts(dropOffItems)
  const quantityRemainingCounts = Object.entries(pickUpItemCounts).reduce(
    (counts: Record<string, number>, [itemMappingKey, count]: [string, number]) => ({
      ...counts,
      [itemMappingKey]: count - (dropOffItemCounts[itemMappingKey] ?? 0),
    }),
    {},
  )

  return {
    pickUpItemCounts,
    dropOffItemCounts,
    quantityRemainingCounts,
  }
}

const useAvailableItems = ({ itemsStops }: { itemsStops: StopItem[] }) => {
  const [availableItems, setAvailableItems] = useState<AvailableItem[]>([])

  useEffect(() => {
    const { quantityRemainingCounts } = getItemCounts({ itemsStops })
    const [pickUpItems]: Item[][] = getItemsFromStops(itemsStops ?? [])

    const items = Object.entries(quantityRemainingCounts)
      .map(([itemMappingKey, remaining]: [string, number]) => ({
        id: itemMappingKey,
        name: find(pickUpItems, { itemMappingKey })?.manifest ?? '',
        remaining,
      }))
      .filter(({ name }) => name)

    setAvailableItems(items)
  }, [itemsStops])

  return availableItems
}

const getItemsFromStops = (itemsStops: StopItem[]) =>
  itemsStops.reduce(
    ([pickUpItems, dropOffItems]: Item[][], stop: StopItem) =>
      stop.stopType === 1
        ? [pickUpItems.concat(stop.items), dropOffItems]
        : [pickUpItems, dropOffItems.concat(stop.items)],
    [[], []],
  )
