import { Select, SelectDropdownProps } from '@components'
import { debounce } from 'lodash-es'
import { useCallback } from 'react'

import { useAppSelector, useAppThunkDispatch } from '../../app/hooks'
import { autoCompleteAgentSearch } from '../../redux/autocompleteSlice'

export const AgentSelect = ({
  value = null,
  onChange,
  withLabel,
  withPlaceholder,
  className = '',
  required,
  label,
  ...props
}: {
  value?: any
  onChange: (value: any) => void
  withLabel?: boolean
  withPlaceholder?: boolean
  className?: string
  required?: boolean
  label?: string
} & Omit<SelectDropdownProps, 'onChange' | 'choices'>) => {
  const dispatch = useAppThunkDispatch()

  const agentSearchOptions = useAppSelector(state => state.autocomplete.agentSearchOptions)
  const loading = useAppSelector(state => state.autocomplete.loading.agentSearchOptions)

  const text = label ?? 'Expansion Rep'

  const onSearch = useCallback(
    debounce((query: string) => {
      query && dispatch(autoCompleteAgentSearch(query))
    }, 200),
    [],
  )

  return (
    <Select
      hideDropdownIndicator
      sm
      choices={agentSearchOptions}
      className={className}
      field='text'
      loading={loading}
      openMenuOnClick={!!agentSearchOptions.length}
      required={required}
      value={value || {}}
      onChange={onChange}
      onInputChange={onSearch}
      onValueNotFound={() => onSearch(value)}
      {...(withLabel && { label: text })}
      {...(withPlaceholder && { placeholder: text })}
      {...props}
    />
  )
}
