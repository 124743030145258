import { CatchError, formatAxiosErrorToPayload, getErrorString } from '@common'
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import dayjs from 'dayjs'
import { snakeCase } from 'lodash-es'
import { toast } from 'react-toastify'

import { api } from '../api/api'
import { initialSearchParams } from '../common/constants'
import {
  CapacityListItem,
  InternalBulkQuote,
  MultiStopQuotingParams,
  NewQuote,
  QuotingLocationItem,
  RootState,
  TableOrder,
} from '../common/types'
import { buildLanes, getOrderingString, keysToCamelCase, keysToSnakeCase } from '../common/utils'

type QuotingToolState = {
  quote: NewQuote
  isSet: boolean
  predictedPrice: number
  truckstopAverage: {
    price?: number
    count?: number
  }
  distance: number
  datapoints: Array<{
    price: number
    count: number
    type: string
    reportDate: number
  }>
  loading: {
    mlPrediction: boolean
    truckstopPrediction: boolean
    tableData: boolean
    submittingNewPrice: boolean
    submittingBulkQuotes: boolean
    predictions: boolean
  }
  searchParams: MultiStopQuotingParams
  matchingCarriersList: Array<CapacityListItem>
  loadsList: Array<{
    id: number
    shipperCity: string
    shipperState: string
    consigneeCity: string
    consigneeState: string
    carrierId: number
    carrierName: string
    carrierTotal: number
    customerTotal: number
    deliveryDate: string
    originsDistance: number
    destinationsDistance: number
    pickupDate: string
    miles: number
  }>
  loadsCount: number
  carriersCount: number
  bulkQuote: InternalBulkQuote | null
  isCapacityChartVisible: boolean
  isQuotingToolChartVisible: boolean
  isCreateButtonEnabled: boolean
  isQuoteModalVisible: boolean
  historicalLoadsOrder: TableOrder
}

const initialState: QuotingToolState = {
  quote: {},
  isSet: false,
  predictedPrice: 0,
  truckstopAverage: {},
  distance: 0,
  datapoints: [],
  loading: {
    mlPrediction: false,
    truckstopPrediction: false,
    tableData: false,
    submittingNewPrice: false,
    submittingBulkQuotes: false,
    predictions: false,
  },
  searchParams: initialSearchParams,
  matchingCarriersList: [],
  loadsList: [],
  loadsCount: 0,
  carriersCount: 0,
  bulkQuote: null,
  isCapacityChartVisible: false,
  isQuotingToolChartVisible: true,
  isCreateButtonEnabled: false,
  isQuoteModalVisible: false,
  historicalLoadsOrder: { label: 'Pickup Date', direction: 'descending', key: 'pickup_date' },
}

export const getStopPayload = (
  stop: QuotingLocationItem,
  pickupWeight?: number,
  dropoffWeight?: number,
) => ({
  country: stop.location?.country || 'USA',
  state: stop.location?.state?.toUpperCase(),
  city: stop.location?.city,
  pickupWeight: pickupWeight || 0,
  dropoffWeight: dropoffWeight || 0,
  postalCode: stop.location?.postalCode,
  lat: stop.location?.latitude,
  lon: stop.location?.longitude,
})

// Creates a ManualDataPoint object in the backend, representing a user-input price.
export const submitNewPrice = createAsyncThunk(
  'quotingTool/submitNewPrice',
  async (newPrice: string | number, { getState }) => {
    const state = getState() as RootState
    const searchParams = state.quotingTool.searchParams
    const distance = state.locations.routeDistance

    const formatLocation = (locationType: 'origin' | 'destination') => ({
      ...searchParams[locationType].location,
      countryCode: searchParams[locationType].location?.country,
      position: {
        lat: searchParams[locationType].location?.latitude,
        lng: searchParams[locationType].location?.longitude,
      },
    })

    const features = {
      price: newPrice,
      modelPredictedPrice: state.quotingTool.predictedPrice || 0,
      truckstopPredictedPrice: state.quotingTool.truckstopAverage.price || 0,
      searchInfo: {
        ...searchParams,
        origin: formatLocation('origin'),
        destination: formatLocation('destination'),
      },
      distance: distance,
    }

    await api.post('/pricing/create-data-point/', {
      features: keysToSnakeCase(features),
    })
  },
)

export const getMatchingCarriersList = createAsyncThunk(
  'quotingTool/getMatchingCarriersList',
  async (payload: MultiStopQuotingParams, { getState }) => {
    const { historicalLoadsOrder } = (getState() as RootState).quotingTool
    const miles = payload.miles || (getState() as RootState).locations.routeDistance || 1

    const { label, direction, key } = historicalLoadsOrder
    const ordering = getOrderingString(label, direction, key, '-pickup_date')

    const data = {
      equipmentType: payload.equipmentType,
      originCity: payload.origin.location?.city,
      originState: payload.origin.location?.state,
      originGeopoint: {
        lat: payload.origin.location?.latitude,
        lng: payload.origin.location?.longitude,
      },
      destinationCity: payload.destination.location?.city,
      destinationState: payload.destination.location?.state,
      destinationGeopoint: {
        lat: payload.destination.location?.latitude,
        lng: payload.destination.location?.longitude,
      },
      carrierPrice: payload.carrierPrice,
      miles: miles,
      pickupDate: payload.pickupDate || dayjs().add(1, 'd').format('YYYY-MM-DD'),
      loadId: payload.loadId,
    }

    const response = await api.post(
      '/carrier-matching/api/quoting-tool-recommended-carriers/',
      keysToSnakeCase(data),
      {
        params: {
          historical_loads_offset: 0,
          historical_loads_limit: 300,
          historical_loads_ordering: ordering,
          recommended_carriers_offset: 0,
          recommended_carriers_limit: 300,
        },
      },
    )

    return keysToCamelCase(response.data)
  },
)

export const getPricePredictionsV2 = createAsyncThunk(
  'quotingTool/getPricePredictionsV2',
  async (payload: any, { getState, rejectWithValue }) => {
    while ((getState() as RootState).locations.loading.routeDistance) {
      await new Promise(resolve => setTimeout(resolve, 100))
    }
    const distance = (getState() as RootState).locations.routeDistance || 1
    const routeSegments = (getState() as RootState).locations.routeSegments

    const { origin, destination, stops, equipmentType } = payload

    const locations = [
      getStopPayload(origin, 44000),
      ...stops.map((stop: QuotingLocationItem) => getStopPayload(stop)),
      getStopPayload(destination, 0, 44000),
    ]
    const lanes: any = []

    await buildLanes(locations, lanes, equipmentType, routeSegments)

    const features = {
      equipmentType,
      lanes,
      pickupDate: lanes[0].pickup_date,
    }

    try {
      const response = await api.post('/pricing/ml-prediction/', keysToSnakeCase(features))
      return keysToCamelCase({ ...response.data, distance })
    } catch (err: CatchError) {
      return rejectWithValue(formatAxiosErrorToPayload(err))
    }
  },
)

export const submitBulkQuotes = createAsyncThunk(
  'quotingTool/submitBulkQuotes',
  async (
    payload: {
      file: File
      customerCompanyId: number
      useTruckstop: boolean
      useMargin: boolean
      useAutomatedBiddingSettings: boolean
      useFuelSurcharge: boolean
      marginTarget: number | null
      marginMin: number | null
      marginMax: number | null
      fuelSurchargeRate: number | null
    },
    { rejectWithValue },
  ) => {
    const { file, ...params } = payload
    const formData = new FormData()
    formData.append('file', file, file.name)
    Object.keys(params).forEach(key => {
      if (params[key as keyof typeof params] !== null)
        formData.append(snakeCase(key), String(params[key as keyof typeof params]))
    })
    try {
      const response = await api.post('/quotes/api/upload-internal-bulk-quote/', formData)
      return keysToCamelCase(response.data)
    } catch (e: CatchError) {
      if (typeof e === 'string' || e instanceof String) return rejectWithValue(e)
      // If it's a validation error, return the first key
      if (e.response.status === 406) {
        const key = Object.keys(e.response.data)[0]
        return rejectWithValue(e.response.data[key])
      }
      // Otherwise, return the error string
      return rejectWithValue(e.response.data)
    }
  },
)

export const checkBulkQuoteStatus = createAsyncThunk(
  'quotingTool/checkBulkQuoteStatus',
  async (payload: { id: number }) => {
    const response = await api.get('/quotes/api/list-internal-bulk-quotes/', {
      params: { id: payload.id },
    })

    return keysToCamelCase(response.data.results[0])
  },
)

export const quotingToolSlice = createSlice({
  name: 'quotingTool',
  initialState,
  reducers: {
    setQuote(state, { payload }: { payload: NewQuote }) {
      state.quote = payload
    },
    deleteQuote(state) {
      state.quote = {}
    },
    setSearchParams(state, { payload }) {
      state.searchParams = payload
    },
    setBulkQuote(state, { payload }) {
      state.bulkQuote = payload
    },
    resetMatchingCarriersList(state) {
      state.matchingCarriersList = []
      state.carriersCount = 0
      state.loadsList = []
      state.loadsCount = 0
    },
    toggleIsCapacityChartVisible(state) {
      state.isCapacityChartVisible = !state.isCapacityChartVisible
    },
    toggleIsQuotingToolChartVisible(state) {
      state.isQuotingToolChartVisible = !state.isQuotingToolChartVisible
    },
    setCreateButtonEnabled(state, { payload }) {
      state.isCreateButtonEnabled = payload
    },
    setQuoteModalVisible(state, { payload }) {
      state.isQuoteModalVisible = payload
    },
    setHistoricalLoadsOrder(state, { payload }) {
      state.historicalLoadsOrder = payload
    },
    resetPricePredictions(state) {
      state.predictedPrice = 0
      state.datapoints = []
      state.truckstopAverage = {}
    },
  },
  extraReducers(builder) {
    builder
      .addCase(getPricePredictionsV2.pending, state => {
        state.loading.predictions = true
      })
      .addCase(getPricePredictionsV2.fulfilled, (state, { payload }) => {
        state.loading.predictions = false
        state.distance = payload.distance
        state.predictedPrice = payload.predictedPrice
        state.datapoints = payload.datapoints
        state.truckstopAverage = {
          count: payload.truckstopAverage?.range30?.count || 0,
          price: payload.truckstopRate,
        }
      })
      .addCase(getPricePredictionsV2.rejected, (state, { payload }) => {
        state.loading.predictions = false
        state.predictedPrice = 0
        state.datapoints = []

        // Even if the EXO Rate generates an error,
        // we may return a truckstop rate, so attempt to parse it
        const data = keysToCamelCase(payload)
        if (data?.truckstopRate)
          state.truckstopAverage = {
            count: data?.truckstopAverage?.range30?.count || 0,
            price: data?.truckstopRate,
          }
        else state.truckstopAverage = {}
        toast.error(getErrorString(payload, 'Failed to get price predictions'))
      })
      .addCase(getMatchingCarriersList.pending, state => {
        state.loading.tableData = true
      })
      .addCase(getMatchingCarriersList.fulfilled, (state, action) => {
        const { recommendedCarriers, historicalLoads } = action.payload
        state.loading.tableData = false

        state.matchingCarriersList = recommendedCarriers.results
        state.loadsList = historicalLoads.results
        state.loadsCount = historicalLoads.count
        state.carriersCount = recommendedCarriers.count
      })
      .addCase(getMatchingCarriersList.rejected, state => {
        state.loading.tableData = false
        state.matchingCarriersList = []
        state.carriersCount = 0
        state.loadsList = []
        state.loadsCount = 0
      })
      .addCase(submitNewPrice.pending, state => {
        state.loading.submittingNewPrice = true
      })
      .addCase(submitNewPrice.fulfilled, state => {
        state.loading.submittingNewPrice = false
        toast.success('Submitted')
      })
      .addCase(submitNewPrice.rejected, (state, action) => {
        state.loading.submittingNewPrice = false
        toast.error(getErrorString(action.payload, 'Error saving price'))
      })
      .addCase(submitBulkQuotes.pending, state => {
        state.loading.submittingBulkQuotes = true
      })
      .addCase(submitBulkQuotes.fulfilled, (state, action) => {
        state.loading.submittingBulkQuotes = false
        state.bulkQuote = {
          ...action.payload,
          errors: Object.keys(action.payload.errors).map(
            row => `Row ${row} - ${action.payload.errors[row]}`,
          ),
        }
      })
      .addCase(submitBulkQuotes.rejected, (state, action) => {
        state.loading.submittingBulkQuotes = false
        toast.error(getErrorString(action.payload, 'Error submitting bulk quote'))
      })
      .addCase(checkBulkQuoteStatus.fulfilled, (state, action) => {
        state.bulkQuote = {
          ...action.payload,
          errors: Object.keys(action.payload.errors).map(
            row => `Row ${row} - ${action.payload.errors[row]}`,
          ),
        }
      })
  },
})

export const {
  setQuote,
  deleteQuote,
  setSearchParams,
  setBulkQuote,
  resetMatchingCarriersList,
  toggleIsCapacityChartVisible,
  toggleIsQuotingToolChartVisible,
  setCreateButtonEnabled,
  setQuoteModalVisible,
  setHistoricalLoadsOrder,
  resetPricePredictions,
} = quotingToolSlice.actions

export default quotingToolSlice.reducer
