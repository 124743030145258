import tw from 'tailwind-styled-components'

export const EmptyMessage = ({
  loading = false,
  length = 0,
  text,
  className = '',
}: {
  loading?: boolean
  length?: number
  text: string
  className?: string
}) => (!loading && !length ? <Container className={className}>No {text}</Container> : <div />)

const Container = tw.div`
  text-center
  text-dark-gray
`
