import { Checkbox, Form, TextArea, TextInput, Toggle } from '@components'
import { useMemo } from 'react'

import { useAppSelector, useAppThunkDispatch } from '../../../app/hooks'
import { OnboardingCustomerContact } from '../../../common/types'
import { getContactHeader, useCustomerType } from '../../../common/utils'
import { setCustomerInfo, setIdsToDelete } from '../../../redux/customersSlice'
import { getCheckboxes } from '../utils'
import { DeleteButton } from './DeleteButton'
import { SectionHeader } from './SectionHeader'

export const Contact = ({
  contact,
  index,
  isAccounting,
}: {
  contact: OnboardingCustomerContact
  index: number
  isAccounting?: boolean
}) => {
  const type = useCustomerType()

  const dispatch = useAppThunkDispatch()

  const customer = useAppSelector(state => state.customers[type])
  const idsToDelete = useAppSelector(state => state.customers.idsToDelete)

  const step = useMemo(() => (isAccounting ? 'accountingStep' : 'contactsStep'), [isAccounting])

  const { contacts } = customer[step]

  const setContacts = (newData: OnboardingCustomerContact[]) =>
    dispatch(
      setCustomerInfo({
        [step as string]: { ...customer[step], contacts: newData },
      }),
    )

  const deleteContact = () => {
    setContacts(contacts.filter(cn => contact.id !== cn.id))
    dispatch(setIdsToDelete({ contacts: [...idsToDelete.contacts, contact.id].filter(Number) }))
  }

  const updateContact = (newData: Partial<OnboardingCustomerContact>) => {
    const tempContacts = [...contacts]
    tempContacts[index] = { ...tempContacts[index], ...newData }
    setContacts(tempContacts)
  }

  const checkboxes = useMemo(() => getCheckboxes(contact), [contact])

  const checkboxInputs = isAccounting ? checkboxes : checkboxes.filter(cb => !cb.isAccounting)

  return (
    <div className='mt-12 first:mt-0'>
      <SectionHeader>{getContactHeader(index, isAccounting, true)}</SectionHeader>
      <Form
        setFormValid={isValid => {
          const isFormValid = contact.isValid
          if (isFormValid !== isValid) updateContact({ isValid: !contact.isValid })
        }}
      >
        <TextInput
          required
          sm
          label='Name'
          value={contact.name}
          onChange={name => updateContact({ name })}
        />
        <div className='grid grid-cols-2 gap-4 mt-3'>
          <TextInput
            required
            sm
            label='Phone'
            type='tel'
            value={contact.phone}
            onChange={phone => updateContact({ phone })}
          />
          <TextInput
            required
            sm
            label='Email'
            type='email'
            value={contact.email}
            onChange={email => updateContact({ email })}
          />
        </div>
        <TextArea
          sm
          label='Notes'
          className='mt-3'
          value={contact.notes}
          onChange={notes => updateContact({ notes })}
        />
        <div className='grid grid-cols-2 mt-3'>
          {checkboxInputs.map(checkbox => (
            <Checkbox
              key={checkbox.key}
              isChecked={checkbox.value}
              className='mt-3'
              title={checkbox.label}
              disabled={isAccounting && checkbox.key === 'invoice'}
              onChange={() => updateContact({ [checkbox.key]: !checkbox.value })}
            />
          ))}
        </div>
        <div className='flex items-center mt-6'>
          <Toggle
            value={contact.shipperPortalEnabled}
            onChange={() => updateContact({ shipperPortalEnabled: !contact.shipperPortalEnabled })}
          />
          <div className='ml-2'>Enable Shipper Account</div>
        </div>
      </Form>
      <DeleteButton onClick={deleteContact} />
    </div>
  )
}
