import tw from 'tailwind-styled-components'

import { IconButton } from '../Button'

type DetailsSectionDividerProps = {
  headerText: string
  className?: string
  editable?: boolean
  isEditing?: boolean
  onCancel?: () => void
  onSave?: () => void
  isSaveButtonDisabled?: boolean
  setEditing?: (value: boolean) => void
  addable?: boolean
  onAdd?: () => void
  disabled?: boolean
  rightContent?: JSX.Element | null
}

export const DetailsSectionDivider = ({
  headerText,
  className = '',
  editable,
  isEditing,
  onCancel = () => {},
  onSave,
  isSaveButtonDisabled,
  setEditing = () => {},
  onAdd = () => {},
  addable,
  disabled,
  rightContent = null,
}: DetailsSectionDividerProps) => (
  <Container className={className}>
    {headerText}
    <DividerBar />
    {editable && (
      <div className='mr-4'>
        {isEditing ? (
          <div className='flex'>
            <IconButton
              className='mx-4'
              disabled={isSaveButtonDisabled || disabled}
              icon='check'
              type='success'
              onClick={onSave}
            />
            <IconButton
              icon='close'
              type='secondary'
              onClick={() => {
                setEditing(false)
                onCancel()
              }}
            />
          </div>
        ) : (
          <IconButton
            className='ml-4'
            disabled={disabled}
            icon={addable ? 'plus-circle' : 'edit'}
            type={addable ? 'warn' : 'secondary'}
            onClick={() => (addable ? onAdd() : setEditing(true))}
          />
        )}
      </div>
    )}
    {rightContent && <div className='ml-4'>{rightContent}</div>}
  </Container>
)

const Container = tw.div`
  my-4
  flex 
  whitespace-nowrap 
  items-center 
  uppercase 
  font-semibold
  text-sm
  h-8
`

const DividerBar = tw.hr`
  w-full
  ml-4
  border-t
  border-light-blue
`
