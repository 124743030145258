import { Button, DraggableAlphaModal, Select, TextArea } from '@components'
import dayjs from 'dayjs'
import { useCallback, useEffect, useState } from 'react'
import tw from 'tailwind-styled-components'

import { useAppSelector, useAppThunkDispatch } from '../../app/hooks'
import { carrierCallOutcomeChoices } from '../../common/constants'
import { CarrierCallDisposition } from '../../common/types'
import { findCarrierByMcOrDot } from '../../redux/autocompleteSlice'
import { updateOrCreateCarrierCallDisposition } from '../../redux/carrierLoadOffersSlice'
import { addNewCarrierLane } from '../../redux/carriersSlice'
import { CapacityForm, CapacityLane, isCapacityLaneValid } from '../Capacity'
import { CarrierInfoForm } from './components/CarrierInfoForm'

enum Steps {
  CARRIER_INFO = 'CARRIER_INFO',
  CALL_NOTES = 'CALL_NOTES',
}

export const CarrierCallDispositionModal = ({
  isVisible,
  setVisible,
  carrierCompanyId,
  mcNumber,
  dotNumber,
  carrierName,
}: {
  isVisible: boolean
  setVisible: (value: boolean) => void
  carrierCompanyId?: number
  mcNumber?: string
  dotNumber?: string
  carrierName?: string
}) => {
  const carrierCallDispositionList = useAppSelector(
    state => state.carrierLoadOffers.carrierCallDispositionList,
  )
  const isCreateOrUpdateLoading = useAppSelector(
    state => state.carrierLoadOffers.loading.updateOrCreateCarrierCallDisposition,
  )
  const isCarrierAutocompleteLoading = useAppSelector(
    state => state.autocomplete.loading.carrierSearchOptions,
  )
  const isAddCarrierLaneButtonLoading = useAppSelector(
    state => state.carriers.loading.addNewCarrierLane,
  )
  const loadId = useAppSelector(state => state.loads.loadDetails.id)

  const [_mcNumber, setMcNumber] = useState(mcNumber)
  const [_dotNumber, setDotNumber] = useState(dotNumber)
  const [_carrierName, setCarrierName] = useState(carrierName)
  const [_carrierCompanyId, setCarrierCompanyId] = useState(carrierCompanyId)
  const [capacityLane, setCapacityLane] = useState<CapacityLane>({})
  const [carrierCall, setCarrierCall] = useState<Partial<CarrierCallDisposition>>({
    loadId,
    carrierCompanyId: _carrierCompanyId,
    mcNumber: _mcNumber,
    dotNumber: _dotNumber,
    carrierName: _carrierName,
  })
  const [step, setStep] = useState(Steps.CARRIER_INFO)
  const setCarrierCallField = (field: string, value: any) =>
    setCarrierCall({ ...carrierCall, [field]: value })

  useEffect(() => {
    // If carrier info is passed in as a prop, update our internal state to reflect it
    setMcNumber(mcNumber)
    setDotNumber(dotNumber)
    setCarrierName(carrierName)
    setCarrierCompanyId(carrierCompanyId)
    const needsCarrier = !(carrierCompanyId || carrierName || mcNumber || dotNumber)
    setStep(needsCarrier ? Steps.CARRIER_INFO : Steps.CALL_NOTES)
  }, [mcNumber, dotNumber, carrierName, carrierCompanyId])

  // Find any existing call notes for the current carrier
  const existingCarrierCall = carrierCallDispositionList.find(
    carrierCallDisposition =>
      (_carrierCompanyId && carrierCallDisposition.carrierCompanyId === _carrierCompanyId) ||
      (_mcNumber && carrierCallDisposition.mcNumber === _mcNumber) ||
      (_dotNumber && carrierCallDisposition.dotNumber === _dotNumber) ||
      (_carrierName && carrierCallDisposition.carrierName === _carrierName),
  )

  useEffect(() => {
    // Update the carrierCall state to match the current carrier info and any previous call notes
    if (existingCarrierCall) setCarrierCall(existingCarrierCall)
    else
      setCarrierCall({
        loadId,
        carrierCompanyId: _carrierCompanyId,
        mcNumber: _mcNumber,
        dotNumber: _dotNumber,
        carrierName: _carrierName,
      })
  }, [_mcNumber, _dotNumber, _carrierName, _carrierCompanyId, existingCarrierCall?.id])

  const dispatch = useAppThunkDispatch()

  const onClose = useCallback(() => {
    setStep(Steps.CARRIER_INFO)
    setDotNumber('')
    setMcNumber('')
    setCarrierName('')
    setCarrierCompanyId(undefined)
    setCarrierCall({
      loadId,
      carrierCompanyId: _carrierCompanyId,
      mcNumber: _mcNumber,
      dotNumber: _dotNumber,
      carrierName: _carrierName,
    })
    setCapacityLane({})
    setVisible(false)
  }, [loadId, _carrierCompanyId, _mcNumber, _dotNumber, _carrierName])

  const onSubmit = useCallback(async () => {
    if (step === Steps.CARRIER_INFO) {
      // Query backend to check if carrier MC/DOT exists
      const matchingCarriers = await dispatch(
        findCarrierByMcOrDot({ mcNumber: _mcNumber, dotNumber: _dotNumber }),
      )
        .unwrap()
        .catch(() => [])
      // Update _carrierCompanyId if we found a match
      if (matchingCarriers.length) setCarrierCompanyId(Number(matchingCarriers[0].id))
      setStep(Steps.CALL_NOTES)
      return
    }
    const response = await dispatch(updateOrCreateCarrierCallDisposition(carrierCall))
    if (response.meta.requestStatus === 'rejected') return
    onClose()
  }, [
    carrierCall.callNotes,
    carrierCall.callOutcome,
    loadId,
    _carrierCompanyId,
    _mcNumber,
    _dotNumber,
    _carrierName,
    step,
  ])

  const addCarrierLane = useCallback(async () => {
    if (!_carrierCompanyId) return
    const response = await dispatch(
      addNewCarrierLane({ newLane: capacityLane, carrierId: _carrierCompanyId }),
    )
    if (response.meta.requestStatus === 'rejected') return
    setCapacityLane({})
  }, [
    _carrierCompanyId,
    capacityLane.srcLocation?.name,
    capacityLane.srcRadius,
    capacityLane.dstLocation?.name,
    capacityLane.dstRadius,
    capacityLane.equipmentType,
    capacityLane.availabilityStart,
    capacityLane.availabilityEnd,
  ])

  const isCallNotesValid =
    carrierCall.callOutcome &&
    (existingCarrierCall
      ? existingCarrierCall.callNotes !== carrierCall.callNotes ||
        existingCarrierCall.callOutcome !== carrierCall.callOutcome
      : true)
  const isConfirmButtonDisabled =
    step === Steps.CARRIER_INFO ? !_carrierName || (!_mcNumber && !_dotNumber) : !isCallNotesValid

  return (
    <DraggableModal
      withOverlay
      $step={step}
      confirmButtonLabel={step === Steps.CARRIER_INFO ? 'Next' : 'Save Call Notes'}
      isConfirmButtonDisabled={isConfirmButtonDisabled}
      isVisible={isVisible}
      setVisible={setVisible}
      title='Carrier Call Notes'
      isConfirmButtonLoading={
        step === Steps.CARRIER_INFO ? isCarrierAutocompleteLoading : isCreateOrUpdateLoading
      }
      onCancel={onClose}
      onConfirm={onSubmit}
    >
      {step === Steps.CARRIER_INFO ? (
        <CarrierInfoForm
          carrierName={_carrierName}
          dotNumber={_dotNumber}
          mcNumber={_mcNumber}
          setCarrierName={setCarrierName}
          setDotNumber={setDotNumber}
          setMcNumber={setMcNumber}
        />
      ) : (
        <div className='p-6 flex'>
          {Boolean(_carrierCompanyId) && (
            <div className='flex-1 pr-2'>
              <h2 className='font-bold text-lg mb-2'>Carrier Preferences</h2>
              <SubForm>
                <h3 className='font-bold text-base'>Add Capacity</h3>

                <CapacityForm lane={capacityLane} setLane={setCapacityLane} isInline={false} />

                <Button
                  disabled={!isCapacityLaneValid(capacityLane)}
                  loading={isAddCarrierLaneButtonLoading}
                  type='warn'
                  onClick={addCarrierLane}
                >
                  Add Capacity
                </Button>
              </SubForm>
            </div>
          )}
          <div className='flex-1 pl-2'>
            <h2 className='font-bold text-lg mb-2'>Call Notes</h2>
            <TextArea
              required
              className='h-[330px]'
              value={carrierCall.callNotes ?? ''}
              onChange={value => setCarrierCallField('callNotes', value)}
            />
            {existingCarrierCall && (
              <div className='text-dark-gray'>
                Last called by {existingCarrierCall.agentUser.name} at{' '}
                {dayjs(existingCarrierCall.updatedAt).format('MM/DD/YYYY hh:mma')}
              </div>
            )}
            <Select
              sm
              required
              isClearable={false}
              className='mt-4'
              label='Call Outcome'
              choices={carrierCallOutcomeChoices}
              value={carrierCall.callOutcome}
              onChange={({ value }: { value: string }) => setCarrierCallField('callOutcome', value)}
            />
          </div>
        </div>
      )}
    </DraggableModal>
  )
}

const DraggableModal = tw(DraggableAlphaModal)<{ $step: Steps }>`
  !overflow-visible
  !min-w-[400px]
  ${({ $step }) => ($step === Steps.CARRIER_INFO ? '' : 'w-[800px]')}
`

const SubForm = tw.div`
  bg-lighter-gray
  p-2
  rounded-lg
  border
  border-light-gray
  mb-4
  flex
  flex-col
  gap-2
`
