import { TruckIcon } from '@heroicons/react/24/solid'
import dayjs from 'dayjs'
import { useEffect, useMemo, useState } from 'react'
import tw from 'tailwind-styled-components'

import { useAppThunkDispatch } from '../../../app/hooks'
import { Notification as NotifType, NotificationReason as Reason } from '../../../common/types'
import { updateNotifications } from '../../../redux/notificationsSlice'

type NotificationProps = {
  notification: NotifType
}

export const Notification = ({ notification }: NotificationProps) => {
  const {
    read,
    createdAt,
    data: { title, loadId },
  } = notification

  const openInTab = useOpenInTab({ notification })
  const timeSinceCreate = dayjs(createdAt).fromNow()
  const [pickupMessage, dropoffMessage] = useMessages({ notification })

  const carrierOfferNotificationType = useMemo(() => title?.split(' ')[3] || '', [title])

  return (
    <Container $read={read} onClick={openInTab}>
      <ReasonContainer $reason={notification.reason} $type={carrierOfferNotificationType}>
        <ReasonIconContainer>
          <TruckIcon className='text-white' />
        </ReasonIconContainer>
        <span className='text-white whitespace-nowrap font-semibold'>{notification.reason}</span>
      </ReasonContainer>
      <div className='w-[406px]'>
        <p className='font-semibold text-xs text-dark-blue'>{title}</p>
        <p className='text-xs text-dark-blue mt-1'>
          <span>
            {pickupMessage?.subtitle && <b>{pickupMessage?.subtitle}:</b>} {pickupMessage.message}
          </span>
          <span className='ml-3'>
            {dropoffMessage?.subtitle && <b>{dropoffMessage?.subtitle}:</b>}{' '}
            {dropoffMessage.message}
          </span>
        </p>
        {loadId && <p className='mt-1'>Load Number: {notification.data.loadId}</p>}
      </div>
      <div className='w-[110px] text-right'>
        <span className='text-dark-blue text-xs font-semibold'>{timeSinceCreate}</span>
      </div>
    </Container>
  )
}

const useMessages = ({
  notification,
}: {
  notification: NotifType
}): Array<{ subtitle?: string; message: string }> => {
  const {
    data: { pickupData, dropoffData },
  } = notification
  const [messages, setMessages] = useState([{ message: '' }, { message: '' }])

  useEffect(() => {
    const emptyMessage = { subtitle: '', loc: '', dateMessage: '', date: '' }

    const pickupMessage = createMessage(pickupData || emptyMessage)
    const dropoffMessage = createMessage(dropoffData || emptyMessage)

    setMessages([pickupMessage, dropoffMessage])
  }, [])

  return messages
}

const createMessage = ({
  subtitle = '',
  loc,
  dateMessage,
  date,
}: NotifType['data']['pickupData'] | NotifType['data']['dropoffData']) => ({
  subtitle,
  message: `${loc} ${dateMessage} ${date}`,
})

const useOpenInTab = ({ notification }: { notification: NotifType }) => {
  const dispatch = useAppThunkDispatch()

  const href = useHrefGenerator({ notification })

  return () => {
    window.open(href, '_blank')
    if (!notification.read) {
      dispatch(updateNotifications([{ ...notification, read: true }]))
    }
  }
}

const useHrefGenerator = ({ notification }: { notification: NotifType }) => {
  const {
    reason,
    data: { loadId },
  } = notification

  if (reason === Reason.NewEdiLoad || reason === Reason.LoadTracking) {
    return `${window.location.protocol}//${window.location.host}/loads/${loadId}`
  } else if (reason === Reason.EdiRequest) {
    return `${window.location.protocol}//${window.location.host}/edi?pendingOnly=true`
  }

  if (loadId) return `${window.location.protocol}/loads/${loadId}`

  return ''
}

const Container = tw.div`
  w-full
  flex
  items-center
  justify-between
  h-20
  p-6
  border-b
  border-light-gray
  cursor-pointer
  ${({ $read }: { $read: boolean }) => ($read ? 'bg-white' : 'bg-lighter-blue')}
`

const ReasonContainer = tw.div`
  h-6
  w-[121px]
  flex
  gap-2
  items-center
  rounded-full
  px-3
  ${({ $reason, $type }: { $reason: Reason; $type?: string }) =>
    ({
      [Reason.NewEdiLoad]: 'bg-success',
      [Reason.EdiRequest]: 'bg-exo-orange',
      [Reason.LoadTracking]: 'bg-brand-accent',
      [Reason.ContractLane]: 'bg-peach',
      [Reason.CarrierQuote]:
        ($type === 'submitted' && 'bg-purple') ||
        ($type === 'accepted' && 'bg-success') ||
        ($type === 'selected' && 'bg-brand-accent') ||
        'bg-error',
    })[$reason]}
`

const ReasonIconContainer = tw.div`
  h-3
  w-3
  flex
  items-center
  justify-center
`
