import { Form, TextArea, TextInput } from '@components'

import { useAppSelector, useAppThunkDispatch } from '../../../app/hooks'
import { CustomerAddress } from '../../../common/types'
import { useCustomerType } from '../../../common/utils'
import { setCustomerInfo, setIdsToDelete } from '../../../redux/customersSlice'
import { AddressForm } from '../../Forms'
import { DeleteButton } from './DeleteButton'
import { SectionHeader } from './SectionHeader'

export const Facility = ({ facility, index }: { facility: CustomerAddress; index: number }) => {
  const type = useCustomerType()

  const dispatch = useAppThunkDispatch()

  const addressesStep = useAppSelector(state => state.customers[type].addressesStep)
  const idsToDelete = useAppSelector(state => state.customers.idsToDelete)

  const { facilities } = addressesStep

  const setFacilities = (newData: CustomerAddress[]) =>
    dispatch(setCustomerInfo({ addressesStep: { ...addressesStep, facilities: newData } }))

  const updateFacility = (newData: Partial<CustomerAddress>) => {
    const tempFacilities = [...facilities]
    tempFacilities[index] = { ...tempFacilities[index], ...newData }

    setFacilities(tempFacilities)
  }

  const deleteFacility = () => {
    setFacilities(facilities.filter(fc => facility.id !== fc.id))
    dispatch(setIdsToDelete({ locations: [...idsToDelete.locations, facility.id].filter(Number) }))
  }

  return (
    <Form
      className='mt-8'
      setFormValid={isValid => isValid !== facility.isValid && updateFacility({ isValid })}
    >
      <SectionHeader>Address {index + 2}</SectionHeader>
      <TextInput
        sm
        required
        value={facility.name}
        label='Location Name'
        className='mb-3'
        onChange={name => updateFacility({ name })}
      />
      <AddressForm
        required
        value={facility}
        addressData={facility}
        setAddressData={updateFacility}
        type='address'
      />
      <TextArea
        sm
        className='mt-3'
        label='Notes'
        value={facility.notes}
        onChange={notes => updateFacility({ notes })}
      />
      <DeleteButton onClick={deleteFacility} />
    </Form>
  )
}
