import { AlphaModal, DateInput, Form, TextInput } from '@components'
import { useEffect, useState } from 'react'
import tw from 'tailwind-styled-components'

import { useAppSelector, useAppThunkDispatch } from '../../../app/hooks'
import { initialNewContractLane } from '../../../common/constants'
import { NewContractLane } from '../../../common/types/contractLane'
import { addContractLane, setNewContractLane } from '../../../redux/contractLanesSlice'
import { CityAutocomplete } from '../../Autocomplete'
import { CustomerSelect, EquipmentTypeSelect } from '../../Selects'

export const CreateContractLaneModal = ({
  isVisible,
  setVisible,
}: {
  isVisible: boolean
  setVisible: (value: boolean) => void
}) => {
  const [isFormValid, setFormValid] = useState(false)

  const dispatch = useAppThunkDispatch()

  const newContractLane = useAppSelector(state => state.contractLane.newContractLane)
  const loading = useAppSelector(state => state.contractLane.loading.addContractLane)

  useEffect(() => {
    if (!isVisible) dispatch(setNewContractLane(initialNewContractLane))
  }, [isVisible])

  const setLane = (newData: Partial<NewContractLane>) =>
    dispatch(setNewContractLane({ ...newContractLane, ...newData }))

  const createLane = () =>
    dispatch(addContractLane())
      .unwrap()
      .then(() => setVisible(false))
      .catch(() => {})

  return (
    <AlphaModal
      isVisible={isVisible}
      setVisible={setVisible}
      title='Create New Contract Lane'
      confirmButtonLabel='Create'
      className='lg:min-w-[50vw]'
      isConfirmButtonDisabled={!isFormValid}
      isConfirmButtonLoading={loading}
      onConfirm={createLane}
    >
      <Form className='p-6 lg:max-h-[60vh] overflow-auto' setFormValid={setFormValid}>
        <SectionHeader>Lane Details</SectionHeader>
        <Grid>
          <CustomerSelect
            withLabel
            required
            value={newContractLane.customer}
            setValue={customer => setLane({ customer })}
          />
          <EquipmentTypeSelect
            withLabel
            required
            setValue={equipmentType => setLane({ equipmentType })}
            value={newContractLane.equipmentType}
          />
          <CityAutocomplete
            required
            label='Origin'
            value={newContractLane.origin?.title ? newContractLane.origin : null}
            onChange={origin => setLane({ origin })}
          />
          <CityAutocomplete
            required
            label='Destination'
            value={newContractLane.destination?.title ? newContractLane.destination : null}
            onChange={destination => setLane({ destination })}
          />
          <DateInput
            required
            value={newContractLane.startDate}
            label='Contract Start Date'
            onChange={(startDate: any) => setLane({ startDate })}
          />
          <DateInput
            required
            value={newContractLane.endDate}
            label='Contract End Date'
            onChange={(endDate: any) => setLane({ endDate })}
          />
          <TextInput
            sm
            value={newContractLane.loadsPerWeek}
            label='Number of loads per week'
            type='number'
            onChange={loadsPerWeek => setLane({ loadsPerWeek })}
          />
        </Grid>
        <SectionHeader>Rate</SectionHeader>
        <Grid>
          <TextInput
            sm
            value={newContractLane.ratePerMile}
            label='Line Haul'
            type='currency'
            onChange={ratePerMile => setLane({ ratePerMile })}
          />
          <TextInput
            sm
            value={newContractLane.minimumCharge}
            label='Minimum Charge'
            type='currency'
            onChange={minimumCharge => setLane({ minimumCharge })}
          />
          <TextInput
            sm
            value={newContractLane.fuelCharge}
            label='Fuel Charge (Customer Accessorial)'
            type='currency'
            onChange={fuelCharge => setLane({ fuelCharge })}
          />
          <TextInput
            sm
            required
            value={newContractLane.allIn}
            label='All-In'
            type='currency'
            onChange={allIn => setLane({ allIn })}
          />
        </Grid>
        <TextInput
          sm
          className='mb-6'
          value={newContractLane.notes}
          label='Notes'
          onChange={notes => setLane({ notes })}
        />
      </Form>
    </AlphaModal>
  )
}

const Grid = tw.div`
  grid
  grid-cols-2
  gap-y-3
  gap-x-4
  mb-6
`

const SectionHeader = tw.div`
  font-bold
  text-base
  mb-4
`
