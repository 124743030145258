import { CatchError, formatAxiosErrorToPayload, getErrorString } from '@common'
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import * as sentry from '@sentry/react'
import { isObject, mapValues, omit } from 'lodash-es'
import { toast } from 'react-toastify'

import { api } from '../api/api'
import { PresetActionType, RootState, SearchFilters } from '../common/types'
import { formatDate, getPresetKey, keysToCamelCase } from '../common/utils'
import { initializeTracking } from './trackingSlice'

const dateRgx = /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}\.\d{3}Z$/
const transformLegacyPreset = (filters: any): any =>
  mapValues(filters, value => {
    if (Array.isArray(value)) {
      if (isObject(value[0])) return value.map((v: { id: string | number }) => v.id)
      if (dateRgx.test(value[0])) return value.map(formatDate)
      return value
    } else if (isObject(value)) return (value as { id: string | number }).id
    else if (dateRgx.test(value)) return formatDate(value)
    return value
  })

type LoginState = {
  loading: boolean
  error: string
  user: {
    dateJoined: string
    email: string
    firstName: string
    id: number
    isActive: boolean
    isAgent: boolean
    isCarrier: boolean
    isCustomer: boolean
    isStaff: boolean
    isSuperuser: boolean
    lastLogin: string
    lastName: string
    username: string
    accounting: boolean
    featureFlags: { [key: string]: string }
    csr: boolean
    admin: boolean
    isCarrierSalesRep: boolean
    isExpansionRep: boolean
  }
  presets: any
  presetsLoading: boolean
  showFilters: boolean
}

const initialState: LoginState = {
  loading: false,
  error: '',
  user: {
    dateJoined: '',
    email: '',
    firstName: '',
    id: 0,
    isActive: false,
    isAgent: false,
    isCarrier: false,
    isCustomer: false,
    isStaff: false,
    isSuperuser: false,
    lastLogin: '',
    lastName: '',
    username: '',
    accounting: false,
    featureFlags: {},
    csr: false,
    admin: false,
    isCarrierSalesRep: false,
    isExpansionRep: false,
  },
  presets: null,
  presetsLoading: false,
  showFilters: true,
}

export const login = createAsyncThunk(
  'user/login',
  async (credentials: any, { rejectWithValue }) => {
    try {
      const response = await api.post('/tms/api/login-with-password/', credentials)
      return keysToCamelCase(response.data)
    } catch (err: CatchError) {
      return rejectWithValue(formatAxiosErrorToPayload(err))
    }
  },
)

export const sendOTP = createAsyncThunk(
  'user/sendOTP',
  async (email: string, { rejectWithValue }) => {
    try {
      return api
        .post('/tms/users/contact-verification/', { email })
        .then(({ data }) => keysToCamelCase(data))
        .then(({ codeId }) => codeId as string)
    } catch (err: CatchError) {
      return rejectWithValue(formatAxiosErrorToPayload(err))
    }
  },
)

export const verifyOTP = createAsyncThunk(
  'user/verifyOTP',
  async ({ verificationId, otp }: { verificationId: string; otp: string }, { rejectWithValue }) => {
    try {
      await api.post(`/tms/users/contact-verification/${verificationId}/${otp}/`)
    } catch (err: CatchError) {
      return rejectWithValue(formatAxiosErrorToPayload(err))
    }
  },
)

export const logout = createAsyncThunk('user/logout', async () => api.post('/api/logout/'))

export const getAgentDetails = createAsyncThunk('user/getAgentDetail', async (_, thunkApi) => {
  try {
    const { data } = await api.get('/accounts/api/agent/detail/')
    sentry.setUser({ email: data.user.email, id: data.user.id })
    thunkApi.dispatch(initializeTracking(data.user?.email || data.user?.username))
    return keysToCamelCase(data)
  } catch (err: CatchError) {
    return thunkApi.rejectWithValue(formatAxiosErrorToPayload(err))
  }
})

export const getPresets = createAsyncThunk('user/getPresets', async () =>
  api.get('/accounts/api/preset/').then(({ data }) =>
    mapValues(data, location => {
      if (!location.presets) return location

      const presets = mapValues(location.presets, preset => {
        if (!preset.filters) return preset

        return {
          ...preset,
          filters: transformLegacyPreset(preset.filters),
        }
      })

      return {
        ...location,
        presets,
        selectedPreset: '',
      }
    }),
  ),
)

export const savePresets = createAsyncThunk(
  'user/savePresets',
  async (
    {
      presets,
      actionType,
    }: {
      presets: any
      actionType?: PresetActionType
    },
    { rejectWithValue },
  ) => {
    try {
      await api.put('/accounts/api/preset/', presets)
      return actionType
    } catch (err: CatchError) {
      return rejectWithValue(formatAxiosErrorToPayload(err))
    }
  },
)

export const setPresets = createAsyncThunk(
  'user/setPresets',
  async (
    {
      id,
      title,
      filters,
      actionType,
    }: { id: string; title: string; filters: SearchFilters; actionType?: PresetActionType },
    { getState, dispatch },
  ) => {
    const { presets = {} } = (getState() as RootState).user

    const presetKey = getPresetKey() // E.g. 'loads'
    const activePresets = presets?.[presetKey]?.activePresets || []

    const payload = {
      ...presets,
      [presetKey]: {
        ...presets?.[presetKey],
        presets: {
          ...presets?.[presetKey]?.presets,
          [id]: {
            title,
            filters,
          },
        },
        ...(actionType === 'created' && {
          activePresets: [...activePresets, id],
          selectedPreset: id,
        }),
      },
    }

    dispatch(savePresets({ presets: payload, actionType }))
  },
)

// selected preset is the one currently applied
export const setSelectedPreset = createAsyncThunk(
  'user/setSelectedPreset',
  async (id: string, { getState, dispatch }) => {
    const { presets = {} } = (getState() as RootState).user

    const presetKey = getPresetKey()

    const payload = {
      ...presets,
      [presetKey]: {
        ...presets?.[presetKey],
        selectedPreset: id,
      },
    }

    dispatch(setPresetsProp(payload))
  },
)

// active presets are the ones marked as checked in the presets dropdown
export const setActivePresets = createAsyncThunk(
  'user/setActivePresets',
  async (id: string, { getState, dispatch }) => {
    const { presets = {} } = (getState() as RootState).user

    const presetKey = getPresetKey()
    const activePresets = presets?.[presetKey]?.activePresets || []

    const payload = {
      ...presets,
      [presetKey]: {
        ...presets?.[presetKey],
        activePresets: activePresets.includes(id)
          ? activePresets.filter((presetId: string) => presetId !== id)
          : [...activePresets, id],
      },
    }

    dispatch(savePresets({ presets: payload }))
  },
)

export const setMultipleActivePresets = createAsyncThunk(
  'user/setMultipleActivePresets',
  async (ids: Array<string>, { getState, dispatch }) => {
    const { presets = {} } = (getState() as RootState).user

    const presetKey = getPresetKey()
    const selectedPreset = presets?.[presetKey]?.selectedPreset

    const payload = {
      ...presets,
      [presetKey]: {
        ...presets?.[presetKey],
        activePresets: ids,
        selectedPreset: !ids.includes(selectedPreset) ? '' : selectedPreset,
      },
    }

    dispatch(savePresets({ presets: payload }))
  },
)

export const deletePreset = createAsyncThunk(
  'user/deletePreset',
  async (data: string, { getState, dispatch }) => {
    const { presets = {} } = (getState() as RootState).user

    const presetKey = getPresetKey()
    const current = presets?.[presetKey]
    const currentPresets = omit(current.presets, data)

    const payload = {
      ...presets,
      [presetKey]: {
        presets: currentPresets,
        selectedPreset: '',
        activePresets: current.activePresets.filter((presetId: string) => presetId !== data),
      },
    }

    dispatch(savePresets({ presets: payload, actionType: 'deleted' }))
  },
)

export const checkIfUserIsAuthenticated = createAsyncThunk(
  'user/checkIfUserIsAuthenticated',
  async () => {
    const { data } = await api.get('/tms/users/check-auth/')
    return data.authenticated
  },
)

const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    resetLogin(state) {
      state.loading = false
      state.error = ''
    },
    setPresetsProp(state, { payload }) {
      state.presets = payload
    },
    setShowFilters(state, { payload }) {
      state.showFilters = payload
    },
  },
  extraReducers(builder) {
    builder
      .addCase(login.pending, state => {
        state.loading = true
        state.error = ''
      })
      .addCase(login.fulfilled, state => {
        state.loading = false
      })
      .addCase(login.rejected, (state, { payload }) => {
        state.loading = false
        state.error = getErrorString(payload, 'No active account found with the given credentials')
      })
      .addCase(sendOTP.pending, state => {
        state.loading = true
        state.error = ''
      })
      .addCase(sendOTP.fulfilled, state => {
        state.loading = false
      })
      .addCase(sendOTP.rejected, (state, { payload }) => {
        state.loading = false
        state.error = getErrorString(payload, 'Could not send OTP, please try again')
      })
      .addCase(verifyOTP.pending, state => {
        state.loading = true
        state.error = ''
      })
      .addCase(verifyOTP.fulfilled, state => {
        state.loading = false
      })
      .addCase(verifyOTP.rejected, (state, { payload }) => {
        state.loading = false
        state.error = getErrorString(payload, 'Error validating OTP')
      })
      .addCase(getAgentDetails.fulfilled, (state, { payload }) => {
        state.user = {
          ...payload.user,
          accounting: payload.accounting,
          featureFlags: payload.featureFlags,
          csr: payload.carrierRelations,
          admin: payload.admin,
        }
      })
      .addCase(getPresets.pending, state => {
        state.presetsLoading = true
      })
      .addCase(getPresets.fulfilled, (state, { payload }) => {
        state.presetsLoading = false
        state.presets = payload
      })
      .addCase(getPresets.rejected, state => {
        state.presetsLoading = false
        toast.error('Error getting presets')
      })
      .addCase(savePresets.pending, state => {
        state.presetsLoading = true
      })
      .addCase(savePresets.fulfilled, (state, { payload, meta }) => {
        state.presetsLoading = false
        state.presets = meta.arg.presets
        payload && toast.success(`Successfully ${payload} preset`)
      })
      .addCase(savePresets.rejected, (state, { payload }) => {
        state.presetsLoading = false
        toast.error(getErrorString(payload, 'Error updating presets'))
      })
  },
})

export const { resetLogin, setPresetsProp, setShowFilters } = userSlice.actions

export default userSlice.reducer
