import { Menu } from '@headlessui/react'
import { ChevronDownIcon, EllipsisVerticalIcon } from '@heroicons/react/24/solid'
import { useMemo } from 'react'
import tw from 'tailwind-styled-components'

import { EaseInOutTransition } from '../EaseInOutTransition'

export const MoreDropdown = ({
  items,
  withBorder,
  className,
  icon,
  dropdownClassName,
  iconColor = 'dark-blue',
}: {
  items: Array<{ label: string; onClick: () => void }>
  withBorder?: boolean
  className?: string
  icon?: string
  dropdownClassName?: string
  iconColor?: string
}) => {
  const renderIcon = useMemo(() => {
    const iconClassName = `w-4 text-${iconColor}`
    switch (icon) {
      case 'chevron':
        return <ChevronDownIcon className={iconClassName} />
      default:
        return <EllipsisVerticalIcon className={iconClassName} />
    }
  }, [icon])

  return (
    <MenuContainer as='div' className={className}>
      <Menu.Item>
        <MainButton $withBorder={withBorder} data-testid='menu-button'>
          {renderIcon}
        </MainButton>
      </Menu.Item>
      <EaseInOutTransition>
        <Dropdown className={dropdownClassName}>
          {items.map((item: { label: string; onClick: () => void }) => (
            <MenuItem key={item.label} onClick={item.onClick}>
              {item.label}
            </MenuItem>
          ))}
        </Dropdown>
      </EaseInOutTransition>
    </MenuContainer>
  )
}

const MenuContainer = tw(Menu)`
  relative
  inline-block
  text-left
`

const MenuItem = tw(Menu.Button)`
  hover:bg-lighter-blue
  cursor-pointer
  transition-all
  whitespace-nowrap
  py-3
  px-4
  w-full
  text-left
`

const MainButton = tw(Menu.Button)<{ $withBorder?: boolean }>`
  hover:opacity-80
  transition-all
  cursor-pointer
  ${({ $withBorder }) =>
    $withBorder && 'border border-dark-blue rounded-md w-8 h-8 flex items-center justify-center'}
`

const Dropdown = tw(Menu.Items)`
  origin-top-right
  mt-2
  rounded-md
  shadow-lg
  ring-1
  ring-black
  ring-opacity-5
  focus:outline-none
  bg-white
  left-auto
  bottom-auto
  absolute
  -top-8
  right-10
  py-1
`
