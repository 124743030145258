import { Tooltip } from '@components'
import { InformationCircleIcon } from '@heroicons/react/24/solid'
import tw from 'tailwind-styled-components'

export const Tab = ({
  label,
  index,
  activeTab,
  setTab,
  error = false,
  errorText,
}: {
  label: string
  index: number
  activeTab: number
  setTab: (index: number) => void
  error?: boolean
  errorText?: string
}) => (
  <Container $error={error} $active={index === activeTab} onClick={() => setTab(index)}>
    {label}
    {error && (
      <Tooltip content={errorText}>
        <InformationCircleIcon className='w-4 text-error ml-2' />
      </Tooltip>
    )}
  </Container>
)

const Container = tw.div<{ $active: boolean; $error?: boolean }>`
  font-semibold
  px-6
  py-4
  rounded-r-lg
  mb-2
  cursor-pointer
  hover:bg-lighter-blue
  transition-all
  text-sm
  flex items-center
  ${({ $active }) => $active && 'bg-lighter-blue text-link'}
  ${({ $error }) => ($error ? 'text-error' : 'hover:text-link')}
`
