import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'

import { api } from '../api/api'
import { HereMapLocation, LatitudeLongitude, LatLng } from '../common/types'
import { keysToCamelCase } from '../common/utils'

type LocationsState = {
  loading: {
    locations: boolean
    routeDistance: boolean
    cities: boolean
  }
  locations: Array<any>
  routeDistance: number
  routeSegments: Array<{
    polyline: any
    distance: number
    origin: LatLng
    destination: LatLng
  }>
  autocompleteLocations: Array<any>
  cities: (LatitudeLongitude & { city: string; state: string; country: string })[]
  popularCities: [string, string, string, number, number][]
  requestTimestamps: {
    cities: number | null
  }
}

const initialState: LocationsState = {
  loading: {
    locations: false,
    routeDistance: false,
    cities: false,
  },
  locations: [],
  routeDistance: 0,
  routeSegments: [],
  autocompleteLocations: [],
  cities: [],
  popularCities: [],
  requestTimestamps: {
    cities: null,
  },
}

export const getPopularCities = createAsyncThunk('locations/getPopularCities', async () =>
  api.get('/locations/geocode/popular-cities-for-autocomplete-v5/').then(({ data }) => data),
)

export const getCities = createAsyncThunk(
  'locations/getCities',
  async ({ query, countries = [] }: { query: string; countries?: string[] }) => {
    const requestTimestamp = Date.now()
    const response = await api.get('/locations/geocode/search/', {
      params: { query, countries_filter: countries.join(',') },
    })

    return { requestTimestamp, cities: keysToCamelCase(response.data) }
  },
)

export const geocode = createAsyncThunk(
  'locations/geocode',
  async (payload: { address: string; citiesOnly: boolean; countries?: string[] }) => {
    const { address, citiesOnly, countries = [] } = payload
    const response = await api.get('/locations/api/here-map-geocode-proxy/', {
      params: { query: encodeURI(address) },
    })
    // We use HereMaps for our location api, this is a link to the api docs:
    // https://developer.here.com/documentation/geocoding-search-api/dev_guide/topics/result-types.html

    if (citiesOnly && countries.length)
      return response.data.items.filter(
        (item: HereMapLocation) =>
          item.resultType === 'locality' && countries.includes(item.address?.countryCode || ''),
      )
    if (citiesOnly)
      return response.data.items.filter((item: HereMapLocation) => item.resultType === 'locality')
    if (countries.length)
      return response.data.items.filter((item: HereMapLocation) =>
        countries.includes(item.address?.countryCode || ''),
      )

    return response.data.items
  },
)

export const getAutocompleteLocations = createAsyncThunk(
  'locations/getAutocompleteLocations',
  async (query: string) =>
    api
      .get('/locations/location-front-end-autocomplete/', { params: { q: query } })
      .then(({ data }) => data.results),
)

// Compute truck route distance between origin and destination
export const getRouteDistance = createAsyncThunk(
  'locations/getRouteDistance',
  async ({
    origin,
    destination,
    stops = [],
  }: {
    origin: LatLng
    destination: LatLng
    stops?: LatLng[]
  }) => {
    const via = stops.reduce((v: string, stop: LatLng) => `${v}&via=${stop.lat},${stop.lng}`, '')
    const response = await api.get('/locations/api/here-map-route-proxy/', {
      params: {
        origin: `${origin.lat},${origin.lng}`,
        destination: `${destination.lat},${destination.lng}${via}`,
      },
    })

    // Sum distance of sections
    const distance = response.data.routes.reduce(
      (total: number, route: any) =>
        total +
        route.sections.reduce(
          (routeTotal: number, section: any) =>
            routeTotal + Math.round(section.summary.length / 1609.344),
          0,
        ),
      0,
    )

    const segments = response.data.routes
      .map((route: { sections: Array<any> }) =>
        route.sections.map(section => ({
          distance: Math.round(section.summary.length / 1609.344),
          polyline: section.polyline,
          origin: section.departure.place.location,
          destination: section.arrival.place.location,
        })),
      )
      .flat()

    // Meters to miles
    return { distance, segments }
  },
)

const locationsSlice = createSlice({
  name: 'locations',
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(geocode.pending, state => {
        state.loading.locations = true
      })
      .addCase(geocode.fulfilled, (state, { payload }) => {
        state.loading.locations = false
        state.locations = payload
      })
      .addCase(geocode.rejected, state => {
        state.loading.locations = false
      })
      .addCase(getCities.pending, state => {
        state.loading.cities = true
      })
      .addCase(getCities.fulfilled, (state, { payload }) => {
        const { requestTimestamp, cities } = payload
        // NOTE: this request is intentionally un-debounced for a more responsive feel
        // To handle race conditions - we store/compare timestamps from when the request was started
        if (!state.requestTimestamps.cities || requestTimestamp > state.requestTimestamps.cities) {
          state.loading.cities = false
          state.cities = cities
          state.requestTimestamps.cities = requestTimestamp
        }
      })
      .addCase(getCities.rejected, state => {
        state.loading.cities = false
      })
      .addCase(getPopularCities.pending, state => {
        state.loading.cities = true
      })
      .addCase(getPopularCities.fulfilled, (state, { payload }) => {
        state.loading.cities = false
        state.popularCities = payload
      })
      .addCase(getPopularCities.rejected, state => {
        state.loading.cities = false
      })
      .addCase(getRouteDistance.pending, state => {
        state.loading.routeDistance = true
        state.routeDistance = 0
        state.routeSegments = []
      })
      .addCase(getRouteDistance.fulfilled, (state, { payload }) => {
        const { distance, segments } = payload
        state.loading.routeDistance = false
        state.routeDistance = distance
        state.routeSegments = segments
      })
      .addCase(getRouteDistance.rejected, state => {
        state.loading.routeDistance = false
        state.routeDistance = 0
        state.routeSegments = []
      })
      .addCase(getAutocompleteLocations.fulfilled, (state, { payload }) => {
        state.autocompleteLocations = keysToCamelCase(payload)
      })
  },
})

export default locationsSlice.reducer
