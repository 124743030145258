import { ConfirmationDialog } from '@components'
import { useState } from 'react'
import tw from 'tailwind-styled-components'

import { useAppSelector, useAppThunkDispatch } from '../../../app/hooks'
import { CustomerDocument } from '../../../common/types'
import { displayDateTime } from '../../../common/utils'
import { deleteCustomerDocument } from '../../../redux/customersSlice'
import { MoreDropdown } from '../../MoreDropdown'
import { DocumentModal } from './DocumentModal'

export const ProfileDocument = ({ document }: { document: CustomerDocument }) => {
  const [isEditModalVisible, setEditModalVisible] = useState(false)
  const [isDeleteModalVisible, setDeleteModalVisible] = useState(false)

  const dispatch = useAppThunkDispatch()

  const deleteLoading = useAppSelector(state => state.customers.loading.deleteDocument)

  const deleteDocument = () =>
    dispatch(deleteCustomerDocument(document.id))
      .unwrap()
      .then(() => setDeleteModalVisible(false))
      .catch(() => {})

  const viewDocument = () => window.open(document.file as string, '_blank')

  return (
    <>
      <div className='flex justify-between items-center'>
        <TD className='font-semibold cursor-pointer' onClick={viewDocument}>
          {document.name}
        </TD>
        <TD>
          <div>{displayDateTime(document.updatedAt)}</div>
          <div className='text-[10px]'>{document.uploadedByDisplay}</div>
        </TD>
        <TD className='flex justify-end'>
          <MoreDropdown
            dropdownClassName='right-5'
            items={[
              { label: 'View', onClick: viewDocument },
              {
                label: 'Edit',
                onClick: () => setEditModalVisible(true),
              },
              { label: 'Delete', onClick: () => setDeleteModalVisible(true) },
            ]}
          />
        </TD>
      </div>
      <ConfirmationDialog
        isVisible={isDeleteModalVisible}
        setVisible={setDeleteModalVisible}
        confirmButtonType='danger'
        confirmButtonText='Yes, delete'
        confirmButtonLoading={deleteLoading}
        title={`Are you sure you want to delete ${document.name}?`}
        onConfirm={deleteDocument}
      />
      <DocumentModal
        editMode
        document={document}
        isVisible={isEditModalVisible}
        setVisible={setEditModalVisible}
      />
    </>
  )
}

const TD = tw.div`
  w-1/4
  mb-4
`
