import { useMemo } from 'react'
import tw from 'tailwind-styled-components'

import { useAppSelector } from '../../../../app/hooks'
import { DBQuoteStop } from '../../../../common/types'
import { quoteHasCommodity } from '../../../../common/utils'
import { DetailItem } from '../../../DetailItem'

const Stops = ({ stops }: { stops: Array<DBQuoteStop> }) => (
  <>
    {stops.map((stop: DBQuoteStop, i: number) => (
      <div key={stop.id}>
        <Label>
          {stop.stopType} #{i + 1}
        </Label>
        {stop.city}, {stop.state}
      </div>
    ))}
  </>
)

// This banner is displayed in the load creation modal when user is creating a load from a quote
export const QuoteBanner = () => {
  const quote = useAppSelector(state => state.customerQuotes.quoteDetails)

  const pickupStops = useMemo(
    () =>
      quote.manualquotestopsSet.filter(
        (stop: DBQuoteStop) => stop.stopType.toLowerCase() === 'pickup',
      ),
    [quote.manualquotestopsSet],
  )
  const dropoffStops = useMemo(
    () =>
      quote.manualquotestopsSet.filter(
        (stop: DBQuoteStop) => stop.stopType.toLowerCase() !== 'pickup',
      ),
    [quote.manualquotestopsSet],
  )

  return (
    <BannerContainer>
      <div className='uppercase font-semibold mb-2'>Quote Details #{quote.id}</div>
      <div className='grid grid-cols-5 gap-y-3'>
        <div>
          <Label>Origin</Label>
          <div>
            {quote.originCity}, {quote.originState}
          </div>
        </div>
        <Stops stops={pickupStops} />
        <Stops stops={dropoffStops} />
        <div>
          <Label>Destination</Label>
          <div>
            {quote.destinationCity}, {quote.destinationState}
          </div>
        </div>
      </div>
      {quoteHasCommodity(quote) && (
        <>
          <Label className='mt-3'>Commodity</Label>
          <QuoteGrid>
            <DetailItem label='Name'>{quote.commodity}</DetailItem>
            <DetailItem label='Quantity'>{quote.pieces}</DetailItem>
            <DetailItem label='Total Weight'>{quote.weight}</DetailItem>
            <DetailItem label='Total Length'>{quote.length}</DetailItem>
            <DetailItem label='Total Width'>{quote.width}</DetailItem>
            <DetailItem label='Total Height'>{quote.height}</DetailItem>
          </QuoteGrid>
          <DetailItem label='Description'>{quote.description}</DetailItem>
        </>
      )}
      {quote.notes && (
        <>
          <Label className='mt-3'>Notes</Label>
          {quote.notes}
        </>
      )}
    </BannerContainer>
  )
}

const QuoteGrid = tw.div`
  grid
  gap-x-4
  mb-2
  grid-cols-3
`

const BannerContainer = tw.div`
  bg-light-blue
  border-border-gray
  rounded-lg
  p-4
`

const Label = tw.div`
  text-dark-gray
  font-semibold
  mb-2
  capitalize
`
