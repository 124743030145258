import { CheckIcon } from '@heroicons/react/24/solid'
import tw from 'tailwind-styled-components'

import { WizardStep } from '../../../common/types'

export const Step = ({
  step,
  isActive,
  isCompleted,
  index,
  setCurrentStep,
  isChecked,
}: {
  step: WizardStep
  isActive: boolean
  isCompleted: boolean
  isChecked: boolean
  index: number
  setCurrentStep: (step: number) => void
}) => (
  <StepContainer
    $active={isActive}
    $completed={isCompleted}
    $checked={isChecked}
    onClick={() => isCompleted && setCurrentStep(index)}
  >
    <div className='flex items-center'>
      <Counter $checked={isChecked}>
        {isChecked ? <CheckIcon className='w-3' /> : index + 1}
      </Counter>
      <StepLabel $active={isActive}>
        {step.label} {step.required && <span className='text-error'>*</span>}
      </StepLabel>
    </div>
    {step.required && <RequiredLabel>required</RequiredLabel>}
  </StepContainer>
)

const StepContainer = tw.div<{ $active: boolean; $completed: boolean; $checked: boolean }>`
  flex
  p-4
  border-b
  border-light-gray
  last:border-none
  relative
  bg-lighter-blue
  h-16
  ${({ $active }) => $active && 'bg-white'}
  ${({ $checked, $active }) => $checked && !$active && 'bg-lighter-green'}
  ${({ $completed }) => $completed && 'cursor-pointer'}
`

const StepLabel = tw.div<{ $active: boolean }>`
  ${({ $active }) => $active && 'font-bold'}
`

const RequiredLabel = tw.span`
  absolute
  right-2
  top-1
  text-[10px]
  text-dark-gray
  italic
`

const Counter = tw.div<{ $checked: boolean }>`
  bg-dark-blue
  rounded-full
  w-4
  min-w-4
  h-4
  mr-4
  flex
  items-center
  justify-center
  text-[10px]
  text-white
  font-semibold
  ${({ $checked }) => $checked && 'bg-success'}
`
