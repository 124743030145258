import { ConfirmationDialog, Form, Link, TextInput } from '@components'
import { useCallback, useEffect, useMemo, useState } from 'react'
import tw from 'tailwind-styled-components'

import { useAppSelector, useAppThunkDispatch } from '../../../app/hooks'
import { ContractLaneCarrier } from '../../../common/types/contractLane'
import { formatCurrency } from '../../../common/utils'
import {
  deleteContractLaneCarrier,
  updateContractLaneCarrier,
} from '../../../redux/contractLanesSlice'
import { setSelectedCarrier, setSelectedContact } from '../../../redux/loadsSlice'
import { IconButton } from '../../Button'
import { EditCarrierForm } from '../../Forms'
import { CarrierView } from './CarrierView'

export const Carrier = ({ carrier, index }: { carrier: ContractLaneCarrier; index: number }) => {
  const [carrierRate, setCarrierRate] = useState('')
  const [isFormValid, setFormValid] = useState(false)

  const [isEditing, setEditing] = useState(false)
  const [isModalVisible, setModalVisible] = useState(false)

  const dispatch = useAppThunkDispatch()

  const selectedCarrier = useAppSelector(state => state.loads.selectedCarrier)
  const selectedContact = useAppSelector(state => state.loads.selectedContact)
  const deleteLoading = useAppSelector(
    state => state.contractLane.loading.deleteContractLaneCarrier,
  )
  const updateLoading = useAppSelector(
    state => state.contractLane.loading.updateContractLaneCarrier,
  )
  const carriers = useAppSelector(state => state.contractLane.contractLaneCarriers)

  const changesMade = useMemo(
    () =>
      selectedCarrier.id !== carrier.carrier.id ||
      selectedContact.id !== carrier.dispatchContact.id ||
      carrierRate !== carrier.carrierRate,
    [selectedCarrier, selectedContact, carrierRate],
  )

  useEffect(() => {
    setCarrierRate(carrier.carrierRate)
  }, [])

  const setSelectedCarrierData = () => {
    dispatch(setSelectedCarrier({ id: carrier.carrier.id, text: carrier.carrier.name }))
    dispatch(
      setSelectedContact({
        id: carrier.dispatchContact.id,
        name: carrier.dispatchContact.name,
        phone: carrier.dispatchContact.phone,
        email: carrier.dispatchContact.email,
        value: carrier.dispatchContact.name,
        label: carrier.dispatchContact.name,
      }),
    )
    setEditing(true)
  }

  const updateCarrier = () =>
    dispatch(updateContractLaneCarrier({ id: carrier.id, rate: carrierRate }))
      .unwrap()
      .then(() => setEditing(false))
      .catch(() => {})

  const Buttons = useCallback(
    () => (
      <div className='flex gap-4'>
        {isEditing ? (
          <>
            <IconButton
              icon='close'
              type='secondary'
              onClick={() => {
                setCarrierRate(carrier.carrierRate)
                setEditing(false)
              }}
            />
            <IconButton
              icon='check'
              type='success'
              disabled={
                !isFormValid ||
                !selectedCarrier?.id ||
                !selectedContact?.id ||
                updateLoading ||
                !changesMade
              }
              onClick={updateCarrier}
            />
          </>
        ) : (
          <>
            <IconButton icon='edit' type='secondary' onClick={setSelectedCarrierData} />
            <IconButton icon='delete' type='danger' onClick={() => setModalVisible(true)} />
          </>
        )}
      </div>
    ),
    [isEditing, isFormValid, selectedCarrier, selectedContact, carrierRate],
  )

  return (
    <Container>
      <Header>
        <div>
          {index + 1}. <Link to={`/carriers/${carrier.carrier.id}`}>{carrier.carrier.name}</Link>
        </div>
        <DividerBar />
        <div className='flex items-center justify-between'>
          <div>Rate {formatCurrency(carrier.carrierRate)}</div>
          <DividerBar className='min-w-8' />
          <Buttons />
        </div>
      </Header>
      {isEditing ? (
        <Form setFormValid={setFormValid}>
          <EditCarrierForm isContractLane showCarrierSelect={false} />
          <TextInput
            sm
            required
            value={carrierRate}
            label='Carrier Rate'
            className='mt-3'
            type='currency'
            onChange={setCarrierRate}
          />
        </Form>
      ) : (
        <CarrierView carrier={carrier} isFirst={!index} isLast={index === carriers.length - 1} />
      )}
      <ConfirmationDialog
        title={`Are you sure you want to delete contract carrier ${carrier.carrier.name}?`}
        isVisible={isModalVisible}
        setVisible={setModalVisible}
        confirmButtonType='danger'
        confirmButtonText='Yes, Delete'
        confirmButtonLoading={deleteLoading}
        onConfirm={() => dispatch(deleteContractLaneCarrier(carrier.id))}
      />
    </Container>
  )
}

const Container = tw.div`
  border
  border-border-gray
  rounded-lg
  p-4
  mt-4
  last:mb-5
`

const Header = tw.div`
  font-bold
  text-base
  flex
  items-center
  justify-between
  whitespace-nowrap
  mb-2
`

const DividerBar = tw.hr`
  w-full
  mx-4
  border-t
  border-light-blue
`
