import { CatchError, formatAxiosErrorToPayload, getErrorString } from '@common'
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { toast } from 'react-toastify'

import { api } from '../api/api'
import { initialFilters } from '../common/constants'
import {
  CarrierCallDisposition,
  CarrierLoadOffer,
  LoadQuote,
  RootState,
  SearchFilters,
} from '../common/types'
import { keysToCamelCase, keysToSnakeCase } from '../common/utils'
import { getLoadDetail } from './loadsSlice'

type QuotesState = {
  quotes: Array<LoadQuote>
  activeQuotes: Array<LoadQuote>
  declinedQuotes: Array<LoadQuote>
  selectedQuotes: Array<LoadQuote>
  pendingQuotes: Array<LoadQuote>
  loading: {
    quotes: boolean
    createQuote: boolean
    acceptOrDeclineQuote: boolean
    updateQuote: boolean
    deleteQuote: boolean
    sendSMSOffer: boolean
    updateOrCreateCarrierCallDisposition: boolean
    getCarrierCallDispositions: boolean
  }
  quoteCount: number
  loadOfferSMSCount: number
  offset: number
  size: number
  filters: SearchFilters
  currentQuote: CarrierLoadOffer
  hasAccepted: boolean
  loadOfferSMSList: Array<{
    loadId: number
    createdAt: string
    carrierCompany: {
      id: number
      name: string
    }
    delivered: boolean
    offerRate: number | null
  }>
  carrierCallDispositionList: Array<CarrierCallDisposition>
}

const initialState: QuotesState = {
  quotes: [],
  activeQuotes: [],
  declinedQuotes: [],
  selectedQuotes: [],
  pendingQuotes: [],
  loading: {
    quotes: false,
    createQuote: false,
    acceptOrDeclineQuote: false,
    updateQuote: false,
    deleteQuote: false,
    sendSMSOffer: false,
    updateOrCreateCarrierCallDisposition: false,
    getCarrierCallDispositions: false,
  },
  quoteCount: 0,
  loadOfferSMSCount: 0,
  offset: 0,
  size: 50,
  filters: initialFilters,
  currentQuote: { isNewCarrier: false },
  hasAccepted: false,
  loadOfferSMSList: [],
  carrierCallDispositionList: [],
}

const getQuotePayload = (quote: CarrierLoadOffer) =>
  quote.isNewCarrier
    ? {
        carrierBid: quote.carrierTotal,
        name: quote.carrierName,
        email: quote.email,
        phone: quote.phone,
        mcNumber: quote.mcNumber,
        dotNumber: quote.dotNumber,
        notes: quote.notes,
      }
    : {
        carrierBid: quote.carrierTotal,
        carrierId: quote.carrier?.id,
        notes: quote.notes,
      }

interface GetQuotesOptions {
  ordering?: string
}

export const getQuotes = createAsyncThunk(
  'carrierLoadOffers/getQuotes',
  async ({ ordering }: GetQuotesOptions = { ordering: '-created_at' }, { getState }) => {
    const { filters, size = 50, offset = 0 } = (getState() as RootState).carrierLoadOffers
    const response = await api.get('/quotes/api/list-quote/', {
      params: {
        limit: size,
        offset,
        carrier_company: filters.carrier || null,
        name__icontains: filters.name || null,
        equipment_type: filters.equipmentType || null,
        dot_number__icontains: filters.dotNumber || null,
        mc_number__icontains: filters.mcNumber || null,
        load_id: filters.loadId || null,
        load__shipper__city__icontains: filters.originCity || null,
        load__shipper__state_province_region__icontains: filters.originState || null,
        load__consignee__city__icontains: filters.destinationCity || null,
        load__consignee__state_province_region__icontains: filters.destinationState || null,
        created_by: filters.createdBy || null,
        quote_status__in: filters.quoteStatus?.join(',') || null,
        ordering: ordering,
      },
    })

    return keysToCamelCase(response.data)
  },
)

export const createQuote = createAsyncThunk(
  'carrierLoadOffers/createQuote',
  async (_, { getState, dispatch, rejectWithValue }) => {
    const {
      loads: {
        loadDetails: { id },
      },
      carrierLoadOffers: { currentQuote },
    } = getState() as RootState

    const payload = getQuotePayload(currentQuote)

    try {
      await api.post(`/quotes/api/carrier-bid-load/${id}/`, keysToSnakeCase(payload))
      dispatch(getQuotes({}))
    } catch (err: CatchError) {
      return rejectWithValue(formatAxiosErrorToPayload(err))
    }
  },
)

export const updateQuote = createAsyncThunk(
  'carrierLoadOffers/updateQuote',
  async (quoteId: number, { getState, dispatch, rejectWithValue }) => {
    const { currentQuote } = (getState() as RootState).carrierLoadOffers

    const payload = getQuotePayload(currentQuote)

    try {
      await api.patch(`/quotes/api/quote-ud/${quoteId}/`, keysToSnakeCase(payload))
      dispatch(getQuotes({}))
    } catch (err: CatchError) {
      return rejectWithValue(formatAxiosErrorToPayload(err))
    }
  },
)

export const toggleSelectQuote = createAsyncThunk(
  'carrierLoadOffers/toggleSelectQuote',
  async (
    { quoteId, shortlisted }: { quoteId: number; shortlisted: boolean },
    { dispatch, rejectWithValue },
  ) => {
    try {
      await api.patch(`/quotes/api/quote-ud/${quoteId}/`, { shortlisted: !shortlisted })
      dispatch(getQuotes({}))
      return shortlisted
    } catch (err: CatchError) {
      return rejectWithValue({ error: err.response.data, shortlisted })
    }
  },
)

export const deleteQuote = createAsyncThunk(
  'carrierLoadOffers/deleteQuote',
  async (quoteId: number, { dispatch, rejectWithValue }) => {
    try {
      await api.delete(`/quotes/api/quote-ud/${quoteId}/`)
      dispatch(getQuotes({}))
    } catch (err: CatchError) {
      return rejectWithValue(formatAxiosErrorToPayload(err))
    }
  },
)

export const acceptOrDeclineQuote = createAsyncThunk(
  'carrierLoadOffers/acceptOrDeclineQuote',
  async (
    { quoteId, accepted }: { quoteId: number; accepted: boolean },
    { getState, dispatch, rejectWithValue },
  ) => {
    const { loadDetails } = (getState() as RootState).loads

    try {
      const response = await api.post(
        '/quotes/api/carrier-quote-acceptance/',
        keysToSnakeCase({
          quoteId,
          accepted,
        }),
      )
      dispatch(getQuotes({}))
      dispatch(getLoadDetail(loadDetails.id))
      return response.data
    } catch (err: CatchError) {
      return rejectWithValue({ error: err.response.data, accepted })
    }
  },
)

export const acceptOrDeclineQuoteForNonExistentCarrier = createAsyncThunk(
  'carrierLoadOffers/acceptOrDeclineQuoteForNonExistentCarrier',
  async (
    { quoteId, email = '', accepted }: { quoteId: number; email?: string; accepted: boolean },
    { dispatch, rejectWithValue },
  ) => {
    try {
      const response = await api.post(
        '/quotes/api/carrier-quote-acceptance-invitation/',
        keysToSnakeCase({
          quoteId,
          accepted,
        }),
      )
      dispatch(getQuotes({}))
      return { message: response.data, email, accepted }
    } catch (err: CatchError) {
      return rejectWithValue({ error: err.response.data, accepted })
    }
  },
)

export const getSMSOffers = createAsyncThunk(
  'carrierLoadOffers/getSMSOffers',
  async (payload: { loadId: number }, { getState }) => {
    const { size = 50, offset = 0 } = (getState() as RootState).carrierLoadOffers

    const response = await api.get('/carrier-matching/api/list-load-offer-sms/', {
      params: {
        limit: size,
        offset,
        ordering: '-created_at',
        load_id: payload.loadId,
      },
    })
    return response.data
  },
)

export const sendSMSOffer = createAsyncThunk(
  'carrierLoadOffers/sendSMSOffer',
  async (payload: { loadId: number; carrierCompanyId: number }, { rejectWithValue }) => {
    try {
      const response = await api.post('/carrier-matching/api/send-load-offer-sms/', {
        load_id: payload.loadId,
        carrier_company_id: payload.carrierCompanyId,
      })

      return response.data
    } catch (err: CatchError) {
      return rejectWithValue(formatAxiosErrorToPayload(err))
    }
  },
)

export const getCarrierCallDispositions = createAsyncThunk(
  'carrierLoadOffers/getCarrierCallDispositions',
  async (payload: { loadId: number }, { rejectWithValue }) => {
    try {
      const response = await api.get('/carrier-matching/carrier-call-dispositions', {
        params: { load_id: payload.loadId },
      })
      return keysToCamelCase(response.data)
    } catch (err: CatchError) {
      return rejectWithValue(formatAxiosErrorToPayload(err))
    }
  },
)

export const updateOrCreateCarrierCallDisposition = createAsyncThunk(
  'carrierLoadOffers/updateOrCreateCarrierCallDisposition',
  async (payload: Partial<CarrierCallDisposition>, { rejectWithValue }) => {
    try {
      let response = null
      if (payload.id)
        response = await api.patch(
          `/carrier-matching/carrier-call-dispositions/${payload.id}/`,
          keysToSnakeCase(payload),
        )
      else
        response = await api.post(
          '/carrier-matching/carrier-call-dispositions/',
          keysToSnakeCase(payload),
        )
      return keysToCamelCase(response.data)
    } catch (err: CatchError) {
      return rejectWithValue(formatAxiosErrorToPayload(err))
    }
  },
)

const carrierLoadOffersSlice = createSlice({
  name: 'carrierLoadOffers',
  initialState,
  reducers: {
    setSize(state, { payload }) {
      state.size = payload
    },
    setOffset(state, { payload }) {
      state.offset = payload
    },
    setFilters(state, { payload }) {
      state.filters = payload
    },
    setCurrentQuote(state, { payload }) {
      state.currentQuote = payload
    },
    resetLoadOfferSMSList(state) {
      state.loadOfferSMSList = []
      state.loadOfferSMSCount = 0
    },
    handleDeliveredLoadOfferSMS(state, { payload }) {
      const { loadId, carrierCompanyId } = payload
      state.loadOfferSMSList = state.loadOfferSMSList.map(loadOfferSMS =>
        loadOfferSMS.loadId === loadId && loadOfferSMS.carrierCompany.id === carrierCompanyId
          ? {
              ...loadOfferSMS,
              delivered: true,
            }
          : loadOfferSMS,
      )
    },
    resetCarrierCallDispositionList(state) {
      state.carrierCallDispositionList = []
    },
  },
  extraReducers(builder) {
    builder
      .addCase(getQuotes.pending, state => {
        state.loading.quotes = true
      })
      .addCase(getQuotes.fulfilled, (state, { payload }) => {
        const { count, results } = payload
        const acceptedStatuses = [2, 6]
        const declinedStatuses = [3, 5]
        const allQuotes = (results as LoadQuote[])
          .filter(quote => !quote.archived && !quote.expired)
          .map(quote => ({ ...quote, quoteStatusDisplay: quote.quoteStatusDisplay.toLowerCase() }))
        const quotes = allQuotes.filter(quote => !quote.shortlisted)
        const accepted = allQuotes.filter(quote => acceptedStatuses.includes(quote.quoteStatus))
        const hasAccepted = !!accepted.length
        state.activeQuotes = accepted
        state.declinedQuotes = allQuotes
          .filter(
            quote =>
              (hasAccepted && !acceptedStatuses.includes(quote.quoteStatus)) ||
              (!hasAccepted && declinedStatuses.includes(quote.quoteStatus)),
          )
          .map(quote => ({
            ...quote,
            quoteStatusDisplay: 'declined',
            quoteStatus: 5,
          }))
        state.pendingQuotes = hasAccepted ? [] : quotes.filter(quote => quote.quoteStatus === 1)
        state.selectedQuotes = hasAccepted
          ? []
          : allQuotes
              .filter(quote => quote.shortlisted && !acceptedStatuses.includes(quote.quoteStatus))
              .map(quote => ({ ...quote, quoteStatusDisplay: 'selected' }))
        state.loading.quotes = false
        state.quotes = allQuotes
        state.hasAccepted = hasAccepted
        state.quoteCount = count
      })
      .addCase(getQuotes.rejected, state => {
        state.loading.quotes = false
        toast.error('Failed to get quotes')
      })
      .addCase(createQuote.pending, state => {
        state.loading.createQuote = true
      })
      .addCase(createQuote.fulfilled, state => {
        toast.success('Successfully created quote')
        state.loading.createQuote = false
      })
      .addCase(createQuote.rejected, (state, { payload }) => {
        state.loading.createQuote = false
        toast.error(getErrorString(payload, 'Failed to create quote'))
      })
      .addCase(updateQuote.pending, state => {
        state.loading.updateQuote = true
      })
      .addCase(updateQuote.fulfilled, state => {
        state.loading.updateQuote = false
        toast.success('Successfully updated quote')
      })
      .addCase(updateQuote.rejected, (state, { payload }) => {
        state.loading.updateQuote = false
        toast.error(getErrorString(payload, 'Failed to update quote'))
      })
      .addCase(toggleSelectQuote.pending, state => {
        state.loading.acceptOrDeclineQuote = true
      })
      .addCase(toggleSelectQuote.fulfilled, (state, { payload }) => {
        state.loading.acceptOrDeclineQuote = false
        toast.success(`Successfully ${payload ? 'unselected' : 'selected'} quote`)
      })
      .addCase(toggleSelectQuote.rejected, (state, { payload }: any) => {
        const { error, shortlisted } = payload

        state.loading.acceptOrDeclineQuote = false
        toast.error(getErrorString(error, `Failed to ${shortlisted ? 'unselect' : 'select'} quote`))
      })
      .addCase(deleteQuote.pending, state => {
        toast.success('Successfully deleted quote')
        state.loading.deleteQuote = true
      })
      .addCase(deleteQuote.fulfilled, state => {
        state.loading.deleteQuote = false
      })
      .addCase(deleteQuote.rejected, (state, { payload }) => {
        state.loading.deleteQuote = false
        toast.error(getErrorString(payload, 'Failed to delete quote'))
      })
      .addCase(acceptOrDeclineQuote.pending, state => {
        state.loading.acceptOrDeclineQuote = true
      })
      .addCase(acceptOrDeclineQuote.fulfilled, (state, { payload }) => {
        state.loading.acceptOrDeclineQuote = false
        toast.success(payload)
      })
      .addCase(acceptOrDeclineQuote.rejected, (state, { payload }: any) => {
        const { error, accepted } = payload
        state.loading.acceptOrDeclineQuote = false
        toast.error(getErrorString(error, `Failed to ${accepted ? 'accept' : 'decline'} quote`))
      })
      .addCase(acceptOrDeclineQuoteForNonExistentCarrier.pending, state => {
        state.loading.acceptOrDeclineQuote = true
      })
      .addCase(acceptOrDeclineQuoteForNonExistentCarrier.fulfilled, (state, { payload }) => {
        const { email, accepted, message } = payload
        state.loading.acceptOrDeclineQuote = false
        toast.success(accepted ? `An onboarding link was sent to this email ${email}` : message)
      })
      .addCase(acceptOrDeclineQuoteForNonExistentCarrier.rejected, (state, { payload }: any) => {
        const { error, accepted } = payload
        state.loading.acceptOrDeclineQuote = false
        toast.error(
          getErrorString(
            error,
            accepted ? 'Failed to decline quote' : 'Failed to accept quote and invite carrier',
          ),
        )
      })
      .addCase(getSMSOffers.fulfilled, (state, action) => {
        const { count, results } = action.payload
        state.loadOfferSMSList = keysToCamelCase(results)
        state.loadOfferSMSCount = count
      })
      .addCase(getSMSOffers.rejected, () => {
        toast.error('Failed to get SMS Offers')
      })
      .addCase(sendSMSOffer.pending, state => {
        state.loading.sendSMSOffer = true
      })
      .addCase(sendSMSOffer.fulfilled, (state, action) => {
        const loadOfferSMS = action.payload
        state.loadOfferSMSList = [keysToCamelCase(loadOfferSMS), ...state.loadOfferSMSList]
        state.loadOfferSMSCount = state.loadOfferSMSCount + 1
        state.loading.sendSMSOffer = false
        toast.success('SMS offer sent.')
      })
      .addCase(sendSMSOffer.rejected, (state, action) => {
        toast.error(getErrorString(action.payload, 'Failed to send SMS offer.'))
        state.loading.sendSMSOffer = false
      })
      .addCase(getCarrierCallDispositions.pending, state => {
        state.loading.getCarrierCallDispositions = true
      })
      .addCase(getCarrierCallDispositions.fulfilled, (state, action) => {
        state.carrierCallDispositionList = action.payload
        state.loading.getCarrierCallDispositions = false
      })
      .addCase(getCarrierCallDispositions.rejected, (state, action) => {
        state.carrierCallDispositionList = []
        state.loading.getCarrierCallDispositions = false
        toast.error(getErrorString(action.payload, 'Failed to lookup carrier call notes.'))
      })
      .addCase(updateOrCreateCarrierCallDisposition.pending, state => {
        state.loading.updateOrCreateCarrierCallDisposition = true
      })
      .addCase(updateOrCreateCarrierCallDisposition.fulfilled, (state, action) => {
        state.loading.updateOrCreateCarrierCallDisposition = false
        const savedCallDisposition: CarrierCallDisposition = action.payload
        const callIndex = state.carrierCallDispositionList.findIndex(
          carrierCallDisposition => carrierCallDisposition.id === savedCallDisposition.id,
        )
        if (callIndex === -1) {
          state.carrierCallDispositionList.unshift(savedCallDisposition)
        } else {
          state.carrierCallDispositionList[callIndex] = savedCallDisposition
        }
        toast.success('Call notes saved successfully.')
      })
      .addCase(updateOrCreateCarrierCallDisposition.rejected, (state, action) => {
        state.loading.updateOrCreateCarrierCallDisposition = false
        toast.error(getErrorString(action.payload, 'Failed to save call notes.'))
      })
  },
})

export const {
  setSize,
  setOffset,
  setFilters,
  setCurrentQuote,
  resetLoadOfferSMSList,
  handleDeliveredLoadOfferSMS,
  resetCarrierCallDispositionList,
} = carrierLoadOffersSlice.actions

export default carrierLoadOffersSlice.reducer
