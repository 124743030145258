import { dateDiff } from '@common'
import { useEffect, useMemo } from 'react'
import { useState } from 'react'
import tw from 'tailwind-styled-components'

import { useAppSelector } from '../../app/hooks'
import { carrierCallOutcomeChoices } from '../../common/constants'
import { displayDateTime, formatPhone } from '../../common/utils'
import { CarrierCallDispositionModal } from '../QuotingTool/CarrierCallDispositionModal'
import { SelectedCarrier } from '../QuotingTool/CarriersList'

const getOutcomeLabel = (callOutcome: string) => {
  const outcomeObject = carrierCallOutcomeChoices.find(choice => choice.value === callOutcome)
  return outcomeObject ? outcomeObject.label : callOutcome
}

export const CallNotesList = () => {
  const carrierCallDispositionList = useAppSelector(
    state => state.carrierLoadOffers.carrierCallDispositionList,
  )
  const [isCallModalVisible, setCallModalVisible] = useState(false)
  const [selectedCarrier, setSelectedCarrier] = useState<SelectedCarrier>({})

  const sortedCarrierCallDispositionList = useMemo(
    () =>
      [...carrierCallDispositionList].sort((a, b) =>
        dateDiff(new Date(b.updatedAt), new Date(a.updatedAt)),
      ),
    [carrierCallDispositionList],
  )

  useEffect(() => {
    if (!isCallModalVisible) setSelectedCarrier({})
  }, [isCallModalVisible])

  return (
    <>
      <Row className='border-none pb-0 font-bold'>
        <div className='w-2/12 ml-4'>Call Outcome</div>
        <div className='w-7/12'>Note</div>
        <div className='w-3/12'>Carrier</div>
      </Row>
      <Container>
        <div>
          {sortedCarrierCallDispositionList.map(item => (
            <Row key={item.id}>
              <OutcomeColumn>{getOutcomeLabel(item.callOutcome)}</OutcomeColumn>
              <NoteColumn>
                {item.callNotes}
                <AgentInfo>
                  {item.agentUser.name} {displayDateTime(item.updatedAt)}
                  <span
                    className='text-link font-bold cursor-pointer ml-3'
                    onClick={() => {
                      setSelectedCarrier({
                        ...(item.carrierCompanyId !== null && {
                          carrierCompanyId: item.carrierCompanyId,
                        }),
                        ...(item.carrierName !== null && { carrierName: item.carrierName }),
                        ...(item.mcNumber !== null && { mcNumber: item.mcNumber }),
                        ...(item.dotNumber !== null && { dotNumber: item.dotNumber }),
                      })
                      setCallModalVisible(true)
                    }}
                  >
                    Edit
                  </span>
                </AgentInfo>
              </NoteColumn>
              <CarrierColumn>
                {!item.carrierCompanyId ? (
                  <>
                    <div>{item.carrierName || ''}</div>
                    <div>
                      MC {item.mcNumber ? item.mcNumber : 'N/A'} | DOT{' '}
                      {item.dotNumber ? item.dotNumber : 'N/A'}
                    </div>
                  </>
                ) : (
                  <>
                    <a
                      className='text-link font-bold'
                      href={`/carriers/${item.carrierCompanyId}`}
                      target='_blank'
                      rel='noreferrer'
                    >
                      {item.carrierCompany?.name || ''}
                    </a>
                    <div>
                      MC {item.carrierCompany?.mcNumber ? item.carrierCompany?.mcNumber : 'N/A'} |
                      DOT {item.carrierCompany?.dotNumber ? item.carrierCompany?.dotNumber : 'N/A'}
                    </div>
                    <div>{item.carrierCompany?.contactName || ''}</div>
                    {item.carrierCompany?.contactPhone && (
                      <a
                        className='text-gray-500 cursor-pointer'
                        href={`tel:${item.carrierCompany.contactPhone}`}
                      >
                        {formatPhone(item.carrierCompany.contactPhone)}
                      </a>
                    )}
                  </>
                )}
              </CarrierColumn>
            </Row>
          ))}
        </div>
      </Container>
      <CarrierCallDispositionModal
        isVisible={isCallModalVisible}
        setVisible={setCallModalVisible}
        {...selectedCarrier}
      />
    </>
  )
}

const Container = tw.div`
    border
    border-gray-300
    rounded-lg
    m-4
`

const Row = tw.div`
    flex 
    flex-row 
    w-full 
    last:border-b-0 
    border-b 
    border-gray-300 
    p-4
`

const OutcomeColumn = tw.div`
    flex 
    items-center 
    w-2/12 
    font-bold
`

const NoteColumn = tw.div`
    p-4 
    w-7/12 
    bg-lighter-blue 
    rounded-lg 
    relative 
    pb-8
`

const CarrierColumn = tw.div`
    w-3/12 
    px-1 
    items-center 
    justify-center 
    flex 
    flex-col 
    space-y-1
`

const AgentInfo = tw.div`
    text-gray-500 
    text-xs 
    absolute 
    right-3 
    bottom-3
`
