import { Button, FileInput, Select, TextInput } from '@components'
import tw from 'tailwind-styled-components'

import {
  financeDocumentTypeChoices,
  freightDocumentTypeChoices,
  freightOrFinanceChoices,
} from '../../common/constants'
import { Document, LocalDocument } from '../../common/types'

type CarrierDocumentFormProps = {
  formData: LocalDocument | Document
  setFormData: (field: string, value: string | File | number | null) => void
  isFactoring?: boolean
}

export const CarrierDocumentForm = ({ formData, setFormData }: CarrierDocumentFormProps) => (
  <>
    <TextInput
      required
      sm
      className='mb-4'
      label='Name'
      value={formData.name}
      onChange={(value: string) => setFormData('name', value)}
    />
    <Select
      sm
      required
      choices={freightOrFinanceChoices}
      isClearable={false}
      className='w-full mb-6'
      label='Freight or Finance'
      value={formData.newFreightOrFinance}
      disabled={formData.initialFreightOrFinance == 'finance'}
      onChange={({ value }: { value: string }) => {
        setFormData('newFreightOrFinance', value)
        value === 'freight' ? setFormData('documentType', 1) : setFormData('documentType', 'MISC')
      }}
    />
    <Select
      sm
      required
      className='w-full mb-6'
      label='Document Type'
      value={formData.documentType}
      choices={
        formData.newFreightOrFinance === 'finance'
          ? financeDocumentTypeChoices
          : freightDocumentTypeChoices
      }
      onChange={({ value }: { value: number | string }) => {
        setFormData('documentType', value)
      }}
    />
    {!((formData as Document).fileName || (formData as LocalDocument).file?.name) ? (
      <StyledFileInput>
        <ButtonWrapper>
          <span>Upload a new document</span>
          <FileInput
            mimeType='image/jpeg,image/png,image/bmp,image/webp,image/gif,application/pdf,application/vnd.openxmlformats-officedocument.wordprocessingml.document,application/msword,application/vnd.ms-excel,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,application/vnd.ms-excel.sheet.macroEnabled.12,text/csv'
            onChange={(value: File) => {
              setFormData('file', value)
              setFormData('fileName', value.name)
            }}
          >
            <Button preIcon='plus-circle' type='warn'>
              Upload
            </Button>
          </FileInput>
        </ButtonWrapper>
      </StyledFileInput>
    ) : (
      <FileWrapper>
        <ButtonWrapper>
          <div>
            {(formData as Document).fileName || (formData as LocalDocument).file?.name}
            <UploadedText>is succesfully uploaded.</UploadedText>
          </div>
          <Button
            type='primary'
            onClick={() => {
              setFormData('file', null)
              setFormData('fileName', '')
            }}
          >
            Delete
          </Button>
        </ButtonWrapper>
      </FileWrapper>
    )}
  </>
)

const StyledFileInput = tw.div`
  border-l-4
  rounded-l-md
  border-link
  bg-light-blue
  font-semibold
  items-center
  flex
  h-[96px]
`

const ButtonWrapper = tw.div`
  flex
  items-center
  w-[600px]
  justify-between
  p-4
  h-full
  border-light-blue
  border
`

const FileWrapper = tw.div`
  border-l-4
  rounded-l-md
  border-success
  font-semibold
  flex
  items-center
  h-[96px]
`

const UploadedText = tw.div`
  text-dark-gray
  text-sm
  font-normal
`
