import dayjs from 'dayjs'

/**
 * Convert CSV content to a file and download it on the user's computer.
 * This is used to download CSV files that are created/returned from the backend.
 * @param csvContent - The raw content of the CSV file in string format ('col1,col2,col3,...')
 * @param fileName - The name of the resulting CSV file to download content to
 */
export const downloadCSV = (csvContent: string, fileName: string) => {
  const csvData = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' })
  const link = document.createElement('a')
  if (link.download !== undefined) {
    const url = URL.createObjectURL(csvData)
    link.setAttribute('href', url)
    link.setAttribute('download', `${fileName}_${dayjs().format('YYYY-MM-DD-HH:mm')}`)
    link.style.visibility = 'hidden'
    document.body.appendChild(link)
    link.click()
    document.body.removeChild(link)
  }
}
