import { useAppSelector } from '../../../app/hooks'
import { useCustomerType } from '../../../common/utils'
import { AddNewNote, Note, SectionHeader } from '../components'

export const Notes = () => {
  const type = useCustomerType()

  const notes = useAppSelector(state => state.customers[type].notes)

  return (
    <>
      <SectionHeader>Notes</SectionHeader>
      <AddNewNote />
      {notes.map(note => (
        <Note key={note.id} note={note} />
      ))}
    </>
  )
}
