import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { cloneDeep, isEmpty } from 'lodash-es'
import { toast } from 'react-toastify'

import { api } from '../api/api'
import { Document, LocalDocument } from '../common/types'
import { GroupedDocuments, keysToCamelCase } from '../common/utils'

export const buildNewDocument = () => ({
  id: -1,
  name: '',
  file: null,
  isDocumentValid: false,
  documentType: '',
  initialFreightOrFinance: '' as const,
  newFreightOrFinance: 'freight' as const,
  freightOrFinance: 'freight' as const,
})

type DocumentsProps = {
  count: number
  newDocument: LocalDocument
  documents: Array<Document | LocalDocument>
  documentsBackup: Array<Document | LocalDocument>
  loading: {
    getDocuments: boolean
    saveDocument: boolean
    deleteDocument: boolean
  }
  documentViewer: {
    selectedDocumentIndex: number
    selectedFileIndex: number
    colorMap: Map<string, string>
    filesToCompare: Array<GroupedDocuments['files'][number]>
  }
}

const initialState: DocumentsProps = {
  count: 0,
  documents: [],
  documentsBackup: [],
  newDocument: buildNewDocument(),
  loading: {
    getDocuments: false,
    saveDocument: false,
    deleteDocument: false,
  },
  documentViewer: {
    selectedDocumentIndex: 0,
    selectedFileIndex: 0,
    colorMap: new Map(),
    filesToCompare: [],
  },
}

export const validateDocument = <T extends LocalDocument | Document>(document: T): T => ({
  ...document,
  isDocumentValid: !!(document.name.length && document?.file),
})

const makeDocumentGetter = <IDProp extends object>({
  actionName,
  idProp,
  endpoint,
}: {
  actionName: string
  idProp: keyof IDProp
  endpoint: string
}) =>
  createAsyncThunk(
    `documents/${actionName}`,
    async (payload: { offset: number; limit: number } & IDProp) => {
      const response = await api.get(`${endpoint}/${payload[idProp]}/`, {
        params: {
          offset: payload.offset,
          limit: payload.limit,
        },
      })
      const data = keysToCamelCase(response.data)
      data.results = data.results
        .filter((document: Document) => document.file)
        .map((document: Document) => ({
          ...document,
          isDocumentValid: true,
        }))
      return data
    },
  )

// Add a new document for a particular customer
const makeDocumentAdder = <IDProp extends object>({
  actionName,
  idProp,
  endpoint,
}: {
  actionName: string
  idProp: keyof IDProp
  endpoint: string
}) =>
  createAsyncThunk(
    `documents/${actionName}`,
    async (payload: { file: File; name: string } & IDProp) => {
      const formData = new FormData()
      formData.append('name', payload.name)
      formData.append('file', payload.file)
      const response = await api.post(`${endpoint}/${payload[idProp]}/`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      })

      const document = validateDocument(keysToCamelCase(response.data))
      return {
        ...document,
      }
    },
  )

const makeDocumentUpdater = ({ actionName, endpoint }: { actionName: string; endpoint: string }) =>
  createAsyncThunk(
    `documents/${actionName}`,
    async (payload: { documentId: number; file: File | string; name: string }) => {
      const formData = new FormData()
      formData.append('name', payload.name)
      if (typeof payload.file !== 'string') {
        formData.append('file', payload.file)
      }
      const response = await api.put(`${endpoint}/${payload.documentId}/`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      })

      const document = validateDocument(keysToCamelCase(response.data))
      return {
        ...document,
      }
    },
  )

const makeDocumentDeleter = ({ actionName, endpoint }: { actionName: string; endpoint: string }) =>
  createAsyncThunk(`documents/${actionName}`, async ({ documentId }: { documentId: number }) => {
    await api.delete(`${endpoint}/${documentId}/`)
    return documentId
  })

// Get list of customer documents for documents tab
export const getCustomerDocuments = makeDocumentGetter<{ customerId: string | number }>({
  actionName: 'getCustomerDocuments',
  idProp: 'customerId',
  endpoint: '/accounts/api/customer/document',
})

export const getLocationDocuments = makeDocumentGetter<{ locationId: string | number }>({
  actionName: 'getLocationDocuments',
  idProp: 'locationId',
  endpoint: '/locations/api/document',
})

export const addCustomerDocument = makeDocumentAdder<{ customerId: number }>({
  actionName: 'addCustomerDocument',
  idProp: 'customerId',
  endpoint: '/accounts/api/customer/document',
})

export const addLocationDocument = makeDocumentAdder<{ locationId: number }>({
  actionName: 'addLocationDocument',
  idProp: 'locationId',
  endpoint: '/locations/api/document',
})

// Update a particular customer document
export const updateCustomerDocument = makeDocumentUpdater({
  actionName: 'updateCustomerDocument',
  endpoint: '/accounts/api/customer/document-rud',
})

export const updateLocationDocument = makeDocumentUpdater({
  actionName: 'updateLocationDocument',
  endpoint: '/locations/api/document-rud',
})

// Delete a particular customer document
export const deleteCustomerDocument = makeDocumentDeleter({
  actionName: 'deleteCustomerDocument',
  endpoint: '/accounts/api/customer/document-rud',
})

export const deleteLocationDocument = makeDocumentDeleter({
  actionName: 'deleteLocationDocument',
  endpoint: '/locations/api/document-rud',
})

export const documentsSlice = createSlice({
  name: 'documents',
  initialState,
  reducers: {
    setDocument(state, { payload }) {
      if (payload.index === -1) {
        state.newDocument = validateDocument({
          ...state.newDocument,
          [payload.field]: payload.value,
        })
      } else {
        state.documents[payload.index] = validateDocument({
          ...state.documents[payload.index],
          [payload.field]: payload.value,
        })
      }
    },
    resetDocument(state, { payload }) {
      if (payload.index === -1) {
        state.newDocument = buildNewDocument()
      } else {
        state.documents = state.documents.map((document, i: number) => {
          if (i == payload.index) return cloneDeep(state.documentsBackup[payload.index])
          return document
        })
      }
    },
    setDocumentViewerIndexes(state, { payload }: { payload: [number, number] }) {
      state.documentViewer.selectedDocumentIndex = payload[0]
      state.documentViewer.selectedFileIndex = payload[1]
    },
    setDocumentViewerColorMap(state, { payload }: { payload: Map<string, string> }) {
      state.documentViewer.colorMap = payload
    },
    setFilesToCompare(state, { payload }) {
      state.documentViewer.filesToCompare = isEmpty(payload)
        ? []
        : state.documentViewer.filesToCompare.find(fileToCompare => fileToCompare.id === payload.id)
          ? state.documentViewer.filesToCompare.filter(
              fileToCompare => fileToCompare.id !== payload.id,
            )
          : [...state.documentViewer.filesToCompare, payload]
    },
  },
  extraReducers(builder) {
    builder
      .addCase(getCustomerDocuments.pending, state => {
        state.loading.getDocuments = true
      })
      .addCase(getCustomerDocuments.fulfilled, (state, action) => {
        const data = action.payload
        state.documents = data.results
        state.count = data.count
        state.documentsBackup = cloneDeep(state.documents)
        state.loading.getDocuments = false
      })
      .addCase(getCustomerDocuments.rejected, state => {
        state.loading.getDocuments = false
      })
      .addCase(getLocationDocuments.pending, state => {
        state.loading.getDocuments = true
      })
      .addCase(getLocationDocuments.fulfilled, (state, action) => {
        const data = action.payload
        state.documents = data.results
        state.count = data.count
        state.documentsBackup = cloneDeep(state.documents)
        state.loading.getDocuments = false
      })
      .addCase(getLocationDocuments.rejected, state => {
        state.loading.getDocuments = false
      })
      .addCase(addCustomerDocument.pending, state => {
        state.loading.saveDocument = true
      })
      .addCase(addCustomerDocument.fulfilled, (state, action) => {
        state.documents.unshift(action.payload)
        state.documentsBackup = cloneDeep(state.documents)
        state.loading.saveDocument = false
        toast.success('Successfully added new document')
      })
      .addCase(addCustomerDocument.rejected, state => {
        state.loading.saveDocument = false
        toast.error('Failed to save new document')
      })
      .addCase(addLocationDocument.pending, state => {
        state.loading.saveDocument = true
      })
      .addCase(addLocationDocument.fulfilled, (state, action) => {
        state.documents.unshift(action.payload)
        state.documentsBackup = cloneDeep(state.documents)
        state.loading.saveDocument = false
        toast.success('Successfully added new document')
      })
      .addCase(addLocationDocument.rejected, state => {
        state.loading.saveDocument = false
        toast.error('Failed to save new document')
      })
      .addCase(updateCustomerDocument.pending, state => {
        state.loading.saveDocument = true
      })
      .addCase(updateCustomerDocument.fulfilled, (state, action) => {
        const updatedDocument = action.payload
        state.documents = state.documents.map(document =>
          document.id === updatedDocument.id ? updatedDocument : document,
        )
        state.documentsBackup = cloneDeep(state.documents)
        state.loading.saveDocument = false
        toast.success('Successfully updated document')
      })
      .addCase(updateCustomerDocument.rejected, state => {
        state.loading.saveDocument = false
        toast.error('Failed to update document')
      })
      .addCase(updateLocationDocument.pending, state => {
        state.loading.saveDocument = true
      })
      .addCase(updateLocationDocument.fulfilled, (state, action) => {
        const updatedDocument = action.payload
        state.documents = state.documents.map(document =>
          document.id === updatedDocument.id ? updatedDocument : document,
        )
        state.documentsBackup = cloneDeep(state.documents)
        state.loading.saveDocument = false
        toast.success('Successfully updated document')
      })
      .addCase(updateLocationDocument.rejected, state => {
        state.loading.saveDocument = false
        toast.error('Failed to update document')
      })
      .addCase(deleteCustomerDocument.pending, state => {
        state.loading.deleteDocument = true
      })
      .addCase(deleteCustomerDocument.fulfilled, (state, action) => {
        const documentId = action.payload
        state.loading.deleteDocument = false
        state.documents = state.documents.filter(document => document.id != documentId)
        state.documentsBackup = cloneDeep(state.documents)
        toast.success('Successfully deleted document')
      })
      .addCase(deleteCustomerDocument.rejected, state => {
        state.loading.deleteDocument = false
        toast.error('Failed to delete document')
      })
      .addCase(deleteLocationDocument.pending, state => {
        state.loading.deleteDocument = true
      })
      .addCase(deleteLocationDocument.fulfilled, (state, action) => {
        const documentId = action.payload
        state.loading.deleteDocument = false
        state.documents = state.documents.filter(document => document.id != documentId)
        state.documentsBackup = cloneDeep(state.documents)
        toast.success('Successfully deleted document')
      })
      .addCase(deleteLocationDocument.rejected, state => {
        state.loading.deleteDocument = false
        toast.error('Failed to delete document')
      })
  },
})

export const {
  setDocument,
  resetDocument,
  setDocumentViewerIndexes,
  setDocumentViewerColorMap,
  setFilesToCompare,
} = documentsSlice.actions
export default documentsSlice.reducer
