import { Button, ButtonProps } from '@components'
import { TrashIcon } from '@heroicons/react/24/outline'
import { omit } from 'lodash-es'

export const DeleteBtn = (props: ButtonProps) => (
  <Button
    sm
    innerClassName='h-[24px] text-[8px] rounded-sm !bg-white !text-error !p-0 !border-error'
    {...omit(props, ['children'])}
  >
    <div className='flex gap-1 p-1.5 pb-1'>
      <div className='w-3 h-3'>
        <TrashIcon />
      </div>
      <span>{props.children}</span>
    </div>
  </Button>
)
