import { broadcastLogout } from '@common'
import axios from 'axios'
import Cookies from 'js-cookie'

// after every response, check if it's a 401, and if so, redirect to login
axios.interceptors.response.use(
  response => response,
  async error => {
    const authedRouteFailed =
      error.response?.status === 401 && !['/', '/echo-bravo-romeo'].includes(location.pathname)
    const csrfFailed = error.response?.status === 403 && !Cookies.get('csrftoken3')

    // log out if response is a 401, or if we're missing the CSRF token
    if (authedRouteFailed || csrfFailed) {
      broadcastLogout()
    }

    return Promise.reject(error)
  },
)

axios.interceptors.request.use(async config => {
  const ediURL = import.meta.env.VITE_EDI_URL as string
  if (ediURL && config.url?.startsWith(ediURL)) {
    const ediToken = `Token ${import.meta.env.VITE_EDI_AUTH_TOKEN}`
    config.headers.Authorization = ediToken
  }

  return config
})
