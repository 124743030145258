import { Button } from '@components'
import { useState } from 'react'
import { useParams } from 'react-router-dom'

import { useAppSelector, useAppThunkDispatch } from '../../../app/hooks'
import { CustomerNote } from '../../../common/types'
import { randomString, useCustomerType } from '../../../common/utils'
import { addCustomerNote, setCustomerInfo } from '../../../redux/customersSlice'
import { EditNote } from './EditNote'

export const AddNewNote = ({ className }: { className?: string }) => {
  const { id } = useParams()
  const type = useCustomerType()

  const [newNote, setNewNote] = useState<CustomerNote>({
    subject: '',
    note: '',
    id: randomString(),
  })

  const dispatch = useAppThunkDispatch()

  const notes = useAppSelector(state => state.customers[type].notes)
  const loading = useAppSelector(state => state.customers.loading.addNote)

  const addNewNoteLocal = () => {
    dispatch(setCustomerInfo({ notes: [{ ...newNote, id: randomString() }, ...notes] }))
    resetNote()
  }

  const addNewNoteDB = () =>
    dispatch(addCustomerNote({ note: newNote, customerId: id }))
      .unwrap()
      .then(() => resetNote())
      .catch(() => {})

  const addNewNote = () => {
    if (type === 'onboardingCustomer') addNewNoteLocal()
    else addNewNoteDB()
  }

  const resetNote = () => setNewNote({ subject: '', note: '', id: randomString() })

  return (
    <div className={className}>
      <EditNote note={newNote} setNote={setNewNote} />
      <div className='flex justify-center mt-4'>
        <Button type='warn' disabled={!newNote.note} loading={loading} onClick={addNewNote}>
          Add Note
        </Button>
      </div>
    </div>
  )
}
