import { CatchError, formatDateForBackend } from '@common'
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import dayjs from 'dayjs'
import { cloneDeep } from 'lodash-es'
import { toast } from 'react-toastify'

import { api } from '../api/api'
import { trackEvent } from '../common/tracking'
import { Load, Location, TrackingUpdate } from '../common/types'
import { formatDateTimeForBackend, keysToCamelCase, validateStateCode } from '../common/utils'

export const newTrackingUpdate = () => ({
  loadId: -1,
  loadStatus: 1,
  pickedAt: undefined,
  pickedAtTime: undefined,
  deliveredAt: undefined,
  deliveredAtTime: undefined,
  pickupApptAt: undefined,
  pickupApptAtTime: undefined,
  deliveryApptAt: undefined,
  deliveryApptAtTime: undefined,
  arrivedAtShipperAt: undefined,
  arrivedAtShipperAtTime: undefined,
  arrivedAtConsigneeAt: undefined,
  arrivedAtConsigneeAtTime: undefined,
  onTimePick: false,
  onTimeDelivery: false,
  eventType: 0,
  reasonCode: 0,
  callOutcome: null,
  notes: null,
  city: '',
  stateProvinceRegion: '',
  stopId: -1,
  stop: {},
  stopDate: null,
  stopTime: null,
  postalCode: '',
})

type LoadsSliceState = {
  count: number
  loadCounts: {
    all: number
    archived: number
  }
  currentLoad: Load
  loading: {
    getLoads: boolean
    getLoadDetail: boolean
    getTrackingUpdate: boolean
    postLoadTrackingUpdate: boolean
    getLoadStatusHistory: boolean
  }
  loads: Array<Load>
  trackingUpdate: TrackingUpdate
  trackingUpdateBackup: TrackingUpdate
  loadStatusHistory: string
}

const initialState: LoadsSliceState = {
  count: 0,
  loadCounts: {
    all: 0,
    archived: 0,
  },
  currentLoad: {
    id: -1,
    shipper: { id: -1 } as Location,
    consignee: { id: -1 } as Location,
    customerPrice: 0,
    loadStatus: 1,
    isHotLoad: false,
  },
  loads: [],
  loading: {
    getLoads: false,
    getLoadDetail: false,
    getTrackingUpdate: false,
    postLoadTrackingUpdate: false,
    getLoadStatusHistory: false,
  },
  trackingUpdate: newTrackingUpdate(),
  trackingUpdateBackup: newTrackingUpdate(),
  loadStatusHistory: '',
}

const validateTrackingUpdate = (update: TrackingUpdate) => {
  update.isValid = !(
    (update.loadStatus === 16 && !update.arrivedAtShipperAt) ||
    (update.loadStatus === 5 && !update.pickedAt) ||
    (update.loadStatus === 17 && !update.arrivedAtConsigneeAt) ||
    !validateStateCode(update.stateProvinceRegion, false)
  )
}

export const getLoadStatusHistory = createAsyncThunk(
  'detailsLoads/getLoadStatusHistory',
  async (id: string | number) =>
    api
      .get(`/loads/api/history/${id}/?limit=100&offset=0&field_name=new_load_status`)
      .then(({ data }) => data),
)

// Get tracking update for a particular load
export const getLoadTrackingUpdate = createAsyncThunk(
  'detailsLoads/getLoadTrackingUpdate',
  async (id: number, { rejectWithValue }) => {
    try {
      const response = await api.get(`/loads/api/load-status-tracking-update/${id}/`)
      return response.data
    } catch (err: CatchError) {
      if (!err.response) {
        throw err
      }
      return rejectWithValue(err.response)
    }
  },
)

// Post new tracking update for a particular load
export const postLoadTrackingUpdate = createAsyncThunk(
  'detailsLoads/postLoadTrackingUpdate',
  async (_, { getState, dispatch }) => {
    const {
      detailsLoads: { trackingUpdate, currentLoad },
    } = getState() as any // TODO: Fix these type errors

    if (currentLoad.newLoadStatusDisplay !== trackingUpdate.newLoadStatusDisplay) {
      trackEvent('Updated load status', { status: trackingUpdate.loadStatus })
    }

    formatDateTimeForBackend(trackingUpdate.pickedAt, trackingUpdate.pickedAtTime)
    const payload = {
      event_datetime: `${formatDateForBackend(trackingUpdate.stopDate)} ${trackingUpdate.stopTime}`,
      city: trackingUpdate.city || undefined,
      state_province_region: trackingUpdate.stateProvinceRegion || undefined,
      postal_code: trackingUpdate.postalCode || undefined,
      call_outcome: trackingUpdate.callOutcome || undefined,
      notes: trackingUpdate.notes || undefined,
      event_type: trackingUpdate.eventType || undefined,
      reason_code: trackingUpdate.reasonCode || undefined,
    }

    const response = await api.post(`/edi/api/send-edi-update/${trackingUpdate.loadId}/`, payload)

    dispatch(
      setTrackingUpdate({
        ...trackingUpdate,
        reasonCode: 0,
        eventType: 0,
        stop: null,
        stopDate: undefined,
        stopTime: undefined,
        city: '',
        stateProvinceRegion: '',
        callOutcome: null,
        notes: null,
        postalCode: '',
      }),
    )

    return keysToCamelCase(response.data)
  },
)

// Get details for a particular load
export const getLoadDetail = createAsyncThunk(
  'detailsLoads/getLoadDetail',
  async (id: number, { dispatch }) => {
    const response = await api.get(`/loads/api/load-detail/${id}/`)

    dispatch(getLoadStatusHistory(id))

    return keysToCamelCase(response.data)
  },
)

// Get list of loads for a particular customer
export const getCustomerLoads = createAsyncThunk(
  'detailsLoads/getCustomerLoads',
  async (payload: {
    customerId: number | string
    offset: number
    limit: number
    archived?: boolean
  }) => {
    if (Number(payload.customerId) > 0) {
      const response = await api.get(
        `/accounts/api/customer/historical-loads/${payload.customerId}/`,
        {
          params: {
            offset: payload.offset,
            limit: payload.limit,
            archived: payload.archived ?? false,
          },
        },
      )
      return keysToCamelCase(response.data)
    }
    if (payload.customerId === -1) {
      return {
        count: 0,
        results: [],
      }
    }
  },
)

// Get count of all and archived loads for a particular customer
export const getCustomerLoadCounts = createAsyncThunk(
  'detailsLoads/getCustomerLoadCounts',
  async (payload: { customerId: number | string }) => {
    let response
    response = await api.get(`/accounts/api/customer/historical-loads/${payload.customerId}/`, {
      params: {
        archived: false,
      },
    })
    const count = response.data.count
    response = await api.get(`/accounts/api/customer/historical-loads/${payload.customerId}/`, {
      params: {
        archived: true,
      },
    })
    const archivedCount = response.data.count
    return {
      all: count,
      archived: archivedCount,
    }
  },
)

// Get list of loads for a particular carrier
export const getCarrierLoads = createAsyncThunk(
  'detailsLoads/getCarrierLoads',
  async (payload: { carrierId: number | string; offset: number; limit: number }) => {
    const response = await api.get(`/accounts/api/carrier/historical-loads/${payload.carrierId}/`, {
      params: {
        offset: payload.offset,
        limit: payload.limit,
        ordering: '-date_created',
      },
    })
    return keysToCamelCase(response.data)
  },
)

// Get list of loads for a particular carrier
export const getLocationLoads = createAsyncThunk(
  'detailsLoads/getLocationLoads',
  async ({
    locationId,
    offset,
    limit,
  }: {
    locationId: number | string
    offset: number
    limit: number
  }) => {
    const response = await api.get(`/locations/api/shipment-list/${locationId}/`, {
      params: {
        offset,
        limit,
      },
    })
    return keysToCamelCase(response.data)
  },
)

export const loadsSlice = createSlice({
  name: 'detailsLoads',
  initialState,
  reducers: {
    setTrackingUpdate(state, { payload }) {
      state.trackingUpdate = payload
      validateTrackingUpdate(state.trackingUpdate)
    },
    clearTrackingUpdate(state) {
      state.trackingUpdate = newTrackingUpdate()
    },
  },
  extraReducers(builder) {
    builder
      .addCase(getLoadDetail.pending, state => {
        state.loading.getLoadDetail = true
      })
      .addCase(getLoadDetail.fulfilled, (state, action) => {
        state.loading.getLoadDetail = false
        state.currentLoad = action.payload
      })
      .addCase(getLoadDetail.rejected, state => {
        state.loading.getLoadDetail = false
        toast.error('Failed to get load details')
      })
      .addCase(getLoadTrackingUpdate.pending, state => {
        state.loading.getTrackingUpdate = true
      })
      .addCase(getLoadTrackingUpdate.fulfilled, (state, action) => {
        const isValidDateString = (datestring: string) =>
          datestring && datestring.toLowerCase() !== 'invalid date'
        const formatTime = (datestring: string) =>
          isValidDateString(datestring) ? dayjs(datestring).format('HH:mm') : undefined
        const formatDate = (datestring: string) =>
          isValidDateString(datestring) ? datestring : undefined

        const trackingUpdate = keysToCamelCase(action.payload)
        state.loading.getTrackingUpdate = false
        state.trackingUpdate = {
          ...state.trackingUpdate,
          ...trackingUpdate,
          pickedAt: formatDate(trackingUpdate.pickedAt),
          pickedAtTime: formatTime(trackingUpdate.pickedAt),
          deliveredAt: formatDate(trackingUpdate.deliveredAt),
          deliveredAtTime: formatTime(trackingUpdate.deliveredAt),
          pickupApptAt: formatDate(trackingUpdate.pickupApptAt),
          pickupApptAtTime: formatTime(trackingUpdate.pickupApptAt),
          deliveryApptAt: formatDate(trackingUpdate.deliveryApptAt),
          deliveryApptAtTime: formatTime(trackingUpdate.deliveryApptAt),
          arrivedAtShipperAt: formatDate(trackingUpdate.arrivedAtShipperAt),
          arrivedAtShipperAtTime: formatTime(trackingUpdate.arrivedAtShipperAt),
          arrivedAtConsigneeAt: formatDate(trackingUpdate.arrivedAtConsigneeAt),
          arrivedAtConsigneeAtTime: formatTime(trackingUpdate.arrivedAtConsigneeAt),
        }
        validateTrackingUpdate(state.trackingUpdate)
        state.trackingUpdateBackup = cloneDeep(state.trackingUpdate)
      })
      .addCase(getLoadTrackingUpdate.rejected, (state, action: any) => {
        state.loading.getTrackingUpdate = false
        if (action?.payload?.status !== 404) toast.error('Failed to get tracking update')
      })
      .addCase(postLoadTrackingUpdate.pending, state => {
        state.loading.postLoadTrackingUpdate = true
      })
      .addCase(postLoadTrackingUpdate.fulfilled, state => {
        state.loading.postLoadTrackingUpdate = false
        state.trackingUpdateBackup = cloneDeep(state.trackingUpdate)
        state.loads = state.loads.map((load: Load) => {
          if (load.id == state.trackingUpdate.loadId)
            return {
              ...load,
              loadStatus: state.trackingUpdate.loadStatus,
            }
          return load
        })
        toast.success('Successfully posted tracking update')
      })
      .addCase(postLoadTrackingUpdate.rejected, state => {
        state.loading.postLoadTrackingUpdate = false
        toast.error('Failed to post tracking update')
      })
      .addCase(getCustomerLoads.pending, state => {
        state.loading.getLoads = true
      })
      .addCase(getCustomerLoads.fulfilled, (state, action) => {
        state.loading.getLoads = false
        state.loads = action.payload.results
        state.count = action.payload.count
      })
      .addCase(getCustomerLoads.rejected, state => {
        state.loading.getLoads = false
        toast.error('Failed to get customer shipments')
      })
      .addCase(getCustomerLoadCounts.fulfilled, (state, action) => {
        state.loadCounts = action.payload
      })
      .addCase(getCarrierLoads.pending, state => {
        state.loading.getLoads = true
      })
      .addCase(getCarrierLoads.fulfilled, (state, action) => {
        state.loading.getLoads = false
        state.loads = action.payload.results
        state.count = action.payload.count
      })
      .addCase(getCarrierLoads.rejected, state => {
        state.loading.getLoads = false
        toast.error('Failed to get carrier shipments')
      })
      .addCase(getLocationLoads.pending, state => {
        state.loading.getLoads = true
      })
      .addCase(getLocationLoads.fulfilled, (state, action) => {
        state.loading.getLoads = false
        state.loads = action.payload.results
        state.count = action.payload.count
      })
      .addCase(getLocationLoads.rejected, state => {
        state.loading.getLoads = false
        toast.error('Failed to get location shipments')
      })
      .addCase(getLoadStatusHistory.pending, state => {
        state.loading.getLoadStatusHistory = true
      })
      .addCase(getLoadStatusHistory.fulfilled, (state, action) => {
        state.loadStatusHistory = action.payload.results[0]?.at
        state.loading.getLoadStatusHistory = false
      })
      .addCase(getLoadStatusHistory.rejected, state => {
        state.loading.getLoadStatusHistory = false
      })
  },
})

export const { setTrackingUpdate, clearTrackingUpdate } = loadsSlice.actions

export default loadsSlice.reducer
