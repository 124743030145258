import { CatchError, formatAxiosErrorToPayload, getErrorString } from '@common'
import { downloadCSV } from '@common'
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import dayjs from 'dayjs'
import { toast } from 'react-toastify'

import { api } from '../api/api'
import { initialFilters, invoiceStatuses } from '../common/constants'
import {
  CSVField,
  Document,
  InvoiceStatus,
  RootState,
  SearchFilters,
  TableOrder,
} from '../common/types'
import {
  cleanFilters,
  formatDateForBackend,
  getOrderingString,
  keysToCamelCase,
  keysToSnakeCase,
} from '../common/utils'

type CollectionsState = {
  collections: {
    id: number
    loadId: number
    customerCompany: {
      id: number
      name: string
    }
    carrier: { id: number; name: string } | null
    status: {
      status: string
      notesCount: number
      notes: string
    }
    poNumber: string | null
    customerReferenceId: string | null
    amounts: {
      invoiceAmount: number | null
      receivedAmount: number | null
      openBalance: number | null
    }
    deliveredDate: string
    ageDays: number
    orderType: {
      display: string
      type: number
    }
    invoicedDate: string
    callbackDate: string
    lfrSubmissionType: string
  }[]
  loading: {
    collections: boolean
    voidCheck: boolean
    invoiceNotes: boolean
    createInvoiceNote: boolean
    invoiceDocuments: boolean
    exportCSV: boolean
    bulkUpdateCollections: boolean
  }
  count: number
  offset: number
  size: number
  filters: SearchFilters
  orderBy: TableOrder
  invoiceNotes: {
    author: { id: number; name: string }
    id: number
    loadInvoiceCollection: number
    notes: string
    status: string
    statusDisplay: string
    createdAt: string
  }[]
  invoiceDocuments: Document[]
  selectedFields: Array<string>
  exportFields: CSVField[]
  selectedCollections: number[]
}

const initialState: CollectionsState = {
  collections: [],
  loading: {
    collections: false,
    voidCheck: false,
    invoiceNotes: false,
    createInvoiceNote: false,
    invoiceDocuments: false,
    exportCSV: false,
    bulkUpdateCollections: false,
  },
  count: 0,
  offset: 0,
  size: 50,
  filters: initialFilters,
  orderBy: {
    label: '',
    direction: '',
    key: '',
  },
  invoiceNotes: [],
  invoiceDocuments: [],
  selectedFields: ['load_id', 'customer', 'carrier', 'order_type', 'po_number'],
  exportFields: [
    { label: 'Load ID', key: 'load_id' },
    { label: 'Customer', key: 'customer' },
    { label: 'Carrier', key: 'carrier' },
    { label: 'Order Type', key: 'order_type' },
    { label: 'PO Number', key: 'po_number' },
    { label: 'Delivered Date', key: 'delivered_date' },
    { label: 'Invoiced Date', key: 'invoiced_date' },
    { label: 'Customer Ref ID', key: 'customer_ref_id' },
    { label: 'Status', key: 'status' },
    { label: 'Age', key: 'ages' },
    { label: 'Invoice Amount', key: 'invoice_amount' },
  ],
  selectedCollections: [],
}

const getFilters = (filters: SearchFilters, returnString = true) => {
  let invoiceDates: (string | null)[] = []

  switch (filters.invoiceAge) {
    case '0':
      invoiceDates = [
        formatDateForBackend(dayjs()),
        formatDateForBackend(dayjs().subtract(30, 'd')),
      ]
      break
    case '1':
      invoiceDates = [
        formatDateForBackend(dayjs().subtract(31, 'd')),
        formatDateForBackend(dayjs().subtract(60, 'd')),
      ]
      break
    case '2':
      invoiceDates = [
        formatDateForBackend(dayjs().subtract(61, 'd')),
        formatDateForBackend(dayjs().subtract(90, 'd')),
      ]
      break
    case '3':
      invoiceDates = [
        formatDateForBackend(dayjs().subtract(91, 'd')),
        formatDateForBackend(dayjs().subtract(120, 'd')),
      ]
      break
    case '4':
      invoiceDates = [formatDateForBackend(dayjs().subtract(121, 'd'))]
      break
    default:
      invoiceDates = []
  }

  const formatArray = (array?: (string | number)[]) =>
    returnString
      ? array?.join(',')
      : array?.map((el: number | string) => (!isNaN(Number(el)) ? Number(el) : el))

  return cleanFilters({
    load_id: filters.loadId || null,
    load__customer_company: filters.customerCompany,
    load__customer_company__accounting_contact: filters.accountingContact,
    load__customer_company__accounting_contact__isnull: filters.noAccountingContact ? true : null,
    load__carrier: filters.carrier,
    load__po_number__icontains: filters.poNumber,
    load__customer_reference_id__icontains: filters.refId,
    status: filters.invoiceStatus,
    factoring_submission_type: filters.factoringSubmissionType,
    invoiced_date_from: invoiceDates[1],
    invoiced_date_to: invoiceDates[0],
    ...(filters.invoiceType && {
      load__order_type__in: formatArray(filters.invoiceType === 'freight' ? [1, 2, 3] : [4]),
    }),
    ...(filters.deliveryDate?.[1]
      ? {
          delivered_date_to: formatDateForBackend(filters.deliveryDate?.[1] ?? null),
          delivered_date_from: formatDateForBackend(filters.deliveryDate?.[0] ?? null),
        }
      : {
          delivered_date: formatDateForBackend(filters.deliveryDate?.[0] ?? null),
        }),
    ...(filters.callbackDate?.[1]
      ? {
          callback_date__lte: formatDateForBackend(filters.callbackDate?.[1] ?? null) || null,
          callback_date__gte: formatDateForBackend(filters.callbackDate?.[0] ?? null) || null,
        }
      : {
          callback_date: formatDateForBackend(filters.callbackDate?.[0] ?? null),
        }),
  })
}

export const getCollections = createAsyncThunk(
  'collections/getCollections',
  async (_, { getState }) => {
    const { filters, size, offset, orderBy } = (getState() as RootState).collections

    const ordering =
      getOrderingString(orderBy?.label, orderBy?.direction, orderBy?.key) || 'invoiced_date'

    const response = await api.get('/loads/api/load-collection/', {
      params: {
        limit: size,
        offset,
        ordering,
        ...getFilters(filters),
      },
    })

    return keysToCamelCase(response.data)
  },
)

export const getInvoiceStatus = createAsyncThunk(
  'collections/getInvoiceStatus',
  async (id: number) =>
    await api
      .get(`/loads/api/load-invoice-status/${id}/`)
      .then(({ data }) => keysToCamelCase(data)),
)

export const getInvoiceDocuments = createAsyncThunk(
  'collections/getInvoiceDocuments',
  async (id: number) =>
    await api
      .get(`/loads/api/load-collection-documents/${id}/`)
      .then(({ data }) => keysToCamelCase(data)),
)

export const createInvoiceNote = createAsyncThunk(
  'collections/createInvoiceNote',
  async (
    {
      loadInvoiceCollection,
      invoiceStatus,
      invoiceNote: notes,
      loadId,
      callbackDate,
    }: {
      loadInvoiceCollection: number
      invoiceStatus: InvoiceStatus
      invoiceNote: string
      loadId: number
      callbackDate: Date | string | null
    },
    { dispatch, rejectWithValue },
  ) => {
    try {
      const response = await api.post(
        `/loads/api/load-invoice-status/${loadId}/`,
        keysToSnakeCase({
          notes,
          loadInvoiceCollection,
          callbackDate,
          loadId,
          status: invoiceStatuses.find(status => status.label === invoiceStatus?.label)?.key || '',
        }),
      )
      dispatch(getInvoiceStatus(loadId))
      dispatch(getCollections())
      return keysToCamelCase(response.data)
    } catch (err: CatchError) {
      return rejectWithValue(formatAxiosErrorToPayload(err))
    }
  },
)

export const bulkUpdateCollections = createAsyncThunk(
  'collections/bulkUpdateCollections',
  async (
    {
      invoiceStatus,
      invoiceNote: notes,
      callbackDate,
    }: {
      invoiceStatus: InvoiceStatus
      invoiceNote: string
      callbackDate: Date | string | null
    },
    { getState, dispatch, rejectWithValue },
  ) => {
    const { selectedCollections } = (getState() as RootState).collections

    try {
      const response = await api.post(
        `/loads/api/create-multiple-load-invoice-status/`,
        keysToSnakeCase({
          notes,
          callbackDate,
          loadInvoiceCollectionIds: selectedCollections,
          status: invoiceStatuses.find(status => status.label === invoiceStatus?.label)?.key || '',
        }),
      )
      dispatch(getCollections())
      return keysToCamelCase(response.data)
    } catch (err: CatchError) {
      return rejectWithValue(formatAxiosErrorToPayload(err))
    }
  },
)

export const exportLoadCollectionsCSV = createAsyncThunk(
  'loads/exportLoadCollectionsCSV',
  async (_, { getState, rejectWithValue }) => {
    const { filters, selectedFields } = (getState() as RootState).collections

    try {
      const response = await api.post('/loads/api/export-load-collection-data/', {
        filters: {
          ...getFilters(filters, false),
        },
        fields: selectedFields.join(','),
      })

      downloadCSV(response.data, 'load-collections')
    } catch (err: CatchError) {
      return rejectWithValue(formatAxiosErrorToPayload(err))
    }
  },
)

const collectionsSlice = createSlice({
  name: 'collections',
  initialState,
  reducers: {
    setSize(state, { payload }) {
      state.size = payload
    },
    setOffset(state, { payload }) {
      state.offset = payload
    },
    setFilters(state, { payload }) {
      state.filters = payload
    },
    setOrderBy(state, { payload }) {
      state.orderBy = payload
    },
    setSelectedFields(state, { payload }) {
      state.selectedFields =
        typeof payload === 'string'
          ? state.selectedFields.includes(payload)
            ? state.selectedFields.filter(field => field !== payload)
            : [...state.selectedFields, payload]
          : payload
    },
    setSelectedCollections(state, { payload }) {
      state.selectedCollections =
        typeof payload === 'number'
          ? state.selectedCollections.includes(payload)
            ? state.selectedCollections.filter(field => field !== payload)
            : [...state.selectedCollections, payload]
          : payload
    },
  },
  extraReducers(builder) {
    builder
      .addCase(bulkUpdateCollections.pending, state => {
        state.loading.bulkUpdateCollections = true
      })
      .addCase(bulkUpdateCollections.fulfilled, state => {
        state.selectedCollections = []
        state.loading.bulkUpdateCollections = false
        toast.success('Successfully updated collections')
      })
      .addCase(bulkUpdateCollections.rejected, state => {
        state.loading.bulkUpdateCollections = false
        toast.error('Failed to update collections')
      })
      .addCase(getCollections.pending, state => {
        state.loading.collections = true
      })
      .addCase(getCollections.fulfilled, (state, { payload }) => {
        const { count, results } = payload
        state.collections = results
        state.count = count
        state.loading.collections = false
      })
      .addCase(getCollections.rejected, state => {
        state.loading.collections = false
        toast.error('Failed to retrieve collections')
      })
      .addCase(getInvoiceStatus.pending, state => {
        state.loading.invoiceNotes = true
      })
      .addCase(getInvoiceStatus.fulfilled, (state, { payload }) => {
        state.invoiceNotes = payload
        state.loading.invoiceNotes = false
      })
      .addCase(getInvoiceStatus.rejected, state => {
        state.loading.invoiceNotes = false
        toast.error('Failed to retrieve notes')
      })
      .addCase(createInvoiceNote.pending, state => {
        state.loading.createInvoiceNote = true
      })
      .addCase(createInvoiceNote.fulfilled, state => {
        state.loading.createInvoiceNote = false
        toast.success('Successfully created note')
      })
      .addCase(createInvoiceNote.rejected, (state, { payload }) => {
        state.loading.createInvoiceNote = false
        toast.error(getErrorString(payload, 'Failed to create note'))
      })
      .addCase(getInvoiceDocuments.pending, state => {
        state.loading.invoiceDocuments = true
      })
      .addCase(getInvoiceDocuments.fulfilled, (state, { payload }) => {
        state.loading.invoiceDocuments = false
        state.invoiceDocuments = payload
      })
      .addCase(getInvoiceDocuments.rejected, state => {
        state.loading.invoiceDocuments = false
        toast.error('Failed to retrieve documents')
      })
      .addCase(exportLoadCollectionsCSV.pending, state => {
        state.loading.exportCSV = true
      })
      .addCase(exportLoadCollectionsCSV.fulfilled, state => {
        state.loading.exportCSV = false
        toast.success('Successfully exported CSV')
      })
      .addCase(exportLoadCollectionsCSV.rejected, (state, { payload }) => {
        state.loading.exportCSV = false
        toast.error(getErrorString(payload, 'Failed to export CSV'))
      })
  },
})

export const {
  setSize,
  setOffset,
  setFilters,
  setOrderBy,
  setSelectedFields,
  setSelectedCollections,
} = collectionsSlice.actions

export default collectionsSlice.reducer
