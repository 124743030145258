import { Link, Select, SelectDropdownProps } from '@components'
import { ArrowTopRightOnSquareIcon } from '@heroicons/react/24/outline'
import { debounce } from 'lodash-es'
import { useCallback, useMemo } from 'react'
import { MenuPlacement } from 'react-select'
import tw from 'tailwind-styled-components'

import { useAppSelector, useAppThunkDispatch } from '../../app/hooks'
import { autoCompleteCustomerNameSearch } from '../../redux/autocompleteSlice'
import { setCreateModalVisible } from '../../redux/customersSlice'
import { CreateCustomerModal } from '../CreateModals'

export const CustomerSelect = ({
  value = null,
  disabled = false,
  setValue,
  label,
  withLabel,
  withPlaceholder,
  className = '',
  required,
  addable,
  menuPlacement,
  withError,
  ...props
}: {
  value: any
  setValue: (value: any) => void
  withLabel?: boolean
  label?: string
  withPlaceholder?: boolean
  className?: string
  required?: boolean
  addable?: boolean
  menuPlacement?: MenuPlacement
  disabled?: boolean
  withError?: boolean
} & Omit<SelectDropdownProps, 'choices' | 'onChange'>) => {
  const dispatch = useAppThunkDispatch()

  const customerNamesOptions = useAppSelector(state => state.autocomplete.customerNamesOptions)
  const loading = useAppSelector(state => state.autocomplete.loading.customerNamesOptions)
  const text = label || 'Customer Name'

  const onSearch = useCallback(
    debounce((query: string) => {
      query && dispatch(autoCompleteCustomerNameSearch(query))
    }, 200),
    [],
  )

  const customers = useMemo(
    () =>
      withError
        ? customerNamesOptions.map(customer => ({
            ...customer,
            ...(customer.billingError && {
              rightComponent: (
                <LinkComponent to={`/customers/${customer.id}?tab=1&detailsTab=2&edit=true`}>
                  <ArrowTopRightOnSquareIcon className='w-4 mr-2 stroke-2' />
                  Add Billing Details
                </LinkComponent>
              ),
              isDisabled: true,
              tooltipText:
                'You can’t build loads with this customer until accounting information is complete',
            }),
          }))
        : customerNamesOptions,
    [customerNamesOptions, withError],
  )

  return (
    <>
      <Select
        hideDropdownIndicator
        sm
        disabled={disabled}
        addable={addable}
        addableText='customer'
        choices={customers}
        className={className}
        field='text'
        loading={loading}
        openMenuOnClick={!!customers.length}
        required={required}
        value={value || null}
        menuPlacement={menuPlacement}
        onAdd={() => dispatch(setCreateModalVisible(true))}
        onChange={setValue}
        onInputChange={onSearch}
        onValueNotFound={() => onSearch(value)}
        {...((withLabel || label) && { label: text })}
        {...(withPlaceholder && { placeholder: text })}
        {...props}
      />
      <CreateCustomerModal setSelectValue={setValue} />
    </>
  )
}

const LinkComponent = tw(Link)`
  text-link
  flex
  items-center
  font-semibold
`
