import { Button, ConfirmDialog } from '@components'
import { isEqual, omit } from 'lodash-es'
import { useEffect, useMemo, useState } from 'react'
import tw from 'tailwind-styled-components'

import { useAppSelector, useAppThunkDispatch } from '../../../../app/hooks'
import { Accessorial } from '../../../../common/types'
import { deleteAccessorial, updateAccessorial } from '../../../../redux/loadsSlice'
import { IconButton } from '../../../Button'
import { EditAccessorialForm } from '../../../Forms'
import { MobileButtonsContainer } from '../MobileButtonsContainer'

export const EditAccessorialItem = ({
  accessorial,
  onCancelEdit,
}: {
  accessorial: Accessorial
  onCancelEdit: () => void
}) => {
  const [isModalVisible, setModalVisible] = useState(false)
  const [isFormValid, setFormValid] = useState(false)

  const accessorialTypes = useAppSelector(state => state.autocomplete.accessorialTypes)

  const item = {
    accessorialType: {
      id: accessorial.accessorialType,
      name: accessorial.accessorialTypeText,
      reimbursement: false,
    },
    chargeTo: {
      id: accessorial.chargeTo,
      name: accessorial.chargeToDisplay,
    },
    chargeType: {
      id: accessorial.chargeType,
      name: accessorial.chargeTypeDisplay,
    },
    quantity: accessorial.quantity,
    amount: accessorial.amount,
    reason: accessorial.reason,
    prepaid: accessorial.prepaid,
    carrierDeduction: accessorial.carrierDeduction,
  }

  const [editItem, setEditItem] = useState(item)

  useEffect(() => {
    if (accessorialTypes.length)
      setEditItem({
        ...editItem,
        accessorialType: {
          ...editItem.accessorialType,
          reimbursement:
            accessorialTypes.find(acc => acc.id === accessorial.accessorialType)?.reimbursement ??
            false,
        },
      })
  }, [accessorialTypes])

  const dispatch = useAppThunkDispatch()

  const load = useAppSelector(state => state.loads.loadDetails)
  const loading = useAppSelector(state => state.loads.loading.deleteAccessorial)

  const onSave = async () => {
    const response = await dispatch(
      updateAccessorial({ id: accessorial.id, accessorial: editItem }),
    )
    if (response.meta.requestStatus === 'rejected') return

    onCancelEdit()
  }

  const omitFields = ['accessorialType.reimbursement', 'accessorialType.description']

  const changesMade = useMemo(
    () => !isEqual(omit(item, omitFields), omit(editItem, omitFields)),
    [editItem],
  )

  const setItem = (field: string, value: any) => setEditItem({ ...editItem, [field]: value })

  const isSaveButtonsDisabled = load.hold || !changesMade || !isFormValid

  return (
    <div className='pb-2 relative'>
      <DesktopHeader>
        <div className='font-semibold uppercase'>Edit Accessorial</div>
        <div className='flex'>
          <IconButton
            className='mr-4'
            disabled={isSaveButtonsDisabled}
            icon='check'
            type='success'
            onClick={onSave}
          />
          <IconButton dataTestId='close' icon='close' type='secondary' onClick={onCancelEdit} />
        </div>
      </DesktopHeader>
      <EditAccessorialForm
        accessorial={editItem}
        setAccessorial={setItem}
        setFormValid={setFormValid}
      />
      <div className='justify-end mt-4 hidden md:flex'>
        <IconButton icon='delete' type='danger' onClick={() => setModalVisible(true)} />
      </div>
      <MobileButtonsContainer>
        <Button
          noBackground
          fullWidth
          type='danger'
          className='w-full'
          onClick={() => setModalVisible(true)}
        >
          Delete
        </Button>
        <Button fullWidth type='secondary' className='w-full' onClick={onCancelEdit}>
          Cancel
        </Button>
        <Button
          fullWidth
          type='warn'
          className='w-full'
          disabled={isSaveButtonsDisabled}
          onClick={onSave}
        >
          Save
        </Button>
      </MobileButtonsContainer>
      <ConfirmDialog
        isVisible={isModalVisible}
        loading={loading}
        setVisible={setModalVisible}
        text={`Accessorial #${accessorial.id}`}
        onConfirm={() => {
          dispatch(deleteAccessorial(accessorial.id))
        }}
      />
    </div>
  )
}

const DesktopHeader = tw.div`
  w-full
  justify-between
  items-center
  mb-2
  hidden
  md:flex
`
