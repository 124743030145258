import { Checkbox, Select, TextInput } from '@components'
import { CurrencyDollarIcon, TruckIcon, UsersIcon } from '@heroicons/react/24/solid'
import { zodResolver } from '@hookform/resolvers/zod'
import { debounce, filter, omit } from 'lodash-es'
import { Fragment, useCallback, useEffect, useState } from 'react'
import { Controller, useFieldArray, useForm, useWatch } from 'react-hook-form'

import { useAppSelector, useAppThunkDispatch } from '../../../../app/hooks'
import {
  dimensionsByEquipmentType,
  equipmentTypeChoices,
  modeTypes,
  orderTypes,
} from '../../../../common/constants'
import { Location } from '../../../../common/types'
import { formatDate, getCurrencyMaskOptions, getNumberMaskOptions } from '../../../../common/utils'
import { getContractLanes, resetCustomerContractLanes } from '../../../../redux/contractLanesSlice'
import {
  getPredictedMaxBuy,
  setContractLaneId,
  setContractLaneModalVisible,
} from '../../../../redux/loadsSlice'
import { CheckboxInput } from '../../../CheckboxInput'
import { InputContainer } from '../../../InputContainer'
import { RouteFormProps, routeSchema, StopItem } from '../types'
import { AddStopButton } from './AddStopButton'
import { CustomerNameAutocomplete } from './CustomerNameAutocomplete'
import { InputRow } from './InputRow'
import { Section } from './Section'
import { defaultStops } from './Steps'
import { Stop } from './Stop'

export const RouteForm = ({
  control,
  stopFields,
  addPickUp,
  addDropOff,
  getStopDeleter,
  setRouteValue,
}: RouteFormProps) => {
  const dispatch = useAppThunkDispatch()

  const customerContractLanes = useAppSelector(state => state.contractLane.customerContractLanes)

  const customerCompany = useWatch({ control, name: 'customerCompany' })
  const stops = useWatch({
    control,
    name: 'stops',
  })
  const isHazmat = useWatch({
    control,
    name: 'isHazmat',
  })
  const isHighValue = useWatch({
    control,
    name: 'isHighValue',
  })
  const currencyMaskOptions = omit(getCurrencyMaskOptions(), ['lazy'])

  const [useExoRate, setUseExoRate] = useState(true)
  const [exoRateError, setExoRateError] = useState('')
  const [isOversize, isTeamLoad, equipmentType, customerPrice] = useWatch({
    control: control,
    name: ['isOversize', 'isTeamLoad', 'equipmentType', 'customerPrice'],
  })

  const debouncedGetCarrierMaxBuy = useCallback(
    debounce(
      ({ equipmentType, customerPrice }: { equipmentType: string; customerPrice: number }) => {
        dispatch(
          getPredictedMaxBuy({
            origin: stops[0].location.addressPreview,
            destination: stops[1].location.addressPreview,
            customerPrice,
            equipmentType,
            loadPickupDate: formatDate(stops[0].stopDate),
          }),
        )
          .unwrap()
          .then(value => {
            setUseExoRate(true)
            setRouteValue('carrierMax', value)
          })
          .catch(() => {
            setRouteValue('carrierMax', null)
            setUseExoRate(false)
            setExoRateError('Failed to generate Predicted Carrier Max')
          })
      },
      500,
    ),
    [],
  )

  useEffect(() => {
    if (customerCompany) {
      dispatch(getContractLanes({ customerId: customerCompany }))
      dispatch(setContractLaneId(null))
    } else {
      dispatch(resetCustomerContractLanes())
      dispatch(setContractLaneId(null))
    }
  }, [customerCompany])

  useEffect(() => {
    if (customerCompany && customerContractLanes.length) dispatch(setContractLaneModalVisible(true))
  }, [customerContractLanes])

  useEffect(() => {
    if (!useExoRate) {
      debouncedGetCarrierMaxBuy.cancel()
      return
    } else if (isHazmat || isOversize || isTeamLoad || isHighValue || stops?.length > 2) {
      // Load that we can't price
      setRouteValue('carrierMax', null)
      setUseExoRate(false)
      setExoRateError('Unable to use Predicted Carrier Max for this load')
    } else if (
      stops?.length != 2 ||
      !stops[0].location?.addressPreview ||
      !stops[1].location?.addressPreview ||
      !stops[0].stopDate ||
      !customerPrice ||
      !equipmentType
    ) {
      // Don't have required info to price load yet
      setRouteValue('carrierMax', null)
    } else {
      // Otherwise, fetch the predicted rate
      debouncedGetCarrierMaxBuy({ equipmentType, customerPrice })
    }
  }, [
    isHazmat,
    isOversize,
    isHighValue,
    isTeamLoad,
    equipmentType,
    customerPrice,
    useExoRate,
    JSON.stringify(stops),
  ])

  const equipmentTypes = equipmentTypeChoices?.map(type => type.name)

  const onEquipmentTypeChange = (type = '') => {
    const equipmentType = type.split(' or')[0] as keyof typeof dimensionsByEquipmentType
    const {
      weight = '',
      length = '',
      width = '',
      height = '',
    } = dimensionsByEquipmentType[equipmentType] || {}
    setRouteValue('loadWeight', weight)
    setRouteValue('loadLength', length)
    setRouteValue('loadWidth', width)
    setRouteValue('loadHeight', height)
  }

  return (
    <>
      <Section title='Load Details'>
        <InputRow>
          <InputContainer className='w-1/2' icon={<UsersIcon />} iconContainerClassName='mt-5'>
            <Controller
              control={control}
              name='customerCompany'
              render={({ field }) => <CustomerNameAutocomplete required {...{ field }} />}
            />
          </InputContainer>
          <InputContainer className='w-1/2'>
            <Controller
              control={control}
              name='customerReferenceId'
              render={({ field }) => (
                <TextInput sm className='w-full' label='Customer Ref ID' {...field} />
              )}
            />
          </InputContainer>
        </InputRow>
        <InputRow>
          <InputContainer className='w-1/3' icon={<TruckIcon />} iconContainerClassName='mt-5'>
            <Controller
              control={control}
              name='mode'
              render={({ field }) => (
                <Select
                  extractValue
                  required
                  sm
                  choices={modeTypes}
                  className='w-full'
                  field='name'
                  label='Select Mode'
                  valueField='id'
                  {...field}
                />
              )}
            />
          </InputContainer>
          <InputContainer className='w-1/3'>
            <Controller
              control={control}
              name='orderType'
              render={({ field }) => (
                <Select
                  extractValue
                  required
                  sm
                  className='w-full'
                  label='Select Order Type'
                  valueField='id'
                  choices={orderTypes.filter(
                    orderType => orderType.label.toLowerCase() !== 'factoring',
                  )}
                  {...field}
                />
              )}
            />
          </InputContainer>
          <InputContainer className='w-1/3'>
            <Controller
              control={control}
              name='equipmentType'
              render={({ field }) => (
                <Select
                  required
                  sm
                  choices={equipmentTypes}
                  className='w-full'
                  label='Select Equipment'
                  {...field}
                  onChange={(value: string) => {
                    field.onChange(value ?? '')
                    onEquipmentTypeChange(value)
                  }}
                />
              )}
            />
          </InputContainer>
        </InputRow>
      </Section>
      <Section title='Special Requirements'>
        <InputRow>
          <InputContainer className='w-1/4'>
            <Controller
              control={control}
              name='isHazmat'
              render={({ field }) => (
                <CheckboxInput
                  className='mt-1'
                  isChecked={field.value}
                  text='Haz Mat'
                  onClick={() => field.onChange(!field.value)}
                  {...field}
                />
              )}
            />
          </InputContainer>
          <InputContainer className='w-1/4'>
            <Controller
              control={control}
              name='isHighValue'
              render={({ field }) => (
                <CheckboxInput
                  className='mt-1'
                  isChecked={field.value}
                  text='High Value'
                  onClick={() => field.onChange(!field.value)}
                  {...field}
                />
              )}
            />
          </InputContainer>
          <InputContainer className='w-1/4'>
            <Controller
              control={control}
              name='isTeamLoad'
              render={({ field }) => (
                <CheckboxInput
                  className='mt-1'
                  isChecked={field.value}
                  text='Team Load'
                  onClick={() => field.onChange(!field.value)}
                  {...field}
                />
              )}
            />
          </InputContainer>
          <InputContainer className='w-1/4'>
            <Controller
              control={control}
              name='isOversize'
              render={({ field }) => (
                <CheckboxInput
                  className='mt-1'
                  isChecked={field.value}
                  text='Over Dimensional'
                  onClick={() => field.onChange(!field.value)}
                  {...field}
                />
              )}
            />
          </InputContainer>
        </InputRow>
        <InputRow>
          <InputContainer className='w-1/4'>
            {isHazmat && (
              <Controller
                control={control}
                name='hazmatUhn'
                render={({ field }) => (
                  <TextInput
                    required
                    sm
                    className='w-full'
                    label='Enter four digits UN number'
                    {...field}
                  />
                )}
              />
            )}
          </InputContainer>
          <InputContainer className='w-1/4'>
            {isHighValue && (
              <Controller
                control={control}
                name='highValueAmount'
                render={({ field }) => (
                  <TextInput
                    required
                    sm
                    className='w-full'
                    label='Enter Value'
                    maskOptions={getNumberMaskOptions(10)}
                    {...field}
                    onChange={(value: string) => field.onChange(Number(value))}
                  />
                )}
              />
            )}
          </InputContainer>
        </InputRow>
      </Section>
      <Section title='Total Dimensions'>
        <InputRow>
          <InputContainer className='w-1/3'>
            <Controller
              control={control}
              name='loadWeight'
              render={({ field }) => (
                <TextInput
                  required
                  sm
                  className='w-full'
                  label='Total Weight'
                  maskOptions={getNumberMaskOptions(5)}
                  {...field}
                  onChange={(value: string) => field.onChange(Number(value))}
                />
              )}
            />
          </InputContainer>
          <InputContainer className='w-1/3'>
            <Controller
              control={control}
              name='temperature'
              render={({ field }) => (
                <TextInput
                  sm
                  className='w-full'
                  label='Total Temp'
                  maskOptions={getNumberMaskOptions(10)}
                  {...field}
                />
              )}
            />
          </InputContainer>
          <InputContainer className='w-1/3'>
            <Controller
              control={control}
              name='loadLength'
              render={({ field }) => (
                <TextInput
                  required
                  sm
                  className='w-full'
                  label='Total Length'
                  maskOptions={getNumberMaskOptions()}
                  {...field}
                  onChange={(value: string) => field.onChange(Number(value))}
                />
              )}
            />
          </InputContainer>
        </InputRow>
        <InputRow>
          <InputContainer className='w-1/2'>
            <Controller
              control={control}
              name='loadWidth'
              render={({ field }) => (
                <TextInput
                  required
                  sm
                  className='w-full'
                  label='Total Width'
                  maskOptions={getNumberMaskOptions()}
                  {...field}
                  onChange={(value: string) => field.onChange(Number(value))}
                />
              )}
            />
          </InputContainer>
          <InputContainer className='w-1/2'>
            <Controller
              control={control}
              name='loadHeight'
              render={({ field }) => (
                <TextInput
                  required
                  sm
                  className='w-full'
                  label='Total Height'
                  maskOptions={getNumberMaskOptions()}
                  {...field}
                  onChange={(value: string) => field.onChange(Number(value))}
                />
              )}
            />
          </InputContainer>
        </InputRow>
      </Section>
      {stopFields
        .slice(0, stopFields.length - 1)
        .map((stopField: Record<'id', string>, idx: number) =>
          stops[idx] ? (
            <Stop key={stopField.id} {...{ control, idx, getStopDeleter }} />
          ) : (
            <Fragment key={stopField.id} />
          ),
        )}
      <InputRow>
        <div className='flex w-full justify-between items-center'>
          <hr className='border border-light-blue w-[281px]' />
          <div className='flex w-[271px] gap-4 px-4'>
            <AddStopButton innerClassName='!bg-in-transit w-[104px]' onClick={addPickUp}>
              Add Pick-Up
            </AddStopButton>
            <AddStopButton innerClassName='!bg-light-pink w-[119px]' onClick={addDropOff}>
              Add Drop-Off
            </AddStopButton>
          </div>
          <hr className='border border-light-blue w-[281px]' />
        </div>
      </InputRow>
      {stopFields.length && stops[stopFields.length - 1] && (
        <Stop
          {...{
            control,
            idx: stopFields.length - 1,
            getStopDeleter,
          }}
        />
      )}
      <Section title='Price'>
        <InputRow>
          <InputContainer
            className='w-1/3'
            icon={<CurrencyDollarIcon />}
            iconContainerClassName='mt-5'
          >
            <Controller
              control={control}
              name='customerPrice'
              render={({ field }) => (
                <TextInput
                  required
                  sm
                  className='w-full'
                  label='Customer Price'
                  maskOptions={currencyMaskOptions}
                  {...field}
                  onChange={(value: string) => field.onChange(Number(value))}
                />
              )}
            />
          </InputContainer>
          <InputContainer className='w-1/3'>
            <Controller
              control={control}
              name='carrierMin'
              render={({ field }) => (
                <TextInput
                  required
                  sm
                  className='w-full'
                  label='Carrier Min'
                  maskOptions={currencyMaskOptions}
                  {...field}
                  onChange={(value: string) => field.onChange(Number(value))}
                />
              )}
            />
          </InputContainer>
          <InputContainer className='w-1/3'>
            <div className='flex flex-col w-full'>
              <Controller
                control={control}
                name='carrierMax'
                render={({ field }) => (
                  <TextInput
                    required
                    sm
                    blue={useExoRate}
                    className='w-full'
                    disabled={useExoRate}
                    error={exoRateError}
                    label='Carrier Max'
                    maskOptions={currencyMaskOptions}
                    {...field}
                    onChange={(value: string) => field.onChange(Number(value))}
                  />
                )}
              />
              <Checkbox
                className={`pt-${exoRateError ? 4 : 2}`}
                isChecked={useExoRate}
                title='Use Predicted Carrier Max'
                onChange={(value: boolean) => {
                  setUseExoRate(value)
                  setExoRateError('')
                }}
              />
            </div>
          </InputContainer>
        </InputRow>
      </Section>
    </>
  )
}

export const useRouteForm = () => {
  const {
    handleSubmit,
    formState: { errors, isValid: routeFormIsValid, touchedFields },
    control: routeControl,
    trigger: routeTrigger,
    setValue: setRouteValue,
  } = useForm({
    mode: 'all',
    resolver: zodResolver(routeSchema),
  })

  const duplicateTemplateLoad = useAppSelector(state => state.loads.duplicateTemplateLoad)

  const { fields: stopFields, replace: replaceStops } = useFieldArray({
    control: routeControl,
    name: 'stops',
  })

  useEffect(() => {
    replaceStops(defaultStops)
  }, [])

  const stops = useWatch({
    control: routeControl,
    name: 'stops',
  })

  const { addPickUp } = useAddPickUp({ replaceStops, stops })
  const { addDropOff } = useAddDropOff({ replaceStops, stops })
  const getStopDeleter = (stopToRemove: StopItem) => () =>
    replaceStops(stops.filter((stop: StopItem) => stop.id !== stopToRemove.id))

  useEffect(() => {
    const ready =
      duplicateTemplateLoad &&
      stops &&
      stops.length - 2 !== duplicateTemplateLoad?.loadstopSet?.length

    if (ready) {
      const { loadstopSet, manifestitemSet } = duplicateTemplateLoad

      const makeItem = (item: any) => ({
        ...item,
        id: Math.ceil(Math.random() * 1000000000),
        manifest: item.name,
      })

      const innerStops = (loadstopSet ?? []).map((stop: any) => ({
        id: Math.ceil(Math.random() * 1000000000),
        location: adornLocation(stop.location),
        stopDate: undefined,
        stopType: stop.stopType,
        items: (stop?.items ?? []).map(makeItem),
        notes: stop.notes,
      }))

      const originItems = filter(manifestitemSet, { stopType: 1 }).map(makeItem)

      const destItems = filter(manifestitemSet, { stopType: 2 }).map(makeItem)

      const originStop = {
        ...stops[0],
        items: originItems,
        notes: duplicateTemplateLoad.shipperPickupNotes,
      }

      const destStop = {
        ...stops.slice(-1)[0],
        items: destItems,
        notes: duplicateTemplateLoad.consigneeDeliveryNotes,
      }

      const replacement = [originStop, ...stops.slice(1, stops.length - 1), ...innerStops, destStop]

      replaceStops(replacement)
    }
  }, [stops?.length])

  return {
    handleSubmit,
    errors,
    routeFormIsValid,
    touchedFields,
    routeControl,
    routeTrigger,
    setRouteValue,
    stopFields,
    addPickUp,
    addDropOff,
    getStopDeleter,
    stops,
    replaceStops,
  }
}

export type UseRouteFormReturnType = ReturnType<typeof useRouteForm>

export type StopAdderHook<RT> = (
  props: Pick<UseRouteFormReturnType, 'replaceStops' | 'stops'>,
) => RT

export const useAddPickUp: StopAdderHook<{ addPickUp: () => void }> = ({ replaceStops, stops }) => {
  const addPickUp: () => void = () => {
    const replacement = [
      stops[0],
      ...stops.slice(1, stops.length - 1),
      {
        id: Math.ceil(Math.random() * 1000000000),
        location: '',
        stopDate: undefined,
        stopType: 1,
        items: [
          {
            id: Math.ceil(Math.random() * 1000000000),
            manifest: '',
            weight: '',
            quantity: 0,
            length: '',
            width: '',
            height: '',
            notes: '',
            stopType: 3,
            itemMappingKey: Math.random().toString(36).substr(2, 6),
          },
        ],
      },
      stops.slice(-1)[0],
    ]
    replaceStops(replacement)
  }

  return {
    addPickUp,
  }
}

export const useAddDropOff: StopAdderHook<{ addDropOff: () => void }> = ({
  replaceStops,
  stops,
}) => {
  const addDropOff = () =>
    replaceStops([
      stops[0],
      ...stops.slice(1, stops.length - 1),
      {
        id: Math.ceil(Math.random() * 1000000000),
        location: '',
        stopDate: undefined,
        stopType: 2,
        items: [
          {
            id: Math.ceil(Math.random() * 1000000000),
            manifest: '',
            weight: '',
            quantity: 0,
            length: '',
            width: '',
            height: '',
            notes: '',
            stopType: 3,
          },
        ],
      },
      stops.slice(-1)[0],
    ])

  return {
    addDropOff,
  }
}

export const adornLocation = (loc: Location) => {
  const getLocAddrPreview = (loc: Location) =>
    `${loc?.address} ${loc?.city}, ${loc?.stateProvinceRegion} ${loc?.postalCode}`

  return {
    ...loc,
    id: String(loc?.id),
    addressPreview: getLocAddrPreview(loc),
  }
}
