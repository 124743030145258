import { ExclamationCircleIcon } from '@heroicons/react/24/solid'

import { useAppSelector } from '../../../../app/hooks'

export const ReviewBanner = () => {
  const duplicateTemplateLoad = useAppSelector(state => state.loads.duplicateTemplateLoad)
  const showBanner = !!duplicateTemplateLoad

  return !showBanner ? (
    <></>
  ) : (
    <div className='w-full flex justify-center'>
      <div className='w-[832px] h-[52px] rounded relative'>
        <div className='absolute bg-brand-accent h-full w-1 rounded-l' />
        <div className='absolute bg-brand-accent opacity-40 h-full w-full' />
        <div className='flex items-center px-5 py-4 h-full w-full gap-2'>
          <div className='h-5 w-5 text-brand-accent'>
            <ExclamationCircleIcon />
          </div>
          <span className='opacity-100 text-dark-blue font-semibold'>
            Please review your order details below
          </span>
        </div>
      </div>
    </div>
  )
}
