import {
  CatchError,
  formatAxiosErrorToPayload,
  formatDateFilterForBackend,
  getErrorString,
} from '@common'
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { toast } from 'react-toastify'

import { api } from '../api/api'
import { initialFilters } from '../common/constants'
import { RootState, SearchFilters } from '../common/types'
import { keysToCamelCase, keysToSnakeCase } from '../common/utils'

type ApprovedPaymentsState = {
  approvedPayments: Array<any>
  loading: {
    approvedPayments: boolean
    voidCheck: boolean
  }
  count: number
  offset: number
  size: number
  filters: SearchFilters
}

const initialState: ApprovedPaymentsState = {
  approvedPayments: [],
  loading: {
    approvedPayments: false,
    voidCheck: false,
  },
  count: 0,
  offset: 0,
  size: 50,
  filters: initialFilters,
}

export const getApprovedPayments = createAsyncThunk(
  'approvedPayments/getApprovedPayments',
  async (_, { getState }) => {
    const { size = 50, offset = 0, filters } = (getState() as RootState).approvedPayments

    const response = await api.get('/billing/api/load-carrier-payment-approve/', {
      params: {
        limit: size,
        offset,
        ordering: '-created_at',
        load__id: filters.loadId || null,
        check_id__icontains: filters.checkId || null,
        carrier_id: filters.carrier || null,
        name__icontains: filters.carrierName || null,
        status: filters.payment || null,
        payment_method: filters.paymentMethod || null,
        payment_type: filters.paymentType || null,
        invoice_number__icontains: filters.carrierInvoiceNumber || null,
        id: filters.checkNumber || null,
        ar_name__icontains: filters.recipient || null,
        ...formatDateFilterForBackend(filters.datePaid, 'load__paid_date'),
      },
    })

    return response.data
  },
)

export const voidCheck = createAsyncThunk(
  'approvedPayments/voidCheck',
  async (payload: { loadPaymentId: number }, { dispatch, rejectWithValue }) => {
    try {
      const response = await api.post('/billing/api/check-void/', keysToSnakeCase(payload))

      dispatch(getApprovedPayments())

      return response.data
    } catch (err: CatchError) {
      return rejectWithValue(formatAxiosErrorToPayload(err))
    }
  },
)

const approvedPaymentsSlice = createSlice({
  name: 'approvedPayments',
  initialState,
  reducers: {
    setSize(state, { payload }) {
      state.size = payload
    },
    setOffset(state, { payload }) {
      state.offset = payload
    },
    setFilters(state, { payload }) {
      state.filters = payload
    },
  },
  extraReducers(builder) {
    builder
      .addCase(getApprovedPayments.pending, state => {
        state.loading.approvedPayments = true
      })
      .addCase(getApprovedPayments.fulfilled, (state, action) => {
        const { count, results } = action.payload
        state.approvedPayments = keysToCamelCase(results)
        state.count = count
        state.loading.approvedPayments = false
      })
      .addCase(getApprovedPayments.rejected, state => {
        state.loading.approvedPayments = false
        toast.error('Error getting approved payments')
      })
      .addCase(voidCheck.pending, state => {
        state.loading.voidCheck = true
      })
      .addCase(voidCheck.fulfilled, state => {
        state.loading.voidCheck = false
        toast.success('Successfully voided check')
      })
      .addCase(voidCheck.rejected, (state, action) => {
        state.loading.voidCheck = false
        toast.error(getErrorString(action.payload, 'Error voiding check'))
      })
  },
})

export const { setSize, setOffset, setFilters } = approvedPaymentsSlice.actions

export default approvedPaymentsSlice.reducer
