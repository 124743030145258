type IconWithBadgeProps = {
  count: number
  icon: React.ReactNode
}

export const IconWithBadge = ({ count, icon }: IconWithBadgeProps) => (
  <div className='relative'>
    <div className='w-6'>{icon}</div>
    {count > 0 && (
      <div className='absolute -top-1 -right-1'>
        <div className='flex items-center justify-center w-4 h-4 bg-red-500 rounded-full'>
          <div className='text-white text-xxs font-bold'>{count}</div>
        </div>
      </div>
    )}
  </div>
)
