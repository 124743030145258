import {
  ArrowUpTrayIcon,
  BanknotesIcon,
  BookmarkIcon,
  BookOpenIcon,
  ChatBubbleBottomCenterTextIcon,
  CreditCardIcon,
  CurrencyDollarIcon,
  GlobeAltIcon,
  TruckIcon,
  UserIcon,
} from '@heroicons/react/24/outline'

import { useAppSelector } from '../../app/hooks'
import { SidebarLink } from '../../common/types'
import { ShipmentIcon } from '../Icons/ShipmentIcon'
import { IconWithBadge } from '../IconWithBadge'

const FactoringIconWithBadge = () => {
  const carrierFactoringRequests = useAppSelector(
    state => state.carrierFactoringRequests.count.pendingCarrierFactoringRequests,
  )
  const loadFactoringRequests = useAppSelector(
    state => state.loadFactoringRequests.count.pendingLoadFactoringRequests,
  )
  return (
    <IconWithBadge
      count={carrierFactoringRequests + loadFactoringRequests}
      icon={<CreditCardIcon />}
    />
  )
}

export const links: Array<SidebarLink> = [
  {
    icon: <UserIcon />,
    url: '/customers',
    label: 'Customers',
  },
  {
    icon: <GlobeAltIcon />,
    url: '/locations',
    label: 'Locations',
  },
  {
    icon: <TruckIcon />,
    url: '/carriers',
    label: 'Carriers',
  },
  {
    icon: <TruckIcon />,
    url: '/active-capacity',
    label: 'Active Capacity',
  },
  {
    icon: <ChatBubbleBottomCenterTextIcon />,
    url: '/customer-quotes',
    label: 'Customer Quotes',
  },
  {
    icon: <ShipmentIcon />,
    url: '/contract-lanes',
    label: 'Contract Lanes',
  },
  {
    icon: <ShipmentIcon />,
    url: '/loads',
    label: 'Loads',
  },
  {
    icon: <ShipmentIcon />,
    url: '/collections',
    label: 'Collections',
  },
  {
    icon: <ShipmentIcon />,
    url: '/edi',
    label: 'EDI',
  },
  {
    icon: <ChatBubbleBottomCenterTextIcon />,
    url: '/carrier-load-offers',
    label: 'Carrier Load Offers',
  },
  {
    icon: <ArrowUpTrayIcon />,
    url: '/csv-import',
    label: 'CSV Import',
  },
  {
    icon: <FactoringIconWithBadge />,
    url: '/exo-factoring',
    label: 'Factoring',
  },
  {
    icon: <BanknotesIcon />,
    url: '/receivables',
    label: 'Receivables',
    accounting: true,
  },
  {
    icon: <BanknotesIcon />,
    url: '/invoicing',
    label: 'Invoicing',
    accounting: true,
  },
  {
    icon: <BanknotesIcon />,
    url: '/load-payments',
    label: 'Load Payments',
    accounting: true,
  },
  {
    icon: <BanknotesIcon />,
    url: '/approved-payments',
    label: 'Approved Payments',
    accounting: true,
  },
  {
    icon: <BanknotesIcon />,
    url: '/factoring-companies',
    label: 'Factoring Companies',
    accounting: true,
  },
  {
    icon: <BookOpenIcon />,
    url: 'https://www.notion.so/exo-freight/Company-Home-4ab009785d444a6d8cf0ad354b50647e',
    label: 'Knowledge Base',
    external: true,
  },
  {
    icon: <CurrencyDollarIcon />,
    url: '/quotes',
    label: 'Quoting Tool',
  },
  {
    icon: <BookmarkIcon />,
    url: '/automated-bidding',
    label: 'Automated Bidding',
  },
]
