// Mixpanel documentation:
// https://developer.mixpanel.com/docs/javascript-quickstart?projectToken=5f3855549423149ae299d5372460b39e

import mixpanel from 'mixpanel-browser'

import { isProduction, isStage } from './constants'

let isTrackingEnabled = false
const mixpanelID = '5f3855549423149ae299d5372460b39e'

if (isStage) {
  // This is running on staging. TODO: monitor how many events we're getting from
  // staging and if too many, turn this off by default.
  isTrackingEnabled = true
  mixpanel.init(mixpanelID, { ignore_dnt: true })
} else if (isProduction) {
  // This is running in production.
  isTrackingEnabled = true
  mixpanel.init(mixpanelID, { ignore_dnt: true })
}

// We use email to identify users in mixpanel and connect their actions across front-end,
// back-end and multiple devices.
export const identifyUser = (userEmail: string) => {
  if (!isTrackingEnabled) return
  // console.log(`Tracking with mixpanel: identifying user ${userEmail}`)
  mixpanel.identify(userEmail)
}

// You can pass information into extra_data in an optional dict.
//
// Example call:
// trackEvent('View Load', {'load_id': 8311})
export const trackEvent = (event: string, extra_data?: any) => {
  event = `TMS V2 - ${event}`
  if (!isTrackingEnabled) return
  // console.log('Tracking with mixpanel: ', event)
  mixpanel.track(event, extra_data)
}

export const resetTracking = () => isProduction && mixpanel.reset()
