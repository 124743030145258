import { TextInput } from '@components'
import { DocumentIcon } from '@heroicons/react/24/solid'
import tw from 'tailwind-styled-components'

import { useAppSelector, useAppThunkDispatch } from '../../../app/hooks'
import { CustomerDocument } from '../../../common/types'
import { useCustomerType } from '../../../common/utils'
import { setCustomerInfo } from '../../../redux/customersSlice'
import { DeleteButton } from './DeleteButton'

export const Document = ({ document }: { document: CustomerDocument }) => {
  const type = useCustomerType()

  const dispatch = useAppThunkDispatch()

  const documents = useAppSelector(state => state.customers[type].documents)

  const setDocument = (newData: Partial<CustomerDocument>) => {
    const tempDocuments = documents.map(doc =>
      doc.id === document.id ? { ...doc, ...newData } : doc,
    )
    dispatch(setCustomerInfo({ documents: tempDocuments }))
  }

  const deleteDocument = () =>
    dispatch(
      setCustomerInfo({
        documents: documents.filter(doc => document.id !== doc.id),
      }),
    )

  return (
    <DocumentContainer>
      <div className='flex items-center w-full mr-4'>
        <DocumentIcon className='text-icon-gray w-5 mr-2' />
        <TextInput
          sm
          placeholder='Name'
          value={document.name}
          className='w-full'
          onChange={name => setDocument({ name })}
        />
      </div>
      <DeleteButton className='mt-0' onClick={deleteDocument} />
    </DocumentContainer>
  )
}

const DocumentContainer = tw.div`
  bg-lighter-gray
  flex
  items-center
  justify-between
  p-3
  mt-6
  rounded-lg
`
