import { useAppSelector, useAppThunkDispatch } from '../../../app/hooks'
import { randomString, useCustomerType } from '../../../common/utils'
import { setCustomerInfo } from '../../../redux/customersSlice'
import { AddButton, SectionHeader, SpecialRequirement } from '../components'

export const SpecialRequirements = () => {
  const type = useCustomerType()

  const dispatch = useAppThunkDispatch()

  const specialRequirements = useAppSelector(state => state.customers[type].specialRequirements)

  const addNewRequirement = () =>
    dispatch(
      setCustomerInfo({
        specialRequirements: [
          ...specialRequirements,
          {
            id: randomString(),
            name: '',
            showOnRateCon: false,
            isValid: false,
          },
        ],
      }),
    )

  return (
    <div>
      <SectionHeader>special requirements</SectionHeader>
      {specialRequirements.map((requirement, i) => (
        <SpecialRequirement key={requirement.id} requirement={requirement} index={i} />
      ))}
      <AddButton onClick={addNewRequirement}>Add Special Requirement</AddButton>
    </div>
  )
}
