import tw from 'tailwind-styled-components'

export const SectionHeader = ({ children, className }: { children: any; className?: string }) => (
  <Container className={className}>{children}</Container>
)

const Container = tw.div`
  uppercase
  font-bold
  mb-4
`
