import tw from 'tailwind-styled-components'

import { useAppSelector, useAppThunkDispatch } from '../../../app/hooks'
import { Notification as NotifType } from '../../../common/types'
import { updateNotifications } from '../../../redux/notificationsSlice'
import { EmptyMessage } from '../../LoadDetails/components/EmptyMessage'
import { Notification } from './Notification'

type NotificationGroupProps = {
  headerText: string
  notifications: NotifType[]
  withMarkAllAsRead?: boolean
}

export const NotificationGroup = ({
  headerText,
  notifications,
  withMarkAllAsRead,
}: NotificationGroupProps) => {
  const dispatch = useAppThunkDispatch()
  const { mode, notificationsToday, notificationsThisWeek } = useAppSelector(
    store => store.notifications,
  )

  const markAllAsRead = () => {
    const isToday = mode === 'today'
    const notifs = isToday ? notificationsToday : notificationsThisWeek
    const unread = notifs.filter((notif: NotifType) => !notif.read)
    if (unread.length) {
      const read = unread.map((notif: NotifType) => ({ ...notif, read: true }))
      dispatch(updateNotifications(read))
    }
  }

  return (
    <div className='w-full'>
      <SubHeader>
        <span className='text-dark-blue text-xs font-semibold'>{headerText}</span>
        {withMarkAllAsRead && (
          <a className='text-xs text-link font-semibold cursor-pointer' onClick={markAllAsRead}>
            Mark all as read
          </a>
        )}
      </SubHeader>
      <div className='h-[400px] overflow-auto w-full'>
        <EmptyMessage text='notifications' length={notifications.length} className='mt-4' />
        {notifications.map((notification: NotifType) => (
          <Notification key={notification.id} notification={notification} />
        ))}
      </div>
    </div>
  )
}

const SubHeader = tw.div`
  w-full
  h-9
  flex
  justify-between
  pt-3
  px-4
  pb-2
  border-b
  border-light-blue
`
