import { AlphaModalProps, DraggableAlphaModal, Form, TextInput } from '@components'
import { useEffect, useState } from 'react'

import { useAppSelector, useAppThunkDispatch } from '../../app/hooks'
import { DbLocation } from '../../common/types'
import { getPhoneMaskOptions, useCountryCode } from '../../common/utils'
import { createLocation } from '../../redux/dbLocationsSlice'
import { AddressForm } from '../Forms'

type CreateLocationModalProps = Pick<AlphaModalProps, 'isVisible' | 'setVisible'>

export const CreateLocationModal = ({
  isVisible,
  setVisible,
  locationData = {},
  onLocationCreate = () => {},
  requireContact = true,
  withOverlay,
}: CreateLocationModalProps & {
  onLocationCreate?: (location: DbLocation) => void
  locationData?: {
    address?: string
    city?: string
    state?: string
    postalCode?: string
    country?: string
  }
  requireContact?: boolean
  withOverlay?: boolean
}) => {
  const [isFormValid, setFormValid] = useState(false)
  const [data, setData] = useState<any>({})

  useEffect(() => {
    if (!isVisible) setData({})
  }, [isVisible])

  const {
    loading: { createLocation: createIsLoading },
  } = useAppSelector(state => state.dbLocations)

  const [addressData, setAddressData] = useState({
    address: '',
    addressDisplay: '',
    city: '',
    country: '',
    state: '',
    postalCode: '',
    currentPlace: null,
  })

  useEffect(() => {
    setAddressData({
      ...addressData,
      ...locationData,
    })
  }, [JSON.stringify(locationData)])

  const dispatch = useAppThunkDispatch()
  const onClose = () => setVisible(false)

  const [phoneMaskOpts, setPhoneMaskOpts] = useState<any>('')

  const countryCode = useCountryCode(addressData.country).countryCode

  useEffect(() => {
    setPhoneMaskOpts(getPhoneMaskOptions(countryCode))
  }, [countryCode])

  const onSubmit = async () => {
    const newLocation = await dispatch(createLocation({ ...data, ...addressData })).unwrap()
    onLocationCreate(newLocation)
    onClose()
  }

  const setFormData = (field: string, value: any) => setData({ ...data, [field]: value })

  return (
    <DraggableAlphaModal
      className='!overflow-visible'
      confirmButtonLabel='Create'
      isConfirmButtonDisabled={!isFormValid}
      isConfirmButtonLoading={createIsLoading}
      isVisible={isVisible}
      setVisible={setVisible}
      title='Create Location'
      withOverlay={withOverlay}
      onCancel={onClose}
      onConfirm={onSubmit}
    >
      <Form className='p-6' setFormValid={setFormValid}>
        <TextInput
          required
          sm
          className='mb-4'
          label='Location Name'
          value={data.name}
          onChange={name => setFormData('name', name)}
        />
        <AddressForm
          required
          addressData={addressData}
          setAddressData={(addressData: any) => setAddressData(addressData)}
          type='address'
          value={addressData}
        />
        <div className='grid grid-cols-2 gap-4 mt-4'>
          <TextInput
            sm
            label='Contact Phone'
            maskOptions={phoneMaskOpts}
            required={requireContact}
            type='tel'
            value={data.contactPhone}
            onChange={contactPhone => setFormData('contactPhone', contactPhone)}
          />
          <TextInput
            sm
            label='Contact Name'
            required={requireContact}
            value={data.contactName}
            onChange={contactName => setFormData('contactName', contactName)}
          />
          <TextInput
            sm
            label='Hours of Operation'
            value={data.hours}
            onChange={hours => setFormData('hours', hours)}
          />
          <TextInput
            sm
            label='Notes'
            value={data.notes}
            onChange={notes => setFormData('notes', notes)}
          />
        </div>
      </Form>
    </DraggableAlphaModal>
  )
}
