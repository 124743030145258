import { CatchError, formatAxiosErrorToPayload, getErrorString } from '@common'
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import axios from 'axios'
import { toast } from 'react-toastify'

import { EDI, LatLng, MultiStopQuotingParams, RootState, SearchFilters } from '../common/types'
import { keysToCamelCase } from '../common/utils'
import { geocode } from './locationsSlice'

const ediUrl = import.meta.env.VITE_EDI_URL

type EdiDetailsState = {
  ediList: Array<EDI>
  offset: number
  size: number
  count: number
  filters: SearchFilters
  loading: {
    list: boolean
    accept: Array<number>
    reject: Array<number>
  }
  coordinates: {
    origin: LatLng
    destination: LatLng
  }
}

const initialState: EdiDetailsState = {
  ediList: [],
  offset: 0,
  size: 50,
  count: 0,
  filters: {},
  loading: {
    list: false,
    accept: [],
    reject: [],
  },
  coordinates: {
    origin: {
      lat: 0,
      lng: 0,
    },
    destination: {
      lat: 0,
      lng: 0,
    },
  },
}

// Get list of EDI for EDI page
export const getEDIList = createAsyncThunk('edi/getEDIList', async (_, { getState }) => {
  const { filters, size = 50, offset = 0 } = (getState() as RootState).edi
  const response = await axios.get(`${ediUrl}/edi/api/external-incoming-edi-list/`, {
    params: {
      limit: size,
      offset,
      customer_company__name__icontains: filters.customerName || null,
      customer_reference_id__icontains: filters.refId || null,
      load_id: filters.loadId || null,
      status__in: filters.ediStatuses?.join(',') || null,
    },
  })
  return response.data
})

// Accept or reject EDI
export const updateEDI = createAsyncThunk(
  'edi/updateEDI',
  async ({ id, status }: { id: number; status: number }, { rejectWithValue }) => {
    try {
      const response = await axios.post(`${ediUrl}/edi/api/incoming-edi-accept-reject/`, {
        id,
        status,
      })
      return response.data
    } catch (err: CatchError) {
      return rejectWithValue(formatAxiosErrorToPayload(err))
    }
  },
)

// Get coordinates of origin and destination
export const getEDICoordinates = createAsyncThunk(
  'edi/getCoordinates',
  async (payload: MultiStopQuotingParams, { dispatch }) => {
    const origins = await dispatch(
      geocode({
        address: `${payload.origin.location?.city}, ${payload.origin.location?.state}`,
        citiesOnly: true,
      }),
    ).unwrap()
    const destinations = await dispatch(
      geocode({
        address: `${payload.destination?.location?.city}, ${payload.destination.location?.state}`,
        citiesOnly: true,
      }),
    ).unwrap()
    return {
      origin: origins[0].position,
      destination: destinations[0].position,
    }
  },
)

export const ediSlice = createSlice({
  name: 'edi',
  initialState,
  reducers: {
    setSize(state, { payload }) {
      state.size = payload
    },
    setOffset(state, { payload }) {
      state.offset = payload
    },
    setFilters(state, { payload }) {
      state.filters = {
        ...state.filters,
        ...payload,
      }
    },
  },
  extraReducers(builder) {
    builder
      .addCase(getEDIList.pending, state => {
        state.loading.list = true
      })
      .addCase(getEDIList.fulfilled, (state, action) => {
        const { results, count } = action.payload
        state.ediList = keysToCamelCase(results)
        state.count = count
        state.loading.list = false
      })
      .addCase(getEDIList.rejected, state => {
        state.loading.list = false
        toast.error('Failed to load EDI list')
      })
      .addCase(updateEDI.pending, (state, action) => {
        const { id, status } = action.meta.arg
        if (status === 1) {
          state.loading.accept.push(id)
        }
        if (status === 3) {
          state.loading.reject.push(id)
        }
      })
      .addCase(updateEDI.fulfilled, (state, action) => {
        const { id: updatedEdiId, status } = action.meta.arg
        if (status === 1) {
          state.loading.accept = state.loading.accept.filter((id: number) => id !== updatedEdiId)
        }
        if (status === 3) {
          state.loading.reject = state.loading.reject.filter((id: number) => id !== updatedEdiId)
        }
        state.ediList = state.ediList.map((edi: EDI) =>
          edi.id === updatedEdiId
            ? { ...edi, status: status === 3 ? 'Rejected' : 'Accepted' }
            : edi,
        )
        toast.success('Successfully updated EDI')
      })
      .addCase(updateEDI.rejected, (state, action) => {
        const { id: updatedEdiId, status } = action.meta.arg
        if (status === 1) {
          state.loading.accept = state.loading.accept.filter((id: number) => id !== updatedEdiId)
        }
        if (status === 3) {
          state.loading.reject = state.loading.reject.filter((id: number) => id !== updatedEdiId)
        }
        toast.error(getErrorString(action.payload, 'Failed to update EDI'))
      })
      .addCase(getEDICoordinates.fulfilled, (state, action) => {
        state.coordinates = action.payload
      })
      .addCase(getEDICoordinates.rejected, state => {
        state.coordinates = {
          origin: {
            lat: 0,
            lng: 0,
          },
          destination: {
            lat: 0,
            lng: 0,
          },
        }
      })
  },
})

export const { setFilters, setSize, setOffset } = ediSlice.actions

export default ediSlice.reducer
