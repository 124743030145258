import { Link } from '@components'
import { ArrowTopRightOnSquareIcon } from '@heroicons/react/24/outline'
import deepEqual from 'fast-deep-equal/es6/react'
import { useEffect, useState } from 'react'
import tw from 'tailwind-styled-components'

import { useAppSelector } from '../../../../app/hooks'
import { IndeterminateCheckbox } from '../../../IndeterminateCheckbox'

type Item = {
  title: string
  subtitle: string
  finished: boolean
}

export const DuplicatedLoadsList = () => {
  const [items, setItems] = useState<Item[]>([])
  const { loadDupQuantity, loadDupIds, duplicateTemplateLoad } = useAppSelector(
    state => state.loads,
    deepEqual,
  )

  useEffect(() => {
    if (loadDupQuantity && duplicateTemplateLoad) {
      const { customerReferenceId: refId } = duplicateTemplateLoad
      const items = Array(loadDupQuantity)
        .fill(0)
        .map((_, idx: number) => ({
          title: `Load ${idx + 1}`,
          subtitle: `Ref: ${refId}`,
          finished: idx < loadDupIds.length,
          editing: idx === loadDupIds.length,
          id: idx < loadDupIds.length ? loadDupIds[idx] : undefined,
        }))

      setItems(items)
    }
  }, [loadDupIds.length, loadDupQuantity])

  return (
    <div className='w-[245px] bg-dark-blue flex flex-col px-2.5 py-4 gap-5'>
      <span className='text-lighter-gray text-sm font-semibold ml-1.5'>Duplicated Loads</span>
      {items.map((item: any) => (
        <Item key={item.title} {...item} />
      ))}
    </div>
  )
}

type ItemProps = {
  title: string
  subtitle: string
  editing: boolean
  finished?: boolean
  id?: number
}

const Item = ({ title, subtitle, editing, finished, id }: ItemProps) => (
  <ItemWrapper $editing={editing}>
    <div className='flex flex-col'>
      <span className='font-semibold'>{title}</span>
      <span className='whitespace-nowrap text-xs'>{subtitle}</span>
    </div>
    <StatusWrapper $finished={finished}>
      {!finished ? (
        <div className='flex whitespace-nowrap gap-2 text-end'>
          <IndeterminateCheckbox
            disabled={!finished}
            isChecked={!!finished}
            primary={true}
            someSelected={!finished}
            className={`!h-4 !w-4 border-dark-blue ${
              !finished ? 'bg-transparent border-gray-500' : ''
            }`}
          />
          <span className='w-[74px]'>Not finished</span>
        </div>
      ) : (
        <Link className='flex gap-2 hover:text-white' to={`/loads/${id}`}>
          <ArrowTopRightOnSquareIcon className='w-4 h-4' />
          <span className='w-[74px] pl-3'>Open load</span>
        </Link>
      )}
    </StatusWrapper>
  </ItemWrapper>
)

const ItemWrapper = tw.div`
  py-0.5
  pl-1.5
  pr-1.5
  h-9
  w-full
  flex
  text-lighter-gray
  justify-between
  rounded-lg
  ${({ $editing }: { $editing: boolean }) => $editing && '!bg-link !bg-opacity-25'}
`

const StatusWrapper = tw.div<{ $finished?: boolean }>`
  flex
  items-center
  text-xs
  px-2
  py-1
  rounded-md
  ${({ $finished }) => $finished && 'bg-success'}
`
