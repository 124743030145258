import { useDeepDependencies } from '@common'
import { DraggableAlphaModal, RadioInput, Status, TextInput } from '@components'
import { ExclamationTriangleIcon } from '@heroicons/react/24/outline'
import { useCallback, useEffect, useState } from 'react'
import tw from 'tailwind-styled-components'

import { useAppSelector, useAppThunkDispatch } from '../../app/hooks'
import { CarrierLookupHighway } from '../../common/types'
import { capitalizeAllWords, formatCurrency } from '../../common/utils'
import { checkCarrierMcOrDot, resetCarrierLookup } from '../../redux/carriersSlice'
import { HalfWidthDetailItem } from '../DetailItem'

enum Steps {
  INPUT = 'INPUT',
  RESULTS = 'RESULTS',
}

enum SearchTypes {
  SEARCH_BY_MC = 'SEARCH_BY_MC',
  SEARCH_BY_DOT = 'SEARCH_BY_DOT',
}

const InsuranceItem = ({
  insuranceItem,
}: {
  insuranceItem: CarrierLookupHighway['attributes']['insurance']['insurancePolicies'][0]
}) => (
  <InsuranceItemContainer>
    <HalfWidthDetailItem label='Insurance Type'>{insuranceItem.insuranceType}</HalfWidthDetailItem>
    <HalfWidthDetailItem label='Status'>{insuranceItem.insuranceStatus}</HalfWidthDetailItem>
    <HalfWidthDetailItem label='Insurer'>
      {capitalizeAllWords(insuranceItem.insurerName)}
    </HalfWidthDetailItem>
    <HalfWidthDetailItem label='Policy #'>{insuranceItem.policy}</HalfWidthDetailItem>
    <HalfWidthDetailItem label='Effective Date'>{insuranceItem.effectiveDate}</HalfWidthDetailItem>
    <HalfWidthDetailItem label='Coverage'>
      {formatCurrency(insuranceItem.coverage)}
    </HalfWidthDetailItem>
  </InsuranceItemContainer>
)

export const CarrierCheckModal = ({
  isVisible,
  setVisible,
}: {
  isVisible: boolean
  setVisible: (value: boolean) => void
}) => {
  const [query, setQuery] = useState('')
  const [step, setStep] = useState(Steps.INPUT)
  const [searchType, setSearchType] = useState(SearchTypes.SEARCH_BY_MC)

  const loading = useAppSelector(state => state.carriers.loading.checkCarrierMcOrDot)
  const carrierLookup = useAppSelector(state => state.carriers.carrierLookup)

  const dispatch = useAppThunkDispatch()

  const onClose = useCallback(() => {
    dispatch(resetCarrierLookup())
    setQuery('')
    setSearchType(SearchTypes.SEARCH_BY_MC)
    setStep(Steps.INPUT)
    setVisible(false)
  }, [])

  useEffect(() => {
    if (carrierLookup) setStep(Steps.RESULTS)
    else setStep(Steps.INPUT)
  }, useDeepDependencies(carrierLookup))

  const onSubmit = useCallback(async () => {
    if (step === Steps.INPUT) dispatch(checkCarrierMcOrDot({ query, searchType }))
    else {
      dispatch(resetCarrierLookup())
      setQuery('')
    }
  }, [step, query, searchType])

  // Parse complete authority details from the different response sections
  let commonAuthority = null
  let contractAuthority = null
  let brokerAuthority = null
  if (carrierLookup?.attributes?.authority) {
    commonAuthority = carrierLookup.attributes.authority.common
    contractAuthority = carrierLookup.attributes.authority.contract
    brokerAuthority = carrierLookup.attributes.authority.broker
  }
  if (commonAuthority && commonAuthority == 'Active')
    commonAuthority += ` (${carrierLookup?.attributes.header.common})`
  else if (!commonAuthority) commonAuthority = carrierLookup?.attributes.header.common

  if (contractAuthority && contractAuthority == 'Active')
    contractAuthority += ` (${carrierLookup?.attributes.header.contract})`
  else if (!contractAuthority) contractAuthority = carrierLookup?.attributes.header.contract

  if (brokerAuthority && brokerAuthority == 'Active')
    brokerAuthority += ` (${carrierLookup?.attributes.header.broker})`
  else if (!brokerAuthority) brokerAuthority = carrierLookup?.attributes.header.broker

  const formatAddress = (address: string) =>
    `${capitalizeAllWords(address.split(',').slice(0, -1).join(','))},${address
      .split(',')
      .slice(-1)}`

  return (
    <DraggableModal
      withOverlay
      $step={step}
      confirmButtonLabel={step === Steps.INPUT ? 'Search' : 'New Search'}
      isConfirmButtonDisabled={step === Steps.INPUT && !query}
      isVisible={isVisible}
      setVisible={setVisible}
      title='Carrier Check'
      isConfirmButtonLoading={loading}
      onCancel={onClose}
      onConfirm={onSubmit}
    >
      {!carrierLookup || step === Steps.INPUT ? (
        <div className='p-4'>
          <TextInput sm label='Carrier ID' value={query} onChange={setQuery} />
          <div className='mt-2'>
            <div className='text-dark-gray'>ID Type</div>
            <div className='flex items-center'>
              <RadioInput
                className='mr-4 item-center'
                label='MC'
                checked={searchType === SearchTypes.SEARCH_BY_MC}
                onChange={() => setSearchType(SearchTypes.SEARCH_BY_MC)}
              />
              <RadioInput
                label='DOT'
                checked={searchType === SearchTypes.SEARCH_BY_DOT}
                onChange={() => setSearchType(SearchTypes.SEARCH_BY_DOT)}
              />
            </div>
          </div>
        </div>
      ) : (
        <div className='p-4  max-h-[min(700px,75vh)] overflow-scroll'>
          {carrierLookup.attributes.alerts?.identityAlerts && (
            <div className='mb-4 border-error rounded border-2 bg-error/20'>
              <div className='text-lg font-bold mb-2 pl-2 flex  items-center'>
                Alerts <ExclamationTriangleIcon className='w-5 mx-2 pt-0.5' />
              </div>
              <div className='flex font-bold mb-2 pl-2'>
                <div className='w-1/6'>Incident Type</div>
                <div className='w-1/6'>Incident Date</div>
                <div className='w-2/3'>Incident Details</div>
              </div>
              {carrierLookup.attributes.alerts.identityAlerts.alertDetails.map(alert => (
                <div
                  key={alert['alert#']}
                  className='flex border-b border-error last:border-b-0 p-2'
                >
                  <div className='w-1/6'>{capitalizeAllWords(alert.alertType)}</div>
                  <div className='w-1/6'>{alert.timePeriod}</div>
                  <div className='w-2/3'>{alert.incidentDetails}</div>
                </div>
              ))}
            </div>
          )}
          <div className='mb-4'>
            <div className='text-lg font-bold'>Carrier Info</div>
            <div className='mb-2'>
              <span className='text-xs text-link'>
                <a
                  className='hover:underline'
                  href={carrierLookup.urlCarrierDetails}
                  target='_blank'
                  rel='noreferrer'
                >
                  Highway Profile
                </a>
              </span>
              {Boolean(carrierLookup.carrierCompany) && (
                <span className='text-xs text-link ml-4'>
                  <a
                    className='hover:underline'
                    href={`/carriers/${carrierLookup.carrierCompany}`}
                    target='_blank'
                    rel='noreferrer'
                  >
                    EXO Freight Profile
                  </a>
                </span>
              )}
            </div>
            <div className='flex mb-2'>
              <HalfWidthDetailItem className='w-1/2' label='Name'>
                {capitalizeAllWords(carrierLookup.attributes.header.carrierName)}
              </HalfWidthDetailItem>
              <HalfWidthDetailItem className='w-1/2' label='Address'>
                {formatAddress(carrierLookup.attributes.header.dispatchContact.address || '')}
              </HalfWidthDetailItem>
            </div>
            <div className='flex mb-2'>
              <HalfWidthDetailItem className='w-1/2' label='MC'>
                {carrierLookup.attributes.header.mcDot.mc}
              </HalfWidthDetailItem>
              <HalfWidthDetailItem className='w-1/2' label='DOT'>
                {carrierLookup.attributes.header.mcDot.dot}
              </HalfWidthDetailItem>
            </div>
            <div className='flex mb-2'>
              <HalfWidthDetailItem className='w-1/2' label='Dispatch Email'>
                {carrierLookup.attributes.header.dispatchContact.email?.toLowerCase()}
              </HalfWidthDetailItem>
              <HalfWidthDetailItem className='w-1/2' label='Dispatch Phone'>
                {carrierLookup.attributes.header.dispatchContact.phoneNumber}
              </HalfWidthDetailItem>
            </div>
          </div>
          <div className='mb-4'>
            <div className='text-lg font-bold mb-2'>Authority</div>
            <div className='flex'>
              <HalfWidthDetailItem className='w-1/4' label='Common'>
                {capitalizeAllWords(commonAuthority || '')}
              </HalfWidthDetailItem>
              <HalfWidthDetailItem className='w-1/4' label='Contract'>
                {capitalizeAllWords(contractAuthority || '')}
              </HalfWidthDetailItem>
              <HalfWidthDetailItem className='w-1/4' label='Broker'>
                {capitalizeAllWords(brokerAuthority || '')}
              </HalfWidthDetailItem>
              <HalfWidthDetailItem className='w-1/4' label='Licensed Capabilities'>
                {carrierLookup.attributes.authority?.licensedCapabilities.join(', ')}
              </HalfWidthDetailItem>
            </div>
          </div>
          <div className='mb-4'>
            <div className='text-lg font-bold mb-2'>Insurance</div>
            {!carrierLookup.attributes.insurance.insurancePolicies.length &&
              'No Insurance Policies Found.'}
            {carrierLookup.attributes.insurance.insurancePolicies.map((policy: any) => (
              <InsuranceItem key={policy.policy} insuranceItem={policy} />
            ))}
          </div>
          {carrierLookup.attributes.assessment && (
            <div className='mb-4'>
              <div className='text-lg font-bold mb-2'>Highway Assessment</div>
              <div className='flex'>
                <HalfWidthDetailItem className='w-1/4' label='Authorization'>
                  {capitalizeAllWords(carrierLookup.attributes.assessment.authorization || '')}
                </HalfWidthDetailItem>
                <HalfWidthDetailItem className='w-3/4' label='Attributes'>
                  {
                    <div className='flex flex-wrap'>
                      {carrierLookup.attributes.assessment.attributes.map(attribute => (
                        <AssessmentAttribute
                          key={attribute.text}
                          $isWarning={attribute.isWarning}
                          label={attribute.text}
                        />
                      ))}
                    </div>
                  }
                </HalfWidthDetailItem>
              </div>
            </div>
          )}
          {carrierLookup.attributes.insights && (
            <div className='mb-4'>
              <div className='text-lg font-bold mb-2'>Highway Insights</div>
              <div className='flex'>
                <HalfWidthDetailItem className='w-1/3' label='Operational Insights'>
                  <div className='flex flex-wrap'>
                    {carrierLookup.attributes.insights.operationInsights?.map(insight => (
                      <Status key={insight} className='bg-error/50 border-white' label={insight} />
                    ))}
                  </div>
                </HalfWidthDetailItem>
                <HalfWidthDetailItem className='w-1/3' label='Association Insights'>
                  {
                    <div className='flex flex-wrap'>
                      {carrierLookup.attributes.insights.associationInsights?.map(insight => (
                        <Status
                          key={insight}
                          className='bg-error/50 border-white'
                          label={insight}
                        />
                      ))}
                    </div>
                  }
                </HalfWidthDetailItem>
                <HalfWidthDetailItem className='w-1/3' label='Location Insights'>
                  {
                    <div className='flex flex-wrap'>
                      {carrierLookup.attributes.insights.locationInsights?.map(insight => (
                        <Status
                          key={insight}
                          className='bg-error/50 border-white'
                          label={insight}
                        />
                      ))}
                    </div>
                  }
                </HalfWidthDetailItem>
              </div>
            </div>
          )}
          <div className='mb-4'>
            <div className='text-lg font-bold mb-2'>Contacts</div>
            <div className='flex'>
              <HalfWidthDetailItem className='w-1/3' label='Names'>
                {Object.keys(carrierLookup.attributes.contactInformation.names).map(key => (
                  <div key={key}>
                    {capitalizeAllWords(key)} —{' '}
                    <span className='text-dark-gray'>
                      {capitalizeAllWords(carrierLookup.attributes.contactInformation.names[key])}
                    </span>
                  </div>
                ))}
              </HalfWidthDetailItem>
              <HalfWidthDetailItem className='w-1/3' label='Phone Numbers'>
                {Object.keys(carrierLookup.attributes.contactInformation.phoneNumbers).map(key => (
                  <div key={key}>
                    {capitalizeAllWords(key)} —{' '}
                    <span className='text-dark-gray'>
                      {capitalizeAllWords(
                        carrierLookup.attributes.contactInformation.phoneNumbers[key],
                      )}
                    </span>
                  </div>
                ))}
              </HalfWidthDetailItem>
              <HalfWidthDetailItem className='w-1/3' label='Emails'>
                {Object.keys(carrierLookup.attributes.contactInformation.emails).map(key => (
                  <div key={key}>
                    {capitalizeAllWords(key)} —{' '}
                    <span className='text-dark-gray'>
                      {capitalizeAllWords(carrierLookup.attributes.contactInformation.emails[key])}
                    </span>
                  </div>
                ))}
              </HalfWidthDetailItem>
            </div>
            <HalfWidthDetailItem className='w-full' label='Addresses'>
              {Object.keys(carrierLookup.attributes.contactInformation.addresses).map(key => (
                <div key={key}>
                  {capitalizeAllWords(key)} —{' '}
                  <span className='text-dark-gray'>
                    {capitalizeAllWords(carrierLookup.attributes.contactInformation.addresses[key])}
                  </span>
                </div>
              ))}
            </HalfWidthDetailItem>
          </div>
        </div>
      )}
    </DraggableModal>
  )
}

const DraggableModal = tw(DraggableAlphaModal)<{ $step: Steps }>`
  !overflow-visible
  !min-w-[400px]
  ${({ $step }) => ($step === Steps.INPUT ? '' : 'w-[800px]')}
`

const InsuranceItemContainer = tw.div`
  flex
  odd:bg-lighter-gray
  p-2
  justify-between
`

const AssessmentAttribute = tw(Status)<{ $isWarning: boolean }>`
  border-white
  ${({ $isWarning }) => ($isWarning ? 'bg-error/50' : 'bg-success/50')}
`
