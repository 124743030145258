import tw from 'tailwind-styled-components'

import { SectionProps } from '../types'

export const Section = ({
  title,
  children,
  tagTitle,
  tagClassName,
  actionBtn,
  badgeCount,
  bodyClassName,
  className,
}: SectionProps) => (
  <div className={`flex flex-col ${className}`}>
    <div className='flex items-center justify-between mb-4'>
      <div className='flex gap-2 items-center'>
        {tagTitle && (
          <TagTitleContainer className={tagClassName}>
            <span>{tagTitle}</span>
          </TagTitleContainer>
        )}
        <span className='uppercase font-semibold text-xs'>{title}</span>
        {badgeCount !== undefined && (
          <BadgeCount>
            <span>{badgeCount}</span>
          </BadgeCount>
        )}
      </div>
      {actionBtn && actionBtn}
    </div>
    <div className={`w-full ${bodyClassName}`}>{children}</div>
  </div>
)

const BadgeCount = tw.div`
  h-[18px]
  w-[18px]
  bg-light-blue
  rounded-full
  text-[10px]
  font-semibold
  flex
  items-center
  justify-center
`

const TagTitleContainer = tw.div`
  h-4
  rounded-sm
  text-dark-blue
  uppercase
  text-[8px]
  py-0.5
  px-1
  font-bold
  flex
  items-center
`
