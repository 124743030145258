import { useAppSelector, useAppThunkDispatch } from '../../../app/hooks'
import { randomString, useCustomerType } from '../../../common/utils'
import { setCustomerInfo } from '../../../redux/customersSlice'
import { AddButton, ExternalPortal, SectionHeader } from '../components'

export const ExternalPortals = () => {
  const type = useCustomerType()

  const dispatch = useAppThunkDispatch()

  const externalPortals = useAppSelector(state => state.customers[type].externalPortals)

  const addNewPortal = () =>
    dispatch(
      setCustomerInfo({
        externalPortals: [
          ...externalPortals,
          {
            id: randomString(),
            name: '',
            address: '',
            email: '',
            password: '',
            notes: '',
            isValid: false,
          },
        ],
      }),
    )

  return (
    <>
      <SectionHeader>External Portals</SectionHeader>
      {externalPortals.map((portal, i) => (
        <ExternalPortal key={portal.id} portal={portal} index={i} />
      ))}
      <AddButton onClick={addNewPortal}>Add Portal</AddButton>
    </>
  )
}
