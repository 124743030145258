import { Form, TextInput } from '@components'

import { useAppSelector, useAppThunkDispatch } from '../../../app/hooks'
import { CustomerTeamEmail } from '../../../common/types'
import { useCustomerType } from '../../../common/utils'
import { setCustomerInfo, setIdsToDelete } from '../../../redux/customersSlice'
import { DeleteButton } from './DeleteButton'

export const TeamEmail = ({ email, index }: { email: CustomerTeamEmail; index: number }) => {
  const type = useCustomerType()

  const dispatch = useAppThunkDispatch()

  const contactsStep = useAppSelector(state => state.customers[type].contactsStep)
  const idsToDelete = useAppSelector(state => state.customers.idsToDelete)

  const { teamEmails } = contactsStep

  const setTeamEmails = (newData: CustomerTeamEmail[]) =>
    dispatch(setCustomerInfo({ contactsStep: { ...contactsStep, teamEmails: newData } }))

  const deleteEmail = () => {
    setTeamEmails(teamEmails.filter(te => email.id !== te.id))
    dispatch(setIdsToDelete({ teamEmails: [...idsToDelete.teamEmails, email.id].filter(Number) }))
  }

  const updateEmail = (newData: Partial<CustomerTeamEmail>) => {
    const tempEmails = [...teamEmails]
    tempEmails[index] = { ...tempEmails[index], ...newData }
    setTeamEmails(tempEmails)
  }

  return (
    <div className='mt-6 first:mt-0'>
      <Form setFormValid={isValid => email.isValid !== isValid && updateEmail({ isValid })}>
        <div className='grid grid-cols-2 gap-4'>
          <TextInput sm value={email.name} label='Name' onChange={name => updateEmail({ name })} />
          <TextInput
            sm
            value={email.email}
            label='Email'
            type='email'
            onChange={email => updateEmail({ email })}
          />
        </div>
      </Form>
      {!!index && <DeleteButton onClick={deleteEmail} />}
    </div>
  )
}
