import { CatchError, formatAxiosErrorToPayload, getErrorString } from '@common'
import { downloadCSV } from '@common'
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { cloneDeep } from 'lodash-es'
import { toast } from 'react-toastify'

import { api } from '../api/api'
import { initialFilters } from '../common/constants'
import {
  CSVField,
  FactoringCompany,
  FactoringCompanyPayment,
  LoadCarrier,
  RootState,
  SearchFilters,
} from '../common/types'
import { cleanFilters, keysToCamelCase, keysToSnakeCase, serializeAddress } from '../common/utils'

const getFactoringDetails = (data: FactoringCompany) => ({
  postalCode: data.postalCode,
  usZipcode: data.postalCode,
  caPostalCode: data.postalCode,
  stateProvinceRegion: data.state,
  caProvince: data.state,
  address: data.addressLine1,
  addressDisplay: serializeAddress({
    street: data.addressLine1,
    city: data.city,
    stateCode: data.state,
    postalCode: data.postalCode,
    countryCode: data.country,
  }),
})

type FactoringCompanyState = {
  factoringCompanies: Array<FactoringCompany>
  factoringCompanyDetails: FactoringCompany
  updatedFactoringCompanyDetails: FactoringCompany
  loading: {
    createFactoringCompany: boolean
    factoringCompanies: boolean
    factoringCompanyDetails: boolean
    factoringCompanyCarriers: boolean
    factoringCompanyPayments: boolean
    updateFactoringCompany: boolean
    mergeFactoringCompany: boolean
    exportCSV: boolean
  }
  error: boolean
  count: number
  offset: number
  size: number
  filters: SearchFilters
  factoringCompanyCarriers: {
    carriers: Array<LoadCarrier>
    count: number
    offset: number
    size: number
  }
  factoringCompanyPayments: {
    payments: Array<FactoringCompanyPayment>
    count: number
    offset: number
    size: number
  }
  selectedFactoringCompany: FactoringCompany
  currentFactoringCompany: FactoringCompany
  exportFields: CSVField[]
  selectedFields: Array<string>
}

const initialState: FactoringCompanyState = {
  factoringCompanies: [],
  factoringCompanyDetails: {},
  updatedFactoringCompanyDetails: {},
  loading: {
    createFactoringCompany: false,
    factoringCompanies: false,
    factoringCompanyDetails: false,
    factoringCompanyCarriers: false,
    factoringCompanyPayments: false,
    updateFactoringCompany: false,
    mergeFactoringCompany: false,
    exportCSV: false,
  },
  error: false,
  count: 0,
  offset: 0,
  size: 50,
  filters: initialFilters,
  factoringCompanyCarriers: {
    carriers: [],
    count: 0,
    offset: 0,
    size: 50,
  },
  factoringCompanyPayments: {
    payments: [],
    count: 0,
    offset: 0,
    size: 50,
  },
  selectedFactoringCompany: {},
  currentFactoringCompany: {},
  exportFields: [
    { label: 'Company Name', key: 'business_name' },
    { label: 'Address', key: 'address_line_1' },
    { label: 'City', key: 'city' },
    { label: 'State', key: 'state' },
    { label: 'Country', key: 'country' },
    { label: 'Phone', key: 'phone' },
    { label: 'Email', key: 'email' },
  ],
  selectedFields: [
    'business_name',
    'address_line_1',
    'city',
    'state',
    'postal_code',
    'country',
    'phone',
    'email',
  ],
}

const getFilters = (filters: SearchFilters) =>
  cleanFilters({
    business_name__icontains: filters.name,
    city__icontains: filters.city,
    state__icontains: filters.state,
    email__icontains: filters.email,
    phone__icontains: filters.phone,
  })

export const getFactoringCompanies = createAsyncThunk(
  'factoringCompanies/getFactoringCompanies',
  async (_, { getState }) => {
    const { size = 50, offset = 0, filters } = (getState() as RootState).factoring

    const response = await api.get('/accounts/api/factoring/list-create/', {
      params: {
        limit: size,
        offset,
        ...getFilters(filters),
      },
    })

    return response.data
  },
)

export const getFactoringCompanyDetails = createAsyncThunk(
  'factoringCompanies/getFactoringCompanyDetails',
  async (id: string | number | undefined) =>
    api.get(`/accounts/api/factoring/detail/${id}/`).then(({ data }) => data),
)

export const mergeFactoringCompany = createAsyncThunk(
  'factoringCompanies/mergeFactoringCompany',
  async (_, { dispatch, getState, rejectWithValue }) => {
    const { selectedFactoringCompany, currentFactoringCompany } = (getState() as RootState)
      .factoring

    try {
      const response = await api.post('/accounts/api/factoring/merge/', {
        source_id: currentFactoringCompany.id,
        target_id: selectedFactoringCompany.id,
      })
      dispatch(getFactoringCompanies())
      return response.data
    } catch (err: CatchError) {
      return rejectWithValue(formatAxiosErrorToPayload(err))
    }
  },
)

export const updateFactoringCompany = createAsyncThunk(
  'factoringCompanies/updateFactoringCompany',
  async (payload: FactoringCompany, { getState, rejectWithValue }) => {
    const {
      factoringCompanyDetails: { id },
    } = (getState() as RootState).factoring

    const data = {
      id: 4,
      businessName: payload.businessName,
      addressLine_1: payload.address,
      addressLine_2: null,
      postalCode: payload.usZipcode || payload.caPostalCode || payload.postalCode,
      city: payload.city,
      state: payload.state || payload.stateProvinceRegion || payload.caProvince,
      country: payload.country,
      phone: payload.phone,
      email: payload.email,
      contactName: payload.contactName,
    }

    try {
      const response = await api.put(`/accounts/api/factoring/detail/${id}/`, keysToSnakeCase(data))
      return response.data
    } catch (err: CatchError) {
      return rejectWithValue(formatAxiosErrorToPayload(err))
    }
  },
)

export const getFactoringCompanyCarriers = createAsyncThunk(
  'factoringCompanies/getFactoringCompanyCarriers',
  async (_, { getState }) => {
    const {
      factoringCompanyDetails: { id },
      factoringCompanyCarriers: { offset, size },
    } = (getState() as RootState).factoring
    const response = await api.get(`/accounts/api/factoring/carrier-list/${id}/`, {
      params: {
        offset,
        limit: size,
      },
    })
    return response.data
  },
)

export const getFactoringCompanyPayments = createAsyncThunk(
  'factoringCompanies/getFactoringCompanyPayments',
  async (_, { getState }) => {
    const {
      factoringCompanyDetails: { id },
      factoringCompanyPayments: { offset, size },
    } = (getState() as RootState).factoring
    const response = await api.get(`/billing/api/factoring-payment-list/${id}/`, {
      params: {
        offset,
        limit: size,
      },
    })
    return response.data
  },
)

export const createFactoringCompany = createAsyncThunk(
  'factoringCompanies/createFactoringCompany',
  async (factoringCompany: any, { rejectWithValue }) => {
    try {
      const response = await api.post(
        '/accounts/api/factoring/list-create/',
        keysToSnakeCase(factoringCompany),
      )
      return response.data
    } catch (err: CatchError) {
      return rejectWithValue(formatAxiosErrorToPayload(err))
    }
  },
)

export const exportFactoringCSV = createAsyncThunk(
  'factoringCompanies/exportFactoringCSV',
  async (_, { getState, rejectWithValue }) => {
    const { filters, selectedFields } = (getState() as RootState).factoring

    try {
      const response = await api.post('/accounts/api/export-account-data/', {
        type: 'factoring',
        filters: getFilters(filters),
        fields: selectedFields.join(','),
      })

      downloadCSV(response.data, 'factoring')
    } catch (err: CatchError) {
      return rejectWithValue(formatAxiosErrorToPayload(err))
    }
  },
)

export const factoringCompaniesSlice = createSlice({
  name: 'factoringCompanies',
  initialState,
  reducers: {
    setSize(state, { payload }) {
      state.size = payload
    },
    setOffset(state, { payload }) {
      state.offset = payload
    },
    setFilters(state, { payload }) {
      state.filters = payload
    },
    setCarriersSize(state, { payload }) {
      state.factoringCompanyCarriers.size = payload
    },
    setCarriersOffset(state, { payload }) {
      state.factoringCompanyCarriers.offset = payload
    },
    setPaymentsSize(state, { payload }) {
      state.factoringCompanyPayments.size = payload
    },
    setPaymentsOffset(state, { payload }) {
      state.factoringCompanyPayments.offset = payload
    },
    setUpdatedFactoringCompanyDetails(state, { payload }) {
      state.updatedFactoringCompanyDetails = payload
    },
    setSelectedFactoringCompany(state, { payload }) {
      state.selectedFactoringCompany = payload
    },
    setCurrentFactoringCompany(state, { payload }) {
      state.currentFactoringCompany = payload
    },
    setSelectedFields(state, { payload }) {
      state.selectedFields =
        typeof payload === 'string'
          ? state.selectedFields.includes(payload)
            ? state.selectedFields.filter(field => field !== payload)
            : [...state.selectedFields, payload]
          : payload
    },
  },
  extraReducers(builder) {
    builder
      .addCase(createFactoringCompany.pending, state => {
        state.loading.createFactoringCompany = true
      })
      .addCase(createFactoringCompany.fulfilled, (state, action) => {
        state.loading.createFactoringCompany = false
        state.factoringCompanies = [keysToCamelCase(action.payload), ...state.factoringCompanies]
        toast.success('Successfully created factoring company')
      })
      .addCase(createFactoringCompany.rejected, (state, action) => {
        state.loading.createFactoringCompany = false
        toast.error(getErrorString(action.payload, 'Error creating new factoring company'))
      })
      .addCase(getFactoringCompanies.pending, state => {
        state.loading.factoringCompanies = true
      })
      .addCase(getFactoringCompanies.fulfilled, (state, action) => {
        const { count, results } = action.payload
        state.factoringCompanies = keysToCamelCase(results)
        state.count = count
        state.loading.factoringCompanies = false
      })
      .addCase(getFactoringCompanies.rejected, state => {
        state.loading.factoringCompanies = false
        toast.error('Error getting factoring companies')
      })
      .addCase(getFactoringCompanyDetails.pending, state => {
        state.loading.factoringCompanyDetails = true
      })
      .addCase(getFactoringCompanyDetails.fulfilled, (state, action) => {
        const data = keysToCamelCase({
          ...action.payload,
          phone: (action.payload.phone ?? '').replaceAll('-', ''),
        })
        const factoringCompany = {
          ...data,
          ...getFactoringDetails(data),
        }
        state.factoringCompanyDetails = factoringCompany
        state.updatedFactoringCompanyDetails = factoringCompany
        state.loading.factoringCompanyDetails = false
      })
      .addCase(getFactoringCompanyDetails.rejected, state => {
        state.loading.factoringCompanyDetails = false
        toast.error('Error getting factoring company details')
      })
      .addCase(getFactoringCompanyCarriers.pending, state => {
        state.loading.factoringCompanyCarriers = true
      })
      .addCase(getFactoringCompanyCarriers.fulfilled, (state, action) => {
        const { count, results } = action.payload
        state.factoringCompanyCarriers.carriers = keysToCamelCase(results)
        state.factoringCompanyCarriers.count = count
        state.loading.factoringCompanyCarriers = false
      })
      .addCase(getFactoringCompanyCarriers.rejected, state => {
        state.loading.factoringCompanyCarriers = false
        toast.error('Error getting carriers')
      })
      .addCase(getFactoringCompanyPayments.pending, state => {
        state.loading.factoringCompanyPayments = true
      })
      .addCase(getFactoringCompanyPayments.fulfilled, (state, action) => {
        const { count, results } = action.payload
        state.factoringCompanyPayments.payments = keysToCamelCase(results)
        state.factoringCompanyPayments.count = count
        state.loading.factoringCompanyPayments = false
      })
      .addCase(getFactoringCompanyPayments.rejected, state => {
        state.loading.factoringCompanyPayments = false
        toast.error('Error getting payments')
      })
      .addCase(updateFactoringCompany.pending, state => {
        state.loading.updateFactoringCompany = true
      })
      .addCase(updateFactoringCompany.fulfilled, (state, action) => {
        const data = keysToCamelCase(action.payload)
        state.factoringCompanyDetails = {
          ...state.factoringCompanyDetails,
          ...data,
          ...getFactoringDetails(data),
        }
        state.updatedFactoringCompanyDetails = cloneDeep(state.factoringCompanyDetails)
        state.loading.updateFactoringCompany = false
        toast.success('Successfully updated factoring company')
      })
      .addCase(updateFactoringCompany.rejected, (state, action) => {
        state.loading.updateFactoringCompany = false
        toast.error(getErrorString(action.payload, 'Failed to update factoring company'))
      })
      .addCase(mergeFactoringCompany.pending, state => {
        state.loading.mergeFactoringCompany = true
      })
      .addCase(mergeFactoringCompany.fulfilled, state => {
        toast.success('Successfully replaced factoring company')
        state.loading.mergeFactoringCompany = false
      })
      .addCase(mergeFactoringCompany.rejected, (state, action) => {
        state.loading.mergeFactoringCompany = false
        toast.error(getErrorString(action.payload, 'Failed to replace factoring company'))
      })
      .addCase(exportFactoringCSV.pending, state => {
        state.loading.exportCSV = true
      })
      .addCase(exportFactoringCSV.fulfilled, state => {
        state.loading.exportCSV = false
        toast.success('Successfully exported CSV')
      })
      .addCase(exportFactoringCSV.rejected, (state, { payload }) => {
        state.loading.exportCSV = false
        toast.error(getErrorString(payload, 'Failed to export CSV'))
      })
  },
})

export const {
  setSize,
  setOffset,
  setFilters,
  setCarriersOffset,
  setCarriersSize,
  setPaymentsOffset,
  setPaymentsSize,
  setUpdatedFactoringCompanyDetails,
  setSelectedFactoringCompany,
  setCurrentFactoringCompany,
  setSelectedFields,
} = factoringCompaniesSlice.actions

export default factoringCompaniesSlice.reducer
