import {
  CarrierLocationTrackingEvent,
  CatchError,
  downloadCSV,
  formatAxiosErrorToPayload,
  getErrorString,
} from '@common'
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { filter, groupBy, isEmpty, isEqual, omit } from 'lodash-es'
import { toast } from 'react-toastify'

import { api } from '../api/api'
import {
  initialCommodity,
  initialFilters,
  initialLoadInfo,
  initialTrackInfo,
} from '../common/constants'
import {
  Accessorial,
  CSVField,
  FactoringDocument,
  Load,
  LoadCommodity,
  LoadDocument,
  LoadHistory,
  LoadInfo,
  LoadNote,
  LoadsBeingViewed,
  LoadsListItem,
  LoadSMS,
  LoadStop,
  Manifest,
  NewAccessorial,
  NewLoadNote,
  RootState,
  RouteInfo,
  SearchFilters,
  SMSTemplate,
  Stop,
  TableOrder,
  TenderItem,
  TrackingEvent,
  ValidatedCarrierContact,
  WaterfallAction,
} from '../common/types'
import { TenderedCarrier } from '../common/types/contractLane'
import {
  booleanFromYesNoString,
  cleanFilters,
  formatArrayOfIds,
  formatDateForBackend,
  formatFileName,
  getLoadChangeStatuses,
  getOrderingString,
  keysToCamelCase,
  keysToSnakeCase,
  normalizeTimeForBackend,
  randomString,
  validateContact,
} from '../common/utils'
import { saveCarrierDetails } from './carriersSlice'
import { setPayables } from './invoicingSlice'
import { normalizeFactoringTenderItems, normalizeTenderItems } from './normalize'

type LoadsState = {
  loads: LoadsListItem[]
  count: number
  offset: number
  size: number
  scroll: number
  search: string
  filters: SearchFilters
  order?: TableOrder
  orderDisplay: string
  loadDetails: Load
  loadBookMethod: {
    bookMethod: string | null
    additionalInfo: string | null
  }
  carrierContacts: ValidatedCarrierContact[]
  customerAccessorials: Array<Accessorial>
  customerAccessorialsBackup: Array<Accessorial>
  carrierAccessorials: Array<Accessorial>
  carrierAccessorialsBackup: Array<Accessorial>
  loading: {
    loads: boolean
    createLoad: boolean
    loadDetails: boolean
    addNewAccessorial: boolean
    loadAccessorials: boolean
    deleteAccessorial: boolean
    updateAccessorial: boolean
    loadNotes: boolean
    loadHistory: boolean
    loadDocuments: boolean
    loadSMS: boolean
    loadTrackingUpdate: boolean
    updateLoad: boolean
    saveLoadChanges: boolean
    postToLoadboard: boolean
    updateStop: boolean
    updateStopOrder: boolean
    addNewStop: boolean
    deleteLoadStop: boolean
    updateManifestItem: boolean
    addManifestItem: boolean
    hold: boolean
    archive: boolean
    deleteDocument: boolean
    deleteNote: boolean
    holdReasons: boolean
    blindBOL: boolean
    trackShipment: boolean
    stopTrackingShipment: boolean
    createBOL: boolean
    createRateConfirmation: boolean
    exportCSV: boolean
    checkPaymentReceived: boolean
    sendDigitalRateCon: boolean
    carrierContacts: boolean
    toggleWaterfall: boolean
    getWaterfallTenderedCarriers: boolean
    locationEvents: boolean
    updateStatus: boolean
  }
  tenderItems: Array<TenderItem>
  backupTenderItems: Array<TenderItem>
  loadNotes: Array<LoadNote>
  loadNotesCount: number
  loadHistory: Array<LoadHistory>
  loadHistoryCount: number
  loadHistoryFilterField: string
  loadHistoryLimit: number
  loadDocuments: Array<LoadDocument>
  loadDocumentsCount: number
  loadSMS: Array<LoadSMS>
  loadSMSCount: number
  smsTemplates: Array<SMSTemplate>
  loadTrackingUpdate: { [key: string]: Array<TrackingEvent> }
  allLoadboards: Array<{ name: string; isEnabled: boolean }>
  changedLoadInfo: LoadInfo
  loadInfo: LoadInfo
  routeInfo: RouteInfo
  changedRouteInfo: RouteInfo
  commodity: LoadCommodity
  changedCommodity: LoadCommodity
  selectedCarrier: any
  selectedContact: any
  driverInfo: { phone: string | null; name: string | null }
  trackInfo: { notifyEmails: string[]; updateFrequency: number; isTrack: boolean }
  pickupItems: Array<Manifest>
  trackingEvents: Array<TrackingEvent>
  backupCarrier: {
    carrier: string
    dispatcher: number | null
    driverName: string
    driverPhone: string
  }
  holdReasons: Array<{ id: number; reason: string }>
  showDupLoadsDialog: boolean
  showDuplicationReviewModal: boolean
  showUnfinishedDupDialog: boolean
  showDeleteDupLoadDialog: boolean
  loadDupQuantity: number
  dupDialogCreated: boolean
  duplicateTemplateLoad?: Load
  createLoadModalVisible: boolean
  customerCompanyName: string
  loadDupIds: number[]
  poNumbers: Array<{ id?: string; name: string }>
  exportFields: CSVField[]
  selectedFields: Array<string>
  uiState: {
    selectedLoadsTableColumns: Array<string>
  }
  vehicleInfo: { truckNumber?: string | null; trailerNumber?: string | null }
  loadsBeingViewed: LoadsBeingViewed
  isEditingConfirmationDialogVisible: boolean
  onActionButtonConfirm?: () => void
  isMainUser: boolean
  mainUserName: string
  isBOLModalVisible: boolean
  isSendRateConModalVisible: boolean
  contractLaneId: number | null
  isContractLaneModalVisible: boolean
  selectedLoadId: number
  tenderedCarriers: TenderedCarrier[]
  locationTrackingEvents: CarrierLocationTrackingEvent[]
  loadRoute: {
    locationId: number
    locationName: string
    stopType: 'Pickup' | 'Drop'
    locationType: 'Origin' | 'Destination' | 'Stop'
  }[]
}

const initialState: LoadsState = {
  loads: [],
  count: 0,
  offset: 0,
  size: 50,
  scroll: 0,
  search: '',
  filters: initialFilters,
  order: { label: '', direction: '', key: '' },
  orderDisplay: '',
  loadDetails: {} as Load,
  loadBookMethod: {
    bookMethod: null,
    additionalInfo: null,
  },
  carrierContacts: [],
  customerAccessorials: [],
  customerAccessorialsBackup: [],
  carrierAccessorials: [],
  carrierAccessorialsBackup: [],
  loading: {
    loads: false,
    createLoad: false,
    loadDetails: false,
    addNewAccessorial: false,
    loadAccessorials: false,
    deleteAccessorial: false,
    updateAccessorial: false,
    loadNotes: false,
    loadHistory: false,
    loadDocuments: false,
    loadSMS: false,
    loadTrackingUpdate: false,
    updateLoad: false,
    saveLoadChanges: false,
    postToLoadboard: false,
    updateStop: false,
    updateStopOrder: false,
    addNewStop: false,
    deleteLoadStop: false,
    updateManifestItem: false,
    addManifestItem: false,
    hold: false,
    archive: false,
    deleteDocument: false,
    deleteNote: false,
    holdReasons: false,
    blindBOL: false,
    trackShipment: false,
    stopTrackingShipment: false,
    createBOL: false,
    createRateConfirmation: false,
    exportCSV: false,
    checkPaymentReceived: false,
    sendDigitalRateCon: false,
    carrierContacts: false,
    toggleWaterfall: false,
    getWaterfallTenderedCarriers: false,
    locationEvents: false,
    updateStatus: false,
  },
  tenderItems: [],
  backupTenderItems: [],
  loadNotes: [],
  loadNotesCount: 0,
  loadHistory: [],
  loadHistoryCount: 0,
  loadHistoryFilterField: '',
  loadHistoryLimit: 10,
  loadDocuments: [],
  loadDocumentsCount: 0,
  loadSMS: [],
  loadSMSCount: 0,
  smsTemplates: [],
  loadTrackingUpdate: {},
  allLoadboards: [],
  changedLoadInfo: initialLoadInfo,
  loadInfo: initialLoadInfo,
  routeInfo: {
    loadstopSet: [],
  },
  changedRouteInfo: {
    loadstopSet: [],
  },
  commodity: initialCommodity,
  changedCommodity: initialCommodity,
  selectedCarrier: {},
  selectedContact: {},
  driverInfo: { phone: null, name: null },
  trackInfo: initialTrackInfo,
  pickupItems: [],
  trackingEvents: [],
  backupCarrier: {
    carrier: '',
    dispatcher: null,
    driverName: '',
    driverPhone: '',
  },
  holdReasons: [],
  showDupLoadsDialog: false,
  showDuplicationReviewModal: false,
  showUnfinishedDupDialog: false,
  showDeleteDupLoadDialog: false,
  loadDupQuantity: 0,
  dupDialogCreated: false,
  duplicateTemplateLoad: undefined,
  createLoadModalVisible: false,
  customerCompanyName: '',
  loadDupIds: [],
  poNumbers: [],
  exportFields: [
    { label: 'Customer', key: 'customer' },
    { label: 'ID', key: 'id' },
    { label: 'Status', key: 'status' },
    { label: 'Carrier', key: 'carrier_company' },
    { label: 'CSR', key: 'csr' },
    {
      label: 'Origin',
      key: '',
      fields: [
        { label: 'Address', key: 'origin_address' },
        { label: 'City', key: 'origin_city' },
        { label: 'State', key: 'origin_state' },
        { label: 'Postal Code', key: 'origin_postal_code' },
        { label: 'Country', key: 'origin_country' },
      ],
    },
    {
      label: 'Destination',
      key: '',
      fields: [
        { label: 'Address', key: 'destination_address' },
        { label: 'City', key: 'destination_city' },
        { label: 'State', key: 'destination_state' },
        { label: 'Postal Code', key: 'destination_postal_code' },
        { label: 'Country', key: 'destination_country' },
      ],
    },
    { label: 'Sales Rep', key: 'sales_rep' },
    { label: 'Order Types', key: 'order_types' },
    { label: 'Pick Up Date', key: 'pickup_date' },
    { label: 'Delivery Date', key: 'delivery_date' },
    { label: 'Equipment Type', key: 'equipment_type' },
    { label: 'Mode Type', key: 'mode_type' },
    { label: 'Create Type', key: 'create_type' },
    { label: 'Network Type', key: 'network_type' },
    { label: 'Dispatcher', key: 'dispatcher_name' },
    { label: 'Acquisition Rep', key: 'manager' },
    { label: 'Factoring Manager', key: 'factoring_agent' },
    {
      label: 'Cost Details',
      key: '',
      fields: [
        { label: 'Carrier Start - Max Buy', key: 'carrier_start_and_max_buy' },
        { label: 'Carrier Total', key: 'carrier_total' },
        { label: 'Customer Total', key: 'customer_total' },
        { label: 'Load Margin', key: 'load_margin' },
      ],
    },
    { label: 'Tags', key: 'tags' },
  ],
  selectedFields: [
    'customer',
    'id',
    'status',
    'carrier_company',
    'csr',
    'origin_address',
    'origin_city',
    'origin_state',
    'origin_postal_code',
    'origin_country',
    'destination_address',
    'destination_city',
    'destination_state',
    'destination_postal_code',
    'destination_country',
    'sales_rep',
    'order_types',
    'pickup_date',
    'delivery_date',
    'equipment_type',
    'mode_type',
    'create_type',
    'network_type',
    'dispatcher_name',
    'manager',
    'factoring_agent',
    'carrier_start_and_max_buy',
    'carrier_total',
    'customer_total',
    'load_margin',
    'tags',
  ],
  uiState: {
    selectedLoadsTableColumns: [],
  },
  vehicleInfo: { truckNumber: null, trailerNumber: null },
  loadsBeingViewed: {},
  isEditingConfirmationDialogVisible: false,
  onActionButtonConfirm: () => {},
  isMainUser: false,
  mainUserName: '',
  isBOLModalVisible: false,
  isSendRateConModalVisible: false,
  contractLaneId: null,
  isContractLaneModalVisible: false,
  selectedLoadId: -1,
  tenderedCarriers: [],
  locationTrackingEvents: [],
  loadRoute: [],
}

const getFilters = (filters: SearchFilters, returnString = true) => {
  const pickup =
    filters?.pickupDate?.filter(Boolean)?.length === 2
      ? {
          pickup_date__gte: filters.pickupDate[0],
          pickup_date__lte: filters.pickupDate[1],
        }
      : filters?.pickupDate?.filter(Boolean).length
        ? { pickup_date: filters.pickupDate[0] }
        : { pickup_date: undefined }

  const delivery =
    filters?.deliveryDate?.filter(Boolean)?.length === 2
      ? {
          delivery_date__gte: filters.deliveryDate[0],
          delivery_date__lte: filters.deliveryDate[1],
        }
      : filters?.deliveryDate?.filter(Boolean).length
        ? { delivery_date: filters.deliveryDate[0] }
        : { delivery_date: undefined }

  const formatStates = (states = '') => {
    const statesList = states.replaceAll(' ', ',').split(',').filter(Boolean)
    return statesList.length ? (returnString ? statesList.join(',') : statesList) : undefined
  }

  const formatTimeFilter = (earlyKey: string, lateKey: string, timeRange?: string[]) => {
    const [earlyTime, lateTime] = timeRange || []

    const result: Record<string, string> = {}

    if (earlyTime) result[`${earlyKey}__gte`] = normalizeTimeForBackend(earlyTime)
    if (lateTime) result[`${lateKey}__lte`] = normalizeTimeForBackend(lateTime)

    return result
  }

  const getTagFilter = (key: string, condition?: boolean) => (condition ? { [key]: true } : {})

  return cleanFilters({
    archived: filters.archived,
    order_type__in: filters.factoringLoads
      ? '4'
      : formatArrayOfIds(filters.freightOrderTypes, returnString) || '1,2,3',
    ...(filters.loadStatuses?.length && {
      new_load_status__in: filters.loadStatuses.join(',').replaceAll('+', '_'),
    }),
    shipper__city__icontains: filters.originCity,
    shipper__state_province_region__in: formatStates(filters.originState),
    consignee__city__icontains: filters.destinationCity,
    consignee__state_province_region__in: formatStates(filters.destinationState),
    id: filters.loadId,
    customer_company: filters.customerCompany,
    customer_company__account_manager: filters.accountManager,
    carrier: filters.carrier,
    customer_reference_id__icontains: filters.refId,
    po_number__icontains: filters.poNumber,
    pu_number__icontains: filters.puNumber,
    order_type: filters.orderType,
    equipment_type: filters.equipmentType,
    carrier_invoice_number: filters.carrierInvoiceNumber,
    agent: filters.accountOwner,
    consignee_delivery_number__icontains: filters.deliveryNumber,
    carrier_sales_rep__in: formatArrayOfIds(filters.csrList, returnString),
    customer_company__in: formatArrayOfIds(filters.customerCompaniesList, returnString),
    agent__in: formatArrayOfIds(filters.accountOwnersList, returnString),
    customer_company__accounting_contact__in: formatArrayOfIds(
      filters.accountingContactsList,
      returnString,
    ),
    customer_company__account_manager__in: formatArrayOfIds(
      filters.accountManagersList,
      returnString,
    ),
    ...pickup,
    ...delivery,
    shipper_pickup_number__icontains: filters.shipperPickupNumber,
    mode__in: formatArrayOfIds(filters.modes, returnString),
    phone_number: filters.phone,
    hold: booleanFromYesNoString(filters.hold),
    ...(booleanFromYesNoString(filters.exoLoad) == true && { network: 2 }),
    ...(booleanFromYesNoString(filters.exoLoad) == false && { network: 4 }),
    ...formatTimeFilter('pickup_early_time', 'pickup_late_time', filters.pickupTime),
    ...formatTimeFilter('delivery_early_time', 'delivery_late_time', filters.deliveryTime),
    ...getTagFilter('is_hot_load', filters.loadTags?.includes('isHotLoad')),
    ...getTagFilter('is_flexible_load', filters.loadTags?.includes('isFlexibleLoad')),
    ...getTagFilter('is_rolled_load', filters.loadTags?.includes('isRolledLoad')),
  })
}

export const addNewManifestItem = createAsyncThunk(
  'loads/addNewManifestItem',
  async (item: Manifest, { dispatch, getState, rejectWithValue }) => {
    const {
      loads: { loadDetails },
    } = getState() as RootState

    const isPickup = item.locationType === 'pickup' || item.locationType === 'origin'

    const payload = {
      name: item.name,
      weight: item.weight,
      quantity: item.quantity,
      length: item.length,
      width: item.width,
      height: item.height,
      notes: item.notes,
      stopType:
        (item.locationType === 'origin' && 1) || (item.locationType === 'destination' && 2) || 3,
      itemMappingKey: isPickup ? randomString() : item.itemMappingKey,
      stop: item.stop,
    }

    try {
      const response = await api.post(
        `/loads/api/manifest-item/${loadDetails.id}/`,
        keysToSnakeCase({ ...payload, loadId: loadDetails.id }),
      )

      dispatch(getLoadDetail(loadDetails.id))

      return response.data
    } catch (err: CatchError) {
      return rejectWithValue(formatAxiosErrorToPayload(err))
    }
  },
)

export const deleteManifestItem = createAsyncThunk(
  'loads/deleteManifestItem',
  async (id: number | undefined, { dispatch, getState, rejectWithValue }) => {
    const {
      loads: { loadDetails },
    } = getState() as RootState

    try {
      const response = await api.delete(`/loads/api/manifest-item-rud/${id}/`)

      dispatch(getLoadDetail(loadDetails.id))

      return response.data
    } catch (err: CatchError) {
      return rejectWithValue(formatAxiosErrorToPayload(err))
    }
  },
)

export const updateManifestItem = createAsyncThunk(
  'loads/updateManifestItem',
  async (
    { id, item }: { id: number | undefined; item: Manifest },
    { dispatch, getState, rejectWithValue },
  ) => {
    const {
      loads: { loadDetails },
    } = getState() as RootState

    const payload = {
      stop: item.stop,
      name: item.name,
      weight: item.weight,
      quantity: item.quantity,
      length: item.length,
      width: item.width,
      height: item.height,
      notes: item.notes,
      files: null,
      stopType: item.stopType,
    }

    try {
      const response = await api.put(
        `/loads/api/manifest-item-rud/${id}/`,
        keysToSnakeCase({ ...payload, loadId: loadDetails.id }),
      )

      dispatch(getLoadDetail(loadDetails.id))

      return response.data
    } catch (err: CatchError) {
      return rejectWithValue(formatAxiosErrorToPayload(err))
    }
  },
)

export const postOrDeleteLoadboards = createAsyncThunk(
  'loads/postOrDeleteLoadboards',
  async (
    payload: {
      toPost: Array<string>
      toDelete: Array<string>
      toUpdate: Array<string>
      showPrice: boolean
    },
    { dispatch, getState },
  ) => {
    const {
      loads: {
        loadDetails: { id },
      },
    } = getState() as RootState

    const postAction = !isEmpty(payload.toPost)
      ? api.post(`/integrations/post-to-loadboards/${id}/`, {
          service_names: payload.toPost,
          action: 'POST',
          loadboard_price_enable: payload.showPrice,
        })
      : Promise.resolve({})

    const updateAction = !isEmpty(payload.toUpdate)
      ? api.post(`/integrations/post-to-loadboards/${id}/`, {
          service_names: payload.toUpdate,
          action: 'UPDATE',
          loadboard_price_enable: payload.showPrice,
        })
      : Promise.resolve({})

    const deleteAction = !isEmpty(payload.toDelete)
      ? api.post(`/integrations/post-to-loadboards/${id}/`, {
          service_names: payload.toDelete,
          action: 'DELETE',
        })
      : Promise.resolve({})

    const [posted, updated, deleted] = await Promise.allSettled([
      postAction,
      updateAction,
      deleteAction,
    ])

    dispatch(getLoadDetail(id))

    if (posted.status === 'rejected')
      throw Error(posted.reason.response?.data ?? 'Error posting load to loadboards')
    if (updated.status === 'rejected')
      throw Error(updated.reason.response?.data ?? 'Error updating load on loadboards')
    if (deleted.status === 'rejected')
      throw Error(deleted.reason.response?.data ?? 'Error deleting load from loadboards')
  },
)

export const getAllLoadboards = createAsyncThunk('loads/getAllLoadboards', async () =>
  api.get('/integrations/all-loadboards/').then(({ data }) => data),
)

export const getHoldReasons = createAsyncThunk('loads/getHoldReasons', async () =>
  api.get('/loads/api/hold-reasons/').then(({ data }) => data),
)

export const createLoad = createAsyncThunk(
  'loads/createLoad',
  async (load: any, { rejectWithValue, dispatch }) => {
    try {
      const { data } = await api.post('/loads/api/load-create/', keysToSnakeCase(load))
      dispatch(getLoads())
      return data
    } catch (err: CatchError) {
      return rejectWithValue(formatAxiosErrorToPayload(err))
    }
  },
)

export const getLoadNotes = createAsyncThunk(
  'loads/getLoadNotes',
  async ({ id, limit }: { id: number; limit?: number }) => {
    const response = await api.get(`/loads/api/create-list-load-comment/${id}/`, {
      params: {
        limit: limit || 200,
        user_only: true,
      },
    })

    return response.data
  },
)

export const addNewNote = createAsyncThunk(
  'loads/addNewNote',
  async ({ text, loadId, isPinned }: NewLoadNote, { dispatch, getState, rejectWithValue }) => {
    const {
      loads: {
        loadDetails: { id: detailsId },
      },
    } = getState() as RootState

    try {
      const id = loadId ?? detailsId
      const response = await api.post(
        `/loads/api/create-list-load-comment/${id}/`,
        keysToSnakeCase({
          text,
          isPinned,
        }),
      )

      dispatch(getLoadNotes({ id }))

      return response.data
    } catch (err: CatchError) {
      return rejectWithValue(formatAxiosErrorToPayload(err))
    }
  },
)

export const updateNote = createAsyncThunk(
  'loads/updateNote',
  async ({ id, text, isPinned }: NewLoadNote, { dispatch, getState, rejectWithValue }) => {
    const {
      loads: { loadDetails },
    } = getState() as RootState

    try {
      const response = await api.put(
        `/loads/api/rud-load-comment/${id}/`,
        keysToSnakeCase({
          text,
          isPinned,
        }),
      )

      dispatch(getLoadNotes({ id: loadDetails.id }))

      return response.data
    } catch (err: CatchError) {
      return rejectWithValue(formatAxiosErrorToPayload(err))
    }
  },
)

export const deleteNote = createAsyncThunk(
  'loads/deleteNote',
  async (id: number, { dispatch, getState }) => {
    const {
      loads: { loadDetails },
    } = getState() as RootState

    const response = await api.delete(`/loads/api/rud-load-comment/${id}/`)

    dispatch(getLoadNotes({ id: loadDetails.id }))

    return response.data
  },
)

export const getLoadHistory = createAsyncThunk(
  'loads/getLoadHistory',
  async ({ id, limit }: { id: string | number; limit?: number }, { getState }) => {
    const {
      loads: { loadHistoryFilterField: field, loadHistoryLimit },
    } = getState() as RootState

    const response = await api.get(`/loads/api/history/${id}/`, {
      params: {
        limit: limit || loadHistoryLimit,
        offset: 0,
        ...(field.length ? { field_name: field.toLowerCase().replaceAll(' ', '_') } : {}),
      },
    })

    return response.data
  },
)

export const getLoadTrackingEvents = createAsyncThunk(
  'loads/getLoadTrackingEvents',
  async (id: number) => {
    const response = await api.get(`/loads/api/load-tracking-update/${id}/`)

    return response.data
  },
)

export const getLoadDocuments = createAsyncThunk(
  'loads/getLoadDocuments',
  async ({ id, orderType }: { id: number; orderType: number }) => {
    const {
      data: { results: loadDocuments },
    } = await api.get(`/loads/api/list-load-upload/${id}/?limit=100`)

    let documents = loadDocuments

    if (orderType === 4) {
      const factoringDocuments = await api
        .get(`/loads/api/load-finance-documents/${id}/`)
        .then(({ data }) => keysToCamelCase(data))
      documents = [
        ...documents,
        ...factoringDocuments.map((document: FactoringDocument) => ({
          ...document,
          uploadedBy: document.uploadedByDisplay,
          documentTypeDisplay: document.name,
          fileName: formatFileName(document.fileName),
          timeStamp: document.createdAt,
        })),
      ]
    }

    return {
      results: documents,
      count: documents.length,
    }
  },
)

export const getSMSTemplates = createAsyncThunk('loads/getSMSTemplates', async () =>
  api.get('/sms/api/sms-message-templates/').then(({ data }) => data),
)

export const getLoadSMS = createAsyncThunk('loads/getLoadSMS', async (id: number) =>
  api.get(`/sms/api/list-sms-messages/${id}/?limit=100/`).then(({ data }) => data),
)

export const sendLoadSMS = createAsyncThunk(
  'loads/sendLoadSMS',
  async (template: number, { dispatch, getState, rejectWithValue }) => {
    const {
      loads: {
        loadDetails: { id },
      },
    } = getState() as RootState

    try {
      const response = await api.post(`/sms/api/send-sms-message/${id}/`, { template })

      dispatch(getLoadSMS(id))

      return response.data
    } catch (err: CatchError) {
      return rejectWithValue(formatAxiosErrorToPayload(err))
    }
  },
)

export const addLoadDocument = createAsyncThunk(
  'loads/addLoadDocument',
  async (
    document: {
      loadId: number
      file: File
      documentType: { name: string; value: number | string }
      documentCategory: any
    },
    { dispatch, getState, rejectWithValue },
  ) => {
    const {
      loads: {
        loadDetails: { id, orderType },
      },
    } = getState() as RootState

    const flData = new FormData()
    const extension = document.file.name.substring(
      document.file.name.lastIndexOf('.') + 1,
      document.file.name.length,
    )
    const docType = document.documentType.name.split(' ').join('_').toLowerCase()
    const fileName = `${document.loadId}_${docType}.${extension}`
    flData.append('file', new File([document.file], fileName))
    flData.append('document_type', String(document.documentType.value))

    try {
      const response = await api.post(
        `/loads/api/${
          document.documentCategory === 'Load Document'
            ? 'list-load-upload'
            : 'load-finance-documents'
        }/${id}/`,
        flData,
      )

      dispatch(
        getLoadDocuments({
          id,
          orderType: orderType ?? 1,
        }),
      )

      return response.data
    } catch (err: CatchError) {
      return rejectWithValue(formatAxiosErrorToPayload(err))
    }
  },
)

export const deleteLoadDocument = createAsyncThunk(
  'loads/deleteLoadDocument',
  async (document: any, { dispatch, getState, rejectWithValue }) => {
    const {
      loads: {
        loadDetails: { id, orderType },
      },
    } = getState() as RootState

    try {
      const response = await api.delete(
        `${
          document?.factoringRequest
            ? '/accounts/api/carrier/finance-document-ud'
            : '/loads/api/rud-load-upload'
        }/${document.id}/`,
      )

      dispatch(
        getLoadDocuments({
          id,
          orderType: orderType ?? 1,
        }),
      )

      return response.data
    } catch (err: CatchError) {
      return rejectWithValue(formatAxiosErrorToPayload(err))
    }
  },
)

export const getLoadAccessorials = createAsyncThunk(
  'loads/getLoadAccessorials',
  async (id: number) => api.get(`/loads/api/load-accessorial/${id}/`).then(({ data }) => data),
)

export const addNewAccessorial = createAsyncThunk(
  'loads/addNewAccessorial',
  async (
    { accessorial, id }: { accessorial: NewAccessorial; id?: number },
    { dispatch, getState, rejectWithValue },
  ) => {
    const {
      loads: { loadDetails },
    } = getState() as RootState

    const payload = {
      ...accessorial,
      accessorialType: accessorial.accessorialType.id,
      chargeTo: accessorial.chargeTo.id,
      chargeType: accessorial.chargeType.id,
    }

    try {
      const response = await api.post(
        `/loads/api/load-accessorial/${id || loadDetails.id}/`,
        keysToSnakeCase(payload),
      )

      dispatch(getLoadAccessorials(id || loadDetails.id))
      dispatch(getLoadDetail(id || loadDetails.id))

      return response.data
    } catch (err: CatchError) {
      return rejectWithValue(formatAxiosErrorToPayload(err))
    }
  },
)

export const updateAccessorial = createAsyncThunk(
  'loads/updateAccessorial',
  async (
    { id, accessorial }: { id: string | number; accessorial: NewAccessorial },
    { dispatch, getState, rejectWithValue },
  ) => {
    const {
      loads: { loadDetails },
    } = getState() as RootState

    const payload = {
      ...accessorial,
      accessorialType: accessorial.accessorialType.id,
      chargeTo: accessorial.chargeTo.id,
      chargeType: accessorial.chargeType.id,
    }

    try {
      const response = await api.put(
        `/loads/api/load-accessorial-rud/${id}/`,
        keysToSnakeCase(payload),
      )

      dispatch(getLoadAccessorials(loadDetails.id))
      dispatch(getLoadDetail(loadDetails.id))

      return response.data
    } catch (err: CatchError) {
      return rejectWithValue(formatAxiosErrorToPayload(err))
    }
  },
)

export const deleteAccessorial = createAsyncThunk(
  'loads/deleteAccessorial',
  async (id: string | number, { dispatch, getState }) => {
    const response = await api.delete(`/loads/api/load-accessorial-rud/${id}/`)

    const {
      loads: { loadDetails },
    } = getState() as RootState

    dispatch(getLoadDetail(loadDetails.id))
    dispatch(getLoadAccessorials(loadDetails.id))

    return response.data
  },
)

export const getLoadDetail = createAsyncThunk(
  'loads/getLoadDetail',
  async (id: string | number, { dispatch, getState }) => {
    const response = await api.get(`/loads/api/load-detail/${id}/`)
    const payables = (getState() as RootState).invoicing.payables
    const load = keysToCamelCase(response.data)

    dispatch(getLoadHistory({ id }))

    // afer load details are saved, we fetch it again to get the updated reference
    // we update the load's verion inside payables, so that the conditions required
    // to pay the load are updated
    dispatch(
      setPayables(
        payables.map(payable => {
          if (payable.id === id) {
            return {
              ...payable,
              loadMargin: {
                ...load.loadMargin,
                carrierPriceTotal: load.loadMargin.carrierPriceTotal,
              },
              poNumber: load.poNumber,
            }
          }
          return payable
        }),
      ),
    )
    return load
  },
)

export const createBlindBOL = createAsyncThunk(
  'loads/createBlindBOL',
  async (
    data: {
      shipperName: string
      address: string
      city: string
      state: string
      postalCode: string
    },
    { dispatch, getState, rejectWithValue },
  ) => {
    const {
      loadDetails: { id, orderType },
    } = (getState() as RootState).loads

    try {
      const response = await api.post(`/loads/api/blind-bol/${id}/`, keysToSnakeCase(data))
      dispatch(
        getLoadDocuments({
          id,
          orderType: orderType ?? 1,
        }),
      )
      return response.data
    } catch (err: CatchError) {
      return rejectWithValue(formatAxiosErrorToPayload(err))
    }
  },
)

export const createBOL = createAsyncThunk(
  'loads/createBOL',
  async (_, { dispatch, getState, rejectWithValue }) => {
    const {
      loadDetails: { id },
    } = (getState() as RootState).loads

    try {
      const response = await api.post(`/loads/api/create-bol/${id}/`)
      dispatch(getLoadDocuments({ id: id, orderType: 1 }))
      return response.data
    } catch (err: CatchError) {
      return rejectWithValue(formatAxiosErrorToPayload(err))
    }
  },
)

export const createRateConfirmation = createAsyncThunk(
  'loads/createRateConfirmation',
  async (_, { dispatch, getState, rejectWithValue }) => {
    const {
      loadDetails: { id },
    } = (getState() as RootState).loads

    try {
      const response = await api.post(`/loads/api/create-rate-con/${id}/`)
      dispatch(getLoadDocuments({ id: id, orderType: 1 }))
      return response.data
    } catch (err: CatchError) {
      return rejectWithValue(formatAxiosErrorToPayload(err))
    }
  },
)

// Get list of contacts for a particular carrier
export const getCarrierContacts = createAsyncThunk(
  'loads/getCarrierContacts',
  async (payload: { carrierId: number }, { rejectWithValue }) => {
    try {
      const response = await api.get(`/accounts/api/carrier/contact/${payload.carrierId}/`, {
        params: {
          offset: 0,
          limit: 100,
        },
      })
      return keysToCamelCase(response.data.results).map(
        validateContact,
      ) as ValidatedCarrierContact[]
    } catch (err: CatchError) {
      return rejectWithValue(formatAxiosErrorToPayload(err))
    }
  },
)

export const sendDigitalRateCon = createAsyncThunk(
  'loads/sendDigitalRateCon',
  async (
    {
      email_ids,
      sms_ids,
      loadID,
    }: {
      email_ids: number[]
      sms_ids: number[]
      loadID: number
    },
    { rejectWithValue },
  ) => {
    try {
      const payload = {
        load_id: loadID,
        email_ids,
        sms_ids,
      }

      const response = await api.post(`/loads/api/send-digital-rate-con/`, payload)
      return response
    } catch (err: CatchError) {
      return rejectWithValue(formatAxiosErrorToPayload(err))
    }
  },
)

export const holdLoad = createAsyncThunk(
  'loads/holdLoad',
  async (payload: { id: number; hold: boolean; reason: number }, { dispatch, rejectWithValue }) => {
    try {
      const response = await api.post(`/loads/api/load-hold/${payload.id}/`, payload)
      dispatch(getLoadDetail(payload.id))
      return response.data
    } catch (err: CatchError) {
      return rejectWithValue(formatAxiosErrorToPayload(err))
    }
  },
)

export const archiveLoad = createAsyncThunk(
  'loads/archiveLoad',
  async (payload: { archived: boolean; id?: number }, { dispatch, getState, rejectWithValue }) => {
    const {
      loadDetails: { id },
    } = (getState() as RootState).loads

    try {
      const response = await api.post(`/loads/api/load-archive/${payload.id || id}/`, {
        archived: payload.archived,
      })
      if (payload.id) dispatch(getLoads())
      else dispatch(getLoadDetail(id))
      return response.data
    } catch (err: CatchError) {
      if (payload.id) dispatch(getLoads())
      else dispatch(getLoadDetail(id))
      return rejectWithValue(formatAxiosErrorToPayload(err))
    }
  },
)

export const updateLoad = createAsyncThunk(
  'loads/updateLoad',
  async (payload: any, { dispatch, getState, rejectWithValue }) => {
    const { loadDetails, vehicleInfo } = (getState() as RootState).loads
    const { currentCarrier } = (getState() as RootState).carriers

    if (currentCarrier?.id) {
      const truckNumbers = Array.from(
        new Set([...(currentCarrier.truckNumbers || []), vehicleInfo.truckNumber].filter(Boolean)),
      )
      const trailerNumbers = Array.from(
        new Set(
          [...(currentCarrier.trailerNumbers || []), vehicleInfo.trailerNumber].filter(Boolean),
        ),
      )

      if (
        !isEqual(truckNumbers, currentCarrier?.truckNumbers) ||
        !isEqual(trailerNumbers, currentCarrier?.trailerNumbers)
      )
        dispatch(
          saveCarrierDetails({
            id: currentCarrier?.id,
            truckNumbers,
            trailerNumbers,
          }),
        )
    }

    try {
      await api.patch(
        `/loads/api/load-update/${payload.id || loadDetails.id}/`,
        keysToSnakeCase(payload),
      )

      await dispatch(getLoadDetail(payload.id || loadDetails.id))

      return payload.withToast ?? true
    } catch (err: CatchError) {
      return rejectWithValue(formatAxiosErrorToPayload(err))
    }
  },
)

export const getLoadStop = createAsyncThunk(
  'loads/getLoadStop',
  async ({ id }: { id: number | undefined }) =>
    api.get(`/loads/api/load-stop-rud/${id}/`).then(({ data }) => keysToCamelCase(data)),
)

export const updateLoadStop = createAsyncThunk(
  'loads/updateLoadStop',
  async ({ id, stop }: { id: number; stop: Stop }, { dispatch, getState, rejectWithValue }) => {
    const {
      loads: { loadDetails },
    } = getState() as RootState

    const payload = {
      location: stop.location.id,
      stopDate: stop.date,
      stopEarlyTime: normalizeTimeForBackend(stop.earlyTime),
      stopLateTime: normalizeTimeForBackend(stop.lateTime),
      phone: stop.phone,
      contactName: stop.contactName,
      notes: stop.notes,
      stopType: stop.stopType.id,
      shipmentNumber: stop.number,
      poNumber: typeof stop.poNumber === 'object' ? stop.poNumber?.join(' & ') : stop.poNumber,
    }

    try {
      const response = await api.put(`/loads/api/load-stop-rud/${id}/`, {
        ...keysToSnakeCase(payload),
        load: loadDetails.id,
      })

      await dispatch(getLoadDetail(loadDetails.id))
      await dispatch(getLoadStop({ id }))

      return response.data
    } catch (err: CatchError) {
      return rejectWithValue(formatAxiosErrorToPayload(err))
    }
  },
)

export const deleteLoadStop = createAsyncThunk(
  'loads/deleteLoadStop',
  async (id: number, { dispatch, getState, rejectWithValue }) => {
    const {
      loads: { loadDetails },
    } = getState() as RootState

    try {
      const response = await api.delete(`/loads/api/load-stop-rud/${id}/`)

      dispatch(getLoadDetail(loadDetails.id))

      return response.data
    } catch (err: CatchError) {
      return rejectWithValue(formatAxiosErrorToPayload(err))
    }
  },
)

export const addLoadStop = createAsyncThunk(
  'loads/addLoadStop',
  async (stop: Stop, { dispatch, getState, rejectWithValue }) => {
    const {
      loads: { loadDetails },
    } = getState() as RootState
    const payload = {
      location: stop.location.id,
      stopDate: formatDateForBackend(stop.date),
      stopEarlyTime: stop.earlyTime,
      stopLateTime: stop.lateTime,
      phone: stop.phone,
      contactName: stop.contactName,
      notes: stop.notes,
      stopType: stop.stopType?.id,
      shipmentNumber: stop.shipmentNumber,
      poNumber: typeof stop.poNumber === 'object' ? stop.poNumber?.join(' & ') : stop.poNumber,
    }

    try {
      const response = await api.post(`/loads/api/add-stop/${loadDetails.id}/`, {
        ...keysToSnakeCase(payload),
        load: loadDetails.id,
      })

      dispatch(getLoadDetail(loadDetails.id))

      return response.data
    } catch (err: CatchError) {
      return rejectWithValue(formatAxiosErrorToPayload(err))
    }
  },
)

export const updateLoadStopOrder = createAsyncThunk(
  'loads/updateLoadStopOrder',
  async (
    { id, direction }: { id: number; direction: number },
    { dispatch, getState, rejectWithValue },
  ) => {
    const {
      loads: { loadDetails },
    } = getState() as RootState

    try {
      const response = await api.put(`/loads/api/load-stop-order/${id}/`, { direction })

      dispatch(getLoadDetail(loadDetails.id))

      return response.data
    } catch (err: CatchError) {
      return rejectWithValue(formatAxiosErrorToPayload(err))
    }
  },
)

export const getLoads = createAsyncThunk(
  'loads/getLoads',
  async (_, { getState, rejectWithValue }) => {
    const { filters, size = 50, offset = 0, order } = (getState() as RootState).loads

    let ordering = ''
    if (order) {
      const { label, direction, key } = order
      ordering = getOrderingString(label, direction, key, '')
    }

    try {
      const response = await api.get('/loads/api/all-loads-list/', {
        params: {
          limit: size,
          offset,
          ordering,
          ...getFilters(filters),
        },
      })
      return keysToCamelCase(response.data)
    } catch (err: CatchError) {
      return rejectWithValue(formatAxiosErrorToPayload(err))
    }
  },
)

export const checkLoadPaymentReceived = createAsyncThunk(
  'loads/checkLoadPaymentReceived',
  async (_, { getState, rejectWithValue }) => {
    const {
      loadDetails: { id },
    } = (getState() as RootState).loads

    try {
      const response = await api.get(`/loads/api/check-load-payment-status/${id}/`)
      return response.data
    } catch (err: CatchError) {
      return rejectWithValue(formatAxiosErrorToPayload(err))
    }
  },
)

export const duplicateLoad = createAsyncThunk('loads/duplicateLoad', async (templateLoad: Load) => {
  const { id } = templateLoad
  const response = await api.get(`/loads/api/load-detail/${id}/`)
  const load = response.data
  return load
})

export const saveLoadChanges = createAsyncThunk(
  'loads/saveLoadChanges',
  async (
    { showToast = true }: { showToast?: boolean },
    { getState, dispatch, rejectWithValue },
  ) => {
    const {
      loadInfo,
      changedLoadInfo,
      selectedCarrier,
      backupCarrier,
      tenderItems,
      backupTenderItems,
      routeInfo,
      changedRouteInfo,
      customerAccessorials,
      customerAccessorialsBackup,
      carrierAccessorials,
      carrierAccessorialsBackup,
      loadDetails: load,
    } = (getState() as RootState).loads

    const { isLoadDetailChanged, isLoadStopChanged, isAccessorialChanged } = getLoadChangeStatuses({
      backupTenderItems,
      tenderItems,
      loadInfo,
      changedLoadInfo,
      selectedCarrier,
      backupCarrier,
      routeInfo,
      changedRouteInfo,
      customerAccessorials,
      customerAccessorialsBackup,
      carrierAccessorials,
      carrierAccessorialsBackup,
    })

    if (isLoadDetailChanged) {
      const {
        equipmentType = null,
        poNumber = null,
        refId = null,
        carrierInvoice = null,
        customer,
        billingReferenceNumber,
        accountManager,
        pickupDate,
        deliveryDate,
        customerPrice,
      } = changedLoadInfo

      const payload = {
        equipmentType,
        poNumber,
        customerReferenceId: refId,
        carrierInvoiceNumber: carrierInvoice,
        customerCompany: customer?.id,
        billingReferenceNumber,
        accountManager: accountManager?.id ?? null,
        carrierPrice: tenderItems[11]?.price?.toString(),
        carrier: parseInt(selectedCarrier.id) || null,
        shipper: load.shipper
          ? {
              ...load.shipper,
              id: changedRouteInfo.shipper?.id,
              city: changedRouteInfo.shipper?.city,
            }
          : null,
        consignee: load.consignee
          ? {
              ...load.consignee,
              id: changedRouteInfo.consignee?.id,
              city: changedRouteInfo.consignee?.city,
            }
          : null,
        pickupDate,
        deliveryDate,
        customerPrice,
      }
      try {
        await api.patch(`/loads/api/load-update/${load.id}/`, keysToSnakeCase(payload))
        return showToast
      } catch (err: CatchError) {
        return rejectWithValue(formatAxiosErrorToPayload(err))
      }
    }

    if (isLoadStopChanged) {
      for (let i = 0; i < changedRouteInfo.loadstopSet.length; i++) {
        if (changedRouteInfo.loadstopSet[i].location.id != routeInfo.loadstopSet[i].location.id) {
          const stop = changedRouteInfo.loadstopSet[i]
          const payload = omit(
            {
              ...stop,
              location: stop.location.id,
            },
            ['latitude', 'longitude', 'locationName'],
          )
          try {
            await api.put(`/loads/api/load-stop-rud/${payload.id}/`, {
              ...keysToSnakeCase(payload),
              load: load.id,
            })
          } catch (err: CatchError) {
            return rejectWithValue(formatAxiosErrorToPayload(err))
          }
        }
      }
    }

    if (isAccessorialChanged) {
      for (let i = 0; i < customerAccessorials.length; i++) {
        if (
          customerAccessorials[i].accessorialType !==
            customerAccessorialsBackup[i].accessorialType ||
          parseFloat(customerAccessorials[i].amount) !==
            parseFloat(customerAccessorialsBackup[i].amount) ||
          parseFloat(customerAccessorials[i].quantity) !==
            parseFloat(customerAccessorialsBackup[i].quantity)
        ) {
          const accessorial = customerAccessorials[i]
          try {
            await api.put(
              `/loads/api/load-accessorial-rud/${accessorial.id}/`,
              keysToSnakeCase(accessorial),
            )
          } catch (err: CatchError) {
            return rejectWithValue(formatAxiosErrorToPayload(err))
          }
        }
      }

      for (let i = 0; i < carrierAccessorials.length; i++) {
        if (
          carrierAccessorials[i].accessorialType !== carrierAccessorialsBackup[i].accessorialType ||
          parseFloat(carrierAccessorials[i].amount) !==
            parseFloat(carrierAccessorialsBackup[i].amount) ||
          parseFloat(carrierAccessorials[i].quantity) !==
            parseFloat(carrierAccessorialsBackup[i].quantity)
        ) {
          const accessorial = carrierAccessorials[i]
          try {
            await api.put(
              `/loads/api/load-accessorial-rud/${accessorial.id}/`,
              keysToSnakeCase(accessorial),
            )
          } catch (err: CatchError) {
            return rejectWithValue(formatAxiosErrorToPayload(err))
          }
        }
      }
      dispatch(getLoadAccessorials(load.id))
    }

    if (isLoadDetailChanged || isLoadStopChanged || isAccessorialChanged) {
      dispatch(getLoadDetail(load.id))
    }
  },
)

export const trackShipment = createAsyncThunk(
  'loads/trackShipment',
  async (
    { id, notifyEmail, duration }: { id: number; notifyEmail: string; duration: number },
    { getState, rejectWithValue, dispatch },
  ) => {
    const {
      loads: { loadDetails },
    } = getState() as RootState

    try {
      const response = await api.post(
        `/loads/api/macropoint-tracking-request/${id}/`,
        keysToSnakeCase({ notifyEmail, duration }),
      )
      dispatch(getLoadDetail(loadDetails.id))
      return response.data
    } catch (err: CatchError) {
      return rejectWithValue(formatAxiosErrorToPayload(err))
    }
  },
)

export const stopTrackingShipment = createAsyncThunk(
  'loads/stopTrackingShipment',
  async ({ id }: { id: number }, { getState, rejectWithValue, dispatch }) => {
    const {
      loads: { loadDetails },
    } = getState() as RootState

    try {
      const response = await api.post(`/loads/api/stop-tracking-request/${id}/`)

      dispatch(getLoadDetail(loadDetails.id))
      return response.data
    } catch (err: CatchError) {
      return rejectWithValue(formatAxiosErrorToPayload(err))
    }
  },
)

export const getPredictedMaxBuy = createAsyncThunk(
  'loads/getPredictedMaxBuy',
  async (
    payload: {
      origin: string
      destination: string
      equipmentType: string
      loadPickupDate: string
      customerPrice: number
    },
    { rejectWithValue },
  ) => {
    try {
      const response = await api.post(
        '/pricing/get-carrier-start-max-buy/',
        keysToSnakeCase(payload),
      )
      return response.data.predicted_max_price
    } catch (err: CatchError) {
      return rejectWithValue(formatAxiosErrorToPayload(err))
    }
  },
)

export const exportLoadsCSV = createAsyncThunk(
  'loads/exportLoadsCSV',
  async (_, { getState, rejectWithValue }) => {
    const { filters, selectedFields } = (getState() as RootState).loads

    try {
      const response = await api.post('/loads/api/export-load-data/', {
        filters: {
          ...getFilters(filters, false),
        },
        fields: selectedFields.join(','),
      })

      downloadCSV(response.data, 'loads')
    } catch (err: CatchError) {
      return rejectWithValue(formatAxiosErrorToPayload(err))
    }
  },
)

export const toggleWaterfall = createAsyncThunk(
  'loads/toggleWaterfall',
  async (trigger: WaterfallAction, { getState, dispatch, rejectWithValue }) => {
    const {
      loadDetails: { id },
    } = (getState() as RootState).loads

    try {
      const response = await api.post(`/loads/api/waterfall-carrier-tender/${id}/`, { trigger })
      dispatch(getWaterfallTenderedCarriers(id))
      return response.data
    } catch (err: CatchError) {
      return rejectWithValue(formatAxiosErrorToPayload(err))
    }
  },
)

export const getWaterfallTenderedCarriers = createAsyncThunk(
  'loads/getWaterfallTenderedCarriers',
  async (id: string | number, { rejectWithValue }) => {
    try {
      const response = await api.get(`/loads/api/waterfall-carrier-tender/${id}/`)
      return keysToCamelCase(response.data)
    } catch (err: CatchError) {
      return rejectWithValue(formatAxiosErrorToPayload(err))
    }
  },
)

export const getLocationTrackingEvents = createAsyncThunk(
  'loads/getLocationTrackingEvents',
  async (loadId?: number) => {
    const url = loadId
      ? `/tracking/location-tracking-event/${loadId}/`
      : '/tracking/location-tracking-event/'
    const response = await api.get(url)
    return response.data.map((event: any) => {
      const [longitude, latitude] = event.geo_point.split('(')[1].split(')')[0].split(' ')
      return {
        timestamp: new Date(event.timestamp).toISOString(),
        accuracy: event.accuracy,
        coordinates: {
          lat: parseFloat(latitude),
          lng: parseFloat(longitude),
        },
      }
    })
  },
)

export const updateStatus = createAsyncThunk(
  'loads/updateStatus',
  async (
    data: {
      loadId: number
      status: string
      date?: Date | string | null
      time?: string
    },
    { rejectWithValue },
  ) => {
    try {
      const response = await api.post(
        `/loads/api/load-status-update/${data.loadId}/`,
        keysToSnakeCase({
          ...data,
          ...(data.date &&
            data.time && { eventTime: `${formatDateForBackend(data.date)} ${data.time}` }),
        }),
      )
      return keysToCamelCase(response.data) as {
        newLoadStatus: string
        eventTime: string
      }
    } catch (err: CatchError) {
      return rejectWithValue(formatAxiosErrorToPayload(err))
    }
  },
)

export const getLoadRoute = createAsyncThunk(
  'loads/getLoadRoute',
  async (id: string | number, { rejectWithValue }) => {
    try {
      const response = await api.get(`/loads/api/load-routes/${id}/`)
      return keysToCamelCase(response.data)
    } catch (err: CatchError) {
      return rejectWithValue(formatAxiosErrorToPayload(err))
    }
  },
)

const loadsSlice = createSlice({
  name: 'loads',
  initialState,
  reducers: {
    setSize(state, { payload }) {
      state.size = payload
    },
    setOffset(state, { payload }) {
      state.offset = payload
    },
    setScroll(state, { payload }) {
      state.scroll = payload
    },
    setSearch(state, { payload }) {
      state.search = payload
    },
    setOrder(state, { payload }) {
      state.order = payload
    },
    setOrderDisplay(state, { payload }) {
      state.orderDisplay = payload
    },
    setFilters(state, { payload }) {
      state.filters = {
        ...state.filters,
        ...payload,
      }
    },
    setTenderItems(state, { payload }) {
      state.tenderItems = payload
    },
    setLoadHistoryFilterField(state, { payload }) {
      state.loadHistoryFilterField = payload
    },
    setLoadHistoryLimit(state, { payload }) {
      state.loadHistoryLimit = payload
    },
    setChangedLoadInfo(state, { payload }) {
      state.changedLoadInfo = payload
    },
    setChangedRouteInfo(state, { payload }) {
      state.changedRouteInfo = payload
    },
    setCustomerAccessorials(state, { payload }) {
      state.customerAccessorials = payload
    },
    setCarrierAccessorials(state, { payload }) {
      state.carrierAccessorials = payload
    },
    setChangedCommodity(state, { payload }) {
      state.changedCommodity = payload
    },
    setSelectedCarrier(state, { payload }) {
      state.selectedCarrier = payload
    },
    setSelectedContact(state, { payload }) {
      state.selectedContact = payload
    },
    setDriverInfo(state, { payload }) {
      state.driverInfo = payload
    },
    setTrackInfo(state, { payload }) {
      state.trackInfo = payload
    },
    setSelectedCarrierInfo(state) {
      const {
        carrier,
        dispatcher,
        dispatchName,
        dispatchPhone,
        dispatchEmail,
        driverPhone,
        driverName,
        truckNumber,
        trailerNumber,
      } = state.loadDetails
      state.selectedCarrier = {
        id: carrier?.id?.toString(),
        text: carrier?.name,
        selectedText: carrier?.name,
      }
      if (dispatcher)
        state.selectedContact = {
          id: dispatcher,
          name: dispatchName,
          phone: dispatchPhone,
          email: dispatchEmail,
          value: dispatchName,
          label: dispatchName,
        }
      state.driverInfo = { phone: driverPhone ?? '', name: driverName ?? '' }
      state.vehicleInfo = { truckNumber, trailerNumber }
    },
    setShowDupLoadsDialog(state, { payload }) {
      state.showDupLoadsDialog = payload
    },
    setShowDuplicationReviewModal(state, { payload }) {
      state.showDuplicationReviewModal = payload
    },
    setShowUnfinishedDupDialog(state, { payload }) {
      state.showUnfinishedDupDialog = payload
    },
    setShowDeleteDupLoadDialog(state, { payload }) {
      state.showDeleteDupLoadDialog = payload
    },
    setLoadDupQuantity(state, { payload }) {
      state.loadDupQuantity = payload
    },
    setDupDialogCreated(state, { payload }) {
      state.dupDialogCreated = payload
    },
    setDuplicateTemplateLoad(state, { payload }) {
      state.duplicateTemplateLoad = payload
    },
    setCreateLoadModalVisible(state, { payload }) {
      state.createLoadModalVisible = payload
    },
    setCustomerCompanyName(state, { payload }) {
      state.customerCompanyName = payload
    },
    setLoadDupIds(state, { payload }) {
      state.loadDupIds = payload
    },
    setPoNumbersObjects(state, { payload }) {
      const poNumbersIds = state.poNumbers.map(num => num.id)

      state.poNumbers = poNumbersIds.includes(payload.id)
        ? state.poNumbers.map(number =>
            payload.id === number.id ? { ...number, name: payload.poNumber } : number,
          )
        : [...state.poNumbers, { id: payload.id, name: payload.poNumber }]
    },
    setPoNumbers(state) {
      state.poNumbers = []
      if (state.loadDetails.shipperPoNumber)
        state.poNumbers.push({ name: state.loadDetails.shipperPoNumber })

      state.poNumbers.concat(
        [
          ...(state.loadDetails.loadstopSet
            ?.filter(stop => stop.stopType === 1)
            .map(stop => ({ name: stop.poNumber })) ?? []),
        ].filter(Boolean),
      )
    },
    resetPoNumbers(state) {
      state.poNumbers = []
    },
    setSelectedFields(state, { payload }) {
      state.selectedFields =
        typeof payload === 'string'
          ? state.selectedFields.includes(payload)
            ? state.selectedFields.filter(field => field !== payload)
            : [...state.selectedFields, payload]
          : payload
    },
    setVehicleInfo(state, { payload }) {
      state.vehicleInfo = payload
    },
    setLoadsBeingViewed(state, { payload }) {
      state.loadsBeingViewed = payload
    },
    updateLoadViewers(state, { payload }) {
      if (!payload.viewers.length)
        state.loadsBeingViewed = omit(state.loadsBeingViewed, [payload.loadId])
      else
        state.loadsBeingViewed = {
          ...state.loadsBeingViewed,
          [payload.loadId]: payload.viewers,
        }
    },
    setEditingConfirmationDialogVisible(state, { payload }) {
      state.isEditingConfirmationDialogVisible = payload
    },
    setIsMainUser(state, { payload }) {
      state.isMainUser = payload
    },
    setMainUserName(state, { payload }) {
      state.mainUserName = payload
    },
    setOnActionButtonConfirm(state, { payload }) {
      state.onActionButtonConfirm = payload
    },
    resetLoadDetails(state) {
      state.loadDetails = {} as Load
    },
    setBOLModalVisible(state, { payload }) {
      state.isBOLModalVisible = payload
    },
    setSendRateConModalVisible(state, { payload }) {
      state.isSendRateConModalVisible = payload
    },
    setSelectedTableColumns(state, { payload }) {
      state.uiState.selectedLoadsTableColumns = payload
    },
    setLoadBookMethod(state, { payload }) {
      state.loadBookMethod = payload
    },
    setContractLaneId(state, { payload }) {
      state.contractLaneId = payload
    },
    setContractLaneModalVisible(state, { payload }) {
      state.isContractLaneModalVisible = payload
    },
    setSelectedLoadId(state, { payload }) {
      state.selectedLoadId = payload
    },
  },
  extraReducers(builder) {
    builder
      .addCase(getLoads.pending, state => {
        state.loading.loads = true
      })
      .addCase(getLoads.fulfilled, (state, { payload }) => {
        const { count, results } = payload
        state.loads = results
        state.count = count
        state.loading.loads = false
      })
      .addCase(getLoads.rejected, (state, { payload }) => {
        state.loading.loads = false
        toast.error(getErrorString(payload, 'Failed to retrieve loads'))
      })
      .addCase(getLoadDetail.pending, state => {
        state.loading.loadDetails = true
      })
      .addCase(getLoadDetail.fulfilled, (state, { payload }) => {
        const load = payload
        const {
          commodityDimensions: commodity,
          carrier,
          driverName,
          driverPhone,
          dispatcher,
          loadstopSet,
          manifestitemSet,
          trackingRequest,
        } = load
        state.loadDetails = load
        const tenderItems = load.isFactoring
          ? normalizeFactoringTenderItems(load)
          : normalizeTenderItems(load)
        state.tenderItems = tenderItems
        state.backupTenderItems = tenderItems
        const loadInfo = {
          equipmentType: load.equipmentType,
          orderType: load.orderType ? { id: load.orderType, label: load.orderTypeDisplay } : {},
          mode: load.mode ? { id: load.mode, name: load.modeDisplay } : {},
          refId: load.customerReferenceId,
          poNumber: load.poNumber,
          agent: { id: load.agent?.id, text: load.agent?.name },
          csr: { id: load.carrierSalesRep?.id, text: load.carrierSalesRep?.name },
          fsr: { id: load.factoringSalesRep?.id, text: load.factoringSalesRep?.name },
          accountManager: load.accountManager
            ? {
                id: load.accountManager?.id,
                text: load.accountManager?.name,
              }
            : {},
          carrierInvoice: load.carrierInvoiceNumber,
          customer: { id: load.customerCompany?.id, text: load.customerCompany?.name },
          billingReferenceNumber: load.billingReferenceNumber,
          hold: load.hold,
          holdDetail: load.holdDetail,
          isHazmat: load.isHazmat,
          hazmatUhn: load.hazmatUhn,
          isHighValue: load.isHighValue,
          highValueAmount: load.highValueAmount,
          isTeamLoad: load.isTeamLoad,
          isOversize: load.isOversize,
          loadboardPriceEnable: load.loadboardPriceEnable,
          carrierPrice: load.carrierPrice,
          pickupDate: load.pickupDate,
          deliveryDate: load.deliveryDate,
          carrier: load.carrier,
          customerPrice: load.loadMargin?.customerTotalPrice,
        }
        const commodityDetails = {
          weight: commodity.loadWeight ?? '',
          temperature: load.temperature ?? '',
          length: commodity.loadLength ?? '',
          width: commodity.loadWidth ?? '',
          height: commodity.loadHeight ?? '',
          commodity: commodity.commodity ?? '',
          description: commodity.description ?? '',
        }
        state.commodity = commodityDetails
        state.changedCommodity = commodityDetails
        const routeInfo = {
          shipper: load?.shipper,
          consignee: load?.consignee,
          loadstopSet: load.loadstopSet,
        }
        state.routeInfo = routeInfo
        state.changedRouteInfo = routeInfo
        state.loadInfo = loadInfo
        state.changedLoadInfo = loadInfo
        state.selectedCarrier = loadInfo.carrier
        setSelectedCarrierInfo()
        state.backupCarrier = {
          carrier: carrier?.id.toString(),
          dispatcher,
          driverName: driverName ?? '',
          driverPhone: driverPhone ?? '',
        }
        state.trackInfo.isTrack = !!trackingRequest

        const pickupStops = loadstopSet
          .filter((stop: LoadStop) => stop.stopType === 1)
          .map((stop: LoadStop) => stop.id)

        state.pickupItems = manifestitemSet.filter(
          (item: Manifest) => pickupStops.includes(item.stop) || item.stopType === 1,
        )

        state.loading.loadDetails = false
      })
      .addCase(getLoadDetail.rejected, state => {
        state.loading.loadDetails = false
        toast.error('Error getting load details')
      })
      .addCase(getLoadAccessorials.pending, state => {
        state.loading.loadAccessorials = true
      })
      .addCase(getLoadAccessorials.fulfilled, (state, { payload }) => {
        const data = keysToCamelCase(payload.results)
        const customerAccessorials = data.filter(
          (accessorial: { chargeTo: number }) => accessorial.chargeTo === 1,
        )
        const carrierAccessorials = data.filter(
          (accessorial: { chargeTo: number }) => accessorial.chargeTo === 2,
        )
        state.customerAccessorials = customerAccessorials
        state.customerAccessorialsBackup = customerAccessorials
        state.carrierAccessorials = carrierAccessorials
        state.carrierAccessorialsBackup = carrierAccessorials
        state.loading.loadAccessorials = false
      })
      .addCase(getLoadAccessorials.rejected, state => {
        state.loading.loadAccessorials = false
        toast.error('Error getting load accessorials')
      })
      .addCase(addNewAccessorial.pending, state => {
        state.loading.addNewAccessorial = true
      })
      .addCase(addNewAccessorial.fulfilled, state => {
        state.loading.addNewAccessorial = false
        toast.success('Successfully added new accessorial')
      })
      .addCase(addNewAccessorial.rejected, (state, { payload }) => {
        state.loading.addNewAccessorial = false
        toast.error(getErrorString(payload, 'Error adding new accessorial'))
      })
      .addCase(updateAccessorial.pending, state => {
        state.loading.updateAccessorial = true
      })
      .addCase(updateAccessorial.fulfilled, state => {
        state.loading.updateAccessorial = false
        toast.success('Successfully updated accessorial')
      })
      .addCase(updateAccessorial.rejected, (state, { payload }) => {
        state.loading.updateAccessorial = false
        toast.error(getErrorString(payload, 'Error updating accessorial'))
      })
      .addCase(deleteAccessorial.pending, state => {
        state.loading.deleteAccessorial = true
      })
      .addCase(deleteAccessorial.fulfilled, state => {
        state.loading.deleteAccessorial = false
        toast.success('Successfully deleted accessorial')
      })
      .addCase(deleteAccessorial.rejected, state => {
        state.loading.deleteAccessorial = false
        toast.error('Error deleting accessorial')
      })
      .addCase(getLoadNotes.pending, state => {
        state.loading.loadNotes = true
      })
      .addCase(getLoadNotes.fulfilled, (state, { payload }) => {
        const { results, count } = payload
        state.loadNotes = keysToCamelCase(results)
        state.loadNotesCount = count
        state.loading.loadNotes = false
      })
      .addCase(getLoadNotes.rejected, state => {
        state.loading.loadNotes = false
        toast.error('Error getting notes')
      })
      .addCase(addNewNote.pending, state => {
        state.loading.loadNotes = true
      })
      .addCase(addNewNote.fulfilled, state => {
        state.loading.loadNotes = false
        toast.success('Successfully added note')
      })
      .addCase(addNewNote.rejected, (state, { payload }) => {
        state.loading.loadNotes = false
        toast.error(getErrorString(payload, 'Error adding note'))
      })
      .addCase(updateNote.pending, state => {
        state.loading.loadNotes = true
      })
      .addCase(updateNote.fulfilled, state => {
        state.loading.loadNotes = false
        toast.success('Successfully updated note')
      })
      .addCase(updateNote.rejected, (state, { payload }) => {
        state.loading.loadNotes = false
        toast.error(getErrorString(payload, 'Error updating note'))
      })
      .addCase(deleteNote.pending, state => {
        state.loading.deleteNote = true
      })
      .addCase(deleteNote.fulfilled, state => {
        state.loading.deleteNote = false
        toast.success('Successfully deleted note')
      })
      .addCase(deleteNote.rejected, state => {
        state.loading.deleteNote = false
        toast.error('Error deleting note')
      })
      .addCase(getLoadHistory.pending, state => {
        state.loading.loadHistory = true
      })
      .addCase(getLoadHistory.fulfilled, (state, { payload }) => {
        const { results, count } = payload
        state.loadHistory = keysToCamelCase(results)
        state.loadHistoryCount = count
        state.loading.loadHistory = false
      })
      .addCase(getLoadHistory.rejected, state => {
        state.loading.loadHistory = false
        toast.error('Error getting load history')
      })
      .addCase(getLoadTrackingEvents.pending, state => {
        state.loading.loadTrackingUpdate = true
      })
      .addCase(getLoadTrackingEvents.fulfilled, (state, { payload }) => {
        const trackingUpdate = keysToCamelCase(payload)
        const events = trackingUpdate.map((update: any, id: number) => ({
          ...update,
          createdAtDate: update.createdAt.substring(0, 10),
          id,
        }))
        state.loadTrackingUpdate = groupBy(events, 'createdAtDate')
        state.trackingEvents = trackingUpdate.map((update: any, id: number) => ({ ...update, id }))
        state.loading.loadTrackingUpdate = false
      })
      .addCase(getLoadTrackingEvents.rejected, state => {
        state.loading.loadTrackingUpdate = false
        toast.error('Error getting load tracking events')
      })
      .addCase(getLoadDocuments.pending, state => {
        state.loading.loadDocuments = true
      })
      .addCase(getLoadDocuments.fulfilled, (state, { payload }) => {
        const { results, count } = payload
        state.loadDocuments = keysToCamelCase(results)
        state.loadDocumentsCount = count
        state.loading.loadDocuments = false
      })
      .addCase(getLoadDocuments.rejected, state => {
        state.loading.loadDocuments = false
        toast.error('Error getting load documents')
      })
      .addCase(addLoadDocument.pending, state => {
        state.loading.loadDocuments = true
      })
      .addCase(addLoadDocument.fulfilled, state => {
        state.loading.loadDocuments = false
        toast.success('Successfully added document')
      })
      .addCase(addLoadDocument.rejected, (state, { payload }) => {
        state.loading.loadDocuments = false
        toast.error(getErrorString(payload, 'Error adding document'))
      })
      .addCase(deleteLoadDocument.pending, state => {
        state.loading.deleteDocument = true
        toast.success('Successfully deleted document')
      })
      .addCase(deleteLoadDocument.fulfilled, state => {
        state.loading.deleteDocument = false
      })
      .addCase(deleteLoadDocument.rejected, (state, { payload }) => {
        state.loading.deleteDocument = false
        toast.error(getErrorString(payload, 'Error deleting document'))
      })
      .addCase(getLoadSMS.pending, state => {
        state.loading.loadSMS = true
      })
      .addCase(getLoadSMS.fulfilled, (state, { payload }) => {
        const { results, count } = payload
        state.loadSMS = keysToCamelCase(results)
        state.loadSMSCount = keysToCamelCase(count)
        state.loading.loadSMS = false
      })
      .addCase(getLoadSMS.rejected, state => {
        state.loading.loadSMS = false
        toast.error('Error getting SMS')
      })
      .addCase(getSMSTemplates.pending, state => {
        state.loading.loadSMS = true
      })
      .addCase(getSMSTemplates.fulfilled, (state, { payload }) => {
        state.loading.loadSMS = false
        state.smsTemplates = keysToCamelCase(payload.results)
      })
      .addCase(getSMSTemplates.rejected, state => {
        state.loading.loadSMS = false
        toast.error('Error getting SMS templates')
      })
      .addCase(sendLoadSMS.pending, state => {
        state.loading.loadSMS = true
      })
      .addCase(sendLoadSMS.fulfilled, state => {
        state.loading.loadSMS = false
        toast.success('Successfully sent SMS')
      })
      .addCase(sendLoadSMS.rejected, (state, { payload }) => {
        state.loading.loadSMS = false
        toast.error(getErrorString(payload, 'Error sending SMS'))
      })
      .addCase(updateLoad.pending, state => {
        state.loading.updateLoad = true
      })
      .addCase(updateLoad.fulfilled, (state, { payload }) => {
        state.loading.updateLoad = false
        if (payload) toast.success('Successfully updated load details')
      })
      .addCase(updateLoad.rejected, (state, { payload }) => {
        state.loading.updateLoad = false
        toast.error(getErrorString(payload, 'Error updating load details'))
      })
      .addCase(postOrDeleteLoadboards.pending, state => {
        state.loading.postToLoadboard = true
      })
      .addCase(postOrDeleteLoadboards.fulfilled, state => {
        state.loading.postToLoadboard = false
        toast.success('Successfully completed loadboard action')
      })
      .addCase(postOrDeleteLoadboards.rejected, (state, action) => {
        state.loading.postToLoadboard = false
        toast.error(getErrorString(action.error.message, 'Error completing loadboard action'))
      })
      .addCase(getAllLoadboards.fulfilled, (state, { payload }) => {
        state.allLoadboards = keysToCamelCase(payload)
      })
      .addCase(getAllLoadboards.rejected, () => {
        toast.error('Error getting loadboards')
      })
      .addCase(createLoad.pending, state => {
        state.loading.createLoad = true
      })
      .addCase(createLoad.fulfilled, state => {
        state.loading.createLoad = false
        state.poNumbers = []
        toast.success('Load successfully created')
      })
      .addCase(createLoad.rejected, (state, { payload }) => {
        state.loading.createLoad = false
        toast.error(getErrorString(payload, 'Error creating new load'))
      })
      .addCase(updateLoadStop.pending, state => {
        state.loading.updateStop = true
      })
      .addCase(updateLoadStop.fulfilled, state => {
        state.loading.updateStop = false
        toast.success('Successfully updated load stop')
      })
      .addCase(updateLoadStop.rejected, (state, { payload }) => {
        state.loading.updateStop = false
        toast.error(getErrorString(payload, 'Error updating load stop'))
      })
      .addCase(updateLoadStopOrder.pending, state => {
        state.loading.updateStopOrder = true
      })
      .addCase(updateLoadStopOrder.fulfilled, state => {
        state.loading.updateStopOrder = false
        toast.success('Successfully updated load stop order')
      })
      .addCase(updateLoadStopOrder.rejected, (state, { payload }) => {
        state.loading.updateStopOrder = false
        toast.error(getErrorString(payload, 'Error updating load stop order'))
      })
      .addCase(addLoadStop.pending, state => {
        state.loading.addNewStop = true
      })
      .addCase(addLoadStop.fulfilled, state => {
        state.loading.addNewStop = false
        toast.success('Successfully added load stop')
      })
      .addCase(addLoadStop.rejected, (state, { payload }) => {
        state.loading.addNewStop = false
        toast.error(getErrorString(payload, 'Error adding load stop'))
      })
      .addCase(deleteLoadStop.pending, state => {
        state.loading.deleteLoadStop = true
      })
      .addCase(deleteLoadStop.fulfilled, state => {
        state.loading.deleteLoadStop = false
        toast.success('Successfully deleted load stop')
      })
      .addCase(deleteLoadStop.rejected, (state, { payload }) => {
        state.loading.deleteLoadStop = false
        toast.error(getErrorString(payload, 'Error deleting load stop'))
      })
      .addCase(deleteManifestItem.pending, state => {
        state.loading.updateManifestItem = true
      })
      .addCase(deleteManifestItem.fulfilled, state => {
        state.loading.updateManifestItem = false
        toast.success('Successfully deleted manifest item')
      })
      .addCase(deleteManifestItem.rejected, (state, { payload }) => {
        state.loading.updateManifestItem = false
        toast.error(getErrorString(payload, 'Error deleting manifest item'))
      })
      .addCase(updateManifestItem.pending, state => {
        state.loading.updateManifestItem = true
      })
      .addCase(updateManifestItem.fulfilled, state => {
        state.loading.updateManifestItem = false
        toast.success('Successfully updated manifest item')
      })
      .addCase(updateManifestItem.rejected, (state, { payload }) => {
        state.loading.updateManifestItem = false
        toast.error(getErrorString(payload, 'Error updating manifest item'))
      })
      .addCase(addNewManifestItem.pending, state => {
        state.loading.addManifestItem = true
      })
      .addCase(addNewManifestItem.fulfilled, state => {
        state.loading.addManifestItem = false
        toast.success('Successfully added manifest item')
      })
      .addCase(addNewManifestItem.rejected, (state, { payload }) => {
        state.loading.addManifestItem = false
        toast.error(getErrorString(payload, 'Error adding manifest item'))
      })
      .addCase(holdLoad.pending, state => {
        state.loading.hold = true
      })
      .addCase(holdLoad.fulfilled, (state, { payload }) => {
        state.loading.hold = false
        toast.success(payload)
      })
      .addCase(holdLoad.rejected, (state, { payload }) => {
        state.loading.hold = false
        toast.error(getErrorString(payload, 'Failed to hold/unhold load'))
      })
      .addCase(createBlindBOL.pending, state => {
        state.loading.blindBOL = true
      })
      .addCase(createBlindBOL.fulfilled, (state, { payload }) => {
        state.loading.blindBOL = false
        toast.success(payload)
      })
      .addCase(createBlindBOL.rejected, (state, { payload }) => {
        state.loading.blindBOL = false
        toast.error(getErrorString(payload, 'Failed to create blind BOL'))
      })
      .addCase(archiveLoad.pending, state => {
        state.loading.archive = true
      })
      .addCase(archiveLoad.fulfilled, (state, { payload }) => {
        state.loading.archive = false
        toast.success(payload)
      })
      .addCase(archiveLoad.rejected, (state, { payload }) => {
        state.loading.archive = false
        toast.error(getErrorString(payload, 'Failed to archive/unarchive load'))
      })
      .addCase(getHoldReasons.pending, state => {
        state.loading.holdReasons = true
      })
      .addCase(getHoldReasons.fulfilled, (state, { payload }) => {
        state.loading.holdReasons = false
        state.holdReasons = payload
      })
      .addCase(getHoldReasons.rejected, state => {
        state.loading.holdReasons = false
        toast.error('Failed to get hold reasons')
      })
      .addCase(getLoadStop.rejected, () => {
        toast.error('Failed to get load stop details')
      })
      .addCase(duplicateLoad.fulfilled, (state, { payload }) => {
        const load = keysToCamelCase(payload)
        load.loadstopSet.forEach((stop: any) => {
          stop.items = filter(load.manifestitemSet, { stop: stop.id })
        })
        state.duplicateTemplateLoad = load
        state.createLoadModalVisible = true
      })
      .addCase(saveLoadChanges.pending, state => {
        state.loading.saveLoadChanges = true
      })
      .addCase(saveLoadChanges.fulfilled, (state, { payload }) => {
        state.loading.saveLoadChanges = false
        if (payload) toast.success('Successfully saved load changes')
      })
      .addCase(saveLoadChanges.rejected, (state, { payload }) => {
        state.loading.saveLoadChanges = false
        toast.error(getErrorString(payload, 'Error saving load changes'))
      })
      .addCase(trackShipment.pending, state => {
        state.loading.trackShipment = true
      })
      .addCase(trackShipment.fulfilled, state => {
        state.loading.trackShipment = false
        toast.success('Successfully posted tracking request')
      })
      .addCase(trackShipment.rejected, (state, { payload }) => {
        state.loading.trackShipment = false
        toast.error(getErrorString(payload, 'Failed to post tracking request'))
      })
      .addCase(stopTrackingShipment.pending, state => {
        state.loading.stopTrackingShipment = true
      })
      .addCase(stopTrackingShipment.fulfilled, state => {
        state.loading.stopTrackingShipment = false
        toast.success('Successfully stopped shipment tracking')
      })
      .addCase(stopTrackingShipment.rejected, (state, { payload }) => {
        state.loading.stopTrackingShipment = false
        toast.error(getErrorString(payload, 'Failed to stop shipment tracking'))
      })
      .addCase(createBOL.pending, state => {
        state.loading.createBOL = true
      })
      .addCase(createBOL.fulfilled, state => {
        state.loading.createBOL = false
        toast.success('BOL created successfully')
      })
      .addCase(createBOL.rejected, (state, { payload }) => {
        state.loading.createBOL = false
        toast.error(getErrorString(payload, 'Failed to create BOL'))
      })
      .addCase(getCarrierContacts.pending, state => {
        state.loading.carrierContacts = true
      })
      .addCase(getCarrierContacts.fulfilled, (state, { payload }) => {
        state.loading.carrierContacts = false
        state.carrierContacts = payload
      })
      .addCase(getCarrierContacts.rejected, state => {
        state.loading.carrierContacts = false
        state.carrierContacts = []
        toast.error(getErrorString('Failed to get carrier contacts'))
      })
      .addCase(createRateConfirmation.pending, state => {
        state.loading.createRateConfirmation = true
      })
      .addCase(createRateConfirmation.fulfilled, state => {
        state.loading.createRateConfirmation = false
        toast.success('Rate confirmation created successfully')
      })
      .addCase(createRateConfirmation.rejected, (state, { payload }) => {
        state.loading.createRateConfirmation = false
        toast.error(getErrorString(payload, 'Failed to create Rate Confirmation'))
      })
      .addCase(sendDigitalRateCon.pending, state => {
        state.loading.sendDigitalRateCon = true
      })
      .addCase(sendDigitalRateCon.fulfilled, state => {
        state.loading.sendDigitalRateCon = false
        state.isSendRateConModalVisible = false
        toast.success('Rate confirmation sent successfully')
      })
      .addCase(sendDigitalRateCon.rejected, (state, { payload }) => {
        state.loading.sendDigitalRateCon = false
        toast.error(getErrorString(payload, 'Failed to send rate confirmation'))
      })
      .addCase(exportLoadsCSV.pending, state => {
        state.loading.exportCSV = true
      })
      .addCase(exportLoadsCSV.fulfilled, state => {
        state.loading.exportCSV = false
        toast.success('Successfully exported CSV')
      })
      .addCase(exportLoadsCSV.rejected, (state, { payload }) => {
        state.loading.exportCSV = false
        toast.error(getErrorString(payload, 'Failed to export CSV'))
      })
      .addCase(checkLoadPaymentReceived.pending, state => {
        state.loading.checkPaymentReceived = true
      })
      .addCase(checkLoadPaymentReceived.fulfilled, state => {
        state.loading.checkPaymentReceived = false
        state.loadDetails.isReceived = true
        toast.success('Load payment detail updated successfully')
      })
      .addCase(checkLoadPaymentReceived.rejected, (state, { payload }) => {
        state.loading.checkPaymentReceived = false
        toast.error(getErrorString(payload, 'Load received payment have an issue'))
      })
      .addCase(toggleWaterfall.pending, state => {
        state.loading.toggleWaterfall = true
      })
      .addCase(toggleWaterfall.fulfilled, (state, { payload }) => {
        state.loading.toggleWaterfall = false
        toast.success(payload)
      })
      .addCase(toggleWaterfall.rejected, (state, { payload }) => {
        state.loading.toggleWaterfall = false
        toast.error(getErrorString(payload, 'Failed to start waterfall'))
      })
      .addCase(getWaterfallTenderedCarriers.pending, state => {
        state.loading.getWaterfallTenderedCarriers = true
      })
      .addCase(getWaterfallTenderedCarriers.fulfilled, (state, { payload }) => {
        state.loading.getWaterfallTenderedCarriers = false
        state.tenderedCarriers = payload || []
      })
      .addCase(getWaterfallTenderedCarriers.rejected, (state, { payload }) => {
        state.loading.getWaterfallTenderedCarriers = false
        toast.error(getErrorString(payload, 'Failed to get tendered carriers'))
      })
      .addCase(getLocationTrackingEvents.pending, state => {
        state.loading.locationEvents = true
      })
      .addCase(getLocationTrackingEvents.fulfilled, (state, { payload }) => {
        state.locationTrackingEvents = payload
        state.loading.locationEvents = false
      })
      .addCase(getLocationTrackingEvents.rejected, state => {
        state.loading.locationEvents = false
      })
      .addCase(updateStatus.pending, state => {
        state.loading.updateStatus = true
      })
      .addCase(updateStatus.fulfilled, (state, { payload }) => {
        state.loading.updateStatus = false
        toast.success(`Status updated to ${payload.newLoadStatus}`)
      })
      .addCase(updateStatus.rejected, (state, { payload }) => {
        state.loading.updateStatus = false
        toast.error(getErrorString(payload, 'Failed to update load status'))
      })
      .addCase(getLoadRoute.fulfilled, (state, { payload }) => {
        state.loadRoute = payload
      })
      .addCase(getLoadRoute.rejected, (state, { payload }) => {
        toast.error(getErrorString(payload, 'Failed to get load route'))
      })
  },
})

// Configuration for redux-persist to know what to save and what not to save to local-storage
export const loadsPersistConfig = {
  key: 'loads',
  whitelist: ['uiState'],
}

export const {
  setSize,
  setOffset,
  setScroll,
  setOrder,
  setOrderDisplay,
  setFilters,
  setTenderItems,
  setLoadHistoryFilterField,
  setLoadHistoryLimit,
  setChangedLoadInfo,
  setChangedRouteInfo,
  setCustomerAccessorials,
  setCarrierAccessorials,
  setChangedCommodity,
  setSelectedCarrier,
  setSelectedContact,
  setDriverInfo,
  setTrackInfo,
  setSelectedCarrierInfo,
  setShowDupLoadsDialog,
  setShowDuplicationReviewModal,
  setShowUnfinishedDupDialog,
  setShowDeleteDupLoadDialog,
  setLoadDupQuantity,
  setDupDialogCreated,
  setDuplicateTemplateLoad,
  setCreateLoadModalVisible,
  setCustomerCompanyName,
  setLoadDupIds,
  setPoNumbersObjects,
  setPoNumbers,
  resetPoNumbers,
  setSelectedFields,
  setVehicleInfo,
  setLoadsBeingViewed,
  updateLoadViewers,
  setEditingConfirmationDialogVisible,
  setIsMainUser,
  setMainUserName,
  setOnActionButtonConfirm,
  resetLoadDetails,
  setBOLModalVisible,
  setSendRateConModalVisible,
  setSelectedTableColumns,
  setLoadBookMethod,
  setContractLaneId,
  setContractLaneModalVisible,
  setSelectedLoadId,
} = loadsSlice.actions

export default loadsSlice.reducer
