import { EyeIcon, EyeSlashIcon } from '@heroicons/react/24/outline'
import { useState } from 'react'
import tw from 'tailwind-styled-components'

import { CustomerExternalPortal } from '../../../common/types'
import { HalfWidthDetailItem } from '../../DetailItem'

export const ExternalPortalView = ({ portal }: { portal: CustomerExternalPortal }) => {
  const [showPassword, setShowPassword] = useState(false)

  const EyeIconComponent = showPassword ? EyeIcon : EyeSlashIcon

  return (
    <div className='flex flex-col gap-y-3'>
      <HalfWidthDetailItem label='Name'>{portal.name}</HalfWidthDetailItem>
      <div className='grid grid-cols-2 gap-y-3'>
        <HalfWidthDetailItem label='Address'>{portal.url}</HalfWidthDetailItem>
        <HalfWidthDetailItem label='Email'>{portal.email}</HalfWidthDetailItem>
        <HalfWidthDetailItem label='Password'>
          {portal.password ? (
            <div className='flex items-center'>
              <div className='min-h-[10px]'>
                {showPassword ? portal.password : '*'.repeat(portal.password.length)}
              </div>
              <EyeIconContainer onClick={() => setShowPassword(!showPassword)}>
                <EyeIconComponent className='w-4' />
              </EyeIconContainer>
            </div>
          ) : (
            '—'
          )}
        </HalfWidthDetailItem>
        <HalfWidthDetailItem label='Notes'>{portal.notes}</HalfWidthDetailItem>
      </div>
    </div>
  )
}

const EyeIconContainer = tw.div`
  ml-2
  hover:opacity-70
  transition-all
  cursor-pointer
`
