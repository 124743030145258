import { ApprovedToHaulStatus } from './carrier'
import { LoadStop, Location } from './location'

export type LoadPayment = {
  id: number
  load?: number
  carrier: {
    id: number
    name: string
    isFactoringExternally: boolean
    isFactoringInternally: boolean
  }
  carrierInvoiceNumber: string
  remitTo: {
    id: number
    name: string
    method?: string
    quickPay?: boolean
    details?: {
      accountNumber: string
      type: string
    }
  }
  hold: boolean
  dueDate: string | null
  orderType: string
  billDate: string | null
  paidDate: string | null
  customerCompany: {
    name: string
    id: number
  }
  loadMargin: LoadMargin
  poNumber: string | null
  payment: {
    id: number
    status: string
    checkId: string
    arName: string
    amount: number
  }
  prev?: number | null
  next?: number | null
  rowIndex?: number
  payableDueDate?: string | null
  paymentType?: string
  invoicedDate?: string | null
}

export type LoadLocation = Location & {
  items?: Array<Manifest>
}

export type LoadCarrier = {
  id: number
  ein?: string
  blacklist?: boolean
  name: string
  phone?: string
  fax?: string
  email?: string
  address?: string
  city?: string
  stateProvinceRegion?: string | null
  country?: string
  ssn?: string | null
  mcNumber?: string
  dotNumber?: string
  scacNumber?: string
  phoneTwo?: string
  phoneThree?: string
  postalCode?: string
  daysBetweenEmails?: number
  arName?: string
  arAddress?: string
  arCity?: string
  arState?: string
  arZipcode?: string
  arCountry?: string
  arPhone?: string
  arEmail?: string
  arRemitEmail?: string
  arContactName?: string
  payTerms?: string
  quickbooksId?: string | null
  customerCompanies?: Array<any>
  approvedToHaulStatus?: ApprovedToHaulStatus
  quickPay?: boolean
  paymentMethodType?: string
  isBusiness?: boolean
  carrierManager?: any
  hasPrimaryPaymentMethod?: boolean
  isFactoringExternally?: boolean
  isFactoringInternally?: boolean
}

export type LoadsListItem = {
  carrierSalesRep: string | null
  id: number
  customerCompanyName: string
  customerCompanyId: number
  pickupDate: string | null
  deliveryDate: string | null
  customerReferenceId: string | null
  poNumber: string | null
  puNumber: string | null
  shipper: { city: string; stateProvinceRegion: string } | null
  pickupEarlyTime: string | null
  pickupLateTime: string | null
  consignee: { city: string; stateProvinceRegion: string } | null
  deliveryEarlyTime: string | null
  deliveryLateTime: string | null
  carrierStartBuy?: string | number
  carrierMaxBuy?: string | number
  carrierCompanyName: string | null
  equipmentType: string | null
  loadStatus: number
  orderType: number
  orderTypeDisplay: string
  customerPrice: string
  archived: boolean
  archivedAt: string | null
  loadWeight: string | null
  miles: string | null
  commodity: string | null
  agentName: string | null
  hold: boolean
  loadStatusDisplay: string
  consigneeDeliveryNumber: string | null
  isReceived: boolean
  isPaid: boolean
  isInvoiced: boolean
  isHazmat: boolean
  isHighValue: boolean
  isTeamLoad: boolean
  isOversize: boolean
  isHotLoad: boolean
  metaDetail: any
  createdBy: number | null
  createdByDisplay: string | null
  loadboardPostStatuses?: Array<any>
  loadMargin?: LoadMargin
  carrierCalls: number
  network: number
  newLoadStatus?: string
  newLoadStatusDisplay?: string
}

export type Load = {
  shipperPoNumber?: string
  consigneePoNumber?: string
  accountManager?: { name?: string; id?: string | number } | null
  id: number
  shipper?: Location
  consignee?: Location
  accessorial?: string
  carrier?: LoadCarrier
  temperature?: string | null
  remarks?: string | null
  loadboard?: boolean
  mode?: number
  commodity?: string | null
  manifestitemSet?: Array<Manifest>
  metadata?: {
    driverId: string | null
    dispatcherId: number
    truckstopLoadId: string | null
  }
  archived?: boolean
  hold?: boolean
  holdDetail?: {
    reason: string
    holdBy: string
    holdDatetime: string
    accounting: boolean
  }
  dispatcher?: any
  driver?: any
  agent?: {
    name: string
    id: number
  }
  customerCompany?: {
    id: number
    name: string
    active: boolean
    parent: any
    website: string
    phone: string
    address: string
    city: string
    state: string | null
    country: string
    accountOwner: number
    accountOwnerDisplay: string
    organizationLevel: any
    organizationLevelDisplay: string
    sizeClassification: any
    sizeClassificationDisplay: string
    usZipcode: string | null
    caProvince: string | null
    caPostalCode: string | null
    creditLimit: number
    utilizedCredit: number
    quickbooksId: number
    payTerms: number
    accountManager: number
    accountManagerDisplay: string
    customerBillingCode: string | null
    canPostLoadsToDat?: boolean
    anonymizeLoadboardPostings: boolean
  }
  agentCompany?: number
  pickupDate?: string
  deliveryDate?: string
  customerReferenceId?: string
  poNumber?: string | null
  puNumber?: string | null
  shipperPickupNumber?: string | null
  shipperPickupNotes?: string | null
  pickupEarlyTime?: string
  pickupLateTime?: string
  consigneeDeliveryNumber?: string | null
  consigneeDeliveryNotes?: string | null
  deliveryEarlyTime?: string
  deliveryLateTime?: string
  carrierStartBuy?: number
  carrierMaxBuy?: number
  predictedStartBuy?: number
  predictedMaxBuy?: number
  carrierPrice?: number
  customerPrice?: number
  fuelSurcharge?: string
  loadMargin?: LoadMargin
  driverName?: string
  driverPhone?: string
  dispatchName?: string
  dispatchPhone?: string
  dispatchEmail?: string
  equipmentType?: string
  loadStatus: number
  loadStatusDisplay?: string
  orderType?: number
  orderTypeDisplay?: string
  shipmentNotes?: string | null
  archivedAt?: string | null
  modeDisplay?: string
  carrierInvoiceNumber?: string | null
  loadstopSet?: Array<LoadStop>
  declaredValue?: string
  paidDate?: string | null
  isPaid?: boolean
  isInvoiced?: boolean
  isReceived?: boolean
  carrierPaymentMethod?: string
  carrierPaymentAmount?: string | number | null
  receivedAt?: string | null
  createMethod?: number
  loadboardPostStatuses?: Array<any>
  carrierSalesRep?: {
    name: string
    id: number
  }
  factoringSalesRep?: {
    name: string
    id: number
  }
  loadDates?: {
    dateCreated: string | null
    bookedDate: string | null
    confirmedDate: string | null
    pickedDate: string | null
    deliveredDate: string | null
    invoicedDate: string | null
    paidDate: string | null
    assignedAt: string | null
    updatedAt: string | null
    archivedAt: string | null
    dueDateEarly: string | null
    dueDateLate: string | null
  }
  commodityDimensions?: {
    description: string | null
    commodity: string | null
    quantity: string | number | null
    miles: number
    loadLength: string | null
    loadHeight: string | null
    loadWidth: string | null
    loadWeight: string | null
  }
  billingReferenceNumber?: string | null
  loadWeight?: string
  loadLength?: string
  loadWidth?: string
  loadHeight?: string
  quoteId?: number | null
  trackingRequest?: {
    url: string
    code: number
    duration: number
    emails: string
  }
  isHazmat?: boolean
  hazmatUhn?: string
  isHighValue?: boolean
  highValueAmount?: string
  isTeamLoad?: boolean
  isOversize?: boolean
  loadboardPriceEnable?: boolean
  truckNumber?: string
  trailerNumber?: string
  isHotLoad: boolean
  receivedPayment?: number | null
  createdByDisplay?: string | null
  disableExternalLoadPosting?: boolean | null
  isFactoring?: boolean
  factoringPriceBreakdown?: {
    flatFee?: number
    achFee?: number
    margin?: {
      amount: number
      percentage: number
    }
    reserve?: {
      amount: number
      percentage: number
    }
    recoveries?: Array<RecoveryItem>
    totalDeductions?: number
    totalAmountAfterDeductions?: number
  }
  isFlexibleLoad?: boolean
  isRolledLoad?: boolean
  trackingNumber?: string
  bookMethod?: string
  bookMethodAdditionalInfo?: string
  needsSignedRateCon?: boolean
  contractLaneId?: number
  network?: number
  newLoadStatus?: string
  newLoadStatusDisplay?: string
}

export type RecoveryItem = {
  amount: number
  description: string | null
}

export type LoadNote = {
  id: number
  author: number | string
  text: string
  load?: number
  timeStamp?: string
  isPinned?: boolean
  manualQuote?: number
}

export type NewLoadNote = { text: string; isPinned?: boolean; id?: number; loadId?: number }

export enum LoadDocumentType {
  BILL_OF_LADING = 1,
  RATE_CONFIRMATION = 2,
  OTHER = 3,
  PURCHASE_ORDER = 4,
  CARRIER_INVOICE = 5,
  CUSTOMER_INVOICE_ATTACHMENT = 6,
  CUSTOMER_INVOICE = 7,
  CUSTOMER_FACTORING_INVOICE = 8,
  BLIND_BILL_OF_LADING = 9,
  BLIND_BOL_BILLING = 10,
  BLIND_BOL_SHIPPING = 11,
  BLIND_BOL_DELIVERY = 12,
}

export type DocumentAnnotation = {
  type: string
  boundingBox: [number, number, number, number]
  page: number
  data: {
    value: string
    address?: string
    city?: string
    country?: string
    lat?: number
    lon?: number
    postalCode?: string
    state?: string
  }
}

export type Document = {
  id: number
  createdAt: Date | string
  initialFreightOrFinance: 'freight' | 'finance' | ''
  newFreightOrFinance: 'freight' | 'finance'
  documentType: string | number // TODO: change to string; load document is still number but everything else is string
  documentTypeDisplay: string
  file: string
  fileName: string
  name: string
  uploadedBy?: number
  updatedAt: Date | string
  uploadedByDisplay: string
  isDocumentValid?: boolean
  timestamp?: string
  timeStamp?: string
  selectable?: boolean
  annotations?: Array<DocumentAnnotation>
  label?: string
  date?: string
  freightOrFinance: 'freight' | 'finance'
}

export type LocalDocument = {
  id: number
  file: File | null
  name: string
  initialFreightOrFinance: 'freight' | 'finance' | ''
  newFreightOrFinance: 'freight' | 'finance'
  documentType: string | number // TODO: change to string; load document is still number but everything else is string
  isDocumentValid: boolean
  freightOrFinance: 'freight' | 'finance'
}

export type LoadDocument = Document & {
  load: number
  documentType: LoadDocumentType
  annotations?: Array<DocumentAnnotation>
}

export type LoadHistoryChange = {
  field: string
  new: string | object
  old: string | object
}

export type LoadHistory = {
  by: {
    id: number
    username: string
    fullName: string
  }
  at: string
  changes: Array<LoadHistoryChange>
  changeType: string
}

export type LoadQuote = {
  id: number
  archived: boolean
  expired: boolean
  load?: {
    id: number
    shipper?: Location
    consignee?: Location
  }
  name: string
  createdBy: string
  email: string
  phone: string
  source: number
  sourceDisplay: string
  createdAt: string | null
  updatedAt: string | null
  archivedAt: string | null
  expiredAt: string | null
  carrierCompany: LoadQuoteCarrier
  agentCompany: number
  carrierBid: string
  equipmentType: string
  dotNumber: string
  mcNumber: string
  quoteStatus: number
  quoteStatusDisplay: string
  shortlisted: boolean
  notes: string | null
}

export type LoadQuoteCarrier = {
  id: number
  name: string
  email: string
  phone?: string
  phoneTwo?: string
  phoneThree?: string
  mcNumber: string | null
  dotNumber: string | null
  approvedToHaulStatus: ApprovedToHaulStatus
}

export type LoadInfo = {
  holdDetail?: {
    reason: string
    holdBy: string
    holdDatetime: string
  }
  hold?: boolean
  equipmentType: string
  orderType: { id?: number; label?: string }
  mode: { id?: number; name?: string }
  refId: string
  poNumber: string
  csr: { id?: string | number; text: string }
  fsr: { id: number; text: string }
  accountManager: { id?: string | number; text?: string }
  carrierInvoice: string
  customer: { id?: string | number; text?: string }
  agent: { id: string | number; text: string }
  billingReferenceNumber: string
  isHazmat: boolean
  hazmatUhn: string
  isHighValue: boolean
  highValueAmount: string
  isTeamLoad: boolean
  isOversize: boolean
  loadboardPriceEnable: boolean
  pickupDate?: string
  deliveryDate?: string
  carrier?: LoadCarrier
  customerPrice?: number
  carrierPrice?: number
  trackingNumber?: string
}

export type LoadCommodity = {
  loadWidth?: string
  loadLength?: string
  loadWeight?: string
  loadHeight?: string
  weight: string
  temperature: string
  length: string
  width: string
  height: string
  commodity: string
  description: string
}

export type Manifest = {
  id?: number
  stop: number | null
  name: string | null
  weight: string | null
  quantity: number | string | null
  length: string | null
  width: string | null
  height: string | null
  notes: string | null
  file?: string | null
  loadId?: number
  stopType: number
  stopOrder?: number | null
  itemMappingKey?: string
  selectedManifest?: any
  locationType?: string
}

export type LoadMargin = {
  customerTotalPrice: number
  carrierTotalPrice: number
  carrierDue: number
  customerTotalAccessorials: number
  carrierTotalAccessorials: number
  carrierTotalPrepaidCarrierDeductionsAccessorial: number
  carrierTotalAccessorialsPaidToCarrier: number
  margin: number
  percentMargin: number
  quickPay: number
}

export type PayableLoad = {
  id: number
  customerCompany?: {
    name: string
    id: number
  }
  poNumber: string | null
  customerReferenceId: string | null
  deliveredDate: string | null
  origin: string | null
  destination: string | null
  receivedAt: string | null
  loadStatus: number
  isInvoiced: boolean
  isReceived: boolean
  invoicedDate: string | null
  loadMargin: LoadMargin
  carrier?: {
    name: string
    id: number
  }
  prev?: number | null
  next?: number | null
  rowIndex?: number | null
  hold: boolean
}

export type TrackingUpdate = {
  loadId: number
  loadStatus: number
  pickedAt?: Date | string
  pickedAtTime?: string
  deliveredAt?: Date | string
  deliveredAtTime?: string
  pickupApptAt?: Date | string
  pickupApptAtTime?: string
  deliveryApptAt?: Date | string
  deliveryApptAtTime?: string
  arrivedAtShipperAt?: Date | string
  arrivedAtShipperAtTime?: string
  arrivedAtConsigneeAt?: Date | string
  arrivedAtConsigneeAtTime?: string
  onTimePick: boolean
  onTimeDelivery: boolean
  eventType: number
  reasonCode: number
  callOutcome: string | null
  notes: string | null
  city: string
  stateProvinceRegion: string
  stopId: number
  isValid?: boolean
  stop: { id?: number; locationName?: string }
  stopDate?: Date | string | null
  stopTime?: string | null
  postalCode?: string
}

export type LoadSMS = {
  load: number
  template: number
  message: string
  userFullName: string
  createdAt: string
  createdBy: string
}

export type SMSTemplate = {
  id: number
  name: string
  description: string
  messageLong: string
  messageShort: string | null
  createdAt: string
  updatedAt: string
}

export type TrackingEvent = {
  city: string | null
  country: string | null
  stop: number | null
  newLoadStatus?: string | null
  stateProvinceRegion: string | null
  postalCode: string | null
  eventType: string | null
  reasonCode: string | null
  createdAt: string
  createdAtDate: string
  id: number
  createdBy: string | null
  notes: string | null
  callOutcome: string | null
}

export type NewCSVUpload = {
  file: File
  customerCompanyId: string
  jobType: number
}

export type LoadViewersUpdate = {
  viewers: {
    viewerId: number
    viewerName: string
  }[]
  event?: string
  loadId: number
}

export type LoadsBeingViewed = {
  [key: string]: {
    viewerId: number
    viewerName: string
  }[]
}

export type QuoteListStopItem = {
  id?: number
  city: string
  state: string
  stopType: string
  type?: number
}

export type WaterfallAction = 'START' | 'CANCEL'
