import dayjs from 'dayjs'

import { api } from '../../api/api'
import { LatLng, QuoteDetails as QuoteDetailsProps } from '../types'

type Location = {
  country: string
  state: string
  city: string
  pickupWeight: number
  dropoffWeight: number
  postalCode: string
  lat: number
  lon: number
}

type Lane = {
  equipmentType: string
  origin: Location
  destination: Location
  miles: number | null
  pickup_date: string
}

export const getDistance = async (origin: LatLng, destination: LatLng) => {
  const response = await api.get('/locations/api/here-map-route-proxy/', {
    params: {
      origin: `${origin.lat},${origin.lng}`,
      destination: `${destination.lat},${destination.lng}`,
    },
  })

  const distance = response.data.routes.reduce(
    (total: number, route: any) =>
      total +
      route.sections.reduce(
        (routeTotal: number, section: any) => routeTotal + section.summary.length,
        0,
      ),
    0,
  )

  return Math.round(distance / 1609.344)
}

export const buildLanes = async (
  locations: Location[],
  lanes: Lane[],
  equipmentType: string,
  routeSegments: Array<{ distance: number; origin: LatLng; destination: LatLng }>,
) => {
  for (let i = 0; i < locations.length - 1; i++) {
    const origin = locations[i]
    const destination = locations[i + 1]

    // First, attempt to get mileage from the route segments that we fetched for the map.
    // We multiply lat/lon by 100 then round to compare origin/destination to two decimal places
    let miles = null
    for (let j = 0; j < routeSegments.length; j++) {
      if (
        Math.round(origin.lat * 100) === Math.round((routeSegments[j].origin.lat ?? 0) * 100) &&
        Math.round(origin.lon * 100) === Math.round((routeSegments[j].origin?.lng ?? 0) * 100) &&
        Math.round(destination.lat * 100) ===
          Math.round((routeSegments[j].destination?.lat ?? 0) * 100) &&
        Math.round(destination.lon * 100) ===
          Math.round((routeSegments[j].destination?.lng ?? 0) * 100)
      ) {
        miles = routeSegments[j].distance
        break
      }
    }

    // If we didn't find the mileage in our cached results, look it up from the HERE API.
    if (miles === null) {
      miles = await getDistance(
        { lat: origin.lat, lng: origin.lon },
        { lat: destination.lat, lng: destination.lon },
      )
    }

    lanes.push({
      equipmentType,
      origin: { ...origin, pickupWeight: 44000, dropoffWeight: 0 },
      destination: { ...destination, pickupWeight: 0, dropoffWeight: 44000 },
      pickup_date: dayjs().add(1, 'd').format('YYYY-MM-DD'),
      miles,
    })
  }
}

export const quoteHasCommodity = (quote: QuoteDetailsProps) =>
  quote.commodity ||
  quote.pieces ||
  quote.weight ||
  quote.length ||
  quote.width ||
  quote.height ||
  quote.description
