import { Button, FileInput, Form, TextInput } from '@components'
import tw from 'tailwind-styled-components'

type DocumentFormProps = {
  formData: {
    name: string
    file: File | null | string
    fileName?: string
  }
  setFormData: (value: any) => void
  setFormValid: (value: boolean) => void
}

export const DocumentForm = ({ formData, setFormData, setFormValid }: DocumentFormProps) => {
  const fileName =
    typeof formData.file === 'string' ? formData.fileName?.split('/').pop() : formData.file?.name

  return (
    <Form setFormValid={setFormValid}>
      <TextInput
        required
        sm
        className='mb-4'
        label='Name'
        value={formData.name}
        onChange={(value: string) => setFormData({ ...formData, name: value })}
      />
      {!formData.file ? (
        <FileInput
          required
          mimeType='image/jpeg,image/png,image/bmp,image/webp,image/gif,application/pdf,application/vnd.openxmlformats-officedocument.wordprocessingml.document,application/msword,application/vnd.ms-excel,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,application/vnd.ms-excel.sheet.macroEnabled.12,text/csv'
          onChange={(file: File) => {
            setFormData({
              ...formData,
              file: file,
              fileName: file.name,
            })
          }}
        >
          <FileOuterContainer $fileSelected={Boolean(formData.file)}>
            <FileInnerContainer $fileSelected={Boolean(formData.file)}>
              <span className='font-semibold'>Upload a new document</span>
              <Button preIcon='plus-circle' type='warn'>
                Upload
              </Button>
            </FileInnerContainer>
          </FileOuterContainer>
        </FileInput>
      ) : (
        <FileOuterContainer $fileSelected={Boolean(fileName)}>
          <FileInnerContainer $fileSelected={Boolean(fileName)}>
            <div>
              <div className='font-semibold'>{fileName}</div>
              <div className='text-dark-gray'>is succesfully uploaded</div>
            </div>
            <Button
              onClick={() => {
                setFormData({
                  ...formData,
                  file: '',
                })
              }}
            >
              delete
            </Button>
          </FileInnerContainer>
        </FileOuterContainer>
      )}
    </Form>
  )
}

const FileOuterContainer = tw.div<{ $fileSelected: boolean }>`
  bg-light-blue
  rounded-xl
  border
  border-light-blue
  w-[500px]
  ${({ $fileSelected }: { $fileSelected: boolean }) => $fileSelected && 'bg-white'}
`

const FileInnerContainer = tw.div<{ $fileSelected: boolean }>`
  border-l-4
  rounded-l-lg
  flex
  justify-between
  items-center
  p-4
  h-[70px]
  ${({ $fileSelected }: { $fileSelected: boolean }) =>
    $fileSelected ? 'border-success' : 'border-link'}
`
