import { AlphaModal, AlphaModalProps, TextInput } from '@components'
import { zodResolver } from '@hookform/resolvers/zod'
import { useEffect, useRef, useState } from 'react'
import { Controller, useForm, useWatch } from 'react-hook-form'
import tw from 'tailwind-styled-components'
import { z } from 'zod'

import { useAppSelector, useAppThunkDispatch } from '../../app/hooks'
import {
  getPhoneMaskOptions,
  useCountryCode,
  useErrorFor,
  validateAddress,
} from '../../common/utils'
import { createFactoringCompany } from '../../redux/factoringCompanySlice'
import { AddressForm } from '../Forms'
import { InputContainer } from '../InputContainer'

type CreateFactoringCompanyModalProps = Pick<AlphaModalProps, 'isVisible' | 'setVisible'>

const schema = z
  .object({
    businessName: z.string().min(1),
    addressLine1: z.string().min(1),
    city: z.string().min(1),
    state: z.string().optional().nullable(),
    postalCode: z.string().optional().nullable(),
    country: z.string().min(1),
    phone: z.string().min(10),
    email: z.string().email(),
    contactName: z.string().min(1),
  })
  .refine(schema => validateAddress(schema))

export const CreateFactoringCompanyModal = ({
  isVisible,
  setVisible,
}: CreateFactoringCompanyModalProps) => {
  const {
    handleSubmit,
    formState: { errors, isValid, touchedFields },
    control,
    setValue,
    trigger,
  } = useForm({
    mode: 'all',
    resolver: zodResolver(schema),
  })

  const [addressData, setAddressData] = useState({
    address: '',
    addressDisplay: '',
    city: '',
    country: '',
    state: '',
    postalCode: '',
    usZipcode: '',
    currentPlace: null,
  })

  const updateAddressData = (data: any) => {
    setAddressData(data)
    setValue('addressLine1', data.address)
    setValue('city', data.city)
    setValue('state', data.state ?? data.stateProvinceRegion)
    setValue('postalCode', data.postalCode)
    setValue('country', data.country)
    trigger()
  }

  const errorFor = useErrorFor(touchedFields, errors)

  const onClose = () => setVisible(false)

  const dispatch = useAppThunkDispatch()

  const loading = useAppSelector(state => state.factoring.loading.createFactoringCompany)

  const submitRef = useRef<HTMLInputElement>()

  const onConfirm = () => {
    if (submitRef.current) {
      submitRef.current.click()
    }
  }

  const country = useWatch({ control, name: 'country' })

  const { countryCode } = useCountryCode(country)

  const [phoneMaskOpts, setPhoneMaskOpts] = useState(getPhoneMaskOptions(countryCode))

  useEffect(() => {
    setPhoneMaskOpts(getPhoneMaskOptions(countryCode))
  }, [countryCode])

  const onSubmit = async (data: any) => {
    const response = await dispatch(createFactoringCompany(data))
    if (response.meta.requestStatus === 'fulfilled') {
      onClose()
    }
  }

  return (
    <AlphaModal
      className='!overflow-visible'
      confirmButtonLabel='Create'
      isConfirmButtonDisabled={!isValid}
      isConfirmButtonLoading={loading}
      isVisible={isVisible}
      setVisible={setVisible}
      title='Create Factoring Company'
      onCancel={onClose}
      onConfirm={onConfirm}
    >
      <Form onSubmit={handleSubmit(onSubmit)}>
        <InputContainer className='w-full' error={errorFor('businessName')}>
          <Controller
            control={control}
            name='businessName'
            render={({ field }) => (
              <TextInput required sm className='w-full' label='Business Name' {...field} />
            )}
          />
        </InputContainer>
        <AddressForm
          required
          addressData={addressData}
          setAddressData={updateAddressData}
          withMargin={false}
        />
        <InputRow className='mt-4'>
          <InputContainer className='w-1/2' error={errorFor('phone')}>
            <Controller
              control={control}
              name='phone'
              render={({ field }) => (
                <TextInput
                  required
                  sm
                  className='w-full'
                  label='Phone'
                  maskOptions={phoneMaskOpts}
                  type='tel'
                  {...field}
                />
              )}
            />
          </InputContainer>
          <InputContainer className='w-1/2' error={errorFor('email')}>
            <Controller
              control={control}
              name='email'
              render={({ field }) => (
                <TextInput required sm className='w-full' label='Email' type='email' {...field} />
              )}
            />
          </InputContainer>
        </InputRow>
        <InputRow>
          <InputContainer className='w-full' error={errorFor('contactName')}>
            <Controller
              control={control}
              name='contactName'
              render={({ field }) => (
                <TextInput required sm className='w-full' label='Contact Name' {...field} />
              )}
            />
          </InputContainer>
        </InputRow>
        <input ref={submitRef as any} className='hidden' type='submit' />
      </Form>
    </AlphaModal>
  )
}

const Form = tw.form`
  flex
  flex-col
  lg:w-[640px]
  p-6
  gap-2
`

const InputRow = tw.div`
  flex
  gap-2
`
