/* Keep ./sentry at the top to ensure it's imported before
 anything that it would instrument */
import './sentry'
import 'react-toastify/dist/ReactToastify.css'
import './index.scss'
import './api/middleware'

import { BackStackProvider } from '@common'
import dayjs, { Dayjs } from 'dayjs'
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter'
import relativeTime from 'dayjs/plugin/relativeTime'
import React from 'react'
import { createRoot } from 'react-dom/client'
import { Provider } from 'react-redux'
import { RouterProvider } from 'react-router-dom'
import { ToastContainer } from 'react-toastify'
import { PersistGate } from 'redux-persist/integration/react'

import { persistor, store } from './app/store'
import reportWebVitals from './reportWebVitals'
import { router } from './router'
type DateType = string | number | Date | Dayjs

// we need to extend dayjs and specify types for every extension to manipulate dates
dayjs.extend(isSameOrAfter)
dayjs.extend(relativeTime)

declare module 'dayjs' {
  interface Dayjs {
    isSameOrAfter(compared: DateType, withoutSuffix?: boolean): string
  }
}

// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
const container = document.getElementById('root')!
const root = createRoot(container)

root.render(
  <React.StrictMode>
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <ToastContainer
          autoClose={2000}
          pauseOnFocusLoss={false}
          position='bottom-right'
          limit={3}
        />
        <BackStackProvider router={router}>
          <RouterProvider router={router} />
        </BackStackProvider>
      </PersistGate>
    </Provider>
  </React.StrictMode>,
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
