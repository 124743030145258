import { CatchError, formatAxiosErrorToPayload, getErrorString } from '@common'
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { orderBy } from 'lodash-es'
import { toast } from 'react-toastify'

import { api } from '../api/api'
import { initialFilters } from '../common/constants'
import {
  AutomatedBiddingPartner,
  EquipmentTypeSetting,
  Multiplier,
  RegionMultiplier,
  RootState,
  SearchFilters,
  TableOrder,
} from '../common/types'
import {
  formatArrayOfIds,
  getDifferentObjects,
  getMarginPercentage,
  getMarginValue,
  getOrderingString,
  keysToCamelCase,
  keysToSnakeCase,
} from '../common/utils'

const initialLocationBasedAdjustment = {
  originState: null,
  destinationState: null,
  multiplier: 0,
  sign: '+',
  equipmentType: '',
  isValid: false,
  regionMultiplierId: 0,
  pbsWithSimilarMlpsApplied: [],
  index: -1,
}

type AutomatedBiddingState = {
  automatedBiddingPartners: Array<AutomatedBiddingPartner>
  loading: {
    partners: boolean
    multipliers: boolean
    regionMultipliers: boolean
    saveRegionMultiplier: boolean
    deleteLocationBasedAdjustment: boolean
    changeSettingsType: boolean
    updateSettings: boolean
    getSettings: boolean
    updateTimeBasedAdjustments: boolean
    getEquipmentTypeSettings: boolean
    updateEquipmentTypeSettings: boolean
    updateLocationBasedAdjustments: boolean
    deleteSelectedLocationBasedAdjustments: boolean
  }
  count: number
  offset: number
  size: number
  order: TableOrder
  multipliers: Array<Multiplier>
  changedMultipliers: Array<Multiplier>
  regionMultipliers: Array<RegionMultiplier>
  changedRegionMultipliers: Array<RegionMultiplier>
  regionMultiplierBeingAddedOrEdited: boolean
  filters: SearchFilters
  customSettingsId: string | number
  settings: any
  selectedMultipliers: Array<number>
  editingMultiplier: number | null
  equipmentTypeSettings: Array<EquipmentTypeSetting>
  changedEquipmentTypeSettings: Array<EquipmentTypeSetting>
  timeBasedFormsValid: Array<boolean>
  marginBasedFormsValid: Array<boolean>
  searchParams: {
    equipmentTypes: Array<string>
    originStates: Array<string>
    destinationStates: Array<string>
  }
  equipmentTypes: Array<string>
  isOverrideModalVisible: boolean
  overridingMultipliers: Array<RegionMultiplier>
  newLocationBasedAdjustment: RegionMultiplier
  isAddingNew: boolean
}

const initialState: AutomatedBiddingState = {
  automatedBiddingPartners: [],
  loading: {
    partners: false,
    multipliers: false,
    regionMultipliers: false,
    saveRegionMultiplier: false,
    deleteLocationBasedAdjustment: false,
    changeSettingsType: false,
    updateSettings: false,
    getSettings: false,
    updateTimeBasedAdjustments: false,
    getEquipmentTypeSettings: false,
    updateEquipmentTypeSettings: false,
    updateLocationBasedAdjustments: false,
    deleteSelectedLocationBasedAdjustments: false,
  },
  count: 0,
  offset: 0,
  size: 50,
  order: {
    label: '',
    direction: '',
    key: '',
  },
  multipliers: [],
  changedMultipliers: [],
  regionMultipliers: [],
  changedRegionMultipliers: [],
  regionMultiplierBeingAddedOrEdited: false,
  filters: initialFilters,
  customSettingsId: 'default',
  settings: {},
  selectedMultipliers: [],
  editingMultiplier: null,
  equipmentTypeSettings: [],
  changedEquipmentTypeSettings: [],
  timeBasedFormsValid: [false, false, false],
  marginBasedFormsValid: [],
  searchParams: {
    equipmentTypes: [],
    originStates: [],
    destinationStates: [],
  },
  equipmentTypes: [],
  isOverrideModalVisible: false,
  overridingMultipliers: [],
  newLocationBasedAdjustment: initialLocationBasedAdjustment,
  isAddingNew: false,
}

export const getCustomerCompanies = createAsyncThunk(
  'automatedBidding/getCustomerCompanies',
  async (_, { getState }) => {
    const {
      order: { label, direction, key },
      size,
      offset,
      filters,
    } = (getState() as RootState).automatedBidding

    const ordering = getOrderingString(label, direction, key, '-id')

    const response = await api.get('/pricing/customer-companies-list/', {
      params: {
        limit: size,
        offset,
        ordering,
        robobidding_enabled: filters.autobidShowOnly === '0' || null,
        has_robobidder: filters.autobidShowOnly === '1' || null,
        portal_quoting_enabled: filters.autobidShowOnly === '2' || null,
        customer_company_id: filters.customerCompany || null,
        account_owner_agent_id: formatArrayOfIds(filters.accountOwnersList) || null,
        account_manager_user_id: formatArrayOfIds(filters.accountManagersList) || null,
      },
    })

    return keysToCamelCase(response.data)
  },
)

export const changeSettingsType = createAsyncThunk(
  'automatedBidding/changeSettingsType',
  async (
    { id, settingsType }: { id: number; settingsType: string },
    { dispatch, rejectWithValue },
  ) => {
    try {
      const response = await api.patch(
        '/pricing/customer-companies-list/',
        keysToSnakeCase({ customerCompanyId: id, settingsType }),
      )
      dispatch(getCustomerCompanies())
      return keysToCamelCase(response.data)
    } catch (err: CatchError) {
      return rejectWithValue(formatAxiosErrorToPayload(err))
    }
  },
)

export const getTimeBasedAdjustments = createAsyncThunk(
  'automatedBidding/getTimeBasedAdjustments',
  async (_, { getState }) => {
    const { customSettingsId = 'default' } = (getState() as RootState).automatedBidding

    const response = await api.get(
      `/pricing/automated-bidding-settings/${customSettingsId}/multipliers-list/`,
    )
    return keysToCamelCase(response.data)
  },
)

export const updateTimeBasedAdjustments = createAsyncThunk(
  'automatedBidding/updateTimeBasedAdjustments',
  async (_, { getState, dispatch, rejectWithValue }) => {
    const { customSettingsId = 'default', changedMultipliers } = (getState() as RootState)
      .automatedBidding

    try {
      const response = await api.patch(
        `/pricing/automated-bidding-settings/${customSettingsId}/multipliers-list/`,
        keysToSnakeCase(
          changedMultipliers.map(mult => ({
            name: mult.name,
            multiplier: getMarginValue(mult.multiplier, mult.sign),
          })),
        ),
      )
      dispatch(getTimeBasedAdjustments())
      dispatch(getLocationBasedAdjustments())
      return response.data
    } catch (err: CatchError) {
      return rejectWithValue(formatAxiosErrorToPayload(err))
    }
  },
)

export const getLocationBasedAdjustments = createAsyncThunk(
  'automatedBidding/getLocationBasedAdjustments',
  async (_, { getState }) => {
    const {
      customSettingsId = 'default',
      order: { label, direction, key },
      searchParams,
    } = (getState() as RootState).automatedBidding

    const ordering = getOrderingString(label, direction, key)

    const response = await api.get(
      `/pricing/automated-bidding-settings/${customSettingsId}/region-multipliers-list/`,
      {
        params: {
          ordering: ordering || null,
          equipment_type: searchParams.equipmentTypes.join(',') || null,
          origin_state: searchParams.originStates.join(',') || null,
          destination_state: searchParams.destinationStates.join(',') || null,
        },
      },
    )

    return keysToCamelCase(response.data)
  },
)

export const updateLocationBasedAdjustments = createAsyncThunk(
  'automatedBidding/updateLocationBasedAdjustments',
  async (_, { getState, dispatch, rejectWithValue }) => {
    const {
      customSettingsId = 'default',
      regionMultipliers,
      changedRegionMultipliers,
    } = (getState() as RootState).automatedBidding

    const payload = getDifferentObjects(changedRegionMultipliers, regionMultipliers, [
      'isValid',
      'index',
    ])

    try {
      await api.patch(
        `/pricing/automated-bidding-settings/${customSettingsId}/region-multipliers-list/`,
        keysToSnakeCase(
          payload.map((mult: RegionMultiplier) => ({
            ...mult,
            multiplier: getMarginValue(mult.multiplier, mult.sign),
          })),
        ),
      )
      dispatch(getLocationBasedAdjustments())
    } catch (err: CatchError) {
      return rejectWithValue(formatAxiosErrorToPayload(err))
    }
  },
)

export const addNewLocationBasedAdjustment = createAsyncThunk(
  'automatedBidding/addNewLocationBasedAdjustment',
  async (_, { getState, dispatch, rejectWithValue }) => {
    const { customSettingsId = 'default', newLocationBasedAdjustment } = (getState() as RootState)
      .automatedBidding

    try {
      const response = await api.post(
        `/pricing/automated-bidding-settings/${customSettingsId}/region-multipliers-list/`,
        keysToSnakeCase([
          {
            ...newLocationBasedAdjustment,
            multiplier: getMarginValue(
              newLocationBasedAdjustment.multiplier,
              newLocationBasedAdjustment.sign,
            ),
          },
        ]),
      )
      dispatch(getLocationBasedAdjustments())
      return keysToCamelCase(response.data)
    } catch (err: CatchError) {
      return rejectWithValue(formatAxiosErrorToPayload(err))
    }
  },
)

export const deleteLocationBasedAdjustment = createAsyncThunk(
  'automatedBidding/deleteLocationBasedAdjustment',
  async (multiplierId: number, { dispatch, rejectWithValue }) => {
    try {
      const response = await api.delete(`/pricing/modify-region-multiplier/${multiplierId}/`)
      dispatch(getLocationBasedAdjustments())
      return response.data
    } catch (err: CatchError) {
      return rejectWithValue(formatAxiosErrorToPayload(err))
    }
  },
)

export const deleteSelectedLocationBasedAdjustments = createAsyncThunk(
  'automatedBidding/deleteSelectedLocationBasedAdjustments',
  async (
    { multipliersIds, withToast = true }: { multipliersIds?: Array<number>; withToast?: boolean },
    { getState, dispatch, rejectWithValue },
  ) => {
    const { customSettingsId = 'default', selectedMultipliers } = (getState() as RootState)
      .automatedBidding

    try {
      await api.delete(
        `/pricing/automated-bidding-settings/${customSettingsId}/region-multipliers-list/`,
        {
          data: {
            region_multiplier_id: multipliersIds?.length ? multipliersIds : selectedMultipliers,
          },
        },
      )
      dispatch(getLocationBasedAdjustments())
      return withToast
    } catch (err: CatchError) {
      return rejectWithValue(formatAxiosErrorToPayload(err))
    }
  },
)

export const getEquipmentTypeSettings = createAsyncThunk(
  'automatedBidding/getEquipmentTypeSettings',
  async (_, { getState }) => {
    const { customSettingsId = 'default' } = (getState() as RootState).automatedBidding

    const response = await api.get(
      `/pricing/automated-bidding-settings/${customSettingsId}/equipment-type-settings-list/`,
    )

    return keysToCamelCase(response.data)
  },
)

export const updateEquipmentTypeSettings = createAsyncThunk(
  'automatedBidding/updateEquipmentTypeSettings',
  async (_, { getState, dispatch, rejectWithValue }) => {
    const {
      customSettingsId = 'default',
      changedEquipmentTypeSettings,
      equipmentTypeSettings,
    } = (getState() as RootState).automatedBidding

    const payload = getDifferentObjects(changedEquipmentTypeSettings, equipmentTypeSettings)

    try {
      const response = await api.patch(
        `/pricing/automated-bidding-settings/${customSettingsId}/equipment-type-settings-list/`,
        keysToSnakeCase(
          payload.map((type: EquipmentTypeSetting) => ({
            ...type,
            marginTarget: Number((type.sign === '+' ? '' : '-') + type.marginTarget),
          })),
        ),
      )
      dispatch(getEquipmentTypeSettings())
      return keysToCamelCase(response.data)
    } catch (err: CatchError) {
      return rejectWithValue(formatAxiosErrorToPayload(err))
    }
  },
)

export const getSettings = createAsyncThunk(
  'automatedBidding/getSettings',
  async (_, { getState, rejectWithValue }) => {
    const { customSettingsId = 'default' } = (getState() as RootState).automatedBidding

    try {
      const response = await api.get(`/pricing/automated-bidding-settings/${customSettingsId}/`)
      return keysToCamelCase(response.data)
    } catch (err: CatchError) {
      return rejectWithValue(formatAxiosErrorToPayload(err))
    }
  },
)

export const updateSettings = createAsyncThunk(
  'automatedBidding/updateSettings',
  async (data: any, { getState, dispatch, rejectWithValue }) => {
    const { customSettingsId = 'default' } = (getState() as RootState).automatedBidding

    try {
      const response = await api.patch(
        `/pricing/automated-bidding-settings/${customSettingsId}/`,
        keysToSnakeCase({
          ...data,
        }),
      )
      dispatch(getSettings())
      return response.data
    } catch (err: CatchError) {
      return rejectWithValue(formatAxiosErrorToPayload(err))
    }
  },
)

export const checkForOverridingMultipliers = createAsyncThunk(
  'automatedBidding/checkForOverridingMultipliers',
  async ({ isNew }: { isNew?: boolean }, { getState, dispatch }) => {
    const { changedRegionMultipliers, regionMultipliers, newLocationBasedAdjustment } = (
      getState() as RootState
    ).automatedBidding

    const changedMultipliers = getDifferentObjects(
      isNew ? [newLocationBasedAdjustment] : changedRegionMultipliers,
      regionMultipliers,
      ['isValid', 'index'],
    )

    const overridingMultipliers = regionMultipliers.filter((multiplier: RegionMultiplier) =>
      changedMultipliers.some(
        (changedMultiplier: RegionMultiplier) =>
          multiplier.regionMultiplierId !== changedMultiplier.regionMultiplierId &&
          (multiplier.originState === changedMultiplier.originState ||
            (!multiplier.originState && !changedMultiplier.originState)) &&
          (multiplier.destinationState === changedMultiplier.destinationState ||
            (!multiplier.destinationState && !changedMultiplier.destinationState)) &&
          multiplier.equipmentType === changedMultiplier.equipmentType,
      ),
    )

    if (!overridingMultipliers.length)
      isNew ? dispatch(addNewLocationBasedAdjustment()) : dispatch(updateLocationBasedAdjustments())

    return { overridingMultipliers, isNew }
  },
)

export const overrideMultipliers = createAsyncThunk(
  'automatedBidding/overrideMultipliers',
  async (_, { getState, dispatch }) => {
    const { isAddingNew, overridingMultipliers } = (getState() as RootState).automatedBidding

    const updateHandler = isAddingNew
      ? addNewLocationBasedAdjustment
      : updateLocationBasedAdjustments

    dispatch(
      deleteSelectedLocationBasedAdjustments({
        multipliersIds: overridingMultipliers.map(multiplier => multiplier.regionMultiplierId),
        withToast: false,
      }),
    )
      .unwrap()
      .then(() =>
        dispatch(updateHandler())
          .unwrap()
          .then(() => dispatch(setOverrideModalVisible(false)))
          .catch(() => {}),
      )
      .catch(() => {})
  },
)

const automatedBiddingSlice = createSlice({
  name: 'automatedBidding',
  initialState,
  reducers: {
    setSize(state, { payload }) {
      state.size = payload
    },
    setOffset(state, { payload }) {
      state.offset = payload
    },
    setOrder(state, { payload }) {
      state.order = payload
    },
    setFilters(state, { payload }) {
      state.filters = payload
    },
    setMultiplierBeingAdded(state, { payload }) {
      state.regionMultiplierBeingAddedOrEdited = payload
      if (!payload) state.newLocationBasedAdjustment = initialLocationBasedAdjustment
    },
    setCustomSettingsId(state, { payload }) {
      state.customSettingsId = payload
    },
    setSelectedMultipliers(state, { payload }) {
      state.selectedMultipliers = state.selectedMultipliers.includes(payload)
        ? state.selectedMultipliers.filter(mult => mult !== payload)
        : [...state.selectedMultipliers, payload]
    },
    resetSelectedMultipliers(state) {
      state.selectedMultipliers = []
    },
    setEditingMultiplier(state, { payload }) {
      state.editingMultiplier = payload
    },
    setPrimaryCheckbox(state) {
      const multipliersIds = state.regionMultipliers.map(
        multiplier => multiplier.regionMultiplierId,
      )

      state.selectedMultipliers =
        state.selectedMultipliers.length === state.regionMultipliers.length ? [] : multipliersIds
    },
    setChangedMultipliers(state, { payload }) {
      state.changedMultipliers = payload
    },
    resetChangedMultipliers(state) {
      state.changedMultipliers = state.multipliers
    },
    setChangedEquipmentTypeSettings(state, { payload }) {
      state.changedEquipmentTypeSettings = payload
    },
    resetChangedEquipmentTypeSettings(state) {
      state.changedEquipmentTypeSettings = state.equipmentTypeSettings
    },
    setChangedRegionMultipliers(state, { payload }) {
      state.changedRegionMultipliers = payload
    },
    resetChangedRegionMultipliers(state) {
      state.changedRegionMultipliers = state.regionMultipliers
    },
    setTimeBasedFormsValid(state, { payload }) {
      state.timeBasedFormsValid = payload
    },
    setMarginBasedFormsValid(state, { payload }) {
      state.marginBasedFormsValid = payload
    },
    setSearchParams(state, { payload }) {
      state.searchParams = payload
    },
    setOverrideModalVisible(state, { payload }) {
      state.isOverrideModalVisible = payload
    },
    setNewLocationBasedAdjustment(state, { payload }) {
      state.newLocationBasedAdjustment = payload
    },
  },
  extraReducers(builder) {
    builder
      .addCase(getCustomerCompanies.pending, state => {
        state.loading.partners = true
      })
      .addCase(getCustomerCompanies.fulfilled, (state, action) => {
        const { results, count } = action.payload
        state.loading.partners = false
        state.count = count
        state.automatedBiddingPartners = results.map((result: AutomatedBiddingPartner) => ({
          ...result,
          id: result.customerCompanyId,
        }))
      })
      .addCase(getCustomerCompanies.rejected, state => {
        state.loading.partners = false
      })
      .addCase(getTimeBasedAdjustments.pending, state => {
        state.loading.multipliers = true
      })
      .addCase(getTimeBasedAdjustments.fulfilled, (state, action) => {
        const { results } = action.payload
        state.loading.multipliers = false
        const multipliers = results.map((result: Multiplier) => ({
          ...result,
          sign: Number(result.multiplier) >= 1 ? '+' : '-',
          multiplier: getMarginPercentage(result.multiplier),
        }))
        state.multipliers = multipliers
        state.changedMultipliers = multipliers
      })
      .addCase(getTimeBasedAdjustments.rejected, state => {
        state.loading.regionMultipliers = false
      })
      .addCase(getLocationBasedAdjustments.pending, state => {
        state.loading.regionMultipliers = true
      })
      .addCase(getLocationBasedAdjustments.fulfilled, (state, action) => {
        const { results } = action.payload
        state.loading.regionMultipliers = false
        const regionMultipliers = results.map((result: RegionMultiplier, i: number) => ({
          ...result,
          sign: Number(result.multiplier) >= 1 ? '+' : '-',
          multiplier: getMarginPercentage(result.multiplier),
          isValid: false,
          pbsWithSimilarMlpsApplied: result.pbsWithSimilarMlpsApplied?.map(pbs => ({
            ...pbs,
            regionMultipliers: pbs.regionMultipliers.map(multiplier => ({
              ...multiplier,
              sign: Number(multiplier.multiplier) >= 1 ? '+' : '-',
              multiplier: getMarginPercentage(multiplier.multiplier),
            })),
            timeBasedMultipliers: pbs.timeBasedMultipliers.map(multiplier => ({
              ...multiplier,
              sign: Number(multiplier.multiplier) >= 1 ? '+' : '-',
              multiplier: getMarginPercentage(multiplier.multiplier),
            })),
          })),
          index: i,
        }))
        state.regionMultipliers = regionMultipliers
        state.changedRegionMultipliers = regionMultipliers
      })
      .addCase(getLocationBasedAdjustments.rejected, state => {
        state.loading.regionMultipliers = false
      })
      .addCase(addNewLocationBasedAdjustment.pending, state => {
        state.loading.saveRegionMultiplier = true
      })
      .addCase(addNewLocationBasedAdjustment.fulfilled, state => {
        state.loading.saveRegionMultiplier = false
        state.regionMultiplierBeingAddedOrEdited = false
        state.newLocationBasedAdjustment = initialLocationBasedAdjustment
        state.isAddingNew = false
        state.overridingMultipliers = []
        toast.success('Successfully created new location-based adjustment')
      })
      .addCase(addNewLocationBasedAdjustment.rejected, (state, { payload }) => {
        state.loading.saveRegionMultiplier = false
        toast.error(getErrorString(payload, 'Failed to create new location-based adjustment'))
      })
      .addCase(deleteLocationBasedAdjustment.pending, state => {
        state.loading.deleteLocationBasedAdjustment = true
      })
      .addCase(deleteLocationBasedAdjustment.fulfilled, state => {
        state.loading.deleteLocationBasedAdjustment = false
        toast.success('Successfully deleted location-based adjustment')
      })
      .addCase(deleteLocationBasedAdjustment.rejected, (state, { payload }) => {
        state.loading.deleteLocationBasedAdjustment = false
        toast.error(getErrorString(payload, 'Failed to delete location-based adjustment'))
      })
      .addCase(updateLocationBasedAdjustments.pending, state => {
        state.loading.updateLocationBasedAdjustments = true
      })
      .addCase(updateLocationBasedAdjustments.fulfilled, state => {
        state.loading.updateLocationBasedAdjustments = false
        state.editingMultiplier = null
        toast.success(
          `Successfully updated ${
            state.selectedMultipliers.length ? 'selected' : ''
          } location-based adjustments`,
        )
        state.selectedMultipliers = []
        state.overridingMultipliers = []
      })
      .addCase(updateLocationBasedAdjustments.rejected, (state, { payload }) => {
        state.loading.updateLocationBasedAdjustments = false
        toast.error(
          getErrorString(
            payload,
            `Failed to update ${
              state.selectedMultipliers.length ? 'selected' : ''
            } location-based adjustments`,
          ),
        )
      })
      .addCase(deleteSelectedLocationBasedAdjustments.pending, state => {
        state.loading.deleteSelectedLocationBasedAdjustments = true
      })
      .addCase(deleteSelectedLocationBasedAdjustments.fulfilled, (state, { payload }) => {
        state.loading.deleteSelectedLocationBasedAdjustments = false
        state.selectedMultipliers = []
        if (payload) toast.success('Successfully deleted selected location-based adjustments')
      })
      .addCase(deleteSelectedLocationBasedAdjustments.rejected, (state, { payload }) => {
        state.loading.deleteSelectedLocationBasedAdjustments = false
        toast.error(getErrorString(payload, 'Failed to delete selected location-based adjustments'))
      })
      .addCase(changeSettingsType.pending, state => {
        state.loading.changeSettingsType = true
      })
      .addCase(changeSettingsType.fulfilled, state => {
        state.loading.changeSettingsType = false
        toast.success('Successfully updated settings type')
      })
      .addCase(changeSettingsType.rejected, (state, { payload }) => {
        state.loading.changeSettingsType = false
        toast.error(getErrorString(payload, 'Failed to update settings type'))
      })
      .addCase(getSettings.pending, state => {
        state.loading.getSettings = true
      })
      .addCase(getSettings.fulfilled, (state, { payload }) => {
        state.loading.getSettings = false
        state.settings = payload
      })
      .addCase(getSettings.rejected, (state, { payload }) => {
        state.loading.getSettings = false
        toast.error(getErrorString(payload, 'Failed to get settings'))
      })
      .addCase(updateSettings.pending, state => {
        state.loading.updateSettings = true
      })
      .addCase(updateSettings.fulfilled, state => {
        state.loading.updateSettings = false
        toast.success('Successfully updated settings')
      })
      .addCase(updateSettings.rejected, (state, { payload }) => {
        state.loading.updateSettings = false
        toast.error(getErrorString(payload, 'Failed to update settings'))
      })
      .addCase(updateTimeBasedAdjustments.pending, state => {
        state.loading.updateTimeBasedAdjustments = true
      })
      .addCase(updateTimeBasedAdjustments.fulfilled, state => {
        state.loading.updateTimeBasedAdjustments = false
        toast.success('Successfully updated time-based adjustments')
      })
      .addCase(updateTimeBasedAdjustments.rejected, (state, { payload }) => {
        state.loading.updateTimeBasedAdjustments = false
        toast.error(getErrorString(payload, 'Failed to update time-based adjustments'))
      })
      .addCase(getEquipmentTypeSettings.pending, state => {
        state.loading.getEquipmentTypeSettings = true
      })
      .addCase(getEquipmentTypeSettings.fulfilled, (state, { payload }) => {
        const { results, count } = payload
        state.loading.getEquipmentTypeSettings = false
        let equipmentTypeSettings = results.map((type: EquipmentTypeSetting) => ({
          ...type,
          marginTarget: Math.abs(Number(type.marginTarget)),
          marginMax: type.marginMax.toString(),
          sign: Number(type.marginTarget) >= 0 ? '+' : '-',
        }))
        equipmentTypeSettings = orderBy(equipmentTypeSettings, 'equipmentType')
        state.equipmentTypeSettings = equipmentTypeSettings
        state.changedEquipmentTypeSettings = equipmentTypeSettings
        state.marginBasedFormsValid = [...Array(count)].map(() => false)
        state.equipmentTypes = results.map((type: EquipmentTypeSetting) => type.equipmentType)
      })
      .addCase(getEquipmentTypeSettings.rejected, (state, { payload }) => {
        state.loading.getEquipmentTypeSettings = false
        toast.error(getErrorString(payload, 'Failed to retrieve modal-based margin'))
      })
      .addCase(updateEquipmentTypeSettings.pending, state => {
        state.loading.updateEquipmentTypeSettings = true
      })
      .addCase(updateEquipmentTypeSettings.fulfilled, state => {
        state.loading.updateEquipmentTypeSettings = false
        toast.success('Successfully updated modal-based margin')
      })
      .addCase(updateEquipmentTypeSettings.rejected, (state, { payload }) => {
        state.loading.updateEquipmentTypeSettings = false
        toast.error(getErrorString(payload, 'Failed to update modal-based margin'))
      })
      .addCase(checkForOverridingMultipliers.fulfilled, (state, { payload }) => {
        state.isAddingNew = payload.isNew ?? false
        if (payload.overridingMultipliers.length) {
          state.isOverrideModalVisible = true
          state.overridingMultipliers = payload.overridingMultipliers
        }
      })
  },
})

export const {
  setSize,
  setOffset,
  setOrder,
  setMultiplierBeingAdded,
  setFilters,
  setCustomSettingsId,
  setSelectedMultipliers,
  setPrimaryCheckbox,
  resetSelectedMultipliers,
  setEditingMultiplier,
  setChangedMultipliers,
  resetChangedMultipliers,
  setChangedEquipmentTypeSettings,
  resetChangedEquipmentTypeSettings,
  setTimeBasedFormsValid,
  setMarginBasedFormsValid,
  resetChangedRegionMultipliers,
  setChangedRegionMultipliers,
  setSearchParams,
  setOverrideModalVisible,
  setNewLocationBasedAdjustment,
} = automatedBiddingSlice.actions

export default automatedBiddingSlice.reducer
