import { Button, Modal, TextInput } from '@components'
import { useState } from 'react'

import { useAppThunkDispatch } from '../../../../app/hooks'
import {
  setDupDialogCreated,
  setLoadDupQuantity,
  setShowDupLoadsDialog,
} from '../../../../redux/loadsSlice'

export const DuplicateLoadsDialog = ({ isVisible }: { isVisible: boolean }) => {
  const dispatch = useAppThunkDispatch()
  const showDupDialog = (visible: boolean) => dispatch(setShowDupLoadsDialog(visible))
  const close = () => showDupDialog(false)
  const [quantity, setQuantity] = useState(2)
  const updateQuantity = (value: string) => setQuantity(Number(value))
  const onCreate = () => {
    dispatch(setDupDialogCreated(true))
    dispatch(setLoadDupQuantity(quantity))
    close()
  }

  return (
    <Modal
      className='w-[464px] h-[278px] bg-black'
      isVisible={isVisible}
      setVisible={showDupDialog}
      showCloseIcon={false}
    >
      <div className='flex flex-col p-6 gap-6 justify-between'>
        <div className='flex justify-center'>
          <span className='text-sm text-center'>
            How many loads do you want to create in total?
          </span>
        </div>
        <hr />
        <div className='flex flex-col gap-4'>
          <span className='uppercase text-dark-gray font-semibold'>Max Number of Loads 10</span>
          <div className='w-full flex justify-center'>
            <TextInput
              autoFocus
              sm
              className='w-full'
              label='Quantity'
              type='number'
              value={quantity}
              onChange={updateQuantity}
            />
          </div>
        </div>
        <div className='flex justify-between'>
          <Button
            sm
            className='!w-[200px]'
            innerClassName='!w-[200px]'
            label='Cancel'
            type='secondary'
            onClick={close}
          />
          <Button
            sm
            className='!w-[200px]'
            innerClassName='!w-[200px]'
            label='Create'
            type='primary'
            onClick={onCreate}
          />
        </div>
      </div>
    </Modal>
  )
}
