import { Button, ConfirmationDialog, Select } from '@components'
import { XMarkIcon } from '@heroicons/react/24/solid'
import { useState } from 'react'
import { useLocation } from 'react-router-dom'
import tw from 'tailwind-styled-components'

import { useAppSelector, useAppThunkDispatch } from '../../../../app/hooks'
import { loadTags } from '../../../../common/constants'
import { Load } from '../../../../common/types'
import { getContractLanes } from '../../../../redux/contractLanesSlice'
import {
  getLoadDetail,
  getLoads,
  setContractLaneModalVisible,
  setSelectedLoadId,
  toggleWaterfall,
  updateLoad,
} from '../../../../redux/loadsSlice'

type LoadValueTypes = Load[keyof Load]

export const Tags = ({
  data,
  className,
  handleUpdate,
  editable = true,
}: {
  data: { [key: string]: LoadValueTypes }
  className?: string
  handleUpdate?: (tag: { key: string }) => void
  editable?: boolean
}) => {
  const [isModalVisible, setModalVisible] = useState(false)

  const { pathname } = useLocation()

  const dispatch = useAppThunkDispatch()

  const isExpansionRep = useAppSelector(state => state.user.user.isExpansionRep)
  const loading = useAppSelector(state => state.loads.loading.updateLoad)
  const customerContractLanes = useAppSelector(state => state.contractLane.customerContractLanes)

  const appliedTags = loadTags.filter(tag => data[tag.key])
  const unusedTags = loadTags.filter(tag => !data[tag.key])

  const showActions = isExpansionRep && editable

  const updateTag = (tag: { key: string }) => {
    dispatch(updateLoad({ id: data.id, [tag.key]: !data[tag.key] }))
      .unwrap()
      .then(() => dispatch(getLoads()))
      .catch(() => {})
  }

  const onClick = (tag: { key: string }) => (handleUpdate ? handleUpdate(tag) : updateTag(tag))

  const disconnectContractLane = async () => {
    await dispatch(toggleWaterfall('CANCEL'))

    dispatch(updateLoad({ id: data.id, contractLaneId: null }))
      .unwrap()
      .then(() => {
        dispatch(getLoads())
        dispatch(getLoadDetail(data.id))
        setModalVisible(false)
      })
      .catch(() => {})
  }

  const inLoadDetails = !/^\/loads$/.test(pathname)

  return (
    <Container className={className}>
      {appliedTags.map(tag => (
        <Tag key={tag.key} className={tag.background}>
          {tag.label}
          {showActions && <DeleteIcon onClick={() => onClick(tag)} />}
        </Tag>
      ))}
      {data.contractLaneId && (
        <Tag className='bg-dark-wheat'>
          Contract Lane #{data.contractLaneId} <DeleteIcon onClick={() => setModalVisible(true)} />
        </Tag>
      )}
      {data.network !== 2 && <Tag className='bg-dark-wheat'>Non-EXO Load</Tag>}
      {!!unusedTags.length && showActions && (
        <Select
          xs
          choices={unusedTags}
          value={{ label: 'Add tag' }}
          isClearable={false}
          field='label'
          className='w-[90px] mr-3'
          onChange={onClick}
        />
      )}
      {/* The button will show in loads list regardless of whether there are available contract lanes for now. It will only show in load details if the customer has contract lanes */}
      {!data.contractLaneId && (inLoadDetails ? !!customerContractLanes.length : true) && (
        <Button
          xxs
          type='secondary'
          onClick={() => {
            if (!inLoadDetails) {
              dispatch(setSelectedLoadId(data.id))
              dispatch(getContractLanes({ customerId: data.customerCompanyId }))
            }
            dispatch(setContractLaneModalVisible(true))
          }}
        >
          Connect Contract Lane
        </Button>
      )}
      <ConfirmationDialog
        isVisible={isModalVisible}
        setVisible={setModalVisible}
        confirmButtonLoading={loading}
        title={`Are you sure you want to disconnect Contract Lane #${data.contractLaneId}?`}
        confirmButtonText='Yes, Disconnect'
        confirmButtonType='danger'
        onConfirm={disconnectContractLane}
      />
    </Container>
  )
}

const Container = tw.td`
  flex
  items-center
  font-semibold
`

const Tag = tw.div`
  px-3
  lg:px-2.5
  py-1
  lg:py-0.5
  flex
  items-center
  justify-between
  rounded-full
  lg:rounded-md
  mr-3
`

const DeleteIcon = tw(XMarkIcon)`
  cursor-pointer
  hover:opacity-70
  transition-all
  w-4
  ml-2
`
