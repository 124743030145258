import { AlphaModal, Button, FileInput, Form, TextInput } from '@components'
import { noop } from 'lodash-es'
import { useEffect, useMemo, useState } from 'react'
import tw from 'tailwind-styled-components'

import { useAppSelector, useAppThunkDispatch } from '../../../app/hooks'
import { addCustomerDocument, updateCustomerDocument } from '../../../redux/customersSlice'

export const DocumentModal = ({
  document,
  setVisible,
  isVisible,
  editMode = false,
}: {
  document?: any
  isVisible: boolean
  setVisible: (value: boolean) => void
  editMode?: boolean
}) => {
  const [changedDocument, setChangedDocument] = useState(document || { name: '', file: null })
  const [isFormValid, setFormValid] = useState(false)

  const dispatch = useAppThunkDispatch()

  const updateLoading = useAppSelector(state => state.customers.loading.updateDocument)
  const addLoading = useAppSelector(state => state.customers.loading.addDocument)

  useEffect(() => {
    if (!editMode) setChangedDocument({ name: '', file: null })
  }, [isVisible])

  const handleSubmit = () => {
    const payload = {
      name: changedDocument.name,
      file: changedDocument.file,
      documentId: document?.id,
    }
    const handler = editMode ? updateCustomerDocument : addCustomerDocument
    dispatch(handler(payload))
      .unwrap()
      .then(() => setVisible(false))
      .catch(noop)
  }

  const title = useMemo(
    () => `${editMode ? 'Edit' : 'Add'} Document ${editMode ? `#${document.id}` : ''}`,
    [editMode, document],
  )

  return (
    <AlphaModal
      isVisible={isVisible}
      setVisible={setVisible}
      title={title}
      isConfirmButtonDisabled={!isFormValid}
      isConfirmButtonLoading={updateLoading || addLoading}
      onConfirm={handleSubmit}
    >
      <Form setFormValid={setFormValid} className='p-4'>
        <TextInput
          sm
          required
          label='Name'
          value={changedDocument.name}
          maxLength={64}
          onChange={name => setChangedDocument({ ...changedDocument, name })}
        />
        <FileOuterContainer>
          <FileInnerContainer $fileSelected={!!changedDocument.file}>
            <div className='flex justify-between items-center'>
              <span className='font-semibold'>
                {changedDocument.file
                  ? changedDocument.file.name || 'New Document'
                  : 'Upload a new document'}
              </span>
              {changedDocument.file ? (
                <Button
                  type='danger'
                  onClick={() => setChangedDocument({ ...changedDocument, file: null })}
                >
                  Delete
                </Button>
              ) : (
                <FileInput
                  required
                  mimeType='image/jpeg,image/png,image/bmp,image/webp,image/gif,application/pdf,application/vnd.openxmlformats-officedocument.wordprocessingml.document,application/msword,application/vnd.ms-excel,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,application/vnd.ms-excel.sheet.macroEnabled.12,text/csv'
                  onChange={file => {
                    setChangedDocument({
                      ...changedDocument,
                      file,
                      ...(!changedDocument.name && { name: file.name }),
                    })
                  }}
                >
                  <Button preIcon='plus-circle' type='warn'>
                    Upload
                  </Button>
                </FileInput>
              )}
            </div>
          </FileInnerContainer>
        </FileOuterContainer>
      </Form>
    </AlphaModal>
  )
}

const FileOuterContainer = tw.div`
  border
  border-light-blue
  rounded-lg
  mt-4
`

const FileInnerContainer = tw.div<{ $fileSelected: boolean }>`
  border-l-4
  border-link
  p-3
  rounded-lg
  ${({ $fileSelected }) => $fileSelected && 'bg-white border-success'}
`
