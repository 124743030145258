import tw from 'tailwind-styled-components'

import { useAppSelector } from '../../../app/hooks'
import { Notification } from '../../../common/types'

export const UnseenCount = () => {
  const { notificationsThisWeek } = useAppSelector(
    store => store.notifications ?? { notificationsThisWeek: [] },
  )

  const totalUnseen = notificationsThisWeek.filter((notif: Notification) => !notif.seen).length

  return !totalUnseen ? (
    <></>
  ) : (
    <MenuBadge>
      <span className='font-semibold text-xs text-[10px]'>{totalUnseen}</span>
    </MenuBadge>
  )
}

const MenuBadge = tw.div`
  bg-error
  rounded-full
  h-[18px]
  w-[18px]
  border-dark-blue
  border-2
  text-white
  absolute
  bottom-[-8px]
  flex
  items-center
  justify-center
`
