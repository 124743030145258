import { Menu } from '@headlessui/react'
import { BellIcon } from '@heroicons/react/24/outline'
import { useEffect } from 'react'
import tw from 'tailwind-styled-components'

import { useAppSelector, useAppThunkDispatch } from '../../../app/hooks'
import { Notification } from '../../../common/types'
import { updateNotifications } from '../../../redux/notificationsSlice'
import { NotificationGroup } from './NotificationGroup'
import { Tabs } from './Tabs'

export const MenuDisplay = () => {
  const dispatch = useAppThunkDispatch()
  const {
    notificationsToday,
    weekNotifsByDay,
    mode,
    notificationsThisWeek,
    loading: { update: notifUpdateLoading },
  } = useAppSelector(store => store.notifications)

  const isToday = mode === 'today'

  useEffect(() => {
    const notifs = isToday ? notificationsToday : notificationsThisWeek
    const unseen = notifs.filter((notif: Notification) => !notif.seen)
    if (unseen.length && !notifUpdateLoading) {
      const seen = unseen.map((notif: Notification) => ({ ...notif, seen: true }))
      dispatch(updateNotifications(seen))
    }
  }, [mode])

  return (
    <Dropdown $isToday={isToday}>
      <Header>
        <HeaderTitle>
          <BellIcon className='text-dark-blue w-6 h-6' />
          <span>Notifications</span>
        </HeaderTitle>
        <Tabs />
      </Header>
      {isToday && (
        <NotificationGroup
          {...{
            headerText: 'Today',
            notifications: notificationsToday,
            withMarkAllAsRead: true,
          }}
        />
      )}
      {!isToday && (
        <div className='flex flex-col w-full'>
          {weekNotifsByDay.map(([day, notifications]: [string, Notification[]], idx: number) => (
            <NotificationGroup
              key={day}
              {...{
                headerText: day,
                notifications,
                withMarkAllAsRead: !idx,
              }}
            />
          ))}
        </div>
      )}
    </Dropdown>
  )
}

type DropdownProps = {
  $isToday?: boolean
  children: React.ReactNode
}

const Dropdown: (p: DropdownProps) => React.ReactElement = tw(Menu.Items)`
  origin-top-right
  mt-2
  absolute
  -right-20
  lg:right-0
  w-screen
  lg:w-[749px]
  max-md:h-[90vh]
  lg:min-h-[400px]
  bg-white
  z-10
  rounded-lg
  shadow-md
  cursor-default
  transition-all
`

const Header = tw.div`
  w-full
  h-12
  flex
  items-center
  py-3
  px-4
  justify-between
  bg-lighter-blue
  rounded-t-md
`

const HeaderTitle = tw.div`
  h-full
  flex
  items-center
  gap-3
  text-dark-blue
  text-base
  font-semibold
`
