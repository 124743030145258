import { Button, ButtonIconType } from '@components'

import { SelectButton, SelectButtonProps } from '../CreateLoadModal/components'

type ModalFooterProps = {
  onConfirm: () => void
  onCancel: () => void
  onDelete: () => void
  ready: boolean
  selectBtnOpts: SelectButtonProps['options']
  showDelete: boolean
  showSelect: boolean
  confirmLabel?: string
  confirmPreIcon?: ButtonIconType
  cancelLabel?: string
  loading?: boolean
}

export const ModalFooter = ({
  onConfirm,
  onCancel,
  onDelete,
  ready,
  confirmLabel = 'Create load',
  confirmPreIcon = 'arrow-right',
  cancelLabel,
  loading,
  selectBtnOpts,
  showSelect,
  showDelete,
}: ModalFooterProps) => (
  <>
    <Button
      short
      sm
      dataTestId='cancel'
      label={cancelLabel ?? 'Cancel'}
      type='secondary'
      onClick={onCancel}
    />
    <div className='flex justify-between'>
      {showDelete && (
        <Button
          short
          sm
          className='mr-3'
          dataTestId='delete'
          label='Delete Load'
          type='secondary'
          onClick={onDelete}
        />
      )}
      {confirmLabel !== 'Create load' && (
        <Button
          short
          sm
          dataTestId='confirm'
          disabled={!ready}
          label={confirmLabel}
          loading={loading}
          preIcon={confirmPreIcon}
          type='warn'
          onClick={onConfirm}
        />
      )}
      {confirmLabel === 'Create load' && showSelect && (
        <SelectButton label={confirmLabel} options={selectBtnOpts} onClick={onConfirm} />
      )}
      {confirmLabel === 'Create load' && !showSelect && (
        <Button short sm label='Create and Continue' type='warn' onClick={onConfirm} />
      )}
    </div>
  </>
)
