import { CatchError, formatAxiosErrorToPayload, getErrorString } from '@common'
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { toast } from 'react-toastify'

import { api } from '../api/api'
import { initialFilters } from '../common/constants'
import {
  FactoringAccessorial,
  FactoringDocument,
  LoadFactoringRequest,
  LoadFactoringRequestListItem,
  ReserveSettlement,
  RootState,
  SearchFilters,
} from '../common/types'
import {
  displayFullLocation,
  formatFileName,
  keysToCamelCase,
  keysToSnakeCase,
} from '../common/utils'

type LoadFactoringRequestState = {
  count: {
    loadFactoringRequests: number
    pendingLoadFactoringRequests: number
  }
  factoringDocuments: {
    id: number
    file: string
    fileName: string
    timeStamp: string
  }[]
  currentLoadFactoringRequest: LoadFactoringRequest | null
  carrierLfrSettlement: ReserveSettlement | null
  loadFactoringRequests: Array<LoadFactoringRequestListItem>
  loading: {
    list: boolean
    updatingLoadFactoringRequest: boolean
    currentLoadFactoringRequest: boolean
    carrierLfrSettlement: boolean
    addFactoringDocument: boolean
    addBuyout: boolean
    importBuyout: boolean
  }
  importBuyout: {
    selectedFile: File | null
    columns: string[]
    rows: {
      index: number
      row: string[]
      error: string | null
    }[]
    totals: {
      totalInvoiceAmount: number
      totalAdvancedAmount: number
      totalExoFee: number
      totalReserveEscrow: number
    }
    error: string | null
  }
  filters: SearchFilters
  offset: number
  size: number
}

const initialState: LoadFactoringRequestState = {
  count: {
    loadFactoringRequests: 0,
    pendingLoadFactoringRequests: 0,
  },
  factoringDocuments: [],
  loadFactoringRequests: [],
  currentLoadFactoringRequest: null,
  carrierLfrSettlement: null,
  loading: {
    list: false,
    updatingLoadFactoringRequest: false,
    currentLoadFactoringRequest: false,
    carrierLfrSettlement: false,
    addFactoringDocument: false,
    addBuyout: false,
    importBuyout: false,
  },
  importBuyout: {
    selectedFile: null,
    columns: [],
    rows: [],
    error: null,
    totals: {
      totalInvoiceAmount: 0,
      totalAdvancedAmount: 0,
      totalExoFee: 0,
      totalReserveEscrow: 0,
    },
  },
  filters: initialFilters,
  offset: 0,
  size: 50,
}

// example: '/path/to/file/document.txt' => ['document.txt']
const extractFileNameFromPath = (file = '') => file.split('/').slice(-1)
export const getFactoringDocuments = createAsyncThunk(
  'factoring/getFactoringDocuments',
  async ({ loadId }: { loadId: number }) => {
    const factoringDocuments = await api
      .get(`/loads/api/load-finance-documents/${loadId}/`)
      .then(({ data }) =>
        keysToCamelCase(data).map((document: FactoringDocument) => ({
          id: document.id,
          fileName: extractFileNameFromPath(document.fileName),
          timeStamp: document.createdAt,
          file: document.file,
        })),
      )
    return factoringDocuments
  },
)

export const deleteFactoringDocument = createAsyncThunk(
  'factoring/deleteFactoringDocument',
  async (
    { document, loadId }: { document: FactoringDocument; loadId: number },
    { dispatch, rejectWithValue },
  ) => {
    try {
      const response = await api.delete(`/accounts/api/carrier/finance-document-ud/${document.id}/`)

      dispatch(getFactoringDocuments({ loadId: loadId }))
      return response.data
    } catch (err: CatchError) {
      return rejectWithValue(formatAxiosErrorToPayload(err))
    }
  },
)

export const addFactoringDocument = createAsyncThunk(
  'factoring/addFactoringDocument',
  async (
    document: {
      loadId: number
      fileData: string
    },
    { dispatch, rejectWithValue },
  ) => {
    const formData = new FormData()
    formData.append('file', document?.fileData)
    formData.append('document_type', 'BOL_AND_OR_RATE_CONFIRMATION')

    try {
      const response = await api.post(
        `/loads/api/load-finance-documents/${document.loadId}/`,
        formData,
      )

      dispatch(getFactoringDocuments({ loadId: document.loadId }))

      return response.data
    } catch (err: CatchError) {
      return rejectWithValue(formatAxiosErrorToPayload(err))
    }
  },
)

export const getLoadFactoringRequestDetails = createAsyncThunk(
  'loadFactoringRequests/getLoadFactoringRequestDetails',
  async ({ id }: { id: number }) =>
    api
      .get(`/accounts/api/load-factoring-request/${id}/`)
      .then(({ data }) => keysToCamelCase(data)),
)

export const getLoadFactorinRequestSettlement = createAsyncThunk(
  'loadFactoringRequests/getLoadFactorinRequestSettlement',
  async ({ id }: { id: number }) =>
    api.get(`/billing/api/carrier-lfr-settlement/${id}/`).then(({ data }) => keysToCamelCase(data)),
)

export const getLoadFactoringRequests = createAsyncThunk(
  'loadFactoringRequests/getLoadFactoringRequests',
  async (_, { getState }) => {
    const { filters, size, offset } = (getState() as RootState).loadFactoringRequests
    const response = await api.get('/accounts/api/load-factoring-requests/', {
      params: {
        offset,
        limit: size,
        factoring_status: filters.loadFactoringRequestStatus || null,
        carrier_company: filters.carrier || null,
        customer_company: filters?.customerCompany || null,
        load__id: filters.loadId || null,
        load__customer_reference_id: filters.refId || null,
      },
    })
    return keysToCamelCase(response.data)
  },
)

export const getPendingLoadFactoringRequests = createAsyncThunk(
  'loadFactoringRequests/getPendingLoadFactoringRequests',
  async () => {
    const response = await api.get('/accounts/api/load-factoring-requests/', {
      params: { offset: 0, limit: 1, factoring_status: 'PENDING' },
    })
    return response.data
  },
)

// Update active status of a single load factoring request
export const updateLoadFactoringRequestStatus = createAsyncThunk(
  'carriers/updateLoadFactoringRequestStatus',
  async (
    payload: {
      id: number
      status: string
      rejectionReason?: string
      rejectionNote?: string
      rejectedBy?: string
      emails?: Array<string>
    },
    { dispatch },
  ) => {
    const response = await api.patch(
      `/accounts/api/load-factoring-request/${payload.id}/`,
      keysToSnakeCase(payload),
    )

    dispatch(updateLocalLoadFactoringRequest(payload))

    return response.data.status
  },
)

// Put a hold on an LFR
export const holdLoadFactoringRequest = createAsyncThunk(
  'carriers/holdLoadFactoringRequest',
  async (
    payload: {
      id: number
      holdReason: string
      holdNote: string
      holdPutBy: string
      emails: Array<string>
    },
    { dispatch },
  ) => {
    const response = await api.patch(
      `/factoring/api/hold-lfr/${payload.id}/`,
      keysToSnakeCase(payload),
    )

    dispatch(updateLocalLoadFactoringRequest({ ...payload, hold: true }))

    return response.data.status
  },
)

// Remove a hold that was put on an LFR
export const unholdLoadFactoringRequest = createAsyncThunk(
  'carriers/unholdLoadFactoringRequest',
  async (
    payload: {
      id: number
    },
    { dispatch },
  ) => {
    const response = await api.patch(
      `/factoring/api/unhold-lfr/${payload.id}/`,
      keysToSnakeCase(payload),
    )

    dispatch(updateLocalLoadFactoringRequest({ ...payload, hold: false }))

    return response.data.status
  },
)

export const addBuyout = createAsyncThunk(
  'carriers/addBuyout',
  async (
    payload: {
      carrierId: number
      customerId: number
      customerReferenceNumber: string
      invoiceDate: string
      advancedDate: string
      invoiceAmount: number
      advancedAmount: number
      exoFee: number
    },
    { rejectWithValue },
  ) => {
    try {
      const response = await api.post(`/factoring/api/add-buyout/`, keysToSnakeCase(payload))
      return response.data.status
    } catch (err: CatchError) {
      return rejectWithValue(formatAxiosErrorToPayload(err))
    }
  },
)

export const importBuyout = createAsyncThunk(
  'carriers/importBuyout',
  async (
    payload: {
      reviewOnly: boolean
      file: File
    },
    { rejectWithValue },
  ) => {
    try {
      const formData = new FormData()

      formData.append('file', payload.file)
      formData.append('review_only', String(payload.reviewOnly))
      const response = await api.post(`/factoring/api/import-buyout-csv/`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      })
      return { response: keysToCamelCase(response.data), reviewOnly: payload.reviewOnly }
    } catch (err: CatchError) {
      return rejectWithValue(formatAxiosErrorToPayload(err))
    }
  },
)

export const loadFactoringRequestSlice = createSlice({
  name: 'loadFactoringRequests',
  initialState,
  reducers: {
    updateLocalLoadFactoringRequest(state, action) {
      for (let i = 0; i < state.loadFactoringRequests.length; i++) {
        const req = state.loadFactoringRequests[i]
        if (req.id == action.payload.id) {
          if (action.payload.status) {
            req.status = action.payload.status
          }
          if (typeof action.payload.hold === 'boolean') {
            req.isHold = action.payload.hold
          }
          const nowISO = new Date().toISOString()
          if (action.payload.holdReason) {
            req.holdReason = action.payload.holdReason
            req.holdNote = action.payload.holdNote
            req.holdPutAt = nowISO
            req.holdPutBy = action.payload.holdPutBy
          }
          if (action.payload.rejectionReason) {
            req.rejectionReason = action.payload.rejectionReason
            req.rejectionNote = action.payload.rejectionNote
            req.rejectedAt = nowISO
            req.rejectedBy = action.payload.rejectedBy
          }
        }
      }
    },
    setSize(state, { payload }) {
      state.size = payload
    },
    setOffset(state, { payload }) {
      state.offset = payload
    },
    setFilters(state, { payload }) {
      state.filters = payload
    },
    setPendingLoadFactoringRequests(state, { payload }) {
      state.count.pendingLoadFactoringRequests = payload
    },
    setImportBuyoutSelectedFile(state, { payload }) {
      state.importBuyout.selectedFile = payload
      state.importBuyout.error = null
    },
  },
  extraReducers(builder) {
    builder
      .addCase(getLoadFactoringRequests.pending, state => {
        state.loading.list = true
      })
      .addCase(getLoadFactoringRequests.fulfilled, (state, action) => {
        const data = action.payload
        const loadFactoringRequests: LoadFactoringRequest[] = data.results.map(
          (req: any): LoadFactoringRequest => ({
            bucketName: req.companyBucketName,
            submissionType: req.submissionType,
            canApprove: req.canApprove,
            carrierCompany: req.carrierCompany,
            carrierName: req.carrierName,
            load: req.load,
            customer: {
              id: req.customerCompany,
              name: req.companyName,
            },
            creditScore: req.companyCreditScore,
            daysToPay: req.companyDaysToPay,
            documents: req.documents.map((document: FactoringDocument) => ({
              ...document,
              annotations: document.annotations?.map(annotation => {
                if (['location', 'origin', 'destination'].includes(annotation.type)) {
                  return {
                    ...annotation,
                    data: {
                      ...annotation.data,
                      value: displayFullLocation(annotation.data),
                    },
                  }
                }
                return annotation
              }),
              fileName: formatFileName(document.fileName),
            })),
            id: req.id,
            customerRefId: req.referenceNumber,
            paymentMethodId: req.paymentMethod,
            status: req.status,
            totalAmount: req.totalAmount,
            createdAt: req.createdAt,
            noaVerified: req.noaVerified,
            isHold: req.isHold,
            isPaidClosed: req.isPaidClosed,
            isFunded: req.isFunded,
            archived: req.archived,
            holdReason: req.holdReason,
            holdNote: req.holdNote,
            holdPutBy: req.holdPutBy,
            holdPutAt: req.holdPutAt,
            rejectionReason: req.rejectionReason,
            rejectionNote: req.rejectionNote,
            rejectedBy: req.rejectedBy,
            rejectedAt: req.rejectedAt,
          }),
        )
        state.loadFactoringRequests = loadFactoringRequests
        state.count.loadFactoringRequests = data.count
        state.loading.list = false
      })
      .addCase(getLoadFactoringRequests.rejected, state => {
        state.loading.list = false
      })
      .addCase(getPendingLoadFactoringRequests.fulfilled, (state, action) => {
        const data = action.payload
        state.count.pendingLoadFactoringRequests = data.count
      })
      .addCase(getLoadFactoringRequestDetails.pending, state => {
        state.loading.currentLoadFactoringRequest = true
      })
      .addCase(getLoadFactorinRequestSettlement.pending, state => {
        state.loading.carrierLfrSettlement = true
      })
      .addCase(getLoadFactoringRequestDetails.fulfilled, (state, action) => {
        const rra = action.payload.reserveRecoveryAccessorial
        const invoiceAmount = action.payload.totalAmount
        const factoringFee = action.payload.factoringFee
        const accessorials: FactoringAccessorial[] = action.payload.accessorials

        action.payload.netAdvance = invoiceAmount - (rra.reserveAmount + factoringFee)

        if (rra.recoveryAmount > 0) {
          action.payload.grossAdvance = action.payload.netAdvance
          action.payload.netAdvance -= rra.recoveryAmount
        }

        const filteredAcessorials = accessorials.filter(item => !item.isReserve && !item.isRecovery)
        if (filteredAcessorials.length > 0) {
          if (rra.recoveryAmount === 0) {
            action.payload.grossAdvance = action.payload.netAdvance
          }
          filteredAcessorials.map(acessorial => {
            action.payload.netAdvance -= acessorial.amount
          })
        }

        action.payload.calculatedEscrow = rra.reserveAmount + factoringFee

        state.currentLoadFactoringRequest = action.payload
        state.loading.currentLoadFactoringRequest = false
      })
      .addCase(getLoadFactorinRequestSettlement.fulfilled, (state, action) => {
        const accessorialTotal = action.payload.accessorialTotal
        const debtorPaid = action.payload.debtorPaid
        const factoringFee = action.payload.factoringFee
        const invoiceAmount = action.payload.invoiceAmount
        const netAdvance = action.payload.netAdvance

        const rra = state.currentLoadFactoringRequest?.reserveRecoveryAccessorial

        if (debtorPaid == 0) {
          action.payload.paymentState = 'None'
        } else if (debtorPaid > 0 && debtorPaid < invoiceAmount) {
          action.payload.paymentState = 'Partial'
        } else if (invoiceAmount == debtorPaid) {
          action.payload.paymentState = 'Full'
        } else {
          action.payload.paymentState = ''
        }

        action.payload.grossReserveSettlement =
          debtorPaid - (netAdvance + rra?.recoveryAmount + accessorialTotal)
        action.payload.netReserveSettlement = action.payload.grossReserveSettlement - factoringFee

        action.payload.netSettlement = netAdvance + action.payload.netReserveSettlement

        state.carrierLfrSettlement = action.payload
        state.loading.carrierLfrSettlement = false
      })
      .addCase(getLoadFactoringRequestDetails.rejected, state => {
        state.loading.currentLoadFactoringRequest = false
        toast.error('Error getting factoring request data')
      })
      .addCase(getLoadFactorinRequestSettlement.rejected, state => {
        state.loading.carrierLfrSettlement = false
        toast.error('Error getting factoring request settlement data')
      })
      .addCase(updateLoadFactoringRequestStatus.pending, state => {
        state.loading.updatingLoadFactoringRequest = true
      })
      .addCase(updateLoadFactoringRequestStatus.fulfilled, state => {
        state.loading.updatingLoadFactoringRequest = false
        toast.success('Updated')
      })
      .addCase(updateLoadFactoringRequestStatus.rejected, state => {
        state.loading.updatingLoadFactoringRequest = false
        toast.error('Failed')
      })
      .addCase(holdLoadFactoringRequest.pending, state => {
        state.loading.updatingLoadFactoringRequest = true
      })
      .addCase(holdLoadFactoringRequest.fulfilled, state => {
        state.loading.updatingLoadFactoringRequest = false
        toast.success('Updated')
      })
      .addCase(holdLoadFactoringRequest.rejected, state => {
        state.loading.updatingLoadFactoringRequest = false
        toast.error('Failed')
      })
      .addCase(unholdLoadFactoringRequest.pending, state => {
        state.loading.updatingLoadFactoringRequest = true
      })
      .addCase(unholdLoadFactoringRequest.fulfilled, state => {
        state.loading.updatingLoadFactoringRequest = false
        toast.success('Updated')
      })
      .addCase(unholdLoadFactoringRequest.rejected, state => {
        state.loading.updatingLoadFactoringRequest = false
        toast.error('Failed')
      })
      .addCase(getFactoringDocuments.fulfilled, (state, action) => {
        state.factoringDocuments = action.payload
      })
      .addCase(getFactoringDocuments.rejected, () => {
        toast.error('Error getting documents')
      })
      .addCase(addFactoringDocument.pending, state => {
        state.loading.addFactoringDocument = true
      })
      .addCase(addFactoringDocument.fulfilled, state => {
        state.loading.addFactoringDocument = false
      })
      .addCase(addFactoringDocument.rejected, state => {
        state.loading.addFactoringDocument = false
        toast.error('Error adding document')
      })
      .addCase(addBuyout.pending, state => {
        state.loading.addBuyout = true
      })
      .addCase(addBuyout.fulfilled, state => {
        state.loading.addBuyout = false
        toast.success('Buyout added successfully')
      })
      .addCase(addBuyout.rejected, (state, action) => {
        state.loading.addBuyout = false
        toast.error(getErrorString(action.payload, 'Failed to add buyout'))
      })
      .addCase(importBuyout.pending, state => {
        state.loading.importBuyout = true
      })
      .addCase(importBuyout.fulfilled, (state, action) => {
        state.loading.importBuyout = false
        state.importBuyout.error = null
        state.importBuyout.columns = action.payload.response.columns
        state.importBuyout.rows = action.payload.response.rows
        state.importBuyout.totals = action.payload.response.totals
        const failedRows = action.payload.response.rows.filter((row: any) => row.error)
        if (!action.payload.reviewOnly && failedRows.length == 0) {
          toast.success(
            `Successfully imported buyout of ${action.payload.response.rows.length} loads`,
          )
        }
      })
      .addCase(importBuyout.rejected, (state, action) => {
        state.loading.importBuyout = false
        state.importBuyout.error = getErrorString(
          action.payload,
          'Failed to import buyout CSV',
          10000,
        )
      })
  },
})

export const {
  updateLocalLoadFactoringRequest,
  setSize,
  setOffset,
  setFilters,
  setPendingLoadFactoringRequests,
  setImportBuyoutSelectedFile,
} = loadFactoringRequestSlice.actions

export default loadFactoringRequestSlice.reducer
