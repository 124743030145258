import { AlphaModal } from '@components'
import { useEffect, useMemo, useRef, useState } from 'react'
import tw from 'tailwind-styled-components'

import { WizardStep } from '../../common/types'
import { ModalFooter, Step } from './components'

export const CreationWizard = ({
  isVisible,
  setVisible,
  title,
  steps,
  onSubmit,
  submitButtonText,
  loading,
}: {
  isVisible: boolean
  setVisible: (value: boolean) => void
  title: string
  steps: Array<WizardStep>
  onSubmit: () => void
  submitButtonText: string
  loading?: boolean
}) => {
  const [currentStep, setCurrentStep] = useState(0)
  const [completedSteps, setCompletedSteps] = useState<number[]>([])
  const [checkedSteps, setCheckedSteps] = useState<number[]>([])

  const topRef: any = useRef(null)

  const step = useMemo(() => steps[currentStep], [steps, currentStep])

  const isConfirmButtonDisabled = useMemo(() => !step.isValid, [steps])

  const allRequiredStepsValid = useMemo(
    () => steps.filter(step => step.required).every(step => step.isValid),
    [steps, completedSteps],
  )

  useEffect(() => {
    topRef.current?.scrollIntoView()
  }, [currentStep])

  const onNextClick = () => {
    setCompletedSteps([...completedSteps, currentStep])
    if (step.isCompleted || step.required) setCheckedSteps([...checkedSteps, currentStep])
    if (currentStep < steps.length - 1) setCurrentStep(currentStep + 1)
    else onSubmit()
  }

  return (
    <AlphaModal
      title={title}
      className='min-w-[50vw] max-w-[1100px] !h-[677px] !min-h-[677px] !overflow-hidden'
      closeOnOverlayClick={false}
      isVisible={isVisible}
      setVisible={setVisible}
      showCloseIcon={false}
      confirmButtonLabel='Next'
      isConfirmButtonDisabled={isConfirmButtonDisabled}
      footerClassName='bg-light-blue border-t border-light-gray'
      footer={
        <ModalFooter
          isNextDisabled={!step.isValid}
          showBackButton={!!currentStep}
          showFinishButton={allRequiredStepsValid && currentStep !== steps.length - 1}
          setVisible={setVisible}
          isLastStep={steps.length - 1 === currentStep}
          submitButtonText={submitButtonText}
          loading={loading}
          onNextClick={onNextClick}
          onBackClick={() => setCurrentStep(currentStep - 1)}
          onSaveAndFinish={onSubmit}
        />
      }
    >
      <div className='flex w-[50vw] min-w-[800px]'>
        <StepsContainer>
          {steps.map((step, i) => (
            <Step
              key={step.label}
              step={step}
              isActive={i === currentStep}
              isCompleted={completedSteps.includes(i)}
              isChecked={checkedSteps.includes(i)}
              index={i}
              setCurrentStep={setCurrentStep}
            />
          ))}
        </StepsContainer>
        <Component>
          <div ref={topRef} />
          <div className='p-6'>{step?.component}</div>
        </Component>
      </div>
    </AlphaModal>
  )
}

const StepsContainer = tw.div`
  border-r
  border-light-gray
  w-[168px]
  min-w-[168px]
  overflow-auto
  h-[576px]
  max-h-[576px]
  min-h-[576px]
`

const Component = tw.div`
  overflow-auto
  w-full
  h-[576px]
  max-h-[576px]
  min-h-[576px]
`
