import { TextInput } from '@components'
import React from 'react'
import tw from 'tailwind-styled-components'

type DetailsSearchTableProps = {
  headerNames: string[]
  rows: JSX.Element | JSX.Element[]
  setSearchValue: (value: string) => void
  empty?: boolean
  emptyText?: string
  search?: string
  padding?: number
  className?: string
}

export const DetailsSearchTable = ({
  headerNames,
  rows,
  setSearchValue,
  empty,
  emptyText,
  search,
  padding,
  className = '',
}: DetailsSearchTableProps) => (
  <SearchContainer className={className}>
    <CustomTextInput
      sm
      $padding={padding}
      dataTestId='search'
      icon='search'
      value={search}
      onChange={(searchValue: string) => setSearchValue(searchValue)}
    />
    <ScrollContainer $padding={padding}>
      <SearchTable>
        <thead>
          <tr className='text-dark-gray'>
            {headerNames.map((header, i) => (
              <SearchHeader key={i} $center={i === headerNames.length - 1}>
                {header}
              </SearchHeader>
            ))}
          </tr>
        </thead>
        <tbody>{rows}</tbody>
      </SearchTable>
      {empty && <div className='text-center'>{emptyText}</div>}
    </ScrollContainer>
  </SearchContainer>
)

const SearchTable = tw.table`
  text-left
  w-full
  text-sm
`

const ScrollContainer = tw.div<{ $padding?: number }>`
  overflow-auto
  flex-1
  mt-2
  max-h-[calc(100vh-442px)]
  pb-4
  ${({ $padding }) => $padding && `px-${$padding}`}
`
const SearchContainer = tw.div`
  flex
  flex-col
  h-full
`
const SearchHeader = tw.th<{ $center?: boolean }>`
  pb-2
  font-normal
  ${({ $center }) => $center && 'text-center'}
`

const CustomTextInput = tw(TextInput)<{ $padding?: number }>`
  mb-4
  ${({ $padding }) => $padding && `px-${$padding}`}
`
