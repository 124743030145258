import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { toast } from 'react-toastify'

import { api } from '../api/api'

type LoadboardsSliceState = {
  loading: {
    removeExternalLoadboardPostings: boolean
  }
}

const initialState: LoadboardsSliceState = {
  loading: {
    removeExternalLoadboardPostings: false,
  },
}

// Remove all of a customer's loads from external loadboards
export const removeExternalLoadboardPostings = createAsyncThunk(
  'loadboards/removeExternalLoadboardPostings',
  async (payload: { customerId: string | number }) => {
    await api.post(`/integrations/api/remove-truckstop-postings/${payload.customerId}/`, {
      remove_postings: true,
    })
    return payload.customerId
  },
)

export const loadboardsSlice = createSlice({
  name: 'loadboards',
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(removeExternalLoadboardPostings.pending, state => {
        state.loading.removeExternalLoadboardPostings = true
      })
      .addCase(removeExternalLoadboardPostings.fulfilled, state => {
        state.loading.removeExternalLoadboardPostings = false
        toast.success('Removed external loadboard postings successfully')
      })
      .addCase(removeExternalLoadboardPostings.rejected, state => {
        state.loading.removeExternalLoadboardPostings = false
        toast.error('Failed to remove external loadboard postings')
      })
  },
})

export default loadboardsSlice.reducer
