import './Autocomplete.scss'

import { PlusCircleIcon } from '@heroicons/react/24/outline'
import { debounce } from 'lodash-es'
import { FocusEvent, FormEvent, forwardRef, useCallback, useEffect, useState } from 'react'
import Autosuggest from 'react-autosuggest'
import tw from 'tailwind-styled-components'

import { useAppSelector, useAppThunkDispatch } from '../../app/hooks'
import { geocode, getAutocompleteLocations } from '../../redux/locationsSlice'
import { CreateLocationModal } from '../CreateModals'

export type LocationAutocompleteProps = {
  value?: string
  label?: string
  className?: string
  placeholder?: string
  setValue: Function
  style?: any
  inputClassName?: string
  searchType?: 'geocode' | 'db'
  required?: boolean
  requireContactOnCreate?: boolean
}

export const LocationAutocomplete = forwardRef<HTMLInputElement, LocationAutocompleteProps>(
  (
    {
      value = '',
      label = '',
      className = '',
      placeholder = '',
      setValue,
      style = {},
      inputClassName = '',
      searchType = 'geocode',
      required = false,
      requireContactOnCreate = true,
    }: LocationAutocompleteProps,
    ref,
  ) => {
    const [isVisible, setVisible] = useState(false)

    const dispatch = useAppThunkDispatch()
    const locations = useAppSelector(state =>
      searchType === 'geocode' ? state.locations.locations : state.locations.autocompleteLocations,
    )

    const getSuggestionValue = ({ title, name }: any) => (searchType === 'geocode' ? title : name)

    const renderSuggestion = ({
      id,
      title,
      name,
      addressPreview,
    }: {
      id: number
      title?: string
      name?: string
      addressPreview?: string
    }) =>
      id === -1 ? (
        <div className='flex items-center text-dark-gray'>
          <PlusCircleIcon className='w-5 mr-2' />
          <span>Add new location</span>
        </div>
      ) : (
        <>
          <div>{searchType === 'geocode' ? title : name}</div>
          {addressPreview && <div className='text-xs text-dark-gray mt-1'>{addressPreview}</div>}
        </>
      )

    const [query, setQuery] = useState(value || '')

    const [suggestions, setSuggestions] = useState(locations)

    const onChange = (event: any, { newValue }: any) => {
      setQuery(newValue)
    }

    const geocodeDebounced = useCallback(
      debounce((address: string) => dispatch(geocode({ address, citiesOnly: true })), 250),
      [],
    )

    const retrieveLocations = ({ value }: any) => {
      if (searchType === 'geocode') {
        geocodeDebounced(value)
      } else {
        dispatch(getAutocompleteLocations(value))
      }
    }

    const onSuggestionSelected = (event: FormEvent, { suggestion }: any) => {
      if (suggestion.id === -1) {
        setQuery('')
        setValue({})
        setVisible(true)
      } else {
        setValue(suggestion)
      }
    }

    const inputProps = {
      className: `placeholder-dark-gray ${inputClassName}`,
      placeholder,
      value: query,
      onChange,
      style,
      required,
      ref,
      onBlur: (
        event: FocusEvent<HTMLElement>,
        params?: { highlightedSuggestion: { id: number } },
      ) => {
        const highlightedSuggestion = params?.highlightedSuggestion
        if (highlightedSuggestion && highlightedSuggestion.id === -1) setQuery('')
        else if (highlightedSuggestion) setValue(highlightedSuggestion)
      },
    }

    useEffect(() => {
      setSuggestions(locations)
    }, [locations])

    useEffect(() => {
      if (!query) setValue('')
    }, [query])

    useEffect(() => {
      if ((value && query && query !== value) || (value && !query)) {
        setQuery(value)
      }
      if (!value && query) setQuery('')
    }, [value])

    return (
      <div className={className}>
        {label && (
          <Label>
            {label}
            {required && <span className='text-error'> *</span>}
          </Label>
        )}
        <Autosuggest
          getSuggestionValue={getSuggestionValue}
          inputProps={inputProps}
          renderInputComponent={props => <AutocompleteInput {...props} />}
          renderSuggestion={renderSuggestion}
          suggestions={
            [{ id: -1, name: 'Add new location', title: 'Add new location' }, ...suggestions] || []
          }
          onSuggestionsClearRequested={() => setSuggestions([])}
          onSuggestionSelected={onSuggestionSelected}
          onSuggestionsFetchRequested={retrieveLocations}
        />
        <CreateLocationModal
          isVisible={isVisible}
          requireContact={requireContactOnCreate}
          setVisible={setVisible}
        />
      </div>
    )
  },
)

const AutocompleteInput = tw.input`
  border
  rounded-lg
  focus:outline-none
  focus:border-gray-800
  transition-all
  border-border-gray
  px-3
`

const Label = tw.div`
  mb-1
  block
  text-dark-gray
  self-start
  font-poppins
  text-xs
`
