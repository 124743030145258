import { CatchError, formatAxiosErrorToPayload, getErrorString } from '@common'
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { toast } from 'react-toastify'

import { api } from '../api/api'
import { AutoCompleteLocation, RootState } from '../common/types'
import { keysToCamelCase } from '../common/utils'

type AutocompleteState = {
  loading: {
    csrSearchOptions: boolean
    customerNamesOptions: boolean
    agentSearchOptions: boolean
    managerSearchOptions: boolean
    exoUsersSearchOptions: boolean
    carrierSearchOptions: boolean
    fsrSearchOptions: boolean
  }
  agentSearchOptions: Array<{
    id: number
    text: string
  }>
  carrierSearchOptions: Array<{
    id: number
    selectedText: string
    text: string
  }>
  locationSearchOptions: Array<AutoCompleteLocation>
  managerSearchOptions: Array<{
    id: number
    text: string
  }>
  customerNamesOptions: Array<{
    id: number
    selectedText: string
    text: string
    billingError: boolean
  }>
  csrSearchOptions: Array<{
    id: number
    text: string
  }>
  fsrSearchOptions: Array<{
    id: number
    text: string
  }>
  dispatchContactOptions: Array<any>
  accessorialTypes: Array<{
    id: number
    name: string
    description: string | null
    reimbursement: boolean
  }>
  factoringCompaniesOptions: Array<{
    id: number
    businessName: string
    addressLine1: string | null
    addressLine2: string | null
    city: string | null
    state: string | null
    postalCode: string | null
    country: string | null
    phone: string | null
    email: string | null
    contactName: string | null
  }>
  exoUsersSearchOptions: Array<{
    id: number
    text: string
  }>
  loadSearchOptions: Array<{
    id: number
    text: string
  }>
}

const initialState: AutocompleteState = {
  loading: {
    csrSearchOptions: false,
    customerNamesOptions: false,
    agentSearchOptions: false,
    managerSearchOptions: false,
    exoUsersSearchOptions: false,
    carrierSearchOptions: false,
    fsrSearchOptions: false,
  },
  agentSearchOptions: [],
  carrierSearchOptions: [],
  locationSearchOptions: [],
  managerSearchOptions: [],
  customerNamesOptions: [],
  csrSearchOptions: [],
  fsrSearchOptions: [],
  dispatchContactOptions: [],
  accessorialTypes: [],
  factoringCompaniesOptions: [],
  exoUsersSearchOptions: [],
  loadSearchOptions: [],
}

// Accessorial types
export const getAccessorialTypes = createAsyncThunk('autocomplete/getAccessorialTypes', async () =>
  api.get('/loads/api/load-accessorial-types/').then(({ data }) => data),
)

// Dispatch contacts
export const getDispatchContacts = createAsyncThunk(
  'autocomplete/getDispatchContacts',
  async (id: string) =>
    api.get(`/accounts/api/carrier/contact/${id}/?limit=100`).then(({ data }) => data),
)

// Auto-complete for location search
export const autoCompleteLocationSearch = createAsyncThunk(
  'autocomplete/autoCompleteLocationSearch',
  async (payload: string) =>
    api
      .get('/locations/location-front-end-autocomplete/', { params: { q: payload } })
      .then(({ data }) => data),
)

// Auto-complete for carrier search
export const autoCompleteCarrierSearch = createAsyncThunk(
  'autocomplete/autoCompleteCarrierSearch',
  async (payload: string) =>
    api
      .get('/accounts/carrier-front-end-autocomplete/', { params: { q: payload } })
      .then(({ data }) => keysToCamelCase(data)),
)

// Auto-complete for carrier by exact MC/DOT match
export const findCarrierByMcOrDot = createAsyncThunk(
  'autocomplete/findCarrierByMcOrDot',
  async ({ mcNumber, dotNumber }: { mcNumber?: string; dotNumber?: string }, { dispatch }) => {
    let matchingCarriers = []
    if (mcNumber) {
      const response = await dispatch(autoCompleteCarrierSearch(mcNumber))
        .unwrap()
        .catch(() => {})
      if (response) {
        matchingCarriers = response.results.filter(
          (carrier: { mcNumber: string }) => carrier.mcNumber === mcNumber,
        )
      }
    }
    if (dotNumber && !matchingCarriers.length) {
      const response = await dispatch(autoCompleteCarrierSearch(dotNumber))
        .unwrap()
        .catch(() => {})
      if (response) {
        matchingCarriers = response.results.filter(
          (carrier: { dotNumber: string }) => carrier.dotNumber === dotNumber,
        )
      }
    }
    return matchingCarriers
  },
)

// Auto-complete for agent search
export const autoCompleteAgentSearch = createAsyncThunk(
  'autocomplete/autoCompleteAgentSearch',
  async (payload: string) =>
    api
      .get('/accounts/agent-front-end-autocomplete/', { params: { q: payload } })
      .then(({ data }) => data),
)

// Auto-complete for agent search
export const autoCompleteExoUsersSearch = createAsyncThunk(
  'autocomplete/autoCompleteExoUsersSearch',
  async (payload: string) =>
    api
      .get('/accounts/exo-users-front-end-autocomplete/', { params: { q: payload } })
      .then(({ data }) => data),
)

// Auto-complete for manager search
export const autoCompleteManagerSearch = createAsyncThunk(
  'autocomplete/autoCompleteManagerSearch',
  async (payload: string) =>
    api
      .get('/accounts/account-manager-autocomplete/', { params: { q: payload } })
      .then(({ data }) => data),
)

// Auto-complete for customer names
export const autoCompleteCustomerNameSearch = createAsyncThunk(
  'autocomplete/autoCompleteCustomerName',
  async (payload: string) =>
    api
      .get('/accounts/customer-front-end-autocomplete/', { params: { q: payload } })
      .then(({ data }) => keysToCamelCase(data)),
)

// Auto-complete for carrier sales representative
export const autoCompleteCSRSearch = createAsyncThunk(
  'autocomplete/autoCompleteCSRSearch',
  async (payload: string) =>
    api
      .get('/accounts/csr-front-end-autocomplete/', { params: { q: payload } })
      .then(({ data }) => data),
)

// Auto-complete for EXO Finance sales representative
export const autoCompleteFSRSearch = createAsyncThunk(
  'autocomplete/autoCompleteFSRSearch',
  async (payload: string) =>
    api
      .get('/accounts/finance-manager-autocomplete/', { params: { q: payload } })
      .then(({ data }) => data),
)

// Auto-complete for factoring companies
export const autoCompleteFactoringCompanySearch = createAsyncThunk(
  'autocomplete/autoCompleteFactoringCompanySearch',
  async (payload: string) =>
    api
      .get('/accounts/api/factoring/autocomplete/', { params: { q: payload } })
      .then(({ data }) => data),
)

// Auto-complete for load
export const autoCompleteLoadSearch = createAsyncThunk(
  'autocomplete/autoCompleteLoadSearch',
  async (payload: string, { getState, rejectWithValue }) => {
    const carrierId = (getState() as RootState).reservesRecoveries.newManualReserveEntry.carrier.id
    return api
      .get('/loads/factoring-load-autocomplete/', { params: { carrier_id: carrierId, q: payload } })
      .then(({ data }) => data)
      .catch((err: CatchError) => rejectWithValue(formatAxiosErrorToPayload(err)))
  },
)

export const autocompleteSlice = createSlice({
  name: 'autocomplete',
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(autoCompleteExoUsersSearch.pending, state => {
        state.loading.exoUsersSearchOptions = true
      })
      .addCase(autoCompleteExoUsersSearch.fulfilled, (state, action) => {
        state.loading.exoUsersSearchOptions = false
        state.exoUsersSearchOptions = keysToCamelCase(action.payload.results)
      })
      .addCase(autoCompleteExoUsersSearch.rejected, state => {
        state.loading.exoUsersSearchOptions = false
        toast.error('Failed to lookup users')
      })
      .addCase(autoCompleteAgentSearch.pending, state => {
        state.loading.agentSearchOptions = true
      })
      .addCase(autoCompleteAgentSearch.fulfilled, (state, action) => {
        state.loading.agentSearchOptions = false
        state.agentSearchOptions = keysToCamelCase(action.payload.results)
      })
      .addCase(autoCompleteAgentSearch.rejected, state => {
        state.loading.agentSearchOptions = false
        toast.error('Failed to lookup agents')
      })
      .addCase(autoCompleteFactoringCompanySearch.fulfilled, (state, action) => {
        state.factoringCompaniesOptions = keysToCamelCase(action.payload.results)
      })
      .addCase(autoCompleteFactoringCompanySearch.rejected, () => {
        toast.error('Failed to lookup factoring companies')
      })
      .addCase(autoCompleteLoadSearch.fulfilled, (state, action) => {
        state.loadSearchOptions = keysToCamelCase(action.payload.results)
      })
      .addCase(autoCompleteLoadSearch.rejected, payload => {
        toast.error(getErrorString(payload, 'Failed to lookup loads'))
      })
      .addCase(getAccessorialTypes.fulfilled, (state, action) => {
        state.accessorialTypes = keysToCamelCase(action.payload)
      })
      .addCase(getAccessorialTypes.rejected, () => {
        toast.error('Failed to get accessorial types')
      })
      .addCase(getDispatchContacts.fulfilled, (state, action) => {
        state.dispatchContactOptions = keysToCamelCase(action.payload.results)
      })
      .addCase(getDispatchContacts.rejected, () => {
        toast.error('Failed to lookup dispatch contacts')
      })
      .addCase(autoCompleteLocationSearch.fulfilled, (state, action) => {
        state.locationSearchOptions = keysToCamelCase(action.payload.results)
      })
      .addCase(autoCompleteLocationSearch.rejected, () => {
        toast.error('Failed to lookup locations')
      })
      .addCase(autoCompleteCarrierSearch.pending, state => {
        state.loading.carrierSearchOptions = true
      })
      .addCase(autoCompleteCarrierSearch.fulfilled, (state, action) => {
        state.loading.carrierSearchOptions = false
        state.carrierSearchOptions = action.payload.results
      })
      .addCase(autoCompleteCarrierSearch.rejected, state => {
        state.loading.carrierSearchOptions = false
        toast.error('Failed to lookup carriers')
      })
      .addCase(autoCompleteManagerSearch.pending, state => {
        state.loading.managerSearchOptions = true
      })
      .addCase(autoCompleteManagerSearch.fulfilled, (state, action) => {
        state.loading.managerSearchOptions = false
        state.managerSearchOptions = keysToCamelCase(action.payload.results)
      })
      .addCase(autoCompleteManagerSearch.rejected, state => {
        state.loading.managerSearchOptions = false
        toast.error('Failed to lookup acquisition reps')
      })
      .addCase(autoCompleteCustomerNameSearch.pending, state => {
        state.loading.customerNamesOptions = true
      })
      .addCase(autoCompleteCustomerNameSearch.fulfilled, (state, { payload }) => {
        state.loading.customerNamesOptions = false
        state.customerNamesOptions = payload.results.map(
          (customer: { isBillingAddress: boolean; isInvoiceContact: boolean }) => ({
            ...customer,
            billingError: !customer.isInvoiceContact,
          }),
        )
      })
      .addCase(autoCompleteCustomerNameSearch.rejected, state => {
        state.loading.customerNamesOptions = false
        toast.error('Failed to lookup customer names')
      })
      .addCase(autoCompleteCSRSearch.pending, state => {
        state.loading.csrSearchOptions = true
      })
      .addCase(autoCompleteCSRSearch.fulfilled, (state, action) => {
        state.loading.csrSearchOptions = false
        state.csrSearchOptions = keysToCamelCase(action.payload.results)
      })
      .addCase(autoCompleteCSRSearch.rejected, state => {
        state.loading.csrSearchOptions = false
        toast.error('Failed to lookup carrier sales representatives')
      })
      .addCase(autoCompleteFSRSearch.pending, state => {
        state.loading.fsrSearchOptions = true
      })
      .addCase(autoCompleteFSRSearch.fulfilled, (state, action) => {
        state.loading.fsrSearchOptions = false
        state.fsrSearchOptions = keysToCamelCase(action.payload.results)
      })
      .addCase(autoCompleteFSRSearch.rejected, state => {
        state.loading.fsrSearchOptions = false
        toast.error('Failed to lookup EXO Finance sales representatives')
      })
  },
})

export default autocompleteSlice.reducer
