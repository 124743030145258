import { CheckIcon } from '@heroicons/react/24/outline'
import tw from 'tailwind-styled-components'

export const CheckboxInput = ({
  onClick,
  isChecked,
  text,
  className = '',
  disabled = false,
}: {
  onClick: (value: boolean) => void
  isChecked: boolean
  text?: string
  className?: string
  disabled?: boolean
}) => (
  <Container
    $disabled={disabled}
    $isChecked={isChecked}
    className={className}
    data-testid='container'
    onClick={() => onClick(!isChecked)}
  >
    <Checkbox $isChecked={isChecked}>
      {isChecked && <CheckIcon className='text-white w-3' />}
    </Checkbox>
    {text && <span className='ml-4'>{text}</span>}
  </Container>
)

const Container = tw.div<{ $disabled: boolean; $isChecked: boolean }>`
  mt-4
  flex
  items-center
  cursor-pointer
  hover:opacity-80
  w-fit
  transition-all
  ${({ $disabled }: { $disabled: boolean }) => $disabled && 'opacity-70 cursor-not-allowed'}
`

const Checkbox = tw.div`
  rounded-md
  w-[18px]
  min-w-[18px]
  h-[18px]
  flex
  items-center
  justify-center
  border
  border-light-blue
  transition-all
  ${({ $isChecked }: { $isChecked: boolean }) => $isChecked && 'bg-success border-success'}
`
