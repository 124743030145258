import {
  CatchError,
  formatAxiosErrorToPayload,
  formatDateFilterForBackend,
  getErrorString,
} from '@common'
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import dayjs from 'dayjs'
import { toast } from 'react-toastify'

import { api } from '../api/api'
import { initialFilters } from '../common/constants'
import { LoadPayment, RootState, SearchFilters, TableOrder } from '../common/types'
import {
  booleanFromYesNoString,
  formatRowsForNavigation,
  getOrderingString,
  keysToCamelCase,
  keysToSnakeCase,
} from '../common/utils'

type LoadPaymentsState = {
  loadPayments: Array<LoadPayment>
  loading: {
    loadPayments: boolean
    voidPayment: boolean
    approvePayment: boolean
    createCarrierBill: boolean
  }
  count: number
  offset: number
  order: TableOrder
  size: number
  filters: SearchFilters
  currentPayment: {
    id: number
    loadMargin: {
      carrierDue: number
      carrierTotalPrice: number
      quickPay: number
    }
    amount?: string | number
    loadPaymentId: number
  }
  selected: number[]
  approveSelected: boolean
  currentPayable?: LoadPayment | null
  payableDueDate?: string | null
  invoicedDate?: string | null
}

const initialState: LoadPaymentsState = {
  loadPayments: [],
  loading: {
    loadPayments: false,
    voidPayment: false,
    approvePayment: false,
    createCarrierBill: false,
  },
  count: 0,
  offset: 0,
  order: { label: '', direction: '', key: '' },
  size: 50,
  filters: initialFilters,
  currentPayment: {
    id: 0,
    loadMargin: { carrierDue: 0, carrierTotalPrice: 0, quickPay: 0 },
    loadPaymentId: 0,
  },
  selected: [],
  approveSelected: false,
  currentPayable: null,
  payableDueDate: null,
  invoicedDate: null,
}

export const getPayableLoads = createAsyncThunk(
  'loadPayments/getPayableLoads',
  async ({ type }: { type?: string }, { getState }) => {
    const {
      filters,
      size = 50,
      offset = 0,
      order: { label, direction, key },
    } = (getState() as RootState).loadPayments

    const ordering = getOrderingString(label, direction, key) || null
    const response = await api.get('/loads/api/payable-loads/', {
      params: {
        limit: size,
        offset,
        ordering,
        load_status__in: '14,21,22', // Ready To Bill, Billing in Progress & Completed
        id: filters.loadId || null,
        carrier: filters.carrier || null,
        ...(typeof filters.loadPaymentType === 'string' && {
          carrier__payment_method_type:
            filters.loadPaymentType === '1'
              ? 'QUICK_PAY'
              : filters.loadPaymentType === '0'
                ? 'STANDARD'
                : 'FACTORING',
          exo_factoring: filters.loadPaymentType === '2' || null,
          non_exo_factoring: filters.loadPaymentType === '3' || null,
        }),
        is_paid: filters.isPaid,
        ...formatDateFilterForBackend(filters.deliveryDate, 'delivered_date'),
        ...(filters.hasPayableDueDate &&
          formatDateFilterForBackend(filters.dueDate, 'payable_due_date')),
        ...(filters.isPaid && formatDateFilterForBackend(filters.datePaid, 'paid_date')),
        payable_due_date__isnull:
          !filters.isPaid && typeof filters.hasPayableDueDate === 'boolean'
            ? !filters.hasPayableDueDate
            : null,
        order_type__in: filters.orderTypes?.join(',') || null,
        is_carrier_invoice: booleanFromYesNoString(filters.hasCarrierInvoice),
        is_bol: booleanFromYesNoString(filters.hasBOL),
        is_customer_invoice: booleanFromYesNoString(filters.hasCustomerInvoice),
        hold: booleanFromYesNoString(filters.hold),
      },
    })
    return keysToCamelCase({ ...response.data, type })
  },
)

export const voidPayment = createAsyncThunk(
  'loadPayments/voidPayment',
  async (payload: { loadPaymentId: number }, { dispatch, rejectWithValue }) => {
    try {
      const response = await api.post('/billing/api/check-void/', keysToSnakeCase(payload))
      dispatch(getPayableLoads({}))
      return response.data
    } catch (err: CatchError) {
      return rejectWithValue(formatAxiosErrorToPayload(err))
    }
  },
)

export const approvePayment = createAsyncThunk(
  'loadPayments/approvePayment',
  async (id: number | string, { dispatch, rejectWithValue }) => {
    try {
      const response = await api.post(`/billing/api/process-carrier-payment/${id}/`)
      dispatch(getPayableLoads({}))
      return response.data
    } catch (err: CatchError) {
      return rejectWithValue(formatAxiosErrorToPayload(err))
    }
  },
)

export const createCarrierBill = createAsyncThunk(
  'loadPayments/createCarrierBill',
  async (id: number, { getState, dispatch, rejectWithValue }) => {
    const { payableDueDate: dueDate } = (getState() as RootState).loadPayments

    try {
      const response = await api.post(
        `/billing/api/process-load-bill/${id}/`,
        keysToSnakeCase({
          dueDate: dayjs(dueDate).format('MM-DD-YYYY'),
        }),
      )
      dispatch(getPayableLoads({}))
      return response.data
    } catch (err: CatchError) {
      return rejectWithValue(formatAxiosErrorToPayload(err))
    }
  },
)

const loadPaymentsSlice = createSlice({
  name: 'loadPayments',
  initialState,
  reducers: {
    setSize(state, { payload }) {
      state.size = payload
    },
    setOffset(state, { payload }) {
      state.offset = payload
    },
    setFilters(state, { payload }) {
      state.filters = {
        ...state.filters,
        ...payload,
      }
    },
    setCurrentPayment(state, { payload }) {
      state.currentPayment = payload
    },
    setOrder(state, { payload }) {
      state.order = payload
    },
    setCurrentPayable(state, { payload }) {
      state.currentPayable = state.loadPayments.find(payable => payable.id === payload)
    },
    handleSelect(state, { payload }) {
      state.selected = state.selected.includes(payload)
        ? state.selected.filter((item: any) => item !== payload)
        : [...state.selected, payload]
    },
    resetSelected(state) {
      state.selected = []
    },
    handleSelectAll(state) {
      const allSelected = state.selected.length === state.loadPayments.length
      state.selected = allSelected ? [] : state.loadPayments.map((payment: any) => payment.id)
    },
    setApproveSelected(state, { payload }) {
      state.approveSelected = payload
    },
    setPayableDueDate(state, { payload }) {
      state.payableDueDate = payload
    },
  },
  extraReducers(builder) {
    builder
      .addCase(getPayableLoads.pending, state => {
        state.loading.loadPayments = true
      })
      .addCase(getPayableLoads.fulfilled, (state, { payload }) => {
        const { count, results, type } = payload
        const loadPayments = formatRowsForNavigation(results, state.offset)
        state.loadPayments = loadPayments
        state.count = count
        state.loading.loadPayments = false
        if (type)
          state.currentPayable = loadPayments[type === 'prev' ? state.loadPayments.length - 1 : 0]
      })
      .addCase(getPayableLoads.rejected, state => {
        toast.error('Failed to get load payments')
        state.loading.loadPayments = false
      })
      .addCase(voidPayment.pending, state => {
        state.loading.voidPayment = true
      })
      .addCase(voidPayment.fulfilled, state => {
        state.loading.voidPayment = false
        toast.success('Successfully voided payment')
      })
      .addCase(voidPayment.rejected, (state, { payload }) => {
        toast.error(getErrorString(payload, 'Failed to void payment'))
        state.loading.voidPayment = false
      })
      .addCase(approvePayment.pending, state => {
        state.loading.approvePayment = true
      })
      .addCase(approvePayment.fulfilled, state => {
        state.loading.approvePayment = false
        toast.success('Successfully approved payment')
      })
      .addCase(approvePayment.rejected, (state, { payload }) => {
        toast.error(getErrorString(payload, 'Failed to approve payment'))
        state.loading.approvePayment = false
      })
      .addCase(createCarrierBill.pending, state => {
        state.loading.createCarrierBill = true
      })
      .addCase(createCarrierBill.fulfilled, state => {
        state.loading.createCarrierBill = false
        toast.success('Successfully created carrier bill')
      })
      .addCase(createCarrierBill.rejected, (state, { payload }) => {
        toast.error(getErrorString(payload, 'Failed to create carrier bill'))
        state.loading.createCarrierBill = false
      })
  },
})

export const {
  setSize,
  setOffset,
  setFilters,
  setCurrentPayment,
  setOrder,
  setCurrentPayable,
  handleSelectAll,
  handleSelect,
  setApproveSelected,
  resetSelected,
  setPayableDueDate,
} = loadPaymentsSlice.actions

export default loadPaymentsSlice.reducer
