import { CatchError, formatAxiosErrorToPayload, getErrorString } from '@common'
import { downloadCSV } from '@common'
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { cloneDeep, mapKeys, omit, orderBy, snakeCase } from 'lodash-es'
import { toast } from 'react-toastify'

import { api } from '../api/api'
import { initialFilters } from '../common/constants'
import {
  CarrierContact,
  CarrierDetails,
  CarrierEquipment,
  CarrierInsurance,
  CarrierLane,
  CarrierLookupHighway,
  CarrierPaymentMethod,
  CSVField,
  Document,
  EquipmentAccessory,
  Load,
  LocalDocument,
  Note,
  RootState,
  SearchFilters,
  ValidatedCarrierContact,
} from '../common/types'
import {
  addPrecedingZeros,
  cleanFilters,
  keysToCamelCase,
  keysToSnakeCase,
  serializeAddress,
  validateContact,
} from '../common/utils'
import { buildNewDocument } from './documentsSlice'

type CarrierDetailsState = {
  carriers: Array<CarrierDetails>
  size: number
  offset: number
  count: {
    carriers: number
    payments: number
    matches: number
    documents: number
    notes: number
    equipments: number
    contacts: number
  }
  currentCarrier: CarrierDetails | null
  currentCarrierBackup: CarrierDetails | null
  shipments: Array<Load>
  loading: {
    addCustomer: boolean
    saveCarrierDetails: boolean
    details: boolean
    list: boolean
    createCarrier: boolean
    saveNote: Array<string | number>
    deleteNote: boolean
    getNotes: boolean
    getPayments: boolean
    getMatches: boolean
    getCarrierContacts: boolean
    addCarrierContact: boolean
    saveCarrierContact: Array<number>
    deleteCarrierContact: boolean
    getInsurances: boolean
    saveCarrierInsurance: Array<number>
    addCarrierInsurnace: boolean
    deleteCarrierInsurance: boolean
    getPaymentMethods: boolean
    saveCarrierPaymentMethod: Array<number>
    addCarrierPaymentMethod: boolean
    deleteCarrierPaymentMethod: boolean
    getDocuments: boolean
    saveDocument: boolean
    deleteDocument: boolean
    getLanes: boolean
    getEquipments: boolean
    getAccessories: boolean
    getAccessoryOptions: boolean
    deleteCustomerCarrierRelationship: boolean
    deleteLane: boolean
    setEquipments: boolean
    exportCSV: boolean
    addNewCarrierLane: boolean
    addNewCarrierEquipments: boolean
    checkCarrierMcOrDot: boolean
  }
  totalShipments: number
  averageMargin: number
  newNote: string
  notes: Array<Note>
  notesBackup: Array<Note>
  payments: Array<any>
  matches: Array<Load>
  contacts: Array<ValidatedCarrierContact>
  contactsBackup: Array<ValidatedCarrierContact>
  newContact: ValidatedCarrierContact
  insurances: Array<CarrierInsurance>
  insurancesBackup: Array<CarrierInsurance>
  newInsurance: CarrierInsurance
  paymentMethods: CarrierPaymentMethod[]
  paymentMethodsBackup: CarrierPaymentMethod[]
  newPaymentMethod: CarrierPaymentMethod
  newDocument: LocalDocument
  documents: Array<Document | LocalDocument>
  documentsBackup: Array<Document | LocalDocument>
  lanes: Array<CarrierLane>
  equipments: Array<CarrierEquipment>
  equipmentsBackup: Array<CarrierEquipment>
  accessories: Array<EquipmentAccessory>
  accessoryOptions: Array<EquipmentAccessory>
  selectedAccessories: Array<EquipmentAccessory>
  filters: SearchFilters
  exportFields: CSVField[]
  selectedFields: Array<string>
  carrierLookup: null | CarrierLookupHighway
}

const initialState: CarrierDetailsState = {
  carriers: [],
  size: 50,
  offset: 0,
  count: {
    carriers: 0,
    payments: 0,
    matches: 0,
    documents: 0,
    notes: 0,
    equipments: 0,
    contacts: 0,
  },
  currentCarrier: null,
  currentCarrierBackup: null,
  shipments: [],
  loading: {
    addCustomer: false,
    saveCarrierDetails: false,
    details: false,
    list: false,
    createCarrier: false,
    saveNote: [],
    deleteNote: false,
    getNotes: false,
    getPayments: false,
    getMatches: false,
    getCarrierContacts: false,
    addCarrierContact: false,
    saveCarrierContact: [],
    deleteCarrierContact: false,
    getInsurances: false,
    saveCarrierInsurance: [],
    addCarrierInsurnace: false,
    deleteCarrierInsurance: false,
    getPaymentMethods: false,
    saveCarrierPaymentMethod: [],
    addCarrierPaymentMethod: false,
    deleteCarrierPaymentMethod: false,
    getDocuments: false,
    saveDocument: false,
    deleteDocument: false,
    getLanes: false,
    getEquipments: false,
    getAccessories: false,
    getAccessoryOptions: false,
    deleteCustomerCarrierRelationship: false,
    deleteLane: false,
    setEquipments: false,
    exportCSV: false,
    addNewCarrierLane: false,
    addNewCarrierEquipments: false,
    checkCarrierMcOrDot: false,
  },
  totalShipments: 0,
  averageMargin: 0,
  newNote: '',
  notes: [],
  notesBackup: [],
  payments: [],
  matches: [],
  contacts: [],
  contactsBackup: [],
  newContact: {
    id: -1,
    name: '',
    email: '',
    phone: '',
    isAccounting: false,
    isClaims: false,
    isDispatch: false,
    isDriver: false,
    isFinance: false,
    isPrimary: false,
    sendEmailOffers: false,
    sendSmsOffers: false,
    isNameValid: false,
    isEmailValid: false,
    isPhoneValid: false,
  },
  insurances: [],
  insurancesBackup: [],
  newInsurance: {
    coverageAmount: null,
    createdAt: '',
    expirationDate: '',
    id: -1,
    insuranceType: -1,
    insuranceTypeDisplay: '',
    notes: '',
    policyNumber: '',
    producer: '',
    provider: '',
    updatedAt: '',
  },
  paymentMethods: [],
  paymentMethodsBackup: [],
  newPaymentMethod: {
    id: -1,
    isPrimary: false,
    name: '',
    paymentMethod: 'ACH',
  },
  documents: [],
  documentsBackup: [],
  newDocument: buildNewDocument(),
  lanes: [],
  equipments: [],
  equipmentsBackup: [],
  accessories: [],
  selectedAccessories: [],
  accessoryOptions: [],
  filters: initialFilters,
  exportFields: [
    { label: 'Carrier Name', key: 'name' },
    { label: 'MC Number', key: 'mc_number' },
    { label: 'DOT Number', key: 'dot_number' },
    { label: 'Acquisition Rep', key: 'manager' },
    { label: 'Base Location', key: 'address' },
    { label: 'Shipments', key: 'shipment' },
    { label: 'Email', key: 'email' },
    { label: 'City', key: 'city' },
    { label: 'State', key: 'state' },
  ],
  selectedFields: [
    'name',
    'mc_number',
    'dot_number',
    'manager',
    'address',
    'shipment',
    'email',
    'city',
    'state',
  ],
  carrierLookup: null,
}

const validateDocument = <T extends Document | LocalDocument>(document: T): T => ({
  ...document,
  isDocumentValid: !!(document.name.length && document?.file),
})

const getFilters = (filters: SearchFilters, returnString = true) =>
  cleanFilters({
    name__icontains: filters.carrierName,
    city: filters.city,
    state: filters.state,
    mc_number__iexact: filters.mcNumber,
    dot_number__iexact: filters.dotNumber,
    scac_number__iexact: filters.scacNumber,
    blacklist: filters.blacklist,
    approved_to_haul_status: filters.haulingStatus,
    company_equipment__equipment_type: filters.equipmentType,
    carrier_manager__in: returnString ? filters.csrList?.join(',') : filters.csrList,
  })

// Get list of carriers for carriers tab
export const getCarriers = createAsyncThunk('carriers/getCarriers', async (_, { getState }) => {
  const { filters, offset = 0, size = 50 } = (getState() as RootState).carriers

  const response = await api.get('/accounts/api/carrier/list-create/', {
    params: {
      limit: size,
      offset,
      ...getFilters(filters),
    },
  })

  return response.data
})

export const createCarrier = createAsyncThunk(
  'carriers/createCarrier',
  async (carrier: any, { dispatch, rejectWithValue }) => {
    try {
      const response = await api.post(
        '/accounts/api/carrier/list-create/',
        keysToSnakeCase(carrier),
      )
      dispatch(getCarriers())

      return response.data
    } catch (err: CatchError) {
      return rejectWithValue(formatAxiosErrorToPayload(err))
    }
  },
)

// Get details for a single carrier for the carrier details page
export const getCarrierDetails = createAsyncThunk(
  'carriers/getCarrierDetails',
  async ({ carrierId }: { carrierId: number | string }) => {
    const detailsRequest = api.get(`/accounts/api/carrier/detail/${carrierId}/`)
    const statsRequest = api.get(`/accounts/api/carrier/stats/${carrierId}/`)
    const shipmentsRequest = api.get(`/accounts/api/carrier/historical-loads/${carrierId}/`, {
      params: {
        limit: 5,
        offset: 0,
      },
    })

    const [carrierDetails, carrierStats, carrierShipments] = await Promise.all([
      detailsRequest,
      statsRequest,
      shipmentsRequest,
    ])
    return {
      currentCarrier: {
        addressDisplay: serializeAddress({
          street: carrierDetails.data.address,
          city: carrierDetails.data.city,
          stateCode: carrierDetails.data.state_province_region,
          postalCode: carrierDetails.data.postal_code,
          countryCode: carrierDetails.data.country,
        }),
        arAddressDisplay: serializeAddress({
          street: carrierDetails.data.ar_address,
          city: carrierDetails.data.ar_city,
          stateCode: carrierDetails.data.ar_state || carrierDetails.data.ar_ca_province,
          postalCode: carrierDetails.data.ar_zipcode || carrierDetails.data.ar_ca_postal_code,
          countryCode: carrierDetails.data.ar_country,
        }),
        ...keysToCamelCase(carrierDetails.data),
      },
      totalShipments: carrierStats.data.total_shipments,
      averageMargin: carrierStats.data.average_margin,
      shipments: keysToCamelCase(carrierShipments.data.results),
    }
  },
)

// Update details of a single customer
export const saveCarrierDetails = createAsyncThunk(
  'carriers/saveCarrierDetails',
  async (payload: CarrierDetails, { dispatch, rejectWithValue }) => {
    try {
      // Prepare customer object for backend
      const carrierManagerId = payload.carrierManager?.id
      const factoringSalesRepId = payload.factoringSalesRep?.id
      payload = mapKeys(payload, (_, key) => snakeCase(key)) as any
      payload = omit(payload, ['customer_companies']) as any

      const response = await api.put(`/accounts/api/carrier/detail/${payload.id}/`, {
        ...payload,
        carrier_manager: carrierManagerId,
        factoring_sales_rep: factoringSalesRepId,
      })

      response.data.addressDisplay = serializeAddress({
        street: response.data.address,
        city: response.data.city,
        stateCode: response.data.state_province_region,
        postalCode: response.data.postal_code,
        countryCode: response.data.country,
      })
      response.data.arAddressDisplay = serializeAddress({
        street: response.data.ar_address,
        city: response.data.ar_city,
        stateCode: response.data.ar_state || response.data.ar_ca_province,
        postalCode: response.data.ar_zipcode || response.data.ar_ca_postal_code,
        countryCode: response.data.ar_country,
      })
      dispatch(getCarrierDetails({ carrierId: payload.id }))
    } catch (err: CatchError) {
      return rejectWithValue(formatAxiosErrorToPayload(err))
    }
  },
)

export const updateCarrierCompany = createAsyncThunk(
  'carriers/updateCarrierCompany',
  async (payload: Partial<CarrierDetails>, { rejectWithValue }) => {
    try {
      const response = await api.put(
        `/accounts/api/carrier/detail/${payload.id}/`,
        keysToSnakeCase(payload),
      )
      return keysToCamelCase(response.data)
    } catch (err: CatchError) {
      return rejectWithValue(formatAxiosErrorToPayload(err))
    }
  },
)

// Add a new Carrier/Customer relationship
export const addCustomerCarrierRelationship = createAsyncThunk(
  'carriers/addCustomerCarrierRelationship',
  async (
    { customer, status }: { customer: { id: number; text: string }; status: string },
    { getState, rejectWithValue },
  ) => {
    const carrier = (getState() as RootState).carriers.currentCarrier?.id
    if (!carrier) return rejectWithValue('No carrier selected')
    await api.put('/accounts/api/carrier-customer-relationship-rud/', {
      carrier_company_id: carrier,
      customer_company_id: customer.id,
      status: status,
    })
    return {
      id: customer.id,
      status: status,
      name: customer.text,
    }
  },
)

// Delete a particular Customer/Carrier relationship
export const deleteCustomerCarrierRelationship = createAsyncThunk(
  'carriers/deleteCustomerCarrierRelationship',
  async ({
    customerCompanyId,
    carrierCompanyId,
  }: {
    customerCompanyId: string | number
    carrierCompanyId: string | number
  }) => {
    await api.delete('/accounts/api/carrier-customer-relationship-rud/', {
      data: mapKeys({ customerCompanyId, carrierCompanyId }, (_, key) => snakeCase(key)),
    })
    return customerCompanyId
  },
)

// Get list of carrier notes
export const getCarrierNotes = createAsyncThunk(
  'carriers/getCarrierNotes',
  async (payload: { carrierId?: string | number; limit?: number }, { getState }) => {
    const id = (getState() as RootState).carriers.currentCarrier?.id
    return api
      .get(`/accounts/api/carrier/note/${payload.carrierId || id}/`, {
        params: {
          limit: payload.limit || 50,
        },
      })
      .then(({ data }) => data)
  },
)

// Create a new carrier note
export const addCarrierNote = createAsyncThunk(
  'carriers/addCarrierNote',
  async (payload: { note: string }, { getState, rejectWithValue }) => {
    try {
      const carrier = (getState() as RootState).carriers.currentCarrier?.id
      if (!carrier) return rejectWithValue('No carrier selected')
      const response = await api.post(`/accounts/api/carrier/note/${carrier}/`, {
        note: payload.note,
      })

      return response.data
    } catch (err: CatchError) {
      return rejectWithValue(formatAxiosErrorToPayload(err))
    }
  },
)

// Update an existing carrier note
export const updateCarrierNote = createAsyncThunk(
  'carriers/updateCarrierNote',
  async (payload: { note: string; noteId: string | number }) =>
    api
      .put(`/accounts/api/carrier/note-rud/${payload.noteId}/`, {
        note: payload.note,
      })
      .then(({ data }) => data),
)

// Delete an existing carrier note
export const deleteCarrierNote = createAsyncThunk(
  'carriers/deleteCarrierNote',
  async (payload: { noteId: string | number }) => {
    await api.delete(`/accounts/api/carrier/note-rud/${payload.noteId}/`)
    return payload.noteId
  },
)

// Get payments for a single carrier for the carrier details page
export const getCarrierPayments = createAsyncThunk(
  'carriers/getCarrierPayments',
  async (payload: { offset: number; limit: number }, { getState, rejectWithValue }) => {
    const carrier = (getState() as RootState).carriers.currentCarrier?.id
    if (!carrier) return rejectWithValue('No carrier selected')

    const response = await api.get(`/billing/api/carrier-payment-list/${carrier}/`, {
      params: {
        offset: payload.offset,
        limit: payload.limit,
      },
    })
    return keysToCamelCase(response.data)
  },
)

// Get matches for a single carrier for the carrier details page
export const getCarrierMatches = createAsyncThunk(
  'carriers/getCarrierMatches',
  async (payload: { offset: number; limit: number }, { getState, rejectWithValue }) => {
    const carrier = (getState() as RootState).carriers.currentCarrier?.id
    if (!carrier) return rejectWithValue('No carrier selected')
    const response = await api.get(`/carrier-matching/internal/outbound/${carrier}/`, {
      params: {
        offset: payload.offset,
        limit: payload.limit,
      },
    })
    return keysToCamelCase(response.data)
  },
)

// Get list of contacts for a particular carrier
export const getCarrierContacts = createAsyncThunk(
  'carriers/getCarrierContacts',
  async (payload: { offset: number }, { getState, rejectWithValue }) => {
    const carriers = (getState() as RootState).carriers
    const carrier = carriers.currentCarrier?.id
    if (!carrier) return rejectWithValue('No carrier selected')
    const { contacts: currentContacts } = carriers
    const response = await api.get(`/accounts/api/carrier/contact/${carrier}/`, {
      params: {
        offset: payload.offset,
        limit: 100,
      },
    })
    const nextContacts = keysToCamelCase(response.data.results).map(validateContact)
    const newContacts = cloneDeep(currentContacts)

    newContacts.splice(
      payload.offset,
      nextContacts.length,
      ...nextContacts.map((contact: any) => ({
        ...contact,
        phone: contact.phone?.replaceAll('-', ''),
      })),
    )
    return {
      contacts: newContacts,
      count: response.data.count,
    }
  },
)

// Update details of a single carrier contact
export const saveCarrierContact = createAsyncThunk(
  'carriers/saveCarrierContact',
  async (payload: CarrierContact, { rejectWithValue }) => {
    try {
      const response = await api.put(
        `/accounts/api/carrier/contact-rud/${payload.id}/`,
        mapKeys(omit(payload, ['id']), (_, key) => snakeCase(key)),
      )

      return validateContact(keysToCamelCase(response.data))
    } catch (err: CatchError) {
      return rejectWithValue(formatAxiosErrorToPayload(err))
    }
  },
)

// Create a new contact for a carrier
export const addCarrierContact = createAsyncThunk(
  'carriers/addCarrierContact',
  async (_, { getState, rejectWithValue }) => {
    try {
      const carriers = (getState() as RootState).carriers
      const carrier = carriers.currentCarrier?.id
      if (!carrier) return rejectWithValue('No carrier selected')
      const response = await api.post(
        `/accounts/api/carrier/contact/${carrier}/`,
        mapKeys(omit(carriers.newContact, 'id'), (_, key) => snakeCase(key)),
      )

      return validateContact(keysToCamelCase(response.data))
    } catch (err: CatchError) {
      return rejectWithValue(formatAxiosErrorToPayload(err))
    }
  },
)

// Delete a particular carrier contact
export const deleteCarrierContact = createAsyncThunk(
  'carriers/deleteCarrierContact',
  async (payload: { contactId: number | string }) => {
    await api.delete(`/accounts/api/carrier/contact-rud/${payload.contactId}/`)
    return payload.contactId
  },
)

// Get list of insurances for a particular carrier
export const getCarrierInsurances = createAsyncThunk(
  'carriers/getCarrierInsurances',
  async (_, { getState, rejectWithValue }) => {
    const carrier = (getState() as RootState).carriers.currentCarrier?.id
    if (!carrier) return rejectWithValue('No carrier selected')
    const response = await api.get(`/accounts/api/carrier/insurance/${carrier}/`)
    return keysToCamelCase(response.data.results)
  },
)

// Update details of a single carrier insurance
export const saveCarrierInsurance = createAsyncThunk(
  'carriers/saveCarrierInsurance',
  async (payload: CarrierInsurance, { rejectWithValue }) => {
    try {
      const response = await api.put(
        `/accounts/api/carrier/insurance-rud/${payload.id}/`,
        mapKeys(payload, (_, key) => snakeCase(key)),
      )
      return keysToCamelCase(response.data)
    } catch (err: CatchError) {
      return rejectWithValue(formatAxiosErrorToPayload(err))
    }
  },
)

// Delete a particular carrier insurance
export const deleteCarrierInsurance = createAsyncThunk(
  'carriers/deleteCarrierInsurance',
  async (payload: { insuranceId: number | string }) => {
    await api.delete(`/accounts/api/carrier/insurance-rud/${payload.insuranceId}/`)
    return payload.insuranceId
  },
)

// Create a new insurance for a carrier
export const addCarrierInsurance = createAsyncThunk(
  'carriers/addCarrierInsurance',
  async (_, { getState, rejectWithValue }) => {
    try {
      const carriers = (getState() as RootState).carriers
      const carrier = carriers.currentCarrier?.id
      if (!carrier) return rejectWithValue('No carrier selected')
      const response = await api.post(
        `/accounts/api/carrier/insurance/${carrier}/`,
        mapKeys(omit(carriers.newInsurance, 'id'), (_, key) => snakeCase(key)),
      )
      return keysToCamelCase(response.data)
    } catch (err: CatchError) {
      return rejectWithValue(formatAxiosErrorToPayload(err))
    }
  },
)

// Get list of carrier freight and finance documents for documents tab
export const getCarrierDocuments = createAsyncThunk(
  'carriers/getCarrierDocuments',
  async (payload: { offset: number; limit: number }, { getState, rejectWithValue }) => {
    const carrier = (getState() as RootState).carriers.currentCarrier?.id
    if (!carrier) return rejectWithValue('No carrier selected')
    const response = await api.get(`/accounts/api/carrier/document/${carrier}/`, {
      params: {
        offset: payload.offset,
        limit: payload.limit,
      },
    })
    const data = keysToCamelCase(response.data)
    data.results = data.results.filter((document: Document) => document.file)
    data.results.map((document: Document) =>
      validateDocument({
        ...document,
      }),
    )

    return data
  },
)

// Add a new document for a particular carrier
export const addCarrierDocument = createAsyncThunk(
  'carriers/addCarrierDocument',
  async (
    payload: { file: File; name: string; documentType: string; newFreightOrFinance: string },
    { getState, rejectWithValue },
  ) => {
    const carrier = (getState() as RootState).carriers.currentCarrier?.id
    if (!carrier) return rejectWithValue('No carrier selected')
    const formData = new FormData()

    formData.append('file', payload.file)
    formData.append('name', payload.name)
    formData.append('document_type', payload.documentType)
    formData.append('freight_or_finance', payload.newFreightOrFinance)

    const response = await api.post(`/accounts/api/carrier/document/${carrier}/`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    })
    const data = validateDocument(keysToCamelCase(response.data))
    data.fileName = data.fileName.split('/').pop() || data.fileName
    return data
  },
)

// Update a particular carrier document
export const updateCarrierDocument = createAsyncThunk(
  'carriers/updateCarrierDocument',
  async (
    payload: {
      documentId: number
      file: File | string
      name: string
      documentType: string
      initialFreightOrFinance: string
      newFreightOrFinance: string
    },
    { dispatch },
  ) => {
    const initialFreightOrFinance = payload.initialFreightOrFinance
    const newFreightOrFinance = payload.newFreightOrFinance
    const formData = new FormData()

    if (typeof payload.file != 'string') {
      formData.append('file', payload.file)
    }

    formData.append('name', payload.name)
    formData.append('document_type', payload.documentType)
    formData.append('initial_freight_or_finance', initialFreightOrFinance)
    formData.append('new_freight_or_finance', newFreightOrFinance)
    const response =
      initialFreightOrFinance != newFreightOrFinance
        ? await api.put(
            `/accounts/api/carrier/document-convert-to-finance/${payload.documentId}/`,
            formData,
            {
              headers: {
                'Content-Type': 'multipart/form-data',
              },
            },
          )
        : await api.put(`/accounts/api/carrier/document-rud/${payload.documentId}/`, formData, {
            headers: {
              'Content-Type': 'multipart/form-data',
            },
          })

    const data = validateDocument(keysToCamelCase(response?.data))
    data.fileName = data.fileName.split('/').pop() || data.fileName
    dispatch(getCarrierDocuments({ offset: 0, limit: 50 }))
    return data
  },
)

// Delete a particular carrier document
export const deleteCarrierDocument = createAsyncThunk(
  'carriers/deleteCarrierDocument',
  async (payload: { documentId: number; freightOrFinance: 'freight' | 'finance' }) => {
    const documentId = payload.documentId
    payload.freightOrFinance === 'finance'
      ? await api.delete(`/accounts/api/carrier/finance-document-ud/${documentId}/`)
      : await api.delete(`/accounts/api/carrier/document-rud/${documentId}/`)
    return documentId
  },
)

export const getCarrierLanes = createAsyncThunk(
  'carriers/getCarrierLanes',
  async (_, { getState, rejectWithValue }) => {
    const carrier = (getState() as RootState).carriers.currentCarrier?.id
    if (!carrier) return rejectWithValue('No carrier selected')
    const response = await api.get(`/carrier-matching/api/carrier/lane/${carrier}/`)
    return keysToCamelCase(response.data)
  },
)

export const addNewCarrierLane = createAsyncThunk(
  'carriers/addNewCarrierLane',
  async (
    { newLane, carrierId }: { newLane: any; carrierId?: number },
    { getState, rejectWithValue },
  ) => {
    if (!carrierId) carrierId = (getState() as RootState).carriers.currentCarrier?.id
    if (!carrierId) return rejectWithValue('No carrier selected')

    const response = await api.post(
      `/carrier-matching/api/carrier/lane/${carrierId}/`,
      keysToSnakeCase(newLane),
    )

    return response.data
  },
)

export const updateCarrierLane = createAsyncThunk(
  'carriers/updateCarrierLane',
  async ({ lane }: { lane: any }, { dispatch }) => {
    const response = await api.put(
      `/carrier-matching/api/carrier/lane-rud/${lane.id}/`,
      keysToSnakeCase(lane),
    )

    dispatch(getCarrierLanes())

    return response.data
  },
)

// Delete a particular carrier document
export const deleteCarrierLane = createAsyncThunk(
  'carriers/deleteCarrierLane',
  async ({ laneId }: { laneId: number }, { dispatch }) => {
    await api.delete(`/carrier-matching/api/carrier/lane-rud/${laneId}/`)

    dispatch(getCarrierLanes())

    return laneId
  },
)

// Get payment methods for a carrier
export const getPaymentMethods = createAsyncThunk(
  'carriers/getPaymentMethods',
  async (
    {
      businessBranch,
      carrierId,
    }: { businessBranch?: 'FREIGHT' | 'FINANCE'; carrierId?: number | string },
    { getState },
  ) => {
    const requestCarrierId = carrierId || (getState() as RootState).carriers.currentCarrier?.id
    if (!requestCarrierId) return []

    const params: { carrier_id: string; business_branch?: string } = {
      carrier_id: requestCarrierId.toString(),
    }
    if (businessBranch) {
      params.business_branch = businessBranch
    }
    const response = await api.get('/billing/api/carrier-company-payment-methods/', { params })
    return keysToCamelCase(response.data)
  },
)

// Update details of a single carrier payment
export const saveCarrierPaymentMethod = createAsyncThunk(
  'carriers/saveCarrierPaymentMethod',
  async (
    { paymentMethod }: { paymentMethod: CarrierPaymentMethod },
    { getState, rejectWithValue, dispatch },
  ) => {
    try {
      const carrier = (getState() as RootState).carriers.currentCarrier?.id
      if (!carrier) return rejectWithValue('No carrier selected')
      await api.patch(`/billing/api/carrier-company-payment-method-ud/${paymentMethod.id}/`, {
        is_primary: paymentMethod.isPrimary,
        carrier_id: carrier,
      })
      dispatch(getCarrierDetails({ carrierId: carrier }))
      return paymentMethod
    } catch (err: CatchError) {
      return rejectWithValue(formatAxiosErrorToPayload(err))
    }
  },
)

// Delete a particular carrier insurance
export const deleteCarrierPaymentMethod = createAsyncThunk(
  'carriers/deleteCarrierPaymentMethod',
  async (
    { paymentMethodId }: { paymentMethodId: number | string },
    { getState, dispatch, rejectWithValue },
  ) => {
    const carrier = (getState() as RootState).carriers.currentCarrier?.id
    if (!carrier) return rejectWithValue('No carrier selected')
    try {
      await api.delete(`/billing/api/carrier-company-payment-method-ud/${paymentMethodId}/`, {
        data: mapKeys({ carrier_id: carrier }, (_, key) => snakeCase(key)),
      })
      dispatch(getPaymentMethods({}))
      return paymentMethodId
    } catch (err: CatchError) {
      return rejectWithValue(formatAxiosErrorToPayload(err))
    }
  },
)

// Create a new payment method for a carrier
export const addCarrierPaymentMethod = createAsyncThunk(
  'carriers/addCarrierPaymentMethod',
  async (_, { getState, dispatch, rejectWithValue }) => {
    try {
      const carriers = (getState() as RootState).carriers
      const carrierId = carriers.currentCarrier?.id
      if (!carrierId) return rejectWithValue('No carrier selected')

      const accountNumber = addPrecedingZeros(carriers.newPaymentMethod.accountNumber, 7)
      const response = await api.post(
        '/billing/api/carrier-company-payment-methods/',
        mapKeys(
          {
            ...omit(carriers.newPaymentMethod, 'id'),
            carrierId,
            accountNumber,
            accountNumberConfirm: accountNumber,
          },
          (_, key) => snakeCase(key),
        ),
      )

      dispatch(getPaymentMethods({}))
      return keysToCamelCase(response.data)
    } catch (err: CatchError) {
      return rejectWithValue(formatAxiosErrorToPayload(err))
    }
  },
)

// Get equipments for a carrier
export const getCarrierEquipments = createAsyncThunk(
  'carriers/getCarrierEquipments',
  async (_, { getState }) => {
    const carrier = (getState() as RootState).carriers.currentCarrier?.id
    if (!carrier) return []

    const response = await api.get('/accounts/api/carrier/carrier-company-equipment/', {
      params: { carrier_id: carrier },
    })
    return keysToCamelCase(response.data)
  },
)

// Get accessories for a carrier
export const getCarrierAccessories = createAsyncThunk(
  'carriers/getCarrierAccessories',
  async (_, { getState }) => {
    const carrier = (getState() as RootState).carriers.currentCarrier?.id
    if (!carrier) return []
    const response = await api.get('/accounts/api/carrier/carrier-equipment-accessories-list/', {
      params: { carrier_id: carrier },
    })
    return keysToCamelCase(response.data)
  },
)

// Get available accessory options
export const getAccessoryOptions = createAsyncThunk('carriers/getAccessoryOptions', async () => {
  const response = await api.get('/accounts/api/carrier/get-equipment-accessories-list/')
  return keysToCamelCase(response.data)
})

export const setCarrierAccessories = createAsyncThunk(
  'carriers/setCarrierAccessories',
  async ({ accessories }: { accessories: EquipmentAccessory[] }, { getState, rejectWithValue }) => {
    const body = {
      equipment_accessories: accessories.map((accessory: EquipmentAccessory) => accessory.id),
    }
    try {
      const carrier = (getState() as RootState).carriers.currentCarrier?.id
      if (!carrier) return rejectWithValue('No carrier selected')
      await api.put('/accounts/api/manage-carrier-company-equipment-accessories/', body, {
        params: { carrier_id: carrier },
      })

      return accessories
    } catch (err: CatchError) {
      return rejectWithValue(formatAxiosErrorToPayload(err))
    }
  },
)

export const setCarrierEquipments = createAsyncThunk(
  'carriers/setCarrierEquipments',
  async (
    { equipments }: { equipments: CarrierEquipment[] },
    { dispatch, getState, rejectWithValue },
  ) => {
    try {
      const carrier = (getState() as RootState).carriers.currentCarrier?.id
      if (!carrier) return rejectWithValue('No carrier selected')
      await api.post(
        '/accounts/api/carrier/modify-carrier-company-equipment/',
        {
          equipments: keysToSnakeCase(equipments),
        },
        {
          params: { carrier_id: carrier },
        },
      )

      dispatch(getCarrierEquipments())
      return equipments
    } catch (err: CatchError) {
      return rejectWithValue(formatAxiosErrorToPayload(err))
    }
  },
)

export const addNewCarrierEquipments = createAsyncThunk(
  'carriers/addNewCarrierEquipments',
  async (
    {
      carrierCompanyId,
      equipments,
    }: { carrierCompanyId: number; equipments: Array<CarrierEquipment> },
    { rejectWithValue },
  ) => {
    try {
      await api.post(
        `/accounts/api/carrier/add-carrier-company-equipments/${carrierCompanyId}/`,
        keysToSnakeCase(equipments),
      )
    } catch (err: CatchError) {
      return rejectWithValue(formatAxiosErrorToPayload(err))
    }
  },
)

export const exportCarriersCSV = createAsyncThunk(
  'carriers/exportCarriersCSV',
  async (_, { getState, rejectWithValue }) => {
    const { filters, selectedFields } = (getState() as RootState).carriers

    try {
      const response = await api.post('/accounts/api/export-account-data/', {
        type: 'carrier',
        filters: getFilters(filters, false),
        fields: selectedFields.join(','),
      })

      downloadCSV(response.data, 'carriers')
    } catch (err: CatchError) {
      return rejectWithValue(formatAxiosErrorToPayload(err))
    }
  },
)

export const checkCarrierMcOrDot = createAsyncThunk(
  'carriers/checkCarrierMcOrDot',
  async ({ query, searchType }: { query: string; searchType: string }) => {
    /*
          Looks up a carrier via MC/DOT number on Highway and returns relevant info
        */
    const response = await api.post('/integrations/carrier-check-highway/', {
      search_str: query,
      search_by: searchType,
    })
    const data = keysToCamelCase(response.data)
    // The contactInformation keys are meant to be displayed to the end user
    // so we want to preserve the original values
    data.attributes.contactInformation.names = response.data.attributes.contact_information?.names
    data.attributes.contactInformation.phoneNumbers =
      response.data.attributes.contact_information?.phone_numbers
    data.attributes.contactInformation.emails = response.data.attributes.contact_information?.emails
    data.attributes.contactInformation.addresses =
      response.data.attributes.contact_information?.addresses
    return data
  },
)

export const carriersSlice = createSlice({
  name: 'carriers',
  initialState,
  reducers: {
    setSize(state, { payload }) {
      state.size = payload
    },
    setOffset(state, { payload }) {
      state.offset = payload
    },
    setCurrentCarrier(state, { payload }) {
      state.currentCarrier = payload
    },
    setFilters(state, { payload }) {
      state.filters = payload
    },
    setCarrierNote(state, { payload }) {
      state.notes[payload.index].note = payload.value
    },
    resetCarrierNote(state, { payload }) {
      state.notes[payload.index].note = state.notesBackup[payload.index].note
    },
    setNewNote(state, { payload }) {
      state.newNote = payload
    },
    updateCurrentCarrier(state, { payload }) {
      // Update specific fields for the currentCarrier
      const currentCarrierCopy = { ...state.currentCarrier }
      payload.fields.map((field: string, i: number) => {
        // @ts-ignore - this is a dynamic key
        currentCarrierCopy[field] = payload.values[i]
      })
      state.currentCarrier = currentCarrierCopy as CarrierDetails
    },
    setNewContact(state, { payload }) {
      state.newContact = validateContact(payload)
    },
    updateContactField(state, { payload }) {
      /* Update a single field for a particular contact */
      if (payload.index === -1) {
        // if index is -1, this is the new contact being updated
        state.newContact = validateContact({
          ...state.newContact,
          [payload.field]: payload.value,
        })
      } else {
        // Otherwise, update existing contact
        state.contacts = state.contacts.map((contact, i) => {
          if (i == payload.index) {
            const updatedContact = validateContact({
              ...contact,
              [payload.field]: payload.value,
            })
            return updatedContact
          }
          return contact
        })
      }
    },
    resetCarrierContact(state, { payload }) {
      state.contacts[payload.index] = cloneDeep(state.contactsBackup[payload.index])
    },
    updateInsuranceField(state, { payload }) {
      /* Update a single field for a particular insurance */
      if (payload.index === -1) {
        // if index is -1, this is the new insurance being updated
        state.newInsurance = {
          ...state.newInsurance,
          [payload.field]: payload.value,
        }
      } else {
        // Otherwise, update existing insurance
        state.insurances = state.insurances.map((insurance: CarrierInsurance, i: number) => {
          if (i == payload.index) {
            const updatedInsurance = {
              ...insurance,
              [payload.field]: payload.value,
            }
            return updatedInsurance
          }
          return insurance
        })
      }
    },
    setNewInsurance(state, { payload }) {
      state.newInsurance = payload
    },
    resetCarrierInsurance(state, { payload }) {
      state.insurances[payload.index] = cloneDeep(state.insurancesBackup[payload.index])
    },
    updatePaymentMethodField(state, { payload }) {
      /* Update a single field for a particular payment method */
      if (payload.index === -1) {
        // if index is -1, this is the new payment method being updated
        state.newPaymentMethod = {
          ...state.newPaymentMethod,
          [payload.field]: payload.value,
        }
      } else {
        // Otherwise, update existing payment method
        state.paymentMethods = state.paymentMethods.map(
          (method: CarrierPaymentMethod, i: number) => {
            if (i == payload.index) {
              const updatedInsurance = {
                ...method,
                [payload.field]: payload.value,
              }
              return updatedInsurance
            }
            return method
          },
        )
      }
    },
    resetCarrierPaymentMethod(state, { payload }) {
      state.paymentMethods[payload.index] = cloneDeep(state.paymentMethodsBackup[payload.index])
    },
    setNewPaymentMethod(state, { payload }) {
      state.newPaymentMethod = payload
    },
    setCarrierDocument(state, { payload }) {
      if (payload.index === -1) {
        state.newDocument = validateDocument({
          ...state.newDocument,
          [payload.field]: payload.value,
        })
      } else {
        state.documents[payload.index] = validateDocument({
          ...state.documents[payload.index],
          [payload.field]: payload.value,
        })
      }
    },
    resetCarrierDocument(state, { payload }) {
      if (payload.index === -1) {
        state.newDocument = buildNewDocument()
      } else {
        state.documents = state.documents.map((document, i: number) => {
          if (i == payload.index) return cloneDeep(state.documentsBackup[payload.index])
          return document
        })
      }
    },
    setSelectedAccessories(state, { payload }: { payload: EquipmentAccessory[] }) {
      state.selectedAccessories = payload
    },
    setSelectedFields(state, { payload }) {
      state.selectedFields =
        typeof payload === 'string'
          ? state.selectedFields.includes(payload)
            ? state.selectedFields.filter(field => field !== payload)
            : [...state.selectedFields, payload]
          : payload
    },
    resetCarrierLookup(state) {
      state.carrierLookup = null
    },
  },
  extraReducers(builder) {
    builder
      .addCase(getCarriers.pending, state => {
        state.loading.list = true
      })
      .addCase(getCarriers.fulfilled, (state, action) => {
        const data = action.payload
        state.carriers = keysToCamelCase(data.results)
        state.count.carriers = data.count
        state.loading.list = false
      })
      .addCase(getCarriers.rejected, state => {
        state.loading.list = false
      })
      .addCase(createCarrier.pending, state => {
        state.loading.createCarrier = true
      })
      .addCase(createCarrier.fulfilled, state => {
        state.loading.createCarrier = false
        toast.success('Carrier Created')
      })
      .addCase(createCarrier.rejected, (state, action) => {
        state.loading.createCarrier = false
        toast.error(getErrorString(action.payload, 'Error creating carrier'))
      })
      .addCase(getCarrierDetails.pending, state => {
        state.loading.details = true
      })
      .addCase(getCarrierDetails.fulfilled, (state, action) => {
        const data = action.payload
        state.currentCarrier = {
          ...state.currentCarrier,
          ...data.currentCarrier,
          phone: data.currentCarrier.phone?.replaceAll('-', ''),
          phoneTwo: data.currentCarrier.phoneTwo?.replaceAll('-', ''),
          phoneThree: data.currentCarrier.phoneThree?.replaceAll('-', ''),
        }
        state.currentCarrierBackup = cloneDeep(state.currentCarrier)
        state.shipments = data.shipments
        state.totalShipments = data.totalShipments
        state.averageMargin = data.averageMargin
        state.loading.details = false
      })
      .addCase(getCarrierDetails.rejected, (state, action) => {
        state.loading.details = false
        toast.error(getErrorString(action.payload, 'Failed to load carrier details'))
      })
      .addCase(saveCarrierDetails.pending, state => {
        state.loading.saveCarrierDetails = true
      })
      .addCase(saveCarrierDetails.fulfilled, state => {
        state.loading.saveCarrierDetails = false
        state.currentCarrierBackup = cloneDeep(state.currentCarrier)
        toast.success('Successfully updated carrier info')
      })
      .addCase(saveCarrierDetails.rejected, (state, action) => {
        state.loading.saveCarrierDetails = false
        toast.error(getErrorString(action.payload, 'Error creating new customer'))
      })
      .addCase(updateCarrierCompany.fulfilled, (state, action) => {
        state.currentCarrier = { ...state.currentCarrier, ...action.payload }
        state.currentCarrierBackup = { ...state.currentCarrierBackup, ...action.payload }
        toast.success('Successfully updated carrier company')
      })
      .addCase(updateCarrierCompany.rejected, (_, action) => {
        toast.error(getErrorString(action.payload, 'Failed to update carrier company'))
      })
      .addCase(deleteCustomerCarrierRelationship.pending, state => {
        state.loading.deleteCustomerCarrierRelationship = true
      })
      .addCase(deleteCustomerCarrierRelationship.fulfilled, (state, action) => {
        const customerId = action.payload
        state.loading.deleteCustomerCarrierRelationship = false
        state.currentCarrier = {
          ...state.currentCarrier,
          customerCompanies:
            state.currentCarrier?.customerCompanies?.filter(
              (customer: any) => customer.id != customerId,
            ) || [],
        } as CarrierDetails
        toast.success('Successfully deleted customer-carrier relationship')
      })
      .addCase(deleteCustomerCarrierRelationship.rejected, state => {
        state.loading.deleteCustomerCarrierRelationship = false
        toast.error('Failed to delete customer-carrier relationship')
      })
      .addCase(addCustomerCarrierRelationship.pending, state => {
        state.loading.addCustomer = true
      })
      .addCase(addCustomerCarrierRelationship.fulfilled, (state, action) => {
        state.loading.addCustomer = false
        state.currentCarrier = {
          ...(state.currentCarrier || {}),
          customerCompanies: [...(state.currentCarrier?.customerCompanies || []), action.payload],
        } as CarrierDetails
        toast.success('Successfully added customer-carrier relationship')
      })
      .addCase(addCustomerCarrierRelationship.rejected, state => {
        state.loading.addCustomer = false
        toast.error('Failed to add customer-carrier relationship')
      })
      .addCase(getCarrierNotes.pending, state => {
        state.loading.getNotes = true
      })
      .addCase(getCarrierNotes.fulfilled, (state, action) => {
        const { results, count } = action.payload
        state.notes = keysToCamelCase(results)
        state.notesBackup = cloneDeep(state.notes)
        state.loading.getNotes = false
        state.count.notes = count
      })
      .addCase(getCarrierNotes.rejected, state => {
        state.loading.getNotes = false
        toast.error('Failed to load carrier notes')
      })
      .addCase(addCarrierNote.pending, state => {
        state.loading.saveNote.push(-1)
      })
      .addCase(addCarrierNote.fulfilled, (state, action) => {
        state.notes.unshift(keysToCamelCase(action.payload))
        state.notesBackup = cloneDeep(state.notes)
        state.newNote = ''
        state.loading.saveNote = state.loading.saveNote.filter(id => id !== -1)
        toast.success('Successfully added new note')
      })
      .addCase(addCarrierNote.rejected, (state, action) => {
        state.loading.saveNote = state.loading.saveNote.filter(id => id !== -1)
        toast.error(getErrorString(action.payload, 'Failed to save new note'))
      })
      .addCase(updateCarrierNote.pending, (state, action) => {
        state.loading.saveNote.push(action.meta.arg.noteId)
      })
      .addCase(updateCarrierNote.fulfilled, (state, action) => {
        const updatedNote = keysToCamelCase(action.payload)
        state.notes = state.notes.map((note: Note) =>
          note.id === updatedNote.id ? updatedNote : note,
        )
        state.notesBackup = cloneDeep(state.notes)
        state.loading.saveNote = state.loading.saveNote.filter(id => id !== action.meta.arg.noteId)
        toast.success('Successfully updated note')
      })
      .addCase(updateCarrierNote.rejected, (state, action) => {
        state.loading.saveNote = state.loading.saveNote.filter(id => id !== action.meta.arg.noteId)
        toast.error('Failed to update note')
      })
      .addCase(deleteCarrierNote.pending, state => {
        state.loading.deleteNote = true
      })
      .addCase(deleteCarrierNote.fulfilled, (state, action) => {
        const noteId = action.payload
        state.loading.deleteNote = false
        state.notes = state.notes.filter((note: { id: number | string }) => note.id != noteId)
        state.notesBackup = cloneDeep(state.notes)
        toast.success('Successfully deleted note')
      })
      .addCase(deleteCarrierNote.rejected, state => {
        state.loading.deleteNote = false
        toast.error('Failed to delete note')
      })
      .addCase(getCarrierPayments.pending, state => {
        state.loading.getPayments = true
      })
      .addCase(getCarrierPayments.fulfilled, (state, action) => {
        state.payments = action.payload.results
        state.count.payments = action.payload.count
        state.loading.getPayments = false
      })
      .addCase(getCarrierPayments.rejected, state => {
        state.loading.getPayments = false
      })
      .addCase(getCarrierMatches.pending, state => {
        state.loading.getMatches = true
      })
      .addCase(getCarrierMatches.fulfilled, (state, action) => {
        state.matches = action.payload.results
        state.count.matches = action.payload.count
        state.loading.getMatches = false
      })
      .addCase(getCarrierMatches.rejected, state => {
        state.loading.getMatches = false
      })
      .addCase(getCarrierContacts.pending, state => {
        state.loading.getCarrierContacts = true
      })
      .addCase(getCarrierContacts.fulfilled, (state, action) => {
        state.contacts = action.payload.contacts
        state.loading.getCarrierContacts = false
        state.count.contacts = action.payload.count
        state.contactsBackup = cloneDeep(state.contacts)
      })
      .addCase(getCarrierContacts.rejected, state => {
        state.loading.getCarrierContacts = false
        toast.error('Error loading carrier contacts')
      })
      .addCase(saveCarrierContact.pending, (state, action) => {
        state.loading.saveCarrierContact.push(action.meta.arg.id)
      })
      .addCase(saveCarrierContact.fulfilled, (state, action) => {
        const updatedContact = action.payload
        state.loading.saveCarrierContact = state.loading.saveCarrierContact.filter(
          (id: number) => id !== updatedContact.id,
        )
        state.contacts = state.contacts.map(contact =>
          contact.id === updatedContact.id ? updatedContact : contact,
        )
        state.contactsBackup = cloneDeep(state.contacts)
        toast.success('Successfully updated contact info')
      })
      .addCase(saveCarrierContact.rejected, (state, action) => {
        state.loading.saveCarrierContact = state.loading.saveCarrierContact.filter(
          (id: number) => id !== action.meta.arg.id,
        )
        toast.error(getErrorString(action.error.message, 'Failed to save Carrier Contact'))
      })
      .addCase(addCarrierContact.pending, state => {
        state.loading.addCarrierContact = true
      })
      .addCase(addCarrierContact.fulfilled, (state, action) => {
        state.loading.addCarrierContact = false
        state.contacts.unshift(action.payload)
        state.contactsBackup = cloneDeep(state.contacts)
        state.count.contacts += 1
        toast.success('Successfully added contact')
      })
      .addCase(addCarrierContact.rejected, (state, action) => {
        state.loading.addCarrierContact = false
        toast.error(getErrorString(action.payload, 'Failed to add Carrier Contact'))
      })
      .addCase(deleteCarrierContact.pending, state => {
        state.loading.deleteCarrierContact = true
      })
      .addCase(deleteCarrierContact.fulfilled, (state, action) => {
        state.loading.deleteCarrierContact = false
        const contactId = action.payload
        state.contacts = state.contacts.filter((contact: CarrierContact) => contact.id != contactId)
        state.contactsBackup = cloneDeep(state.contacts)
        state.count.contacts -= 1
        toast.success('Successfully deleted contact')
      })
      .addCase(deleteCarrierContact.rejected, state => {
        state.loading.deleteCarrierContact = false
        toast.error('Failed to delete contact')
      })
      .addCase(getCarrierInsurances.fulfilled, (state, action) => {
        state.insurances = action.payload
        state.insurancesBackup = cloneDeep(state.insurances)
      })
      .addCase(getCarrierInsurances.rejected, () => {
        toast.error('Error loading carrier insurances')
      })
      .addCase(saveCarrierInsurance.pending, (state, action) => {
        state.loading.saveCarrierInsurance.push(action.meta.arg.id)
      })
      .addCase(saveCarrierInsurance.fulfilled, (state, action) => {
        const updatedInsurance = action.payload
        state.loading.saveCarrierInsurance = state.loading.saveCarrierInsurance.filter(
          (id: number) => id !== updatedInsurance.id,
        )
        state.insurances = state.insurances.map((insurance: CarrierInsurance) =>
          insurance.id === updatedInsurance.id ? updatedInsurance : insurance,
        )
        state.insurancesBackup = cloneDeep(state.insurances)
        toast.success('Successfully updated insurance info')
      })
      .addCase(saveCarrierInsurance.rejected, (state, action) => {
        state.loading.saveCarrierInsurance = state.loading.saveCarrierInsurance.filter(
          (id: number) => id !== action.meta.arg.id,
        )
        toast.error(getErrorString(action.error.message, 'Failed to save Carrier Insurance'))
      })
      .addCase(deleteCarrierInsurance.pending, state => {
        state.loading.deleteCarrierInsurance = true
      })
      .addCase(deleteCarrierInsurance.fulfilled, (state, action) => {
        state.loading.deleteCarrierInsurance = false
        const insuranceId = action.payload
        state.insurances = state.insurances.filter(
          (insurance: CarrierInsurance) => insurance.id != insuranceId,
        )
        state.insurancesBackup = cloneDeep(state.insurances)
        toast.success('Successfully deleted insurance')
      })
      .addCase(deleteCarrierInsurance.rejected, state => {
        state.loading.deleteCarrierInsurance = false
        toast.error('Failed to delete insrance')
      })
      .addCase(addCarrierInsurance.pending, state => {
        state.loading.addCarrierInsurnace = true
      })
      .addCase(addCarrierInsurance.fulfilled, (state, action) => {
        state.loading.addCarrierInsurnace = false
        state.insurances.unshift(action.payload)
        state.insurancesBackup = cloneDeep(state.insurances)
        toast.success('Successfully added insurance')
      })
      .addCase(addCarrierInsurance.rejected, (state, action) => {
        state.loading.addCarrierInsurnace = false
        toast.error(getErrorString(action.payload, 'Failed to add Carrier Insurance'))
      })
      .addCase(getPaymentMethods.pending, state => {
        state.loading.getPaymentMethods = true
      })
      .addCase(getPaymentMethods.fulfilled, (state, action) => {
        state.loading.getPaymentMethods = false
        const paymentMethods = action.payload.map((method: any) => ({
          ...method,
          accountNumberConfirm: method.accountNumber,
          phone: method.phone?.replaceAll('-', ''),
        }))
        state.paymentMethods = orderBy(paymentMethods, ['isDeleted'], ['asc'])
        state.paymentMethodsBackup = cloneDeep(state.paymentMethods)
      })
      .addCase(getPaymentMethods.rejected, state => {
        state.loading.getPaymentMethods = false
        toast.error('Error getting payment methods')
      })
      .addCase(saveCarrierPaymentMethod.pending, (state, action) => {
        state.loading.saveCarrierPaymentMethod.push(action.meta.arg.paymentMethod.id)
      })
      .addCase(saveCarrierPaymentMethod.fulfilled, (state, action) => {
        const updatedPaymentMethod = action.payload
        state.loading.saveCarrierPaymentMethod = state.loading.saveCarrierPaymentMethod.filter(
          (id: number) => id !== updatedPaymentMethod.id,
        )
        state.paymentMethods = state.paymentMethods.map((method: CarrierPaymentMethod) =>
          method.id === updatedPaymentMethod.id
            ? updatedPaymentMethod
            : updatedPaymentMethod.isPrimary
              ? { ...method, isPrimary: false }
              : method,
        )
        state.paymentMethodsBackup = cloneDeep(state.paymentMethods)
        toast.success('Successfully updated payment method info')
      })
      .addCase(saveCarrierPaymentMethod.rejected, (state, action) => {
        state.loading.saveCarrierPaymentMethod = state.loading.saveCarrierPaymentMethod.filter(
          (id: number) => id !== action.meta.arg.paymentMethod.id,
        )
        toast.error(getErrorString(action.error.message, 'Failed to save Carrier Payment Method'))
      })
      .addCase(deleteCarrierPaymentMethod.pending, state => {
        state.loading.deleteCarrierPaymentMethod = true
      })
      .addCase(deleteCarrierPaymentMethod.fulfilled, (state, action) => {
        state.loading.deleteCarrierPaymentMethod = false
        const paymentMethodId = action.payload
        state.paymentMethods = state.paymentMethods.filter(
          (method: CarrierPaymentMethod) => method.id != paymentMethodId,
        )
        state.paymentMethodsBackup = cloneDeep(state.paymentMethods)
        toast.success('Successfully deleted payment method')
      })
      .addCase(deleteCarrierPaymentMethod.rejected, (state, { payload }) => {
        state.loading.deleteCarrierPaymentMethod = false
        toast.error(getErrorString(payload, 'Failed to delete payment method'))
      })
      .addCase(addCarrierPaymentMethod.pending, state => {
        state.loading.addCarrierPaymentMethod = true
      })
      .addCase(addCarrierPaymentMethod.fulfilled, state => {
        state.loading.addCarrierPaymentMethod = false
        toast.success('Successfully added payment method')
      })
      .addCase(addCarrierPaymentMethod.rejected, (state, action) => {
        state.loading.addCarrierPaymentMethod = false
        toast.error(getErrorString(action.payload, 'Failed to add Carrier Payment Method'))
      })
      .addCase(getCarrierDocuments.pending, state => {
        state.loading.getDocuments = true
      })
      .addCase(getCarrierDocuments.fulfilled, (state, action) => {
        const data = action.payload
        state.documents = data.results
        state.documents.map(
          document => (document.initialFreightOrFinance = document.freightOrFinance),
        )
        state.count.documents = data.count
        state.documentsBackup = cloneDeep(state.documents)
        state.loading.getDocuments = false
      })
      .addCase(getCarrierDocuments.rejected, state => {
        state.loading.getDocuments = false
      })
      .addCase(addCarrierDocument.pending, state => {
        state.loading.saveDocument = true
      })
      .addCase(addCarrierDocument.fulfilled, (state, action) => {
        state.documents.unshift(action.payload)
        state.documents[0].initialFreightOrFinance = state.documents[0].freightOrFinance
        state.documentsBackup = cloneDeep(state.documents)
        state.loading.saveDocument = false
        state.newDocument = buildNewDocument()
        toast.success('Successfully added new document')
      })
      .addCase(addCarrierDocument.rejected, state => {
        state.loading.saveDocument = false
        toast.error('Failed to save new document')
      })
      .addCase(updateCarrierDocument.pending, state => {
        state.loading.saveDocument = true
      })
      .addCase(updateCarrierDocument.fulfilled, state => {
        state.loading.saveDocument = false
        toast.success('Successfully updated document')
      })
      .addCase(updateCarrierDocument.rejected, state => {
        state.loading.saveDocument = false
        toast.error('Failed to update document')
      })
      .addCase(deleteCarrierDocument.pending, state => {
        state.loading.deleteDocument = true
      })
      .addCase(deleteCarrierDocument.fulfilled, (state, action) => {
        const documentId = action.payload
        state.loading.deleteDocument = false
        state.documents = state.documents.filter(document => document.id != documentId)
        state.documentsBackup = cloneDeep(state.documents)
        toast.success('Successfully deleted document')
      })
      .addCase(deleteCarrierDocument.rejected, state => {
        state.loading.deleteDocument = false
        toast.error('Failed to delete document')
      })
      .addCase(getCarrierLanes.pending, state => {
        state.loading.getLanes = true
      })
      .addCase(getCarrierLanes.fulfilled, (state, action) => {
        state.loading.getLanes = false
        state.lanes = action.payload
      })
      .addCase(getCarrierLanes.rejected, state => {
        state.loading.getLanes = false
        toast.error('Error getting carrier capacity')
      })
      .addCase(addNewCarrierLane.pending, state => {
        state.loading.addNewCarrierLane = true
      })
      .addCase(addNewCarrierLane.fulfilled, state => {
        state.loading.addNewCarrierLane = false
        toast.success('Capacity created successfully')
      })
      .addCase(addNewCarrierLane.rejected, state => {
        state.loading.addNewCarrierLane = false
        toast.error('Error creating new capacity')
      })
      .addCase(updateCarrierLane.fulfilled, () => {
        toast.success('Capacity updated successfully')
      })
      .addCase(updateCarrierLane.rejected, () => {
        toast.error('Error updating capacity')
      })
      .addCase(deleteCarrierLane.pending, state => {
        state.loading.deleteLane = true
      })
      .addCase(deleteCarrierLane.fulfilled, state => {
        state.loading.deleteLane = false
        toast.success('Capacity deleted successfully')
      })
      .addCase(deleteCarrierLane.rejected, state => {
        state.loading.deleteLane = false
        toast.error('Error deleting capacity')
      })
      .addCase(getCarrierEquipments.pending, state => {
        state.loading.getEquipments = true
      })
      .addCase(getCarrierEquipments.fulfilled, (state, action) => {
        const { results, count } = action.payload
        state.loading.getEquipments = false
        state.equipments = results
        state.count.equipments = count
      })
      .addCase(getCarrierEquipments.rejected, state => {
        state.loading.getEquipments = false
        toast.error('Error getting carrier equipments')
      })
      .addCase(getCarrierAccessories.pending, state => {
        state.loading.getAccessories = true
      })
      .addCase(getCarrierAccessories.fulfilled, (state, action) => {
        state.loading.getAccessories = false
        state.accessories = action.payload.results
      })
      .addCase(getCarrierAccessories.rejected, state => {
        state.loading.getAccessories = false
        toast.error('Error getting carrier accessories')
      })
      .addCase(getAccessoryOptions.pending, state => {
        state.loading.getAccessoryOptions = true
      })
      .addCase(getAccessoryOptions.fulfilled, (state, action) => {
        state.loading.getAccessoryOptions = false
        state.accessoryOptions = action.payload.results
      })
      .addCase(getAccessoryOptions.rejected, state => {
        state.loading.getAccessoryOptions = false
        toast.error('Error getting available accessories')
      })
      .addCase(setCarrierAccessories.fulfilled, (state, action) => {
        state.accessories = action.payload
        toast.success('Carrier equipment accessories saved successfully')
      })
      .addCase(setCarrierAccessories.rejected, () => {
        toast.error('Error saving carrier equipment accessories')
      })
      .addCase(setCarrierEquipments.pending, state => {
        state.loading.setEquipments = true
      })
      .addCase(setCarrierEquipments.fulfilled, (state, action) => {
        state.loading.setEquipments = false
        state.equipments = action.payload
        toast.success('Carrier equipments updated successfully')
      })
      .addCase(setCarrierEquipments.rejected, state => {
        state.loading.setEquipments = false
        toast.error('Error saving carrier equipments')
      })
      .addCase(exportCarriersCSV.pending, state => {
        state.loading.exportCSV = true
      })
      .addCase(exportCarriersCSV.fulfilled, state => {
        state.loading.exportCSV = false
        toast.success('Successfully exported CSV')
      })
      .addCase(exportCarriersCSV.rejected, (state, { payload }) => {
        state.loading.exportCSV = false
        toast.error(getErrorString(payload, 'Failed to export CSV'))
      })
      .addCase(addNewCarrierEquipments.pending, state => {
        state.loading.addNewCarrierEquipments = true
      })
      .addCase(addNewCarrierEquipments.fulfilled, state => {
        state.loading.addNewCarrierEquipments = false
        toast.success('Successfully added carrier equipment.')
      })
      .addCase(addNewCarrierEquipments.rejected, (state, { payload }) => {
        state.loading.addNewCarrierEquipments = false
        toast.error(getErrorString(payload, 'Failed to add carrier equipment'))
      })
      .addCase(checkCarrierMcOrDot.pending, state => {
        state.loading.checkCarrierMcOrDot = true
      })
      .addCase(checkCarrierMcOrDot.fulfilled, (state, { payload }) => {
        state.loading.checkCarrierMcOrDot = false
        if (!payload.attributes.header) {
          state.carrierLookup = null
          toast.error('Failed to lookup carrier')
        } else state.carrierLookup = payload
      })
      .addCase(checkCarrierMcOrDot.rejected, state => {
        state.loading.checkCarrierMcOrDot = false
        toast.error('Failed to lookup carrier')
      })
  },
})

export const {
  resetCarrierNote,
  setCarrierNote,
  setNewNote,
  updateCurrentCarrier,
  setCurrentCarrier,
  setNewContact,
  resetCarrierContact,
  updateContactField,
  updateInsuranceField,
  setNewInsurance,
  resetCarrierInsurance,
  updatePaymentMethodField,
  resetCarrierPaymentMethod,
  setNewPaymentMethod,
  setCarrierDocument,
  resetCarrierDocument,
  setSelectedAccessories,
  setFilters,
  setSize,
  setOffset,
  setSelectedFields,
  resetCarrierLookup,
} = carriersSlice.actions

export default carriersSlice.reducer
