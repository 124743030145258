import { Form, TextArea, TextInput } from '@components'

import { useAppSelector, useAppThunkDispatch } from '../../../app/hooks'
import { CustomerAddress } from '../../../common/types'
import { randomString, useCustomerType } from '../../../common/utils'
import { setCustomerInfo } from '../../../redux/customersSlice'
import { AddressForm } from '../../Forms'
import { AddButton, Facility, SectionHeader } from '../components'

export const Addresses = () => {
  const type = useCustomerType()

  const dispatch = useAppThunkDispatch()

  const addressesStep = useAppSelector(state => state.customers[type].addressesStep)
  const primaryAddress = useAppSelector(state => state.customers[type].addressesStep.primaryAddress)
  const facilities = useAppSelector(state => state.customers[type].addressesStep.facilities)

  const updatePrimaryAddress = (newData: Partial<CustomerAddress>) =>
    dispatch(
      setCustomerInfo({
        addressesStep: { ...addressesStep, primaryAddress: { ...primaryAddress, ...newData } },
      }),
    )

  const addNewFacility = () =>
    dispatch(
      setCustomerInfo({
        addressesStep: {
          ...addressesStep,
          facilities: [...facilities, { id: randomString(), isValid: false, currentPlace: null }],
        },
      }),
    )

  return (
    <>
      <Form
        setFormValid={isValid =>
          isValid !== primaryAddress.isValid && updatePrimaryAddress({ isValid })
        }
      >
        <SectionHeader>Primary Address/Headquarters</SectionHeader>
        <TextInput
          sm
          required
          value={primaryAddress.name}
          label='Location Name'
          className='mb-3'
          onChange={name => updatePrimaryAddress({ name })}
        />
        <AddressForm
          required
          value={primaryAddress}
          addressData={primaryAddress}
          setAddressData={updatePrimaryAddress}
          type='address'
        />
        <TextArea
          sm
          className='mt-3'
          label='Notes'
          value={primaryAddress.notes}
          onChange={notes => updatePrimaryAddress({ notes })}
        />
      </Form>
      {facilities?.map((facility, i) => (
        <Facility key={facility.id} facility={facility} index={i} />
      ))}
      <AddButton className='mt-6' onClick={addNewFacility}>
        Add Address
      </AddButton>
    </>
  )
}
