import './Button.scss'

import {
  ArchiveBoxIcon,
  ArrowRightIcon,
  ChevronDownIcon,
  ChevronUpIcon,
  DocumentIcon,
  EllipsisHorizontalCircleIcon,
  MagnifyingGlassIcon,
  PencilIcon,
  PlusCircleIcon,
  TrashIcon,
  XMarkIcon,
} from '@heroicons/react/24/outline'
import { ArrowDownTrayIcon, CheckIcon, PlusIcon } from '@heroicons/react/24/solid'

import { classNames } from '../../common/utils'

export const IconButton = ({
  style,
  disabled,
  onClick = () => {},
  icon,
  type = 'primary',
  className = '',
  iconClassName = '',
  size = 'sm',
  dataTestId,
}: {
  style?: any
  disabled?: boolean
  onClick?: Function
  icon: string
  type?: string
  className?: string
  iconClassName?: string
  size?: string
  dataTestId?: string
}) => {
  const btnClassName = classNames(`btn-${disabled ? 'disabled' : type}`, `btn-${size}`, className)

  const renderIcon = () => {
    const props = { className: classNames('w-4 h-4', iconClassName) }

    switch (icon) {
      case 'plus':
        return <PlusIcon {...props} />
      case 'plus-circle':
        return <PlusCircleIcon {...props} />
      case 'delete':
        return <TrashIcon {...props} />
      case 'close':
        return <XMarkIcon {...props} />
      case 'download':
        return <ArrowDownTrayIcon {...props} />
      case 'arrow-right':
        return <ArrowRightIcon {...props} />
      case 'arrow-down':
        return <ChevronDownIcon {...props} />
      case 'arrow-up':
        return <ChevronUpIcon {...props} />
      case 'edit':
        return <PencilIcon {...props} />
      case 'document':
        return <DocumentIcon {...props} />
      case 'check':
        return <CheckIcon {...props} />
      case 'more':
        return <EllipsisHorizontalCircleIcon {...props} />
      case 'search':
        return <MagnifyingGlassIcon {...props} />
      case 'archive':
        return <ArchiveBoxIcon {...props} />
      default:
        return <div />
    }
  }

  return (
    <button
      className={btnClassName}
      style={style}
      tabIndex={0}
      onClick={() => !disabled && onClick()}
      {...(dataTestId && { 'data-testid': dataTestId })}
    >
      {renderIcon()}
    </button>
  )
}
