import tw from 'tailwind-styled-components'

type InputContainerProps = {
  children: React.ReactNode
  className?: string
  error?: string
  icon?: React.ReactNode
  iconContainerClassName?: string
}

export const InputContainer = ({
  children,
  icon,
  className,
  error,
  iconContainerClassName = '',
}: InputContainerProps) => (
  <div className={`flex flex-col ${className}`}>
    <div className='flex gap-2 items-center'>
      {icon && (
        <IconContainer className={iconContainerClassName} data-testid='icon-container'>
          {icon}
        </IconContainer>
      )}
      {children}
    </div>
    <ErrorText>{error}</ErrorText>
  </div>
)

const ErrorText = tw.div`
  text-xs
  text-red-500
  h-4
  ml-1
`

const IconContainer = tw.div`
  h-6
  w-6
  flex
  items-center
  justify-center
  text-icon-gray
`
