import { CatchError } from '@common'
import { createAsyncThunk, createSelector, createSlice } from '@reduxjs/toolkit'
import { cloneDeep, isEqual, mapKeys, omit, snakeCase } from 'lodash-es'
import { toast } from 'react-toastify'

import { api } from '../api/api'
import { CustomerContact, RootState } from '../common/types'
import { keysToCamelCase, validateEmail, validatePhoneNumber } from '../common/utils'

type ContactsSliceState = {
  contacts: Array<CustomerContact>
  contactsBackup: Array<CustomerContact>
  loading: {
    addCustomerContact: boolean
    saveCustomerContact: Array<number>
  }
  newContact: CustomerContact
}

export const getInitialState: () => ContactsSliceState = () => ({
  contacts: [],
  contactsBackup: [],
  loading: {
    addCustomerContact: false,
    saveCustomerContact: [],
  },
  newContact: {
    id: -1,
    name: '',
    phone: '',
    email: '',
    isPrimary: false,
    isAccounting: false,
    isInvoice: false,
    pickupNotifications: false,
    deliveryNotifications: false,
    driverInfoNotifications: false,
    role: 1,
    isNameValid: false,
    isEmailValid: false,
    isPhoneValid: false,
    isRoleValid: false,
    portalAccess: false,
    receiveNoa: false,
    marketingContact: false,
    receiveMarketingEmails: false,
  },
})

const validateContact = (contact: CustomerContact) => ({
  ...contact,
  isNameValid: contact.name.length > 0,
  isEmailValid: validateEmail(contact.email, true),
  isPhoneValid: validatePhoneNumber(contact.phone, true),
  isRoleValid:
    contact.isPrimary ||
    contact.isAccounting ||
    contact.isInvoice ||
    contact.pickupNotifications ||
    contact.deliveryNotifications ||
    contact.driverInfoNotifications,
})

// Get list of contacts for a particular customer
export const getCustomerContacts = createAsyncThunk(
  'contacts/getCustomerContacts',
  async (payload: { customerId: number | string }) => {
    const response = await api.get(`/accounts/api/customer/contact/${payload.customerId}/`)

    return keysToCamelCase(response.data.results).map(({ firstName, lastName, ...contact }: any) =>
      validateContact({
        ...contact,
        name: `${firstName}${lastName ? ` ${lastName}` : ''}`,
      }),
    )
  },
)

// Update details of a single customer contact
export const saveCustomerContact = createAsyncThunk(
  'contacts/saveCustomerContact',
  async (payload: CustomerContact) => {
    try {
      const response = await api.put(
        `/accounts/api/customer/contact-rud/${payload.id}/`,
        mapKeys(payload, (_, key) => snakeCase(key)),
      )

      const contact = keysToCamelCase(response.data)

      return omit(
        validateContact({
          ...contact,
          name: `${contact.firstName}${contact.lastName ? ` ${contact.lastName}` : ''}`,
        }),
        ['firstName', 'lastName'],
      ) as CustomerContact
    } catch (e: CatchError) {
      throw JSON.stringify(e.response.data)
    }
  },
)

// Save all customer contacts that have actually been changed
export const saveAllCustomerContacts = createAsyncThunk(
  'contacts/saveAllCustomerContacts',
  async (_: any, { dispatch, getState }) => {
    const state = getState() as RootState
    state.contacts.contacts.map((contact: CustomerContact, index: number) => {
      const originalContact = state.contacts.contactsBackup[index] ?? {}
      if (!isEqual(contact, originalContact)) {
        dispatch(saveCustomerContact(contact))
      }
    })
  },
)

// Delete a particular customer contact
export const deleteCustomerContact = createAsyncThunk(
  'contacts/deleteCustomerContact',
  async (payload: { contactId: number | string }) => {
    await api.delete(`/accounts/api/customer/contact-rud/${payload.contactId}/`)
    return payload.contactId
  },
)

// Create a new contact for a customer
export const addCustomerContact = createAsyncThunk(
  'contacts/addCustomerContact',
  async (payload: { customerId: number | string }, { getState }) => {
    try {
      const state = getState() as RootState

      const response = await api.post(
        `/accounts/api/customer/contact/${payload.customerId}/`,
        mapKeys(omit(state.contacts.newContact, ['id']), (_, key) => snakeCase(key)),
      )

      const contact = keysToCamelCase(response.data)

      return omit(
        validateContact({
          ...contact,
          name: `${contact.firstName}${contact.lastName ? ` ${contact.lastName}` : ''}`,
        }),
        ['firstName', 'lastName'],
      ) as CustomerContact
    } catch (e: CatchError) {
      throw JSON.stringify(e.response.data)
    }
  },
)

// memoized selector for customer contacts with isInvoice set to true
export const selectInvoiceContacts = createSelector(
  (state: RootState) => state.contacts.contacts,
  contacts => contacts.filter(contact => contact.isInvoice),
)

export const contactsSlice = createSlice({
  name: 'contacts',
  initialState: getInitialState(),
  reducers: {
    setNewContact(state, { payload }) {
      state.newContact = validateContact(payload)
    },
    updateContactField(state, { payload }) {
      /* Update a single field for a particular contact */
      if (payload.index === -1) {
        // if index is -1, this is the new contact being updated
        state.newContact = validateContact({
          ...state.newContact,
          [payload.field]: payload.value,
        })
      } else {
        // Otherwise, update existing contact
        state.contacts = state.contacts.map((contact: CustomerContact, i: number) => {
          if (i == payload.index) {
            return validateContact({
              ...contact,
              [payload.field]: payload.value,
            })
          }
          return contact
        })
      }
    },
  },
  extraReducers(builder) {
    builder
      .addCase(getCustomerContacts.fulfilled, (state, action) => {
        state.contacts = action.payload
        state.contactsBackup = cloneDeep(state.contacts)
      })
      .addCase(getCustomerContacts.rejected, () => {
        toast.error('Error loading customer contacts')
      })
      .addCase(saveCustomerContact.pending, (state, action) => {
        state.loading.saveCustomerContact.push(action.meta.arg.id)
      })
      .addCase(saveCustomerContact.fulfilled, (state, action) => {
        const updatedContact = action.payload
        state.loading.saveCustomerContact = state.loading.saveCustomerContact.filter(
          (id: number) => id !== updatedContact.id,
        )
        state.contacts = state.contacts.map((contact: CustomerContact) =>
          contact.id === updatedContact.id ? updatedContact : contact,
        )
        state.contactsBackup = cloneDeep(state.contacts)
        toast.success('Successfully updated contact info')
      })
      .addCase(saveCustomerContact.rejected, (state, action) => {
        state.loading.saveCustomerContact = state.loading.saveCustomerContact.filter(
          (id: number) => id !== action.meta.arg.id,
        )
        toast.error(action.error.message)
      })
      .addCase(deleteCustomerContact.fulfilled, (state, action) => {
        const contactId = action.payload
        state.contacts = state.contacts.filter(
          (contact: CustomerContact) => contact.id != contactId,
        )
        state.contactsBackup = cloneDeep(state.contacts)
        toast.success('Successfully deleted contact')
      })
      .addCase(deleteCustomerContact.rejected, () => {
        toast.error('Failed to deleted contact')
      })
      .addCase(addCustomerContact.pending, state => {
        state.loading.addCustomerContact = true
      })
      .addCase(addCustomerContact.fulfilled, (state, action) => {
        state.loading.addCustomerContact = false
        state.contacts.unshift(action.payload)
        state.contactsBackup = cloneDeep(state.contacts)
        toast.success('Successfully added contact')
      })
      .addCase(addCustomerContact.rejected, (state, action) => {
        state.loading.addCustomerContact = false
        toast.error(action.error.message)
      })
  },
})

export const { setNewContact, updateContactField } = contactsSlice.actions

export default contactsSlice.reducer
