import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { toast } from 'react-toastify'

import { api } from '../api/api'
import { initialFilters } from '../common/constants'
import {
  CarrierFactoringRequest,
  FactoringDocument,
  RootState,
  SearchFilters,
} from '../common/types'
import { formatFileName, keysToCamelCase } from '../common/utils'

type CarrierFactoringRequestState = {
  carrierFactoringRequests: CarrierFactoringRequest[]
  count: {
    carrierFactoringRequests: number
    pendingCarrierFactoringRequests: number
  }
  loading: {
    list: boolean
    updatingCarrierFactoringRequest: boolean
  }
  filters: SearchFilters
  offset: number
  size: number
}

const initialState: CarrierFactoringRequestState = {
  carrierFactoringRequests: [],
  count: {
    carrierFactoringRequests: 0,
    pendingCarrierFactoringRequests: 0,
  },
  loading: {
    list: false,
    updatingCarrierFactoringRequest: false,
  },
  filters: initialFilters,
  offset: 0,
  size: 50,
}

export const getCarrierFactoringRequests = createAsyncThunk(
  'carrierFactoringRequests/getCarrierFactoringRequests',
  async (_, { getState }) => {
    const { filters, size, offset } = (getState() as RootState).carrierFactoringRequests
    return api
      .get('/accounts/api/carrier-factoring-requests/', {
        params: {
          offset,
          limit: size,
          status: filters.carrierFactoringRequestStatus || null,
          carrier_company: filters.carrier || null,
        },
      })
      .then(({ data }) => keysToCamelCase(data))
  },
)

export const getPendingCarrierFactoringRequests = createAsyncThunk(
  'carrierFactoringRequests/getPendingCarrierFactoringRequests',
  async () =>
    api
      .get('/accounts/api/carrier-factoring-requests/', {
        params: { offset: 0, limit: 1, status: 'PENDING' },
      })
      .then(({ data }) => data),
)

// Send NOA to customer associated with a single carrier factoring request
export const sendNoaToCustomer = createAsyncThunk(
  'carrierFactoringRequests/sendNoaToCustomer',
  async (id: number) => {
    const response = await api.post(`/accounts/api/send-noa-to-customer/${id}/`, {
      id: id,
    })

    return response.data.status
  },
)

// Update active status of a single carrier factoring request
export const updateCarrierFactoringRequestStatus = createAsyncThunk(
  'carrierFactoringRequests/updateCarrierFactoringRequestStatus',
  async (
    payload: {
      id: number
      status: string
    },
    { dispatch },
  ) => {
    const response = await api.patch(`/accounts/api/carrier-factoring-request/${payload.id}/`, {
      status: payload.status,
      id: payload.id,
    })

    dispatch(updateLocalCarrierFactoringRequest(payload))

    return response.data.status
  },
)

export const carrierFactoringRequestSlice = createSlice({
  name: 'carrierFactoringRequests',
  initialState,
  reducers: {
    updateLocalCarrierFactoringRequest(state, action) {
      for (let i = 0; i < state.carrierFactoringRequests.length; i++) {
        const req = state.carrierFactoringRequests[i]
        if (req.id == action.payload.id) {
          req.status = action.payload.status
        }
      }
    },
    setSize(state, { payload }) {
      state.size = payload
    },
    setOffset(state, { payload }) {
      state.offset = payload
    },
    setFilters(state, { payload }) {
      state.filters = payload
    },
    setPendingCarrierFactoringRequests(state, { payload }) {
      state.count.pendingCarrierFactoringRequests = payload
    },
  },
  extraReducers(builder) {
    builder
      .addCase(sendNoaToCustomer.pending, state => {
        state.loading.updatingCarrierFactoringRequest = true
      })
      .addCase(sendNoaToCustomer.fulfilled, state => {
        state.loading.updatingCarrierFactoringRequest = false
        toast.success('Sent NOAs successfully')
      })
      .addCase(sendNoaToCustomer.rejected, state => {
        state.loading.updatingCarrierFactoringRequest = false
        toast.error('Failed to send NOAs')
      })
      .addCase(getCarrierFactoringRequests.pending, state => {
        state.loading.list = true
      })
      .addCase(getCarrierFactoringRequests.fulfilled, (state, action) => {
        const data = action.payload
        state.carrierFactoringRequests = data.results.map(
          (req: any): CarrierFactoringRequest => ({
            ...req,
            carrierCompany: {
              id: req.carrierCompanyId,
              name: req.carrierCompanyName,
            },
            documents: req.documents.map((document: FactoringDocument) => ({
              ...document,
              fileName: formatFileName(document.fileName),
            })),
          }),
        )
        state.count.carrierFactoringRequests = data.count
        state.loading.list = false
      })
      .addCase(getCarrierFactoringRequests.rejected, state => {
        state.loading.list = false
      })
      .addCase(getPendingCarrierFactoringRequests.fulfilled, (state, action) => {
        const data = action.payload
        state.count.pendingCarrierFactoringRequests = data.count
      })
      .addCase(updateCarrierFactoringRequestStatus.pending, state => {
        state.loading.updatingCarrierFactoringRequest = true
      })
      .addCase(updateCarrierFactoringRequestStatus.fulfilled, state => {
        state.loading.updatingCarrierFactoringRequest = false
        toast.success('Updated')
      })
      .addCase(updateCarrierFactoringRequestStatus.rejected, state => {
        state.loading.updatingCarrierFactoringRequest = false
        toast.error('Failed')
      })
  },
})

export const {
  updateLocalCarrierFactoringRequest,
  setSize,
  setOffset,
  setFilters,
  setPendingCarrierFactoringRequests,
} = carrierFactoringRequestSlice.actions

export default carrierFactoringRequestSlice.reducer
