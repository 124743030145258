import { AlphaModal, AlphaModalProps, TextInput } from '@components'
import { zodResolver } from '@hookform/resolvers/zod'
import { useRef } from 'react'
import { Controller, useForm } from 'react-hook-form'
import tw from 'tailwind-styled-components'
import { z } from 'zod'

import { useAppSelector, useAppThunkDispatch } from '../../app/hooks'
import { getPhoneMaskOptions, useErrorFor } from '../../common/utils'
import { autoCompleteFSRSearch } from '../../redux/autocompleteSlice'
import { createCarrier } from '../../redux/carriersSlice'
import { Autocomplete, CityAutocomplete } from '../Autocomplete'
import { InputContainer } from '../InputContainer'

type CreateCarrierModalProps = Pick<AlphaModalProps, 'isVisible' | 'setVisible'>

const schema = z.object({
  name: z.string().min(3),
  mcNumber: z.string().regex(/^\d+$/, 'Digits only').min(6),
  dotNumber: z.string().regex(/^\d+$/, 'Digits only').min(6),
  phone: z.string().regex(/^\d+$/, 'Digits only').min(10),
  email: z.string().email(),
  address: z.string().min(3),
  cityLocation: z.object({
    city: z.string(),
    state: z.string(),
    country: z.string(),
  }),
  postalCode: z.string().min(5),
  factoringSalesRep: z
    .object({
      id: z.string(),
      text: z.string(),
    })
    .optional(),
  firstName: z.string(),
  lastName: z.string(),
})

export const CreateCarrierModal = ({ isVisible, setVisible }: CreateCarrierModalProps) => {
  const {
    handleSubmit,
    formState: { errors, isValid, touchedFields },
    control,
  } = useForm({
    mode: 'all',
    resolver: zodResolver(schema),
  })

  const onClose = () => setVisible(false)

  const dispatch = useAppThunkDispatch()

  const loading = useAppSelector(state => state.carriers.loading.createCarrier)

  const errorFor = useErrorFor(touchedFields, errors)

  const submitRef = useRef<HTMLInputElement>()
  const fsrSearchOptions = useAppSelector(state => state.autocomplete.fsrSearchOptions)

  const onConfirm = () => {
    if (submitRef.current) {
      submitRef.current.click()
    }
  }

  const phoneMaskOpts = getPhoneMaskOptions()

  const onSubmit = async (data: any) => {
    data = {
      ...data,
      factoringSalesRep: data.factoringSalesRep?.id,
      city: data.cityLocation.city,
      stateProvinceRegion: data.cityLocation.state,
      country: data.cityLocation.country,
    }
    delete data['cityLocation']
    const response = await dispatch(createCarrier(data))

    if (response.meta.requestStatus === 'fulfilled') {
      onClose()
    }
  }

  return (
    <AlphaModal
      className='!overflow-visible'
      confirmButtonLabel='Create'
      isConfirmButtonDisabled={!isValid}
      isConfirmButtonLoading={loading}
      isVisible={isVisible}
      setVisible={setVisible}
      title='Create Carrier'
      onCancel={onClose}
      onConfirm={onConfirm}
    >
      <Form onSubmit={handleSubmit(onSubmit)}>
        <InputContainer className='w-full' error={errorFor('name')}>
          <Controller
            control={control}
            name='name'
            render={({ field }) => (
              <TextInput required sm className='w-full' label='Carrier Name' {...field} />
            )}
          />
        </InputContainer>
        <InputRow>
          <InputContainer className='w-1/2' error={errorFor('mcNumber')}>
            <Controller
              control={control}
              name='mcNumber'
              render={({ field }) => (
                <TextInput required sm className='w-full' label='MC Number' {...field} />
              )}
            />
          </InputContainer>
          <InputContainer className='w-1/2' error={errorFor('dotNumber')}>
            <Controller
              control={control}
              name='dotNumber'
              render={({ field }) => (
                <TextInput required sm className='w-full' label='DOT Number' {...field} />
              )}
            />
          </InputContainer>
        </InputRow>
        <p className='font-bold mb-2'>Primary Contact</p>
        <InputRow>
          <InputContainer className='w-1/2' error={errorFor('firstName')}>
            <Controller
              control={control}
              name='firstName'
              render={({ field }) => (
                <TextInput required sm className='w-full' label='First Name' {...field} />
              )}
            />
          </InputContainer>
          <InputContainer className='w-1/2' error={errorFor('lastName')}>
            <Controller
              control={control}
              name='lastName'
              render={({ field }) => (
                <TextInput required sm className='w-full' label='Last Name' {...field} />
              )}
            />
          </InputContainer>
        </InputRow>
        <InputRow>
          <InputContainer className='w-1/2' error={errorFor('phone')}>
            <Controller
              control={control}
              name='phone'
              render={({ field }) => (
                <TextInput
                  required
                  sm
                  className='w-full'
                  label='Phone'
                  maskOptions={phoneMaskOpts}
                  type='tel'
                  {...field}
                />
              )}
            />
          </InputContainer>
          <InputContainer className='w-1/2' error={errorFor('email')}>
            <Controller
              control={control}
              name='email'
              render={({ field }) => (
                <TextInput required sm className='w-full' label='Email' type='email' {...field} />
              )}
            />
          </InputContainer>
        </InputRow>
        <p className='font-bold mb-2'>Address</p>
        <InputContainer className='w-full' error={errorFor('address')}>
          <Controller
            control={control}
            name='address'
            render={({ field }) => (
              <TextInput required sm className='w-full' label='Street Address' {...field} />
            )}
          />
        </InputContainer>
        <InputRow>
          <InputContainer className='w-1/2' error={errorFor('cityLocation')}>
            <Controller
              control={control}
              name='cityLocation'
              render={({ field }) => (
                <CityAutocomplete
                  required={true}
                  label='City'
                  className='w-full'
                  placeholder=''
                  {...field}
                />
              )}
            />
          </InputContainer>
          <InputContainer className='w-1/2' error={errorFor('postalCode')}>
            <Controller
              control={control}
              name='postalCode'
              render={({ field }) => (
                <TextInput required sm className='w-full' label='Postal Code' {...field} />
              )}
            />
          </InputContainer>
        </InputRow>

        <InputRow className='mt-6'>
          <InputContainer className='w-1/2' error={errorFor('factoringSalesRep')}>
            <Controller
              control={control}
              name='factoringSalesRep'
              render={({ field }) => (
                <Autocomplete
                  className='w-full'
                  field='text'
                  items={fsrSearchOptions}
                  label='EXO Finance Sales Representative'
                  setValue={field.onChange}
                  onValueChange={(q: string) => dispatch(autoCompleteFSRSearch(q))}
                  {...field}
                  value={field.value?.text}
                />
              )}
            />
          </InputContainer>
        </InputRow>

        <input ref={submitRef as any} className='hidden' type='submit' />
      </Form>
    </AlphaModal>
  )
}

const Form = tw.form`
  flex
  flex-col
  lg:w-[640px]
  px-6
  py-3
`

const InputRow = tw.div`
  flex
  gap-2
`
