import { CheckCircleIcon } from '@heroicons/react/24/outline'
import { useState } from 'react'
import tw from 'tailwind-styled-components'

import type { StepIconProps, StepProps, StepsProps } from '../types'

export const Steps = ({ currentStep }: StepsProps) => (
  <StepsContainer>
    <Step idx={1} status={getStepStatus(currentStep)(0)} title='Set route' />
    <Divider />
    <Step idx={2} status={getStepStatus(currentStep)(1)} title='Manage items' />
    <Divider />
    <Step idx={3} status={getStepStatus(currentStep)(2)} title='Review information' />
  </StepsContainer>
)

const getStepStatus = (currentStep: number) => (index: number) => {
  if (index === currentStep) {
    return 'Current Step'
  } else if (index < currentStep) {
    return 'Completed'
  } else {
    return 'Not Complete'
  }
}

const Step = ({ status, title, idx }: StepProps) => (
  <StepContainer $isCurrent={status === 'Current Step'}>
    <div className='flex items-center justify-center p-6'>
      <StepIcon {...{ status, idx }} />
    </div>
    <div className='flex flex-col justify-center'>
      <StepStatus>{status}</StepStatus>
      <StepTitle>{title}</StepTitle>
    </div>
  </StepContainer>
)

const StepIcon = ({ status, idx }: StepIconProps) => (
  <StepIconContainer $isCurrent={status === 'Current Step'}>
    {['Current Step', 'Not Complete'].includes(status) ? (
      <StepNumber $disabled={status === 'Not Complete'}>{idx}</StepNumber>
    ) : (
      <CheckCircleIcon />
    )}
  </StepIconContainer>
)

export const useSteps = () => {
  const [currentStep, setCurrentStep] = useState(0)
  const stepName = currentStep === 0 ? 'route' : currentStep === 1 ? 'items' : 'review'

  return {
    currentStep,
    setCurrentStep,
    stepName,
  }
}

export const defaultStops = [
  {
    id: Math.ceil(Math.random() * 1000000000),
    location: {
      name: '',
      city: '',
      stateProvinceRegion: '',
      postalCode: '',
      country: '',
    },
    stopDate: undefined,
    stopType: 1,
    items: [
      {
        id: Math.ceil(Math.random() * 1000000000),
        manifest: '',
        weight: '',
        quantity: 0,
        length: '',
        width: '',
        height: '',
        notes: '',
        stopType: 1,
        itemMappingKey: Math.random().toString(36).substr(2, 6),
      },
    ],
  },
  {
    id: Math.ceil(Math.random() * 1000000000),
    location: {
      name: '',
      city: '',
      stateProvinceRegion: '',
      postalCode: '',
      country: '',
    },
    stopDate: undefined,
    stopType: 2,
    items: [
      {
        id: Math.ceil(Math.random() * 1000000000),
        manifest: '',
        weight: '',
        quantity: 0,
        length: '',
        width: '',
        height: '',
        notes: '',
        stopType: 2,
      },
    ],
  },
]

const StepsContainer = tw.div`
  flex
  border-b
  border-light-blue
  text-sm
`

const StepContainer = tw.div<{ $isCurrent?: boolean }>`
  w-1/3
  flex
  h-20
  ${p => (p.$isCurrent ? 'bg-light-blue' : 'text-icon-gray')}
`

const StepIconContainer = tw.div<{ $isCurrent?: boolean }>`
  h-6
  w-6
  flex
  text-xs
  ${p => (p.$isCurrent ? 'text-dark-blue' : 'text-icon-gray')}
`

const StepStatus = tw.span``

const StepTitle = tw.span``

const Divider = tw.div`
  bg-light-blue
  w-px
  h-20
`

type StepNumberProps = {
  $disabled: boolean
}

const StepNumber = tw.div<StepNumberProps>`
  ${(p: StepNumberProps) => (p.$disabled ? 'bg-icon-gray' : 'bg-dark-blue')}
  h-[18px]
  w-[18px]
  rounded-full
  flex
  items-center
  justify-center
  font-bold
  text-white
`
