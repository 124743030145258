import { PlusCircleIcon } from '@heroicons/react/24/solid'
import tw from 'tailwind-styled-components'

export const AddButton = ({
  children,
  onClick,
  className,
}: {
  children: string
  onClick: () => void
  className?: string
}) => (
  <OuterContainer className={className}>
    <InnerContainer onClick={onClick}>
      <PlusCircleIcon className='w-4 mr-2' /> <div>{children}</div>
    </InnerContainer>
  </OuterContainer>
)

const OuterContainer = tw.div`
  flex
  justify-end
  mt-4
`

const InnerContainer = tw.div`
  text-link
  flex
  items-center
  whitespace-nowrap
  font-semibold
  hover:opacity-80
  transition-all
  cursor-pointer
`
