import { useAppSelector, useAppThunkDispatch } from '../../../app/hooks'
import { initialCustomerContact } from '../../../common/constants'
import { CustomerTeamEmail, OnboardingCustomerContact } from '../../../common/types'
import { randomString, useCustomerType } from '../../../common/utils'
import { setCustomerInfo } from '../../../redux/customersSlice'
import { AddButton, Contact, ContactView, SectionHeader, TeamEmail } from '../components'

export const Contacts = () => {
  const type = useCustomerType()

  const dispatch = useAppThunkDispatch()

  const contactsStep = useAppSelector(state => state.customers[type].contactsStep)
  const contacts = useAppSelector(state => state.customers[type].contactsStep.contacts)
  const invoiceContacts = useAppSelector(state => state.customers[type].accountingStep.contacts)
  const teamEmails = useAppSelector(state => state.customers[type].contactsStep.teamEmails)

  const setCustomer = (
    newData: { contacts: OnboardingCustomerContact[] } | { teamEmails: CustomerTeamEmail[] },
  ) => dispatch(setCustomerInfo({ contactsStep: { ...contactsStep, ...newData } }))

  const addNewContact = () =>
    setCustomer({
      contacts: [
        ...contacts,
        {
          ...initialCustomerContact,
          id: randomString(),
        },
      ],
    })

  const addNewEmail = () =>
    setCustomer({
      teamEmails: [
        ...teamEmails,
        {
          name: '',
          email: '',
          id: randomString(),
          isValid: false,
        },
      ],
    })

  return (
    <>
      {!contacts.length && <SectionHeader>contacts</SectionHeader>}
      {contacts.map((contact, i) => (
        <Contact key={i} contact={contact} index={i} />
      ))}
      <AddButton onClick={addNewContact}>Add Contact</AddButton>
      {invoiceContacts.map((contact, i) => (
        <ContactView key={i} isAccounting withTooltip contact={contact} index={i} />
      ))}
      <SectionHeader className='mt-6'>external team email(s)</SectionHeader>
      {teamEmails?.map((email, i) => <TeamEmail key={email.id} email={email} index={i} />)}
      <div className='flex justify-end mt-4'>
        <AddButton onClick={addNewEmail}>Add External Team Email</AddButton>
      </div>
    </>
  )
}
