import { CatchError, formatAxiosErrorToPayload, getErrorString } from '@common'
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { snakeCase } from 'lodash-es'
import { toast } from 'react-toastify'

import { api } from '../api/api'
import { NewCSVUpload, RootState } from '../common/types'
import { keysToCamelCase } from '../common/utils'

type CSVState = {
  csvList: Array<any>
  loading: {
    csvListLoading: boolean
    uploadCSVLoading: boolean
  }
  count: number
  offset: number
  size: number
}

const initialState: CSVState = {
  csvList: [],
  loading: {
    csvListLoading: false,
    uploadCSVLoading: false,
  },
  count: 0,
  offset: 0,
  size: 50,
}

export const getCSV = createAsyncThunk('csv/getCSV', async (_, { getState }) => {
  const { size = 50, offset = 0 } = (getState() as RootState).csv

  const response = await api.get('/customer-import-jobs/api/load-csv-import/', {
    params: {
      limit: size,
      offset,
    },
  })

  return response.data
})

export const uploadNewCSV = createAsyncThunk(
  'csv/uploadNewCSV',
  async (payload: NewCSVUpload, { dispatch, rejectWithValue }) => {
    const flData = new FormData()
    flData.append('file', payload.file)

    Object.keys(payload).forEach(
      // @ts-ignore - dynamic key
      (key: string) => key !== 'file' && flData.append(snakeCase(key), payload[key].toString()),
    )

    try {
      const response = await api
        .post('/customer-import-jobs/api/load-csv-import/', flData)
        .then(({ data }) => data)
      dispatch(getCSV())
      return response.data
    } catch (err: CatchError) {
      return rejectWithValue(formatAxiosErrorToPayload(err))
    }
  },
)

const csvSlice = createSlice({
  name: 'csv',
  initialState,
  reducers: {
    setSize(state, { payload }) {
      state.size = payload
    },
    setOffset(state, { payload }) {
      state.offset = payload
    },
  },
  extraReducers(builder) {
    builder
      .addCase(getCSV.pending, state => {
        state.loading.csvListLoading = true
      })
      .addCase(getCSV.fulfilled, (state, action) => {
        const { count, results } = action.payload
        state.csvList = keysToCamelCase(results)
        state.count = count
        state.loading.csvListLoading = false
      })
      .addCase(getCSV.rejected, state => {
        toast.error('Failed to get CSV Imports')
        state.loading.csvListLoading = false
      })
      .addCase(uploadNewCSV.pending, state => {
        state.loading.uploadCSVLoading = true
      })
      .addCase(uploadNewCSV.fulfilled, state => {
        toast.success('Successfully uploaded CSV')
        state.loading.uploadCSVLoading = false
      })
      .addCase(uploadNewCSV.rejected, (state, action) => {
        toast.error(getErrorString(action.payload, 'Failed to upload CSV'))
      })
  },
})

export const { setSize, setOffset } = csvSlice.actions

export default csvSlice.reducer
