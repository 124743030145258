import { useState } from 'react'
import tw from 'tailwind-styled-components'

import { Accessorial } from '../../../../common/types'
import { EditAccessorialItem } from './EditAccessorialItem'
import { ViewAccessorialItem } from './ViewAccessorialItem'

export const AccessorialItem = ({ accessorial }: { accessorial: Accessorial }) => {
  const [isEditing, setEditing] = useState(false)

  return (
    <div className='border border-light-blue rounded-md mb-4'>
      <AccessorialWrap data-testid='accessorial-wrap'>
        {isEditing ? (
          <EditAccessorialItem accessorial={accessorial} onCancelEdit={() => setEditing(false)} />
        ) : (
          <ViewAccessorialItem accessorial={accessorial} onEdit={() => setEditing(true)} />
        )}
      </AccessorialWrap>
    </div>
  )
}

const AccessorialWrap = tw.div`
  px-4
  py-3
`
