import { CatchError, formatAxiosErrorToPayload, getErrorString } from '@common'
import { downloadCSV } from '@common'
import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit'
import { toast } from 'react-toastify'

import { api } from '../api/api'
import { initialFilters } from '../common/constants'
import {
  CarrierCustomerRelationship,
  CarrierPaymentMethod,
  CarrierRecovery,
  CarrierRecoveryDetail,
  CarrierReserves,
  CarrierReservesDashboard,
  CarrierReservesRecoveries,
  CSVField,
  FactoringCarrierDetails,
  RootState,
  SearchFilters,
} from '../common/types'
import { cleanFilters, keysToCamelCase, keysToSnakeCase } from '../common/utils'

type CarrierReservesRecoveriesState = {
  reservesRecoveries: CarrierReservesRecoveries[]
  recoveries: CarrierRecovery[]
  recoveryDetails: CarrierRecoveryDetail[]
  recovery: CarrierRecovery | null
  reserves: CarrierReserves[]
  reservesDashboard: CarrierReservesDashboard
  carrierCreditDetails: FactoringCarrierDetails | null
  carrierCustomerRelationshipList: CarrierCustomerRelationship[]
  count: {
    reservesRecoveries: number
    reserves: number
    recoveries: number
    recoveryDetails: number
    carrierCustomerRelationshipList: number
  }
  loading: {
    list: boolean
    createReserve: boolean
    createRecovery: boolean
    reservesRecoveries: boolean
    reservesDashboard: boolean
    reserves: boolean
    recoveries: boolean
    recovery: boolean
    carrierCreditDetails: boolean
    carrierCustomerRelationshipList: boolean
    reservesExportCSV: boolean
    recoveriesExportCSV: boolean
    recoveryDetailsExportCSV: boolean
  }
  reservesExportFields: CSVField[]
  reservesSelectedFields: Array<string>
  recoveriesExportFields: CSVField[]
  recoveriesSelectedFields: Array<string>
  recoveryDetailsExportFields: CSVField[]
  recoveryDetailsSelectedFields: Array<string>
  filters: SearchFilters
  offset: number
  size: number
  newManualReserveEntry: {
    type: 'CHARGEBACK' | 'RESERVE_PAYMENT' | 'RESERVE_RELEASE'
    carrier: { id?: string | number; text?: string }
    load: { id?: number; text?: string }
    referenceNumber?: string
    customer: { id?: string | number; text?: string }
    notes: string
    amount: string
    paymentMethod: CarrierPaymentMethod | null
  }
  newRecovery: {
    amount: number
    averageInvoice: number
    carrier: { id?: number; text?: string }
    dollarsPerInstallment: number
    notes: string
    recoveryTargetAccount: string
  }
}

const initialState: CarrierReservesRecoveriesState = {
  reservesRecoveries: [],
  reserves: [],
  recoveryDetails: [],
  recoveries: [],
  recovery: null,
  carrierCreditDetails: null,
  carrierCustomerRelationshipList: [],
  reservesDashboard: {
    requiredReserveBalance: 0,
    totalReserved: 0,
    totalRecovery: 0,
    recoveryDetails: 0,
    dashboardState: {
      last30Total: 0,
      prior30Total: 0,
    },
    reservePercentage: 0,
    carrierCompany: {
      id: -1,
      name: '',
    },
  },
  count: {
    reservesRecoveries: 0,
    reserves: 0,
    recoveries: 0,
    recoveryDetails: 0,
    carrierCustomerRelationshipList: 0,
  },
  loading: {
    list: false,
    createReserve: false,
    createRecovery: false,
    reservesRecoveries: false,
    reserves: false,
    reservesDashboard: false,
    recoveries: false,
    recovery: false,
    carrierCreditDetails: false,
    carrierCustomerRelationshipList: false,
    reservesExportCSV: false,
    recoveriesExportCSV: false,
    recoveryDetailsExportCSV: false,
  },
  filters: initialFilters,
  offset: 0,
  size: 50,
  newManualReserveEntry: {
    type: 'CHARGEBACK',
    carrier: {},
    load: {},
    referenceNumber: '',
    customer: {},
    notes: '',
    amount: '',
    paymentMethod: null,
  },
  newRecovery: {
    amount: 0,
    averageInvoice: 0,
    carrier: {},
    dollarsPerInstallment: 0,
    notes: '',
    recoveryTargetAccount: '',
  },
  reservesExportFields: [
    { label: 'Date', key: 'created_at' },
    { label: 'Load ID', key: 'load_id' },
    { label: 'Reference Number', key: 'reference_number' },
    { label: 'Debtor', key: 'debtor' },
    { label: 'Type', key: 'entry_type' },
    { label: 'Notes', key: 'notes' },
    { label: 'Amount', key: 'amount' },
    { label: 'Balance', key: 'balance' },
  ],
  reservesSelectedFields: [
    'created_at',
    'load_id',
    'reference_number',
    'debtor',
    'entry_type',
    'notes',
    'amount',
    'balance',
  ],
  recoveriesExportFields: [
    { label: 'Date', key: 'created_at' },
    { label: 'Recovery ID', key: 'id' },
    { label: 'Load ID', key: 'load_id' },
    { label: 'Reference Number', key: 'reference_number' },
    { label: 'Recovery Plan', key: 'recovery_plan' },
    { label: 'Description', key: 'description' },
    { label: 'Outstanding', key: 'outstanding' },
    { label: 'Amount', key: 'amount_to_recover' },
  ],
  recoveriesSelectedFields: [
    'created_at',
    'id',
    'load_id',
    'reference_number',
    'recovery_plan',
    'description',
    'outstanding',
    'amount_to_recover',
  ],
  recoveryDetailsExportFields: [
    { label: 'Date', key: 'created_at' },
    { label: 'Load ID', key: 'load_id' },
    { label: 'Reference Number', key: 'reference_number' },
    { label: 'Description', key: 'description' },
    { label: 'Amount', key: 'amount' },
    { label: 'Outstanding', key: 'outstanding' },
  ],
  recoveryDetailsSelectedFields: [
    'created_at',
    'load_id',
    'reference_number',
    'description',
    'amount',
    'outstanding',
  ],
}

const getFilters = (filters: SearchFilters) =>
  cleanFilters({
    carrier_id: filters.carrier,
    released_carriers: filters.releasedCarriers,
  })

export const getCarrierReservesRecoveries = createAsyncThunk(
  'reservesRecoveries/getCarrierReservesRecoveries',
  async (_, { getState }) => {
    const { filters, size, offset } = (getState() as RootState).reservesRecoveries

    const response = await api.get('/billing/api/carriers-company-summary/', {
      params: {
        limit: size,
        offset,
        ...getFilters(filters),
      },
    })

    const results: CarrierReservesRecoveries[] = keysToCamelCase(response.data.results)
    results.map(item => (item.id = item.carrierCompanyId))

    return {
      results: results,
      count: response.data.count,
    }
  },
)

export const createManualReserveEntry = createAsyncThunk(
  'reservesRecoveries/createManualReserveEntry',
  async (_, { getState, rejectWithValue, dispatch }) => {
    const { amount, carrier, customer, load, notes, referenceNumber, type } = (
      getState() as RootState
    ).reservesRecoveries.newManualReserveEntry
    let requestData = {}
    requestData = {
      amount,
      notes,
      type,
      carrier_id: carrier?.id,
      load_id: load?.id,
      customer_id: customer?.id,
    }

    if (type === 'CHARGEBACK') {
      requestData = {
        ...requestData,
        reference_number: referenceNumber ? referenceNumber : null,
      }
    }

    try {
      const response = await api.post('/billing/api/manual-reserve/', requestData)
      dispatch(getCarrierReserves({ carrierId: Number(carrier?.id) || 0 }))
      return keysToCamelCase(response.data)
    } catch (err: CatchError) {
      return rejectWithValue(formatAxiosErrorToPayload(err))
    }
  },
)

export const createCarrierReservePayment = createAsyncThunk(
  'reservesRecoveries/createCarrierReservePayment',
  async (_, { getState, rejectWithValue }) => {
    const { amount, carrier, notes, paymentMethod, type } = (getState() as RootState)
      .reservesRecoveries.newManualReserveEntry
    return api
      .post('/billing/api/carrier-reserve-pay/', {
        notes,
        type,
        amount: Number(amount),
        carrier_id: Number(carrier?.id),
        payment_method_id: paymentMethod?.id,
        payment_for: 'RESERVE_EXCEED',
      })
      .then(({ data }) => keysToCamelCase(data))
      .catch((err: CatchError) => rejectWithValue(formatAxiosErrorToPayload(err)))
  },
)

export const getCarrierReserves = createAsyncThunk(
  'reservesRecoveries/getCarrierReserves',
  async ({ carrierId }: { carrierId: number }, { getState, rejectWithValue }) => {
    const { size, offset } = (getState() as RootState).reservesRecoveries
    return api
      .get('/billing/api/carrier-company-reserve/', {
        params: {
          carrier_id: carrierId,
          offset,
          limit: size,
        },
      })
      .then(({ data }) => keysToCamelCase(data))
      .catch((err: CatchError) => rejectWithValue(formatAxiosErrorToPayload(err)))
  },
)

export const getCarrierRecoveries = createAsyncThunk(
  'reservesRecoveries/getCarrierRecoveries',
  async ({ carrierId }: { carrierId: number }, { getState, rejectWithValue }) => {
    const { size, offset } = (getState() as RootState).reservesRecoveries
    return api
      .get('/billing/api/carrier-company-recovery/', {
        params: {
          carrier_id: carrierId,
          offset,
          limit: size,
        },
      })
      .then(({ data }) => keysToCamelCase(data))
      .catch((err: CatchError) => rejectWithValue(formatAxiosErrorToPayload(err)))
  },
)

export const getCarrierRecoveryDetails = createAsyncThunk(
  'reservesRecoveries/getCarrierRecoveryDetails',
  async ({ recoveryId }: { recoveryId: number }, { rejectWithValue }) =>
    api
      .get(`/billing/api/carrier-recovery-detail/${recoveryId}/`)
      .then(({ data }) => keysToCamelCase(data))
      .catch((err: CatchError) => rejectWithValue(formatAxiosErrorToPayload(err))),
)

export const getCarrierReservesDashboard = createAsyncThunk(
  'reservesRecoveries/getCarrierReservesDashboard',
  async ({ carrierId }: { carrierId: number }) => {
    const response = await api.get('/accounts/api/load-factoring-dashboard/', {
      params: {
        carrier_id: carrierId,
      },
    })
    return response.data
  },
)

export const getCarrierAverageFactoredLoadInvoice = createAsyncThunk(
  'reservesRecoveries/getCarrierAverageFactoredLoadInvoice',
  async (_, { getState, rejectWithValue }) => {
    const lookupId = (getState() as RootState).reservesRecoveries.newRecovery.carrier.id
    return api
      .get('/billing/api/carrier-company-average-invoice/', {
        params: {
          carrier_company_id: lookupId,
        },
      })
      .then(({ data }) => keysToCamelCase(data))
      .catch((err: CatchError) => rejectWithValue(formatAxiosErrorToPayload(err)))
  },
)

export const createCarrierRecovery = createAsyncThunk(
  'reservesRecoveries/createCarrierRecovery',
  async (_, { getState, rejectWithValue }) => {
    const { amount, carrier, dollarsPerInstallment, notes, recoveryTargetAccount } = (
      getState() as RootState
    ).reservesRecoveries.newRecovery
    return api
      .post('/billing/api/create-carrier-recovery/', {
        description: notes,
        amount_to_recover: Number(amount),
        carrier_company: carrier?.id,
        repayment_amount_per_load: dollarsPerInstallment,
        recovery_target_account: recoveryTargetAccount,
      })
      .then(({ data }) => keysToCamelCase(data))
      .catch((err: CatchError) => rejectWithValue(formatAxiosErrorToPayload(err)))
  },
)

export const updateCarrierCreditDetails = createAsyncThunk(
  'reservesRecoveries/updateCarrierCreditDetails',
  async (
    payload: {
      id: number
      buyStatus?: boolean
      creditLimit?: number
    },
    { dispatch, rejectWithValue },
  ) => {
    try {
      const response = await api.patch(
        `/factoring/api/carrier-credit-details/${payload.id}/`,
        keysToSnakeCase(payload),
      )

      dispatch(getCarrierReservesRecoveries())
      dispatch(getFactoringCreditDetails({ carrierId: Number(payload.id) }))
      return response.data
    } catch (err: CatchError) {
      return rejectWithValue(formatAxiosErrorToPayload(err))
    }
  },
)

export const getFactoringCreditDetails = createAsyncThunk(
  'reservesRecoveries/getFactoringCreditDetails',
  async ({ carrierId }: { carrierId: number }) =>
    api
      .get(`/factoring/api/carrier-credit-details/${carrierId}/`)
      .then(({ data }) => keysToCamelCase(data)),
)

export const getCarrierCustomerRelationshipList = createAsyncThunk(
  'reservesRecoveries/getCarrierCustomerRelationshipList',
  async ({ carrierId }: { carrierId: number }, { getState }) => {
    const { filters, size, offset } = (getState() as RootState).reservesRecoveries

    const response = await api.get(`/factoring/api/carrier-relationship-list/${carrierId}`, {
      params: {
        limit: size,
        offset,
        ...getFilters(filters),
      },
    })

    const results: CarrierCustomerRelationship[] = keysToCamelCase(response.data.results)
    results.map(item => (item.id = item.relationshipId))

    return {
      results: results,
      count: response.data.count,
    }
  },
)

export const updateFactoringRelationshipDetails = createAsyncThunk(
  'reservesRecoveries/updateFactoringRelationshipDetails',
  async (
    payload: {
      id: number
      relationshipId: number
      currentlyActive?: boolean
      creditLimit?: number
      hasNoa?: boolean
    },
    { dispatch, rejectWithValue },
  ) => {
    try {
      const response = await api.patch(
        `/factoring/api/customer-carrier-relationship/${payload.relationshipId}/`,
        keysToSnakeCase(payload),
      )

      dispatch(getCarrierCustomerRelationshipList({ carrierId: payload.id }))
      return response.data
    } catch (err: CatchError) {
      return rejectWithValue(formatAxiosErrorToPayload(err))
    }
  },
)

export const updateCarrierFee = createAsyncThunk(
  'reservesRecoveries/updateCarrierFee',
  async (
    payload: {
      carrierId: number
      flatFee?: number
      achFee?: number
      factoringFeePercentage?: number
    },
    { dispatch, rejectWithValue },
  ) => {
    try {
      const response = await api.patch(
        `/factoring/api/update-carrier-fee/${payload.carrierId}/`,
        keysToSnakeCase(payload),
      )

      dispatch(getCarrierReservesRecoveries())
      return response.data
    } catch (err: CatchError) {
      return rejectWithValue(formatAxiosErrorToPayload(err))
    }
  },
)

export const exportReservesCSV = createAsyncThunk(
  'reservesRecoveries/exportReservesCSV',
  async ({ carrierId }: { carrierId: number }, { getState, rejectWithValue }) => {
    const { reservesSelectedFields } = (getState() as RootState).reservesRecoveries

    try {
      const response = await api.get(`/billing/api/export-carrier-reserves/${carrierId}`, {
        params: {
          fields: reservesSelectedFields.join(','),
        },
      })

      downloadCSV(response.data, `reserves_carrier_id_${carrierId}`)
    } catch (err: CatchError) {
      return rejectWithValue(formatAxiosErrorToPayload(err))
    }
  },
)

export const exportRecoveriesCSV = createAsyncThunk(
  'reservesRecoveries/exportRecoveriesCSV',
  async ({ carrierId }: { carrierId: number }, { getState, rejectWithValue }) => {
    const { recoveriesSelectedFields } = (getState() as RootState).reservesRecoveries

    try {
      const response = await api.get(`/billing/api/export-carrier-recoveries/${carrierId}`, {
        params: {
          fields: recoveriesSelectedFields.join(','),
        },
      })

      downloadCSV(response.data, `recoveries_carrier_id_${carrierId}`)
    } catch (err: CatchError) {
      return rejectWithValue(formatAxiosErrorToPayload(err))
    }
  },
)

export const exportRecoveryDetailsCSV = createAsyncThunk(
  'reservesRecoveries/exportRecoveryDetailsCSV',
  async ({ recoveryId }: { recoveryId: number }, { getState, rejectWithValue }) => {
    const { recoveryDetailsSelectedFields } = (getState() as RootState).reservesRecoveries

    try {
      const response = await api.get(`/billing/api/export-recovery-details/${recoveryId}`, {
        params: {
          fields: recoveryDetailsSelectedFields.join(','),
        },
      })

      downloadCSV(response.data, `recovery_id_${recoveryId}`)
    } catch (err: CatchError) {
      return rejectWithValue(formatAxiosErrorToPayload(err))
    }
  },
)
export const carrierReservesSlice = createSlice({
  name: 'reservesRecoveries',
  initialState,
  reducers: {
    setSize(state, { payload }) {
      state.size = payload
    },
    setOffset(state, { payload }) {
      state.offset = payload
    },
    setFilters(state, { payload }) {
      state.filters = payload
    },
    setCarrierReservesRecoveries(state, { payload }) {
      state.count.reservesRecoveries = payload
    },
    setManualReserveEntry(
      state,
      { payload }: PayloadAction<Partial<CarrierReservesRecoveriesState['newManualReserveEntry']>>,
    ) {
      state.newManualReserveEntry = {
        ...state.newManualReserveEntry,
        ...payload,
      }
    },
    setCarrierReserves(state, { payload }) {
      state.count.reserves = payload
    },
    resetManualReserveEntry(state) {
      state.newManualReserveEntry = initialState.newManualReserveEntry
    },
    setNewRecovery(
      state,
      { payload }: PayloadAction<Partial<CarrierReservesRecoveriesState['newRecovery']>>,
    ) {
      state.newRecovery = {
        ...state.newRecovery,
        ...payload,
      }
    },
    setReservesSelectedFields(state, { payload }) {
      state.reservesSelectedFields =
        typeof payload === 'string'
          ? state.reservesSelectedFields.includes(payload)
            ? state.reservesSelectedFields.filter(field => field !== payload)
            : [...state.reservesSelectedFields, payload]
          : payload
    },
    setRecoveriesSelectedFields(state, { payload }) {
      state.recoveriesSelectedFields =
        typeof payload === 'string'
          ? state.recoveriesSelectedFields.includes(payload)
            ? state.recoveriesSelectedFields.filter(field => field !== payload)
            : [...state.recoveriesSelectedFields, payload]
          : payload
    },
    setRecoveryDetailsSelectedFields(state, { payload }) {
      state.recoveryDetailsSelectedFields =
        typeof payload === 'string'
          ? state.recoveryDetailsSelectedFields.includes(payload)
            ? state.recoveryDetailsSelectedFields.filter(field => field !== payload)
            : [...state.recoveryDetailsSelectedFields, payload]
          : payload
    },
  },
  extraReducers(builder) {
    builder
      .addCase(getCarrierReservesRecoveries.pending, state => {
        state.loading.reservesRecoveries = true
      })
      .addCase(getCarrierReservesRecoveries.rejected, state => {
        state.loading.reservesRecoveries = false
      })
      .addCase(getCarrierReservesRecoveries.fulfilled, (state, action) => {
        const { count, results } = action.payload

        state.reservesRecoveries = results
        state.count.reservesRecoveries = count
        state.loading.reservesRecoveries = false
      })
      .addCase(getCarrierReserves.fulfilled, (state, action) => {
        const { count, results } = action.payload
        state.reserves = keysToCamelCase(results) as CarrierReserves[]

        // Update the entryType for "Reserve Payment" to "Reserve Deposit"
        state.reserves.forEach(reserve => {
          if (reserve.entryType === 'Reserve Payment') {
            reserve.entryType = 'Reserve Deposit'
          }
        })

        state.count.reserves = count
        state.loading.reserves = false
      })
      .addCase(getCarrierRecoveries.fulfilled, (state, action) => {
        const { count, results } = action.payload
        state.recoveries = keysToCamelCase(results)
        state.count.recoveries = count
        state.loading.recoveries = false
      })
      .addCase(getCarrierReservesDashboard.fulfilled, (state, action) => {
        state.reservesDashboard = keysToCamelCase(action.payload)

        if (!state.reservesDashboard.totalReserved) {
          state.reservesDashboard.totalReserved = 0
        }

        if (!state.reservesDashboard.requiredReserveBalance) {
          state.reservesDashboard.requiredReserveBalance = 0
        }

        state.loading.reservesDashboard = false
      })
      .addCase(createManualReserveEntry.pending, state => {
        state.loading.createReserve = true
      })
      .addCase(createManualReserveEntry.fulfilled, state => {
        state.loading.createReserve = false
        toast.success('Reserve created successfully')
      })
      .addCase(createManualReserveEntry.rejected, (state, { payload }) => {
        state.loading.createReserve = false
        toast.error(getErrorString(payload, 'Error creating reserve'))
      })
      .addCase(createCarrierReservePayment.pending, state => {
        state.loading.createReserve = true
      })
      .addCase(createCarrierReservePayment.fulfilled, state => {
        state.loading.createReserve = false
        toast.success('Reserve created successfully')
      })
      .addCase(createCarrierReservePayment.rejected, (state, { payload }) => {
        state.loading.createReserve = false
        toast.error(getErrorString(payload, 'Error creating reserve'))
      })
      .addCase(getCarrierRecoveryDetails.pending, state => {
        state.loading.recovery = true
      })
      .addCase(getCarrierRecoveryDetails.fulfilled, (state, action) => {
        const amountToRecover = parseFloat(action.payload.amountToRecover)
        const amountPerFactoredLoad = parseFloat(action.payload.repaymentAmountPerLoad)
        const recoveryDetails: CarrierRecoveryDetail[] = action.payload.recoveries

        const estimatedFactoredLoads = Math.ceil(amountToRecover / amountPerFactoredLoad)

        action.payload.estimatedFactoredLoads = estimatedFactoredLoads

        state.recovery = action.payload
        state.loading.recovery = false

        // Handling recoveryDetails
        recoveryDetails.map((recovery, idx) => {
          recovery.id = idx

          recovery.hasAmountTooltip = recovery.amount < amountPerFactoredLoad
        })
        state.recoveryDetails = recoveryDetails
        state.count.recoveryDetails = recoveryDetails.length
      })
      .addCase(getCarrierAverageFactoredLoadInvoice.pending, state => {
        state.loading.createRecovery = true
      })
      .addCase(getCarrierAverageFactoredLoadInvoice.fulfilled, (state, { payload }) => {
        const averageInvoice = parseFloat(payload.averageInvoice)
        state.loading.createRecovery = false
        state.newRecovery = {
          ...state.newRecovery,
          averageInvoice,
        }
      })
      .addCase(getCarrierAverageFactoredLoadInvoice.rejected, state => {
        state.loading.createRecovery = false
      })
      .addCase(createCarrierRecovery.pending, state => {
        state.loading.createRecovery = true
      })
      .addCase(createCarrierRecovery.fulfilled, state => {
        state.loading.createRecovery = false
        toast.success('Recovery created successfully')
      })
      .addCase(createCarrierRecovery.rejected, (state, { payload }) => {
        state.loading.createRecovery = false
        toast.error(getErrorString(payload, 'Error creating recovery'))
      })
      .addCase(updateCarrierCreditDetails.fulfilled, (_, action) => {
        const arg = action.meta.arg

        if ('buyStatus' in arg) {
          toast.success('Successfully updated buy status')
        }

        if ('creditLimit' in arg) {
          toast.success('Successfully updated credit limit')
        }
      })
      .addCase(updateCarrierCreditDetails.rejected, (_, action) => {
        toast.error(getErrorString(action.payload, 'Failed to update carrier details'))
      })
      .addCase(getFactoringCreditDetails.pending, state => {
        state.loading.carrierCreditDetails = true
      })
      .addCase(getFactoringCreditDetails.fulfilled, (state, action) => {
        const availableCredit = action.payload.creditLimit - action.payload.utilizedCredit
        action.payload.availableCredit = availableCredit
        state.carrierCreditDetails = action.payload
        state.loading.carrierCreditDetails = false
      })
      .addCase(getFactoringCreditDetails.rejected, (_, action) => {
        toast.error(getErrorString(action.payload, 'Failed to get factoring credit details'))
      })
      .addCase(getCarrierCustomerRelationshipList.pending, state => {
        state.loading.carrierCustomerRelationshipList = true
      })
      .addCase(getCarrierCustomerRelationshipList.fulfilled, (state, action) => {
        const { count, results } = action.payload

        state.carrierCustomerRelationshipList = results
        state.count.carrierCustomerRelationshipList = count
        state.loading.carrierCustomerRelationshipList = false
      })
      .addCase(getCarrierCustomerRelationshipList.rejected, (state, { payload }) => {
        state.loading.carrierCustomerRelationshipList = false
        toast.error(getErrorString(payload, 'Failed to get carrier customer relationship'))
      })
      .addCase(updateCarrierFee.fulfilled, (_, action) => {
        const arg = action.meta.arg

        if ('flatFee' in arg) {
          toast.success('Successfully updated Flat Fee')
        }

        if ('achFee' in arg) {
          toast.success('Successfully updated ACH Fee')
        }

        if ('factoringFeePercentage' in arg) {
          toast.success('Successfully updated Factoring Percentage')
        }
      })
      .addCase(updateCarrierFee.rejected, (_, action) => {
        const arg = action.meta.arg

        if ('flatFee' in arg) {
          toast.error('Failed to update Flat Fee')
        }

        if ('achFee' in arg) {
          toast.error('Failed to update ACH Fee')
        }

        if ('factoringFeePercentage' in arg) {
          toast.error('Failed to update Factoring Percentage')
        }
      })
      .addCase(updateFactoringRelationshipDetails.fulfilled, (_, action) => {
        const arg = action.meta.arg
        if ('currentlyActive' in arg) {
          toast.success('Successfully updated buy status')
        }

        if ('creditLimit' in arg) {
          toast.success('Successfully updated credit limit')
        }
        if ('hasNoa' in arg) {
          toast.success('Successfully updated noa status')
        }
      })
      .addCase(updateFactoringRelationshipDetails.rejected, (_, action) => {
        toast.error(getErrorString(action.payload, 'Failed to update carrier relationship details'))
      })
      .addCase(exportReservesCSV.pending, state => {
        state.loading.reservesExportCSV = true
      })
      .addCase(exportReservesCSV.fulfilled, state => {
        state.loading.reservesExportCSV = false
        toast.success('Successfully exported CSV')
      })
      .addCase(exportReservesCSV.rejected, (state, { payload }) => {
        state.loading.reservesExportCSV = false
        toast.error(getErrorString(payload, 'Failed to export CSV'))
      })
      .addCase(exportRecoveriesCSV.pending, state => {
        state.loading.recoveriesExportCSV = true
      })
      .addCase(exportRecoveriesCSV.fulfilled, state => {
        state.loading.recoveriesExportCSV = false
        toast.success('Successfully exported CSV')
      })
      .addCase(exportRecoveriesCSV.rejected, (state, { payload }) => {
        state.loading.recoveriesExportCSV = false
        toast.error(getErrorString(payload, 'Failed to export CSV'))
      })
      .addCase(exportRecoveryDetailsCSV.pending, state => {
        state.loading.recoveryDetailsExportCSV = true
      })
      .addCase(exportRecoveryDetailsCSV.fulfilled, state => {
        state.loading.recoveryDetailsExportCSV = false
        toast.success('Successfully exported CSV')
      })
      .addCase(exportRecoveryDetailsCSV.rejected, (state, { payload }) => {
        state.loading.recoveryDetailsExportCSV = false
        toast.error(getErrorString(payload, 'Failed to export CSV'))
      })
  },
})

export const {
  setSize,
  setOffset,
  setFilters,
  setCarrierReserves,
  setCarrierReservesRecoveries,
  setManualReserveEntry,
  resetManualReserveEntry,
  setNewRecovery,
  setReservesSelectedFields,
  setRecoveriesSelectedFields,
  setRecoveryDetailsSelectedFields,
} = carrierReservesSlice.actions

export default carrierReservesSlice.reducer
