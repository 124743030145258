import tw from 'tailwind-styled-components'

export type AvailableItem = {
  id: string
  name: string
  remaining: number
}

type AvailableItemsProps = {
  items: AvailableItem[]
  className?: string
}

type ItemProps = {
  name: string
  remaining: number
}

type ContainerProps = {
  children: React.ReactNode
  itemCount: number
  className: string
}

export const AvailableItems = ({ items, className = '' }: AvailableItemsProps) => (
  <Container className={`flex-col relative overflow-hidden ${className}`} itemCount={items.length}>
    <Container className='gap-[20px] bg-light-blue py-4 px-6' itemCount={items.length}>
      <Accent />
      <div className={`${items.length ? 'h-full' : ''} w-full flex flex-col gap-3`}>
        <Title>Available Items</Title>
        {!!items.length && (
          <div className='w-full flex flex-col gap-2'>
            {items.map(item => (
              <Item key={item.id} {...item} />
            ))}
          </div>
        )}
      </div>
    </Container>
  </Container>
)

const Container = ({ children, itemCount, className }: ContainerProps) => (
  <div
    className={`w-[247px] min-w-[247px] flex items-center rounded-lg ${className}`}
    style={{ height: getContainerHeight(itemCount) }}
  >
    {children}
  </div>
)

const Item = ({ name, remaining }: ItemProps) => {
  const pluralPrefix = remaining > 1 || remaining === 0 ? 's' : ''
  const remainingText = `${remaining} item${pluralPrefix} remaining`

  return (
    <div className='w-full flex justify-between'>
      <ItemText>{name}</ItemText>
      <ItemText>{remainingText}</ItemText>
    </div>
  )
}

const Accent = tw.div`
  absolute
  left-0
  right-0
  top-0
  bottom-0
  bg-link
  rounded-lg
  w-1
`

const Title = tw.span`
  font-bold
  text-dark-blue
  text-base
`

const ItemText = tw.span`
  text-xs
  text-dark-blue
  font-sans
`

const getContainerHeight = (itemCount: number) => `${28 * (itemCount - 1) + 92}px`
