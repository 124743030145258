import { ArrowLongUpIcon } from '@heroicons/react/24/solid'
import tw from 'tailwind-styled-components'

import { useAppSelector, useAppThunkDispatch } from '../../../app/hooks'
import { ContractLaneCarrier } from '../../../common/types/contractLane'
import { formatPhone } from '../../../common/utils'
import { updateContractLaneCarrierOrder } from '../../../redux/contractLanesSlice'
import { DetailItem } from '../../DetailItem'

export const CarrierView = ({
  carrier,
  isFirst,
  isLast,
}: {
  carrier: ContractLaneCarrier
  isFirst: boolean
  isLast: boolean
}) => {
  const dispatch = useAppThunkDispatch()

  const loading = useAppSelector(state => state.contractLane.loading.updateContractLaneCarrierOrder)

  const handleReorder = (direction: 'UP' | 'DOWN') =>
    dispatch(updateContractLaneCarrierOrder({ id: carrier.id, direction }))

  return (
    <>
      <div className='grid grid-cols-2'>
        <DetailItem label='MC Number'>{carrier.carrier.mcNumber}</DetailItem>
        <DetailItem label='DOT Number'>{carrier.carrier.dotNumber}</DetailItem>
      </div>
      <DetailItem label='Dispatch Name'>{carrier.dispatchContact?.name}</DetailItem>
      <div className='grid grid-cols-2'>
        <DetailItem label='Dispatch Email'>{carrier.dispatchContact?.email}</DetailItem>
        <DetailItem label='Dispatch Phone'>
          {formatPhone(carrier.dispatchContact?.phone)}
        </DetailItem>
      </div>
      <div className='flex justify-end mt-1'>
        {!isFirst && <Arrow $loading={loading} onClick={() => handleReorder('UP')} />}
        {!isLast && (
          <Arrow $loading={loading} className='rotate-180' onClick={() => handleReorder('DOWN')} />
        )}
      </div>
    </>
  )
}

const Arrow = tw(ArrowLongUpIcon)`
  text-dark-blue
  w-5
  h-5
  hover:text-link
  transition-all
  cursor-pointer
  ${({ $loading }: { $loading: boolean }) =>
    $loading && 'cursor-not-allowed hover:text-dark-blue opacity-70'}
`
