import { pluralizeNoun } from '@common'
import { Button } from '@components'
import { CheckCircleIcon, InformationCircleIcon } from '@heroicons/react/24/solid'
import tw from 'tailwind-styled-components'

import { useAppSelector, useAppThunkDispatch } from '../../../../app/hooks'
import { setContractLaneId, setContractLaneModalVisible } from '../../../../redux/loadsSlice'

export const ContractLaneBanner = () => {
  const dispatch = useAppThunkDispatch()

  const customerContractLanes = useAppSelector(state => state.contractLane.customerContractLanes)
  const contractLaneId = useAppSelector(state => state.loads.contractLaneId)

  const Icon = contractLaneId ? CheckCircleIcon : InformationCircleIcon

  const text = contractLaneId
    ? `You've selected contract lane #${contractLaneId}`
    : `This customer has ${customerContractLanes.length} contract
    ${pluralizeNoun(customerContractLanes.length, 'lane')}`

  if (!customerContractLanes.length) return null

  return (
    <Banner $selected={!!contractLaneId}>
      <div className='flex items-center gap-2'>
        <Icon className='w-5' />
        {text}
      </div>
      <Button
        type='link'
        noBackground={!!contractLaneId}
        onClick={() =>
          contractLaneId
            ? dispatch(setContractLaneId(null))
            : dispatch(setContractLaneModalVisible(true))
        }
        {...(contractLaneId && { innerClassName: '!border-none' })}
      >
        {contractLaneId ? 'Deselect Lane' : 'Select Contract Lane'}
      </Button>
    </Banner>
  )
}

const Banner = tw.div<{ $selected: boolean }>`
  rounded-md
  border-l-4
  p-4
  flex
  items-center
  justify-between
  font-semibold
  text-sm
  mb-4
  ${({ $selected }) =>
    $selected
      ? 'bg-light-green-bg border-success text-success'
      : 'border-link bg-light-blue-bg text-link'}
`
