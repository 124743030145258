import { CatchError, formatAxiosErrorToPayload } from '@common'
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { toast } from 'react-toastify'

import { api } from '../api/api'
import { initialFilters } from '../common/constants'
import { ActiveCapacityListItem, RootState, SearchFilters } from '../common/types'
import { cleanFilters, formatDateForBackend, keysToCamelCase } from '../common/utils'

type CapacityState = {
  activeCapacity: Array<ActiveCapacityListItem>
  activeCapacityCount: number
  loading: {
    getActiveCapacity: boolean
    getReloads: boolean
  }
  offset: number
  size: number
  filters: SearchFilters
  reloads: Array<{
    id: number
    pickupDate: string
    equipmentType: string
    shipper: {
      city: string
      stateProvinceRegion: string
    }
    consignee: {
      city: string
      stateProvinceRegion: string
    }
    miles: number
    carrierStartBuy: number
    deadheadMiles: number
  }>
  reloadCount: number
}

const initialState: CapacityState = {
  activeCapacity: [],
  activeCapacityCount: 0,
  loading: {
    getActiveCapacity: false,
    getReloads: false,
  },
  filters: initialFilters,
  offset: 0,
  size: 50,
  reloads: [],
  reloadCount: 0,
}

const getFilters = (filters: SearchFilters) => {
  let startDate = null
  let endDate = null
  if (filters.dateRange) {
    startDate = formatDateForBackend(filters.dateRange[0] || null)
    endDate = formatDateForBackend(filters.dateRange[1] || null)
  }
  return cleanFilters({
    equipment_type: filters.equipmentType,
    origin_lat: filters?.originCityAutocomplete?.latitude,
    origin_lon: filters?.originCityAutocomplete?.longitude,
    destination_lat: filters?.destinationCityAutocomplete?.latitude,
    destination_lon: filters?.destinationCityAutocomplete?.longitude,
    start_date: startDate,
    end_date: endDate,
  })
}

export const getActiveCapacity = createAsyncThunk(
  'capacity/getActiveCapacity',
  async (_, { getState }) => {
    const { filters, size = 50, offset = 0 } = (getState() as RootState).capacity

    const response = await api.get('/carrier-matching/active-internal-capacity/', {
      params: {
        limit: size,
        offset,
        archived: false,
        ...getFilters(filters),
      },
    })

    return keysToCamelCase(response.data)
  },
)

export const getReloads = createAsyncThunk(
  'capacity/getReloads',
  async ({ loadId }: { loadId: number }, { rejectWithValue }) => {
    try {
      const response = await api.get('/carrier-matching/reloads/', {
        params: { limit: 50, offset: 0, load_id: loadId },
      })
      return keysToCamelCase(response.data)
    } catch (err: CatchError) {
      return rejectWithValue(formatAxiosErrorToPayload(err))
    }
  },
)

const capacitySlice = createSlice({
  name: 'capacity',
  initialState,
  reducers: {
    setSize(state, { payload }) {
      state.size = payload
    },
    setOffset(state, { payload }) {
      state.offset = payload
    },
    setFilters(state, { payload }) {
      state.filters = payload
    },
    resetActiveCapacity(state) {
      state.activeCapacity = []
      state.activeCapacityCount = 0
    },
    resetReloadsList(state) {
      state.reloads = []
      state.reloadCount = 0
    },
  },
  extraReducers(builder) {
    builder
      .addCase(getActiveCapacity.pending, state => {
        state.loading.getActiveCapacity = true
      })
      .addCase(getActiveCapacity.fulfilled, (state, action) => {
        const { count, results } = action.payload
        state.activeCapacity = keysToCamelCase(results)
        state.activeCapacityCount = count
        state.loading.getActiveCapacity = false
      })
      .addCase(getActiveCapacity.rejected, state => {
        state.loading.getActiveCapacity = false
        toast.error('Failed to fetch active capacity.')
      })
      .addCase(getReloads.pending, state => {
        state.loading.getReloads = true
      })
      .addCase(getReloads.fulfilled, (state, action) => {
        const { results, count } = action.payload
        state.loading.getReloads = false
        state.reloads = results
        state.reloadCount = count
      })
      .addCase(getReloads.rejected, state => {
        state.loading.getReloads = false
        state.reloadCount = 0
        toast.error('Error getting reloads')
      })
  },
})

export const { resetActiveCapacity, setFilters, setOffset, setSize, resetReloadsList } =
  capacitySlice.actions
export default capacitySlice.reducer
