import axios from 'axios'
import axiosBetterStacktrace from 'axios-better-stacktrace'

axiosBetterStacktrace(axios)

// for local, we use a proxy, so we don't need to set the base url
axios.defaults.baseURL = import.meta.env.VITE_BASE_URL
axios.defaults.withCredentials = true
axios.defaults.withXSRFToken = true
axios.defaults.xsrfCookieName = 'csrftoken3'
axios.defaults.xsrfHeaderName = 'X-CSRFToken'

export { axios as api }
