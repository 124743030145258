import { TextArea } from '@components'

import { useAppSelector, useAppThunkDispatch } from '../../../app/hooks'
import { initialNewAccessorial } from '../../../common/constants'
import { NewAccessorial } from '../../../common/types'
import { randomString, useCustomerType } from '../../../common/utils'
import { setCustomerInfo } from '../../../redux/customersSlice'
import { Accessorial, AddButton, SectionHeader } from '../components'

export const Accessorials = () => {
  const type = useCustomerType()

  const dispatch = useAppThunkDispatch()

  const accessorialsStep = useAppSelector(state => state.customers[type].accessorialsStep)

  const { accessorials, process } = accessorialsStep

  const setAccessorials = (newData: { accessorials: NewAccessorial[] } | { process: string }) =>
    dispatch(setCustomerInfo({ accessorialsStep: { ...accessorialsStep, ...newData } }))

  const addNewAccessorial = () =>
    setAccessorials({
      accessorials: [
        ...accessorials,
        {
          ...initialNewAccessorial,
          id: randomString(),
          isValid: false,
        },
      ],
    })

  return (
    <>
      <SectionHeader>accessorial information</SectionHeader>
      <TextArea
        sm
        value={process}
        label='Accessorial Process'
        onChange={process => setAccessorials({ ...accessorials, process })}
      />
      <SectionHeader className='mt-10'>accessorials</SectionHeader>
      {accessorials.map((accessorial, i) => (
        <Accessorial key={accessorial.id} accessorial={accessorial} index={i} />
      ))}
      <AddButton onClick={addNewAccessorial}>Add New Accessorial</AddButton>
    </>
  )
}
