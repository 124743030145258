import { CatchError, formatAxiosErrorToPayload, getErrorString } from '@common'
import { downloadCSV } from '@common'
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { toast } from 'react-toastify'

import { api } from '../api/api'
import { initialFilters } from '../common/constants'
import {
  CSVField,
  LoadDocument,
  PayableLoad,
  RootState,
  SearchFilters,
  TableOrder,
} from '../common/types'
import {
  booleanFromYesNoString,
  cleanFilters,
  formatRowsForNavigation,
  getOrderingString,
  keysToCamelCase,
  keysToSnakeCase,
} from '../common/utils'

type PayablesState = {
  payables: Array<PayableLoad>
  loading: {
    payables: boolean
    sendPayment: boolean
    getInvoicePreview: boolean
    exportCSV: boolean
  }
  count: number
  offset: number
  size: number
  order: TableOrder
  filters: SearchFilters
  invoicePreviewPDF: LoadDocument | null
  selectedDocuments: Array<number>
  selectedContacts: Array<number>
  currentPayable?: PayableLoad | null
  restartAging: boolean
  selectedFields: Array<string>
  exportFields: CSVField[]
}

const initialState: PayablesState = {
  payables: [],
  loading: {
    payables: false,
    sendPayment: false,
    getInvoicePreview: false,
    exportCSV: false,
  },
  count: 0,
  offset: 0,
  size: 50,
  order: { label: '', direction: '', key: '' },
  filters: initialFilters,
  invoicePreviewPDF: null,
  selectedDocuments: [],
  selectedContacts: [],
  currentPayable: null,
  restartAging: false,
  selectedFields: [
    'load_id',
    'customer',
    'po_number',
    'invoice_amount',
    'delivered_date',
    'invoiced_date',
  ],
  exportFields: [
    { label: 'Load ID', key: 'load_id' },
    { label: 'Customer Ref. ID', key: 'customer_reference_id' },
    { label: 'Customer', key: 'customer' },
    { label: 'PO Number', key: 'po_number' },
    { label: 'Invoice Date', key: 'invoiced_date' },
    { label: 'Invoice Amount', key: 'invoice_amount' },
    { label: 'Delivered Date', key: 'delivered_date' },
    { label: 'Received Date', key: 'received_at' },
    { label: 'Origin', key: 'origin' },
    { label: 'Destination', key: 'destination' },
  ],
}

export const getInvoicePreview = createAsyncThunk(
  'invoicing/getInvoicePreview',
  async (id: number) =>
    api.get(`/billing/api/customer-invoice-preview/${id}/`).then(({ data }) => {
      const blob = new Blob([data], { type: 'application/pdf' })
      const file = new File([blob], `customer-invoice-${id}.pdf`, { type: 'application/pdf' })

      return {
        id: -1,
        file: URL.createObjectURL(file),
        fileName: file.name,
        documentTypeDisplay: 'Customer Invoice',
        timeStamp: new Date().toISOString(),
      }
    }),
)

export const sendPayment = createAsyncThunk(
  'invoicing/sendPayment',
  async (payload: { loadId: number }, { dispatch, rejectWithValue, getState }) => {
    const { selectedDocuments, selectedContacts, restartAging } = (getState() as RootState)
      .invoicing

    const selectedPayload = {
      ...payload,
      documentIds: selectedDocuments,
      contactIds: selectedContacts,
      isRestartAging: restartAging,
    }

    try {
      const response = await api.post(
        '/billing/api/process-load-invoice/',
        keysToSnakeCase(selectedPayload),
      )
      dispatch(getInvoicingLoads({}))
      return response.data
    } catch (err: CatchError) {
      return rejectWithValue(formatAxiosErrorToPayload(err))
    }
  },
)

export const getInvoicingLoads = createAsyncThunk(
  'invoicing/getInvoicingLoads',
  async ({ type }: { type?: string }, { getState }) => {
    const {
      filters,
      size = 50,
      offset = 0,
      order: { label, direction, key },
    } = (getState() as RootState).invoicing
    const ordering = getOrderingString(label, direction, key, 'delivered_date')
    const response = await api.get('/billing/api/load-customer-invoice/', {
      params: {
        limit: size,
        offset,
        ordering,
        load_status__in: filters.loadStatuses?.join(',') || '21,22',
        is_invoiced: filters.isInvoiced,
        is_received: filters.isReceived,
        po_number__icontains: filters.poNumber || null,
        shipper__city__icontains: filters.originCity || null,
        shipper__state_province_region__in: filters.originState?.replaceAll(' ', ',') || null,
        consignee__city__icontains: filters.destinationCity || null,
        consignee__state_province_region__in: filters.destinationState?.replace(' ', ',') || null,
        customer_company: filters.customerCompany || null,
        id: filters.loadId || null,
        customer_reference_id__icontains: filters.refId || null,
        customer_company__accounting_contact: filters.accountingContact || null,
        customer_company__accounting_contact__isnull: filters.noAccountingContact ? true : null,
        hold: booleanFromYesNoString(filters.hold),
        order_type__in: filters.orderTypes?.join(',') || null,
      },
    })
    return keysToCamelCase({ ...response.data, type })
  },
)

const getFilters = (filters: SearchFilters, returnString = true) => {
  const formatStates = (states = '') => {
    const statesList = states.replaceAll(' ', ',').split(',').filter(Boolean)
    return statesList.length ? (returnString ? statesList.join(',') : statesList) : undefined
  }

  const formatArray = (array?: (string | number)[]) =>
    returnString
      ? array?.join(',')
      : array?.map((el: number | string) => (!isNaN(Number(el)) ? Number(el) : el))

  return cleanFilters({
    load_status__in: formatArray(filters.loadStatuses || [21, 22]),
    is_invoiced: filters.isInvoiced,
    is_received: filters.isReceived,
    id: filters.loadId,
    customer_company__id: filters.customerCompany,
    po_number__icontains: filters.poNumber,
    customer_reference_id__icontains: filters.refId,
    shipper__city__icontains: filters.originCity,
    shipper__state_province_region__in: formatStates(filters.originState),
    consignee__city__icontains: filters.destinationCity,
    consignee__state_province_region__in: formatStates(filters.destinationState),
    hold: booleanFromYesNoString(filters.hold),
  })
}

export const exportLoadInvoiceCSV = createAsyncThunk(
  'loads/exportLoadInvoiceCSV',
  async (_, { getState, rejectWithValue }) => {
    const { filters, selectedFields } = (getState() as RootState).invoicing

    try {
      const response = await api.post('/loads/api/export-load-invoice-data/', {
        filters: {
          ...getFilters(filters, false),
        },
        fields: selectedFields.join(','),
      })

      downloadCSV(response.data, 'load-invoices')
    } catch (err: CatchError) {
      return rejectWithValue(formatAxiosErrorToPayload(err))
    }
  },
)

const invoicingSlice = createSlice({
  name: 'invoicing',
  initialState,
  reducers: {
    setPayables(state, { payload }) {
      state.payables = payload
    },
    setSize(state, { payload }) {
      state.size = payload
    },
    setOffset(state, { payload }) {
      state.offset = payload
    },
    setFilters(state, { payload }) {
      state.filters = {
        ...state.filters,
        ...payload,
      }
    },
    setOrder(state, { payload }) {
      state.order = payload
    },
    setSelectedDocuments(state, { payload }) {
      state.selectedDocuments = payload
    },
    setSelectedContacts(state, { payload }) {
      state.selectedContacts = payload
    },
    setCurrentPayable(state, { payload }) {
      state.currentPayable = state.payables.find(payable => payable.id === payload)
    },
    setRestartAging(state, { payload }) {
      state.restartAging = payload
    },
    setSelectedFields(state, { payload }) {
      state.selectedFields =
        typeof payload === 'string'
          ? state.selectedFields.includes(payload)
            ? state.selectedFields.filter(field => field !== payload)
            : [...state.selectedFields, payload]
          : payload
    },
  },
  extraReducers(builder) {
    builder
      .addCase(sendPayment.pending, state => {
        state.loading.sendPayment = true
      })
      .addCase(sendPayment.fulfilled, state => {
        state.loading.sendPayment = false
        toast.success('Successfully sent invoice')
      })
      .addCase(sendPayment.rejected, (state, action) => {
        toast.error(getErrorString(action.payload, 'Failed to send payment'))
        state.loading.sendPayment = false
      })
      .addCase(getInvoicePreview.pending, state => {
        state.loading.getInvoicePreview = true
      })
      .addCase(getInvoicePreview.fulfilled, (state, { payload }) => {
        state.loading.getInvoicePreview = false
        state.invoicePreviewPDF = payload as unknown as LoadDocument
      })
      .addCase(getInvoicePreview.rejected, (state, action) => {
        toast.error(getErrorString(action.payload, 'Failed to get invoicing details'))
        state.loading.getInvoicePreview = false
      })
      .addCase(getInvoicingLoads.pending, state => {
        state.loading.payables = true
      })
      .addCase(getInvoicingLoads.fulfilled, (state, { payload }) => {
        const { count, results, type } = payload
        const payables = formatRowsForNavigation(results, state.offset)
        state.payables = payables
        state.count = count
        state.loading.payables = false
        if (type) state.currentPayable = payables[type === 'prev' ? state.payables.length - 1 : 0]
      })
      .addCase(getInvoicingLoads.rejected, state => {
        toast.error('Failed to get invoicing loads')
        state.loading.payables = false
      })
      .addCase(exportLoadInvoiceCSV.pending, state => {
        state.loading.exportCSV = true
      })
      .addCase(exportLoadInvoiceCSV.fulfilled, state => {
        state.loading.exportCSV = false
        toast.success('Successfully exported CSV')
      })
      .addCase(exportLoadInvoiceCSV.rejected, (state, { payload }) => {
        state.loading.exportCSV = false
        toast.error(getErrorString(payload, 'Failed to export CSV'))
      })
  },
})

export const {
  setPayables,
  setSize,
  setOffset,
  setFilters,
  setOrder,
  setSelectedDocuments,
  setSelectedContacts,
  setCurrentPayable,
  setRestartAging,
  setSelectedFields,
} = invoicingSlice.actions

export default invoicingSlice.reducer
