import { Menu } from '@headlessui/react'
import { PlusCircleIcon } from '@heroicons/react/24/solid'
import React, { useState } from 'react'
import tw from 'tailwind-styled-components'

import { useAppSelector, useAppThunkDispatch } from '../../app/hooks'
import { setCreateModalVisible } from '../../redux/customersSlice'
import { setCreateLoadModalVisible } from '../../redux/loadsSlice'
import { SelectContractLaneModal } from '../ContractLanes/components'
import {
  CreateCapacityModal,
  CreateCarrierModal,
  CreateCustomerModal,
  CreateFactoringCompanyModal,
  CreateLoadModal,
  CreateLocationModal,
} from '../CreateModals'
import { EaseInOutTransition } from '../EaseInOutTransition'

export const MainMenu = () => {
  const customerProps = useCustomerModalDisclosure()
  const { onOpen: onCustomerOpen, isVisible: customerIsVisible } = customerProps
  const factoringProps = useModalDisclosure()
  const { onOpen: onFactoringOpen, isVisible: factoringIsVisible } = factoringProps
  const carrierProps = useModalDisclosure()
  const { onOpen: onCarrierOpen, isVisible: carrierIsVisible } = carrierProps
  const loadProps = useLoadModalDisclosure()
  const { onOpen: onLoadOpen, isVisible: loadIsVisible } = loadProps
  const locationProps = useModalDisclosure()
  const { onOpen: onLocationOpen, isVisible: locationIsVisible } = locationProps
  const capacityProps = useModalDisclosure()
  const { onOpen: onCapacityOpen, isVisible: capacityIsVisible } = capacityProps

  return (
    <>
      <MenuContainer as='div'>
        <Menu.Button>
          <div
            className='bg-brand-accent rounded-lg flex items-center justify-center h-10 w-10 hover:opacity-70 transition-all'
            data-testid='circle-icon'
          >
            <PlusCircleIcon className='text-dark-blue w-6 h-6' />
          </div>
        </Menu.Button>
        <EaseInOutTransition>
          <Dropdown>
            <MenuSection>
              <MenuHeader>General</MenuHeader>
              <MenuItem onClick={onCapacityOpen}>Capacity</MenuItem>
              <MenuItem onClick={onLoadOpen}>Load</MenuItem>
              <MenuItem onClick={onLocationOpen}>Location</MenuItem>
            </MenuSection>
            <MenuDivider />
            <MenuSection>
              <MenuHeader>Account</MenuHeader>
              <MenuItem onClick={onCustomerOpen}>Customer</MenuItem>
              <MenuItem onClick={onCarrierOpen}>Carrier</MenuItem>
              <MenuItem onClick={onFactoringOpen}>Factoring</MenuItem>
            </MenuSection>
            <MenuDivider />
            <MenuSection>
              <MenuHeader>Admin</MenuHeader>
              <MenuItem $disabled>User</MenuItem>
              <MenuItem $disabled>Group</MenuItem>
              <MenuItem $disabled>Contract</MenuItem>
            </MenuSection>
          </Dropdown>
        </EaseInOutTransition>
      </MenuContainer>
      {customerIsVisible && <CreateCustomerModal />}
      {factoringIsVisible && <CreateFactoringCompanyModal {...factoringProps} />}
      {carrierIsVisible && <CreateCarrierModal {...carrierProps} />}
      {loadIsVisible && <CreateLoadModal {...loadProps} />}
      {locationIsVisible && <CreateLocationModal {...locationProps} />}
      {capacityIsVisible && <CreateCapacityModal {...capacityProps} />}
      <SelectContractLaneModal />
    </>
  )
}

const Dropdown = tw(Menu.Items)`
  origin-top-right
  mt-2
  absolute
  -right-[339%]
  lg:right-0
  lg:h-44
  w-screen
  lg:w-96
  bg-lighter-blue
  z-10
  rounded-lg
  shadow-md
  p-6
  flex
  justify-between
  cursor-default
`

const MenuContainer = tw(Menu)`
  ml-4
  relative
  inline-block
  text-left
  z-[9]
`

const MenuHeader = tw.span`
  text-link
  uppercase
  text-xs
  font-semibold
  font-sans
`

const MenuItem = tw.a<{ $disabled?: boolean }>`
  text-dark-blue
  font-semibold
  font-sans
  uppercase
  text-sm
  cursor-pointer
  hover:opacity-70
  ${({ $disabled }) => $disabled && 'text-dark-gray cursor-not-allowed hover:opacity-100'}

`

const MenuSection = tw.div`
  h-full
  flex
  flex-col
  gap-4
`

const MenuDivider = tw.div`
  h-full
  border
  border-light-blue
`

const useLoadModalDisclosure = () => {
  const dispatch = useAppThunkDispatch()
  const { createLoadModalVisible } = useAppSelector(state => state.loads)
  const setVisible = (visible: boolean) => dispatch(setCreateLoadModalVisible(visible))
  const isVisible = createLoadModalVisible

  const onOpen = () => setVisible(true)

  return {
    isVisible,
    setVisible,
    onOpen,
  }
}

const useCustomerModalDisclosure = () => {
  const dispatch = useAppThunkDispatch()
  const isCreateModalVisible = useAppSelector(state => state.customers.isCreateModalVisible)
  const setVisible = (visible: boolean) => dispatch(setCreateModalVisible(visible))
  const isVisible = isCreateModalVisible

  const onOpen = () => setVisible(true)

  return {
    isVisible,
    setVisible,
    onOpen,
  }
}

export const useModalDisclosure = () => {
  const [isVisible, setVisible] = useState(false)

  const onOpen = () => setVisible(true)

  return {
    isVisible,
    setVisible,
    onOpen,
  }
}
