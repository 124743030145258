import { CityLocation } from '@common'
import { AsyncThunkAction, PayloadAction } from '@reduxjs/toolkit'

import { carrierCallOutcomeChoices } from '../constants'
import { DbLocation, DBQuoteStop, QuoteStop, QuotingLocation } from './location'
import { NewAccessorial } from './tender'

export type SidebarLink = {
  icon: JSX.Element
  url: string
  label: string
  external?: boolean
  accounting?: boolean
  featureFlag?: string
}

export type TableOrder = {
  direction: string
  key: string
  label: string
}

export type Note = {
  id: number | string
  createdAt: Date | string
  createdByDisplay: string
  note: string
  updatedAt: Date | string
  subject: string
}

export type UnitType = {
  id: number
  name: string
}

export type SelectValue = {
  id: number
  label?: string
  name: string
  value?: string
}

export type ActionHandler<P> = (params: P) => AsyncThunkAction<any, P, {}>
export type PayloadActionHandler = (params: any) => PayloadAction<any, string>

export type ReceivableCarrier = {
  paymentType: number
  carrier: any
  factoringCompany: any
  remitEmail: string
  payTerms: string
  quickbooksId: string
  carrierName: string
  carrierAddress: any
  id: number | null
  carrierARName: string
  phone: string
  email: string
  contactName: string
}

export type QuotingLocationItem = {
  isValid?: boolean
  location: QuotingLocation | null
  stopType?: { id: number; label: string }
  id?: string
}

export type MultiStopQuotingParams = {
  origin: QuotingLocationItem
  destination: QuotingLocationItem
  stops?: QuotingLocationItem[]
  equipmentType: string
  miles?: number
  carrierPrice?: string | number
  pickupDate?: string
  loadId?: number // Used for Capacity search
}

export type EDI = {
  id: number
  createdAt: string
  updatedAt: string
  businessNumber: string
  customerReferenceId: string
  pickupDate: string
  deliveryDate: string
  originCity: string
  originState: string
  destinationCity: string
  destinationState: string
  multiStop: boolean
  isPrimary: boolean
  loadId: number
  purpose: string
  status: string
  customerCompany: string
  equipmentType: string
  stops: number
}

export type SearchFilters = {
  // must be or contain strings to be compatible with query strings
  query?: string
  status?: string
  equipmentType?: string
  origin?: string
  destination?: string
  originCityAutocomplete?: CityLocation | null
  destinationCityAutocomplete?: CityLocation | null
  dateRange?: Array<string | null>
  ediStatuses?: Array<string>
  miles?: string
  loadId?: string
  bookable?: boolean | null
  loadStatuses?: Array<string>
  orderTypes?: Array<string>
  freightOrderTypes?: Array<string>
  archived?: boolean
  factoringLoads?: boolean
  userId?: string
  customerCompany?: string | null
  carrierHaulingStatus?: string
  carrierBlacklist?: boolean
  carrier?: string
  refId?: string
  poNumber?: string
  puNumber?: string
  pickupDate?: any
  deliveryDate?: any
  orderType?: string
  commodity?: string
  hold?: 'Yes' | 'No' | null
  carrierInvoiceNumber?: string
  carrierName?: string
  mcNumber?: string
  dotNumber?: string
  scacNumber?: string
  haulingStatus?: string
  blacklist?: boolean
  locationName?: string
  checkId?: string
  payment?: string | null
  paymentMethod?: string | null
  paymentType?: string | null
  paymentStatus?: string | null
  checkNumber?: string
  name?: string
  email?: string
  phone?: string
  state?: string
  city?: string
  originCity?: string
  originState?: string
  destinationCity?: string
  destinationState?: string
  loadFactoringRequestStatus?: string | null
  carrierFactoringRequestStatus?: string | null
  accountOwner?: string
  accountManager?: string
  parentCompany?: string | null
  customerName?: string
  deliveryNumber?: string
  csrList?: Array<string>
  customerCompaniesList?: Array<string>
  accountingContactsList?: Array<string>
  accountOwnersList?: Array<string>
  accountManagersList?: Array<string>
  customerQuoteStatus?: string
  quoteId?: string
  dateQuoted?: Array<string | null>
  createdBy?: string | null
  autobidShowOnly?: string | null
  isInvoiced?: boolean
  isReceived?: boolean
  recipient?: string
  datePaid?: Array<string | null>
  quickPay?: boolean
  isBilled?: boolean
  isPaid?: boolean
  hasCustomerInvoice?: 'Yes' | 'No' | null
  hasCarrierInvoice?: 'Yes' | 'No' | null
  dueDate?: Array<string | null>
  hasPayableDueDate?: boolean
  loadPaymentType?: string | null
  hasBOL?: 'Yes' | 'No' | null
  batchPricingUploadId?: string
  fileName?: string
  pickupToday?: boolean
  pickupTodayPlusMinusThree?: boolean
  deliveryToday?: boolean
  deliveryTodayPlusMinusThree?: boolean
  shipperPickupNumber?: string
  showInternalQuotes?: 'Any' | 'Non-EXO Users' | null
  invoiceAge?: string
  invoiceStatus?: string | null
  invoiceType?: string | null
  callbackDate?: Array<string | null>
  accountingContact?: string
  noAccountingContact?: boolean
  modes?: Array<string>
  quoteStatus?: Array<string>
  pickupTime?: string[]
  deliveryTime?: string[]
  loadTags?: string[]
  id?: string
  releasedCarriers?: boolean
  exoLoad?: 'Yes' | 'No' | null
  factoringSubmissionType?: string | null
}

export enum NotificationReason {
  NewEdiLoad = 'New EDI Load',
  EdiRequest = 'EDI Request',
  LoadTracking = 'Load Tracking',
  CarrierQuote = 'Carrier Quote',
  ContractLane = 'Contract Lane',
}

export type Notification = {
  id: number
  createdAt: string
  updatedAt: string
  data: {
    title: string
    pickupData: {
      subtitle?: string
      loc?: string
      dateMessage?: string
      date?: string
    }
    dropoffData: {
      subtitle?: string
      loc?: string
      dateMessage?: string
      date?: string
    }
    loadId?: number
    customerReferenceId?: string
  }
  recipient: any
  seen: boolean
  read: boolean
  reason: NotificationReason
}

export type PresetActionType = 'created' | 'updated' | 'deleted' | undefined

export type NewQuote = {
  id?: string | null | number
  equipmentType?: string
  origin?: QuotingLocation | null
  destination?: QuotingLocation | null
  customer?: {
    id: string | number
    text?: string
    selectedText?: string
  }
  stops?: Array<QuoteStop>
  carrierPrice?: number
  gross?: string
  accessorials?: Array<NewAccessorial>
  commodity?: {
    commodity?: string
    quantity?: string
    weight?: string
    temp?: string
    length?: string
    width?: string
    height?: string
    description?: string
  } | null
  notes?: string
  createdAt?: Date | string
  customerTotal?: string | number
  pickupDate?: Date | string | null
  deliveryDate?: Date | string | null
}

export type QuoteDetails = {
  id: number
  customerCompany: number
  customerCompanyName: string
  equipmentType: string
  originCity: string
  originState: string
  destinationCity: string
  destinationState: string
  carrierPrice: string
  customerPrice: string
  notes: string
  pieces: number
  description: string
  commodity: string
  length: string
  width: string
  height: string
  weight: string
  temperature: string
  manualquoteaccessorialsSet: Array<DBQuoteAccessorial>
  manualquotestopsSet: Array<DBQuoteStop>
  createdBy: string
  status: string
  rejectReason: string
  createdAt: string
  loadId: number
  pickupDate?: string
  isODPending?: boolean
  miles?: number
  destinationLat?: number
  destinationLon?: number
  originLat?: number
  originLon?: number
  shipperQuoteRequestId?: number | null
  files?: ManualQuoteFile[]
  loadDetails?: ManualQuoteLoadDetails
  changedLoadDetails?: ManualQuoteLoadDetails
  comments?: ManualQuoteComment[]
  createdByPhone?: string
  changedCustomerPrice?: string
  commodityDetails?: ManualQuoteCommodity
  changedCommodity?: ManualQuoteCommodity
}

export type ManualQuoteCommodity = {
  loadWidth?: string
  loadLength?: string
  loadWeight?: string
  loadHeight?: string
  weight?: string
  temperature?: string
  length?: string
  width?: string
  height?: string
  commodity?: string
  description?: string
}

export type ManualQuoteComment = {
  id: number
  createdBy: string
  createdAt: string
  text: string
  isPinned: boolean
  author: number
  manualQuote: number
}

export type ManualQuoteLoadDetails = {
  customerCompany: number
  customerCompanyName: string
  equipmentType: string
  isInsuredByCustomer: boolean
  insuranceValue: string
  pieces: string
  pickupDate: string
}

export type ManualQuoteFile = {
  id: number
  fileName: string
  createdAt: string
  updatedAt: string
  file: string
  fileType: string
  manualQuote: number
  uploadedBy: number
}

export type DBQuoteAccessorial = {
  id: number
  accessorialType: number
  accessorialTypeName: string
  amount: string
  quantity: string
  reason: string
  chargeType: string
}

export type TMSEvent = {
  event: string
  [key: string]: any
}

export type InternalBulkQuote = {
  id: number
  createdAt: string
  errors: Array<string>
  numLanes: number
  lanesProcessed: number
  fileName: string
  uploadFile: string
  processedFile?: string
  status: string
  customerCompany: {
    id: number
    name: string
  }
  submittedBy: {
    id: number
    name: string
  }
}

export type WizardStep = {
  label: string
  required?: boolean
  isValid?: boolean
  isCompleted?: boolean
  component?: JSX.Element
}

export type CSVField = {
  label: string
  key: string
  fields?: Array<{ label: string; key: string }>
}

export type InvoiceStatus = {
  label: string
  key: string
  color: string
} | null

export type InvoiceData = {
  invoiceStatus: InvoiceStatus
  invoiceNote: string
  callbackDate: Date | string | null
}

export type CarrierCallDisposition = {
  id: number
  createdAt: string
  updatedAt: string
  loadId: number
  mcNumber?: string | null
  dotNumber?: string | null
  carrierName?: string | null
  carrierCompanyId?: number | null
  carrierCompany?: {
    name?: string | null
    mcNumber?: string | null
    dotNumber?: string | null
    contactName?: string | null
    contactPhone?: string | null
  }
  agentUser: {
    id: number
    name: string
  }
  callNotes: string
  callOutcome: (typeof carrierCallOutcomeChoices)[number]['value']
}

export type ExternalCapacity = {
  id: number
  createdAt: string
  updatedAt: string
  loadId: number
  carrierName: string
  mcNumber: string
  dotNumber: string
  contactName: string | null
  contactEmail: string | null
  contactPhone: string | null
  capacitySource: string
}

export type LoadTag = { label: string; key: string; background: string }

export type CapacityListItem = {
  carrierCompanyName: string
  mcNumber: string
  dotNumber: string
  carrierLoadCount: number
  carrierCompanyId: number
  carrierLocation?: string
  dispatchContacts: Array<{
    name: string
    phone: string
    email: string
  }>
  recommendationScore: number
  insights: Array<{
    text: string
    color: 'black' | 'green'
  }>
  isExternal: boolean
}

export type ActiveCapacityListItem = {
  carrierCompany: {
    id: number
    name: string
    mcNumber: string
    dotNumber: string
  }
  srcLocation: DbLocation
  dstLocation: null | DbLocation
  equipmentType: string
  dispatchContact: {
    name: string
    phone: string
    email: string
  }
  availabilityStart: string
  availabilityEnd?: string
  createdBy?: {
    firstName: string
    lastName: string
    isAgent: boolean
    isCarrier: boolean
  }
  matchTypeDisplay?: string
  loadId?: number
  matchingLoads?: Array<{
    id: number
    origin: string
    destination: string
    pickupDate: string
    equipmentType: string
  }>
}

export type MenuItem = {
  id: string
  title: string
  noActions?: boolean
}

export type MenuSection = MenuItem & {
  items?: MenuItem[]
}

export type LoadboardPostStatus = {
  serviceName: string
  latestError: string | null
  status: string
}
