import { CatchError, formatAxiosErrorToPayload } from '@common'
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'

import { api } from '../api/api'
import { initialFilters } from '../common/constants'
import { AutomatedBiddingQuote, RootState, SearchFilters, TableOrder } from '../common/types'
import { formatFileName, getOrderingString, keysToCamelCase } from '../common/utils'

type QuotesState = {
  quotes: Array<AutomatedBiddingQuote>
  loading: boolean
  count: number
  offset: number
  size: number
  filters: SearchFilters
  order: TableOrder
  uploadsList: Array<{
    id: number
    fileName: string
  }>
}

const initialState: QuotesState = {
  quotes: [],
  loading: false,
  count: 0,
  offset: 0,
  size: 50,
  filters: initialFilters,
  order: {
    label: '',
    direction: '',
    key: '',
  },
  uploadsList: [],
}

export const getQuotes = createAsyncThunk(
  'automatedBiddingQuotes/getQuotes',
  async (id: any, { getState }) => {
    const {
      filters: {
        status = '',
        dateRange = [null, null],
        query = '',
        batchPricingUploadId = null,
        showInternalQuotes = 'Non-EXO Users',
      },
      size = 50,
      offset = 0,
      order: { label, direction, key },
    } = (getState() as RootState).automatedBiddingQuotes

    const ordering = getOrderingString(label, direction, key, '-id')
    const response = await api.get(`/pricing/biddings-list/${id}/`, {
      params: {
        limit: size,
        offset,
        ordering,
        search: query || null,
        bid_status: status || null,
        created_at_after: dateRange[0],
        created_at_before: dateRange[1],
        batch_pricing_upload_id: batchPricingUploadId,
        show_internal_quotes: showInternalQuotes === 'Any',
      },
    })

    return keysToCamelCase(response.data)
  },
)

export const getUploadsList = createAsyncThunk(
  'quotes/getUploadsList',
  async (customerCompanyId: string, { rejectWithValue }) => {
    try {
      const response = await api.get('/pricing/get-uploaded-files/', {
        params: {
          limit: 500,
          offset: 0,
          ordering: '-created_at',
          customer_company_id: customerCompanyId,
        },
      })
      return keysToCamelCase(response.data)
    } catch (err: CatchError) {
      return rejectWithValue(formatAxiosErrorToPayload(err))
    }
  },
)

const automatedBiddingQuotesSlice = createSlice({
  name: 'automatedBiddingQuotes',
  initialState,
  reducers: {
    setSize(state, { payload }) {
      state.size = payload
    },
    setOffset(state, { payload }) {
      state.offset = payload
    },
    setFilters(state, { payload }) {
      state.filters = payload
    },
    setOrder(state, { payload }) {
      state.order = payload
    },
  },
  extraReducers(builder) {
    builder
      .addCase(getQuotes.pending, state => {
        state.loading = true
      })
      .addCase(getQuotes.fulfilled, (state, action) => {
        const { count, results } = action.payload
        state.loading = false
        state.count = count
        state.quotes = results.map((quote: AutomatedBiddingQuote) => ({
          ...quote,
          fileName: formatFileName(quote.fileName?.split('.').slice(-2).join('.')),
          metaDetail: {
            color: quote.shipperPortalQuote && 'blue',
          },
        }))
      })
      .addCase(getQuotes.rejected, state => {
        state.loading = false
      })
      .addCase(getUploadsList.fulfilled, (state, { payload }) => {
        const { results } = payload
        state.uploadsList = results.map((quote: { file: string; id: number }) => {
          const fileName = quote.file.split('.').slice(-2).join('.')
          return {
            id: quote.id,
            fileName: formatFileName(fileName),
          }
        })
      })
  },
})

export const { setSize, setOffset, setFilters, setOrder } = automatedBiddingQuotesSlice.actions

export default automatedBiddingQuotesSlice.reducer
