import { LoaderSpinner } from '@components'
import { ArrowRightIcon } from '@heroicons/react/24/solid'
import tw from 'tailwind-styled-components'

import { Load } from '../../common/types'
import { displayCityAndState } from '../../common/utils'

export type DetailsShipmentsTableProps = {
  shipments: Array<Load>
  loading: boolean
}

export const DetailsShipmentsTable = ({ shipments, loading }: DetailsShipmentsTableProps) => {
  if (loading) {
    return <LoaderSpinner />
  }

  if (!shipments.length) {
    return <div className='text-center mb-4'>No shipments</div>
  }

  return (
    <ShipmentTable>
      <thead>
        <tr className='text-icon-gray'>
          <th className='pb-2'>Load</th>
          <th className='pb-2'>Origin</th>
          <th className='pb-2'> </th>
          <th className='pb-2'>Destination</th>
          <th className='pb-2'>Price</th>
        </tr>
      </thead>
      <tbody>
        {shipments?.map((shipment: Load, i: number) => (
          <tr key={i}>
            <td className='font-bold pb-2'>#{shipment.id}</td>
            <td className='pb-2'>
              {displayCityAndState(shipment?.shipper?.city, shipment?.shipper?.stateProvinceRegion)}
            </td>
            <td className='pb-2'>
              <ArrowRightIcon className='fill-link w-4' />
            </td>
            <td className='pb-2'>
              {displayCityAndState(
                shipment?.consignee?.city,
                shipment?.consignee?.stateProvinceRegion,
              )}
            </td>
            <td className='pb-2'>${shipment?.customerPrice}</td>
          </tr>
        ))}
      </tbody>
    </ShipmentTable>
  )
}

const ShipmentTable = tw.table`
  text-left
  text-sm
  w-full
`
