import { Button, ButtonProps } from '@components'

export const AddStopButton = (props: ButtonProps) => (
  <Button
    {...props}
    innerClassName={`h-8 text-sm py-1.5 px-2 !text-dark-blue !border-0 !rounded-md ${props.innerClassName}`}
  >
    {props.children}
  </Button>
)
