import { Select, SelectDropdownProps } from '@components'
import { debounce } from 'lodash-es'
import { useCallback } from 'react'
import { MenuPlacement } from 'react-select'

import { useAppSelector, useAppThunkDispatch } from '../../app/hooks'
import { autoCompleteCarrierSearch } from '../../redux/autocompleteSlice'

export const CarrierSelect = ({
  value,
  setValue,
  withLabel,
  withPlaceholder,
  className = '',
  disabled = false,
  menuPlacement,
  required,
  ...props
}: {
  value: any
  setValue: (value: any) => void
  withLabel?: boolean
  withPlaceholder?: boolean
  className?: string
  required?: boolean
  menuPlacement?: MenuPlacement
  disabled?: boolean
} & Omit<SelectDropdownProps, 'choices' | 'onChange'>) => {
  const dispatch = useAppThunkDispatch()

  const carrierSearchOptions =
    useAppSelector(state => state.autocomplete.carrierSearchOptions) || []
  const loading = useAppSelector(state => state.autocomplete.loading.carrierSearchOptions)
  const text = 'Carrier'

  const onSearch = useCallback(
    debounce((query: string) => {
      query && dispatch(autoCompleteCarrierSearch(query))
    }, 200),
    [],
  )

  return (
    <Select
      hideDropdownIndicator
      sm
      disabled={disabled}
      choices={carrierSearchOptions}
      className={className}
      field='text'
      loading={loading}
      openMenuOnClick={!!carrierSearchOptions.length}
      required={required}
      value={value || ''}
      menuPlacement={menuPlacement}
      onChange={setValue}
      onInputChange={onSearch}
      onValueNotFound={() => onSearch(value)}
      {...(withLabel && { label: text })}
      {...(withPlaceholder && { placeholder: text })}
      {...props}
    />
  )
}
