import { LoaderSpinner } from '@components'
import tw from 'tailwind-styled-components'

import { useAppSelector } from '../../../app/hooks'
import { EmptyMessage } from '../../LoadDetails/components/EmptyMessage'
import { ProfileDocument } from '../components'

export const ProfileDocuments = () => {
  const documents = useAppSelector(state => state.customers.customerInfo.documents)
  const loading = useAppSelector(state => state.customers.loading.documents)

  return (
    <div>
      <TableHeader>
        <TD>Name</TD>
        <TD>Modified</TD>
        <TD />
      </TableHeader>
      <TableBody>
        <EmptyMessage loading={loading} length={documents.length} text='documents' />
        {loading && <LoaderSpinner />}
        {documents.map(doc => (
          <ProfileDocument key={doc.id} document={doc} />
        ))}
      </TableBody>
    </div>
  )
}

const TableHeader = tw.div`
  flex
  justify-between
  font-semibold
  px-6
`

const TableBody = tw.div`
  h-[calc(100vh-354px)]
  overflow-auto
  px-6
`

const TD = tw.div`
  w-1/4
  mb-4
`
