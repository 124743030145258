import tw from 'tailwind-styled-components'

import { useAppSelector, useAppThunkDispatch } from '../../../app/hooks'
import { Notification } from '../../../common/types'
import { updateMode } from '../../../redux/notificationsSlice'

export const Tabs = () => {
  const dispatch = useAppThunkDispatch()
  const { mode, notificationsToday, notificationsThisWeek } = useAppSelector(
    store => store.notifications,
  )
  const unreadToday = notificationsToday.filter((notif: Notification) => !notif.read).length
  const unreadThisWeek = notificationsThisWeek.filter((notif: Notification) => !notif.read).length
  const isToday = mode === 'today'
  const isThisWeek = !isToday
  const switcherFor = (timeFrame: 'today' | 'week') => () => dispatch(updateMode(timeFrame))

  return (
    <TabsContainer>
      <Tab onClick={switcherFor('today')}>
        <span className={`${isToday ? 'text-exo-orange' : 'text-dark-blue'}`}>Today</span>
        <Badge $isToday={isToday}>
          <span className='text-white'>{unreadToday}</span>
        </Badge>
      </Tab>
      <Tab onClick={switcherFor('week')}>
        <span className={`${isThisWeek ? 'text-exo-orange' : 'text-dark-blue'}`}>This week</span>
        <Badge $isToday={!isToday}>
          <span className='text-white'>{unreadThisWeek}</span>
        </Badge>
      </Tab>
      <TabsUnderline>
        <div className={`w-5/12 h-full ${isToday ? 'bg-exo-orange' : 'bg-dark-blue'}`} />
        <div className={`w-7/12 h-full ${isThisWeek ? 'bg-exo-orange' : 'bg-dark-blue'}`} />
      </TabsUnderline>
    </TabsContainer>
  )
}

const TabsContainer = tw.div`
  flex
  items-center
  gap-4
  justify-between
  text-sm
  font-semibold
  relative
`

const Tab = tw.div`
  flex
  items-center
  gap-1
  relative
  cursor-pointer
`

const Badge = tw.div`
  ${(p: { $isToday?: boolean }) => (p.$isToday ? 'bg-exo-orange' : 'bg-dark-blue')}
  flex
  w-[18px]
  h-[18px]
  rounded-full
  items-center
  justify-center
  text-[10px]
`

const TabsUnderline = tw.div`
  absolute
  w-full
  h-0.5
  bottom-[-4px]
  flex
  justify-between
`
