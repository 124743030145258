import { Form, TextInput } from '@components'

import { useAppSelector, useAppThunkDispatch } from '../../../app/hooks'
import { CustomerSpecialRequirement } from '../../../common/types'
import { useCustomerType } from '../../../common/utils'
import { setCustomerInfo, setIdsToDelete } from '../../../redux/customersSlice'
import { DeleteButton } from './DeleteButton'

export const SpecialRequirement = ({
  requirement,
  index,
}: {
  requirement: CustomerSpecialRequirement
  index: number
}) => {
  const type = useCustomerType()

  const dispatch = useAppThunkDispatch()

  const specialRequirements = useAppSelector(state => state.customers[type].specialRequirements)
  const idsToDelete = useAppSelector(state => state.customers.idsToDelete)

  const deleteRequirement = () => {
    dispatch(
      setCustomerInfo({
        specialRequirements: specialRequirements.filter(req => requirement.id !== req.id),
      }),
    )
    dispatch(
      setIdsToDelete({
        specialRequirements: [...idsToDelete.specialRequirements, requirement.id].filter(Number),
      }),
    )
  }

  const updateRequirement = (newData: Partial<CustomerSpecialRequirement>) => {
    const tempRequirements = [...specialRequirements]
    tempRequirements[index] = { ...tempRequirements[index], ...newData }
    dispatch(
      setCustomerInfo({
        specialRequirements: tempRequirements,
      }),
    )
  }

  return (
    <div className='mt-2 first:mt-0'>
      <Form
        setFormValid={isValid => requirement.isValid !== isValid && updateRequirement({ isValid })}
      >
        <TextInput
          sm
          label='Special Requirement'
          value={requirement.name}
          onChange={name => updateRequirement({ name })}
        />
        {/*TODO: bring back when the backend is implemented*/}
        {/*<Checkbox*/}
        {/*  isChecked={requirement.showOnRateCon}*/}
        {/*  title='Show on Carrier Rate Confirmation'*/}
        {/*  onChange={() => updateRequirement({ showOnRateCon: !requirement.showOnRateCon })}*/}
        {/*/>*/}
      </Form>
      <DeleteButton onClick={deleteRequirement} />
    </div>
  )
}
