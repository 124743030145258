import { useAppSelector, useAppThunkDispatch } from '../../../app/hooks'
import { NewAccessorial } from '../../../common/types'
import { useCustomerType } from '../../../common/utils'
import { setCustomerInfo, setIdsToDelete } from '../../../redux/customersSlice'
import { EditAccessorialForm } from '../../Forms'
import { DeleteButton } from './DeleteButton'

export const Accessorial = ({
  accessorial,
  index,
}: {
  accessorial: NewAccessorial
  index: number
}) => {
  const type = useCustomerType()

  const dispatch = useAppThunkDispatch()

  const accessorialsStep = useAppSelector(state => state.customers[type].accessorialsStep)
  const idsToDelete = useAppSelector(state => state.customers.idsToDelete)

  const accessorials = accessorialsStep.accessorials

  const setAccessorials = (newData: NewAccessorial[]) =>
    dispatch(setCustomerInfo({ accessorialsStep: { ...accessorialsStep, accessorials: newData } }))

  const deleteAccessorial = () => {
    setAccessorials(accessorials.filter(acc => accessorial.id !== acc.id))
    dispatch(
      setIdsToDelete({
        accessorials: [...idsToDelete.accessorials, accessorial.id].filter(Number),
      }),
    )
  }

  const updateAccessorial = (newData: Partial<NewAccessorial>) => {
    const tempAccessorials = [...accessorials]
    tempAccessorials[index] = { ...tempAccessorials[index], ...newData }
    setAccessorials(tempAccessorials)
  }

  return (
    <div className='mt-6 first:mt-0'>
      <EditAccessorialForm
        accessorial={accessorial}
        setAccessorial={(field, value) => updateAccessorial({ [field]: value })}
        setFormValid={isValid => isValid !== accessorial.isValid && updateAccessorial({ isValid })}
      />
      <DeleteButton onClick={deleteAccessorial} />
    </div>
  )
}
