import { CatchError, formatAxiosErrorToPayload, getErrorString } from '@common'
import { downloadCSV } from '@common'
import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit'
import { cloneDeep, omit } from 'lodash-es'
import { toast } from 'react-toastify'

import { api } from '../api/api'
import { initialFilters } from '../common/constants'
import { CSVField, DbLocation, Note, RootState, SearchFilters } from '../common/types'
import { cleanFilters, keysToCamelCase, keysToSnakeCase, serializeAddress } from '../common/utils'

export type DbLocationsApiResponseLocation = {
  id: number
  city: string
  name: string
  shipment_count: number
  state_province_region: string
}

export type DbLocationsApiResponse = {
  count: number
  next: string | null
  previous: string | null
  results: DbLocationsApiResponseLocation[]
}

export type DbLocationsState = {
  locations: DbLocation[]
  loading: {
    dbLocations: boolean
    details: boolean
    saveDetails: boolean
    saveNote: Array<string | number>
    getNotes: boolean
    deleteNote: boolean
    createLocation: boolean
    deleteCustomer: boolean
    exportCSV: boolean
  }
  errors?: unknown
  size: number
  offset: number
  count: number
  currentLocation: DbLocation | null
  currentLocationBackup: DbLocation | null
  notesCount: number
  newNote: string
  notes: Array<Note>
  notesBackup: Array<Note>
  filters: SearchFilters
  exportFields: CSVField[]
  selectedFields: Array<string>
}

const initialState: DbLocationsState = {
  locations: [],
  loading: {
    dbLocations: false,
    details: false,
    saveDetails: false,
    saveNote: [],
    getNotes: false,
    deleteNote: false,
    createLocation: false,
    deleteCustomer: false,
    exportCSV: false,
  },
  size: 50,
  offset: 0,
  count: 0,
  currentLocation: null,
  currentLocationBackup: null,
  notesCount: 0,
  newNote: '',
  notes: [],
  notesBackup: [],
  filters: initialFilters,
  exportFields: [
    { label: 'Name', key: 'name' },
    { label: 'Address', key: 'address' },
    { label: 'City', key: 'city' },
    { label: 'State', key: 'state_province_region' },
    { label: 'Shipments', key: 'shipment' },
  ],
  selectedFields: ['name', 'address', 'city', 'state_province_region', 'shipment'],
}

const getFilters = (filters: SearchFilters) =>
  cleanFilters({
    name__icontains: filters.locationName,
    city__icontains: filters.city,
    state_province_region__iexact: filters.state,
  })

export const getDbLocations = createAsyncThunk(
  'exoLocations/getDbLocations',
  async (_, { getState }) => {
    const { offset = 0, size = 50, filters } = (getState() as RootState).dbLocations
    const response = await api.get<DbLocationsApiResponse>('/locations/api/list-create/', {
      params: {
        limit: size,
        offset,
        ...getFilters(filters),
      },
    })
    return response.data
  },
)

export const getLocationNotes = createAsyncThunk(
  'customers/getLocationNotes',
  async (payload: { locationId: string; limit?: number }) =>
    api
      .get(`/locations/api/note/${payload.locationId}/`, {
        params: {
          limit: payload.limit || 10,
        },
      })
      .then(({ data }) => data),
)

export const addLocationNote = createAsyncThunk(
  'customers/addLocationNote',
  async (payload: { note: string; locationId: string }) =>
    api
      .post(`/locations/api/note/${payload.locationId}/`, {
        note: payload.note,
      })
      .then(({ data }) => data),
)

export const updateLocationNote = createAsyncThunk(
  'customers/updateLocationNote',
  async (payload: { note: string; noteId: string | number }) =>
    api
      .put(`/locations/api/note-rud/${payload.noteId}/`, {
        note: payload.note,
      })
      .then(({ data }) => data),
)

export const deleteLocationNote = createAsyncThunk(
  'customers/deleteLocationNote',
  async (payload: { noteId: string | number }) => {
    await api.delete(`/locations/api/note-rud/${payload.noteId}/`)
    return payload.noteId
  },
)

export const addLocationCompany = createAsyncThunk(
  'customers/addLocationCompany',
  async ({
    locationId,
    customer,
  }: {
    locationId: number
    customer: { id: number; name: string }
  }) => {
    await api.put('/locations/api/location-customer-relationship-rud/', {
      location_id: locationId,
      customer_company_id: customer.id,
    })
    return customer
  },
)

export const deleteLocationCompany = createAsyncThunk(
  'customers/deleteLocationCompany',
  async ({ locationId, customerId }: { locationId: number; customerId: number }) => {
    await api.delete('/locations/api/location-customer-relationship-rud/', {
      data: { location_id: locationId, customer_company_id: customerId },
    })
    return customerId
  },
)

export const getLocationDetails = createAsyncThunk(
  'exoLocations/getLocationDetails',
  async (id: string | number) =>
    api.get(`/locations/api/detail/${id}/`).then(({ data }) => keysToCamelCase(data)),
)

export const saveLocationDetails = createAsyncThunk(
  'loads/saveLocationDetails',
  async (payload: DbLocation, { rejectWithValue }) => {
    try {
      const response = await api.put(
        `/locations/api/detail/${payload.id}/`,
        keysToSnakeCase(
          omit({ ...payload, country: payload.country === 'MEX' ? 'MX' : payload.country }, [
            'currentPlace',
          ]),
        ),
      )
      return response.data
    } catch (err: CatchError) {
      return rejectWithValue(formatAxiosErrorToPayload(err))
    }
  },
)

export const createLocation = createAsyncThunk(
  'exoLocations/createLocation',
  async (location: any, { rejectWithValue }) => {
    const payload = {
      ...keysToSnakeCase({
        name: location.name,
        contactPhone: location.contactPhone,
        contactName: location.contactName,
        addressLine_1: location.address,
        city: location.city,
        state: location.state,
        postalCode: location.postalCode,
        country: location.country === 'MEX' ? 'MX' : location.country,
        hours: location.hours,
      }),
      current_place: location.currentPlace,
    }

    try {
      const response = await api.post('/locations/api/list-create/', payload)
      return response.data
    } catch (err: CatchError) {
      return rejectWithValue(formatAxiosErrorToPayload(err))
    }
  },
)

export const exportLocationsCSV = createAsyncThunk(
  'exoLocations/exportLocationsCSV',
  async (_, { getState, rejectWithValue }) => {
    const { filters, selectedFields } = (getState() as RootState).dbLocations

    try {
      const response = await api.post('/locations/api/export-location-data/', {
        filters: getFilters(filters),
        fields: selectedFields.join(','),
      })

      downloadCSV(response.data, 'locations')
    } catch (err: CatchError) {
      return rejectWithValue(formatAxiosErrorToPayload(err))
    }
  },
)

const dbLocations = createSlice({
  name: 'exoLocations',
  initialState,
  reducers: {
    setCurrentLocation(state, { payload }) {
      state.currentLocation = payload
    },
    updateCurrentLocation(state, { payload }) {
      const currentLocationCopy = { ...state.currentLocation }
      payload.fields.map((field: keyof DbLocation, i: number) => {
        currentLocationCopy[field] = payload.values[i]
      })
      state.currentLocation = currentLocationCopy as DbLocation
    },
    setSize(state, { payload }: PayloadAction<number>) {
      state.size = payload
    },
    setOffset(state, { payload }: PayloadAction<number>) {
      state.offset = payload
    },
    setLocationNote(state, { payload }) {
      state.notes[payload.index].note = payload.value
    },
    resetLocationNote(state, { payload }) {
      state.notes[payload.index].note = state.notesBackup[payload.index].note
    },
    setNewNote(state, { payload }) {
      state.newNote = payload
    },
    setFilters(state, { payload }) {
      state.filters = payload
    },
    setSelectedFields(state, { payload }) {
      state.selectedFields =
        typeof payload === 'string'
          ? state.selectedFields.includes(payload)
            ? state.selectedFields.filter(field => field !== payload)
            : [...state.selectedFields, payload]
          : payload
    },
  },
  extraReducers(builder) {
    builder
      .addCase(getDbLocations.pending, state => {
        state.loading.dbLocations = true
      })
      .addCase(getDbLocations.fulfilled, (state, { payload }) => {
        state.loading.dbLocations = false
        state.count = payload.count
        state.locations = keysToCamelCase(payload.results)
      })
      .addCase(getDbLocations.rejected, (state, action) => {
        state.loading.dbLocations = false
        state.errors = action.error
      })
      .addCase(getLocationDetails.pending, state => {
        state.loading.details = true
      })
      .addCase(getLocationDetails.fulfilled, (state, action) => {
        const data = keysToCamelCase(action.payload)
        if (data.earlyTime) {
          data.earlyTime = data.earlyTime.slice(0, 5)
        }
        if (data.lateTime) {
          data.lateTime = data.lateTime.slice(0, 5)
        }
        data.addressDisplay = serializeAddress({
          street: data.address,
          city: data.city,
          stateCode: data.stateProvinceRegion,
          postalCode: data.postalCode,
          countryCode: data.country,
        })
        state.currentLocation = data
        state.currentLocationBackup = cloneDeep(state.currentLocation)
        state.loading.details = false
      })
      .addCase(getLocationDetails.rejected, state => {
        state.loading.details = false
        toast.error('Failed to load location details')
      })
      .addCase(getLocationNotes.pending, state => {
        state.loading.getNotes = true
      })
      .addCase(getLocationNotes.fulfilled, (state, action) => {
        const { results, count } = action.payload
        state.notesCount = count
        state.notes = keysToCamelCase(results)
        state.notesBackup = cloneDeep(state.notes)
        state.loading.getNotes = false
      })
      .addCase(getLocationNotes.rejected, state => {
        state.loading.getNotes = false
        toast.error('Failed to load customer notes')
      })
      .addCase(addLocationNote.pending, state => {
        state.loading.saveNote.push(-1)
      })
      .addCase(addLocationNote.fulfilled, (state, action) => {
        state.notes.unshift(keysToCamelCase(action.payload))
        state.notesBackup = cloneDeep(state.notes)
        state.newNote = ''
        state.loading.saveNote = state.loading.saveNote.filter(id => id !== -1)
        toast.success('Successfully added new note')
      })
      .addCase(addLocationNote.rejected, state => {
        state.loading.saveNote = state.loading.saveNote.filter(id => id !== -1)
        toast.error('Failed to save new note')
      })
      .addCase(updateLocationNote.pending, (state, action) => {
        state.loading.saveNote.push(action.meta.arg.noteId)
      })
      .addCase(updateLocationNote.fulfilled, (state, action) => {
        const updatedNote = keysToCamelCase(action.payload)
        state.notes = state.notes.map((note: Note) =>
          note.id === updatedNote.id ? updatedNote : note,
        )
        state.notesBackup = cloneDeep(state.notes)
        state.loading.saveNote = state.loading.saveNote.filter(id => id !== action.meta.arg.noteId)
        toast.success('Successfully updated note')
      })
      .addCase(updateLocationNote.rejected, (state, action) => {
        state.loading.saveNote = state.loading.saveNote.filter(id => id !== action.meta.arg.noteId)
        toast.error('Failed to update note')
      })
      .addCase(deleteLocationNote.pending, state => {
        state.loading.deleteNote = true
      })
      .addCase(deleteLocationNote.fulfilled, (state, action) => {
        const noteId = action.payload
        state.loading.deleteNote = false
        // @ts-ignore TODO: figure this out
        state.notes = state.notes.filter((note: { id }) => note.id != noteId)
        state.notesBackup = cloneDeep(state.notes)
        toast.success('Successfully deleted note')
      })
      .addCase(deleteLocationNote.rejected, state => {
        state.loading.deleteNote = false
        toast.error('Failed to delete note')
      })
      .addCase(saveLocationDetails.pending, state => {
        state.loading.saveDetails = true
      })
      .addCase(saveLocationDetails.fulfilled, (state, action) => {
        state.loading.saveDetails = false
        state.currentLocation = {
          ...state.currentLocation,
          ...keysToCamelCase(action.payload),
        }
        state.currentLocationBackup = cloneDeep(state.currentLocation)
        toast.success('Successfully updated location info')
      })
      .addCase(saveLocationDetails.rejected, (state, { payload }) => {
        state.loading.saveDetails = false
        toast.error(getErrorString(payload, 'Failed to save location details'))
      })
      .addCase(deleteLocationCompany.pending, state => {
        state.loading.deleteCustomer = true
      })
      .addCase(deleteLocationCompany.fulfilled, (state, action) => {
        ;(state as any).currentLocation = {
          ...state.currentLocation,
          customerCompanies: (state as any).currentLocation.customerCompanies.filter(
            (customer: any) => customer.companyId !== action.payload,
          ),
        }
        state.loading.deleteCustomer = false
        state.currentLocationBackup = cloneDeep(state.currentLocation)
        toast.success('Successfully removed customer')
      })
      .addCase(deleteLocationCompany.rejected, state => {
        state.loading.deleteCustomer = false
        toast.success('Failed to remove customer')
      })
      .addCase(addLocationCompany.fulfilled, (state, action) => {
        ;(state as any).currentLocation = {
          ...state.currentLocation,
          customerCompanies: [...(state as any).currentLocation.customerCompanies, action.payload],
        }
        state.currentLocationBackup = cloneDeep(state.currentLocation)
      })
      .addCase(createLocation.pending, state => {
        state.loading.createLocation = true
      })
      .addCase(createLocation.fulfilled, (state, action) => {
        state.loading.createLocation = false
        state.locations.unshift(
          keysToCamelCase({
            ...action.payload,
            shipment_count: action.payload?.shipment_count ?? 0,
          }),
        )
        toast.success('Customer Location Created')
      })
      .addCase(createLocation.rejected, (state, action) => {
        state.loading.createLocation = false
        toast.error(getErrorString(action.payload, 'Error adding new location'))
      })
      .addCase(exportLocationsCSV.pending, state => {
        state.loading.exportCSV = true
      })
      .addCase(exportLocationsCSV.fulfilled, state => {
        state.loading.exportCSV = false
        toast.success('Successfully exported CSV')
      })
      .addCase(exportLocationsCSV.rejected, (state, { payload }) => {
        state.loading.exportCSV = false
        toast.error(getErrorString(payload, 'Failed to export CSV'))
      })
  },
})

export const {
  setSize,
  setOffset,
  setFilters,
  resetLocationNote,
  setLocationNote,
  setNewNote,
  setCurrentLocation,
  updateCurrentLocation,
  setSelectedFields,
} = dbLocations.actions

export const selectDbLocations = (state: RootState) => state.dbLocations

export default dbLocations.reducer
