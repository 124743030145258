import { find } from 'lodash-es'
import { useEffect, useState } from 'react'
import { ControllerRenderProps, FieldValues } from 'react-hook-form'

import { useAppSelector, useAppThunkDispatch } from '../../../../app/hooks'
import { autoCompleteCustomerNameSearch } from '../../../../redux/autocompleteSlice'
import { CustomerSelect as CustomerAutocomplete } from '../../../Selects'
import { CustomerName } from '../types'

export type CustomerNameAutocompleteProps = {
  field: ControllerRenderProps<FieldValues, 'customerCompany'>
  className?: string
  required?: boolean
}

export const CustomerNameAutocomplete = ({
  field,
  className = 'w-full',
  required,
}: CustomerNameAutocompleteProps) => {
  const dispatch = useAppThunkDispatch()
  const customerCompanyName = useAppSelector(state => state.loads.customerCompanyName)
  const [wasInitialized, setWasInitialized] = useState(false)

  useEffect(() => {
    if (customerCompanyName?.length) {
      dispatch(autoCompleteCustomerNameSearch(customerCompanyName))
    }
  }, [customerCompanyName])

  const customerNamesOptions = useAppSelector(state => state.autocomplete.customerNamesOptions)

  const [customerName, setCustomerName] = useState<any>()

  useEffect(() => {
    if (customerNamesOptions.length && !customerName && !wasInitialized) {
      const opt = find(customerNamesOptions, { id: String(field?.value) })
      if (opt) {
        setCustomerName(opt as CustomerName)
        setWasInitialized(true)
      }
    }
  }, [customerNamesOptions, field?.value])

  return (
    <CustomerAutocomplete
      addable
      withLabel
      withError
      required={required}
      className={className}
      value={customerName ?? null}
      setValue={customerName => {
        setCustomerName(customerName)
        if (!customerName) field.onChange(null)
        else field.onChange(Number(customerName.id), customerName)
      }}
    />
  )
}
