import { Form, TextArea, TextInput } from '@components'

import { useAppSelector, useAppThunkDispatch } from '../../../app/hooks'
import { CustomerExternalPortal } from '../../../common/types'
import { useCustomerType } from '../../../common/utils'
import { setCustomerInfo, setIdsToDelete } from '../../../redux/customersSlice'
import { DeleteButton } from './DeleteButton'

export const ExternalPortal = ({
  portal,
  index,
}: {
  portal: CustomerExternalPortal
  index: number
}) => {
  const type = useCustomerType()

  const dispatch = useAppThunkDispatch()

  const externalPortals = useAppSelector(state => state.customers[type].externalPortals)
  const idsToDelete = useAppSelector(state => state.customers.idsToDelete)

  const deletePortal = () => {
    dispatch(
      setCustomerInfo({
        externalPortals: externalPortals.filter(pr => portal.id !== pr.id),
      }),
    )
    dispatch(
      setIdsToDelete({
        externalPortals: [...idsToDelete.externalPortals, portal.id].filter(Number),
      }),
    )
  }

  const updatePortal = (newData: Partial<CustomerExternalPortal>) => {
    const tempPortals = [...externalPortals]
    tempPortals[index] = { ...tempPortals[index], ...newData }
    dispatch(
      setCustomerInfo({
        externalPortals: tempPortals,
      }),
    )
  }

  return (
    <div className='mt-6 first:mt-0'>
      <Form
        setFormValid={isValid => portal.isValid !== isValid && updatePortal({ isValid })}
        className='flex flex-col gap-y-3'
      >
        <TextInput sm label='Name' value={portal.name} onChange={name => updatePortal({ name })} />
        <TextInput
          sm
          label='Portal Address'
          value={portal.url}
          onChange={url => updatePortal({ url })}
        />
        <div className='grid grid-cols-2 gap-x-4'>
          <TextInput
            sm
            label='Email'
            value={portal.email}
            type='email'
            onChange={email => updatePortal({ email })}
          />
          <TextInput
            sm
            label='Password'
            type='password'
            value={portal.password}
            onChange={password => updatePortal({ password })}
          />
        </div>
        <TextArea
          sm
          label='Notes'
          value={portal.notes}
          onChange={notes => updatePortal({ notes })}
        />
      </Form>
      <DeleteButton onClick={deletePortal} />
    </div>
  )
}
