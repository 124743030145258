import { pluralizeNoun } from '@common'
import { Button, Modal } from '@components'
import { ArrowLongRightIcon } from '@heroicons/react/24/solid'
import { useParams } from 'react-router-dom'
import tw from 'tailwind-styled-components'

import { useAppSelector, useAppThunkDispatch } from '../../../app/hooks'
import { displayDate, formatCurrency } from '../../../common/utils'
import {
  getLoadDetail,
  getLoads,
  setContractLaneId,
  setContractLaneModalVisible,
  updateLoad,
} from '../../../redux/loadsSlice'

export const SelectContractLaneModal = () => {
  const { id = '' } = useParams()

  const { pathname } = window.location

  const dispatch = useAppThunkDispatch()

  const customerContractLanes = useAppSelector(state => state.contractLane.customerContractLanes)
  const isContractLaneModalVisible = useAppSelector(state => state.loads.isContractLaneModalVisible)
  const selectedLoadId = useAppSelector(state => state.loads.selectedLoadId)
  const createLoadModalVisible = useAppSelector(state => state.loads.createLoadModalVisible)

  const updateLoadContractLaneId = (laneId: number) => {
    const loadId = id || selectedLoadId

    dispatch(setContractLaneId(laneId))
    if (pathname.includes('/loads') && !createLoadModalVisible) {
      dispatch(updateLoad({ id: loadId, contractLaneId: laneId }))
        .unwrap()
        .then(() => {
          dispatch(getLoads())
          dispatch(getLoadDetail(loadId))
        })
        .catch(() => {})
    }
    dispatch(setContractLaneModalVisible(false))
  }

  return (
    <Modal
      isVisible={isContractLaneModalVisible}
      setVisible={setContractLaneModalVisible}
      showCloseIcon={false}
      className='lg:min-w-[1077px]'
    >
      <div className='px-6 py-8'>
        <div className='text-base font-bold mb-4'>
          This customer has {customerContractLanes.length} contract{' '}
          {pluralizeNoun(customerContractLanes.length, 'lane')}.
        </div>
        {!!customerContractLanes.length && (
          <>
            <Row className='font-semibold pb-2'>
              <Cell className='w-[300px] min-w-[300px] gap-2'>Lane</Cell>
              <Cell>Contract ID</Cell>
              <Cell>Line Haul</Cell>
              <Cell>Min. Charge</Cell>
              <Cell>All-In</Cell>
              <Cell className='w-[140px] min-w-[140px]'>Contract Duration</Cell>
              <Cell className='w-[140px] min-w-[140px]' />
            </Row>
            <div className='max-h-[300px] overflow-auto'>
              {customerContractLanes.map(lane => (
                <Row key={lane.id} className='py-4 last:border-none'>
                  <Cell className='w-[300px] min-w-[300px] gap-2'>
                    {lane.origin} <ArrowLongRightIcon className='w-4' /> {lane.destination}
                  </Cell>
                  <Cell>{lane.id}</Cell>
                  <Cell>{formatCurrency(lane.ratePerMile) || '—'}</Cell>
                  <Cell>{formatCurrency(lane.minimumCharge) || '—'}</Cell>
                  <Cell>{formatCurrency(lane.allIn) || '—'}</Cell>
                  <Cell className='w-[140px] min-w-[140px]'>
                    {displayDate(lane.startDate)} — {displayDate(lane.endDate)}
                  </Cell>
                  <Cell className='justify-end w-[140px] min-w-[140px]'>
                    <Button type='link' onClick={() => updateLoadContractLaneId(lane.id)}>
                      Select
                    </Button>
                  </Cell>
                </Row>
              ))}
            </div>
          </>
        )}
        <div className='mt-10 flex justify-center'>
          <Button type='secondary' onClick={() => dispatch(setContractLaneModalVisible(false))}>
            Cancel
          </Button>
        </div>
      </div>
    </Modal>
  )
}

const Row = tw.div`
  flex
  items-center
  justiffy-between
  gap-4
  border-b
  border-border-gray
`

const Cell = tw.div`
  whitespace-nowrap
  w-[85px]
  min-w-[85px]
  flex
  items-center
`
