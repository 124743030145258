import { Load, TenderItem } from '../common/types'
import { formatCurrency } from '../common/utils'

export const normalizeTenderItems = (data: Load): Array<TenderItem> => [
  {
    id: 0,
    label: 'Customer Total',
    price: data.loadMargin?.customerTotalPrice ?? 0,
  },
  {
    id: 1,
    label: 'Carrier Due',
    price: data.loadMargin?.carrierDue ?? 0,
  },
  {
    id: 2,
    label: 'Customer Base',
    price: data.customerPrice ?? 0,
    editable: true,
  },
  {
    id: 3,
    label: 'Carrier Total',
    price: data.loadMargin?.carrierTotalPrice ?? 0,
  },
  {
    id: 4,
    label: 'Load Margin',
    price: data.loadMargin?.margin ?? 0,
  },
  {
    id: 5,
    label: 'Carrier Start Buy',
    price: data.carrierStartBuy ?? 0,
    editable: true,
  },
  {
    id: 6,
    label: 'Customer Total Accessorial',
    price: data.loadMargin?.customerTotalAccessorials ?? 0,
    isAccessorial: true,
  },
  {
    id: 7,
    label: 'Carrier Max Buy',
    price: data.carrierMaxBuy ?? 0,
    editable: true,
  },
  {
    id: 8,
    label: 'Percent Margin',
    price: data.loadMargin?.percentMargin ?? 0,
    isPercent: true,
  },
  {
    id: 9,
    label: 'Carrier Total Accessorial',
    price: data.loadMargin?.carrierTotalAccessorials ?? 0,
    details: `Prepaid/Deductions: ${formatCurrency(
      data.loadMargin?.carrierTotalPrepaidCarrierDeductionsAccessorial,
    )}`,
    isAccessorial: true,
  },
  {
    id: 10,
    label: 'Predicted Carrier Start Buy',
    price: data.predictedStartBuy ?? 0,
  },
  {
    id: 11,
    label: 'Carrier Line Haul',
    price: data.carrierPrice ?? 0,
    editable: true,
  },
  {
    id: 12,
    label: 'Predicted Carrier Max Buy',
    price: data.predictedMaxBuy ?? 0,
  },
]

export const normalizeFactoringTenderItems = (data: Load): Array<TenderItem> => [
  {
    id: 0,
    label: 'Invoice Amount',
    price: Number(data.customerPrice ?? 0),
    editable: true,
  },
  {
    id: 1,
    label: `Factoring Fee (${data.factoringPriceBreakdown?.margin?.percentage}%)`,
    price: data.factoringPriceBreakdown?.margin?.amount ?? 0,
  },
  {
    id: 2,
    label: 'Invoice Processing Fee',
    price: data.factoringPriceBreakdown?.flatFee ?? 0,
  },
  {
    id: 3,
    label: 'ACH Fee',
    price: data.factoringPriceBreakdown?.achFee ?? 0,
  },
  {
    id: 4,
    label: `Reserve Escrow ${
      data.factoringPriceBreakdown?.reserve?.percentage
        ? `(${data.factoringPriceBreakdown.reserve.percentage}%)`
        : ''
    }`,
    price: data.factoringPriceBreakdown?.reserve?.amount ?? 0,
  },
  {
    id: 5,
    label: 'Recoveries',
    price:
      data.factoringPriceBreakdown?.recoveries?.reduce(
        (sum, recoveryItem) => sum + recoveryItem.amount,
        0,
      ) ?? 0,
  },
  {
    id: 6,
    label: 'Total Deductions',
    price: data.factoringPriceBreakdown?.totalDeductions ?? 0,
  },
  {
    id: 7,
    label: 'Carrier Advance Amount',
    price: data.factoringPriceBreakdown?.totalAmountAfterDeductions ?? 0,
  },
]
