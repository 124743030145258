import { Checkbox, Form, TextArea, TextInput } from '@components'
import { omit } from 'lodash-es'
import { useEffect, useState } from 'react'

import { useAppSelector, useAppThunkDispatch } from '../../../app/hooks'
import { initialCustomerContact } from '../../../common/constants'
import {
  AccountingAdditionalInformation,
  CustomerAddress,
  OnboardingCustomerContact,
} from '../../../common/types'
import { randomString, useCustomerType } from '../../../common/utils'
import { setCustomerInfo } from '../../../redux/customersSlice'
import { AddressForm } from '../../Forms'
import { AgentSelect } from '../../Selects'
import { AddButton, Contact, SectionHeader } from '../components'

export const Accounting = () => {
  const type = useCustomerType()

  const [isSameAddress, setSameAddress] = useState(false)

  const dispatch = useAppThunkDispatch()

  const accountingStep = useAppSelector(state => state.customers[type].accountingStep)
  const primaryAddress = useAppSelector(state => state.customers[type].addressesStep.primaryAddress)
  const billingAddress = useAppSelector(
    state => state.customers[type].accountingStep.billingAddress,
  )
  const additional = useAppSelector(state => state.customers[type].accountingStep.additional)
  const contacts = useAppSelector(state => state.customers[type].accountingStep.contacts)

  useEffect(() => {
    if (isSameAddress) updateBillingAddress(omit(primaryAddress, ['name', 'notes']))
  }, [isSameAddress])

  const setAccounting = (
    newData:
      | { contacts: OnboardingCustomerContact[] }
      | { additional: AccountingAdditionalInformation }
      | { billingAddress: CustomerAddress },
  ) => dispatch(setCustomerInfo({ accountingStep: { ...accountingStep, ...newData } }))

  const updateBillingAddress = (newData: Partial<CustomerAddress>) => {
    setAccounting({
      billingAddress: { ...billingAddress, ...newData },
    })
  }

  const updateAdditionalInformation = (newData: Partial<AccountingAdditionalInformation>) =>
    setAccounting({
      additional: { ...additional, ...newData },
    })

  const addNewContact = () =>
    setAccounting({
      contacts: [
        ...contacts,
        {
          ...initialCustomerContact,
          id: randomString(),
          invoice: true,
        },
      ],
    })

  return (
    <>
      {!contacts.length && <SectionHeader>contacts</SectionHeader>}
      {contacts.map((contact, i) => (
        <Contact key={contact.id} isAccounting contact={contact} index={i} />
      ))}
      <AddButton onClick={addNewContact}>Add New Contact</AddButton>
      <Form
        setFormValid={isValid =>
          isValid !== billingAddress.isValid && updateBillingAddress({ isValid })
        }
      >
        <SectionHeader className='mt-6'>billing address (optional)</SectionHeader>
        <AddressForm
          value={billingAddress}
          addressData={billingAddress}
          setAddressData={updateBillingAddress}
          type='address'
        />
        {type === 'onboardingCustomer' && (
          <Checkbox
            isChecked={isSameAddress}
            title='Same as Primary Address/Headquarters'
            className='mt-3'
            onChange={() => setSameAddress(!isSameAddress)}
          />
        )}
        <TextArea
          sm
          className='mt-3'
          label='Notes'
          value={billingAddress.notes}
          onChange={notes => updateBillingAddress({ notes })}
        />
      </Form>
      <SectionHeader className='mt-6'>additional information</SectionHeader>
      <Form
        setFormValid={isValid =>
          isValid !== additional.isValid && updateAdditionalInformation({ isValid })
        }
      >
        <div className='flex flex-col gap-y-3'>
          <AgentSelect
            withLabel
            label='Internal Accounting Rep'
            value={additional.accountingContact?.id ? additional.accountingContact : null}
            onChange={accountingContact =>
              updateAdditionalInformation({
                accountingContact,
              })
            }
          />
          <TextInput
            sm
            required={type === 'customerInfo'}
            value={additional.payTerms}
            type='number'
            label='Pay Terms (number of days)'
            onChange={payTerms => updateAdditionalInformation({ payTerms })}
          />
          <TextInput
            sm
            value={additional.invoicingMethod}
            label='Preferred Invoicing Method (ACH or Paper Check)'
            onChange={invoicingMethod => updateAdditionalInformation({ invoicingMethod })}
          />
          <TextInput
            sm
            value={additional.refNumber}
            label='Reference Numbers Needed for Invoicing'
            onChange={refNumber => updateAdditionalInformation({ refNumber })}
          />
          <TextArea
            sm
            className='mb-1'
            value={additional.sendTo}
            label='Send Invoices To (Group, TMS, Individuals)'
            onChange={sendTo => updateAdditionalInformation({ sendTo })}
          />
          <TextArea
            sm
            value={additional.notes}
            label='Notes'
            onChange={notes => updateAdditionalInformation({ notes })}
          />
        </div>
      </Form>
    </>
  )
}
