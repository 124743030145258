import { Checkbox, Select, TextInput } from '@components'
import { useState } from 'react'
import { RenderInputComponent } from 'react-autosuggest'
import tw from 'tailwind-styled-components'

import { AddressData } from '../../common/types'
import { serializeAddress } from '../../common/utils'
import { AddressAutocomplete } from '../Autocomplete'

type AddressFormProps = {
  addressData: AddressData | null
  setAddressData: (addressData: object) => void
  renderAutocompleteComponent?: RenderInputComponent
  withMargin?: boolean
  required?: boolean
  value?: AddressData
  type?: string
  error?: string
}

type ManualInputCheckBoxProps = {
  inputAddressManually: boolean
  setInputAddressManually: React.Dispatch<React.SetStateAction<boolean>>
  addressDisplay?: string
}

export const getAddressDataDisplay = (data: AddressData) =>
  serializeAddress({
    street: data.address,
    city: data.city,
    stateCode: data.state || data.caProvince || data.stateProvinceRegion,
    postalCode: data.usZipcode || data.caPostalCode || data.postalCode,
    countryCode: data.country,
  })

export const AddressForm = ({
  addressData,
  setAddressData,
  withMargin = true,
  required = false,
  error,
}: AddressFormProps) => {
  const marginClass = withMargin ? 'mb-4' : ''
  const isMexico = ['MEX', 'MX'].includes(addressData?.country || '')
  const [inputAddressManually, setInputAddressManually] = useState(false)

  const autoUpdateDataCopy = (copy: any, value: any) => {
    // Process address from Autocomplete
    const country = value.address.countryCode
    const houseNumber = value.address.houseNumber
    copy.address = `${houseNumber ? `${houseNumber} ` : ''}${value.address.street || ''}`
    copy.city = value.address.city
    copy.caProvince = value.address.country == 'CAN' ? value.address.stateCode : ''
    copy.state = value.address.stateCode
    copy.stateProvinceRegion = value.address.stateCode
    copy.caPostalCode = value.address.country == 'CAN' ? value.address.postalCode : ''
    copy.usZipcode = value.address.postalCode
    copy.postalCode = value.address.postalCode
    copy.lat = value.address.position.lat
    copy.lon = value.address.position.lng

    copy.country = country
    copy.addressDisplay = value.address.label
    if (copy.currentPlace !== undefined) {
      copy.currentPlace = value.currentPlace
    }
    return copy
  }

  const updateDataCopyManually = (field: string, copy: any, value: any) => {
    const newAddress = { ...copy.currentPlace?.address }

    // Make sure lat/lon is null for manual input
    copy.lat = null
    copy.lon = null
    if (field == 'state') {
      copy.caProvince = value
      copy.state = value
      copy.stateProvinceRegion = value
      newAddress.stateCode = value
    } else if (field == 'zipcode') {
      copy.caPostalCode = value
      copy.usZipcode = value
      copy.postalCode = value
      newAddress.postalCode = value
    } else if (field == 'country') {
      if (['MEX', 'MX'].includes(value)) {
        copy.stateProvinceRegion = null
        copy.caProvince = null
        copy.state = null
        copy.caPostalCode = null
        copy.usZipcode = null
        copy.postalCode = null
      }
      copy.country = value
      newAddress.countryCode = value
    } else {
      copy[field] = value
      if (field === 'city') {
        newAddress.city = value
      }
      if (field === 'address') {
        newAddress.street = value
      }
    }
    copy.currentPlace = {
      ...copy.currentPlace,
      address: newAddress,
    }
    return copy
  }

  const updateAddressData = (copy: any) => {
    setAddressData({
      ...copy,
      state: copy.country === 'USA' ? copy.state : null,
      caProvince: copy.country === 'CAN' ? copy.state : null,
      addressDisplay: getAddressDataDisplay(copy),
    })
  }

  const updateAddressDetails = (field: string, value: string | any) => {
    /*
      Handle address update logic. For USA we use zipcode/state, for Canada
      we use province/postal, for Mexico we use neither.
    */
    let addressDataCopy = { ...addressData }
    if (field === 'address' && !inputAddressManually) {
      addressDataCopy = autoUpdateDataCopy(addressDataCopy, value)
    } else {
      addressDataCopy = updateDataCopyManually(field, addressDataCopy, value)
    }
    updateAddressData(addressDataCopy)
  }

  // Format countries for Select component
  const countryChoices = [
    { label: 'United States', value: 'USA' },
    { label: 'Canada', value: 'CAN' },
    { label: 'Mexico', value: 'MEX' },
  ]
  const selectedCountry = countryChoices.filter(choice => choice.value === addressData?.country)[0]

  // Display proper values/labels based on country
  const zipCodeLabel = `${addressData?.country === 'USA' ? 'Zip' : 'Postal'} Code`
  const zipCode = addressData?.usZipcode || addressData?.postalCode || addressData?.caPostalCode
  const stateLabel = addressData?.country === 'USA' || !addressData?.country ? 'State' : 'Province'
  const state = addressData?.state || addressData?.caProvince || addressData?.stateProvinceRegion

  return (
    <>
      {inputAddressManually ? (
        <>
          <TextInput
            sm
            className={marginClass}
            label='Address'
            required={required}
            value={addressData?.address ?? ''}
            onChange={(value: string) => updateAddressDetails('address', value)}
          />
          <div className='flex'>
            <TextInput
              sm
              className={`w-full ${marginClass}`}
              label='City'
              required={required}
              value={addressData?.city ?? ''}
              onChange={(value: string) => updateAddressDetails('city', value)}
            />
            {!isMexico && (
              <TextInput
                sm
                className={`w-40 pl-2 ${marginClass}`}
                label={stateLabel}
                required={required}
                value={state}
                onChange={(value: string) => updateAddressDetails('state', value.toUpperCase())}
              />
            )}
          </div>
          <div className='flex'>
            {!isMexico && (
              <InputWrap $error={error} className='mr-4'>
                <TextInput
                  sm
                  className='w-full'
                  label={zipCodeLabel}
                  required={required}
                  value={zipCode}
                  onChange={(value: string) => updateAddressDetails('zipcode', value)}
                />
              </InputWrap>
            )}
            <InputWrap $error={error}>
              <Select
                sm
                choices={countryChoices}
                className='w-full'
                field='label'
                isClearable={false}
                label='Country'
                required={required}
                value={selectedCountry || ''}
                onChange={(value: any) => updateAddressDetails('country', value.value)}
              />
            </InputWrap>
          </div>
        </>
      ) : (
        <InputWrap $error={error}>
          <AddressAutocomplete
            field='address'
            item={{}}
            label='Address'
            required={required}
            setItem={(address: any) => updateAddressDetails('address', address)}
            value={addressData?.addressDisplay ?? ''}
          />
        </InputWrap>
      )}
      {error && <div className='text-error my-2'>{error}</div>}
      <ManualInputCheckBox
        {...{
          inputAddressManually,
          setInputAddressManually,
          addressDisplay: addressData?.addressDisplay,
        }}
      />
    </>
  )
}

export const ManualInputCheckBox = ({
  inputAddressManually,
  setInputAddressManually,
  addressDisplay,
}: ManualInputCheckBoxProps) => {
  const isValidAddressDisplay = !!addressDisplay && !addressDisplay.includes('null')

  return (
    <div className='w-[300px]'>
      {!isValidAddressDisplay ? (
        <div className='text-error mb-2'>Enter the nearest address</div>
      ) : (
        <Checkbox
          className='text-xs'
          title='Edit the address manually'
          isChecked={inputAddressManually}
          onChange={() => isValidAddressDisplay && setInputAddressManually(!inputAddressManually)}
        />
      )}
    </div>
  )
}

const InputWrap = tw.div<{ $error?: string }>`
  w-full
  ${({ $error }) => !$error && 'mb-4'}
`
