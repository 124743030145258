import './Sidebar.scss'

import { useEffect, useState } from 'react'
import { NavLink, useLocation } from 'react-router-dom'
import tw from 'tailwind-styled-components'

import { ThunkAppDispatch, useAppSelector, useAppThunkDispatch } from '../../app/hooks'
import { SidebarLink } from '../../common/types'
import { getPendingCarrierFactoringRequests } from '../../redux/carrierFactoringRequestSlice'
import { getPendingLoadFactoringRequests } from '../../redux/loadFactoringRequestSlice'
import { links } from './links'

const isLinkActive = (currentPath: string, url: string) =>
  (url === '/' && currentPath === url) || (url !== '/' && currentPath.startsWith(url))

const fetchPendingRequests = (dispatch: ThunkAppDispatch) => {
  dispatch(getPendingCarrierFactoringRequests())
  dispatch(getPendingLoadFactoringRequests())
}

export const Sidebar = (): JSX.Element | null => {
  const [linksList, setLinksList] = useState<Array<SidebarLink>>([])
  const location = useLocation()

  const dispatch = useAppThunkDispatch()
  const user = useAppSelector(state => state.user.user)

  useEffect(() => {
    setLinksList(
      links.filter(link => {
        const hasAccountingAccess = link.accounting ? user.accounting : true
        const hasFeatureFlagAccess = link.featureFlag ? user.featureFlags[link.featureFlag] : true
        return hasAccountingAccess && hasFeatureFlagAccess
      }),
    )

    if (user.accounting) fetchPendingRequests(dispatch)
  }, [user.accounting, user.featureFlags])

  return (
    <ContainerDiv>
      {linksList.map(link => {
        const renderLinkContent = () => (
          <div className='flex items-center h-12'>
            <div className='w-16'>
              <IconBox $active={isLinkActive(location.pathname, link.url)}>{link.icon}</IconBox>
            </div>
            <LinkLabel>{link.label}</LinkLabel>
          </div>
        )

        return link.external ? (
          <a key={link.url} href={link.url} rel='noreferrer' target='_blank'>
            {renderLinkContent()}
          </a>
        ) : (
          <NavLink
            key={link.url}
            className={() => (isLinkActive(location.pathname, link.url) ? 'text-brand-accent' : '')}
            to={link.url}
          >
            {renderLinkContent()}
          </NavLink>
        )
      })}
    </ContainerDiv>
  )
}

const ContainerDiv = tw.div`
  sidebar
  hidden
  lg:!block
  w-[76px]
  hover:w-[260px]
  absolute
  bg-white
  z-20
  transition-[width]
  duration-200
  border-r 
  border-light-gray 
  pt-4
  overflow-auto
  h-[calc(100vh-72px)]
`

const LinkLabel = tw.div`
  link-label
  font-semibold
  uppercase
  opacity-0
  w-0
  text-sm
  invisible
  inline-flex
  transition-[opacity,width]
  ease-[ease-in-out,ease]
  duration-[200ms,10ms]
`

const IconBox = tw.div`
  py-4
  pl-6
  pr-4
  ${(p: { $active: boolean }) => p.$active && 'active-link'}
`
