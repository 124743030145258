import { Button } from '@components'
import { noop } from 'lodash-es'
import { useEffect, useMemo, useState } from 'react'
import { useLocation } from 'react-router-dom'
import tw from 'tailwind-styled-components'

import { useAppSelector, useAppThunkDispatch } from '../../../app/hooks'
import { checkIsValidArray, deepEqualWithIgnore } from '../../../common/utils'
import { resetIdsToDelete, setCustomerInfo, updateCustomer } from '../../../redux/customersSlice'
import { DocumentModal } from './DocumentModal'

export const ActionButtons = ({
  isEditing,
  setEditing,
}: {
  isEditing: boolean
  setEditing: (value: boolean) => void
}) => {
  const [isDocumentModalVisible, setDocumentModalVisible] = useState(false)

  const location = useLocation()

  const dispatch = useAppThunkDispatch()

  const customerInfo = useAppSelector(state => state.customers.customerInfo)
  const backupCustomerInfo = useAppSelector(state => state.customers.backupCustomerInfo)
  const contactsStep = useAppSelector(state => state.customers.customerInfo.contactsStep)
  const generalInformation = useAppSelector(
    state => state.customers.customerInfo.generalInformation,
  )
  const externalPortals = useAppSelector(state => state.customers.customerInfo.externalPortals)
  const specialRequirements = useAppSelector(
    state => state.customers.customerInfo.specialRequirements,
  )
  const addressesStep = useAppSelector(state => state.customers.customerInfo.addressesStep)
  const accessorialsStep = useAppSelector(state => state.customers.customerInfo.accessorialsStep)
  const accountingStep = useAppSelector(state => state.customers.customerInfo.accountingStep)
  const saveLoading = useAppSelector(state => state.customers.loading.updateCustomer)

  const handleCancel = () => {
    dispatch(setCustomerInfo(backupCustomerInfo))
    dispatch(resetIdsToDelete())
    setEditing(false)
  }

  const handleSave = () =>
    dispatch(updateCustomer())
      .unwrap()
      .then(() => setEditing(false))
      .catch(noop)

  const params = new URLSearchParams(location.search)
  const tab = params.get('detailsTab') || '0'

  const changesMade = useMemo(
    () => !deepEqualWithIgnore(customerInfo, backupCustomerInfo, ['isValid']),
    [customerInfo, backupCustomerInfo],
  )

  const disabled = useMemo(() => {
    if (!changesMade) return true

    switch (tab) {
      case '0':
        return !generalInformation.isValid
      case '1':
        return !checkIsValidArray([addressesStep.primaryAddress, ...addressesStep.facilities])
      case '2':
        return !checkIsValidArray([
          ...accountingStep.contacts,
          ...(accountingStep.billingAddress?.city ? [accountingStep.billingAddress] : []),
          accountingStep.additional,
        ])
      case '3':
        return !checkIsValidArray([...contactsStep.contacts, ...contactsStep.teamEmails])
      case '4':
        return !checkIsValidArray(accessorialsStep.accessorials)
      case '5':
        return !checkIsValidArray(externalPortals)
      case '6':
        return !checkIsValidArray(specialRequirements)
      case '7':
        return false
      default:
        return false
    }
  }, [tab, customerInfo])

  useEffect(() => {
    setEditing(false)
  }, [tab])

  return (
    <>
      <ButtonContainer>
        {tab === '7' && (
          <Button type='warn' onClick={() => setDocumentModalVisible(true)}>
            Add Document
          </Button>
        )}
        {tab !== '7' &&
          (isEditing ? (
            <>
              <Button type='secondary' className='mr-4' onClick={handleCancel}>
                Cancel
              </Button>
              <Button type='success' loading={saveLoading} disabled={disabled} onClick={handleSave}>
                Save changes
              </Button>
            </>
          ) : (
            <Button type='warn' onClick={() => setEditing(true)}>
              Edit
            </Button>
          ))}
      </ButtonContainer>
      <DocumentModal isVisible={isDocumentModalVisible} setVisible={setDocumentModalVisible} />
    </>
  )
}

const ButtonContainer = tw.div`
  bg-dark-blue
  w-full
  flex
  items-center
  justify-center
  p-4
  rounded-t-lg
`
