import { BaseContact } from './customer'
import { Location } from './location'

export type Carrier = {
  type?: 'carrier'
  id: number
  name?: string
  network?: {
    type?: number
    typeText?: string
  }[]
}

export type CarrierCustomerFreightRelationship = {
  type?: 'carrier'
  id: number
  name?: string
  status?: string
}

export type CarrierDetails = Carrier & {
  addressDisplay?: string
  arAddressDisplay?: string
  arAddress?: string
  arCity?: string
  arContactName?: string
  arCaPostalCode?: string
  arCaProvince?: string
  arCountry?: string
  arEmail?: string
  arName?: string
  arPhone?: string
  arRemitEmail?: string
  arState?: string
  arZipcode?: string
  address?: string
  city?: string
  stateProvinceRegion?: string
  country?: string
  blacklist?: boolean
  dotNumber?: string
  mcNumber?: string
  customerCompanies?: Carrier[]
  email?: string
  phone?: string
  scacNumber?: string
  isBusiness?: boolean
  ein?: string
  ssn?: string
  is1099Eligible?: string
  phoneTwo?: string
  phoneThree?: string
  daysBetweenEmails?: number
  carrierManager?: {
    id: number
    name: string
  }
  factoringSalesRep?: {
    id: number
    name: string
  }
  payTerms?: string
  quickbooksId?: number
  postalcode?: string
  quickPay?: boolean
  approvedToHaulStatus?: ApprovedToHaulStatus
  lat?: number
  lon?: number
  freightQuickbooksId?: number
  financeQuickbooksId?: number
  referralUrl?: string
  isFactoringInternally?: boolean
  truckNumbers?: (string | null | undefined)[]
  trailerNumbers?: (string | null | undefined)[]
  factoringFeePercentage?: number
  paymentMethodType?: string
  shipmentCount?: number
  carrierManagerDisplay?: string
  baseLocation?: string
  name?: string
  id?: number
}

// These fields come directly from the details endpoint
export type CarrierContact = BaseContact & {
  name: string
  id: number
  isAccounting: boolean
  isClaims: boolean
  isDispatch: boolean
  isDriver: boolean
  isFinance: boolean
  isPrimary: boolean
  sendSmsOffers: boolean
  sendEmailOffers: boolean
}

// These fields are computed by the `validateContact` helper function
export type ValidatedCarrierContact = CarrierContact & {
  isNameValid: boolean
  isEmailValid: boolean
  isPhoneValid: boolean
}

export type CarrierInsurance = {
  coverageAmount: number | null
  createdAt: string
  expirationDate: string
  id: number
  insuranceType: number
  insuranceTypeDisplay: string
  notes: string
  policyNumber: string
  producer: string
  provider: string
  updatedAt: string
}

export type CarrierEquipment = {
  id?: number
  equipmentType?: string
  unitType?: number
  unitTypeDisplay?: string
  quantity?: number
  new?: boolean
}

export type EquipmentType = { id: number; name: string }

export type EquipmentAccessory = { id: number; name: string }

export type CarrierLane = {
  id: number
  srcLocation: Location
  dstLocation: Location
  createdAt: string
  updatedAt: string
  expiredAt: string | null
  archivedAt: string | null
  matchDate: string | null
  quantityTrucks: number
  quantityLoads: number
  carrierBid: string | null
  source: number
  sourceDisplay: string
  equipmentType: string
  matchType: number
  matchTypeDisplay: string
  miles: number
  availabilityStart: string | null
  availabilityEnd: string | null
}

export type CarrierLoadOffer = {
  carrier?: { id: string | number; text: string }
  carrierTotal?: string
  carrierName?: string
  mcNumber?: string
  dotNumber?: string
  phone?: string
  email?: string
  isNewCarrier: boolean
  notes?: string
}
export type CarrierPayment = {
  id: number
  loadMargin: {
    carrierTotalPrice?: number
    customerTotalPrice?: number
  }
  loadId: number
  invoiceNumber: string
  carrierName: string
  status: number
}

export type BasePaymentMethod = {
  id: number
  isPrimary: boolean
  name: string
  isDeleted?: boolean
}

export type CheckPaymentMethod = BasePaymentMethod & {
  businessName?: string
  addressLine1?: string
  addressLine2?: string
  country?: string
  city?: string
  state?: string
  postalCode?: string
  paymentMethod?: 'CHECK'
  accountNumber?: string
}

export type ACHPaymentMethodAccountType = 'CHECKING' | 'SAVINGS'

export type ACHPaymentMethod = BasePaymentMethod & {
  accountType?: ACHPaymentMethodAccountType
  accountNumber?: string
  routingNumber?: string
  paymentMethod?: 'ACH'
  accountNumberConfirm?: string
  isFinance?: boolean
}

export type FactoringPaymentMethod = BasePaymentMethod & {
  businessName?: string
  addressLine1?: string
  addressLine2?: string
  country?: string
  city?: string
  state?: string
  postalCode?: string
  contactName?: string
  email?: string
  phone?: string
  paymentMethod?: 'FACTORING'
  accountNumber?: string
}

export type CarrierPaymentMethod = CheckPaymentMethod | ACHPaymentMethod | FactoringPaymentMethod

export enum ApprovedToHaulStatus {
  APPROVED = 'APPROVED',
  PENDING = 'PENDING',
  NOT_STARTED = 'NOT_STARTED',
}

export type CarrierLookupHighway = {
  attributes: {
    header: {
      carrierName: string
      mcDot: {
        mc?: string
        dot?: string
        scac?: string
      }
      dispatchContact: {
        phoneNumber?: string
        email?: string
        address?: string
      }
      common?: string
      contract?: string
      broker?: string
      operatingStatus?: string
      certifications?: string
    }
    authority?: {
      common: string
      contract: string
      broker: string
      licensedCapabilities: Array<string>
      authorityHistory?: Array<{
        authorityType?: string
        originalAction?: string
        actionDate?: string
        dispositionAction?: string
        dispositionDate?: string
      }>
    }
    insurance: {
      insurancePolicies: Array<{
        insuranceType: string
        insuranceStatus: string
        insurerName: string
        policy: string
        effectiveDate: string
        coverage: string
        cancellationDate: string
      }>
    }
    assessment?: {
      authorization: string
      attributes: Array<{ text: string; isWarning: boolean }>
    }
    insights?: {
      operationInsights?: Array<string>
      associationInsights?: Array<string>
      locationInsights?: Array<string>
    }
    contactInformation: {
      names: {
        [key: string]: string
      }
      phoneNumbers: {
        [key: string]: string
      }
      emails: {
        [key: string]: string
      }
      addresses: {
        [key: string]: string
      }
    }
    alerts?: {
      identityAlerts?: {
        tags: Array<string>
        alertDetails: Array<{
          'alert#': string
          timePeriod: string
          alertType: string
          incidentDetails: string
        }>
      }
    }
  }
  urlCarrierDetails: string
  isCarrierApproved: boolean
  carrierCompany?: number
}
