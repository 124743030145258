import { LoadStatus } from '@common'

import {
  LoadTag,
  MenuSection,
  MultiStopQuotingParams,
  SearchFilters,
  Stop,
  UnitType,
} from './types'

export const defaultPath = '/quotes'

export const rejectReasons = [
  'EXO rate is too high',
  'Wrong information',
  'Found better rates',
  'Load was cancelled',
  'Slow response time',
  'Customer price is too low',
  'Other',
]

export const customerQuoteStatuses = ['Accepted', 'OD Accepted', 'Pending', 'Rejected', 'Expired']

export const initialFilters: SearchFilters = {
  query: '',
  status: '',
  equipmentType: '',
  origin: '',
  destination: '',
  originCityAutocomplete: null,
  destinationCityAutocomplete: null,
  dateRange: [null, null],
  miles: '',
  loadId: '',
  bookable: null,
  loadStatuses: [],
  orderTypes: [],
  freightOrderTypes: [],
  archived: false,
  factoringLoads: false,
  userId: '',
  customerCompany: null,
  customerCompaniesList: [],
  accountingContactsList: [],
  carrier: '',
  refId: '',
  poNumber: '',
  puNumber: '',
  pickupDate: [null, null],
  deliveryDate: [null, null],
  commodity: '',
  hold: 'No',
  carrierInvoiceNumber: '',
  carrierName: '',
  mcNumber: '',
  dotNumber: '',
  scacNumber: '',
  haulingStatus: '',
  blacklist: undefined,
  locationName: '',
  checkId: '',
  payment: null,
  ediStatuses: [],
  paymentMethod: null,
  paymentType: null,
  checkNumber: '',
  name: '',
  email: '',
  phone: '',
  state: '',
  city: '',
  originCity: '',
  originState: '',
  destinationCity: '',
  destinationState: '',
  carrierFactoringRequestStatus: '',
  loadFactoringRequestStatus: null,
  deliveryNumber: '',
  csrList: [],
  accountOwnersList: [],
  accountManagersList: [],
  customerQuoteStatus: '',
  quoteId: '',
  dateQuoted: [null, null],
  createdBy: null,
  autobidShowOnly: null,
  isInvoiced: false,
  isReceived: false,
  recipient: '',
  datePaid: [null, null],
  quickPay: false,
  isBilled: false,
  isPaid: false,
  hasCustomerInvoice: null,
  hasCarrierInvoice: null,
  dueDate: [null, null],
  hasPayableDueDate: false,
  loadPaymentType: null,
  hasBOL: null,
  pickupToday: false,
  pickupTodayPlusMinusThree: false,
  deliveryToday: false,
  deliveryTodayPlusMinusThree: false,
  shipperPickupNumber: '',
  invoiceStatus: null,
  invoiceType: null,
  callbackDate: [],
  modes: [],
  noAccountingContact: false,
  accountingContact: undefined,
  quoteStatus: [],
  pickupTime: ['', ''],
  deliveryTime: ['', ''],
  loadTags: [],
  id: '',
  showInternalQuotes: null,
  releasedCarriers: false,
  exoLoad: 'Yes',
}

export const testSearchParams = {
  equipmentType: 'Flatbed',
  origin: {
    city: 'Texarkana',
    state: 'TX',
    countryCode: 'USA',
    postalCode: '00000',
    position: { lat: 0, lng: 0 },
  },
  destination: {
    city: 'Los Angeles',
    state: 'CA',
    countryCode: 'USA',
    postalCode: '00000',
    position: { lat: 0, lng: 0 },
  },
}

export const quoteLoadsStatuses = ['Pending', 'No bid', 'Rejected', 'Won', 'Won and Accepted']

const environment = import.meta.env.VITE_ENVIRONMENT
export const isLocal = environment === 'local'
export const isPreview = environment === 'preview'
export const isStage = environment === 'stage'
export const isProduction = environment === 'production'

// SENTRY_ENV when defined, otherwise use VITE_ENVIRONMENT when Stage or Prod
// otherwise null
export const sentryEnv = import.meta.env.VITE_SENTRY_ENV
  ? import.meta.env.VITE_SENTRY_ENV.toLowerCase()
  : ((isProduction || isStage) && environment) || null

export const countries = [
  {
    id: 0,
    name: 'USA',
    value: 'USA',
  },
  {
    id: 1,
    name: 'Canada',
    value: 'CAN',
  },
  {
    id: 2,
    name: 'Mexico',
    value: 'MX',
  },
]

export const orderTypes = [
  {
    id: 1,
    label: 'Spot',
  },
  {
    id: 2,
    label: 'EDI',
  },
  {
    id: 3,
    label: 'Accepted Tender',
  },
  {
    id: 4,
    label: 'Factoring',
  },
]

export const chargeTypes = [
  {
    id: 1,
    name: 'Flat Rate',
  },
  {
    id: 2,
    name: 'Per Day',
  },
  {
    id: 3,
    name: 'Per Hour',
  },
  {
    id: 4,
    name: 'Per Mile',
  },
]

export const initialNewAccessorial = {
  accessorialType: {
    name: '',
    id: null,
    reimbursement: false,
  },
  chargeTo: {},
  chargeType: {},
  quantity: '',
  amount: '',
  reason: '',
  prepaid: false,
  carrierDeduction: false,
}

export const initialLane = {
  id: -1,
  srcLocation: {},
}

export const ediEventTypeChoices = [
  {
    name: 'Pickup Appointment',
    value: 1,
  },
  {
    name: 'Delivery Appointment',
    value: 2,
  },
  {
    name: 'Arrived at Shipper',
    value: 3,
  },
  {
    name: 'Loaded',
    value: 4,
  },
  {
    name: 'Check Call',
    value: 5,
  },
  {
    name: 'Arrived at Consignee',
    value: 6,
  },
  {
    name: 'Delivered',
    value: 7,
  },
  {
    name: 'Estimated Delivery',
    value: 8,
  },
]

export const accountTypes = ['CHECKING', 'SAVINGS']
export const paymentMethodTypes = ['ACH', 'CHECK', 'FACTORING']
export const achBusinessBranch = ['Freight', 'Finance']

export const initialCSV = {
  jobType: {},
  customer: {},
  file: null,
  fileName: '',
}

export const csvStatuses = [
  {
    id: 1,
    name: 'queued',
  },
  {
    id: 2,
    name: 'success',
  },
  {
    id: 3,
    name: 'running',
  },
  {
    id: 4,
    name: 'error',
  },
]

export const csvTemplates = [
  {
    name: 'EDI Appointments',
    link: 'https://docs.google.com/spreadsheets/d/e/2PACX-1vQU8jD-brHRZgv7JF0t7yiIlsA4jqKJIMEFMsi3PoBryWVmP5pMTzO7HoZHXLo2YbwEkvaJUKL76iF6/pub?gid=0&single=true&output=csv',
  },
  {
    name: 'Contract Lane',
    link: 'https://docs.google.com/spreadsheets/d/e/2PACX-1vQmwU1g6xwKBMeE9Ogc-M-xpq8O9i5b9O8lUc-UPrvraoFEwD6ofIYKAm7eTNTqk_Eju66LPirtQ5UL/pub?output=csv',
  },
]

export const modeTypes = [
  {
    id: 1,
    name: 'FTL',
  },
  {
    id: 2,
    name: 'LTL',
  },
  {
    id: 3,
    name: 'PARTIAL',
  },
]

export const initialLoadInfo = {
  equipmentType: '',
  orderType: { id: 0, label: '' },
  mode: { id: 0, name: '' },
  refId: '',
  poNumber: '',
  csr: { id: 0, text: '' },
  fsr: { id: 0, text: '' },
  accountManager: { id: 0, text: '' },
  carrierInvoice: '',
  customer: { id: '', text: '' },
  agent: { id: '', text: '' },
  billingReferenceNumber: '',
  isHazmat: false,
  hazmatUhn: '',
  isHighValue: false,
  highValueAmount: '',
  isTeamLoad: false,
  isOversize: false,
  loadboardPriceEnable: false,
  customerPrice: 0,
  carrierPrice: 0,
}

export const initialCommodity = {
  weight: '',
  temperature: '',
  length: '',
  width: '',
  height: '',
  commodity: '',
  description: '',
}

export const stopTypes = [
  {
    id: 1,
    name: 'Pickup',
  },
  {
    id: 2,
    name: 'Drop',
  },
]

export const initialStop: Stop = {
  location: { name: '' },
  date: '',
  earlyTime: '',
  lateTime: '',
  phone: null,
  contactName: null,
  notes: null,
  type: undefined,
}

export const initialManifestItem = {
  name: null,
  weight: null,
  quantity: null,
  length: null,
  width: null,
  height: null,
  notes: null,
  itemMappingKey: '',
  stop: null,
  stopType: 0,
}

export const unitTypes: UnitType[] = [
  { id: 1, name: 'Trailer' },
  { id: 2, name: 'Tractor' },
]

export const paymentStatuses = [
  {
    id: 1,
    name: 'Paid',
  },
  {
    id: 2,
    name: 'In Process',
  },
  {
    id: 4,
    name: 'Void',
  },
  {
    id: 5,
    name: 'Expired',
  },
  {
    id: 6,
    name: 'Printed',
  },
  {
    id: 7,
    name: 'Mailed',
  },
  {
    id: 8,
    name: 'Failed',
  },
  {
    id: 9,
    name: 'Refunded',
  },
]

export const paymentMethods = [
  {
    value: 'ACH',
    name: 'ACH',
  },
  {
    value: 'CHECK',
    name: 'Check',
  },
  {
    value: 'FACTORING',
    name: 'Factoring',
  },
]

export const paymentTypes = [
  {
    value: 'STANDARD',
    title: 'Standard (Net 30)',
  },
  {
    value: 'QUICK_PAY',
    title: 'Quick Pay',
  },
  {
    value: 'FACTORING',
    title: 'Factoring',
  },
]

export const loadPaymentTypes = [
  {
    id: 0,
    label: 'Net 30',
  },
  {
    id: 1,
    label: 'Quick Pay',
  },
  {
    id: 2,
    label: 'EXO Factoring',
  },
  {
    id: 3,
    label: 'Non-EXO Factoring',
  },
]

export const initialSearchParams: MultiStopQuotingParams = {
  equipmentType: '',
  origin: {
    isValid: false,
    location: null,
  },
  destination: {
    isValid: false,
    location: null,
  },
  stops: [],
}

export const addressChangeDetectOmitFields = [
  'address',
  'city',
  'state',
  'caProvince',
  'stateProvinceRegion',
  'country',
  'postalCode',
  'caPostalCode',
  'usZipcode',
  'currentPlace',
]

export const ediStatuses = [
  {
    id: 1,
    name: 'Accepted',
  },
  {
    id: 2,
    name: 'Pending',
  },
  {
    id: 3,
    name: 'Rejected',
  },
  {
    id: 4,
    name: 'Update',
  },
  {
    id: 5,
    name: 'Pending Reject',
  },
  {
    id: 6,
    name: 'Cancelled',
  },
]

export const billableLoadStatuses = ['billing in progress', 'completed']

export const trackShipmentUpdateFrequencies = [
  {
    id: 2,
    name: 'Every 20 mins',
    value: 20,
  },
  {
    id: 3,
    name: 'Every 30 mins',
    value: 30,
  },
  {
    id: 1,
    name: 'Every 40 mins',
    value: 40,
  },
  {
    id: 4,
    name: 'Every 50 mins',
    value: 50,
  },
]

export const initialTrackInfo = {
  notifyEmails: [''],
  updateFrequency: 0,
  isTrack: false,
}

export const USStates = [
  'AL',
  'AK',
  'AZ',
  'AR',
  'CA',
  'CO',
  'CT',
  'DE',
  'FL',
  'GA',
  'HI',
  'ID',
  'IL',
  'IN',
  'IA',
  'KS',
  'KY',
  'LA',
  'ME',
  'MD',
  'MA',
  'MI',
  'MN',
  'MS',
  'MO',
  'MT',
  'NE',
  'NV',
  'NH',
  'NJ',
  'NM',
  'NY',
  'NC',
  'ND',
  'OH',
  'OK',
  'OR',
  'PA',
  'RI',
  'SC',
  'SD',
  'TN',
  'TX',
  'UT',
  'VT',
  'VA',
  'WA',
  'WV',
  'WI',
  'WY',
]

export const dimensionsByEquipmentType = {
  Flatbed: {
    weight: '48000',
    length: '48',
    height: '8.5',
    width: '8.5',
  },
  'Step Deck': {
    weight: '47000',
    length: '48',
    height: '8.5',
    width: '8.5',
  },
  Conestoga: {
    weight: '46500',
    length: '47',
    height: '8.2',
    width: '8.6',
  },
  Hotshot: {
    weight: '12000',
    length: '40',
    height: '10',
    width: '8.6',
  },
  'Double Drop': {
    weight: '36000',
    length: '29',
    height: '11.5',
    width: '8.5',
  },
  RGN: {
    weight: '36000',
    length: '29',
    height: '11.5',
    width: '8.5',
  },
  Van: {
    weight: '44000',
    length: '53',
    height: '8.5',
    width: '8.5',
  },
  Reefer: {
    weight: '42500',
    length: '53',
    height: '8.5',
    width: '8.5',
  },
  Box: {
    weight: '10000',
    length: '26',
    height: '8.5',
    width: '8.5',
  },
} as const

export const is1099EligibleChoices = ['Unknown', 'Yes', 'No']

// This contains all equipments that should be shown in the quoting tool.
export const quotingToolSupportedEquipmentTypes = [
  'Flatbed',
  'Step Deck',
  'Van',
  'Reefer',
  'Power Only',
  'Hot Shot',
  'Conestoga',
]

// This contains all equipment types that are currently supported by our pricing model
// This is essentially the same list as quotingToolSupportedEquipmentTypes but with union types (ie: Flatbed and Step Deck)
export const pricingSupportedEquipmentTypes = [
  'Flatbed',
  'Step Deck',
  'Van',
  'Reefer',
  'Power Only',
  'Hot Shot',
  'Conestoga',
  'Flatbed or Step Deck',
  'Flatbed or Step Deck or Van',
  'Van or Reefer',
  'Van Intermodal',
]

export const initialCustomerContact = {
  name: '',
  phone: '',
  email: '',
  notes: '',
  shipperPortalEnabled: false,
  primary: false,
  pickupNotifications: false,
  deliveryNotifications: false,
  driverInfoNotifications: false,
  accounting: false,
  invoice: false,
  receiveNOA: false,
  marketingContact: false,
  receiveMarketingEmails: false,
  isValid: false,
}

export const orgLevels = [
  {
    id: 0,
    name: 'Parent',
    value: '1',
  },
  {
    id: 1,
    name: 'Branch',
    value: '2',
  },
]

export const sizeClassifications = [
  {
    id: 0,
    name: 'Enterprise',
    value: '1',
  },
  {
    id: 1,
    name: 'Mid Market',
    value: '2',
  },
  {
    id: 2,
    name: 'Small Business',
    value: '3',
  },
]

export const equipmentTypeChoices = [
  {
    id: 'Removable Gooseneck',
    name: 'Removable Gooseneck',
  },
  {
    id: 'Van',
    name: 'Van',
  },
  {
    id: 'Reefer',
    name: 'Reefer',
  },
  {
    id: 'Flatbed',
    name: 'Flatbed',
  },
  {
    id: 'Conestoga',
    name: 'Conestoga',
  },
  {
    id: 'Step Deck',
    name: 'Step Deck',
  },
  {
    id: 'Hot Shot',
    name: 'Hot Shot',
  },
  {
    id: 'Power Only',
    name: 'Power Only',
  },
  {
    id: 'Load Out',
    name: 'Load Out',
  },
  {
    id: 'Double Drop',
    name: 'Double Drop',
  },
  {
    id: 'Flatbed or Step Deck',
    name: 'Flatbed or Step Deck',
  },
  {
    id: 'Truck Order Not Used',
    name: 'Truck Order Not Used',
  },
  {
    id: 'Wrecker',
    name: 'Wrecker',
  },
  {
    id: 'Van or Reefer',
    name: 'Van or Reefer',
  },
  {
    id: 'Any',
    name: 'Any',
  },
  {
    id: 'Unknown',
    name: 'Unknown',
  },
  {
    id: 'Flatbed or Step Deck or Van',
    name: 'Flatbed or Step Deck or Van',
  },
  {
    id: 'Van Intermodal',
    name: 'Van Intermodal',
  },
  {
    id: 'Tanker Intermodal',
    name: 'Tanker Intermodal',
  },
  {
    id: 'Reefer Intermodal',
    name: 'Reefer Intermodal',
  },
  {
    id: 'Straight Box Truck',
    name: 'Straight Box Truck',
  },
]

export const loadStatusChoices: Array<object> = [
  {
    name: 'Plan',
    key: LoadStatus.PLAN,
  },
  {
    name: 'Tendered',
    key: LoadStatus.TENDERED,
  },
  {
    name: 'Tender Accepted',
    key: LoadStatus.TENDER_ACCEPTED,
  },
  {
    name: 'Tender Rejected',
    key: LoadStatus.TENDER_REJECTED,
  },
  {
    name: 'Available',
    key: LoadStatus.AVAILABLE,
  },
  {
    name: 'Booked',
    key: LoadStatus.BOOKED,
  },
  {
    name: 'On Track',
    key: LoadStatus.ON_TRACK,
  },
  {
    name: 'Off Track',
    key: LoadStatus.OFF_TRACK,
  },
  {
    name: 'Delivered',
    key: LoadStatus.DELIVERED,
  },
  {
    name: 'Ready To Bill',
    key: LoadStatus.READY_TO_BILL,
  },
  {
    name: 'Billing In Progress',
    key: LoadStatus.BILLING_IN_PROGRESS,
  },
  {
    name: 'Claim',
    key: LoadStatus.CLAIM,
  },
  {
    name: 'Completed',
    key: LoadStatus.COMPLETED,
  },
].map(status => ({ ...status, key: status.key.replaceAll('_', '+') }))

export const carrierInsuranceTypes = [
  {
    id: 1,
    name: 'Cargo',
  },
  {
    id: 2,
    name: 'General Liability',
  },
  {
    id: 3,
    name: 'Auto Liability',
  },
  {
    id: 4,
    name: 'Trailer Interchange',
  },
  {
    id: 5,
    name: 'Non Owned Trailer',
  },
  {
    id: 6,
    name: 'Workers Compensation',
  },
]

export const csvImportJobTypes = [
  {
    id: 1,
    name: 'Available Loads - Spot/Available',
    hidden: true,
  },
  {
    id: 2,
    name: 'EDI Appt',
  },
  {
    id: 3,
    name: 'Fuel Surcharge',
  },
  {
    id: 4,
    name: 'Tendered Load Import - Plan/Accepted Tender',
    hidden: true,
  },
  {
    id: 5,
    name: 'Contract Lane',
  },
]

export const carrierLoadOfferStatus = [
  {
    id: 1,
    label: 'Pending',
  },
  {
    id: 2,
    label: 'Accepted',
  },
  {
    id: 3,
    label: 'Rejected',
  },
  {
    id: 4,
    label: 'Approved',
  },
  {
    id: 5,
    label: 'Denied',
  },
  {
    id: 6,
    label: 'Carrier Invited',
  },
]

// This stop is hardcoded, it's required if we have a stop in Mexico in the Quoting Tool
export const requiredLaredoStop = {
  id: 'laredo-stop',
  isValid: false,
  stopType: { id: 3, label: 'Required' },
  location: {
    city: 'Laredo',
    state: 'TX',
    country: 'US',
    latitude: 27.5625,
    longitude: -99.4874,
    title: 'Laredo, TX, US',
  },
}

export const invoiceStatuses = [
  { label: 'New', key: 'NEW', color: 'border-green-border bg-green-border/[.2]' },
  {
    label: 'In Progress/Pending',
    key: 'IN_PROGRES',
    color: 'border-orange-border bg-orange-border/[.2]',
  },
  {
    label: 'Payment En Route',
    key: 'PAYMENT_EN_ROUTE',
    color: 'border-lighter-blue-border bg-lighter-blue-border/[.2]',
  },
  {
    label: 'Payment En Route (Elapsed)',
    key: 'PAYMENT_EN_ROUTE_ELAPSED',
    color: 'border-blue-border bg-blue-border/[.2]',
  },
  {
    label: 'Schedule To Pay Date Confirm',
    key: 'SCHEDULE_TO_PAY',
    color: 'border-aquamarine-border bg-aquamarine-border/[.2]',
  },
  {
    label: 'Misdirected Payment (Carrier)',
    key: 'MISDIRECTED_PAYMENT_CARRIER',
    color: 'border-red-border bg-red-border/[.2]',
  },
  {
    label: 'Misdirected Payment (Factor)',
    key: 'MISDIRECTED_PAYMENT_FACTOR',
    color: 'border-red-border bg-red-border/[.2]',
  },
  {
    label: 'Unable To Contact',
    key: 'UNABLE_TO_CONTACT',
    color: 'border-peach-border bg-peach-border/[.2]',
  },
  {
    label: 'Pending Chargeback',
    key: 'PENDING_CHARGEBACK',
    color: 'border-lilac-border bg-lilac-border/[.2]',
  },
  {
    label: 'Pending Recovery',
    key: 'PENDING_RECOVERY',
    color: 'border-violet-border bg-violet-border/[.2]',
  },
  {
    label: 'Active Recovery',
    key: 'ACTIVE_RECOVERY',
    color: 'border-yellow-border bg-yellow-border/[.2]',
  },
  {
    label: 'Paperwork Issue',
    key: 'PAPERWORK_ISSUE',
    color: 'border-dark-blue-border bg-dark-blue-border/[.2]',
  },
  {
    label: 'Invoice Re-Billed',
    key: 'INVOICE_RE_BILL',
    color: 'border-cyan-border bg-cyan-border/[.2]',
  },
]

export const freightDocumentTypeChoices: Array<object> = [
  {
    value: 'GENERAL',
    label: 'General',
  },
  {
    value: 'INSURANCE',
    label: 'Insurance',
  },
  {
    value: 'MC_CERTIFICATE',
    label: 'MC Certificate',
  },
  {
    value: 'W9',
    label: 'W9',
  },
  {
    value: 'TRAILER_INTERCHANGE_AGREEMENT',
    label: 'Trailer Interchange Agreement',
  },
  {
    value: 'LETTER_OF_ASSIGNMENT',
    label: 'Letter Of Assignment',
  },
  {
    value: 'NOTICE_OF_ASSIGNMENT',
    label: 'Notice Of Assignment',
  },
]
export const financeDocumentTypeChoices: Array<object> = [
  {
    value: 'RATE_CONFIRMATION',
    label: 'Rate Confirmation',
  },
  {
    value: 'BOL',
    label: 'Bill of Lading',
  },
  {
    value: 'MISC',
    label: 'Miscellaneous',
  },
  {
    value: 'BOL_AND_OR_RATE_CONFIRMATION',
    label: 'BOL and/or Rate Confirmation',
  },
  {
    value: 'NOA',
    label: 'Notice of Assignment',
  },
  {
    value: 'AGREEMENT',
    label: 'Factoring Agreement',
  },
  {
    value: 'INTERNAL_LETTER_OF_RELEASE',
    label: 'Letter of Release From Exo Freight',
  },
  {
    value: 'EXTERNAL_LETTER_OF_RELEASE',
    label: 'Letter of Release from External Company',
  },
]

export const freightOrFinanceChoices: Array<object> = [
  {
    value: 'freight',
    label: 'Freight',
  },
  {
    value: 'finance',
    label: 'Finance',
  },
]

export const recoveryTargetAccount: Array<string> = ['OTHER', 'RESERVE']

export const carrierCallOutcomeChoices = [
  { label: 'Booked', value: 'BOOKED' },
  { label: 'Did Not Answer', value: 'DID_NOT_ANSWER' },
  { label: 'No Capacity', value: 'NO_CAPACITY' },
  { label: 'Lane Issue', value: 'LANE_ISSUE' },
  { label: 'Other', value: 'OTHER' },
]

export const mobileLoadMenuItems: MenuSection[] = [
  { id: 'actions', title: 'Actions' },
  { id: 'tracking-update', title: 'EDI & Tracking Update' },
  {
    title: 'Overview',
    id: 'overview',
    items: [
      { id: 'route', title: 'Route' },
      { id: 'tender', title: 'Tender' },
      { id: 'info', title: 'Load Details' },
      { id: 'carrier', title: 'Carrier Info' },
      { id: 'commodity', title: 'Total Dimensions' },
      { id: 'accessorials', title: 'Accessorials' },
      { id: 'dates', title: 'Load Updates' },
    ],
  },
  {
    title: 'Tracking',
    id: 'tracking',
    items: [
      { id: 'notes', title: 'Notes' },
      { id: 'documents', title: 'Files' },
      { id: 'audit', title: 'Audit' },
      { id: 'tracking-events', title: 'Tracking Events', noActions: true },
    ],
  },
]

export const mobileCarrierMenuItems: MenuSection[] = [
  {
    title: 'Details',
    id: 'details',
    items: [
      { id: 'profile', title: 'Profile' },
      { id: 'payment-methods', title: 'Payment Methods' },
      { id: 'address', title: 'Address' },
      { id: 'contact', title: 'Contacts' },
      { id: 'insurance', title: 'Insurance' },
    ],
  },
  { id: 'matches', title: 'Matches' },
  { id: 'quotes', title: 'Quotes' },
  { id: 'shipments', title: 'Shipments' },
  { id: 'payments', title: 'Payments' },
  { id: 'documents', title: 'Documents' },
  { id: 'lanes', title: 'Lanes' },
  { id: 'equipment', title: 'Equipment' },
  { id: 'notes', title: 'Notes' },
]

export const loadTags: LoadTag[] = [
  { label: 'Hot', key: 'isHotLoad', background: 'bg-light-carrot' },
  { label: 'Flexible', key: 'isFlexibleLoad', background: 'bg-kiwi' },
  { label: 'Rolled', key: 'isRolledLoad', background: 'bg-peach' },
]

export const loadBookMethods = [
  // These need to be included so that they can display properly in TMS if a carrier books via
  // the carrier portal, but they shouldn't be listed as selectable options.
  { label: 'Carrier Portal', value: 'CARRIER_PORTAL_BOOKED', hidden: true },
  { label: 'Carrier Bid Approved', value: 'CARRIER_BID_APPROVED', hidden: true },
  //////////////////////////////////////////////////////////////////////////////
  { label: 'TMS Capacity List', value: 'TMS_CAPACITY_LIST' },
  { label: 'Truckstop (Inbound Call/Text/Email)', value: 'TRUCKSTOP_INBOUND' },
  { label: 'Truckstop (Outbound Call/Text/Email)', value: 'TRUCKSTOP_OUTBOUND' },
  { label: 'DAT (Inbound Call/Text/Email)', value: 'DAT_INBOUND' },
  { label: 'DAT (Outbound Call/Text/Email)', value: 'DAT_OUTBOUND' },
  { label: 'Truckerpath', value: 'TRUCKERPATH' },
  { label: 'Trucksmarter', value: 'TRUCKSMARTER' },
  { label: '123Loadboard', value: 'ONETWOTHREE_LOADBOARD' },
  { label: 'Other (Specify)', value: 'TMS_BOOKED' },
]

export const initialNewContractLane = {
  equipmentType: '',
  origin: null,
  destination: null,
  customer: null,
  loadsPerWeek: '1',
  ratePerMile: '',
  minimumCharge: '',
  fuelCharge: '',
  commodity: '',
  allIn: '',
  weight: '',
  length: '',
  width: '',
  height: '',
  notes: '',
  startDate: null,
  endDate: null,
}

export const shipperQuotingToolSupportedEquipmentTypes = [
  'Flatbed',
  'Step Deck',
  'Removable Gooseneck',
  'Conestoga',
  'Hot Shot',
  'Reefer',
  'Van',
]

/**
 * A string representing a list of MIME types for common document and image formats.
 * This can be used to specify accepted file types in file input elements or for file validation purposes.
 *
 * Supported MIME types include:
 * - Image formats: JPEG, PNG, BMP, WEBP, GIF
 * - Document formats: PDF, Word (both DOC and DOCX), Excel (XLS, XLSX, and XLSM), and CSV
 *
 * @constant
 * @type {string}
 */
export const genericDocumentMimeType =
  'image/jpeg,image/png,image/bmp,image/webp,image/gif,application/pdf,application/vnd.openxmlformats-officedocument.wordprocessingml.document,application/msword,application/vnd.ms-excel,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,application/vnd.ms-excel.sheet.macroEnabled.12,text/csv'
