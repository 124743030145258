import { Button, ConfirmationDialog, LoaderSpinner } from '@components'
import { useState } from 'react'
import tw from 'tailwind-styled-components'

type ModalFooterProps = {
  isNextDisabled: boolean
  showBackButton: boolean
  showFinishButton: boolean
  onNextClick: () => void
  onBackClick: () => void
  onSaveAndFinish: () => void
  setVisible: (value: boolean) => void
  isLastStep: boolean
  submitButtonText: string
  loading?: boolean
}

export const ModalFooter = ({
  isNextDisabled,
  showBackButton,
  showFinishButton,
  onNextClick,
  onBackClick,
  onSaveAndFinish,
  setVisible,
  isLastStep,
  submitButtonText,
  loading,
}: ModalFooterProps) => {
  const [isConfirmModalVisible, setConfirmModalVisible] = useState(false)

  return (
    <>
      <Container>
        <Button
          type='secondary'
          innerClassName='!border-none'
          onClick={() => setConfirmModalVisible(true)}
        >
          Cancel
        </Button>
        <div className='flex items-center gap-4'>
          {showFinishButton && !isLastStep && (
            <div className='flex items-center'>
              <Button noBackground innerClassName='!border-none' onClick={onSaveAndFinish}>
                Save & Finish
              </Button>
              {loading && <LoaderSpinner size={4} />}
            </div>
          )}
          {showBackButton && (
            <Button type='secondary' onClick={onBackClick}>
              Go Back
            </Button>
          )}
          <Button
            disabled={isNextDisabled}
            type={isLastStep ? 'warn' : 'primary'}
            loading={isLastStep && loading}
            onClick={onNextClick}
          >
            {isLastStep ? submitButtonText : 'Next'}
          </Button>
        </div>
      </Container>
      <ConfirmationDialog
        title='Are you sure you want to cancel?'
        isVisible={isConfirmModalVisible}
        cancelButtonText='No, continue'
        confirmButtonText='Yes, cancel'
        confirmButtonType='danger'
        setVisible={setConfirmModalVisible}
        onConfirm={() => setVisible(false)}
      />
    </>
  )
}

const Container = tw.div`
  bg-light-blue
  w-full
  flex
  justify-between
  items-center
  px-4
  max-h-8
`
