import { Checkbox, Toggle, Tooltip } from '@components'
import { InformationCircleIcon } from '@heroicons/react/24/solid'
import { useMemo } from 'react'

import { OnboardingCustomerContact } from '../../../common/types'
import { formatPhone, getContactHeader } from '../../../common/utils'
import { HalfWidthDetailItem } from '../../DetailItem'
import { getCheckboxes } from '../utils'
import { SectionHeader } from './SectionHeader'

export const ContactView = ({
  contact,
  index,
  isAccounting,
  withTooltip,
}: {
  contact: OnboardingCustomerContact
  index: number
  isAccounting?: boolean
  withTooltip?: boolean
}) => {
  const checkboxes = useMemo(() => getCheckboxes(contact), [contact])

  const checkboxInputs = isAccounting ? checkboxes : checkboxes.filter(cb => !cb.isAccounting)

  const checkedInputs = checkboxInputs.filter(checkbox => checkbox.value)

  return (
    <div className='mt-12 first:mt-0'>
      <SectionHeader className='flex items-center'>
        {getContactHeader(index, isAccounting)}
        {withTooltip && (
          <Tooltip content='Navigate to the accounting tab to update billing contact information'>
            <InformationCircleIcon className='w-5 ml-2' />
          </Tooltip>
        )}
      </SectionHeader>
      <div className='flex flex-col gap-y-3'>
        <HalfWidthDetailItem label='Name'>{contact.name}</HalfWidthDetailItem>
        <div className='grid grid-cols-2 gap-y-3'>
          <HalfWidthDetailItem label='Phone'>{formatPhone(contact.phone)}</HalfWidthDetailItem>
          <HalfWidthDetailItem label='Email'>{contact.email}</HalfWidthDetailItem>
        </div>
        <HalfWidthDetailItem label='Notes'>{contact.notes}</HalfWidthDetailItem>
        <div className='grid grid-cols-2 gap-y-3'>
          <HalfWidthDetailItem label='Created By'>{contact.createdBy}</HalfWidthDetailItem>
          <HalfWidthDetailItem label='Updated By'>{contact.updatedBy}</HalfWidthDetailItem>
        </div>
        {!!checkedInputs.length && (
          <div className='grid grid-cols-2'>
            {checkedInputs.map(checkbox => (
              <Checkbox
                key={checkbox.key}
                disabled
                className='mt-3'
                isChecked={checkbox.value}
                title={checkbox.label}
              />
            ))}
          </div>
        )}
        <div className='flex items-center justify-between mt-3'>
          <div className='flex items-center'>
            <Toggle disabled value={contact.shipperPortalEnabled} />
            <div className='ml-2'>Enable Shipper Account</div>
          </div>
        </div>
      </div>
    </div>
  )
}
