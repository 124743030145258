import './Autocomplete.scss'

import { PlusCircleIcon } from '@heroicons/react/24/outline'
import React, { FormEvent, forwardRef, useEffect, useState } from 'react'
import Autosuggest, { RenderInputComponent } from 'react-autosuggest'
import tw from 'tailwind-styled-components'

type AutocompleteProps = {
  value: string
  onValueChange: Function
  label?: string
  className?: string
  field?: string
  items: Array<any>
  setValue: Function
  style?: any
  onBlur?: Function
  placeholder?: string
  renderInputComponent?: RenderInputComponent
  renderSuggestion?: (suggestion: any) => JSX.Element
  selectOnHighlight?: boolean
  addable?: boolean
  addableText?: string
  onAdd?: () => void
  required?: boolean
}

const Autocomplete = forwardRef<HTMLInputElement, AutocompleteProps>(
  (
    {
      value = '',
      onValueChange,
      label,
      className = '',
      field = '',
      items = [],
      setValue,
      style = {},
      onBlur = () => {},
      placeholder,
      renderInputComponent = inputProps => <input {...inputProps} />,
      selectOnHighlight,
      addable,
      addableText,
      renderSuggestion,
      onAdd = () => {},
      required,
    }: AutocompleteProps,
    ref,
  ) => {
    const [query, setQuery] = useState(value || '')

    const [suggestions, setSuggestions] = useState(items)

    const localSuggestions = addable
      ? [{ id: -1, [field]: 'Add new' }, ...suggestions]
      : suggestions

    useEffect(() => {
      setSuggestions(items)
    }, [items])

    useEffect(() => {
      if ((value && query && query !== value) || (value && !query)) {
        setQuery(value)
      }
      if (!value && query) setQuery('')
    }, [value])

    const onChange = (event: any, { newValue }: any) => {
      setQuery(newValue)
    }

    const renderSuggestionOverwrite = (suggestion: { [x: string]: any }) => (
      <div>
        {addable && suggestion.id === -1 ? (
          <div className='flex items-center text-dark-gray'>
            <PlusCircleIcon className='w-5 mr-2' />
            <span>Add new {addableText}</span>
          </div>
        ) : renderSuggestion ? (
          renderSuggestion(suggestion)
        ) : (
          suggestion[field]
        )}
      </div>
    )

    const onSuggestionSelected = (event: FormEvent, { suggestion }: any) => {
      if (suggestion.id === -1) {
        setQuery('')
        setValue('')
        onAdd()
      } else {
        setValue(suggestion)
      }
    }

    const inputProps = {
      placeholder,
      value: query ?? '',
      onChange,
      style,
      ref,
      onBlur: () => {
        if (onBlur) onBlur(query)
        setQuery(value || '')
      },
    }

    return (
      <div className={className}>
        {label && (
          <div className='mb-1'>
            <Label>
              {label}
              {required && <span className='text-error'> *</span>}
            </Label>
          </div>
        )}
        <Autosuggest
          getSuggestionValue={(suggestion: { [x: string]: any }) => suggestion[field]}
          inputProps={inputProps}
          renderInputComponent={renderInputComponent}
          renderSuggestion={renderSuggestionOverwrite}
          suggestions={localSuggestions || []}
          onSuggestionsClearRequested={() => setSuggestions([])}
          onSuggestionSelected={onSuggestionSelected}
          onSuggestionsFetchRequested={({ value }: any) => onValueChange(value)}
          onSuggestionHighlighted={({ suggestion }: any) => {
            if (suggestion !== null && selectOnHighlight) setValue(suggestion)
          }}
        />
      </div>
    )
  },
)

const Label = tw.label`
  font-poppins
  block
  text-dark-gray
  mb-1
  self-start
  text-xs
`

export { Autocomplete }
