import { LoaderSpinner } from '@components'
import { Menu } from '@headlessui/react'
import { ChevronDownIcon } from '@heroicons/react/24/solid'
import tw from 'tailwind-styled-components'

import { useAppSelector } from '../../../../app/hooks'
import { EaseInOutTransition } from '../../../EaseInOutTransition'

export type SelectButtonProps = {
  onClick: () => void
  label: string
  options: Option[]
}

type Option = {
  label: string
  onClick: () => void
}

export const SelectButton = ({ label, options, onClick }: SelectButtonProps) => {
  const loading = useAppSelector(state => state.loads.loading.createLoad)

  return (
    <Menu as='div' className='w-[180px]'>
      <Container>
        <ButtonLabel onClick={onClick}>{label}</ButtonLabel>
        <div className='h-5 w-px bg-dark-blue' />
        {!loading ? (
          <Menu.Item>
            <MainButton>
              <Chevron />
            </MainButton>
          </Menu.Item>
        ) : (
          <LoaderSpinner className='mx-4 my-2' size={4} />
        )}
      </Container>
      <EaseInOutTransition>
        <Dropdown>
          {options.map((opt: Option) => (
            <MenuItem key={opt.label} onClick={opt.onClick}>
              {opt.label}
            </MenuItem>
          ))}
        </Dropdown>
      </EaseInOutTransition>
    </Menu>
  )
}

const Chevron = tw(ChevronDownIcon)`
  mx-4
  my-2
  w-4
  text-dark-blue
`

const Container = tw.div`
  flex
  items-center
  bg-brand-accent
  rounded-md
  font-semibold
  w-[180px]
  whitespace-nowrap
  uppercase
`

const ButtonLabel = tw.div`
  px-4
  py-2
  transition-all
  text-sm
  text-dark-blue
  cursor-pointer
  hover:opacity-80
  transition-all
`

const MainButton = tw(Menu.Button)`
  hover:opacity-80
  transition-all
  cursor-pointer
`

const Dropdown = tw(Menu.Items)`
  origin-top-right
  -mt-1
  rounded-b-md
  shadow-lg
  ring-1
  ring-black
  ring-opacity-5
  focus:outline-none
  bg-brand-accent
  left-auto
  bottom-auto
  absolute
  right-0
  p-4
  w-fit
  min-w-[180px]
`

const MenuItem = tw(Menu.Button)`
  uppercase
  whitespace-nowrap
  font-semibold
  text-dark-blue
`
