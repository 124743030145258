import { DateInput, Select, TextInput } from '@components'
import { ClockIcon, MapPinIcon } from '@heroicons/react/24/solid'
import { parse } from 'date-fns'
import dayjs from 'dayjs'
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter'
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore'
import { Controller, ControllerRenderProps, FieldValues, useWatch } from 'react-hook-form'

import { useAppSelector, useAppThunkDispatch } from '../../../../app/hooks'
import { formatTimeString, getTimeMaskOptions, keysToCamelCase } from '../../../../common/utils'
import { setPoNumbersObjects } from '../../../../redux/loadsSlice'
import {
  LocationAutocomplete as BaseLocationAutocomplete,
  LocationAutocompleteProps as BaseLocationAutocompleteProps,
} from '../../../Autocomplete'
import { InputContainer } from '../../../InputContainer'
import { StopItem, StopProps } from '../types'
import { DeleteBtn } from './DeleteBtn'
import { InputRow } from './InputRow'
import { Section } from './Section'

dayjs.extend(isSameOrBefore)
dayjs.extend(isSameOrAfter)

export const Stop = ({ control, idx, getStopDeleter }: StopProps) => {
  const stops = useWatch({
    control,
    name: 'stops',
  })

  const stop = stops[idx]

  const getDateUpdater = useChronologicalDateUpdater({ idx, stops })
  const getTimeUpdater = useChronologicalTimeUpdater({ item: stop })

  const dispatch = useAppThunkDispatch()

  const poNumbers = useAppSelector(state => state.loads.poNumbers)

  const isPickUp = stop.stopType === 1
  const tagClassName = isPickUp ? 'bg-in-transit' : 'bg-delayed'
  const tagTitle = idx === 0 ? 'Start' : idx === stops.length - 1 ? 'End' : `Point ${1}`
  const title =
    idx === 0
      ? 'Origin'
      : idx === stops.length - 1
        ? 'Destination'
        : `Stop ${idx} ${isPickUp ? 'Pick-Up' : 'Drop-Off'}`
  const isStartOrEnd = idx === 0 || idx === stops.length - 1

  const poValue = isPickUp
    ? stop.poNumber
    : stop.poNumber
        ?.map((po: number | string) => ({
          id: po,
          name: poNumbers.find(number => po === number.id)?.name,
        }))
        .filter((po: { name: string }) => po.name)

  const handleSetPONumber = (field: any, poNumber: string) => {
    field.onChange(poNumber)
    dispatch(setPoNumbersObjects({ id: stop.id, poNumber }))
  }

  return (
    <Section
      tagClassName={tagClassName}
      tagTitle={tagTitle}
      title={title}
      actionBtn={
        isStartOrEnd ? undefined : <DeleteBtn onClick={getStopDeleter(stop)}>Delete Stop</DeleteBtn>
      }
    >
      <InputRow>
        <InputContainer className='w-full' icon={<MapPinIcon />} iconContainerClassName='mt-5'>
          <Controller
            control={control}
            name={`stops.${idx}.location`}
            render={({ field }) => (
              <LocationAutocomplete
                required
                className='w-1/3'
                field={field as any}
                label='Location'
                searchType='db'
              />
            )}
          />
          {isPickUp ? (
            <Controller
              control={control}
              name={`stops.${idx}.poNumber`}
              render={({ field }) => (
                <TextInput
                  sm
                  className='w-1/3'
                  label='PO Number'
                  {...field}
                  onChange={value => handleSetPONumber(field, value)}
                />
              )}
            />
          ) : (
            <Controller
              control={control}
              name={`stops.${idx}.poNumber`}
              render={({ field }) => (
                <Select
                  isMulti
                  sm
                  choices={poNumbers.filter(number => number.name) || []}
                  className='w-1/3'
                  field='name'
                  label='PO Number'
                  {...field}
                  value={poValue}
                  onChange={(poNumbers: Array<{ id: number | string }>) =>
                    field.onChange(poNumbers.map(poNumber => poNumber.id))
                  }
                />
              )}
            />
          )}
          <Controller
            control={control}
            name={`stops.${idx}.shipmentNumber`}
            render={({ field }) => (
              <TextInput
                sm
                className='w-1/3'
                label={`${isPickUp ? 'Pickup' : 'Delivery'} Number`}
                {...field}
              />
            )}
          />
        </InputContainer>
      </InputRow>
      <InputRow>
        <InputContainer className='w-full' icon={<ClockIcon />} iconContainerClassName='mt-5'>
          <Controller
            control={control}
            name={`stops.${idx}.stopDate`}
            render={({ field }) => (
              <DateInput
                required
                className='w-1/3 h-full'
                label='Date'
                size='sm'
                {...field}
                onChange={getDateUpdater(field)}
              />
            )}
          />
          <Controller
            control={control}
            name={`stops.${idx}.stopEarlyTime`}
            render={({ field }) => (
              <TextInput
                sm
                required
                className='w-1/3'
                label='Early Time'
                maskOptions={getTimeMaskOptions()}
                {...field}
                onChange={getTimeUpdater(field, 'stopEarlyTime')}
              />
            )}
          />
          <Controller
            control={control}
            name={`stops.${idx}.stopLateTime`}
            render={({ field }) => (
              <TextInput
                sm
                required
                className='w-1/3'
                label='Late Time'
                maskOptions={getTimeMaskOptions()}
                {...field}
                onChange={getTimeUpdater(field, 'stopLateTime')}
              />
            )}
          />
        </InputContainer>
      </InputRow>
    </Section>
  )
}

const LocationAutocomplete = ({
  field,
  ...props
}: Omit<BaseLocationAutocompleteProps, 'setValue' | 'value'> & {
  field: ControllerRenderProps<FieldValues, string>
}) => {
  const setValue = (value: any) => {
    if (props.searchType === 'geocode' && value.address) {
      field.onChange({
        name: value.address.label,
        city: value.address.city,
        stateProvinceRegion: value.address.stateCode,
        postalCode: value.address.postalCode,
        country: value.address.countryCode,
      })
    } else if (props.searchType === 'db') {
      field.onChange(keysToCamelCase({ ...value }))
    }
  }

  return (
    <BaseLocationAutocomplete
      {...props}
      {...field}
      setValue={setValue}
      value={field?.value?.name ?? ''}
    />
  )
}

const useChronologicalDateUpdater =
  ({ idx, stops }: { idx: number; stops: StopItem[] }) =>
  (field: any) =>
  (selected: any) => {
    const previousStopDates = stops
      .slice(0, idx)
      .map(stop => stop.stopDate)
      .filter(Boolean)

    const nextStopDates = stops
      .slice(idx + 1, stops.length)
      .map(stop => stop.stopDate)
      .filter(Boolean)

    const isSameOrAfter = (first: Date, second: Date) => dayjs(first).isSameOrAfter(second, 'day')

    const isSameOrBefore = (first: Date, second: Date) =>
      (dayjs(first) as any).isSameOrBefore(second, 'day')

    if (
      !selected ||
      (previousStopDates.every(stopDate => isSameOrAfter(selected, stopDate)) &&
        nextStopDates.every(stopDate => isSameOrBefore(selected, stopDate)))
    ) {
      field.onChange(selected)
    }
  }

type EarlyLateTimeItem = {
  stopEarlyTime: string
  stopLateTime: string
}

const useChronologicalTimeUpdater =
  ({ item }: { item: EarlyLateTimeItem }) =>
  (field: any, fieldName: 'stopEarlyTime' | 'stopLateTime') =>
  (value: string) => {
    if (!value.length) field.onChange(value)
    const original = value
    if (value.length < 4) {
      const completionBase =
        fieldName === 'stopEarlyTime' ? '000' : `${value === '2' ? '3' : '9'}59`

      const completion = completionBase
        .slice(value.length - 1, 4)
        .split('')
        .join('')

      value = `${value}${completion}`
    }

    value = formatTimeString(value)

    const valueDate = timeStringToDate(value)

    const isSameOrAfter = (first: Date, second: Date) => dayjs(first).isSameOrAfter(second)

    const isSameOrBefore = (first: Date, second: Date) =>
      (dayjs(first) as any).isSameOrBefore(second)

    const validTime =
      value &&
      (fieldName === 'stopEarlyTime'
        ? !item['stopLateTime'] ||
          isSameOrBefore(valueDate, timeStringToDate(formatTimeString(item['stopLateTime'])))
        : !item['stopEarlyTime'] ||
          isSameOrAfter(valueDate, timeStringToDate(formatTimeString(item['stopEarlyTime']))))

    if (validTime) {
      field.onChange(original)
    } else {
      field.onChange(original.slice(0, original.length - 1))
    }
  }

const timeStringToDate = (timeStr: string) => parse(timeStr, 'HH:mm', new Date())
