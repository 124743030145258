import { AlphaModal, Form, Select, TextArea, TextInput, Toggle } from '@components'
import { useEffect, useMemo, useState } from 'react'
import tw from 'tailwind-styled-components'

import { useAppSelector, useAppThunkDispatch } from '../../app/hooks'
import { loadBookMethods, trackShipmentUpdateFrequencies } from '../../common/constants'
import { CarrierContact } from '../../common/types'
import { usePrevious } from '../../common/utils'
import { getDispatchContacts } from '../../redux/autocompleteSlice'
import {
  addCarrierContact,
  getCarrierDetails,
  setNewContact,
  updateContactField,
} from '../../redux/carriersSlice'
import {
  setDriverInfo,
  setLoadBookMethod,
  setSelectedCarrier,
  setSelectedContact,
  setTrackInfo,
  setVehicleInfo,
} from '../../redux/loadsSlice'
import { IconButton } from '../Button'
import { HalfWidthDetailItem } from '../DetailItem'
import { CarrierSelect } from '../Selects'

export const EditCarrierForm = ({
  setFormValid = () => {},
  isContractLane,
  showCarrierSelect = true,
}: {
  setFormValid?: (value: boolean) => void
  isContractLane?: boolean
  showCarrierSelect?: boolean
}) => {
  const [isVisible, setVisible] = useState(false)
  const [isDispatcherFormValid, setDispatcherFormValid] = useState(false)
  const [vehicleInfoOptions, setVehicleInfoOptions] = useState<{
    truckNumbers: (string | null | undefined)[]
    trailerNumbers: (string | null | undefined)[]
  }>({ truckNumbers: [], trailerNumbers: [] })

  const dispatch = useAppThunkDispatch()

  const trackingRequest = useAppSelector(state => state.loads.loadDetails.trackingRequest)
  const selectedCarrier = useAppSelector(state => state.loads.selectedCarrier)
  const backupCarrier = useAppSelector(state => state.loads.backupCarrier)
  const selectedContact = useAppSelector(state => state.loads.selectedContact)
  const driverInfo = useAppSelector(state => state.loads.driverInfo)
  const dispatchContactOptions = useAppSelector(state => state.autocomplete.dispatchContactOptions)
  const newContact = useAppSelector(state => state.carriers.newContact)
  const addContactLoading = useAppSelector(state => state.carriers.loading.addCarrierContact)
  const trackInfo = useAppSelector(state => state.loads.trackInfo)
  const load = useAppSelector(state => state.loads.loadDetails)
  const vehicleInfo = useAppSelector(state => state.loads.vehicleInfo)
  const carrierDetails = useAppSelector(state => state.carriers.currentCarrier)
  const loadBookMethod = useAppSelector(state => state.loads.loadBookMethod)
  const prevCarrier = usePrevious(selectedCarrier?.id || -1)

  useEffect(() => {
    if (selectedCarrier?.id && selectedCarrier?.id !== -1) {
      dispatch(getDispatchContacts(selectedCarrier.id))
      dispatch(getCarrierDetails({ carrierId: selectedCarrier.id }))
      if (prevCarrier && prevCarrier !== -1 && prevCarrier !== selectedCarrier?.id) {
        dispatch(setSelectedContact({}))
        dispatch(setVehicleInfo({ truckNumber: null, trailerNumber: null }))
      }
    } else {
      dispatch(setSelectedCarrier({}))
      dispatch(setSelectedContact({}))
      dispatch(setVehicleInfo({ truckNumber: null, trailerNumber: null }))
    }
  }, [selectedCarrier?.id])

  useEffect(() => {
    if (carrierDetails?.id && carrierDetails?.id !== -1)
      setVehicleInfoOptions({
        truckNumbers: carrierDetails.truckNumbers || [],
        trailerNumbers: carrierDetails.trailerNumbers || [],
      })
  }, [carrierDetails?.id])

  const handleAddNewDispatcher = async () => {
    const response = await dispatch(addCarrierContact())
    if (response.meta.requestStatus === 'rejected') return
    dispatch(getDispatchContacts(selectedCarrier.id))
    dispatch(setSelectedContact(response.payload))
    setVisible(false)
  }

  const onNewDispatcherChange = (field: string, value: boolean | string) => {
    dispatch(updateContactField({ index: -1, field, value }))
  }

  const updateTrackInfo = (field: string, value: any) => {
    dispatch(
      setTrackInfo({
        ...trackInfo,
        [field]: value,
      }),
    )
  }

  const updateVehicleInfo = (newData: { truckNumber?: string; trailerNumber?: string }) =>
    dispatch(setVehicleInfo({ ...vehicleInfo, ...newData }))

  useEffect(() => {
    dispatch(
      setNewContact({
        userId: -1,
        name: '',
        phone: '',
        email: '',
        isPrimary: false,
        pickupNotifications: false,
        deliveryNotifications: false,
        driverInfoNotifications: false,
        role: 1,
      }),
    )
  }, [isVisible])

  const truckNumbersOptions = useMemo(
    () =>
      Array.from(
        new Set([...vehicleInfoOptions.truckNumbers, vehicleInfo.truckNumber].filter(Boolean)),
      ),
    [vehicleInfoOptions, vehicleInfo],
  )

  const trailerNumbersOptions = useMemo(
    () =>
      Array.from(
        new Set([...vehicleInfoOptions.trailerNumbers, vehicleInfo.trailerNumber].filter(Boolean)),
      ),
    [vehicleInfoOptions, vehicleInfo],
  )

  return (
    <Form setFormValid={setFormValid}>
      <FormContainer>
        {showCarrierSelect &&
          (trackingRequest?.url ? (
            <>
              <HalfWidthDetailItem label='Carrier'>{selectedCarrier?.text}</HalfWidthDetailItem>
              <div className='grid grid-cols-2 gap-x-2'>
                <HalfWidthDetailItem label='Driver Name'>{driverInfo.name}</HalfWidthDetailItem>
                <HalfWidthDetailItem label='Driver Phone'>{driverInfo.phone}</HalfWidthDetailItem>
              </div>
              <div className='text-error'>
                Turn off tracking to change carrier or driver details. This action will also disable
                current tracking updates.
              </div>
            </>
          ) : (
            <CarrierSelect
              required
              withLabel
              setValue={carrier => dispatch(setSelectedCarrier(carrier))}
              value={selectedCarrier}
            />
          ))}
        <Select
          required
          sm
          addable={selectedCarrier?.id}
          addableText='dispatcher'
          choices={selectedCarrier?.id ? dispatchContactOptions : []}
          className='w-full'
          field='name'
          label='Dispatch Contact'
          value={selectedContact}
          onChange={(value: CarrierContact) => dispatch(setSelectedContact(value))}
          onAdd={() => {
            onNewDispatcherChange('isDispatch', true)
            setVisible(true)
          }}
        />
        <div className='grid grid-cols-2 gap-4'>
          <TextInput disabled sm label='Dispatch Email' value={selectedContact?.email} />
          <TextInput disabled sm label='Dispatch Phone' type='tel' value={selectedContact?.phone} />
        </div>
        {!trackingRequest?.url && !isContractLane && (
          <div className='grid grid-cols-2 gap-4'>
            <TextInput
              sm
              label='Driver Name'
              value={driverInfo.name}
              onChange={(name: string) => dispatch(setDriverInfo({ ...driverInfo, name }))}
            />
            <TextInput
              sm
              label='Driver Phone'
              required={trackInfo.isTrack}
              type='tel'
              value={driverInfo.phone}
              onChange={(phone: string) => dispatch(setDriverInfo({ ...driverInfo, phone }))}
            />
          </div>
        )}
        {!isContractLane && (
          <>
            <div className='grid grid-cols-2 gap-4'>
              <Select
                creatable
                sm
                choices={truckNumbersOptions}
                label='Truck Number'
                openMenuOnClick={!!truckNumbersOptions.length}
                placeholder='Start typing to add a new truck number'
                value={vehicleInfo.truckNumber || {}}
                onChange={(truckNumber: string) => updateVehicleInfo({ truckNumber })}
              />
              <Select
                creatable
                sm
                choices={trailerNumbersOptions}
                label='Trailer Number'
                openMenuOnClick={!!trailerNumbersOptions.length}
                placeholder='Start typing to add a new trailer number'
                value={vehicleInfo.trailerNumber || {}}
                onChange={(trailerNumber: string) => updateVehicleInfo({ trailerNumber })}
              />
            </div>
            <div>
              <Select
                sm
                required
                label='Where did you find this carrier?'
                choices={loadBookMethods}
                value={loadBookMethod.bookMethod}
                onChange={(choice?: { value: string }) =>
                  dispatch(setLoadBookMethod({ bookMethod: choice?.value, additionalInfo: null }))
                }
              />
              {loadBookMethod.bookMethod == 'TMS_BOOKED' && (
                <TextArea
                  className='mt-2'
                  value={loadBookMethod.additionalInfo}
                  onChange={(value: string) =>
                    dispatch(setLoadBookMethod({ ...loadBookMethod, additionalInfo: value }))
                  }
                />
              )}
            </div>
            {!backupCarrier?.carrier && load.orderType !== 4 && (
              <div className='flex justify-end items-center mt-2'>
                <div className='font-semibold mr-4'>Track Shipment</div>
                <Toggle
                  value={trackInfo.isTrack}
                  onChange={(isTrack: boolean) => updateTrackInfo('isTrack', isTrack)}
                />
              </div>
            )}
            {!trackingRequest && !!trackInfo.isTrack && (
              <>
                <>
                  {trackInfo.notifyEmails.map((notifyEmail, index) => (
                    <div key={index} className='flex items-center'>
                      <TextInput
                        sm
                        className='w-full'
                        label={index ? '' : 'Send Updates To'}
                        type='email'
                        value={notifyEmail}
                        onChange={(notifyEmail: string) =>
                          updateTrackInfo(
                            'notifyEmails',
                            trackInfo.notifyEmails.map((val, i) =>
                              i === index ? notifyEmail : val,
                            ),
                          )
                        }
                      />
                      {!!index && (
                        <IconButton
                          className='ml-2'
                          icon='delete'
                          type='danger'
                          onClick={() =>
                            updateTrackInfo(
                              'notifyEmails',
                              trackInfo.notifyEmails.filter((_, i) => i !== index),
                            )
                          }
                        />
                      )}
                    </div>
                  ))}
                </>
                <div className='flex justify-end'>
                  <div
                    className='underline text-link cursor-pointer'
                    onClick={() => updateTrackInfo('notifyEmails', [...trackInfo.notifyEmails, ''])}
                  >
                    Add another email address
                  </div>
                </div>
                <Select
                  sm
                  choices={trackShipmentUpdateFrequencies}
                  className='w-full'
                  field='name'
                  label='Expected Update Frequency'
                  value={trackInfo.updateFrequency}
                  onChange={({ value }: { value: number }) =>
                    updateTrackInfo('updateFrequency', value)
                  }
                />
              </>
            )}
          </>
        )}
        <AlphaModal
          className='w-[500px]'
          confirmButtonLabel='Confirm'
          isConfirmButtonDisabled={!isDispatcherFormValid}
          isConfirmButtonLoading={addContactLoading}
          isVisible={isVisible}
          setVisible={setVisible}
          title='Add new dispatcher'
          onConfirm={handleAddNewDispatcher}
        >
          <Form className='p-6' setFormValid={setDispatcherFormValid}>
            <TextInput
              required
              sm
              className='mb-3'
              label='Name'
              value={newContact.name}
              onChange={(name: string) => onNewDispatcherChange('name', name)}
            />
            <div className='grid grid-cols-2 gap-x-4'>
              <TextInput
                required
                sm
                label='Email'
                type='email'
                value={newContact.email}
                onChange={(email: string) => onNewDispatcherChange('email', email)}
              />
              <TextInput
                required
                sm
                label='Phone'
                type='tel'
                value={newContact.phone}
                onChange={(phone: string) => onNewDispatcherChange('phone', phone)}
              />
            </div>
          </Form>
        </AlphaModal>
      </FormContainer>
    </Form>
  )
}

const FormContainer = tw.div`
  flex
  flex-col
  gap-3
  relative
`
