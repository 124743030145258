import dayjs, { Dayjs } from 'dayjs'
import {
  camelCase,
  every,
  groupBy,
  isArray,
  isEqualWith,
  isObject,
  mapKeys,
  mapValues,
  snakeCase,
} from 'lodash-es'
import { v4 as uuid } from 'uuid'

import {
  Accessorial,
  AddressData,
  CarrierPaymentMethod,
  Document,
  LoadQuoteCarrier,
  RouteInfo,
  SearchFilters,
  SelectValue,
  TableOrder,
  TenderItem,
} from '../types'
import { formatPhone } from './masks'

type NestedObject = { [key: string]: any }

export const capitalize = (s = '') => `${s[0]?.toUpperCase()}${s?.slice(1)}`

export const capitalizeAllWords = (string: string) =>
  string.toLowerCase().split(' ').map(capitalize).join(' ')

// 'EXO_FREIGHT' -> 'Exo Freight'
export const toTitleCase = (str: string) =>
  capitalizeAllWords(str?.replace('_', ' ').toLowerCase() ?? '')

export const toTimestamp = (strDate: string) => new Date(strDate).getTime() / 1000

export const setCityCase = (city: string) => city.toLowerCase().split(' ').map(capitalize).join(' ')

export const displayCityAndState = (city: any, state: any) => {
  const builder = []
  if (city) builder.push(setCityCase(city))
  if (state) builder.push(state.toUpperCase())
  return builder.join(', ')
}

export const displayLocation = (location: any, withPostalCode = true) => {
  const builder = []
  if (location?.city) builder.push(`${setCityCase(location.city)}, `)
  const state = location?.state || location?.stateProvinceRegion || location?.caProvince
  if (state) builder.push(state.toUpperCase())
  const postalCode = location?.postalCode || location?.usZipcode || location?.caPostalCode
  if (postalCode && withPostalCode) builder.push(postalCode)
  return builder.join(' ')
}

export const displayFullLocation = (location: any) => {
  const builder = []
  if (location?.address) builder.push(`${location.address}, `)
  builder.push(displayLocation(location))
  return builder.join(' ')
}

export const keysToCamelCase = (obj: any): any => {
  if (isArray(obj)) {
    return obj.map(keysToCamelCase)
  } else if (isObject(obj)) {
    return mapKeys(mapValues(obj, keysToCamelCase), (_, key) => camelCase(key))
  }

  return obj
}

export const keysToSnakeCase = (obj: any): any => {
  if (isArray(obj)) {
    return obj.map(keysToSnakeCase)
  } else if (isObject(obj)) {
    return mapKeys(mapValues(obj, keysToSnakeCase), (_, key) => snakeCase(key))
  }

  return obj
}

export const serializeAddress = (address: any) =>
  `${address.street ? `${address.street}, ` : ''}${address.city ? `${address.city}, ` : ''}${
    address.stateCode ? `${address.stateCode} ` : ''
  }${address.postalCode ? `${address.postalCode}, ` : ''}${address.countryCode || ''}`

// Returns true if name string contains 2 or more words
export const validateFullName = (fullName: string) =>
  fullName.split(' ').filter(name => name != '').length >= 2

// Returns true if email string follows an email pattern or if it's blank and not required, otherwise false
export const validateEmail = (email: string, required = false) =>
  (!required && !email.length) || /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email)

// Returns true if phone string is 10 digits or if it's blank and not required, otherwise false
export const validatePhoneNumber = (phone: string, required = false) =>
  (!required && !phone.length) || phone.replace(/\D/g, '').length === 10

export const validateStateCode = (stateCode: string, required = false) =>
  (!required && !stateCode.length) || /^[A-Za-z]{0,2}$/.test(stateCode)

export const validateAddress = (address: AddressData) => {
  if (!address.city) return false
  if (['MX', 'MEX'].includes(address.country || '')) return true

  const state = address.state || address.caProvince || address.stateProvinceRegion
  const zipCode = address.usZipcode || address.postalCode || address.caPostalCode

  if (!state || !zipCode) return false
  return zipCode.length >= 1 && zipCode.length <= 10
}

// Returns initials from first and last name. Example: EXO Freight Company -> EC
export const getInitialsFromName = (name?: string) => {
  let words = (name ?? '').split(' ')
  const firstWord = words[0]
  const lastWord = words.length > 1 ? words.slice(-1)[0] : ''
  words = [firstWord, lastWord]

  return words
    .map((name: string) => name.charAt(0))
    .join('')
    .toUpperCase()
}

export const formatDateForBackend = (date: Date | Dayjs | string | null) =>
  date ? dayjs(date).format('YYYY-MM-DD') : null

export const formatDateTimeForBackend = (date: Date | string, time: string) => {
  if (date && time) {
    const dateTime = `${dayjs(date).format('YYYY-MM-DD')} ${time.substring(0, 2)}${time.substring(
      2,
      4,
    )}`
    return dayjs(dateTime).format('YYYY-MM-DD HH:mm')
  }
  return null
}

export const formatDate = (date?: Date | Dayjs | string | null) =>
  date ? dayjs(date).format('YYYY-MM-DD') : ''

export const displayDate = (date?: string | Date | null) =>
  date ? dayjs(date).format('MM/DD/YYYY') : '—'

export const displayDateTime = (date?: any) =>
  date ? dayjs(date).format('MM/DD/YYYY hh:mm A') : '—'

// '08:00:00' => '08:00'
export const formatTime = (time = '') => time?.substring(0, 5)

export const formatBoolean = (value: boolean) => (value ? 'Yes' : 'No')

export const getReasonCode = (code: string): { name: string; value: number } => {
  switch (code) {
    case 'MISSED_DELIVERY':
      return {
        name: 'Missed Delivery',
        value: 1,
      }
    case 'ACCIDENT':
      return {
        name: 'Accident',
        value: 2,
      }
    case 'CONSIGNEE_RELATED':
      return {
        name: 'Consignee Related',
        value: 3,
      }
    case 'MECHANICAL_BREAKDOWN':
      return {
        name: 'Mechanical Breakdown',
        value: 4,
      }
    case 'OTHER_CARRIER_RELATED':
      return {
        name: 'Other Carrier Related',
        value: 5,
      }
    case 'PREVIOUS_STOP':
      return {
        name: 'Previous Stop',
        value: 6,
      }
    case 'SHIPPER_RELATED':
      return {
        name: 'Shipper Related',
        value: 7,
      }
    case 'WEATHER_OR_NATURAL_DISASTER':
      return {
        name: 'Weather or Natural Disaster',
        value: 8,
      }
    case 'MISSED_PICKUP':
      return {
        name: 'Missed Pickup',
        value: 9,
      }
    case 'HELD_PER_SHIPPER':
      return {
        name: 'Held Per Shipper',
        value: 10,
      }
    case 'EXCEEDS_SERVICE_LIMITATIONS':
      return {
        name: 'Exceeds Service Limitations',
        value: 11,
      }
    default:
      return {
        name: '',
        value: 0,
      }
  }
}

export const validateAccountNumber = (accountNumber: string): boolean =>
  /^\d{4,16}$/.test(accountNumber)

export const validatePaymentMethod = (paymentMethod: CarrierPaymentMethod) => {
  let valid = false
  if (paymentMethod.paymentMethod === 'ACH') {
    valid =
      every(
        ['accountType', 'routingNumber', 'accountNumber', 'accountNumberConfirm', 'name'],
        (key: keyof typeof paymentMethod) => Boolean(paymentMethod[key]),
      ) &&
      Boolean(paymentMethod.name) &&
      paymentMethod.accountNumber === paymentMethod.accountNumberConfirm &&
      validateAccountNumber(paymentMethod.accountNumber as string) &&
      paymentMethod?.routingNumber?.length === 9
  }
  if (paymentMethod.paymentMethod === 'CHECK') {
    const fieldsToCheck = ['addressLine1', 'country', 'city', 'postalCode', 'name']
    if (paymentMethod.country !== 'MX') {
      fieldsToCheck.push('state')
    }
    valid = every(fieldsToCheck, (key: keyof typeof paymentMethod) => Boolean(paymentMethod[key]))
  }
  if (paymentMethod.paymentMethod === 'FACTORING') {
    valid = every(['businessName', 'name'], (key: keyof typeof paymentMethod) =>
      Boolean(paymentMethod[key]),
    )
  }
  return valid
}

export const getLoadId = () => Number(window.location.pathname.split('/').slice(-1).pop() ?? '')

// Retracts the location path from window url, e.g. 'https://tms.exofreight.com/loads' => 'loads'.
// The returned value is used as key to retrieve all presets for this location, in the example above all presets for loads
export const getPresetKey = () => window.location.pathname.replace(/[/-]/g, '')

// Converts '1200' to '12:00'
export const formatTimeString = (str: string) => `${str.slice(0, 2)}:${str.slice(2, str.length)}`

// Converts '1200' to '12:00' if no semicolon was found in the string; returns an empty string if no timestring was provided
export const normalizeTimeForBackend = (time = '') =>
  (!time.includes(':') && time.length ? formatTimeString(time) : time) || ''

// Generates a 4 letter random id to set to itemManifestKey
export const randomString = () => Math.random().toString(36).substring(2, 6)

// Takes two objects and returns only keys with updated values
export const getDiffBetweenObjects = (firstObject: any, secondObject: any) => {
  const diffKeys = []
  if (firstObject && secondObject) {
    for (const key in firstObject) if (firstObject[key] !== secondObject[key]) diffKeys.push(key)
  }
  return diffKeys
}

export const getOrderingString = (
  label: string,
  direction: string,
  key: string,
  defaultKey = '',
) => (label ? `${direction === 'ascending' ? '' : '-'}${key}` : defaultKey)

// Receives load id, customer name, shipper and consignee states, and returns only those values that are not empty, formatted like "123 W NY — LA"
export const getLoadDetailTitle = (
  id: number,
  customer?: string,
  shipper?: string | null,
  consignee?: string | null,
) =>
  `${id} ${customer ? getInitialsFromName(customer) : ''} ${
    shipper && consignee ? `${shipper} — ${consignee}` : ''
  }`

// Returns an object that indicates which sections have changed on Invocing Documents Viewer side content
export const getLoadChangeStatuses = (load: {
  backupTenderItems: TenderItem[]
  tenderItems: TenderItem[]
  loadInfo: any
  changedLoadInfo: any
  selectedCarrier: any
  backupCarrier: any
  routeInfo: RouteInfo
  changedRouteInfo: RouteInfo
  customerAccessorials: Accessorial[]
  customerAccessorialsBackup: Accessorial[]
  carrierAccessorials: Accessorial[]
  carrierAccessorialsBackup: Accessorial[]
}) => {
  const {
    loadInfo,
    changedLoadInfo,
    selectedCarrier,
    backupCarrier,
    tenderItems,
    backupTenderItems,
    routeInfo,
    changedRouteInfo,
    customerAccessorials,
    customerAccessorialsBackup,
    carrierAccessorials,
    carrierAccessorialsBackup,
  } = load

  const isLoadDetailChanged =
    !isEqualWith(tenderItems, backupTenderItems, (value: string, other: string, key) =>
      key === 'price' ? parseFloat(value) === parseFloat(other) : undefined,
    ) ||
    !isEqualWith(loadInfo, changedLoadInfo, (value, other, key) => {
      if (value === null) return other === null || other?.length === 0
      if (key === 'customer') return value?.id == other?.id
    }) ||
    selectedCarrier.id != backupCarrier.carrier ||
    routeInfo.shipper?.id != changedRouteInfo.shipper?.id ||
    routeInfo.consignee?.id != changedRouteInfo.consignee?.id

  const isLoadStopChanged = !isEqualWith(
    routeInfo.loadstopSet,
    changedRouteInfo.loadstopSet,
    (value: any, other: any, key) => (key === 'location' ? value.id == other.id : undefined),
  )

  const isAccessorialChanged =
    !isEqualWith(
      customerAccessorials,
      customerAccessorialsBackup,
      (value: string, other: string, key) =>
        key === 'amount' || key === 'quantity'
          ? parseFloat(value) === parseFloat(other)
          : undefined,
    ) ||
    !isEqualWith(
      carrierAccessorials,
      carrierAccessorialsBackup,
      (value: string, other: string, key) =>
        key === 'amount' || key === 'quantity'
          ? parseFloat(value) === parseFloat(other)
          : undefined,
    )
  return {
    isLoadDetailChanged,
    isLoadStopChanged,
    isAccessorialChanged,
    isChanged: isLoadDetailChanged || isLoadStopChanged || isAccessorialChanged,
  }
}

export type GroupedDocuments = {
  label: string
  files: Array<Partial<Document>>
}

export const normalizeDocuments = <T extends Document>({
  documents,
  displayKey = 'documentTypeDisplay',
  dateKey,
  determineSelectable = () => true,
}: {
  documents: Array<T>
  displayKey?: keyof T
  dateKey?: keyof T
  determineSelectable?: (document: T) => boolean
}): GroupedDocuments[] => {
  const groupedDocuments = groupBy(documents, displayKey)

  const docs = Object.keys(groupedDocuments).map(key => ({
    label: key,
    files: groupedDocuments[key].map(document => ({
      fileName: document.fileName,
      file: document.file,
      date: dateKey ? document[dateKey] : document['timestamp'] || document['timeStamp'],
      id: document.id,
      selectable: determineSelectable ? determineSelectable(document) : true,
      annotations: document.annotations,
    })),
  }))

  docs.sort((a, b) => {
    // sort docs by files containing id of -1 (new files)
    const aHasId = a.files.some(file => file.id === -1)
    const bHasId = b.files.some(file => file.id === -1)
    if (aHasId && !bHasId) return -1
    if (!aHasId && bHasId) return 1

    // sort docs by files containing selectable files
    const aSelectable = a.files.some(file => file.selectable)
    const bSelectable = b.files.some(file => file.selectable)
    if (aSelectable && !bSelectable) return -1
    if (!aSelectable && bSelectable) return 1

    // sort docs by label name
    return a.label.localeCompare(b.label)
  })

  return docs as GroupedDocuments[]
}

export const formatFileName = (file = '') => file.split('/').slice(-1)[0]

// Texarkana, TX, United States => Texarkana, TX
export const getCityAndStateFromLocationTitle = (location = '') =>
  location.split(',').slice(0, 2).join(', ')

// This function searches for an element with the provided id and copies its inner HTML to clipboard
export const copyHTMLToClipboard = async (id = 'richTextInputId') => {
  const type = 'text/html'
  const content = document?.getElementById(id)?.innerHTML
  const blob = new Blob([content as BlobPart], { type })
  const richTextInput = new ClipboardItem({ [type]: blob })
  await navigator.clipboard.write([richTextInput])
}

/* Converts an array of (typically) SelectValue's into a string of comma
 separated ids for processing by django's querying system */
export const getStringFromArray = (
  list?: Array<{ id: number; text: string } | number> | null,
): string | null => list?.map(item => (isObject(item) ? item.id : item)).join(',') || null

/* Converts an array of (typically) SelectValue's into a an array
 of ids for processing by django's querying system */
export const getIdsFromArray = (
  list?: Array<{ id: number; text: string } | SelectValue>,
  returnString = false,
): Array<number> | string | undefined => {
  const array = list?.map(item => item.id)
  return array?.length ? (returnString ? array.join(',') : array) : undefined
}

/* This function adds previous and next indexes based on adjacent results, and current result's row index (starting with 1) based on offset, e.g.
   [
      {
        id: 0,
        prev: null,
        next: 1,
        rowIndex: 1
      },
      {
        id: 1,
        prev: 0,
        next: 2,
        rowIndex: 2
      },
      {
        id: 2,
        prev: 1,
        next: null,
        rowIndex: 3
      },
   ]
 */
export const formatRowsForNavigation = <T extends { id: number }>(
  array: Array<T>,
  offset: number,
) =>
  array.map((result: any, i: number, arr: any) => ({
    ...result,
    prev: i ? arr[i - 1]?.id : null,
    next: i !== arr.length - 1 ? arr[i + 1]?.id : null,
    rowIndex: i + 1 + offset,
  }))

export const booleanFromYesNoString = (value?: string | null) => {
  // Returns true/false/null depending on if a string is "Yes" or "No"
  if (value === 'Yes') return true
  if (value === 'No') return false
  return null
}

// Receives margin value (0.5 - 5) and transforms it into a percentage, e.g. 0.6 becomes -40, 1.6 becomes 60
export const getMarginPercentage = (value: number | string) => {
  if (!value) return value
  const percentage = (Number(value) - 1) * 100
  return Math.round(Math.abs(percentage))
}

// Reverts the result of getMarginPercentage() to send it to the backend
export const getMarginValue = (value: number | string, sign = '+') => {
  const percentage = parseInt(value as string, 10)
  const number = 1 + (sign === '+' ? percentage / 100 : -(percentage / 100))
  return Math.abs(number)
}

/*
  Takes in two arrays of objects, and compares them to return a new array of only those objects from newArray that are different from backupArray.
  Can also take in an array of fields to ignore while comparing.
*/
export const getDifferentObjects = (
  newArray: Array<any>,
  backupArray: Array<any>,
  ignoredFields?: Array<string>,
) => {
  const result: any = []

  newArray.forEach(obj1 => {
    const found = backupArray.some(obj2 =>
      Object.keys(obj1).every(key => ignoredFields?.includes(key) || obj1[key] === obj2[key]),
    )
    if (!found) {
      result.push(obj1)
    }
  })

  return result
}

/*
  Accepts current table order, headers available for ordering, and the label that was clicked
  Returns the display object for a table order.
 */
export const getTableOrderDisplay = (
  headers: Array<{ label: string; key: string }>,
  label: string,
  orderBy?: TableOrder,
) => {
  const isAscending = orderBy?.direction === 'ascending'
  const header = headers.find(header => header.label === label)
  if (!header) return {}

  const key = header.key

  return orderBy?.label === label
    ? {
        label: isAscending ? label : '',
        direction: isAscending ? 'descending' : '',
        key: isAscending ? key : '',
      }
    : { label: label, direction: 'ascending', key }
}

export const getClientId = () => {
  const hasId = sessionStorage.getItem('client-id')
  if (!hasId) {
    sessionStorage.setItem('client-id', uuid())
  }
  return sessionStorage.getItem('client-id') as string
}

// Compares two lists of objects and returns the objects from the second list that match objects from the first list, while allowing for ignoring certain keys during comparison
export const getMatchingObjectsInLists = (
  list1: Array<any>,
  list2: Array<any>,
  ignoreFields: Array<string> = [],
) =>
  list2.filter(item2 =>
    list1.some(item1 =>
      Object.keys(item1).every(key =>
        ignoreFields.includes(key) ? true : item1[key] === item2[key],
      ),
    ),
  )

// Format and join all carrier phones
export const getCarrierPhones = (carrier: LoadQuoteCarrier) =>
  [carrier.phone, carrier.phoneTwo, carrier.phoneThree]
    .filter(Boolean)
    .map(phone => formatPhone(phone))
    .join(', ')

// Pads a given string with leading zeros to achieve the desired length
export const addPrecedingZeros = (inputString = '', desiredLength: number) =>
  '0'.repeat(Math.max(0, desiredLength - inputString.length)) + inputString

// Checks if each item in array has isValid: true
export const checkIsValidArray = (data: { isValid?: boolean }[]) => data.every(obj => obj.isValid)

// Compares two objects, including nested objects and arrays, while allowing for the exclusion of specified keys during the comparison process
export const deepEqualWithIgnore = <T extends NestedObject>(
  obj1: T,
  obj2: T,
  ignoreKeys: string[] = [],
): boolean =>
  Array.isArray(obj1) && Array.isArray(obj2)
    ? obj1.length === obj2.length &&
      obj1.every((item, index) => deepEqualWithIgnore(item, obj2[index], ignoreKeys))
    : typeof obj1 === 'object' && obj1 !== null && typeof obj2 === 'object' && obj2 !== null
      ? Object.keys(obj1)
          .filter(key => !ignoreKeys.includes(key))
          .every(key => deepEqualWithIgnore(obj1[key], obj2[key], ignoreKeys))
      : obj1 === obj2

// Checks if at least one object in the array has non-empty values, excluding specified fields
export const objectHasNonEmptyValues = (data: any[], excludeFields: string[]) =>
  data.some(obj =>
    Object.entries(obj).some(([key, value]) => !excludeFields.includes(key) && value),
  )

export const isNumber = (value?: string | number) => typeof value === 'number'

// Returns an object containing properties from the first object that have different values compared to the second object
export const getObjectDifferences = (
  obj1: Record<string, any>,
  obj2: Record<string, any>,
): Record<string, any> => {
  const hasOwn = (obj: Record<string, any>, key: string) =>
    Object.prototype.hasOwnProperty.call(obj, key)
  const isObject = (item: any) => item && typeof item === 'object' && !Array.isArray(item)

  const differences: Record<string, any> = {}

  for (const key in obj1) {
    if (hasOwn(obj2, key)) {
      if (isObject(obj1[key]) && isObject(obj2[key])) {
        const nestedDifferences = getObjectDifferences(obj1[key], obj2[key])
        if (Object.keys(nestedDifferences).length !== 0) differences[key] = nestedDifferences
      } else if (Array.isArray(obj1[key]) && Array.isArray(obj2[key])) {
        if (JSON.stringify(obj1[key]) !== JSON.stringify(obj2[key])) differences[key] = obj1[key]
      } else if (obj1[key] !== obj2[key]) differences[key] = obj1[key]
    } else differences[key] = obj1[key]
  }

  return differences
}

// Returns an array with the first value set to the date three days before today and the second value set to the date three days after today
export const getTodayPlusMinusThreeDays = () => [
  formatDate(dayjs().subtract(3, 'd')),
  formatDate(dayjs().add(3, 'd')),
]

/*
   Checks whether any of the following fields in the preset are set to true:
   pickupToday, deliveryToday, pickupTodayPlusMinusThree, or deliveryTodayPlusMinusThree,
   and updates pickup and delivery dates to the corresponding new date values
*/
export const getDynamicDateFilters = (selectedPresetFilters: SearchFilters) => ({
  ...(selectedPresetFilters.pickupToday && { pickupDate: [formatDate(dayjs())] }),
  ...(selectedPresetFilters.deliveryToday && { deliveryDate: [formatDate(dayjs())] }),
  ...(selectedPresetFilters.pickupTodayPlusMinusThree && {
    pickupDate: getTodayPlusMinusThreeDays(),
  }),
  ...(selectedPresetFilters.deliveryTodayPlusMinusThree && {
    deliveryDate: getTodayPlusMinusThreeDays(),
  }),
})

export const formatArrayOfIds = (array?: (string | number)[], returnString = true) =>
  returnString
    ? array?.join(',')
    : array?.map((el: number | string) => (!isNaN(Number(el)) ? Number(el) : el))
