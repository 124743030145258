import { LinkPageHeader, LoaderSpinner, Tabs } from '@components'
import { noop } from 'lodash-es'
import tw from 'tailwind-styled-components'

type DetailsHeaderProps = {
  titleContent: string | JSX.Element
  subtitleContent?: string | JSX.Element
  tabNames: string[]
  activeTab: number
  setActiveTab: (index: number) => void
  rightContent?: string | JSX.Element
  loading?: boolean
  to: string
}

export const DetailsHeader = ({
  titleContent,
  to = '',
  subtitleContent = '',
  tabNames,
  activeTab,
  setActiveTab,
  rightContent = '',
  loading = false,
}: DetailsHeaderProps) => {
  if (loading) {
    titleContent = <LoaderSpinner className='!mt-1 ml-2' />
    subtitleContent = ''
    if (rightContent) rightContent = <LoaderSpinner className='!mt-1 ml-2' />
  }

  return (
    <Container className='details-header'>
      <div className='flex w-full justify-between'>
        <div>
          <LinkPageHeader title={titleContent} to={to} />
          <Subtitle>{subtitleContent}</Subtitle>
        </div>
        <Right>{rightContent}</Right>
      </div>
      <Tabs
        activeTab={activeTab}
        className='absolute bottom-5 w-[calc(100%-3rem)] ml-4'
        setActiveTab={!loading ? setActiveTab : noop}
        tabs={tabNames}
      />
    </Container>
  )
}

const Container = tw.div`
  relative
  border-b
  pr-6
  pl-2
  border-light-blue
  text-dark-blue
  h-[170px]
  box-border
`

const Right = tw.div`
  text-xs
`

const Subtitle = tw.div`
  text-xs
  ml-14
`
