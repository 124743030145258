import { CatchError, formatAxiosErrorToPayload, getErrorString } from '@common'
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { toast } from 'react-toastify'

import { api } from '../api/api'
import { initialFilters } from '../common/constants'
import {
  CarrierCustomerRelationship,
  FactoringCustomerDetails,
  FactoringCustomerSummary,
  LoadFactoringRequest,
  RootState,
  SearchFilters,
} from '../common/types'
import { keysToCamelCase, keysToSnakeCase } from '../common/utils'

type FactoringCustomerState = {
  factoringCustomerSummary: FactoringCustomerSummary[]
  factoringCustomerDetail: FactoringCustomerDetails | null
  carrierCustomerRelationship: CarrierCustomerRelationship
  carrierCustomerRelationshipList: CarrierCustomerRelationship[]
  carrierCustomerLoadList: LoadFactoringRequest[]
  count: {
    factoringCustomerSummary: number
    carrierCustomerRelationshipList: number
    carrierCustomerLoadList: number
  }
  loading: {
    carrierCustomerRelationshipList: boolean
    carrierCustomerLoadList: boolean
    factoringCustomerSummary: boolean
    factoringCustomerDetail: boolean
    factoringRelationshipDetail: boolean
  }
  filters: SearchFilters
  offset: number
  size: number
}

const initialState: FactoringCustomerState = {
  factoringCustomerSummary: [],
  factoringCustomerDetail: null,
  carrierCustomerRelationship: {} as CarrierCustomerRelationship,
  carrierCustomerRelationshipList: [],
  carrierCustomerLoadList: [],
  count: {
    factoringCustomerSummary: 0,
    carrierCustomerRelationshipList: 0,
    carrierCustomerLoadList: 0,
  },
  loading: {
    carrierCustomerRelationshipList: false,
    carrierCustomerLoadList: false,
    factoringCustomerSummary: false,
    factoringCustomerDetail: false,
    factoringRelationshipDetail: false,
  },
  filters: initialFilters,
  offset: 0,
  size: 50,
}

const getFilters = (filters: SearchFilters) => ({
  id: filters.customerCompany,
  mc_number__contains: filters.mcNumber,
})

export const getCustomerSummaryList = createAsyncThunk(
  'factoringCustomer/getCustomerSummaryList',
  async (_, { getState }) => {
    const { filters, size, offset } = (getState() as RootState).factoringCustomer

    const response = await api.get('/factoring/api/customer-summary/', {
      params: {
        limit: size,
        offset,
        ...getFilters(filters),
      },
    })

    return response.data
  },
)

export const getCarrierCustomerRelationshipList = createAsyncThunk(
  'factoringCustomer/getCarrierCustomerRelationshipList',
  async ({ customerId }: { customerId: number }, { getState }) => {
    const { filters, size, offset } = (getState() as RootState).factoringCustomer

    const response = await api.get(`/factoring/api/customer-relationship-list/${customerId}`, {
      params: {
        limit: size,
        offset,
        ...getFilters(filters),
      },
    })

    const results: CarrierCustomerRelationship[] = keysToCamelCase(response.data.results)
    results.map(item => (item.id = item.relationshipId))

    return {
      results: results,
      count: response.data.count,
    }
  },
)

export const getFactoringCustomerDetails = createAsyncThunk(
  'factoringCustomer/getFactoringCustomerDetails',
  async ({ customerId }: { customerId: number }) =>
    api
      .get(`/factoring/api/customer-credit-details/${customerId}/`)
      .then(({ data }) => keysToCamelCase(data)),
)

export const updateCustomerCreditDetails = createAsyncThunk(
  'factoringCustomer/updateCustomerCreditDetails',
  async (
    payload: {
      id: number
      buyStatus?: boolean
      creditLimit?: number
      factoringCreditExpiration?: string | null
    },
    { dispatch, rejectWithValue },
  ) => {
    try {
      const response = await api.patch(
        `/factoring/api/customer-credit-details/${payload.id}/`,
        keysToSnakeCase(payload),
      )

      dispatch(getFactoringCustomerDetails({ customerId: Number(payload.id) }))
      return response.data
    } catch (err: CatchError) {
      return rejectWithValue(formatAxiosErrorToPayload(err))
    }
  },
)

export const updateFactoringRelationshipDetails = createAsyncThunk(
  'factoringCustomer/updateRelationshipDetails',
  async (
    payload: {
      id: number
      relationshipId: number
      currentlyActive?: boolean
      creditLimit?: number
      hasNoa?: boolean
    },
    { dispatch, rejectWithValue },
  ) => {
    try {
      const response = await api.patch(
        `/factoring/api/customer-carrier-relationship/${payload.relationshipId}/`,
        keysToSnakeCase(payload),
      )

      dispatch(getCarrierCustomerRelationshipList({ customerId: Number(payload.id) }))
      dispatch(
        getCustomerCarrierFactoringRelationshipDetails({
          relationshipId: Number(payload.relationshipId),
        }),
      )
      return response.data
    } catch (err: CatchError) {
      return rejectWithValue(formatAxiosErrorToPayload(err))
    }
  },
)

export const getCustomerCarrierFactoringRelationshipDetails = createAsyncThunk(
  'factoringRelationship/getCustomerCarrierFactoringRelationshipDetails',
  async ({ relationshipId }: { relationshipId: number }) =>
    api
      .get(`/factoring/api/customer-carrier-relationship/${relationshipId}/`)
      .then(({ data }) => keysToCamelCase(data)),
)

export const getCustomerCarrierLoadsList = createAsyncThunk(
  'factoringCustomer/getCustomerCarrierLoadsList',
  async ({ relationshipId }: { relationshipId: number }, { getState }) => {
    const { size, offset } = (getState() as RootState).factoringCustomer
    const response = await api.get(
      `/factoring/api/customer-carrier-relationship/${relationshipId}/unreceived-loads`,
      {
        params: {
          limit: size,
          offset,
        },
      },
    )
    return keysToCamelCase(response.data)
  },
)

export const factoringCustomerSlice = createSlice({
  name: 'factoringCustomer',
  initialState,
  reducers: {
    setSize(state, { payload }) {
      state.size = payload
    },
    setOffset(state, { payload }) {
      state.offset = payload
    },
    setFilters(state, { payload }) {
      state.filters = payload
    },
    setFactoringCustomerSummaryList(state, { payload }) {
      state.count.factoringCustomerSummary = payload
    },
  },
  extraReducers(builder) {
    builder
      .addCase(getCustomerSummaryList.pending, state => {
        state.loading.factoringCustomerSummary = true
      })
      .addCase(getCustomerSummaryList.fulfilled, (state, action) => {
        const { count, results } = action.payload

        state.factoringCustomerSummary = keysToCamelCase(results)
        state.count.factoringCustomerSummary = count
        state.loading.factoringCustomerSummary = false
      })
      .addCase(getCustomerSummaryList.rejected, (state, { payload }) => {
        state.loading.factoringCustomerSummary = false
        toast.error(getErrorString(payload, 'Failed to get customer summary'))
      })
      .addCase(getCarrierCustomerRelationshipList.pending, state => {
        state.loading.carrierCustomerRelationshipList = true
      })
      .addCase(getCarrierCustomerRelationshipList.fulfilled, (state, action) => {
        const { count, results } = action.payload

        state.carrierCustomerRelationshipList = results
        state.count.carrierCustomerRelationshipList = count
        state.loading.carrierCustomerRelationshipList = false
      })
      .addCase(getCarrierCustomerRelationshipList.rejected, (state, { payload }) => {
        state.loading.carrierCustomerRelationshipList = false
        toast.error(getErrorString(payload, 'Failed to get carrier customer relationship'))
      })
      .addCase(getFactoringCustomerDetails.pending, state => {
        state.loading.factoringCustomerDetail = true
      })
      .addCase(getFactoringCustomerDetails.fulfilled, (state, action) => {
        const availableCredit = action.payload.creditLimit - action.payload.utilizedCredit
        action.payload.availableCredit = availableCredit
        state.factoringCustomerDetail = action.payload
        state.loading.factoringCustomerDetail = false
      })
      .addCase(updateCustomerCreditDetails.fulfilled, (state, action) => {
        const arg = action.meta.arg

        if ('buyStatus' in arg) {
          toast.success('Successfully updated buy status')
        }

        if ('creditLimit' in arg) {
          toast.success('Successfully updated credit limit')
        }

        if ('factoringCreditExpiration' in arg) {
          toast.success('Successfully updated credit expiration')
        }
      })
      .addCase(updateCustomerCreditDetails.rejected, (_, action) => {
        toast.error(getErrorString(action.payload, 'Failed to update customer details'))
      })
      .addCase(updateFactoringRelationshipDetails.fulfilled, (_, action) => {
        const arg = action.meta.arg
        if ('currentlyActive' in arg) {
          toast.success('Successfully updated buy status')
        }

        if ('creditLimit' in arg) {
          toast.success('Successfully updated credit limit')
        }
        if ('hasNoa' in arg) {
          toast.success('Successfully updated noa status')
        }
      })
      .addCase(updateFactoringRelationshipDetails.rejected, (_, action) => {
        toast.error(getErrorString(action.payload, 'Failed to update relationship details'))
      })
      .addCase(getCustomerCarrierFactoringRelationshipDetails.pending, state => {
        state.loading.factoringRelationshipDetail = true
      })
      .addCase(getCustomerCarrierFactoringRelationshipDetails.fulfilled, (state, action) => {
        const availableCredit = action.payload.creditLimit - action.payload.utilizedCredit
        action.payload.availableCredit = availableCredit
        state.carrierCustomerRelationship = action.payload
        state.loading.factoringRelationshipDetail = false
      })
      .addCase(getCustomerCarrierFactoringRelationshipDetails.rejected, (state, { payload }) => {
        state.loading.factoringRelationshipDetail = false
        toast.error(getErrorString(payload, 'Failed to get customer carrier relationship details'))
      })
      .addCase(getCustomerCarrierLoadsList.pending, state => {
        state.loading.carrierCustomerLoadList = true
      })
      .addCase(getCustomerCarrierLoadsList.fulfilled, (state, action) => {
        const { count, results } = action.payload

        state.carrierCustomerLoadList = results
        state.count.carrierCustomerLoadList = count
        state.loading.carrierCustomerLoadList = false
      })
      .addCase(getCustomerCarrierLoadsList.rejected, (state, { payload }) => {
        state.loading.carrierCustomerLoadList = false
        toast.error(getErrorString(payload, 'Failed to get carrier customer loads'))
      })
  },
})

export const { setSize, setOffset, setFilters, setFactoringCustomerSummaryList } =
  factoringCustomerSlice.actions

export default factoringCustomerSlice.reducer
