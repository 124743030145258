import { Menu } from '@headlessui/react'
import { BellIcon } from '@heroicons/react/24/solid'
import { useEffect } from 'react'
import tw from 'tailwind-styled-components'

import { useAppThunkDispatch } from '../../app/hooks'
import { getNotifications } from '../../redux/notificationsSlice'
import { EaseInOutTransition } from '../EaseInOutTransition'
import { MenuDisplay, UnseenCount } from './components'

export const NotificationsMenu = () => {
  const dispatch = useAppThunkDispatch()

  useEffect(() => {
    dispatch(getNotifications())
  }, [])

  return (
    <>
      <MenuContainer as='div'>
        {({ open }: { open: boolean }) => (
          <>
            <Menu.Button>
              <MenuButtonContainer>
                <BellIcon className='text-dark-blue w-6 h-6' />
                <UnseenCount />
              </MenuButtonContainer>
            </Menu.Button>
            <EaseInOutTransition>{open && <MenuDisplay />}</EaseInOutTransition>
          </>
        )}
      </MenuContainer>
    </>
  )
}

const MenuContainer = tw(Menu)`
  relative
  inline-block
  text-left
  z-[9]
  ml-4
  lg:ml-0
`

const MenuButtonContainer = tw.div`
  bg-light-blue
  rounded-lg
  flex
  items-center
  justify-center
  h-10
  w-10
  hover:opacity-70
  transition-all
  relative
`
