import { broadcastLogout } from '@common'
import { Button } from '@components'
import { Menu } from '@headlessui/react'
import { Bars3Icon, XMarkIcon } from '@heroicons/react/24/solid'
import { useState } from 'react'
import { NavLink, useLocation } from 'react-router-dom'
import tw from 'tailwind-styled-components'

import { useAppSelector } from '../../app/hooks'
import logo from '../../assets/images/logo-small.png'
import { isProduction } from '../../common/constants'
import { useSocket } from '../../common/events'
import { classNames } from '../../common/utils'
import { Avatar } from '../Avatar/Avatar'
import { EaseInOutTransition } from '../EaseInOutTransition'
import { MainMenu } from '../MainMenu'
import { NotificationsMenu } from '../NotificationsMenu'
import { links } from '../Sidebar'
import { CarrierCheckModal } from './CarrierCheckModal'

export const Header = (): JSX.Element => {
  // connect to websockets as the first thing
  useSocket()

  const [isMenuOpen, setMenuOpen] = useState(false)
  const [isCarrierCheckModalVisible, setCarrierCheckModalVisible] = useState(false)
  const user = useAppSelector(state => state.user.user)
  const location = useLocation()
  const isQuoting = location.pathname === '/'

  const renderDropdown = () => (
    <EaseInOutTransition>
      <div className='absolute right-0'>
        <Dropdown>
          <div className='flex flex-col px-4 py-1'>
            <Menu.Item>
              {({ active }) => (
                <MenuButton $active={active} type='submit' onClick={broadcastLogout}>
                  Sign Out
                </MenuButton>
              )}
            </Menu.Item>
          </div>
        </Dropdown>
      </div>
    </EaseInOutTransition>
  )

  const linkClassName = 'stroke-black font-bold text-base transition-all flex items-center'

  const renderLinks = () => (
    <div className='overflow-auto h-[calc(100%-144px)]'>
      {links.map((link, i) => (
        <NavLink
          key={i}
          to={link.url}
          className={({ isActive }) =>
            classNames(linkClassName, isActive ? 'text-brand-accent' : '')
          }
          onClick={() => setMenuOpen(false)}
        >
          <div className='w-7 mr-4'>{link.icon}</div>
          <div className='py-3'>{link.label}</div>
        </NavLink>
      ))}
    </div>
  )

  const name = `${user.firstName} ${user.lastName}`

  const renderBurger = () => (
    <HamburgerMenu $isOpen={isMenuOpen} className='burger-wrap'>
      <LinkWrap onClick={() => setMenuOpen(!isMenuOpen)}>
        {isMenuOpen ? (
          <XMarkIcon className='text-dark-blue w-6 h-10 m-auto' />
        ) : (
          <Bars3Icon className='text-exo-orange w-6 h-10 m-auto' />
        )}
      </LinkWrap>
    </HamburgerMenu>
  )

  return (
    <HeaderContainer>
      <div className='flex items-center'>
        <NavLink to={isQuoting ? '/' : links[0].url}>
          <img alt='logo' className='w-6 h-6' src={logo} />
        </NavLink>
        {isQuoting && <QuotingTitle>Quoting tool</QuotingTitle>}
      </div>
      {!isProduction && <Environment>{import.meta.env.VITE_ENVIRONMENT}</Environment>}
      <div className='flex items-center lg:gap-8'>
        <Button innerClassName='h-10' type='warn' onClick={() => setCarrierCheckModalVisible(true)}>
          Carrier Check
        </Button>
        {isCarrierCheckModalVisible && (
          <CarrierCheckModal
            isVisible={isCarrierCheckModalVisible}
            setVisible={setCarrierCheckModalVisible}
          />
        )}
        <MainMenu data-testid='menu' />
        <NotificationsMenu />
        <MenuContainer as='div'>
          <div className='flex items-center'>
            <AvatarWrap>
              <Avatar dropdown name={name} />
            </AvatarWrap>
            <BurgerWrap>{renderBurger()}</BurgerWrap>
          </div>
          <MobileMenu $isOpen={isMenuOpen}>
            {isMenuOpen && (
              <>
                {renderLinks()}
                <MobileFooter>
                  <MobileAvatarWrap>
                    <Avatar dropdown name={name} />
                  </MobileAvatarWrap>
                  <Button type='danger' preIcon='logout' onClick={broadcastLogout}>
                    Sign Out
                  </Button>
                </MobileFooter>
              </>
            )}
          </MobileMenu>
          {renderDropdown()}
        </MenuContainer>
      </div>
    </HeaderContainer>
  )
}

const MenuButton = tw.button<{ $active: boolean }>`
  ${({ $active }) => ($active ? 'bg-gray-100 text-gray-900' : 'text-gray-700')}
  text-xs
  text-left
  py-2
  px-4
  mx-[-1rem]
  font-semibold
`

const MobileMenu = tw.div<{ $isOpen: boolean }>`
  fixed
  top-0
  right-0
  w-full
  pl-6
  bg-white
  text-center
  opacity-0
  text-3xl
  mt-[72px]
  lg:hidden
  ${({ $isOpen }) => $isOpen && 'opacity-100 transition-opacity delay-75 h-full'}
`

const HamburgerMenu = tw.div<{ $isOpen: boolean }>`
  bg-light-blue
  rounded-lg
  ml-4
  m-0
  p-0
  block
  lg:hidden
  h-10
  w-10
  ${({ $isOpen }) => $isOpen && 'bg-exo-orange'}
`

const HeaderContainer = tw.div`
  fixed
  top-0
  w-full
  z-10
  bg-gray-800
  flex
  justify-between
  items-center
  py-4
  px-6
  h-[72px]
  lg:relative
  lg:will-change-[opacity]
`
// NOTE: will-change-[opacity] is needed to prevent a visual bug with table filters

const QuotingTitle = tw.div`
  text-lighter-blue
  opacity-80
  text-lg
  ml-4
  uppercase
  font-bold
  tracking-wide
`

const MenuContainer = tw(Menu)`
  relative
  inline-block
  text-left
  z-10
`

const AvatarWrap = tw(Menu.Button)`
  hidden
  lg:block
  mr-0
  w-10
  h-10
  transition-colors
`

const MobileAvatarWrap = tw.div`
  mr-0
  w-10
  h-10
  transition-colors
`

const MobileFooter = tw.div`
  fixed
  bg-dark-blue
  w-full
  bottom-0
  left-0
  flex
  items-center
  justify-between
  text-left
  text-white
  font-bold
  text-sm
  p-4
`

const BurgerWrap = tw.div`
  lg:hidden
  top-0
  ml-4
  -right-4
  transition-all
`

const LinkWrap = tw.a`
  text-exo-orange
  absolute
  cursor-pointer
  m-0
  p-0
  h-10
  w-10
`

const Dropdown = tw(Menu.Items)`
  origin-top-right
  fixed
  left-6
  mt-2
  w-56
  rounded-md
  shadow-lg
  bg-white
  ring-1
  ring-black
  ring-opacity-5
  bottom-[88px]
  focus:outline-none
  lg:absolute
  lg:left-auto
  lg:bottom-auto
  lg:right-0
  !min-w-max
`

const Environment = tw.div`
  bg-brand-accent
  text-exo-blue
  text-sm
  font-bold
  uppercase
  px-2
  py-1
  rounded
  hidden
  md:block
`
