import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import dayjs from 'dayjs'
import { findIndex, groupBy, sortBy } from 'lodash-es'

import { api } from '../api/api'
import { Notification } from '../common/types'
import { keysToCamelCase, keysToSnakeCase } from '../common/utils'

export type NotificationsState = {
  notificationsToday: Notification[]
  notificationsThisWeek: Notification[]
  weekNotifsByDay: Array<[string, Notification[]]>
  mode: 'today' | 'week'
  loading: {
    notifications: boolean
    update: boolean
  }
}

const initialState: NotificationsState = {
  notificationsToday: [],
  notificationsThisWeek: [],
  weekNotifsByDay: [],
  mode: 'today',
  loading: {
    notifications: false,
    update: false,
  },
}

export const getNotifications = createAsyncThunk('notifications/getNotifications', async () => {
  const todayResponse = await api.get('/notifications/api/list/', {
    params: {
      time_frame: 'today',
    },
  })
  const notifsToday = keysToCamelCase(todayResponse.data.results)
  const thisWeekResponse = await api.get('/notifications/api/list/', {
    params: {
      time_frame: 'week',
    },
  })
  const notifsThisWeek = keysToCamelCase(thisWeekResponse.data.results)

  const weekByDayNotifEntries: Array<[string, any[]]> = partitionWeekNotifsByDay(notifsThisWeek)

  return {
    today: notifsToday,
    week: notifsThisWeek,
    weekByDay: weekByDayNotifEntries,
  }
})

export const updateNotifications = createAsyncThunk(
  'notifications/updateNotifications',
  async (payload: any) =>
    api.put('/notifications/api/bulk-update', keysToSnakeCase(payload)).then(({ data }) => data),
)

const notifications = createSlice({
  name: 'notifications',
  initialState,
  reducers: {
    updateMode(state, { payload }) {
      state.mode = payload
    },
    addNewNotif(state, { payload }) {
      state.notificationsToday = [payload, ...state.notificationsToday]
      state.notificationsThisWeek = [payload, ...state.notificationsThisWeek]
      state.weekNotifsByDay = partitionWeekNotifsByDay(state.notificationsThisWeek)
    },
  },
  extraReducers(builder) {
    builder
      .addCase(getNotifications.pending, state => {
        state.loading.notifications = true
      })
      .addCase(getNotifications.fulfilled, (state, { payload }) => {
        const { today, week, weekByDay } = payload
        state.loading.notifications = false
        state.notificationsToday = today
        state.notificationsThisWeek = week
        state.weekNotifsByDay = weekByDay
      })
      .addCase(getNotifications.rejected, state => {
        state.loading.notifications = false
      })
      .addCase(updateNotifications.pending, state => {
        state.loading.update = true
      })
      .addCase(updateNotifications.fulfilled, (state, action) => {
        state.loading.update = false
        action.payload.forEach((notif: any) => {
          const today = findIndex(state.notificationsToday, { id: notif.id })
          if (today !== -1) {
            state.notificationsToday[today] = notif
          }
          const week = findIndex(state.notificationsThisWeek, { id: notif.id })
          if (week !== -1) {
            state.notificationsThisWeek[week] = notif
          }
          state.weekNotifsByDay.forEach(([day, notifs]: [string, any[]], idx: number) => {
            const weekDay = findIndex(notifs, { id: notif.id })
            if (weekDay !== -1) {
              const updated = [...notifs]
              updated[weekDay] = notif
              state.weekNotifsByDay[idx] = [day, updated]
            }
          })
        })
      })
      .addCase(updateNotifications.rejected, state => {
        state.loading.update = false
      })
  },
})

// groups a collection (typically, the notifsThisWeek collection) by day (in
// ISOString format) then breaks that object down into entries to effectively
// represent chronological order
const partitionWeekNotifsByDay = (weekNotifs: any[]) => {
  const weekNotifsByDay = groupBy(weekNotifs, (notif: any) =>
    dayjs(notif.createdAt).startOf('day').toISOString(),
  )

  const weekByDayNotifEntries: Array<[string, any[]]> = sortBy(Object.entries(weekNotifsByDay), [
    (e: any) => dayjs(e[0]).toDate(),
  ])
    .reverse()
    .map((e: any) => [dayjs(e[0]).format('dddd MM/DD/YYYY'), e[1]])

  return weekByDayNotifEntries
}

export const { updateMode, addNewNotif } = notifications.actions

export default notifications.reducer
