import { TextInput } from '@components'
import { InformationCircleIcon } from '@heroicons/react/24/outline'
import { ClockIcon, CubeIcon, DocumentIcon, MapPinIcon, ScaleIcon } from '@heroicons/react/24/solid'
import { useEffect, useState } from 'react'
import { useWatch } from 'react-hook-form'

import { getTimeMaskOptions } from '../../../../common/utils/masks'
import { InputContainer } from '../../../InputContainer'
import { Tags } from '../../../LoadDetails/components/Tags'
import type { Item, ReviewViewProps, StopItem } from '../types'
import { formatDate } from '../utils'
import { InputRow } from './InputRow'
import { Section } from './Section'

export const ReviewView = ({ routeControl, itemsControl, tags, setTags }: ReviewViewProps) => {
  const routeStops = useWatch({
    control: routeControl,
    name: 'stops',
  })

  const itemsStops = useWatch({
    control: itemsControl,
    name: 'stops',
  })

  const [stops, setStops] = useState([])

  useEffect(() => {
    const stops = routeStops.map((stop: StopItem, idx: number) => ({
      ...stop,
      ...itemsStops[idx],
      sectionTitle: getStopLabel(idx, routeStops.length),
    }))
    setStops(stops)
  }, [routeStops])

  return (
    <div className='flex flex-col gap-6'>
      <div className='bg-lighter-blue border-l-4 border-link rounded p-4 flex items-center justify-between'>
        <div className='text-base font-semibold'>Tags (optional)</div>
        <Tags
          data={tags}
          handleUpdate={updatedTag => setTags({ ...tags, [updatedTag.key]: !tags[updatedTag.key] })}
        />
      </div>
      {stops.map((stop: any) => (
        <Section key={stop.id} badgeCount={stop.items.length} title={stop.sectionTitle}>
          <InputRow>
            <InputContainer className='w-1/3' icon={<MapPinIcon />} iconContainerClassName='mt-5'>
              <TextInput
                disabled
                sm
                className='w-full'
                label='Origin Location'
                placeholder='Origin Location'
                value={stop.location.name}
                onChange={() => {}}
              />
            </InputContainer>
            <InputContainer className='w-2/3' icon={<ClockIcon />} iconContainerClassName='mt-5'>
              <TextInput
                disabled
                sm
                className='w-1/3'
                label={`${stop.stopType === 1 ? 'Pickup' : 'Dropoff'} Date`}
                value={formatDate(stop.stopDate, '/')}
              />
              <TextInput
                disabled
                sm
                className='w-1/3'
                label='Early Time'
                maskOptions={getTimeMaskOptions()}
                value={stop.stopEarlyTime}
              />
              <TextInput
                disabled
                sm
                className='w-1/3'
                label='Late Time'
                maskOptions={getTimeMaskOptions()}
                value={stop.stopLateTime}
              />
            </InputContainer>
          </InputRow>
          <div className='flex flex-col gap-4'>
            {stop.items.map((item: Item) => (
              <div
                key={item.manifest}
                className='flex flex-col px-4 pt-4 pb-1 gap-2 rounded-lg bg-lighter-blue'
              >
                <InputRow>
                  <InputContainer
                    className='w-1/3'
                    icon={<DocumentIcon />}
                    iconContainerClassName='mt-5'
                  >
                    <TextInput
                      disabled
                      sm
                      className='w-full'
                      label='Manifest'
                      value={item.manifest}
                      onChange={() => {}}
                    />
                  </InputContainer>
                  <InputContainer
                    className='w-1/3'
                    icon={<CubeIcon />}
                    iconContainerClassName='mt-5'
                  >
                    <TextInput
                      disabled
                      sm
                      className='w-full'
                      label='Quantity'
                      value={item.quantity}
                      onChange={() => {}}
                    />
                  </InputContainer>
                  <InputContainer className='w-1/3'>
                    <TextInput
                      disabled
                      sm
                      className='w-full'
                      label='Weight'
                      value={item.weight}
                      onChange={() => {}}
                    />
                  </InputContainer>
                </InputRow>
                <InputRow>
                  <InputContainer
                    className='w-1/3'
                    icon={<ScaleIcon />}
                    iconContainerClassName='mt-5'
                  >
                    <TextInput
                      disabled
                      sm
                      className='w-full'
                      label='Length'
                      value={item.length}
                      onChange={() => {}}
                    />
                  </InputContainer>
                  <InputContainer className='w-1/3'>
                    <TextInput
                      disabled
                      sm
                      className='w-full'
                      label='Width'
                      value={item.width}
                      onChange={() => {}}
                    />
                  </InputContainer>
                  <InputContainer className='w-1/3'>
                    <TextInput
                      disabled
                      sm
                      className='w-full'
                      label='Height'
                      value={item.height}
                      onChange={() => {}}
                    />
                  </InputContainer>
                </InputRow>
                {item.notes && (
                  <InputRow>
                    <InputContainer
                      className='w-full'
                      icon={<InformationCircleIcon />}
                      iconContainerClassName='mt-5'
                    >
                      <TextInput
                        disabled
                        sm
                        className='w-full'
                        label='Notes'
                        value={item.notes}
                        onChange={() => {}}
                      />
                    </InputContainer>
                  </InputRow>
                )}
              </div>
            ))}
          </div>
        </Section>
      ))}
    </div>
  )
}

export const useStopLabels = (stops: StopItem[]) =>
  (stops ?? []).map((_: StopItem, idx: number) => getStopLabel(idx, stops.length))

const getStopLabel = (idx: number, total: number) => {
  if (idx === 0) {
    return 'Origin'
  } else if (idx === total - 1) {
    return 'Destination'
  } else {
    return `Stop ${idx}`
  }
}
