import { CatchError, formatAxiosErrorToPayload, getErrorString } from '@common'
import { downloadCSV } from '@common'
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { cloneDeep, mapKeys, omit, snakeCase } from 'lodash-es'
import { toast } from 'react-toastify'

import { api } from '../api/api'
import {
  AccountingAdditionalInformation,
  CSVField,
  CustomerAddress,
  CustomerCompany,
  CustomerDocument,
  CustomerExternalPortal,
  CustomerGeneralInformation,
  CustomerNote,
  CustomerSpecialRequirement,
  CustomerTeamEmail,
  Location,
  NewAccessorial,
  Note,
  OnboardingCustomerContact,
  RootState,
  SearchFilters,
  TableOrder,
} from '../common/types'
import {
  cleanFilters,
  filterAndSortContacts,
  formatArrayOfIds,
  getCustomerCreationPayload,
  getCustomerUpdatePayload,
  getOrderingString,
  keysToCamelCase,
  normalizeCustomerDetails,
  randomString,
  serializeAddress,
} from '../common/utils'

const initialIdsToDelete = {
  contacts: [],
  teamEmails: [],
  locations: [],
  accessorials: [],
  externalPortals: [],
  specialRequirements: [],
}

export type IdsToDelete = {
  contacts: number[]
  teamEmails: number[]
  locations: number[]
  accessorials: number[]
  externalPortals: number[]
  specialRequirements: number[]
}

export type CustomerInfo = {
  contactsStep: {
    contacts: OnboardingCustomerContact[]
    teamEmails: CustomerTeamEmail[]
  }
  generalInformation: CustomerGeneralInformation
  externalPortals: CustomerExternalPortal[]
  specialRequirements: CustomerSpecialRequirement[]
  notes: CustomerNote[]
  documents: CustomerDocument[]
  addressesStep: {
    primaryAddress: CustomerAddress
    facilities: CustomerAddress[]
  }
  accessorialsStep: {
    process: string
    accessorials: NewAccessorial[]
  }
  accountingStep: {
    contacts: OnboardingCustomerContact[]
    billingAddress: CustomerAddress
    additional: AccountingAdditionalInformation
  }
  billingContactError?: boolean
}

export const initialCustomerInfo: CustomerInfo = {
  contactsStep: {
    contacts: [
      {
        id: 'primary-contact',
        name: '',
        phone: '',
        email: '',
        notes: '',
        shipperPortalEnabled: false,
        primary: true,
        pickupNotifications: false,
        deliveryNotifications: false,
        driverInfoNotifications: false,
        accounting: false,
        invoice: false,
        receiveNOA: false,
        marketingContact: false,
        receiveMarketingEmails: false,
        isValid: false,
      },
    ],
    teamEmails: [{ name: '', email: '', id: 'primary-email', isValid: false }],
  },
  generalInformation: {
    id: randomString(),
    name: '',
    phone: '',
    email: '',
    notes: '',
    mcNumber: '',
    creditLimit: '',
    freightQuickbooksId: '',
    financeQuickbooksId: '',
    needsVerificationForFactoring: false,
    isValid: false,
    utilizedCredit: '',
    factoringCreditExpiration: '',
    anonymizeLoadboardPostings: false,
    autoPostToLoadboards: true,
    disableLoadboardPosting: false,
  },
  externalPortals: [
    {
      id: 'primary-portal',
      name: '',
      url: '',
      email: '',
      password: '',
      notes: '',
      isValid: false,
    },
  ],
  specialRequirements: [
    { id: 'primary-requirement', name: '', showOnRateCon: false, isValid: false },
  ],
  notes: [],
  documents: [],
  addressesStep: {
    primaryAddress: { id: 'primary-address', isValid: false, currentPlace: null },
    facilities: [],
  },
  accessorialsStep: {
    process: '',
    accessorials: [],
  },
  accountingStep: {
    contacts: [],
    billingAddress: { id: 'billing-address', isValid: false, currentPlace: null },
    additional: {},
  },
}

type CustomersState = {
  customers: Array<CustomerCompany>
  count: number
  size: number
  offset: number
  currentCustomer: CustomerCompany
  currentCustomerBackup: CustomerCompany
  filters: SearchFilters
  loading: {
    addCarrier: boolean
    addLocation: boolean
    details: boolean
    list: boolean
    saveCustomerDetails: boolean
    createCustomer: boolean
    saveNote: Array<number>
    notes: boolean
    deleteCustomerLocationRelationship: boolean
    deleteCustomerCarrierRelationship: boolean
    deleteNote: boolean
    exportCSV: boolean
    contacts: boolean
    addNote: boolean
    updateNote: boolean
    documents: boolean
    deleteDocument: boolean
    addDocument: boolean
    updateDocument: boolean
    createNewCustomer: boolean
    updateCustomer: boolean
  }
  orderBy: TableOrder
  totalShipments: number
  averageMargin: number
  customerStatsMetabaseLink: ''
  notesCount: number
  newNote: string
  notes: Array<Note>
  notesBackup: Array<Note>
  exportFields: CSVField[]
  selectedFields: Array<string>
  onboardingCustomer: CustomerInfo
  customerInfo: CustomerInfo
  backupCustomerInfo: CustomerInfo
  isCreateModalVisible: boolean
  documentsCount: number
  idsToDelete: IdsToDelete
}

const initialState: CustomersState = {
  customers: [],
  currentCustomer: {
    id: -1,
    anonymizeLoadboardPostings: false,
    autoPostToLoadboards: true,
    disableLoadboardPosting: false,
  },
  currentCustomerBackup: {
    id: -1,
    anonymizeLoadboardPostings: false,
    autoPostToLoadboards: true,
    disableLoadboardPosting: false,
  },
  count: 0,
  size: 50,
  offset: 0,
  filters: {},
  loading: {
    addCarrier: false,
    addLocation: false,
    details: false,
    list: false,
    saveCustomerDetails: false,
    createCustomer: false,
    saveNote: [],
    notes: false,
    deleteCustomerLocationRelationship: false,
    deleteCustomerCarrierRelationship: false,
    deleteNote: false,
    exportCSV: false,
    contacts: false,
    addNote: false,
    updateNote: false,
    documents: false,
    deleteDocument: false,
    addDocument: false,
    updateDocument: false,
    createNewCustomer: false,
    updateCustomer: false,
  },
  orderBy: {
    label: '',
    direction: '',
    key: '',
  },
  totalShipments: 0,
  averageMargin: 0,
  notesCount: 0,
  newNote: '',
  notes: [],
  notesBackup: [],
  exportFields: [
    { label: 'Customer Name', key: 'name' },
    { label: 'Parent Company', key: 'parent_company' },
    { label: 'Location', key: 'address' },
    { label: 'Shipments', key: 'shipment' },
    { label: 'Total Revenue', key: 'total_revenue' },
    { label: 'Expansion Rep', key: 'owner' },
    { label: 'Acquisition Rep', key: 'manager' },
    { label: 'Phone', key: 'phone' },
    { label: 'Email', key: 'email' },
  ],
  selectedFields: [
    'name',
    'parent_company',
    'address',
    'shipment',
    'total_revenue',
    'manager',
    'owner',
    'phone',
    'email',
  ],
  onboardingCustomer: initialCustomerInfo,
  customerInfo: initialCustomerInfo,
  backupCustomerInfo: initialCustomerInfo,
  customerStatsMetabaseLink: '',
  isCreateModalVisible: false,
  documentsCount: 0,
  idsToDelete: initialIdsToDelete,
}

const getFilters = (filters: SearchFilters) =>
  cleanFilters({
    name__icontains: filters.customerName,
    parent: filters.parentCompany,
    city__iexact: filters.city,
    state__iexact: filters.state,
    account_owner__in: formatArrayOfIds(filters.accountOwnersList),
    account_manager__in: formatArrayOfIds(filters.accountManagersList),
    mc_number__icontains: filters.mcNumber,
  })

// Get list of customer notes
export const getCustomerNotes = createAsyncThunk(
  'customers/getCustomerNotes',
  async (payload: { customerId: string | number; limit?: number }) =>
    api
      .get(`/accounts/api/customer/note/${payload.customerId}/`, {
        params: {
          limit: payload.limit || 50,
        },
      })
      .then(({ data }) => keysToCamelCase(data)),
)

// Get list of customer documents
export const getCustomerDocuments = createAsyncThunk(
  'customers/getCustomerDocuments',
  async (payload: { customerId: string | number; limit?: number }) =>
    api
      .get(`/accounts/api/customer/document/${payload.customerId}/`, {
        params: {
          limit: payload.limit || 50,
        },
      })
      .then(({ data }) => keysToCamelCase(data)),
)

export const deleteCustomerDocument = createAsyncThunk(
  'customers/deleteCustomerDocument',
  async (documentId: string | number, { dispatch, getState }) => {
    const {
      customerInfo: {
        generalInformation: { id },
      },
    } = (getState() as RootState).customers
    const response = await api.delete(`/accounts/api/customer/document-rud/${documentId}/`)
    dispatch(getCustomerDocuments({ customerId: id }))
    return keysToCamelCase(response.data)
  },
)

export const addCustomerDocument = createAsyncThunk(
  'customers/addCustomerDocument',
  async (
    { name, file }: { name: string; file: File; documentId: string | number },
    { dispatch, getState, rejectWithValue },
  ) => {
    const {
      customerInfo: {
        generalInformation: { id },
      },
    } = (getState() as RootState).customers

    const formData = new FormData()
    formData.append('file', file as File)
    formData.append('name', name)

    try {
      const response = await api.post(`/accounts/api/customer/document/${id}/`, formData)
      dispatch(getCustomerDocuments({ customerId: id }))
      return keysToCamelCase(response.data)
    } catch (err: CatchError) {
      return rejectWithValue(formatAxiosErrorToPayload(err))
    }
  },
)

export const updateCustomerDocument = createAsyncThunk(
  'customers/updateCustomerDocument',
  async (
    { documentId, name, file }: { documentId: number | string; name: string; file?: File },
    { dispatch, getState, rejectWithValue },
  ) => {
    const {
      customerInfo: {
        generalInformation: { id },
      },
    } = (getState() as RootState).customers

    const formData = new FormData()
    if (typeof file === 'object') formData.append('file', file as File)
    formData.append('name', name)

    try {
      const response = await api.put(`/accounts/api/customer/document-rud/${documentId}/`, formData)
      dispatch(getCustomerDocuments({ customerId: id }))
      return keysToCamelCase(response.data)
    } catch (err: CatchError) {
      return rejectWithValue(formatAxiosErrorToPayload(err))
    }
  },
)

// Create a new customer note
export const addCustomerNote = createAsyncThunk(
  'customers/addCustomerNote',
  async (payload: { note: CustomerNote; customerId?: string }, { rejectWithValue }) => {
    try {
      const response = await api.post(`/accounts/api/customer/note/${payload.customerId}/`, {
        note: payload.note.note,
        subject: payload.note.subject,
      })

      return response.data
    } catch (err: CatchError) {
      return rejectWithValue(formatAxiosErrorToPayload(err))
    }
  },
)

export const addCustomerNoteOld = createAsyncThunk(
  'customers/addCustomerNote',
  async (payload: { note: string; customerId: string }, { rejectWithValue }) => {
    try {
      const response = await api.post(`/accounts/api/customer/note/${payload.customerId}/`, {
        note: payload.note,
      })

      return response.data
    } catch (err: CatchError) {
      return rejectWithValue(formatAxiosErrorToPayload(err))
    }
  },
)

// Update an existing customer note
export const updateCustomerNote = createAsyncThunk(
  'customers/updateCustomerNote',
  async (payload: CustomerNote) =>
    api
      .put(`/accounts/api/customer/note-rud/${payload.id}/`, {
        note: payload.note,
        subject: payload.subject,
      })
      .then(({ data }) => data),
)

// Delete an existing customer note
export const deleteCustomerNote = createAsyncThunk(
  'customers/deleteCustomerNote',
  async (noteId: number | string, { getState, dispatch, rejectWithValue }) => {
    const {
      customerInfo: {
        generalInformation: { id },
      },
    } = (getState() as RootState).customers

    try {
      await api.delete(`/accounts/api/customer/note-rud/${noteId}/`)
      dispatch(getCustomerNotes({ customerId: id }))
    } catch (err: CatchError) {
      return rejectWithValue(formatAxiosErrorToPayload(err))
    }
  },
)

export const deleteCustomerNoteOld = createAsyncThunk(
  'customers/deleteCustomerNote',
  async (payload: { noteId: string | number }, { getState, dispatch }) => {
    const { currentCustomer } = (getState() as RootState).customers
    await api.delete(`/accounts/api/customer/note-rud/${payload.noteId}/`)
    dispatch(getCustomerNotes({ customerId: currentCustomer?.id }))
    return payload.noteId
  },
)

export const updateCustomerNoteOld = createAsyncThunk(
  'customers/updateCustomerNote',
  async (payload: { note: string; noteId: string | number }) =>
    api
      .put(`/accounts/api/customer/note-rud/${payload.noteId}/`, {
        note: payload.note,
      })
      .then(({ data }) => data),
)

export const createNewCustomer = createAsyncThunk(
  'customers/createNewCustomer',
  async (_, { getState, dispatch, rejectWithValue }) => {
    const { onboardingCustomer } = (getState() as RootState).customers

    const payload = getCustomerCreationPayload(onboardingCustomer)

    try {
      const response = await api.post('/accounts/create-external-customer/', payload)
      dispatch(getCustomers())
      return response.data
    } catch (err: CatchError) {
      return rejectWithValue(formatAxiosErrorToPayload(err))
    }
  },
)

export const updateCustomer = createAsyncThunk(
  'customers/updateCustomer',
  async (_, { getState, dispatch, rejectWithValue }) => {
    const { customerInfo, backupCustomerInfo, idsToDelete } = (getState() as RootState).customers

    const payload = getCustomerUpdatePayload(customerInfo, backupCustomerInfo, idsToDelete)

    try {
      const response = await api.patch(
        `/accounts/api/customer/customer-company-update/${customerInfo.generalInformation.id}/`,
        payload,
      )
      dispatch(getCustomerDetails({ customerId: customerInfo.generalInformation.id }))
      dispatch(getCustomerContacts({ customerId: customerInfo.generalInformation.id }))
      return response.data
    } catch (err: CatchError) {
      return rejectWithValue(formatAxiosErrorToPayload(err))
    }
  },
)

// Get list of customers for customers page
export const getCustomers = createAsyncThunk('customers/getCustomers', async (_, { getState }) => {
  const {
    filters,
    size = 50,
    offset = 0,
    orderBy: { label, direction, key },
  } = (getState() as RootState).customers

  const ordering = getOrderingString(label, direction, key, '-id')

  const response = await api.get('/accounts/api/customer/list/', {
    params: {
      limit: size,
      offset,
      ordering,
      ...getFilters(filters),
    },
  })
  return response.data
})

// Get list of contacts for a particular customer
export const getCustomerContacts = createAsyncThunk(
  'customers/getCustomerContacts',
  async (payload: { customerId: number | string }) => {
    const response = await api.get(`/accounts/api/customer/contact/${payload.customerId}/`)

    return keysToCamelCase(response.data.results).map(
      ({ firstName, lastName, ...contact }: any) => ({
        ...contact,
        name: `${firstName}${lastName ? ` ${lastName}` : ''}`,
      }),
    )
  },
)

// Get details for a single customer
export const getCustomerDetails = createAsyncThunk(
  'customers/getCustomerDetails',
  async ({ customerId }: { customerId: number | string }) => {
    const detailsRequest = api.get(`/accounts/api/customer/customer-rud/${customerId}/`)
    const statsRequest = api.get(`/accounts/api/customer/stats/${customerId}/`)

    const [customerDetails, customerStats] = await Promise.all([detailsRequest, statsRequest])
    return {
      currentCustomer: {
        addressDisplay: serializeAddress({
          street: customerDetails.data.address,
          city: customerDetails.data.city,
          stateCode: customerDetails.data.state || customerDetails.data.ca_province,
          postalCode: customerDetails.data.us_zipcode || customerDetails.data.ca_postal_code,
          countryCode: customerDetails.data.country,
        }),
        ...keysToCamelCase(customerDetails.data),
      },
      totalShipments: customerStats.data.total_shipments,
      averageMargin: customerStats.data.average_margin,
      metabaseLink: customerStats.data.metabase_link,
    }
  },
)

// Update details of a single customer
export const saveCustomerDetails = createAsyncThunk(
  'customers/saveCustomerDetails',
  async (payload: CustomerCompany, { rejectWithValue }) => {
    // Prepare customer object for backend
    payload = mapKeys(payload, (_, key) => snakeCase(key)) as any
    payload = omit(payload, ['locations, carriers']) as any

    try {
      const response = await api.put(`/accounts/api/customer/customer-rud/${payload.id}/`, {
        ...payload,
      })
      response.data.addressDisplay = serializeAddress({
        street: response.data.address,
        city: response.data.city,
        stateCode: response.data.state || response.data.ca_province,
        postalCode: response.data.us_zipcode || response.data.ca_postal_code,
        countryCode: response.data.country,
      })
      return response.data
    } catch (err: CatchError) {
      return rejectWithValue(formatAxiosErrorToPayload(err))
    }
  },
)

// Update active status of a single customer
export const updateCustomerActiveStatus = createAsyncThunk(
  'customers/updateCustomerActiveStatus',
  async ({ id, active }: { id: string | number; active: boolean }) => {
    const response = await api.put(`/accounts/api/customer/customer-company-update/${id}/`, {
      active: active,
    })
    return response.data.active
  },
)

// Add a new Customer/Carrier relationship
export const addCustomerCarrierRelationship = createAsyncThunk(
  'customers/addCustomerCarrierRelationship',
  async ({
    carrier,
    customerId,
    status,
  }: {
    carrier: { id: number; text: string }
    customerId: number
    status: string
  }) => {
    await api.put('/accounts/api/carrier-customer-relationship-rud/', {
      carrier_company_id: carrier.id,
      customer_company_id: customerId,
      status: status,
    })
    return {
      id: carrier.id,
      status: status,
      name: carrier.text.split('DOT:')[0].split('MC:')[0],
    }
  },
)

// Delete a particular Customer/Carrier relationship
export const deleteCustomerCarrierRelationship = createAsyncThunk(
  'customers/deleteCustomerCarrierRelationship',
  async ({
    customerCompanyId,
    carrierCompanyId,
  }: {
    customerCompanyId: string | number
    carrierCompanyId: string | number
  }) => {
    await api.delete('/accounts/api/carrier-customer-relationship-rud/', {
      data: mapKeys({ customerCompanyId, carrierCompanyId }, (_, key) => snakeCase(key)),
    })
    return carrierCompanyId
  },
)

// Add a new Customer/Location relationship
export const addCustomerLocationRelationship = createAsyncThunk(
  'customers/addCustomerLocationRelationship',
  async ({ location, customerId }: { location: Location; customerId: number }) => {
    await api.put('/locations/api/location-customer-relationship-rud/', {
      location_id: location.id,
      customer_company_id: customerId,
    })
    return location
  },
)

// Delete a particular Customer/Location relationship
export const deleteCustomerLocationRelationship = createAsyncThunk(
  'customers/deleteCustomerLocationRelationship',
  async (payload: { customerCompanyId: string | number; locationId: string | number }) => {
    await api.delete('/locations/api/location-customer-relationship-rud/', {
      data: mapKeys(payload, (_, key) => snakeCase(key)),
    })
    return payload.locationId
  },
)

export const exportCustomersCSV = createAsyncThunk(
  'customers/exportCustomersCSV',
  async (_, { getState, rejectWithValue }) => {
    const { filters, selectedFields } = (getState() as RootState).customers

    try {
      const response: any = await api.post('/accounts/api/export-account-data/', {
        type: 'customer',
        filters: getFilters(filters),
        fields: selectedFields.join(','),
      })

      downloadCSV(response.data, 'customers')
    } catch (err: CatchError) {
      return rejectWithValue(formatAxiosErrorToPayload(err))
    }
  },
)

export const customersSlice = createSlice({
  name: 'customers',
  initialState,
  reducers: {
    setSize(state, { payload }) {
      state.size = payload
    },
    setOffset(state, { payload }) {
      state.offset = payload
    },
    setCurrentCustomer(state, { payload }) {
      state.currentCustomer = payload
    },
    setOrderBy(state, { payload }) {
      state.orderBy = payload
    },
    setFilters(state, { payload }) {
      state.filters = payload
    },
    updateCurrentCustomer(state, { payload }) {
      // Update specific fields for the currentCustomer
      const currentCustomerCopy = { ...state.currentCustomer }
      payload.fields.map((field: keyof CustomerCompany, i: number) => {
        // @ts-ignore - this is a dynamic key
        currentCustomerCopy[field] = payload.values[i]
      })
      state.currentCustomer = currentCustomerCopy
    },
    setCustomerNote(state, { payload }) {
      state.notes[payload.index].note = payload.value
    },
    resetCustomerNote(state, { payload }) {
      state.notes[payload.index].note = state.notesBackup[payload.index].note
    },
    setNewNote(state, { payload }) {
      state.newNote = payload
    },
    setSelectedFields(state, { payload }) {
      state.selectedFields =
        typeof payload === 'string'
          ? state.selectedFields.includes(payload)
            ? state.selectedFields.filter(field => field !== payload)
            : [...state.selectedFields, payload]
          : payload
    },
    setCustomerInfo(state, { payload }) {
      const key = state.isCreateModalVisible ? 'onboardingCustomer' : 'customerInfo'
      state[key] = { ...state[key], ...payload }
    },
    setCreateModalVisible(state, { payload }) {
      state.isCreateModalVisible = payload
    },
    setIdsToDelete(state, { payload }) {
      state.idsToDelete = { ...state.idsToDelete, ...payload }
    },
    resetIdsToDelete(state) {
      state.idsToDelete = initialIdsToDelete
    },
  },
  extraReducers(builder) {
    builder
      .addCase(getCustomers.pending, state => {
        state.loading.list = true
      })
      .addCase(getCustomers.fulfilled, (state, action) => {
        const { results, count } = action.payload
        state.customers = keysToCamelCase(results)
        state.count = count
        state.loading.list = false
      })
      .addCase(getCustomers.rejected, state => {
        state.loading.list = false
        toast.error('Failed to load customers')
      })
      .addCase(getCustomerDetails.pending, state => {
        state.loading.details = true
      })
      .addCase(getCustomerDetails.fulfilled, (state, { payload }) => {
        state.currentCustomer = {
          ...state.currentCustomer,
          ...payload.currentCustomer,
        }
        const { currentCustomer } = payload
        const customerInfo = normalizeCustomerDetails(state, currentCustomer)
        state.customerInfo = customerInfo
        state.backupCustomerInfo = customerInfo
        state.currentCustomerBackup = cloneDeep(state.currentCustomer)
        state.totalShipments = payload.totalShipments
        state.averageMargin = payload.averageMargin
        state.customerStatsMetabaseLink = payload.metabaseLink
        state.idsToDelete = initialIdsToDelete
        state.loading.details = false
      })
      .addCase(getCustomerContacts.pending, state => {
        state.loading.contacts = true
      })
      .addCase(getCustomerContacts.fulfilled, (state, { payload }) => {
        state.customerInfo = {
          ...state.customerInfo,
          contactsStep: {
            ...state.customerInfo.contactsStep,
            contacts: filterAndSortContacts(payload),
          },
          accountingStep: {
            ...state.customerInfo.accountingStep,
            contacts: filterAndSortContacts(payload, true),
          },
        }
        state.customerInfo.billingContactError = !payload.find(
          (contact: { isInvoice: boolean }) => contact.isInvoice,
        )
        state.loading.contacts = false
      })
      .addCase(getCustomerContacts.rejected, state => {
        state.loading.contacts = false
        toast.error('Failed to load customer contacts')
      })
      .addCase(saveCustomerDetails.pending, state => {
        state.loading.saveCustomerDetails = true
      })
      .addCase(saveCustomerDetails.fulfilled, (state, action) => {
        state.loading.saveCustomerDetails = false
        state.currentCustomer = {
          ...state.currentCustomer,
          ...keysToCamelCase(action.payload),
        }
        state.currentCustomerBackup = cloneDeep(state.currentCustomer)
        toast.success('Successfully updated customer info')
      })
      .addCase(saveCustomerDetails.rejected, (state, { payload }) => {
        state.loading.saveCustomerDetails = false
        toast.error(getErrorString(payload, 'Failed to save customer details'))
      })
      .addCase(updateCustomerActiveStatus.fulfilled, (state, action) => {
        state.currentCustomer.active = action.payload
        state.currentCustomerBackup.active = action.payload
      })
      .addCase(updateCustomerActiveStatus.rejected, () => {
        toast.error('Failed to update active status')
      })
      .addCase(deleteCustomerLocationRelationship.pending, state => {
        state.loading.deleteCustomerLocationRelationship = true
      })
      .addCase(deleteCustomerLocationRelationship.fulfilled, (state, action) => {
        const locationId = action.payload
        state.currentCustomer = {
          ...state.currentCustomer,
          locations: state.currentCustomer.locations?.filter(location => location.id != locationId),
        }
        state.loading.deleteCustomerLocationRelationship = false
        toast.success('Successfully deleted customer-location relationship')
      })
      .addCase(deleteCustomerLocationRelationship.rejected, state => {
        state.loading.deleteCustomerLocationRelationship = false
        toast.error('Failed to delete customer-location relationship')
      })
      .addCase(addCustomerLocationRelationship.pending, state => {
        state.loading.addLocation = true
      })
      .addCase(addCustomerLocationRelationship.fulfilled, (state, action) => {
        state.loading.addLocation = false
        const existing = state.currentCustomer.locations?.findIndex(
          location => location.id == action.payload.id,
        )
        if (existing === -1) state.currentCustomer.locations?.unshift(action.payload)
        toast.success('Successfully added customer-location relationship')
      })
      .addCase(addCustomerLocationRelationship.rejected, state => {
        state.loading.addLocation = false
        toast.error('Failed to add customer-location relationship')
      })
      .addCase(deleteCustomerCarrierRelationship.pending, state => {
        state.loading.deleteCustomerCarrierRelationship = true
      })
      .addCase(deleteCustomerCarrierRelationship.fulfilled, (state, action) => {
        state.loading.deleteCustomerCarrierRelationship = false
        const carrierId = action.payload
        state.currentCustomer = {
          ...state.currentCustomer,
          carriers: state.currentCustomer.carriers?.filter(carrier => carrier.id != carrierId),
        }
        toast.success('Successfully deleted customer-carrier relationship')
      })
      .addCase(deleteCustomerCarrierRelationship.rejected, state => {
        state.loading.deleteCustomerCarrierRelationship = false
        toast.error('Failed to deleted customer-carrier relationship')
      })
      .addCase(addCustomerCarrierRelationship.pending, state => {
        state.loading.addCarrier = true
      })
      .addCase(addCustomerCarrierRelationship.fulfilled, (state, action) => {
        state.loading.addCarrier = false
        const existing = state.currentCustomer.carriers?.findIndex(
          carrier => carrier.id == action.payload.id,
        )
        if (existing === -1) state.currentCustomer.carriers?.unshift(action.payload)
        toast.success('Successfully added customer-carrier relationship')
      })
      .addCase(addCustomerCarrierRelationship.rejected, state => {
        state.loading.addCarrier = false
        toast.error('Failed to add customer-carrier relationship')
      })
      .addCase(createNewCustomer.pending, state => {
        state.loading.createNewCustomer = true
      })
      .addCase(createNewCustomer.fulfilled, state => {
        state.loading.createNewCustomer = false
        state.isCreateModalVisible = false
        toast.success('Successfully created new customer')
      })
      .addCase(createNewCustomer.rejected, (state, { payload }) => {
        state.loading.createNewCustomer = false
        toast.error(getErrorString(payload, 'Failed to create new customer'))
      })
      .addCase(updateCustomer.pending, state => {
        state.loading.updateCustomer = true
      })
      .addCase(updateCustomer.fulfilled, state => {
        state.loading.updateCustomer = false
        toast.success('Successfully updated customer')
      })
      .addCase(updateCustomer.rejected, (state, { payload }) => {
        state.loading.updateCustomer = false
        toast.error(getErrorString(payload, 'Failed to update customer'))
      })
      .addCase(getCustomerNotes.pending, state => {
        state.loading.notes = true
      })
      .addCase(getCustomerNotes.fulfilled, (state, action) => {
        const { results, count } = action.payload
        state.notesCount = count
        state.notes = keysToCamelCase(results)
        state.notesBackup = cloneDeep(state.notes)
        state.loading.notes = false
      })
      .addCase(getCustomerNotes.rejected, state => {
        state.loading.notes = false
        toast.error('Failed to load customer notes')
      })
      .addCase(getCustomerDocuments.pending, state => {
        state.loading.documents = true
      })
      .addCase(getCustomerDocuments.fulfilled, (state, action) => {
        const { results, count } = action.payload
        state.customerInfo = { ...state.customerInfo, documents: keysToCamelCase(results) }
        state.documentsCount = count
        state.loading.documents = false
      })
      .addCase(getCustomerDocuments.rejected, state => {
        state.loading.documents = false
        toast.error('Failed to load customer documents')
      })
      .addCase(deleteCustomerDocument.pending, state => {
        state.loading.deleteDocument = true
      })
      .addCase(deleteCustomerDocument.fulfilled, state => {
        toast.success('Successfully deleted document')
        state.loading.deleteDocument = false
      })
      .addCase(deleteCustomerDocument.rejected, state => {
        state.loading.deleteDocument = false
        toast.error('Failed to delete document')
      })
      .addCase(addCustomerDocument.pending, state => {
        state.loading.addDocument = true
      })
      .addCase(addCustomerDocument.fulfilled, state => {
        toast.success('Successfully added document')
        state.loading.addDocument = false
      })
      .addCase(addCustomerDocument.rejected, (state, { payload }) => {
        state.loading.addDocument = false
        toast.error(getErrorString(payload, 'Failed to add document'))
      })
      .addCase(updateCustomerDocument.pending, state => {
        state.loading.updateDocument = true
      })
      .addCase(updateCustomerDocument.fulfilled, state => {
        toast.success('Successfully updated document')
        state.loading.updateDocument = false
      })
      .addCase(updateCustomerDocument.rejected, (state, { payload }) => {
        state.loading.updateDocument = false
        toast.error(getErrorString(payload, 'Failed to update document'))
      })
      .addCase(addCustomerNote.pending, state => {
        state.loading.addNote = true
      })
      .addCase(addCustomerNote.fulfilled, (state, action) => {
        state.notes.unshift(keysToCamelCase(action.payload))
        state.notesBackup = cloneDeep(state.notes)
        state.newNote = ''
        state.loading.addNote = false
        toast.success('Successfully added new note')
      })
      .addCase(addCustomerNote.rejected, (state, action) => {
        state.loading.addNote = false
        toast.error(getErrorString(action.payload, 'Failed to save new note'))
      })
      .addCase(updateCustomerNote.pending, state => {
        state.loading.updateNote = true
      })
      .addCase(updateCustomerNote.fulfilled, (state, action) => {
        const updatedNote = keysToCamelCase(action.payload)
        state.notes = state.notes.map((note: Note) =>
          note.id === updatedNote.id ? updatedNote : note,
        )
        state.notesBackup = cloneDeep(state.notes)
        state.loading.updateNote = false
        toast.success('Successfully updated note')
      })
      .addCase(updateCustomerNote.rejected, state => {
        state.loading.updateNote = false
        toast.error('Failed to update note')
      })
      .addCase(deleteCustomerNote.pending, state => {
        state.loading.deleteNote = true
      })
      .addCase(deleteCustomerNote.fulfilled, state => {
        toast.success('Successfully deleted note')
        state.loading.deleteNote = false
      })
      .addCase(deleteCustomerNote.rejected, state => {
        state.loading.deleteNote = false
        toast.error('Failed to delete note')
      })
      .addCase(exportCustomersCSV.pending, state => {
        state.loading.exportCSV = true
      })
      .addCase(exportCustomersCSV.fulfilled, state => {
        state.loading.exportCSV = false
        toast.success('Successfully exported CSV')
      })
      .addCase(exportCustomersCSV.rejected, (state, { payload }) => {
        state.loading.exportCSV = false
        toast.error(getErrorString(payload, 'Failed to export CSV'))
      })
  },
})

export const {
  setFilters,
  setCurrentCustomer,
  setOrderBy,
  updateCurrentCustomer,
  resetCustomerNote,
  setCustomerNote,
  setNewNote,
  setSize,
  setOffset,
  setSelectedFields,
  setCustomerInfo,
  setCreateModalVisible,
  setIdsToDelete,
  resetIdsToDelete,
} = customersSlice.actions

export default customersSlice.reducer
