import { CatchError, formatAxiosErrorToPayload, getErrorString } from '@common'
import { downloadCSV } from '@common'
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import dayjs from 'dayjs'
import { toast } from 'react-toastify'

import { api } from '../api/api'
import { initialFilters } from '../common/constants'
import {
  CSVField,
  ManualQuoteComment,
  MultiStopQuotingParams,
  NewQuote,
  QuoteDetails,
  RootState,
  SearchFilters,
} from '../common/types'
import {
  cleanFilters,
  formatDateForBackend,
  getQuotePayload,
  keysToCamelCase,
  keysToSnakeCase,
} from '../common/utils'

type CustomerQuotesState = {
  quotes: Array<any>
  pendingODQuotes: Array<any>
  loading: {
    quotes: boolean
    quoteDetails: boolean
    addQuote: boolean
    updateQuote: boolean
    exportCSV: boolean
    listBulkQuotes: boolean
    deleteBulkQuotes: boolean
    updateQuoteDetails: boolean
    addQuoteNote: boolean
    deleteQuoteNote: boolean
    updateQuoteNote: boolean
    notifyCustomer: boolean
    deleteQuote: boolean
  }
  count: number
  pendingODQuotesCount: number
  offset: number
  size: number
  filters: SearchFilters
  quoteDetails: QuoteDetails
  quoteId: number | null
  exportFields: CSVField[]
  selectedFields: Array<string>
  bulkQuotesList: Array<any>
  bulkQuotesCount: number
}

const initialState: CustomerQuotesState = {
  quotes: [],
  pendingODQuotes: [],
  loading: {
    quotes: false,
    quoteDetails: false,
    addQuote: false,
    updateQuote: false,
    exportCSV: false,
    listBulkQuotes: false,
    deleteBulkQuotes: false,
    updateQuoteDetails: false,
    addQuoteNote: false,
    deleteQuoteNote: false,
    updateQuoteNote: false,
    notifyCustomer: false,
    deleteQuote: false,
  },
  count: 0,
  pendingODQuotesCount: 0,
  offset: 0,
  size: 50,
  filters: initialFilters,
  quoteDetails: {
    id: 0,
    customerCompany: 0,
    customerCompanyName: '',
    equipmentType: '',
    originCity: '',
    originState: '',
    destinationCity: '',
    destinationState: '',
    carrierPrice: '',
    customerPrice: '',
    notes: '',
    pieces: 0,
    description: '',
    commodity: '',
    length: '',
    width: '',
    height: '',
    weight: '',
    temperature: '',
    manualquoteaccessorialsSet: [],
    manualquotestopsSet: [],
    createdBy: '',
    status: '',
    rejectReason: '',
    createdAt: '',
    loadId: 0,
    shipperQuoteRequestId: null,
  },
  quoteId: null,
  exportFields: [
    { label: 'Customer', key: 'customer' },
    { label: 'Quote ID', key: 'id' },
    { label: 'Equipment Type', key: 'equipment_type' },
    { label: 'Origin City', key: 'origin_city' },
    { label: 'Origin State', key: 'origin_state' },
    { label: 'Stops', key: 'stops' },
    { label: 'Destination City', key: 'destination_city' },
    { label: 'Destination State', key: 'destination_state' },
    { label: 'Quote By', key: 'quote_by' },
    { label: 'Status', key: 'status' },
    { label: 'Carrier Price', key: 'carrier_price' },
    { label: 'Customer Price', key: 'customer_price' },
    { label: 'Load ID', key: 'load_id' },
  ],
  selectedFields: [
    'customer',
    'id',
    'equipment_type',
    'origin_city',
    'origin_state',
    'stops',
    'destination_city',
    'destination_state',
    'quote_by',
    'status',
    'carrier_price',
    'customer_price',
    'load_id',
  ],
  bulkQuotesList: [],
  bulkQuotesCount: 0,
}

const getFilters = (filters: SearchFilters) =>
  cleanFilters({
    equipment_type: filters.equipmentType,
    load_id: filters.loadId,
    id: filters.quoteId,
    origin_city__icontains: filters.originCity,
    origin_state__iexact: filters.originState,
    destination_city__icontains: filters.destinationCity,
    destination_state__iexact: filters.destinationState,
    created_at__lte: formatDateForBackend(filters.dateQuoted?.[1] ?? null),
    created_at__gte: formatDateForBackend(filters.dateQuoted?.[0] ?? null),
    status: filters.customerQuoteStatus,
    created_by_id: filters.createdBy,
    customer_company_id: filters.customerCompany,
  })

export const getQuotes = createAsyncThunk('customerQuotes/getQuotes', async (_, { getState }) => {
  const { filters, size = 50, offset = 0 } = (getState() as RootState).customerQuotes

  const response = await api.get('/quotes/api/manual-quotes/', {
    params: {
      limit: size,
      offset,
      not_status: 'OD Pending',
      ...getFilters(filters),
    },
  })

  return keysToCamelCase(response.data)
})

export const getPendingODQuotes = createAsyncThunk(
  'customerQuotes/getPendingODQuotes',
  async (_, { getState }) => {
    const { filters, size = 50, offset = 0 } = (getState() as RootState).customerQuotes

    const response = await api.get('/quotes/api/manual-quotes/', {
      params: {
        limit: size,
        offset,
        status: 'OD Pending',
        ...getFilters(filters),
      },
    })

    return keysToCamelCase(response.data)
  },
)

export const getQuotesForQuotingTool = createAsyncThunk(
  'customerQuotes/getQuotesForQuotingTool',
  async (params: MultiStopQuotingParams) => {
    const response = await api.get('/quotes/api/manual-quotes/', {
      params: {
        limit: 50,
        offset: 0,
        equipment_type: params.equipmentType,
        origin_lat: params.origin.location?.latitude,
        origin_lon: params.origin.location?.longitude,
        destination_lat: params.destination.location?.latitude,
        destination_lon: params.destination.location?.longitude,
        created_at__gte: formatDateForBackend(dayjs().subtract(180, 'd')),
      },
    })

    return keysToCamelCase(response.data)
  },
)

export const updateQuote = createAsyncThunk(
  'customerQuotes/updateQuote',
  async (
    {
      id,
      payload,
    }: {
      id: number
      payload: { status?: string; rejectReason?: string; loadId?: number }
    },
    { dispatch, rejectWithValue },
  ) => {
    try {
      const response = await api.patch(
        `/quotes/api/manual-quote-rud/${id}/`,
        keysToSnakeCase(payload),
      )
      dispatch(getQuotes())
      return keysToCamelCase(response.data)
    } catch (err: CatchError) {
      return rejectWithValue(formatAxiosErrorToPayload(err))
    }
  },
)

export const addQuote = createAsyncThunk(
  'customerQuotes/addQuote',
  async (quote: NewQuote, { rejectWithValue }) => {
    try {
      const response = await api.post(
        '/quotes/api/manual-quotes/',
        keysToSnakeCase(getQuotePayload(quote)),
      )
      return keysToCamelCase(response.data)
    } catch (err: CatchError) {
      return rejectWithValue(formatAxiosErrorToPayload(err))
    }
  },
)

export const getQuoteDetails = createAsyncThunk(
  'customerQuotes/getQuoteDetails',
  async (id: number | string) =>
    api.get(`/quotes/api/manual-quote-rud/${id}/`).then(({ data }) => keysToCamelCase(data)),
)

export const updateQuoteDetails = createAsyncThunk(
  'customerQuotes/updateQuoteDetails',
  async (payload: any, { getState, dispatch, rejectWithValue }) => {
    const { id } = (getState() as RootState).customerQuotes.quoteDetails

    try {
      await api.patch(
        `/quotes/api/manual-quote-rud/${id}/`,
        keysToSnakeCase({ ...payload, pickupDate: dayjs(payload.pickupDate).format('YYYY-MM-DD') }),
      )
      dispatch(getQuoteDetails(id))
    } catch (err: CatchError) {
      if (!err.response) throw err
      return rejectWithValue(formatAxiosErrorToPayload(err))
    }
  },
)

export const notifyCustomer = createAsyncThunk(
  'customerQuotes/notifyCustomer',
  async (_, { getState, rejectWithValue }) => {
    const { id } = (getState() as RootState).customerQuotes.quoteDetails

    try {
      await api.post(`/quotes/api/notify-ready-to-book/${id}/`)
    } catch (err: CatchError) {
      if (!err.response) throw err
      return rejectWithValue(formatAxiosErrorToPayload(err))
    }
  },
)

export const addQuoteNote = createAsyncThunk(
  'customerQuotes/addQuoteNote',
  async (note: string, { getState, dispatch, rejectWithValue }) => {
    const { id } = (getState() as RootState).customerQuotes.quoteDetails

    try {
      const response = await api.post(
        `/quotes/api/manual-quotes/${id}/comment/`,
        keysToSnakeCase({ text: note, isPinned: false }),
      )
      dispatch(getQuoteDetails(id))
      return keysToCamelCase(response.data)
    } catch (err: CatchError) {
      if (!err.response) throw err
      return rejectWithValue(formatAxiosErrorToPayload(err))
    }
  },
)

export const updateQuoteNote = createAsyncThunk(
  'customerQuotes/updateQuoteNote',
  async (
    { commentId, note }: { commentId: number; note: string },
    { getState, dispatch, rejectWithValue },
  ) => {
    const { id } = (getState() as RootState).customerQuotes.quoteDetails

    try {
      const response = await api.put(
        `/quotes/api/manual-quotes/${id}/comment/${commentId}`,
        keysToSnakeCase({ text: note }),
      )
      dispatch(getQuoteDetails(id))
      return keysToCamelCase(response.data)
    } catch (err: CatchError) {
      if (!err.response) throw err
      return rejectWithValue(formatAxiosErrorToPayload(err))
    }
  },
)

export const deleteQuoteNote = createAsyncThunk(
  'customerQuotes/deleteQuoteNote',
  async (commentId: number, { getState, dispatch, rejectWithValue }) => {
    const { id } = (getState() as RootState).customerQuotes.quoteDetails

    try {
      await api.delete(`/quotes/api/manual-quotes/${id}/comment/${commentId}`)
      dispatch(getQuoteDetails(id))
    } catch (err: CatchError) {
      if (!err.response) throw err
      return rejectWithValue(formatAxiosErrorToPayload(err))
    }
  },
)

export const exportCustomerQuotesCSV = createAsyncThunk(
  'customerQuotes/exportCustomerQuotesCSV',
  async (_, { getState, rejectWithValue }) => {
    const { filters, selectedFields } = (getState() as RootState).customerQuotes

    try {
      const response = await api.post('/quotes/api/export-manual-quotes/', {
        filters: getFilters(filters),
        fields: selectedFields.join(','),
      })

      downloadCSV(response.data, 'customer-quotes')
    } catch (err: CatchError) {
      return rejectWithValue(formatAxiosErrorToPayload(err))
    }
  },
)

export const listBulkQuotes = createAsyncThunk(
  'customerQuotes/listBulkQuotes',
  async (payload, { rejectWithValue, getState }) => {
    try {
      const { filters, size = 50, offset = 0 } = (getState() as RootState).customerQuotes
      const response = await api.get('/quotes/api/list-internal-bulk-quotes/', {
        params: {
          limit: size,
          offset,
          customer_company_id: filters.customerCompany || null,
          upload_file__icontains: filters.fileName || null,
          submitted_by_id: filters.createdBy || null,
          ordering: '-created_at',
        },
      })
      return keysToCamelCase(response.data)
    } catch (e: CatchError) {
      if (typeof e === 'string' || e instanceof String) return rejectWithValue(e)
      // If it's a validation error, return the first key
      if (e.response.status === 406) {
        const key = Object.keys(e.response.data)[0]
        return rejectWithValue(e.response.data[key])
      }
      // Otherwise, return the error string
      return rejectWithValue(e.response.data)
    }
  },
)

export const deleteBulkQuotes = createAsyncThunk(
  'quotingTool/deleteBulkQuotes',
  async (payload: { id: number }) => {
    await api.post(`/quotes/api/archive-internal-bulk-quote/${payload.id}/`)
    return payload.id
  },
)

export const deleteQuote = createAsyncThunk(
  'quotingTool/deleteQuote',
  async (id?: string | number) => api.delete(`/quotes/api/manual-quote-rud/${id}/`),
)

const customerQuotesSlice = createSlice({
  name: 'customerQuotes',
  initialState,
  reducers: {
    setSize(state, { payload }) {
      state.size = payload
    },
    setOffset(state, { payload }) {
      state.offset = payload
    },
    setFilters(state, { payload }) {
      state.filters = payload
    },
    setSelectedFields(state, { payload }) {
      state.selectedFields =
        typeof payload === 'string'
          ? state.selectedFields.includes(payload)
            ? state.selectedFields.filter(field => field !== payload)
            : [...state.selectedFields, payload]
          : payload
    },
    resetCustomerQuotes(state) {
      state.quotes = []
      state.count = 0
    },
    setChangedCommodity(state, { payload }) {
      state.quoteDetails.changedCommodity = payload
    },
    setQuoteDetails(state, { payload }) {
      state.quoteDetails = payload
    },
    resetQuoteDetails(state) {
      state.quoteDetails = Object.assign({}, initialState.quoteDetails)
    },
  },
  extraReducers(builder) {
    builder
      .addCase(getQuotes.pending, state => {
        state.loading.quotes = true
      })
      .addCase(getQuotes.fulfilled, (state, action) => {
        const { count, results } = action.payload
        state.quotes = keysToCamelCase(results)
        state.count = count
        state.loading.quotes = false
      })
      .addCase(getQuotes.rejected, state => {
        state.loading.quotes = false
        toast.error('Failed to get quotes')
      })
      .addCase(updateQuoteDetails.pending, state => {
        state.loading.updateQuoteDetails = true
      })
      .addCase(updateQuoteDetails.fulfilled, state => {
        state.loading.updateQuoteDetails = false
        toast.success('Successfully updated quote')
      })
      .addCase(updateQuoteDetails.rejected, state => {
        state.loading.updateQuoteDetails = false
        toast.error('Failed to update quote')
      })
      .addCase(addQuoteNote.pending, state => {
        state.loading.addQuoteNote = true
      })
      .addCase(addQuoteNote.fulfilled, state => {
        state.loading.addQuoteNote = false
        toast.success('Successfully created note')
      })
      .addCase(addQuoteNote.rejected, state => {
        state.loading.addQuoteNote = false
        toast.error('Failed to create note')
      })
      .addCase(deleteQuoteNote.pending, state => {
        state.loading.deleteQuoteNote = true
      })
      .addCase(deleteQuoteNote.fulfilled, state => {
        state.loading.deleteQuoteNote = false
        toast.success('Successfully deleted note')
      })
      .addCase(deleteQuoteNote.rejected, state => {
        state.loading.deleteQuoteNote = false
        toast.error('Failed to delete note')
      })
      .addCase(updateQuoteNote.pending, state => {
        state.loading.updateQuoteNote = true
      })
      .addCase(updateQuoteNote.fulfilled, state => {
        state.loading.updateQuoteNote = false
        toast.success('Successfully updated note')
      })
      .addCase(updateQuoteNote.rejected, state => {
        state.loading.updateQuoteNote = false
        toast.error('Failed to update note')
      })
      .addCase(notifyCustomer.pending, state => {
        state.loading.notifyCustomer = true
      })
      .addCase(notifyCustomer.fulfilled, state => {
        state.loading.notifyCustomer = false
        toast.success('Successfully notified customer')
      })
      .addCase(notifyCustomer.rejected, state => {
        state.loading.notifyCustomer = false
        toast.error('Failed to notify customer')
      })
      .addCase(getPendingODQuotes.pending, state => {
        state.loading.quotes = true
      })
      .addCase(getPendingODQuotes.fulfilled, (state, action) => {
        const { count, results } = action.payload
        state.pendingODQuotes = keysToCamelCase(results)
        state.pendingODQuotesCount = count
        state.loading.quotes = false
      })
      .addCase(getPendingODQuotes.rejected, state => {
        state.loading.quotes = false
        toast.error('Failed to get pending OD quotes')
      })
      .addCase(getQuotesForQuotingTool.pending, state => {
        state.loading.quotes = true
      })
      .addCase(getQuotesForQuotingTool.fulfilled, (state, action) => {
        const { count, results } = action.payload
        state.quotes = keysToCamelCase(results)
        state.count = count
        state.loading.quotes = false
      })
      .addCase(getQuotesForQuotingTool.rejected, state => {
        state.loading.quotes = false
        toast.error('Failed to get quotes')
      })
      .addCase(getQuoteDetails.pending, state => {
        state.loading.quoteDetails = true
      })
      .addCase(getQuoteDetails.fulfilled, (state, { payload }) => {
        state.loading.quoteDetails = false
        const commodity = {
          weight: payload.weight ?? '',
          temperature: payload.temperature ?? '',
          length: payload.length ?? '',
          width: payload.width ?? '',
          height: payload.height ?? '',
          commodity: payload.commodity ?? '',
          description: payload.description ?? '',
        }
        const loadDetails = {
          customerCompany: payload.customerCompany,
          customerCompanyName: payload.customerCompanyName,
          equipmentType: payload.equipmentType,
          isInsuredByCustomer: payload.isInsuredByCustomer,
          insuranceValue: payload.insuranceValue,
          pieces: payload.pieces,
          pickupDate: payload.pickupDate,
        }
        state.quoteDetails = {
          ...payload,
          ...(payload.status.includes('OD') && {
            isODPending: payload.status === 'OD Pending',
            commodityDetails: commodity,
            changedCommodity: commodity,
            loadDetails,
            changedLoadDetails: loadDetails,
            comments: [
              ...payload.comments.map((note: ManualQuoteComment) => ({
                ...note,
                author: note.createdBy,
                timeStamp: note.createdAt,
              })),
              ...(payload.notes
                ? [
                    {
                      text: payload.notes,
                      author: payload.createdBy,
                      timeStamp: payload.createdAt,
                    },
                  ]
                : []),
            ].filter(Boolean),
            changedCustomerPrice: payload.customerPrice,
          }),
        }
      })
      .addCase(getQuoteDetails.rejected, state => {
        state.loading.quoteDetails = false
        toast.error('Failed to get quote details')
      })
      .addCase(addQuote.pending, state => {
        state.loading.addQuote = true
      })
      .addCase(addQuote.fulfilled, (state, { payload }) => {
        state.quoteId = payload.id
        state.loading.addQuote = false
        toast.success(
          'Your quote was successfully copied and saved. Please paste the quote to the email',
        )
      })
      .addCase(addQuote.rejected, (state, { payload }) => {
        state.loading.addQuote = false
        toast.error(getErrorString(payload, 'Failed to save quote'))
      })
      .addCase(updateQuote.pending, state => {
        state.loading.updateQuote = true
      })
      .addCase(updateQuote.fulfilled, state => {
        state.loading.updateQuote = false
        toast.success('Successfully updated quote')
      })
      .addCase(updateQuote.rejected, (state, { payload }) => {
        state.loading.updateQuote = false
        toast.error(getErrorString(payload, 'Failed to update quote'))
      })
      .addCase(exportCustomerQuotesCSV.pending, state => {
        state.loading.exportCSV = true
      })
      .addCase(exportCustomerQuotesCSV.fulfilled, state => {
        state.loading.exportCSV = false
        toast.success('Successfully exported CSV')
      })
      .addCase(exportCustomerQuotesCSV.rejected, (state, { payload }) => {
        state.loading.exportCSV = false
        toast.error(getErrorString(payload, 'Failed to export CSV'))
      })
      .addCase(listBulkQuotes.pending, state => {
        state.loading.listBulkQuotes = true
      })
      .addCase(listBulkQuotes.fulfilled, (state, action) => {
        state.loading.listBulkQuotes = false
        state.bulkQuotesCount = action.payload.count
        state.bulkQuotesList = action.payload.results.map((bulkQuote: any) => ({
          ...bulkQuote,
          errors: Object.keys(bulkQuote.errors).map(row => `Row ${row} - ${bulkQuote.errors[row]}`),
        }))
      })
      .addCase(listBulkQuotes.rejected, state => {
        state.loading.listBulkQuotes = false
        toast.error('Error getting customer quotes')
      })
      .addCase(deleteBulkQuotes.pending, state => {
        state.loading.deleteBulkQuotes = true
      })
      .addCase(deleteBulkQuotes.fulfilled, (state, action) => {
        state.loading.deleteBulkQuotes = false
        state.bulkQuotesList = state.bulkQuotesList.filter(quote => quote.id != action.payload)
        toast.success('Upload deleted')
      })
      .addCase(deleteBulkQuotes.rejected, state => {
        state.loading.deleteBulkQuotes = false
        toast.error('Error deleting upload')
      })
      .addCase(deleteQuote.pending, state => {
        state.loading.deleteQuote = true
      })
      .addCase(deleteQuote.fulfilled, state => {
        state.loading.deleteQuote = false
        toast.success('Successfully archived quote')
      })
      .addCase(deleteQuote.rejected, state => {
        state.loading.deleteQuote = false
        toast.error('Failed to archive quote')
      })
  },
})

export const {
  setSize,
  setOffset,
  setFilters,
  setSelectedFields,
  resetCustomerQuotes,
  setChangedCommodity,
  setQuoteDetails,
  resetQuoteDetails,
} = customerQuotesSlice.actions

export default customerQuotesSlice.reducer
