import { broadcastLogin } from '@common'
import { Button, Form, TextInput } from '@components'
import { useEffect, useState } from 'react'
import tw from 'tailwind-styled-components'

import { useAppSelector, useAppThunkDispatch } from '../../app/hooks'
import loginImg from '../../assets/images/login.png'
import { isLocal, isPreview, isProduction } from '../../common/constants'
import { Logo } from '../../components/Logo'
import { login, resetLogin, sendOTP, verifyOTP } from '../../redux/userSlice'

export const LoginScreen = ({ otpLogin = true }: { otpLogin?: boolean }): JSX.Element => {
  const [isFormValid, setFormValid] = useState(false)
  const [verificationId, setVerificationId] = useState<string | null>(null)
  const [otp, setOTP] = useState(isLocal || isPreview ? '987654' : '')

  const [credentials, setCredentials] = useState({
    username: localStorage.getItem('LATEST_USERNAME') ?? import.meta.env.VITE_USERNAME ?? '',
    password: import.meta.env.VITE_PASSWORD ?? '',
  })

  const dispatch = useAppThunkDispatch()

  const error = useAppSelector(state => state.user.error)
  const loading = useAppSelector(state => state.user.loading)

  useEffect(() => {
    document.title = 'Login'
    dispatch(resetLogin())
  }, [])

  const getOTP = async () => {
    if (!credentials.username.length) return

    const codeId = await dispatch(sendOTP(credentials.username))
      .unwrap()
      .catch(() => null)

    if (codeId) {
      localStorage.setItem('LATEST_USERNAME', credentials.username)
    }

    setVerificationId(codeId)
  }

  const otpSignIn = async () => {
    if (!verificationId || !otp.length) return

    const response = await dispatch(verifyOTP({ verificationId, otp }))
    if (response.meta.requestStatus === 'rejected') return

    broadcastLogin()
  }

  const passSignIn = async () => {
    if (!credentials.username.length || !credentials.password.length) return

    const response = await dispatch(login(credentials))
    if (response.meta.requestStatus === 'rejected') return

    broadcastLogin()
  }

  const authAction = !otpLogin ? passSignIn : verificationId ? otpSignIn : getOTP

  const handleKeyDown = (event: { code: string }) =>
    event.code === 'Enter' && isFormValid && authAction()

  return (
    <Container>
      <Image alt='login' src={loginImg} />
      <FormContainer>
        <Header>
          <Logo />
        </Header>
        <Form autoComplete='off' setFormValid={setFormValid}>
          {otpLogin && verificationId ? (
            <TextInput
              md
              required
              className='mb-4'
              id='otp'
              label='Verification Code'
              value={otp}
              onChange={val => setOTP(val)}
              onKeyDown={handleKeyDown}
            />
          ) : (
            <>
              <TextInput
                md
                required
                className='mb-4'
                id='username'
                label='Email'
                type={isProduction ? 'email' : 'text'}
                value={credentials.username}
                onChange={username => setCredentials({ ...credentials, username })}
                onKeyDown={handleKeyDown}
              />
              {!otpLogin && (
                <TextInput
                  md
                  required
                  id='password'
                  label='Password'
                  type='password'
                  value={credentials.password}
                  onChange={password => setCredentials({ ...credentials, password })}
                  onKeyDown={handleKeyDown}
                />
              )}
            </>
          )}
        </Form>
        <Error>{error}</Error>
        {verificationId && (
          <div
            className='text-center cursor-pointer text-link underline'
            onClick={() => setVerificationId(null)}
          >
            Go back and try again
          </div>
        )}
        <Button
          fullWidth
          lg
          className='mt-4'
          disabled={!isFormValid || loading}
          id='login-button'
          loading={loading}
          postIcon='arrow-right'
          type='warn'
          onClick={authAction}
        >
          {verificationId ? 'Log In' : 'Continue'}
        </Button>
      </FormContainer>
    </Container>
  )
}

const Container = tw.div`
  block
  md:!flex
  h-screen
  fixed
  md:!static
  top-0
`

const Image = tw.img`
  w-full
  h-54
  md:!w-3/5
  md:h-full
  object-cover
`

const FormContainer = tw.div`
  md:self-center
  md:flex
  md:w-2/5
  md:static
  h-full
  flex-col
  justify-center
  mx-4
  md:mx-10
  mt-10
  md:mt-0
`

const Header = tw.div`
  text-2xl
  mb-6
  font-semibold
  text-gray-800
  flex
  justify-center
  items-center
`

const Error = tw.div`
  text-error
  mt-4
  font-semibold
  text-center
`
