import { Button, Modal } from '@components'
import deepEqual from 'fast-deep-equal/es6/react'

import { useAppSelector, useAppThunkDispatch } from '../../../../app/hooks'
import { setCreateLoadModalVisible, setShowUnfinishedDupDialog } from '../../../../redux/loadsSlice'

export const UnfinishedDupDialog = ({ isVisible }: { isVisible: boolean }) => {
  const dispatch = useAppThunkDispatch()
  const { loadDupQuantity, loadDupIds } = useAppSelector(state => state.loads, deepEqual)
  const closeCreateModal = () => dispatch(setCreateLoadModalVisible(false))
  const showDialog = (visible: boolean) => dispatch(setShowUnfinishedDupDialog(visible))
  const close = () => showDialog(false)
  const confirm = () => {
    close()
    closeCreateModal()
  }

  return (
    <Modal
      className='w-[464px] h-[144px] bg-black border-1 border-light-blue rounded-lg'
      isVisible={isVisible}
      setVisible={showDialog}
      showCloseIcon={false}
    >
      <div className='flex flex-col p-6 gap-6 justify-between uppercase text-xs font-semibold'>
        <span>
          You&apos;ve only created {loadDupIds.length} out of {loadDupQuantity} loads. Are you sure
          you want to cancel?
        </span>
        <div className='flex justify-between'>
          <Button
            sm
            className='!w-[200px]'
            innerClassName='!w-[200px]'
            label='No'
            type='secondary'
            onClick={close}
          />
          <Button
            sm
            className='!w-[200px]'
            innerClassName='!w-[200px]'
            label='Yes, Cancel'
            type='danger'
            onClick={confirm}
          />
        </div>
      </div>
    </Modal>
  )
}
