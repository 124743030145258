import { AlphaModal as AlphaModalBase, ConfirmDialog } from '@components'
import deepEqual from 'fast-deep-equal/es6/react'
import { omit, pick } from 'lodash-es'
import { useEffect, useRef, useState } from 'react'
import styled from 'styled-components'
import tw from 'tailwind-styled-components'

import { useAppSelector, useAppThunkDispatch } from '../../../app/hooks'
import { chargeTypes } from '../../../common/constants'
import { Load, Location, QuoteDetails } from '../../../common/types'
import { formatTimeString, keysToCamelCase, keysToSnakeCase } from '../../../common/utils'
import {
  addNewAccessorial,
  createLoad,
  resetPoNumbers,
  setCustomerCompanyName,
  setDupDialogCreated,
  setDuplicateTemplateLoad,
  setLoadDupIds,
  setLoadDupQuantity,
  setShowDeleteDupLoadDialog,
  setShowDuplicationReviewModal,
  setShowDupLoadsDialog,
  setShowUnfinishedDupDialog,
  updateLoad,
} from '../../../redux/loadsSlice'
import { ModalFooter } from '../components/ModalFooter'
import {
  adornLocation,
  ContractLaneBanner,
  DuplicatedLoadsList,
  DuplicateLoadsDialog,
  DuplicationReviewModal,
  ItemsForm,
  ReviewBanner,
  ReviewView,
  RouteForm,
  Steps,
  UnfinishedDupDialog,
  useItemsForm,
  useRouteForm,
  useSteps,
} from './components'
import { QuoteBanner } from './components/QuoteBanner'
import type { CreateLoadModalProps, Item, StopItem } from './types'
import { formatDate } from './utils'

export type LoadTags = { [key: string]: boolean }

// Populate data from an existing quote into the form (setRouteValue refers to values on the first tab of the load creation modal - about the route)
// This is for when a load is being created out of a quote
const populateQuoteInfo = (
  quote: QuoteDetails,
  setRouteValue: ReturnType<typeof useRouteForm>['setRouteValue'],
) => {
  const {
    customerCompany,
    carrierPrice,
    customerPrice,
    weight,
    temperature,
    length,
    width,
    height,
    equipmentType,
    pickupDate = '',
  } = quote
  setRouteValue('customerCompany', customerCompany)
  setRouteValue('carrierMax', carrierPrice)
  setRouteValue('customerPrice', customerPrice)
  setRouteValue('loadWeight', weight)
  setRouteValue('temperature', temperature)
  setRouteValue('loadLength', length)
  setRouteValue('loadWidth', width)
  setRouteValue('loadHeight', height)
  setRouteValue('equipmentType', equipmentType)
  if (quote.status === 'OD Pending') {
    setRouteValue('stops[0].stopDate', new Date(pickupDate))
    setRouteValue('isOversize', true)
  }
}

export const CreateLoadModal = ({
  isVisible,
  setVisible,
  quote,
  onLoadCreated,
}: CreateLoadModalProps) => {
  const [tags, setTags] = useState<LoadTags>({
    isHotLoad: false,
    isFlexibleLoad: false,
    isRolledLoad: false,
  })
  const submitRef = useRef<HTMLInputElement>()
  const dispatch = useAppThunkDispatch()

  const {
    loading: { createLoad: createLoadLoading },
    showDupLoadsDialog,
    showDuplicationReviewModal,
    showUnfinishedDupDialog,
    showDeleteDupLoadDialog,
  } = useAppSelector(state => state.loads, deepEqual)

  const showDupDialog = () => dispatch(setShowDupLoadsDialog(true))

  const {
    handleSubmit,
    errors,
    routeFormIsValid,
    touchedFields,
    routeControl,
    setRouteValue,
    stopFields,
    addPickUp,
    addDropOff,
    getStopDeleter,
  } = useRouteForm()

  const {
    itemsFormIsValid,
    itemsControl,
    setItemsValue,
    itemsStops,
    getItemDeleter,
    getItemAdder,
    allItemsDropped,
  } = useItemsForm()

  const { currentStep, setCurrentStep, stepName } = useSteps()
  const { isReady } = useIsReady({
    currentStep,
    itemsFormIsValid,
    routeFormIsValid,
    stepName,
    allItemsDropped,
  })

  const {
    onCancel,
    onConfirm,
    onDelete,
    onSubmit,
    onReviewModalClose,
    showDuplicated,
    isLastDup,
    cleanupDuplication,
    setDeleteModalVisible,
  } = useModalBtnActions({
    currentStep,
    setCurrentStep,
    setVisible,
    setRouteValue,
    stepName,
    itemsStops,
    submitRef,
    tags,
    quote,
    onLoadCreated,
  })

  const confirmLabel = currentStep !== 2 ? 'Next' : isLastDup ? 'Create and Review' : 'Create load'
  const cancelLabel = currentStep === 0 ? 'Cancel' : 'Back'

  const showFooterSelect = !showDuplicated && !isLastDup

  useEffect(() => {
    dispatch(resetPoNumbers())
  }, [isVisible])

  return (
    <>
      {!showDuplicationReviewModal && (
        <AlphaModal
          $withDuplicated={showDuplicated}
          className={'!max-h-[100vh] !overflow-visible'}
          closeOnOverlayClick={false}
          isVisible={isVisible}
          setVisible={setVisible}
          title='Create New Load'
          footer={
            <ModalFooter
              loading={createLoadLoading}
              ready={isReady}
              {...{
                cancelLabel,
                confirmLabel,
                onCancel,
                onConfirm,
                onDelete: () => setDeleteModalVisible(true),
                showDelete: showDuplicated,
                showSelect: showFooterSelect,
                selectBtnOpts: [
                  {
                    label: 'Create multiple loads',
                    onClick: showDupDialog,
                  },
                ],
              }}
            />
          }
          onClose={cleanupDuplication}
        >
          <div className='flex w-full'>
            {showDuplicated && <DuplicatedLoadsList />}
            <Container>
              <Steps currentStep={currentStep} />
              <Form $step={stepName} onSubmit={handleSubmit(onSubmit)}>
                {quote?.id && <QuoteBanner />}
                {currentStep === 0 && <ContractLaneBanner />}
                {[0, 2].includes(currentStep) && <ReviewBanner />}
                {currentStep === 0 && (
                  <RouteForm
                    control={routeControl}
                    {...{
                      stopFields,
                      addPickUp,
                      addDropOff,
                      getStopDeleter,
                      errors,
                      touchedFields,
                      setRouteValue,
                    }}
                  />
                )}
                {currentStep === 1 && (
                  <ItemsForm
                    control={itemsControl}
                    setValue={setItemsValue}
                    {...{
                      routeControl,
                      getItemDeleter,
                      getItemAdder,
                      setRouteValue,
                    }}
                  />
                )}
                {currentStep === 2 && (
                  <ReviewView {...{ routeControl, itemsControl, tags, setTags }} />
                )}
                <input ref={submitRef as any} className='invisible h-0 w-0 p-0' type='submit' />
              </Form>
            </Container>
          </div>
        </AlphaModal>
      )}
      <DuplicationReviewModal isVisible={showDuplicationReviewModal} onClose={onReviewModalClose} />
      <DuplicateLoadsDialog isVisible={showDupLoadsDialog} />
      <UnfinishedDupDialog isVisible={showUnfinishedDupDialog} />
      <ConfirmDialog
        actionLabel={isLastDup ? 'Delete  & Review' : 'Yes, Delete'}
        isVisible={showDeleteDupLoadDialog}
        setVisible={setDeleteModalVisible}
        message={`Are you sure you want to delete this load?${
          isLastDup ? ' Deleting this load will also close duplication window.' : ''
        }`}
        onConfirm={onDelete}
      />
    </>
  )
}

const useIsReady = ({
  currentStep,
  itemsFormIsValid,
  routeFormIsValid,
  stepName,
  allItemsDropped,
}: {
  currentStep: number
  itemsFormIsValid: boolean
  routeFormIsValid: boolean
  stepName: string
  allItemsDropped: boolean
}) => {
  const [isReady, setIsReady] = useState(false)

  useEffect(() => {
    if (stepName === 'route') {
      setIsReady(routeFormIsValid)
    } else if (stepName === 'items') {
      setIsReady(itemsFormIsValid && allItemsDropped)
    } else {
      setIsReady(routeFormIsValid && itemsFormIsValid)
    }
  }, [currentStep, itemsFormIsValid, routeFormIsValid, allItemsDropped])

  return {
    isReady,
  }
}

const useModalBtnActions = ({
  currentStep,
  setCurrentStep,
  setVisible,
  setRouteValue,
  stepName,
  itemsStops,
  submitRef,
  tags,
  quote,
  onLoadCreated = () => {},
}: Pick<ReturnType<typeof useSteps>, 'currentStep' | 'setCurrentStep'> & {
  setVisible: (isVisible: boolean) => void
  setRouteValue: ReturnType<typeof useRouteForm>['setRouteValue']
  stepName: string
  itemsStops: ReturnType<typeof useItemsForm>['itemsStops']
  submitRef: React.MutableRefObject<HTMLInputElement | undefined>
  tags: LoadTags
  quote?: QuoteDetails
  onLoadCreated?: (load: Load) => void
}) => {
  const dispatch = useAppThunkDispatch()
  const contractLaneId = useAppSelector(state => state.loads.contractLaneId)
  const poNumbers = useAppSelector(state => state.loads.poNumbers)

  const { dupDialogCreated, duplicateTemplateLoad, loadDupIds, loadDupQuantity } = useAppSelector(
    state => state.loads,
    deepEqual,
  )

  const isLastDup = loadDupQuantity && loadDupIds.length === loadDupQuantity - 1

  const [showDuplicated, setShowDuplicated] = useState(false)

  const cleanupDuplication = () => {
    dispatch(setDupDialogCreated(false))
    dispatch(setLoadDupQuantity(0))
    dispatch(setLoadDupIds([]))
    dispatch(setDuplicateTemplateLoad(undefined))
    dispatch(setCustomerCompanyName(undefined))
    dispatch(setShowDuplicationReviewModal(false))
  }

  const onCancel = () => {
    if (currentStep === 0) {
      if (showDuplicated) {
        dispatch(setShowUnfinishedDupDialog(true))
      } else {
        setVisible(false)
        cleanupDuplication()
      }
    } else {
      if (stepName === 'items') {
        setRouteValue('stops', itemsStops)
      }
      setCurrentStep(currentStep - 1)
    }
  }

  const setDeleteModalVisible = (isVisible: boolean) => {
    dispatch(setShowDeleteDupLoadDialog(isVisible))
  }

  const onDelete = () => {
    dispatch(setLoadDupQuantity(loadDupQuantity - 1))
    if (loadDupQuantity - 1 === loadDupIds.length) {
      dispatch(setShowDuplicationReviewModal(true))
    } else {
      goToFirstStep()
    }
  }

  const onConfirm = () => {
    if (currentStep === 2 && submitRef.current) {
      submitRef.current.click()
    } else if (currentStep !== 2) {
      if (stepName === 'items') {
        setRouteValue('stops', itemsStops)
      }
      setCurrentStep(currentStep + 1)
    }
  }

  const onReviewModalClose = () => {
    setVisible(false)
    cleanupDuplication()
  }

  const handleAddAccessorials = async (id: number) => {
    if (!quote) return

    for (let i = 0; i < quote.manualquoteaccessorialsSet.length; i++) {
      const accessorial = quote.manualquoteaccessorialsSet[i]
      await dispatch(
        addNewAccessorial({
          id,
          accessorial: {
            accessorialType: {
              id: accessorial.accessorialType,
              name: accessorial.accessorialTypeName,
              reimbursement: false,
            },
            amount: accessorial.amount,
            chargeTo: { id: 1 },
            chargeType: {
              id: chargeTypes.find(type => type.name === accessorial.chargeType)?.id,
              name: accessorial.chargeType,
            },
            quantity: accessorial.quantity,
            reason: accessorial.reason,
          },
        }),
      )
    }
  }

  const onSubmit = async (data: any) => {
    if (stepName !== 'review') return

    const payload = getSubmitPayload({
      ...data,
      poNumbers,
      tags,
      quoteId: quote?.id,
      contractLaneId,
    })
    const response = await dispatch(createLoad(payload))
    if (response.meta.requestStatus === 'rejected') return
    const load = response.payload

    setVisible(false)
    onLoadCreated(load)

    if (dupDialogCreated) {
      if (!duplicateTemplateLoad) {
        dispatch(setLoadDupIds([load.id]))
        dispatch(setDuplicateTemplateLoad(keysToCamelCase(load)))
      } else if (isLastDup) {
        dispatch(setLoadDupIds([...loadDupIds, load.id]))
        dispatch(setShowDuplicationReviewModal(true))
      } else {
        dispatch(setLoadDupIds([...loadDupIds, load.id]))
      }
    } else {
      window.open(`/loads/${load.id}`, '_blank')
    }

    if (quote) {
      handleAddAccessorials(load.id)
      dispatch(
        updateLoad({ id: load.id, commodity: quote.commodity, description: quote.description }),
      )
    }
  }

  const goToFirstStep = () => {
    setRouteValue('poNumber', '')
    setRouteValue('customerReferenceId', '')
    itemsStops.forEach((_, idx: number) => {
      setRouteValue(`stops.${idx}.stopDate`, undefined)
      setRouteValue(`stops.${idx}.stopEarlyTime`, undefined)
      setRouteValue(`stops.${idx}.stopLateTime`, undefined)
    })

    setCurrentStep(0)
    setShowDuplicated(true)
  }

  useEffect(() => {
    if (currentStep === 2) {
      goToFirstStep()
    }
  }, [loadDupIds.length, duplicateTemplateLoad?.id])

  useEffect(() => {
    if (currentStep === 0) {
      const companyId = duplicateTemplateLoad
        ? (duplicateTemplateLoad?.customerCompany?.id ?? duplicateTemplateLoad?.customerCompany)
        : quote?.customerCompany

      setRouteValue('customerCompany', companyId)
    }
  }, [currentStep])

  useEffect(() => {
    if (dupDialogCreated && currentStep === 2 && submitRef.current) {
      submitRef.current.click()
    }
  }, [dupDialogCreated])

  useEffect(() => {
    if (quote?.id) {
      populateQuoteInfo(quote, setRouteValue)
      dispatch(setCustomerCompanyName(quote.customerCompanyName))
    }
  }, [quote])

  useEffect(() => {
    if (duplicateTemplateLoad) {
      const {
        customerCompany,
        mode,
        orderType,
        equipmentType,
        temperature,
        customerPrice,
        carrierStartBuy,
        carrierMaxBuy,
        shipper,
        consignee,
        commodityDimensions,
      } = duplicateTemplateLoad

      dispatch(setCustomerCompanyName(customerCompany?.name))
      setRouteValue('mode', mode)
      setRouteValue('orderType', orderType)
      setRouteValue('equipmentType', equipmentType)
      setRouteValue('loadWeight', commodityDimensions?.loadWeight)
      setRouteValue('temperature', temperature)
      setRouteValue('loadLength', commodityDimensions?.loadLength)
      setRouteValue('loadWidth', commodityDimensions?.loadWidth)
      setRouteValue('loadHeight', commodityDimensions?.loadHeight)
      setRouteValue('customerPrice', customerPrice)
      setRouteValue('carrierMin', carrierStartBuy)
      setRouteValue('carrierMax', carrierMaxBuy)
      setRouteValue('stops.0.location', adornLocation(shipper as Location))
      setRouteValue('stops.1.location', adornLocation(consignee as Location))
    }
  }, [])

  const modalWidth = showDuplicated ? 1125 : 880

  return {
    onCancel,
    onConfirm,
    onDelete,
    onReviewModalClose,
    onSubmit,
    showDuplicated,
    modalWidth,
    isLastDup,
    cleanupDuplication,
    setDeleteModalVisible,
  }
}

const getSubmitPayload = (data: any) => {
  // Takes an array of dropoff location's PO Numbers, finds their corresponding names from pickup PO Numbers, and returns a string concatenating the names with " & " as the separator
  const getStringPoNumber = (poNumbers = []) => {
    const names = poNumbers
      .map(
        (po: number | string) =>
          data.poNumbers.find((number: { id: number | string }) => po === number.id)?.name,
      )
      .filter(Boolean)

    return names.join(' & ')
  }

  const originDestItems = [data.stops[0], data.stops.slice(-1)[0]].reduce(
    (items: Item[], stop: StopItem, idx: number) => [
      ...items,
      ...stop.items.map((item: Item) =>
        keysToSnakeCase({
          ...omit(item, ['id', 'manifest']),
          name: item.manifest,
          stopType: idx === 0 ? 1 : 2,
          stopOrder: null, // 0?
          loadId: null,
        }),
      ),
    ],
    [],
  )

  const midwayItems = data.stops.slice(1, -1).reduce(
    (items: Item[], stop: StopItem, idx: number) => [
      ...items,
      ...stop.items.map((item: Item) =>
        keysToSnakeCase({
          ...omit(item, ['id', 'manifest']),
          name: item.manifest,
          stopType: 3,
          stopOrder: idx,
          loadId: null,
        }),
      ),
    ],
    [],
  )

  const manifestitemSet = originDestItems.concat(midwayItems)

  const loadstopSet = data.stops.slice(1, -1).map((stop: StopItem, idx: number) =>
    keysToSnakeCase({
      ...omit(stop, ['id', 'items', 'stopDate']),
      stopEarlyTime: formatTimeString(stop.stopEarlyTime),
      stopLateTime: formatTimeString(stop.stopLateTime),
      stopDate: formatDate(stop.stopDate),
      location: stop.location.id,
      order: idx,
      shipmentNumber: stop.shipmentNumber,
      poNumber:
        typeof stop.poNumber === 'object' ? getStringPoNumber(stop.poNumber) : stop.poNumber,
    }),
  )

  return {
    ...pick(data, [
      'equipmentType',
      'mode',
      'customerReferenceId',
      'loadWeight',
      'temperature',
      'loadLength',
      'loadWidth',
      'loadHeight',
      'orderType',
    ]),
    poNumber: data.poNumbers?.map((number: { name: string }) => number.name).join(' | '),
    customerCompany: data.customerCompany,
    pickupDate: formatDate(data.stops[0].stopDate),
    pickupEarlyTime: formatTimeString(data.stops[0].stopEarlyTime),
    pickupLateTime: formatTimeString(data.stops[0].stopLateTime),
    deliveryDate: formatDate(data.stops.slice(-1)[0].stopDate),
    deliveryEarlyTime: formatTimeString(data.stops.slice(-1)[0].stopEarlyTime),
    deliveryLateTime: formatTimeString(data.stops.slice(-1)[0].stopLateTime),
    customerPrice: String(data.customerPrice),
    carrierStartBuy: String(data.carrierMin),
    carrierMaxBuy: String(data.carrierMax),
    shipper: data.stops[0].location.id,
    consignee: data.stops.slice(-1)[0].location.id,
    loadstopSet,
    manifestitemSet,
    isHazmat: data.isHazmat,
    hazmatUhn: data.hazmatUhn,
    isHighValue: data.isHighValue,
    highValueAmount: data.highValueAmount,
    isTeamLoad: data.isTeamLoad,
    isOversize: data.isOversize,
    shipperPickupNotes: data.stops[0].notes,
    consigneeDeliveryNotes: data.stops.slice(-1)[0].notes,
    shipperPickupNumber: data.stops[0].shipmentNumber,
    consigneeDeliveryNumber: data.stops.slice(-1)[0].shipmentNumber,
    shipperPoNumber: data.stops[0].poNumber,
    consigneePoNumber: getStringPoNumber(data.stops.slice(-1)[0].poNumber),
    quoteId: data.quoteId,
    ...data.tags,
    ...(data.contractLaneId && { contractLaneId: data.contractLaneId }),
  }
}

type FormProps = {
  $step: string
}

const Form = tw.form`
  flex
  flex-col
  ${(p: FormProps) => (p.$step === 'items' ? 'h-[53vh]' : 'h-[73vh]')}
  w-[880px]
  p-6
  gap-4
  overflow-y-auto
  transition-all
  duration-500
  w-full
`

type AlphaModalProps = {
  $withDuplicated: boolean
}

const StyledAlphaModal = styled(AlphaModalBase)`
  .z-10 {
    z-index: 0;
  }
`

const AlphaModal = tw(StyledAlphaModal)`
  ${(p: AlphaModalProps) =>
    p.$withDuplicated ? '!max-w-[1125px] w-[1125px] ' : '!max-w-[880px] !w-[880px]'}
`

const Container = tw.div`
  flex
  flex-col
  w-[880px]
`
