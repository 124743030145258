import { useEffect, useMemo, useRef, useState } from 'react'
import { FieldErrorsImpl } from 'react-hook-form'

import { useDeepDependencies } from '../../../../components/src/common/utils'
import { useAppSelector, useAppThunkDispatch } from '../../app/hooks'
import {
  setEditingConfirmationDialogVisible,
  setOnActionButtonConfirm,
} from '../../redux/loadsSlice'
import { CountryCode } from '../types'

// Get the last value of the variable before it was changed. Usage: const prevValue = usePrevious(value)
export const usePrevious = (value: any) => {
  const ref = useRef()
  useEffect(() => {
    ref.current = value
  }, useDeepDependencies(value))
  return ref.current
}

// Hook to fire a callback function on interval with specified delay. Usage: useInterval(callback, interval)
export const useInterval = (callback: () => void, delay: number) => {
  const savedCallback = useRef(callback)
  useEffect(() => {
    savedCallback.current = callback
  }, [callback])
  useEffect(() => {
    if (delay <= 0) {
      return
    }
    const id = setInterval(() => savedCallback.current(), delay)
    return () => clearInterval(id)
  }, [delay])
}

export const useErrorFor =
  (touchedFields: Record<string, any>, errors: FieldErrorsImpl<{ [x: string]: any }>) =>
  (field: string) => {
    if (touchedFields) {
      return touchedFields[field] && (errors[field]?.message as any)
    }
  }

export const useCountryCode = (country: string) => {
  const [countryCode, setCountryCode] = useState<CountryCode>('52')

  const isMexico = country === 'MX' || country == 'MEX'

  useEffect(() => {
    setCountryCode(isMexico ? '52' : '1')
  }, [country])

  return { countryCode }
}

// Adds an event listener for the keydown event provided in the first argument. Usage: useKey('Enter', callback)
export const useKey = (keyCode: string, callback: (value: any) => void) => {
  const cb = useRef(callback)

  useEffect(() => {
    cb.current = callback
  })

  useEffect(() => {
    const handle = (event: {
      code: string
      key: string
      ctrlKey: any
      metaKey: any
      preventDefault: () => void
    }) => {
      if (event.code === keyCode) {
        cb.current(event)
      }
      if (keyCode === 'Save' && event.key === 's' && (event.ctrlKey || event.metaKey)) {
        event.preventDefault()
        cb.current(event)
      }
    }

    document.addEventListener('keydown', handle)
    return () => document.removeEventListener('keydown', handle)
  }, [keyCode])
}

export const useCustomerType = () => {
  const isCreateModalVisible = useAppSelector(state => state.customers.isCreateModalVisible)

  return isCreateModalVisible ? 'onboardingCustomer' : 'customerInfo'
}

// Check if the current user is the main user and switch the load to edit mode; otherwise display the banner.
export const useHandleLoadEditAction = () => {
  const dispatch = useAppThunkDispatch()

  const user = useAppSelector(state => state.user)
  const isMainUser = useAppSelector(state => state.loads.isMainUser)
  const mainUserName = useAppSelector(state => state.loads.mainUserName)

  const isCarrierSalesRep = user.user.isCarrierSalesRep

  return (callback: () => void) => {
    if (isMainUser || !mainUserName || !isCarrierSalesRep) callback()
    else {
      dispatch(setEditingConfirmationDialogVisible(true))
      dispatch(setOnActionButtonConfirm(callback))
    }
  }
}

export const usePostedCount = (boards: { status: string }[]) => {
  const allLoadboards = useAppSelector(state => state.loads.allLoadboards)

  const enabledLoadboards = allLoadboards.filter(board => board.isEnabled)

  const postedCount = useMemo(
    () => boards.reduce((count, board) => (board.status === 'POSTED' ? count + 1 : count), 0),
    [boards],
  )

  return { postedCount, total: enabledLoadboards.length }
}
