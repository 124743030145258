import { Select, SelectDropdownProps } from '@components'

import { equipmentTypeChoices } from '../../common/constants'

export const EquipmentTypeSelect = ({
  value,
  setValue,
  withLabel,
  withPlaceholder,
  required,
  className = '',
  ...props
}: {
  value?: string
  setValue: (value: string) => void
  withLabel?: boolean
  withPlaceholder?: boolean
  required?: boolean
  className?: string
} & Omit<SelectDropdownProps, 'choices' | 'onChange'>) => {
  const equipmentTypes = equipmentTypeChoices.map(type => type.name)
  const text = 'Equipment Type'

  return (
    <Select
      sm
      choices={equipmentTypes}
      className={className}
      required={required}
      value={value || ''}
      onChange={setValue}
      {...(withLabel && { label: text })}
      {...(withPlaceholder && { placeholder: text })}
      {...props}
    />
  )
}
