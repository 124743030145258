import { noop, omit } from 'lodash-es'
import { useEffect } from 'react'

import { useAppSelector, useAppThunkDispatch } from '../../app/hooks'
import { checkIsValidArray, objectHasNonEmptyValues } from '../../common/utils'
import {
  createNewCustomer,
  initialCustomerInfo,
  setCreateModalVisible,
  setCustomerInfo,
} from '../../redux/customersSlice'
import { CreationWizard } from '../CreationWizard'
import {
  Accessorials,
  Accounting,
  Addresses,
  Contacts,
  Documents,
  ExternalPortals,
  GeneralInformation,
  Notes,
  SpecialRequirements,
} from '../CustomerDetails/steps'

export const CreateCustomerModal = ({
  setSelectValue = () => {},
}: {
  // TODO: type this value
  setSelectValue?: (value: any) => void
}) => {
  const dispatch = useAppThunkDispatch()

  const loading = useAppSelector(state => state.customers.loading.createNewCustomer)
  const isVisible = useAppSelector(state => state.customers.isCreateModalVisible)
  const contactsStep = useAppSelector(state => state.customers.onboardingCustomer.contactsStep)
  const generalInformation = useAppSelector(
    state => state.customers.onboardingCustomer.generalInformation,
  )
  const externalPortals = useAppSelector(
    state => state.customers.onboardingCustomer.externalPortals,
  )
  const specialRequirements = useAppSelector(
    state => state.customers.onboardingCustomer.specialRequirements,
  )
  const notes = useAppSelector(state => state.customers.onboardingCustomer.notes)
  const addressesStep = useAppSelector(state => state.customers.onboardingCustomer.addressesStep)
  const accessorialsStep = useAppSelector(
    state => state.customers.onboardingCustomer.accessorialsStep,
  )
  const accountingStep = useAppSelector(state => state.customers.onboardingCustomer.accountingStep)

  const checkIsCompleted = (data: any[]) => objectHasNonEmptyValues(data, ['id', 'isValid'])

  const onSubmit = () => {
    dispatch(createNewCustomer())
      .unwrap()
      .then(({ payload }) => {
        setSelectValue({
          id: payload.id,
          text: payload.name,
          selectedText: payload.name,
        })
      })
      .catch(noop)
  }

  useEffect(() => {
    if (isVisible) dispatch(setCustomerInfo(initialCustomerInfo))
  }, [isVisible])

  const steps = [
    {
      label: 'General Information',
      required: true,
      isValid: generalInformation.isValid,
      component: <GeneralInformation />,
    },
    {
      label: 'Address',
      required: true,
      isValid: checkIsValidArray([addressesStep.primaryAddress, ...addressesStep.facilities]),
      component: <Addresses />,
    },
    {
      label: 'Accounting',
      isValid: true,
      component: <Accounting />,
      isCompleted:
        !!accountingStep.contacts.length ||
        accountingStep.billingAddress.isValid ||
        Object.values(omit(accountingStep.additional, ['isValid'])).some(Boolean),
    },
    {
      label: 'Contacts',
      isValid: checkIsValidArray([...contactsStep.contacts, ...contactsStep.teamEmails]),
      component: <Contacts />,
      isCompleted: !!contactsStep.contacts.length || !!contactsStep.teamEmails.length,
    },
    {
      label: 'Accessorials',
      isValid: checkIsValidArray(accessorialsStep.accessorials),
      component: <Accessorials />,
      isCompleted: !!accessorialsStep.process || checkIsCompleted(accessorialsStep.accessorials),
    },
    {
      label: 'External Portals',
      component: <ExternalPortals />,
      isValid: checkIsValidArray(externalPortals),
      isCompleted: checkIsCompleted(externalPortals),
    },
    {
      label: 'Special Requirements',
      component: <SpecialRequirements />,
      isValid: checkIsValidArray(specialRequirements),
      isCompleted: checkIsCompleted(specialRequirements),
    },
    { label: 'Notes', component: <Notes />, isValid: true, isCompleted: !!notes.length },
    { label: 'Documents', component: <Documents />, isValid: true },
  ]

  return (
    <CreationWizard
      title='Create Customer'
      isVisible={isVisible}
      setVisible={value => dispatch(setCreateModalVisible(value))}
      steps={steps}
      submitButtonText='Create customer'
      loading={loading}
      onSubmit={onSubmit}
    />
  )
}
