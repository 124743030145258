import { TextInput } from '@components'

export const CarrierInfoForm = ({
  carrierName,
  setCarrierName,
  mcNumber,
  setMcNumber,
  dotNumber,
  setDotNumber,
}: {
  mcNumber?: string
  dotNumber?: string
  carrierName?: string
  setCarrierName: (value: string) => void
  setMcNumber: (value: string) => void
  setDotNumber: (value: string) => void
}) => (
  /*
  Form to be used within the CarrierCallDispositionModal to collect carrier info
  */
  <div className='w-[300px] m-auto p-8'>
    <h3 className='font-bold text-base mb-2'>Enter Carrier Info</h3>
    <TextInput
      sm
      required
      className='mb-2'
      value={carrierName}
      label='Carrier Name'
      onChange={setCarrierName}
    />
    <TextInput
      sm
      required={!dotNumber}
      className='mb-2'
      value={mcNumber}
      label='MC Number'
      onChange={setMcNumber}
    />
    <TextInput
      sm
      required={!mcNumber}
      value={dotNumber}
      label='DOT Number'
      onChange={setDotNumber}
    />
  </div>
)
