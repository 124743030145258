import { camelCase } from 'lodash-es'
import { useCallback } from 'react'
import { singletonHook } from 'react-singleton-hook'
import useWebSocket from 'react-use-websocket'

import { isLocal } from '../constants'
import { TMSEvent } from '../types/misc'
import { getClientId, keysToCamelCase, keysToSnakeCase } from '../utils'
import handlers from './handlers'

const getWebSocketUrl = () => {
  const appBaseUrl = isLocal ? location.origin : (import.meta as any).env.VITE_BASE_URL
  const url = new URL(appBaseUrl)
  return `${url.protocol === 'https:' ? 'wss' : 'ws'}://${url.host}/tms/ws/events`
}

export const useSocketImpl = () => {
  const { sendMessage } = useWebSocket(getWebSocketUrl(), {
    share: true,
    onMessage(event) {
      const eventData = keysToCamelCase(JSON.parse(event.data))
      const handlerName = camelCase(eventData.event)
      const handler = handlers[handlerName as keyof typeof handlers]
      if (handler) handler(eventData)
    },
    queryParams: {
      client_id: getClientId(),
    },
    retryOnError: true,
    reconnectAttempts: Infinity,
    shouldReconnect: () => true,
  })

  const sendEvent = useCallback(
    ({ event, ...data }: TMSEvent) => {
      const payload = data ? { event, ...keysToSnakeCase(data) } : { event }
      sendMessage(JSON.stringify(payload))
    },
    [sendMessage],
  )

  return { sendEvent }
}

export const useSocket = singletonHook(() => ({ sendEvent: () => {} }), useSocketImpl)
