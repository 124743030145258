import { carrierEquipmentSupportedEquipmentTypes, CityLocation } from '@common'
import { DateInput, Select, TextInput } from '@components'
import tw from 'tailwind-styled-components'

import { DbLocation } from '../../common/types'
import { displayDate, formatDateForBackend, getNumberMaskOptions } from '../../common/utils'
import { CityAutocomplete } from '../Autocomplete'

export type CapacityLane = {
  srcLocation?: Partial<DbLocation>
  srcRadius?: number
  dstLocation?: Partial<DbLocation>
  dstRadius?: number
  equipmentType?: string
  availabilityStart?: string | null
  availabilityEnd?: string | null
}

const translateLocation = (location?: CityLocation | undefined) =>
  location
    ? {
        isCityGeocode: true,
        name: location?.title,
        currentPlace: location,
      }
    : undefined

const CapacityFormOrigin = ({
  lane,
  setLane,
  isInline,
}: {
  lane: CapacityLane
  setLane: Function
  isInline: boolean
}) => (
  <CityAutocomplete
    required={true}
    label='Origin'
    className={isInline ? '' : 'col-span-4'}
    placeholder='City or Zip Code'
    value={lane.srcLocation?.currentPlace ? lane.srcLocation.currentPlace : null}
    onChange={(origin: any) => setLane({ ...lane, srcLocation: translateLocation(origin) })}
  />
)

const CapacityFormOriginRadius = ({
  lane,
  setLane,
  isInline,
}: {
  lane: CapacityLane
  setLane: Function
  isInline: boolean
}) => (
  <TextInput
    sm
    label='Radius (miles)'
    className={isInline ? '' : 'col-span-2'}
    placeholder='50'
    maskOptions={getNumberMaskOptions()}
    value={lane.srcRadius}
    onChange={(radius: string) => setLane({ ...lane, srcRadius: parseInt(radius) })}
  />
)

const CapacityFormDestination = ({
  lane,
  setLane,
  isInline,
}: {
  lane: CapacityLane
  setLane: Function
  isInline: boolean
}) => (
  <CityAutocomplete
    required={false}
    label='Destination'
    className={isInline ? '' : 'col-span-4'}
    placeholder='Anywhere'
    value={lane.dstLocation?.currentPlace ? lane.dstLocation.currentPlace : null}
    onChange={(destination: any) =>
      setLane({ ...lane, dstLocation: translateLocation(destination) })
    }
  />
)

const CapacityFormDestinationRadius = ({
  lane,
  setLane,
  isInline,
}: {
  lane: CapacityLane
  setLane: Function
  isInline: boolean
}) => (
  <TextInput
    sm
    label='Radius (miles)'
    className={isInline ? '' : 'col-span-2'}
    placeholder='50'
    maskOptions={getNumberMaskOptions()}
    value={lane.dstRadius}
    onChange={(radius: string) => setLane({ ...lane, dstRadius: parseInt(radius) })}
  />
)

const CapacityFormEquipmentType = ({
  lane,
  setLane,
}: {
  lane: CapacityLane
  setLane: Function
}) => (
  <Select
    sm
    label='Equipment Type'
    choices={carrierEquipmentSupportedEquipmentTypes}
    placeholder='Select Equipment'
    value={lane.equipmentType}
    menuPlacement='bottom'
    onChange={(equipmentType: string) => setLane({ ...lane, equipmentType: equipmentType })}
  />
)

const CapacityFormAvailabilityStart = ({
  lane,
  setLane,
}: {
  lane: CapacityLane
  setLane: Function
}) => (
  <DateInput
    showMonthDropdown
    showYearDropdown
    label='Availability Start'
    minDate={new Date()}
    value={lane.availabilityStart ? new Date(displayDate(lane.availabilityStart)) : null}
    maxDate={lane.availabilityEnd ? new Date(displayDate(lane.availabilityEnd)) : null}
    onChange={(availabilityStart: Date | Date[]) => {
      if (Array.isArray(availabilityStart)) {
        availabilityStart = availabilityStart[0]
      }
      setLane({
        ...lane,
        availabilityStart: formatDateForBackend(availabilityStart),
      })
    }}
  />
)

const CapacityFormAvailabilityEnd = ({
  lane,
  setLane,
}: {
  lane: CapacityLane
  setLane: Function
}) => (
  <DateInput
    showMonthDropdown
    showYearDropdown
    label='Availability End'
    value={lane.availabilityEnd ? new Date(displayDate(lane.availabilityEnd)) : null}
    minDate={lane.availabilityStart ? new Date(displayDate(lane.availabilityStart)) : new Date()}
    onChange={(availabilityEnd: Date | Date[]) => {
      if (Array.isArray(availabilityEnd)) {
        availabilityEnd = availabilityEnd[0]
      }
      setLane({
        ...lane,
        availabilityEnd: formatDateForBackend(availabilityEnd),
      })
    }}
  />
)

export const isCapacityLaneValid = (lane: CapacityLane) =>
  Boolean(lane.srcLocation?.name) &&
  ((lane.availabilityStart && lane.availabilityEnd) ||
    (!lane.availabilityStart && !lane.availabilityEnd))

export const CapacityForm = ({
  lane,
  setLane,
  isInline,
}: {
  lane: CapacityLane
  setLane: Function
  isInline: boolean
}) =>
  isInline ? (
    <InlineContainer>
      <CapacityFormOrigin lane={lane} setLane={setLane} isInline={isInline} />
      <CapacityFormOriginRadius lane={lane} setLane={setLane} isInline={isInline} />
      <CapacityFormDestination lane={lane} setLane={setLane} isInline={isInline} />
      <CapacityFormDestinationRadius lane={lane} setLane={setLane} isInline={isInline} />
      <CapacityFormEquipmentType lane={lane} setLane={setLane} />
      <CapacityFormAvailabilityStart lane={lane} setLane={setLane} />
      <CapacityFormAvailabilityEnd lane={lane} setLane={setLane} />
    </InlineContainer>
  ) : (
    <Container>
      <div className='grid grid-cols-6 gap-y-3 gap-x-2'>
        <CapacityFormOrigin lane={lane} setLane={setLane} isInline={isInline} />
        <CapacityFormOriginRadius lane={lane} setLane={setLane} isInline={isInline} />
        <CapacityFormDestination lane={lane} setLane={setLane} isInline={isInline} />
        <CapacityFormDestinationRadius lane={lane} setLane={setLane} isInline={isInline} />
      </div>
      <CapacityFormEquipmentType lane={lane} setLane={setLane} />
      <CapacityFormAvailabilityStart lane={lane} setLane={setLane} />
      <CapacityFormAvailabilityEnd lane={lane} setLane={setLane} />
    </Container>
  )

const Container = tw.div`
    grid
    grid-cols-1
    gap-3
`

const InlineContainer = tw.div`
    col-span-7
    grid
    grid-cols-1
    md:grid-cols-7
    gap-2
`
