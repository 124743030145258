import React from 'react'
import tw from 'tailwind-styled-components'

export const MobileButtonsContainer = ({
  children,
  className,
}: {
  children: React.ReactNode
  className?: string
}) => <Container className={className}>{children}</Container>

const Container = tw.div`
  flex
  gap-4
  md:hidden
  mt-6
`
