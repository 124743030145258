import { DraggableAlphaModal, Form } from '@components'
import { useCallback, useState } from 'react'

import { useAppSelector, useAppThunkDispatch } from '../../app/hooks'
import { autoCompleteCarrierSearch } from '../../redux/autocompleteSlice'
import { addNewCarrierLane } from '../../redux/carriersSlice'
import { Autocomplete } from '../Autocomplete'
import { CapacityForm, CapacityLane, isCapacityLaneValid } from '../Capacity'

const initialCarrier = {
  id: -1,
  text: '',
}

export const CreateCapacityModal = ({
  isVisible,
  setVisible,
}: {
  isVisible: boolean
  setVisible: (value: boolean) => void
}) => {
  const [isFormValid, setFormValid] = useState(false)
  const isAddCarrierLaneButtonLoading = useAppSelector(
    state => state.carriers.loading.addNewCarrierLane,
  )
  const carrierSearchOptions = useAppSelector(state => state.autocomplete.carrierSearchOptions)
  const [carrier, setCarrier] = useState(initialCarrier)
  const [capacityLane, setCapacityLane] = useState<CapacityLane>({})

  const dispatch = useAppThunkDispatch()

  const onClose = useCallback(() => {
    setCapacityLane({})
    setCarrier(initialCarrier)
    setVisible(false)
  }, [])

  const onSubmit = useCallback(async () => {
    if (!carrier.id) return
    const response = await dispatch(
      addNewCarrierLane({ newLane: capacityLane, carrierId: carrier.id }),
    )
    if (response.meta.requestStatus === 'rejected') return
    onClose()
  }, [
    carrier.id,
    capacityLane.srcLocation?.name,
    capacityLane.srcRadius,
    capacityLane.dstLocation?.name,
    capacityLane.dstRadius,
    capacityLane.equipmentType,
    capacityLane.availabilityStart,
    capacityLane.availabilityEnd,
  ])

  const isCarrierLaneValid = isFormValid && carrier.id > 0 && isCapacityLaneValid(capacityLane)

  return (
    <DraggableAlphaModal
      withOverlay
      className='!overflow-visible w-[400px]'
      confirmButtonLabel='Save'
      isConfirmButtonDisabled={!isCarrierLaneValid}
      isConfirmButtonLoading={isAddCarrierLaneButtonLoading}
      isVisible={isVisible}
      setVisible={setVisible}
      title='Add Capacity'
      onCancel={onClose}
      onConfirm={onSubmit}
    >
      <Form className='p-6 space-y-3' setFormValid={setFormValid}>
        <Autocomplete
          required
          className='w-full'
          field='text'
          items={carrierSearchOptions}
          label='Carrier'
          setValue={(value: { id: number; text: string }) => setCarrier(value)}
          value={carrier.text}
          onBlur={(q: string) => !q && setCarrier({ id: -1, text: '' })}
          onValueChange={(q: string) => dispatch(autoCompleteCarrierSearch(q))}
        />

        <CapacityForm lane={capacityLane} setLane={setCapacityLane} isInline={false} />
      </Form>
    </DraggableAlphaModal>
  )
}
