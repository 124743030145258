import { combineReducers, configureStore } from '@reduxjs/toolkit'
import * as Sentry from '@sentry/react'
import {
  FLUSH,
  PAUSE,
  PERSIST,
  persistReducer,
  persistStore,
  PURGE,
  REGISTER,
  REHYDRATE,
} from 'redux-persist'
import storage from 'redux-persist/lib/storage'

import { isLocal } from '../common/constants'
import { RootState } from '../common/types'
import approvedPaymentsSlice from '../redux/approvedPaymentsSlice'
import autocompleteSlice from '../redux/autocompleteSlice'
import automatedBiddingQuotesSlice from '../redux/automatedBiddingQuotesSlice'
import automatedBiddingSlice from '../redux/automatedBiddingSlice'
import capacitySlice from '../redux/capacitySlice'
import carrierFactoringRequestSlice from '../redux/carrierFactoringRequestSlice'
import carrierLoadOffersSlice from '../redux/carrierLoadOffersSlice'
import carrierReservesRecoveriesSlice from '../redux/carrierReservesRecoveriesSlice'
import carriersSlice from '../redux/carriersSlice'
import collectionsSlice from '../redux/collectionsSlice'
import contactsSlice from '../redux/contactsSlice'
import contractLanesSlice from '../redux/contractLanesSlice'
import csvImportSlice from '../redux/csvSlice'
import customerQuotesSlice from '../redux/customerQuotesSlice'
import customersSlice from '../redux/customersSlice'
import dbLocationsSlice from '../redux/dbLocationsSlice'
import detailsLoadsSlice from '../redux/detailsLoadsSlice'
import documentsSlice from '../redux/documentsSlice'
import ediSlice from '../redux/ediSlice'
import factoringCompanySlice from '../redux/factoringCompanySlice'
import factoringCustomerSlice from '../redux/factoringCustomerSlice'
import invoicingSlice from '../redux/invoicingSlice'
import loadboardsSlice from '../redux/loadboardsSlice'
import loadFactoringRequestSlice from '../redux/loadFactoringRequestSlice'
import loadPaymentsSlice from '../redux/loadPaymentsSlice'
import loadsSlice, { loadsPersistConfig } from '../redux/loadsSlice'
import locationsSlice from '../redux/locationsSlice'
import notificationsSlice from '../redux/notificationsSlice'
import quotingToolSlice from '../redux/quotingToolSlice'
import receivablesSlice from '../redux/receivablesSlice'
import trackingSlice from '../redux/trackingSlice'
import userSlice from '../redux/userSlice'
import { rejectedThunkListenerMiddleware } from './middleware'

const sentryReduxEnhancer = Sentry.createReduxEnhancer({})

// base configuration for redux-persist to use for all persistors
const getBasePersistConfig: any = () => ({
  storage,
  version: isLocal ? 1 : Number(__BUILD_VERSION__),
  migrate: (state: RootState, currentVersion: number) => {
    if (state?._persist && state._persist.version !== currentVersion) {
      return Promise.resolve({})
    }
    return Promise.resolve(state)
  },
})

const appReducer = combineReducers({
  locations: locationsSlice,
  automatedBidding: automatedBiddingSlice,
  automatedBiddingQuotes: automatedBiddingQuotesSlice,
  carriers: carriersSlice,
  carrierFactoringRequests: carrierFactoringRequestSlice,
  reservesRecoveries: carrierReservesRecoveriesSlice,
  factoringCustomer: factoringCustomerSlice,
  loadFactoringRequests: loadFactoringRequestSlice,
  customers: customersSlice,
  edi: ediSlice,
  autocomplete: autocompleteSlice,
  contacts: contactsSlice,
  loadboards: loadboardsSlice,
  dbLocations: dbLocationsSlice,
  user: userSlice,
  tracking: trackingSlice,
  loads: persistReducer({ ...getBasePersistConfig(), ...loadsPersistConfig }, loadsSlice),
  detailsLoads: detailsLoadsSlice,
  documents: documentsSlice,
  factoring: factoringCompanySlice,
  csv: csvImportSlice,
  approvedPayments: approvedPaymentsSlice,
  receivables: receivablesSlice,
  carrierLoadOffers: carrierLoadOffersSlice,
  invoicing: invoicingSlice,
  loadPayments: loadPaymentsSlice,
  notifications: notificationsSlice,
  quotingTool: quotingToolSlice,
  customerQuotes: customerQuotesSlice,
  collections: collectionsSlice,
  capacity: capacitySlice,
  contractLane: contractLanesSlice,
})

// it's hard to type this, so we'll just use any
const persistConfig: any = {
  ...getBasePersistConfig(),
  key: 'root',
  whitelist: ['quotingTool'],
}

const rootReducer = (state: any, action: any) => appReducer(state, action)

const persistedReducer = persistReducer(persistConfig, rootReducer)

const configuredStore = {
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware: any) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
        // temp fix for the non-serializable value error
        ignoredPaths: ['loads', 'documents'],
      },
    }).concat(rejectedThunkListenerMiddleware),
  enhancers: (getDefaultEnhancers: any) => getDefaultEnhancers().concat(sentryReduxEnhancer),
}

export const store = configureStore(configuredStore)
export const persistor = persistStore(store)

export const setupStore = (preloadedState?: RootState) =>
  configureStore({
    ...configuredStore,
    preloadedState,
  })
