import { validateEmail, validatePhoneNumber } from '@common'

import {
  Accessorial,
  CarrierContact,
  NewAccessorial,
  NewQuote,
  QuotingLocation,
  ValidatedCarrierContact,
} from '../types'

// When Carrier Line Haul changes, calculate new customer price, carrier price, load margin, and load margin percentage, and return the percentage
export const getNewLoadMargin = (
  lineHaul: number,
  customerBase: number,
  customerTotalAccessorials: number,
  carrierTotalAccessorials: number,
  customerAccessorials: Array<Accessorial>,
  isFactoring: boolean,
) => {
  const customerPrice = customerBase + customerTotalAccessorials
  const filteredCustomerAccessorials = customerAccessorials.filter(
    accessorial => !accessorial.reimbursement,
  )
  let totalCustomerAccessorials = 0
  filteredCustomerAccessorials.forEach(
    accessorial => (totalCustomerAccessorials += accessorial.totalAmount || 0),
  )
  let carrierPrice = lineHaul
  if (!isFactoring) carrierPrice += carrierTotalAccessorials

  const loadMargin = parseFloat((customerPrice - carrierPrice).toFixed(2))
  let loadMarginPercentage = -100
  if (customerPrice)
    loadMarginPercentage = (loadMargin / (customerBase + totalCustomerAccessorials)) * 100

  return parseFloat(loadMarginPercentage.toFixed(2))
}

// Returns the payload for quote creation
export const getQuotePayload = (quote: NewQuote) => {
  const getCity = (location: any) => (location as QuotingLocation)?.city
  const getState = (location: any) => (location as QuotingLocation)?.state

  let customerTotal = Number(quote?.carrierPrice || 0) + Number(quote?.gross || 0)

  quote?.accessorials?.forEach(
    (accessorial: NewAccessorial) =>
      (customerTotal += Number(accessorial.amount || 0) * Number(accessorial.quantity || 0)),
  )

  const stops = quote?.stops?.map(stop => ({
    city: getCity(stop.location),
    state: getState(stop.location),
    type: stop.type,
    lat: stop.location?.latitude,
    lon: stop.location?.longitude,
  }))

  const originLat = (quote?.origin as QuotingLocation)?.latitude
  const originLon = (quote?.origin as QuotingLocation)?.longitude
  const destinationLat = (quote?.destination as QuotingLocation)?.latitude
  const destinationLon = (quote?.destination as QuotingLocation)?.longitude

  return {
    customerCompany: quote?.customer?.id,
    customerCompanyId: quote?.customer?.id,
    equipmentType: quote?.equipmentType,
    originCity: getCity(quote?.origin),
    originState: getState(quote?.origin),
    originLat,
    originLon,
    destinationCity: getCity(quote?.destination),
    destinationState: getState(quote?.destination),
    destinationLat,
    destinationLon,
    notes: quote?.notes,
    carrierPrice: quote?.carrierPrice || '0',
    manualquotestopsSet: stops?.map(stop => ({
      ...stop,
      stopType: stop.type === 1 ? 'Pickup' : 'Drop',
    })),
    customerPrice: customerTotal?.toFixed(2) || 0,
    pieces: quote?.commodity?.quantity,
    description: quote?.commodity?.description,
    commodity: quote?.commodity?.commodity,
    length: quote?.commodity?.length,
    width: quote?.commodity?.width,
    height: quote?.commodity?.height,
    weight: quote?.commodity?.weight,
    temperature: quote?.commodity?.temp,
    manualquoteaccessorialsSet: quote?.accessorials?.map(accessorial => ({
      accessorialType: accessorial.accessorialType.id,
      amount: accessorial.amount,
      quantity: accessorial.quantity,
      reason: accessorial.reason,
      chargeType: accessorial.chargeType.name,
    })),
  }
}

/**
 * Validates a carrier contact object by checking whether name, email, and phone are valid.
 *
 * The `isNameValid`, `isEmailValid`, and `isPhoneValid` boolean properties are added to the object to indicate whether the name, email, and phone are valid.
 *
 * @param contact - The carrier contact object to validate.
 * @returns A validated carrier contact object.
 */
export const validateContact = (contact: CarrierContact) =>
  ({
    ...contact,
    isNameValid: contact.name.length > 0,
    isEmailValid: validateEmail(contact.email, true),
    isPhoneValid: validatePhoneNumber(contact.phone, true),
  }) as ValidatedCarrierContact
