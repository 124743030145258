import { AlphaModal, Button, Link } from '@components'
import { ArrowTopRightOnSquareIcon } from '@heroicons/react/24/outline'
import deepEqual from 'fast-deep-equal/es6/react'
import { useEffect, useState } from 'react'
import tw from 'tailwind-styled-components'

import { useAppSelector, useAppThunkDispatch } from '../../../../app/hooks'
import { setShowDuplicationReviewModal } from '../../../../redux/loadsSlice'

type Item = {
  title: string
  subtitle: string
}

type DuplicationReviewModalProps = {
  isVisible: boolean
  onClose: () => void
}

type ItemProps = {
  title: string
  subtitle: string
  id?: number
}

const Item = ({ title, subtitle, id }: ItemProps) => (
  <ItemWrapper>
    <div className='flex flex-col'>
      <span className='font-semibold'>{title}</span>
      <span className='whitespace-nowrap text-xs'>{subtitle}</span>
    </div>
    <StatusWrapper>
      <Link className='flex gap-2' to={`/loads/${id}`}>
        <ArrowTopRightOnSquareIcon className='w-4 h-4' />
        <span className='w-[74px] pl-3'>Open load</span>
      </Link>
    </StatusWrapper>
  </ItemWrapper>
)

export const DuplicationReviewModal = ({ isVisible, onClose }: DuplicationReviewModalProps) => {
  const [items, setItems] = useState<Item[]>([])
  const { loadDupQuantity, loadDupIds, duplicateTemplateLoad } = useAppSelector(
    state => state.loads,
    deepEqual,
  )
  const dispatch = useAppThunkDispatch()

  useEffect(() => {
    if (loadDupQuantity && duplicateTemplateLoad) {
      const { customerReferenceId: refId } = duplicateTemplateLoad
      const items = Array(loadDupQuantity)
        .fill(0)
        .map((_, idx: number) => ({
          title: `Load ${idx + 1}`,
          subtitle: `Ref: ${refId}`,
          id: loadDupIds[idx],
        }))

      setItems(items)
    }
  }, [loadDupIds.length, loadDupQuantity])

  const closeModal = () => {
    dispatch(setShowDuplicationReviewModal(false))
    onClose()
  }

  return (
    <AlphaModal
      className='min-w-[550px]'
      closeOnOverlayClick={false}
      isVisible={isVisible}
      setVisible={() => {}}
      showCloseIcon={false}
      title='Review'
      footer={
        <div className='flex justify-end w-full'>
          <Button short sm type='warn' onClick={closeModal}>
            Close
          </Button>
        </div>
      }
      onConfirm={() => {}}
    >
      <div className='px-6 pt-6 pb-2'>
        {items.map((item: any) => (
          <Item key={item.title} {...item} />
        ))}
      </div>
    </AlphaModal>
  )
}

const ItemWrapper = tw.div`
  py-0.5
  mb-6
  pl-1.5
  pr-1.5
  h-9
  w-full
  flex
  justify-between
  rounded-lg
`

const StatusWrapper = tw.div`
  flex
  items-center
  text-xs
  px-2
  py-1
  rounded-md
  bg-success
  text-lighter-gray
`
