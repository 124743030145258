import tw from 'tailwind-styled-components'

type DetailsTabsProps = {
  tabs: Array<any>
  activeTab: number
  setActiveTab: (index: number) => void
  className?: string
  tabClassName?: string
}

export const DetailsTabs = ({
  tabs,
  activeTab,
  setActiveTab,
  className,
  tabClassName,
}: DetailsTabsProps) => {
  const tabsList = tabs.map(tab => ({
    label: tab?.label || tab,
    counter: tab.counter,
  }))

  return (
    <Container className={className}>
      {tabsList.map((tab, i) => (
        <Tab
          key={tab.label}
          $selected={activeTab === i}
          className={tabClassName}
          onClick={() => setActiveTab(i)}
        >
          <div className='flex items-center justify-center py-3'>
            {tab.label}
            {tab.counter ? <Counter>{tab.counter}</Counter> : null}
          </div>
          <TabHighlight $selected={activeTab === i} data-testid={`highlight-${i}`} />
        </Tab>
      ))}
    </Container>
  )
}

const Container = tw.div`
  flex
  border-b
  border-light-blue
`

const Counter = tw.span`
  bg-link
  rounded-full
  h-5
  px-1.5
  flex
  items-center
  justify-center
  text-[10px]
  ml-2
  text-white
`

const Tab = tw.div`
  w-1/2
  text-center
  text-sm
  uppercase
  text-link
  font-semibold
  transition-all
  hover:text-dark-blue
  cursor-pointer
  ${(p: { $selected: boolean }) => p.$selected && 'text-dark-blue'}
`

const TabHighlight = tw.div`
  w-full
  h-1
  rounded-t-md
  transition-all
  ${(p: { $selected: boolean }) => p.$selected && 'bg-dark-blue'}
`
