import { TextInput } from '@components'
import { CubeIcon, DocumentIcon, InformationCircleIcon, ScaleIcon } from '@heroicons/react/24/solid'
import { Controller } from 'react-hook-form'

import { getNumberMaskOptions } from '../../../../common/utils/masks'
import { InputContainer } from '../../../InputContainer'
import type { ItemFormProps } from '../types'
import { DeleteBtn } from './DeleteBtn'
import { InputRow } from './InputRow'

export const ItemForm = ({
  control,
  stopIdx,
  item,
  itemTotal,
  itemIdx,
  getItemDeleter,
}: ItemFormProps) => (
  <div className='flex flex-col p-4 rounded-lg bg-lighter-blue'>
    <InputRow>
      <InputContainer className='w-1/3' icon={<DocumentIcon />} iconContainerClassName='mt-5'>
        <Controller
          control={control}
          name={`stops.${stopIdx}.items.${itemIdx}.manifest`}
          render={({ field }) => (
            <TextInput required sm className='w-full' label='Product' {...field} />
          )}
        />
      </InputContainer>
      <InputContainer className='w-1/3' icon={<CubeIcon />} iconContainerClassName='mt-5'>
        <Controller
          control={control}
          name={`stops.${stopIdx}.items.${itemIdx}.quantity`}
          render={({ field }) => (
            <TextInput
              required
              sm
              className='w-full'
              label='Quantity'
              type='number'
              {...field}
              onChange={(value: string) => field.onChange(Number(value))}
            />
          )}
        />
      </InputContainer>
      <InputContainer className='w-1/3'>
        <Controller
          control={control}
          name={`stops.${stopIdx}.items.${itemIdx}.weight`}
          render={({ field }) => (
            <TextInput required sm className='w-full' label='Weight' type='number' {...field} />
          )}
        />
      </InputContainer>
    </InputRow>
    <InputRow>
      <InputContainer className='w-1/3' icon={<ScaleIcon />} iconContainerClassName='mt-5'>
        <Controller
          control={control}
          name={`stops.${stopIdx}.items.${itemIdx}.length`}
          render={({ field }) => (
            <TextInput sm className='w-full' label='Length' type='number' {...field} />
          )}
        />
      </InputContainer>
      <InputContainer className='w-1/3'>
        <Controller
          control={control}
          name={`stops.${stopIdx}.items.${itemIdx}.width`}
          render={({ field }) => (
            <TextInput
              sm
              className='w-full'
              label='Width'
              maskOptions={getNumberMaskOptions()}
              {...field}
            />
          )}
        />
      </InputContainer>
      <InputContainer className='w-1/3'>
        <Controller
          control={control}
          name={`stops.${stopIdx}.items.${itemIdx}.height`}
          render={({ field }) => (
            <TextInput sm className='w-full' label='Height' type='number' {...field} />
          )}
        />
      </InputContainer>
    </InputRow>
    <InputRow>
      <InputContainer
        className='w-full'
        icon={<InformationCircleIcon />}
        iconContainerClassName='mt-5'
      >
        <Controller
          control={control}
          name={`stops.${stopIdx}.items.${itemIdx}.notes`}
          render={({ field }) => (
            <TextInput sm className='w-full' label='Notes (Optional)' {...field} />
          )}
        />
      </InputContainer>
    </InputRow>
    {itemTotal > 1 && (
      <InputRow className='items-center justify-center'>
        <DeleteBtn onClick={getItemDeleter(item)}>Delete Item</DeleteBtn>
      </InputRow>
    )}
  </div>
)
