import { Button } from '@components'

import { useAppSelector } from '../../../../app/hooks'
import { Accessorial, NewAccessorial } from '../../../../common/types'
import { formatBoolean, formatCurrency, useHandleLoadEditAction } from '../../../../common/utils'
import { IconButton } from '../../../Button'
import { DetailItem } from '../../../DetailItem'

export const ViewAccessorialItem = ({
  accessorial,
  onEdit = () => {},
  editable = true,
}: {
  accessorial: Accessorial | NewAccessorial
  onEdit?: () => void
  editable?: boolean
}) => {
  const handleEditClick = useHandleLoadEditAction()

  const load = useAppSelector(state => state.loads.loadDetails)
  const isCarrierAccessorial = accessorial.chargeTo == 2

  return (
    <div>
      <div className='flex justify-between'>
        <div className='w-full'>
          <DetailItem bold label='Type'>
            {accessorial.accessorialTypeText}
          </DetailItem>
          <div className='grid grid-cols-2 gap-x-8'>
            <DetailItem label='Charge'>{accessorial.chargeTypeDisplay}</DetailItem>
            <DetailItem bold label='Total'>
              {formatCurrency(accessorial.totalAmount)}
            </DetailItem>
            <DetailItem label='Quantity'>{accessorial.quantity}</DetailItem>
            <DetailItem label='Reason'>{accessorial.reason}</DetailItem>
            {isCarrierAccessorial && (
              <DetailItem label='Prepaid'>
                <span className='capitalize'>{formatBoolean(!!accessorial.prepaid)}</span>
              </DetailItem>
            )}
            {isCarrierAccessorial && (
              <DetailItem label='Carrier Deduction'>
                <span className='capitalize'>{formatBoolean(!!accessorial.carrierDeduction)}</span>
              </DetailItem>
            )}
            <DetailItem label='Created By'>{accessorial.createdName || '–'}</DetailItem>
          </div>
        </div>
        {editable && (
          <div className='hidden md:block'>
            <IconButton
              dataTestId='edit'
              disabled={load.hold}
              icon='edit'
              type='secondary'
              onClick={() => handleEditClick(onEdit)}
            />
          </div>
        )}
      </div>
      {editable && (
        <Button
          fullWidth
          type='link'
          className='mt-4 block md:hidden'
          disabled={load.hold}
          onClick={() => handleEditClick(onEdit)}
        >
          Edit
        </Button>
      )}
    </div>
  )
}
