import {
  Checkbox,
  ConfirmationDialog,
  DateInput,
  Form,
  Select,
  TextArea,
  TextInput,
  Tooltip,
} from '@components'
import { useCallback, useEffect, useState } from 'react'
import tw from 'tailwind-styled-components'

import { useAppSelector, useAppThunkDispatch } from '../../../app/hooks'
import { orgLevels, sizeClassifications } from '../../../common/constants'
import { CustomerGeneralInformation } from '../../../common/types'
import { displayDate, formatDateForBackend, useCustomerType } from '../../../common/utils'
import { setCustomerInfo } from '../../../redux/customersSlice'
import { AccountManagerSelect, AgentSelect } from '../../Selects'
import { SectionHeader } from '../components'

export const GeneralInformation = () => {
  const [showTooltip, setShowTooltip] = useState(false)
  const [isModalVisible, setModalVisible] = useState(false)

  const type = useCustomerType()

  const dispatch = useAppThunkDispatch()

  const data = useAppSelector(state => state.customers[type].generalInformation)

  useEffect(() => {
    setShowTooltip(!data.needsVerificationForFactoring)
  }, [data.needsVerificationForFactoring])

  const VerificationCheckbox = useCallback(
    () => (
      <Checkbox
        className='mt-8'
        title='Needs Verification for Factoring'
        isChecked={data.needsVerificationForFactoring}
        disabled={!data.needsVerificationForFactoring}
        onChange={() => setModalVisible(true)}
      />
    ),
    [data.needsVerificationForFactoring],
  )

  const setData = (newData: Partial<CustomerGeneralInformation>) =>
    dispatch(
      setCustomerInfo({
        generalInformation: { ...data, ...newData },
      }),
    )

  return (
    <>
      <SectionHeader>general information</SectionHeader>
      <Form setFormValid={isValid => data.isValid !== isValid && setData({ isValid })}>
        <TextInput
          sm
          required
          label='Customer Name'
          value={data.name}
          onChange={name => setData({ name })}
        />
        <Grid>
          <TextInput
            sm
            required
            type='tel'
            label='Phone'
            value={data.phone}
            onChange={phone => setData({ phone })}
          />
          <TextInput
            sm
            required
            type='email'
            label='Email'
            value={data.email}
            onChange={email => setData({ email })}
          />
          <Select
            sm
            required
            choices={orgLevels}
            field='name'
            label='Organization Level'
            value={data.organizationLevel?.value ? data.organizationLevel : null}
            onChange={(organizationLevel: { id: number; name: string; value: string }) =>
              setData({ organizationLevel })
            }
          />
          <AgentSelect
            withLabel
            required
            value={data.accountOwner?.id ? data.accountOwner : null}
            onChange={accountOwner => setData({ accountOwner })}
          />
          <AccountManagerSelect
            withLabel
            value={data.accountManager?.id ? data.accountManager : null}
            onChange={accountManager => setData({ accountManager })}
          />
          <TextInput
            sm
            label='MC Number'
            value={data.mcNumber}
            maxLength={10}
            onChange={mcNumber => setData({ mcNumber })}
          />
          <TextInput
            sm
            label='Freight QuickBooks ID'
            value={data.freightQuickbooksId}
            onChange={freightQuickbooksId => setData({ freightQuickbooksId })}
          />
          <TextInput
            sm
            label='Finance QuickBooks ID'
            value={data.financeQuickbooksId}
            onChange={financeQuickbooksId => setData({ financeQuickbooksId })}
          />
          <TextInput
            sm
            label='Credit Limit'
            value={data.creditLimit}
            onChange={creditLimit => setData({ creditLimit })}
          />
          <DateInput
            showMonthDropdown
            showYearDropdown
            label='Credit Expiration for Factoring'
            value={
              data.factoringCreditExpiration
                ? new Date(displayDate(data.factoringCreditExpiration))
                : null
            }
            onChange={(factoringCreditExpiration: Date | Date[]) => {
              if (Array.isArray(factoringCreditExpiration)) {
                factoringCreditExpiration = factoringCreditExpiration[0]
              }
              setData({
                factoringCreditExpiration: formatDateForBackend(factoringCreditExpiration),
              })
            }}
          />
          <Select
            sm
            choices={sizeClassifications}
            field='name'
            label='Size Classification'
            value={data.sizeClassification?.value ? data.sizeClassification : null}
            onChange={(sizeClassification: { id: number; name: string; value: string }) =>
              setData({ sizeClassification })
            }
          />
          <Checkbox
            className='mt-4'
            isChecked={data.autoPostToLoadboards || false}
            title='Auto-Post Loads to Loadboards'
            onChange={() => setData({ autoPostToLoadboards: !data.autoPostToLoadboards })}
          />
          <Checkbox
            className='mt-4'
            isChecked={data.disableLoadboardPosting || false}
            title='Disable Loadboard Posting'
            onChange={() => setData({ disableLoadboardPosting: !data.disableLoadboardPosting })}
          />
          <Checkbox
            className='mt-4'
            isChecked={data.anonymizeLoadboardPostings || false}
            title='Post Loads as Ghost Loads'
            onChange={() =>
              setData({ anonymizeLoadboardPostings: !data.anonymizeLoadboardPostings })
            }
          />
        </Grid>
        <TextArea sm label='Notes' value={data.notes} onChange={notes => setData({ notes })} />
        {showTooltip ? (
          <Tooltip
            content="This flag cannot be manually updated. It will be automatically set when a
              customer's credit cannot be verified"
          >
            <VerificationCheckbox />
          </Tooltip>
        ) : (
          <VerificationCheckbox />
        )}
      </Form>
      <ConfirmationDialog
        isVisible={isModalVisible}
        setVisible={setModalVisible}
        title="By unchecking this box, you're confirming you manually verified this customer's credit information in Ansonia"
        onConfirm={() => {
          setData({ needsVerificationForFactoring: false })
          setModalVisible(false)
        }}
      />
    </>
  )
}

const Grid = tw.div`
  grid
  grid-cols-2
  gap-x-4
  gap-y-3
  my-3
`
