import { Select, TextInput } from '@components'
import { CubeIcon, DocumentIcon, InformationCircleIcon } from '@heroicons/react/24/solid'
import { find, pick } from 'lodash-es'
import { useEffect, useState } from 'react'
import { Controller, useWatch } from 'react-hook-form'

import { InputContainer } from '../../../InputContainer'
import type { Item, ItemDropOffFormProps, StopItem } from '../types'
import { DeleteBtn } from './DeleteBtn'
import { InputRow } from './InputRow'
import { getItemCounts } from './ItemsForm'

export const ItemDropOffForm = ({
  control,
  stopIdx,
  item,
  itemCount,
  itemIdx,
  getItemDeleter,
  setItemsValue,
}: ItemDropOffFormProps) => {
  const itemsStops = useWatch({
    control,
    name: 'stops',
  })

  const editingItem: Item = itemsStops[stopIdx].items[itemIdx]

  const pickUpItems: Item[] = itemsStops
    .reduce(
      (pickUpItems: Item[], stop: StopItem) =>
        stop.stopType === 1 ? pickUpItems.concat(stop.items) : pickUpItems,
      [],
    )
    .filter(({ manifest }: Item) => manifest)

  const { setItemMappingKey, itemMappingKey } = useItemMapper({
    setItemsValue,
    editingItem,
    pickUpItems,
    stopIdx,
    itemIdx,
  })

  const getQuantityUpdater = useQuantityUpdater({ itemsStops, itemMappingKey })

  return (
    <div className='flex flex-col p-4 rounded-lg bg-lighter-blue'>
      <InputRow>
        <InputContainer className='w-1/2' icon={<DocumentIcon />} iconContainerClassName='mt-5'>
          <Controller
            control={control}
            name={`stops.${stopIdx}.items.${itemIdx}.manifest`}
            render={({ field }) => (
              <Select
                required
                sm
                choices={pickUpItems}
                className='w-full'
                field='manifest'
                label='Select Product'
                {...field}
                valueField='manifest'
                onChange={(value: Item) => {
                  if (value && value['id']) {
                    setItemMappingKey(value['itemMappingKey'])
                  } else {
                    setItemMappingKey('')
                  }
                }}
              />
            )}
          />
        </InputContainer>
        <InputContainer className='w-1/2' icon={<CubeIcon />} iconContainerClassName='mt-5'>
          <Controller
            control={control}
            name={`stops.${stopIdx}.items.${itemIdx}.quantity`}
            render={({ field }) => (
              <TextInput
                required
                sm
                className='w-full'
                label='Quantity'
                type='number'
                {...field}
                onChange={getQuantityUpdater(field)}
              />
            )}
          />
        </InputContainer>
      </InputRow>
      <InputRow>
        <InputContainer
          className='w-full'
          icon={<InformationCircleIcon />}
          iconContainerClassName='mt-5'
        >
          <Controller
            control={control}
            name={`stops.${stopIdx}.items.${itemIdx}.notes`}
            render={({ field }) => (
              <TextInput sm className='w-full' label='Notes (Optional)' {...field} />
            )}
          />
        </InputContainer>
      </InputRow>
      {itemCount > 1 && (
        <InputRow className='items-center justify-center'>
          <DeleteBtn onClick={getItemDeleter(item, true)}>Delete Item</DeleteBtn>
        </InputRow>
      )}
    </div>
  )
}

const useItemMapper = ({
  setItemsValue,
  editingItem,
  pickUpItems,
  stopIdx,
  itemIdx,
}: Pick<ItemDropOffFormProps, 'setItemsValue' | 'stopIdx' | 'itemIdx'> & {
  editingItem: Item
  pickUpItems: Item[]
}) => {
  const [itemMappingKey, setItemMappingKey] = useState(editingItem.itemMappingKey ?? '')

  useEffect(() => {
    if (itemMappingKey) {
      const pickUpItem = find(pickUpItems, { itemMappingKey })
      const itemValue = {
        ...editingItem,
        ...pick(pickUpItem, ['manifest', 'weight', 'length', 'width', 'height', 'itemMappingKey']),
      }

      setItemsValue(`stops.${stopIdx}.items.${itemIdx}`, itemValue)
    } else {
      const itemValue = {
        ...editingItem,
        manifest: '',
        weight: '',
        length: '',
        width: '',
        height: '',
        itemMappingKey: '',
        quantity: 0,
      }
      setItemsValue(`stops.${stopIdx}.items.${itemIdx}`, itemValue)
    }
  }, [itemMappingKey])

  return {
    setItemMappingKey,
    itemMappingKey,
  }
}

const useQuantityUpdater =
  ({ itemsStops, itemMappingKey }: { itemsStops: StopItem[]; itemMappingKey: string }) =>
  (field: any) =>
  (value: string) => {
    const updateValue = value ? Number(value) : 0
    const { pickUpItemCounts, quantityRemainingCounts } = getItemCounts({ itemsStops })
    const updateType: 'inc' | 'dec' | 'set' =
      updateValue > field?.value ? 'inc' : updateValue < field?.value ? 'dec' : 'set'
    const updateIsLessThanOrEqualToTotal = updateValue <= pickUpItemCounts[itemMappingKey]
    const updateRespectsRemaining =
      updateType === 'inc'
        ? typeof updateValue === 'number' &&
          updateValue - field.value <= quantityRemainingCounts[itemMappingKey]
        : true
    const validValue =
      pickUpItemCounts[itemMappingKey] && updateIsLessThanOrEqualToTotal && updateRespectsRemaining

    if (!value || validValue) {
      field.onChange(updateValue)
    }
  }
