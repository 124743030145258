import { debounce } from 'lodash-es'
import { useCallback, useState } from 'react'

import { useAppThunkDispatch } from '../../app/hooks'
import { LatLng } from '../../common/types'
import { geocode } from '../../redux/locationsSlice'
import { Autocomplete } from './Autocomplete'

type Address = {
  address: {
    city: string
    countryCode: string
    countryName: string
    county: string
    label: string
    postalCode: string
    state: string
    stateCode: string
  }
  position: LatLng
}

type AddressAutocompleteProps = {
  value: string
  setItem: (item: any) => void
  item: object
  field: string
  label?: string
  placeholder?: string
  style?: object
  className?: string
  citiesOnly?: boolean
  selectOnHighlight?: boolean
  required?: boolean
}

const AddressAutocomplete = ({
  value,
  setItem,
  item,
  field = '',
  label,
  placeholder,
  style,
  className,
  citiesOnly = false,
  selectOnHighlight = false,
  required = false,
}: AddressAutocompleteProps) => {
  const dispatch = useAppThunkDispatch()
  const [addresses, setAddresses] = useState([])
  const setLocation = (location: Address) => {
    const { position } = location
    setItem({
      ...item,
      currentPlace: location,
      [field]: {
        ...location.address,
        state: location.address.stateCode,
        position,
      },
    })
  }

  const geocodeDebounced = useCallback(
    debounce(async (address: string) => {
      const locations = await dispatch(geocode({ address, citiesOnly }))
        .unwrap()
        .catch(() => {})
      if (locations) setAddresses(locations)
    }, 250),
    [citiesOnly],
  )

  return (
    <Autocomplete
      className={className}
      field='title'
      items={addresses}
      label={label}
      placeholder={placeholder}
      required={required}
      selectOnHighlight={selectOnHighlight}
      setValue={setLocation}
      style={style}
      value={value}
      renderInputComponent={inputProps => (
        <input {...{ 'data-testid': 'address-autocomplete' }} {...inputProps} />
      )}
      onValueChange={geocodeDebounced}
    />
  )
}

export { AddressAutocomplete }
