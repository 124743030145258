import { isEmpty, omit, some } from 'lodash-es'

import { CustomerInfo, IdsToDelete } from '../../redux/customersSlice'
import {
  Accessorial,
  CurrentCustomerDetails,
  CustomerAddress,
  CustomerContact,
  CustomerDetailsLocation,
  CustomerDocument,
  HereCurrentPlace,
} from '../types'
import { getObjectDifferences, isNumber, keysToSnakeCase } from './textHelpers'

type LocationComparisonObject = {
  name?: string
  addressDisplay?: string
  notes?: string
}

const formatCustomerLocation = (location: CustomerAddress, withId = false) => ({
  ...(withId ? location : omit(keysToSnakeCase(location), ['id'])),
  location: {
    name: location.name || location.addressDisplay,
    address: location.address,
    city: location.city,
    state_province_region: location.state || location.stateProvinceRegion || location.caProvince,
    contact_name: '',
    contact_phone: '',
    hours: '',
    notes: '',
    current_place: location.currentPlace,
  },
})

const hasLocationChanged = (
  location: LocationComparisonObject,
  backupLocation: LocationComparisonObject,
): boolean => {
  const fieldsToCompare: (keyof LocationComparisonObject)[] = ['name', 'notes', 'addressDisplay']

  return fieldsToCompare.some(field => location[field] !== backupLocation[field])
}

const formatCustomerLocationTextFields = (location: CustomerAddress, withNotes = false) => ({
  location_id: location.locationId,
  ...(withNotes && { notes: location.notes }),
  ...(!withNotes && {
    location: {
      name: location.name,
      address: location.address,
      city: location.city,
      state_province_region: location.state || location.caProvince,
    },
  }),
})

const processCustomerLocation = (location: CustomerAddress, backupLocation: CustomerAddress) =>
  location.currentPlace !== null
    ? formatCustomerLocation(location)
    : formatCustomerLocationTextFields(location, location.notes !== backupLocation.notes)

const formatNonRequiredLists = (list: any[]) =>
  list
    .filter(
      item => some(omit(item, ['id', 'isValid']), Boolean), // Check if some properties have truthy values
    )
    .map(item => omit(item, ['id']))

const handleItemId = (item: any) => ({
  ...omit(item, ['id']),
  ...(isNumber(item.id) && { id: item.id }),
})

const composeCustomerUpdatePayload = (customerInfo: CustomerInfo) => {
  const {
    generalInformation: {
      name,
      accountOwner,
      accountManager,
      phone,
      email,
      organizationLevel,
      mcNumber,
      creditLimit,
      factoringCreditExpiration,
      freightQuickbooksId,
      financeQuickbooksId,
      needsVerificationForFactoring,
      sizeClassification,
      anonymizeLoadboardPostings,
      disableLoadboardPosting,
      autoPostToLoadboards,
    },
    specialRequirements,
    externalPortals,
    accessorialsStep: { process: accessorialProcess, accessorials },
    contactsStep: { teamEmails },
    accountingStep: {
      additional: { payTerms, invoicingMethod, sendTo, refNumber, accountingContact },
    },
  } = customerInfo

  return {
    ...keysToSnakeCase({
      name,
      mcNumber: mcNumber || null,
      accountOwner: accountOwner?.id,
      accountManager: accountManager?.id || null,
      accountingContact: accountingContact?.id || null,
      phone,
      email,
      creditLimit: creditLimit || null,
      factoringCreditExpiration: factoringCreditExpiration || null,
      freightQuickbooksId: freightQuickbooksId || null,
      financeQuickbooksId: financeQuickbooksId || null,
      needsVerificationForFactoring,
      organizationLevel: organizationLevel?.value,
      sizeClassification: sizeClassification?.value || null,
      general_notes: customerInfo.generalInformation.notes,
      specialRequirements: specialRequirements.map(requirement => handleItemId(requirement)),
      externalPortals: externalPortals.map(portal => handleItemId(portal)),
      teamEmails: teamEmails.map(teamEmail => handleItemId(teamEmail)),
      accessorialProcess,
      accessorials: accessorials.map(accessorial => ({
        accessorialType: accessorial.accessorialType.id,
        chargeType: 1,
        amount: accessorial.amount,
        quantity: accessorial.quantity,
        reason: accessorial.reason,
        ...(isNumber(accessorial.id) && { id: accessorial.id }),
      })),
      accountingInformation: {
        payTerms,
        preferredInvoicingMethod: invoicingMethod,
        invoicingReferenceNumbers: refNumber,
        sendInvoicesTo: sendTo,
        notes: customerInfo.accountingStep.additional.notes,
      },
      contacts: [
        ...customerInfo.contactsStep.contacts,
        ...customerInfo.accountingStep.contacts,
      ].map(contact => ({
        ...handleItemId(contact),
        isPrimary: contact.primary,
        isAccounting: contact.accounting,
        isInvoice: contact.invoice,
        role: 'OPERATIONAL',
        portalAccess: contact.shipperPortalEnabled,
        receiveNoa: contact.receiveNOA,
      })),
      anonymizeLoadboardPostings,
      disableLoadboardPosting,
      autoPostToLoadboards,
    }),
  }
}

export const getCustomerUpdatePayload = (
  customerInfo: CustomerInfo,
  backupCustomerInfo: CustomerInfo,
  idsToDelete: IdsToDelete,
) => {
  const { teamEmails, locations, accessorials, externalPortals, specialRequirements, contacts } =
    idsToDelete

  const customerLocations = [
    hasLocationChanged(
      customerInfo.addressesStep.primaryAddress,
      backupCustomerInfo.addressesStep.primaryAddress,
    ) && {
      ...processCustomerLocation(
        customerInfo.addressesStep.primaryAddress,
        backupCustomerInfo.addressesStep.primaryAddress,
      ),
      is_primary_address: true,
    },
    hasLocationChanged(
      customerInfo.accountingStep.billingAddress,
      backupCustomerInfo.accountingStep.billingAddress,
    ) && {
      ...processCustomerLocation(
        customerInfo.accountingStep.billingAddress,
        backupCustomerInfo.addressesStep.primaryAddress,
      ),
      is_billing: true,
    },
    ...customerInfo.addressesStep.facilities.map((facility, i) => ({
      ...(hasLocationChanged(
        customerInfo.addressesStep.facilities.find(fac => fac.id === facility.id) || {},
        backupCustomerInfo.addressesStep.facilities.find(fac => fac.id === facility.id) || {},
      ) && {
        ...processCustomerLocation(facility, backupCustomerInfo.addressesStep.primaryAddress),
        is_primary: !i,
        is_facility: true,
      }),
    })),
  ].filter(location => !isEmpty(location))

  return {
    ...getObjectDifferences(
      composeCustomerUpdatePayload(customerInfo),
      composeCustomerUpdatePayload(backupCustomerInfo),
    ),
    ...(customerLocations.length && { customer_locations: customerLocations }),
    ...keysToSnakeCase({
      ...(Object.values(idsToDelete).some(value => !!value.length) && {
        deleteItem: {
          ...(teamEmails.length && { teamEmailIds: teamEmails }),
          ...(locations.length && { customerLocationIds: locations }),
          ...(accessorials.length && { accessorialIds: accessorials }),
          ...(externalPortals.length && { externalPortalIds: externalPortals }),
          ...(specialRequirements.length && { specialRequirementIds: specialRequirements }),
          ...(contacts.length && { contactIds: contacts }),
        },
      }),
    }),
  }
}

export const getCustomerCreationPayload = (onboardingCustomer: CustomerInfo) => {
  const {
    accessorialsStep,
    accountingStep,
    addressesStep,
    contactsStep,
    documents,
    externalPortals,
    generalInformation,
    notes,
    specialRequirements,
  } = onboardingCustomer

  return {
    customer_locations: [
      {
        ...formatCustomerLocation(addressesStep.primaryAddress),
        is_primary_address: true,
        is_primary: false,
        is_facility: false,
        is_billing: false,
      },
      ...addressesStep.facilities.map((facility, i) => ({
        ...formatCustomerLocation(facility),
        is_primary_address: false,
        is_primary: !i,
        is_facility: true,
        is_billing: false,
      })),
      {
        ...formatCustomerLocation(accountingStep.billingAddress),
        is_primary_address: false,
        is_primary: false,
        is_facility: false,
        is_billing: true,
      },
    ].filter(
      (location: { location: { current_place?: HereCurrentPlace | null } }) =>
        location.location.current_place,
    ),
    ...keysToSnakeCase({
      generalInformation: {
        name: generalInformation.name,
        phone: generalInformation.phone,
        email: generalInformation.email,
        organizationLevel: generalInformation.organizationLevel?.value,
        sizeClassification: generalInformation.sizeClassification?.value,
        accountManager: generalInformation.accountManager?.id || null,
        accountOwner: generalInformation.accountOwner?.id,
        notes: generalInformation.notes,
        accessorialProcess: accessorialsStep.process,
        mcNumber: generalInformation.mcNumber || null,
        creditLimit: generalInformation.creditLimit || null,
        freightQuickbooksId: generalInformation.freightQuickbooksId || null,
        financeQuickbooksId: generalInformation.financeQuickbooksId || null,
        needsVerificationForFactoring: generalInformation.needsVerificationForFactoring,
        anonymizeLoadboardPostings: generalInformation.anonymizeLoadboardPostings,
        autoPostToLoadboards: generalInformation.autoPostToLoadboards,
        disableLoadboardPosting: generalInformation.disableLoadboardPosting,
      },
      documents: documents.map((doc: CustomerDocument) => ({
        file: doc.file,
        fileName: `${doc.name}${doc.extension}`,
      })),
      teamEmails: formatNonRequiredLists(contactsStep.teamEmails),
      contacts: [...contactsStep.contacts, ...accountingStep.contacts]
        .filter(contact => contact.name && contact.phone && contact.email)
        .map((contact, i) => ({
          ...omit(contact, ['id']),
          isAccounting: contact.accounting,
          isInvoice: contact.invoice,
          isPrimary: contact.primary || !!i,
          receiveNoa: contact.receiveNOA,
          portalAccess: contact.shipperPortalEnabled,
          role: 'OPERATIONAL',
        })),
      accountingInformation: {
        payTerms: accountingStep.additional.payTerms,
        preferredInvoicingMethod: accountingStep.additional.invoicingMethod,
        invoicingReferenceNumbers: accountingStep.additional.refNumber,
        sendInvoicesTo: accountingStep.additional.sendTo,
        notes: accountingStep.additional.notes,
      },
      accessorials: accessorialsStep.accessorials.map(accessorial => ({
        ...omit(accessorial, ['id']),
        accessorialType: accessorial.accessorialType.id,
        chargeType: 1,
      })),
      externalPortals: formatNonRequiredLists(externalPortals),
      specialRequirements: formatNonRequiredLists(specialRequirements),
      notes: notes.map(note => omit(note, ['id'])),
    }),
  }
}

export const normalizeCustomerDetails = (state: any, currentCustomer: CurrentCustomerDetails) => {
  const normalizeCustomerDetailsAddress = (location?: CustomerDetailsLocation) => ({
    ...location,
    address: location?.address,
    city: location?.city,
    caProvince: location?.stateProvinceRegion,
    state: location?.stateProvinceRegion,
    caPostalCode: location?.postalCode,
    usZipcode: location?.postalCode,
    postalCode: location?.postalCode,
    country: location?.country,
    addressDisplay: location?.addressDisplay,
    currentPlace: null,
  })

  const primaryAddress = currentCustomer.customerLocations.find(
    (loc: CustomerDetailsLocation) => loc.isPrimaryAddress,
  )
  return {
    ...state.customerInfo,
    generalInformation: {
      ...state.customerInfo.generalInformation,
      id: currentCustomer.id,
      name: currentCustomer.name,
      phone: currentCustomer.phone,
      email: currentCustomer.email,
      organizationLevel: {
        name: currentCustomer.organizationLevelDisplay,
        value: currentCustomer.organizationLevel,
      },
      sizeClassification: {
        name: currentCustomer.sizeClassificationDisplay,
        value: currentCustomer.sizeClassification,
      },
      accountOwner: {
        id: currentCustomer.accountOwner,
        text: currentCustomer.accountOwnerDisplay,
      },
      accountManager: {
        id: currentCustomer.accountManager,
        text: currentCustomer.accountManagerDisplay,
      },
      notes: currentCustomer.generalNotes,
      mcNumber: currentCustomer.mcNumber,
      creditLimit: currentCustomer.creditLimit,
      utilizedCredit: currentCustomer.utilizedCredit,
      factoringCreditExpiration: currentCustomer.factoringCreditExpiration,
      freightQuickbooksId: currentCustomer.freightQuickbooksId,
      financeQuickbooksId: currentCustomer.financeQuickbooksId,
      needsVerificationForFactoring: currentCustomer.needsVerificationForFactoring,
      anonymizeLoadboardPostings: currentCustomer.anonymizeLoadboardPostings,
      autoPostToLoadboards: currentCustomer.autoPostToLoadboards,
      disableLoadboardPosting: currentCustomer.disableLoadboardPosting,
    },
    contactsStep: {
      ...state.customerInfo.contactsStep,
      teamEmails: currentCustomer.teamEmails,
    },
    accessorialsStep: {
      ...state.customerInfo.accessorialsStep,
      process: currentCustomer.accessorialProcess,
      accessorials: currentCustomer.accessorials.map((accessorial: Accessorial) => ({
        ...accessorial,
        accessorialType: {
          id: accessorial.accessorialType,
          name: accessorial.accessorialTypeText,
        },
        chargeTo: { id: 1, name: 'Customer' },
        chargeType: { id: accessorial.chargeType, name: accessorial.chargeTypeDisplay },
        accessorialTypeText: accessorial.accessorialTypeText,
      })),
    },
    accountingStep: {
      ...state.customerInfo.accountingStep,
      additional: {
        payTerms: currentCustomer.accountingInformation?.payTerms || currentCustomer.payTerms,
        invoicingMethod: currentCustomer.accountingInformation?.preferredInvoicingMethod,
        refNumber: currentCustomer.accountingInformation?.invoicingReferenceNumbers,
        sendTo: currentCustomer.accountingInformation?.sendInvoicesTo,
        notes: currentCustomer.accountingInformation?.notes,
        accountingContact: {
          id: currentCustomer.accountingContact,
          text: currentCustomer.accountingContactDisplay,
        },
      },
      billingAddress: normalizeCustomerDetailsAddress(
        currentCustomer.customerLocations.find((loc: CustomerDetailsLocation) => loc.isBilling),
      ),
    },
    externalPortals: currentCustomer.externalPortals,
    specialRequirements: currentCustomer.specialRequirements,
    addressesStep: {
      ...state.customerInfo.addressesStep,
      primaryAddress: normalizeCustomerDetailsAddress(
        primaryAddress?.id
          ? primaryAddress
          : {
              address: currentCustomer.address,
              city: currentCustomer.city,
              stateProvinceRegion: currentCustomer.state || currentCustomer.caProvince,
              postalCode: currentCustomer.usZipcode || currentCustomer.caPostalCode,
              country: currentCustomer.country,
              addressDisplay: `${currentCustomer.address}, ${currentCustomer.city}, ${
                currentCustomer.state || currentCustomer.caProvince
              }, ${currentCustomer.usZipcode || currentCustomer.caPostalCode}, ${
                currentCustomer.country
              }`,
            },
      ),
      facilities: currentCustomer.customerLocations
        .filter((loc: CustomerDetailsLocation) => loc.isFacility)
        .map((facility: CustomerDetailsLocation) => normalizeCustomerDetailsAddress(facility))
        .sort((a, b) => (b.isPrimary ? 1 : -1)),
    },
  }
}

export const normalizeCustomerContact = (contact: CustomerContact) => ({
  ...contact,
  shipperPortalEnabled: contact.portalAccess,
  primary: contact.isPrimary,
  accounting: contact.isAccounting,
  invoice: contact.isInvoice,
  receiveNOA: contact.receiveNoa,
  notes: contact.notes,
})

export const filterAndSortContacts = (contacts: CustomerContact[], isAccounting = false) =>
  contacts
    .filter(contact => isAccounting === contact.isInvoice)
    .map(normalizeCustomerContact)
    .sort((a, b) => (b.isPrimary ? 1 : -1))

// Returns the appropriate label for a contact based on its index and whether it pertains to accounting
export const getContactHeader = (index: number, isAccounting = false, isEditing = false) => {
  const base = isAccounting ? 'Billing' : 'Primary'
  const suffix = !index ? '' : ` ${index + 1}`
  const requiredText = isAccounting && !index && isEditing ? ' (required)' : ''

  return `${base} Contact${suffix}${requiredText}`
}
