import { store } from '../../app/store'
import { setPendingCarrierFactoringRequests } from '../../redux/carrierFactoringRequestSlice'
import { handleDeliveredLoadOfferSMS } from '../../redux/carrierLoadOffersSlice'
import { setPendingLoadFactoringRequests } from '../../redux/loadFactoringRequestSlice'
import { setLoadsBeingViewed, updateLoadViewers } from '../../redux/loadsSlice'
import { addNewNotif } from '../../redux/notificationsSlice'
import { LoadsBeingViewed, LoadViewersUpdate, TMSEvent } from '../types'

export default {
  newNotification({ notification }: TMSEvent) {
    store.dispatch(addNewNotif(notification))
  },
  pendingLfrCount({ count }: TMSEvent) {
    store.dispatch(setPendingLoadFactoringRequests(count))
  },
  pendingCfrCount({ count }: TMSEvent) {
    store.dispatch(setPendingCarrierFactoringRequests(count))
  },
  loadOfferSmsDelivered(event: { loadId: number; carrierCompanyId: number }) {
    store.dispatch(handleDeliveredLoadOfferSMS(event))
  },
  allLoadsViewers({ loadViewers }: { loadViewers: LoadsBeingViewed }) {
    store.dispatch(setLoadsBeingViewed(loadViewers))
  },
  loadViewersUpdated({ viewers, loadId }: LoadViewersUpdate) {
    store.dispatch(updateLoadViewers({ viewers, loadId }))
  },
}
