import tw from 'tailwind-styled-components'

import { getInitialsFromName } from '../../common/utils'
import { AvatarDropdownIcon } from '../Icons'

type AvatarProps = {
  name?: string
  className?: string
  dropdown?: boolean
}

// NOTE: This simply shows a circle with the initials of the user. Later we may
//       update this component to show a real photo.
export const Avatar = ({ name, className, dropdown }: AvatarProps): JSX.Element => {
  const initials = getInitialsFromName(name)

  return (
    <Container className={className} data-testid='avatar'>
      {initials}
      {dropdown && (
        <DropdownContainer>
          <AvatarDropdownIcon />
        </DropdownContainer>
      )}
    </Container>
  )
}

const Container = tw.div`
  flex
  relative
  items-center
  justify-center
  font-bold
  w-full
  h-full
  rounded-full
  font-poppins
  uppercase
  text-lg
  text-white
  bg-gray-600
  hover:bg-gray-700
`

const DropdownContainer = tw.div`
  absolute
  -right-1
  bottom-0
  fill-brand-accent
  stroke-dark-blue
  hidden
  md:block
`
