import { broadcastLogin, useAuthBroadcast } from '@common'
import { LoaderLogo, RouteErrorBoundary } from '@components'
import { lazy, Suspense, useCallback, useEffect, useMemo, useState } from 'react'
import {
  Navigate,
  Outlet,
  Route,
  Routes,
  useLocation,
  useNavigate,
  useSearchParams,
} from 'react-router-dom'
import { QueryParamProvider } from 'use-query-params'
import { ReactRouter6Adapter } from 'use-query-params/adapters/react-router-6'

import { api } from '../../api/api'
import { useAppThunkDispatch } from '../../app/hooks'
import { persistor } from '../../app/store'
import { defaultPath } from '../../common/constants'
import { resetTracking, trackEvent } from '../../common/tracking'
import { LoginScreen } from '../../pages/LoginScreen'
import { NotFoundScreen } from '../../pages/NotFoundScreen'
import { getAllLoadboards, getHoldReasons } from '../../redux/loadsSlice'
import {
  checkIfUserIsAuthenticated,
  getAgentDetails,
  getPresets,
  logout,
} from '../../redux/userSlice'
import { Header } from '../Header'
import { Sidebar } from '../Sidebar'

// lazy load pages which are not used on initial load, and contain heavy components
const ContractLanesScreen = lazy(() => import('../../pages/ContractLanesScreen'))
const ContractLaneDetailsScreen = lazy(() => import('../../pages/ContractLaneDetailsScreen'))
const CustomerQuoteDetailsScreen = lazy(() => import('../../pages/CustomerQuoteDetailsScreen'))
const ApprovedPaymentsScreen = lazy(() => import('../../pages/ApprovedPaymentsScreen'))
const AutomatedBiddingQuotesScreen = lazy(() => import('../../pages/AutomatedBiddingQuotesScreen'))
const AutomatedBiddingScreen = lazy(() => import('../../pages/AutomatedBiddingScreen'))
const AutomatedBiddingSettingsScreen = lazy(
  () => import('../../pages/AutomatedBiddingSettingsScreen'),
)
const CarrierDetailsScreen = lazy(() => import('../../pages/CarrierDetailsScreen'))
const CarrierLoadOffersScreen = lazy(() => import('../../pages/CarrierLoadOffersScreen'))
const CarriersScreen = lazy(() => import('../../pages/CarriersScreen'))
const CollectionsScreen = lazy(() => import('../../pages/CollectionsScreen'))
const CSVImportScreen = lazy(() => import('../../pages/CSVImportScreen'))
const CustomerDetailsScreen = lazy(() => import('../../pages/CustomerDetailsScreen'))
const CustomerQuotesScreen = lazy(() => import('../../pages/CustomerQuotesScreen'))
const CustomersScreen = lazy(() => import('../../pages/CustomersScreen'))
const EDIScreen = lazy(() => import('../../pages/EDIScreen'))
const ExoCapitalScreen = lazy(() => import('../../pages/ExoCapitalScreen'))
const FactoringCompaniesScreen = lazy(() => import('../../pages/FactoringCompaniesScreen'))
const FactoringCompanyDetailsScreen = lazy(
  () => import('../../pages/FactoringCompanyDetailsScreen'),
)
const InvoicingScreen = lazy(() => import('../../pages/InvoicingScreen'))
const LoadDetailsScreen = lazy(() => import('../../pages/LoadDetailsScreen'))
const LoadPaymentsScreen = lazy(() => import('../../pages/LoadPaymentsScreen'))
const LoadsScreen = lazy(() => import('../../pages/LoadsScreen'))
const LocationDetailsScreen = lazy(() => import('../../pages/LocationDetailsScreen'))
const LocationScreen = lazy(() => import('../../pages/LocationsScreen'))
const QuotingScreen = lazy(() => import('../../pages/QuotingScreen'))
const ReceivablesScreen = lazy(() => import('../../pages/ReceivablesScreen'))
const ActiveCapacityScreen = lazy(() => import('../../pages/ActiveCapacityScreen'))
const CustomerFactoringDetailsScreen = lazy(
  () => import('../../pages/ExoCapitalScreen/details/CustomerFactoringDetailsScreen'),
)
const RecoveryDetailsScreen = lazy(
  () => import('../../pages/ExoCapitalScreen/details/RecoveryDetailsScreen'),
)
const FactoringRelationshipDetailsScreen = lazy(
  () => import('../../pages/ExoCapitalScreen/FactoringRelationshipDetailsScreen'),
)
const LoadFactoringScreen = lazy(() => import('../../pages/ExoCapitalScreen/LoadFactoringScreen'))
const ReservesRecoveriesScreen = lazy(
  () => import('../../pages/ExoCapitalScreen/ReservesRecoveriesScreen'),
)

const PrivateRoute = () => {
  // this component assumes that the user is authenticated
  const dispatch = useAppThunkDispatch()

  useEffect(() => {
    // gather app dependencies
    dispatch(getAgentDetails())
    dispatch(getHoldReasons())
    dispatch(getAllLoadboards())
    dispatch(getPresets())
  }, [])

  return (
    <>
      <Header />
      <Sidebar />
      <Suspense>
        <Outlet />
      </Suspense>
    </>
  )
}

const SwitchContainer = () => {
  const dispatch = useAppThunkDispatch()
  const { pathname, state, search } = useLocation()
  const navigate = useNavigate()
  const [searchParams, setSearchParams] = useSearchParams()
  const [isAuthenticated, setIsAuthenticated] = useState<boolean | null>(null)

  const performLogoutActions = () => {
    setIsAuthenticated(false)
    persistor.purge()
    setTimeout(() => {
      // There's an odd bug where the user is kept in the private route
      // after logout. Here, we wait for the next event loop
      // to ensure that React has finished propagating the isAuthenticated update
      const destination = pathname === '/echo-bravo-romeo' ? '/echo-bravo-romeo' : '/'
      navigate(destination, {
        state: { from: `${pathname}${search}` },
      })
    }, 0)
  }

  useAuthBroadcast(
    () => setIsAuthenticated(true),
    () => {
      resetTracking()
      dispatch(logout()).then(performLogoutActions)
    },
  )

  useEffect(() => {
    isAuthenticated && trackEvent(`Navigated to ${pathname}`)
  }, [pathname])

  const checkAuth = useCallback(() => {
    // make a request to determine if the user is authenticated
    // if the user is authenticated, and is on the login page, redirect to home
    dispatch(checkIfUserIsAuthenticated())
      .unwrap()
      .then((value: boolean) => {
        if (value) broadcastLogin()
        else return Promise.reject() // trigger the catch below
      })
      .catch(performLogoutActions)
  }, [])

  // on initial load, attempt to authenticate the user
  useEffect(() => {
    // if token is present, it means user is coming from an email verification link
    if (searchParams.has('token')) {
      const token = searchParams.get('token') as string

      api
        .post(`/tms/users/contact-verification/${token}/`)
        .then(() => broadcastLogin())
        .catch(() => checkAuth())
        .finally(() => {
          searchParams.delete('token')
          setSearchParams(searchParams, { replace: true })
        })
    } else checkAuth()
  }, [])

  const path = useMemo(() => state?.from || defaultPath, [state])

  return (
    <Routes>
      <Route errorElement={<RouteErrorBoundary />}>
        <Route
          path='echo-bravo-romeo'
          element={
            isAuthenticated === false ? (
              <LoginScreen otpLogin={false} />
            ) : isAuthenticated === true ? (
              <Navigate replace state={state} to='/' />
            ) : (
              <LoaderLogo />
            )
          }
        />
        <Route
          element={
            isAuthenticated === true ? (
              <PrivateRoute />
            ) : isAuthenticated === false ? (
              <LoginScreen />
            ) : (
              <LoaderLogo />
            )
          }
        >
          <Route element={<Navigate replace to={path} />} path='/' />
          <Route element={<QuotingScreen />} path='/quotes' />
          <Route element={<CustomersScreen />} path='/customers' />
          <Route element={<LocationScreen />} path='/locations' />
          <Route element={<CollectionsScreen />} path='/collections' />
          <Route element={<LocationDetailsScreen />} path='/locations/:id' />
          <Route element={<CarriersScreen />} path='/carriers' />
          <Route element={<ActiveCapacityScreen />} path='/active-capacity' />
          <Route path='/customers/:id' element={<CustomerDetailsScreen />} />
          <Route element={<CarrierDetailsScreen />} path='/carriers/:id' />
          <Route element={<AutomatedBiddingScreen />} path='/automated-bidding' />
          <Route
            element={<AutomatedBiddingSettingsScreen />}
            path='/automated-bidding/default-settings'
          />
          <Route
            element={<AutomatedBiddingSettingsScreen />}
            path='/automated-bidding/custom-settings/:id'
          />
          <Route element={<AutomatedBiddingQuotesScreen />} path='/automated-bidding/:id' />
          <Route element={<LoadsScreen />} path='/loads' />
          <Route element={<LoadDetailsScreen />} path='/loads/:id' />
          <Route element={<CSVImportScreen />} path='/csv-import' />
          <Route element={<ExoCapitalScreen />} path='/exo-factoring' />
          <Route element={<ReservesRecoveriesScreen />} path='/exo-factoring/carrier/:carrierId' />
          <Route
            element={<RecoveryDetailsScreen />}
            path='/exo-factoring/recovery-details/:recoveryId'
          />
          <Route
            element={<CustomerFactoringDetailsScreen />}
            path='/exo-factoring/customer/:customerId'
          />
          <Route
            element={<FactoringRelationshipDetailsScreen />}
            path='/exo-factoring/customer-carrier-relationship/:relationshipId'
          />
          <Route
            element={<LoadFactoringScreen />}
            path='/exo-factoring/load-factoring/:loadFactoringId'
          />
          <Route element={<ApprovedPaymentsScreen />} path='/approved-payments' />
          <Route element={<ReceivablesScreen />} path='/receivables' />
          <Route element={<CarrierLoadOffersScreen />} path='/carrier-load-offers' />
          <Route element={<InvoicingScreen />} path='/invoicing' />
          <Route element={<LoadPaymentsScreen />} path='/load-payments' />
          <Route element={<FactoringCompaniesScreen />} path='/factoring-companies' />
          <Route element={<FactoringCompanyDetailsScreen />} path='/factoring-companies/:id' />
          <Route element={<EDIScreen />} path='/edi' />
          <Route element={<CustomerQuotesScreen />} path='/customer-quotes' />
          <Route element={<CustomerQuoteDetailsScreen />} path='/customer-quotes/:id' />
          <Route element={<ContractLanesScreen />} path='/contract-lanes' />
          <Route element={<ContractLaneDetailsScreen />} path='/contract-lanes/:id' />

          {/* catch-all 404 */}
          <Route element={<NotFoundScreen />} path='*' />
        </Route>

        {/* Redirection routes from old code */}
        <Route element={<Navigate replace state={state} to='/' />} path='login' />
      </Route>
    </Routes>
  )
}

export const SwitchRouter = () => (
  <QueryParamProvider
    adapter={ReactRouter6Adapter}
    options={{
      removeDefaultsFromUrl: true,
      enableBatching: true,
    }}
  >
    <SwitchContainer />
  </QueryParamProvider>
)
