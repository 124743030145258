import { AlphaModalProps } from '@components'
import { Control, FieldErrorsImpl, FieldValues, UseFormSetValue } from 'react-hook-form'
import { z } from 'zod'

import { Load, QuoteDetails } from '../../../common/types'
import { LoadTags } from './CreateLoadModal'

export type CreateLoadModalProps = Pick<AlphaModalProps, 'isVisible' | 'setVisible'> & {
  quote?: QuoteDetails
  onLoadCreated?: (load: Load) => void
}

export type StepsProps = {
  currentStep: number
}

export type StepProps = {
  status: string
  title: string
  idx: number
}

export type StepIconProps = {
  status: string
  idx: number
}

export type SectionProps = {
  title: string
  tagTitle?: string
  tagClassName?: string
  children: React.ReactNode
  actionBtn?: React.ReactNode
  badgeCount?: number
  bodyClassName?: string
  className?: string
}

export type RouteFormProps = {
  control: Control<FieldValues, any>
  stopFields: Record<'id', string>[]
  addPickUp: () => void
  addDropOff: () => void
  getStopDeleter: (stop: StopItem) => () => void
  errors: FieldErrorsImpl<{ [x: string]: any }>
  touchedFields: { [x: string]: any }
  setRouteValue: UseFormSetValue<FieldValues>
}

export type ItemsFormProps = {
  control: Control<FieldValues>
  routeControl: Control<FieldValues, any>
  getItemDeleter: (item: Item, isDropOff?: boolean) => () => void
  getItemAdder: (stop: StopItem) => () => void
  setValue: UseFormSetValue<FieldValues>
  setRouteValue: UseFormSetValue<FieldValues>
}

export type ItemFormsProps = {
  control: Control<FieldValues>
  activeTab: number
  getItemDeleter: (item: Item, isDropOff?: boolean) => () => void
  setItemsValue: UseFormSetValue<FieldValues>
}

export type ItemFormProps = {
  control: Control<FieldValues, any>
  stopIdx: number
  item: Item
  itemTotal: number
  itemIdx: number
  getItemDeleter: (item: Item, isDropOff?: boolean) => () => void
}

export type ItemDropOffFormProps = {
  control: Control<FieldValues, any>
  stopIdx: number
  item: Item
  itemCount: number
  itemIdx: number
  getItemDeleter: (item: Item, isDropOff?: boolean) => () => void
  setItemsValue: UseFormSetValue<FieldValues>
}

export type ReviewViewProps = {
  routeControl: Control<FieldValues, any>
  itemsControl: Control<FieldValues, any>
  tags: LoadTags
  setTags: (tags: LoadTags) => void
}

export type StopProps = {
  control: Control<FieldValues, any>
  idx: number
  getStopDeleter: (stop: StopItem) => () => void
}

export const stopSchema = z.object({
  id: z.number(),
  location: z.object({
    id: z.string(),
    addressPreview: z.string(),
    name: z.string(),
  }),
  stopDate: z.date(),
  stopEarlyTime: z.string(),
  stopLateTime: z.string(),
  stopType: z.number(),
  items: z.array(z.any()),
  notes: z.string().optional(),
  shipmentNumber: z.string().optional(),
  poNumber: z.any().optional(),
})

export type StopItem = z.infer<typeof stopSchema>
export const routeSchema = z
  .object({
    customerCompany: z.number(),
    poNumber: z.string().optional(),
    customerReferenceId: z.string().optional(),
    mode: z.number(),
    orderType: z.number(),
    equipmentType: z.string().min(1),
    loadWeight: z.number(),
    temperature: z.string().optional().nullable(),
    loadLength: z.number(),
    loadWidth: z.number(),
    loadHeight: z.number(),
    stops: z.array(stopSchema).default([]),
    customerPrice: z.number(),
    carrierMin: z.number(),
    carrierMax: z.number(),
    isHazmat: z.boolean().default(false),
    hazmatUhn: z.string().optional().nullable(),
    isHighValue: z.boolean().default(false),
    highValueAmount: z.number().optional().nullable(),
    isTeamLoad: z.boolean().default(false),
    isOversize: z.boolean().default(false),
    isHotLoad: z.boolean().default(false),
  })
  .refine(({ isHazmat, hazmatUhn }) => (isHazmat ? isHazmat && hazmatUhn?.length : true))
  .refine(({ isHighValue, highValueAmount }) =>
    isHighValue ? isHighValue && highValueAmount : true,
  )

export type Route = z.infer<typeof routeSchema>

export const itemSchema = z.object({
  id: z.number(),
  manifest: z.string().min(1),
  weight: z.string().min(1),
  quantity: z.number().min(1),
  length: z.string(),
  width: z.string(),
  height: z.string(),
  notes: z.string().optional(),
  stopType: z.number(),
  itemMappingKey: z.string(),
})

export type Item = z.infer<typeof itemSchema>

export const itemsSchema = z
  .object({
    stops: z
      .array(
        z.object({
          items: z.array(itemSchema),
        }),
      )
      .default([]),
  })
  .required()

export type CustomerName = {
  id: number
  selectedText: string
  text: string
}
