import {
  DateInput,
  Form,
  LoaderSpinner,
  Select,
  TextArea,
  TextInput,
  Toggle,
  Tooltip,
} from '@components'
import { InformationCircleIcon } from '@heroicons/react/24/solid'
import dayjs from 'dayjs'
import { useMemo } from 'react'
import tw from 'tailwind-styled-components'

import { useAppSelector, useAppThunkDispatch } from '../../app/hooks'
import { ediEventTypeChoices, trackShipmentUpdateFrequencies } from '../../common/constants'
import { Load } from '../../common/types'
import { getReasonCode } from '../../common/utils'
import { setTrackingUpdate } from '../../redux/detailsLoadsSlice'
import { setTrackInfo } from '../../redux/loadsSlice'
import { IconButton } from '../Button'

const callOutcomeChoices = [
  { value: 'DID_NOT_ANSWER', label: 'Did Not Answer' },
  { value: 'ON_TRACK', label: 'On Track' },
  { value: 'OFF_TRACK', label: 'Off Track' },
]

export const TrackingUpdateForm = ({
  load,
  setFormValid,
}: {
  load: Load | null
  setFormValid: (value: boolean) => void
}) => {
  const dispatch = useAppThunkDispatch()

  const loadStatusHistory = useAppSelector(state => state.detailsLoads.loadStatusHistory)
  const trackingUpdate = useAppSelector(state => state.detailsLoads.trackingUpdate)
  const loading = useAppSelector(state => state.detailsLoads.loading)
  const loadStatusHistoryLoading = useAppSelector(
    state => state.detailsLoads.loading.getLoadStatusHistory,
  )
  const trackInfo = useAppSelector(state => state.loads.trackInfo)

  enum LoadStatus {
    BOOKED = 2,
    CONFIRM = 3,
    LOADING = 16,
    PICKED = 5,
    UNLOADING = 17,
    DELIVERED = 6,
  }

  enum EDIEventType {
    PICKUP_APP = 1,
    ARR_SHIPPER = 3,
    LOADED = 4,
    ARR_CONSIGNEE = 6,
    DELIVERED = 7,
  }

  const setTrackingUpdateData = (field: string, value: any) => {
    if (load?.orderType == 2 && field == 'loadStatus') {
      let ediEventType = 0
      switch (value) {
        case LoadStatus.BOOKED:
        case LoadStatus.CONFIRM:
          ediEventType = EDIEventType.PICKUP_APP
          break
        case LoadStatus.LOADING:
          ediEventType = EDIEventType.ARR_SHIPPER
          break
        case LoadStatus.PICKED:
          ediEventType = EDIEventType.LOADED
          break
        case LoadStatus.UNLOADING:
          ediEventType = EDIEventType.ARR_CONSIGNEE
          break
        case LoadStatus.DELIVERED:
          ediEventType = EDIEventType.DELIVERED
          break
      }
      dispatch(
        setTrackingUpdate({
          ...trackingUpdate,
          ['eventType']: ediEventType,
          [field]: value,
        }),
      )
    } else {
      dispatch(
        setTrackingUpdate({
          ...trackingUpdate,
          [field]: value,
        }),
      )
    }
  }

  const reasonCodeChoices = useMemo(() => {
    // These choices depend on EDI Event Type
    let choices: Array<{ name: string; value: number }> = []
    setTrackingUpdateData('reasonCode', 0)
    switch (trackingUpdate.eventType) {
      case 1:
        choices = [
          getReasonCode('SHIPPER_RELATED'),
          getReasonCode('OTHER_CARRIER_RELATED'),
          getReasonCode('WEATHER_OR_NATURAL_DISASTER'),
          getReasonCode('CONSIGNEE_RELATED'),
        ]
        setTrackingUpdateData('reasonCode', 7)
        break
      case 2:
        choices = [
          getReasonCode('CONSIGNEE_RELATED'),
          getReasonCode('OTHER_CARRIER_RELATED'),
          getReasonCode('SHIPPER_RELATED'),
          getReasonCode('WEATHER_OR_NATURAL_DISASTER'),
          getReasonCode('EXCEEDS_SERVICE_LIMITATIONS'),
        ]
        setTrackingUpdateData('reasonCode', 3)
        break
      case 3:
        choices = [
          getReasonCode('OTHER_CARRIER_RELATED'),
          getReasonCode('PREVIOUS_STOP'),
          getReasonCode('WEATHER_OR_NATURAL_DISASTER'),
          getReasonCode('EXCEEDS_SERVICE_LIMITATIONS'),
        ]
        setTrackingUpdateData('reasonCode', 5)
        break
      case 4:
        choices = [getReasonCode('SHIPPER_RELATED'), getReasonCode('WEATHER_OR_NATURAL_DISASTER')]
        setTrackingUpdateData('reasonCode', 7)
        break
      case 5:
        choices = [
          getReasonCode('OTHER_CARRIER_RELATED'),
          getReasonCode('WEATHER_OR_NATURAL_DISASTER'),
        ]
        setTrackingUpdateData('reasonCode', 5)
        break
      case 6:
        choices = [
          getReasonCode('CONSIGNEE_RELATED'),
          getReasonCode('OTHER_CARRIER_RELATED'),
          getReasonCode('SHIPPER_RELATED'),
          getReasonCode('WEATHER_OR_NATURAL_DISASTER'),
          getReasonCode('EXCEEDS_SERVICE_LIMITATIONS'),
        ]
        setTrackingUpdateData('reasonCode', 3)
        break
      case 7:
        choices = [getReasonCode('CONSIGNEE_RELATED'), getReasonCode('WEATHER_OR_NATURAL_DISASTER')]
        setTrackingUpdateData('reasonCode', 3)
        break
      case 8:
        choices = [
          getReasonCode('CONSIGNEE_RELATED'),
          getReasonCode('OTHER_CARRIER_RELATED'),
          getReasonCode('SHIPPER_RELATED'),
          getReasonCode('WEATHER_OR_NATURAL_DISASTER'),
          getReasonCode('EXCEEDS_SERVICE_LIMITATIONS'),
        ]
        setTrackingUpdateData('reasonCode', 3)
        break
      default:
        choices = []
    }

    return choices
  }, [trackingUpdate.eventType])

  const selectedEventType = ediEventTypeChoices.filter(
    choice => choice.value == trackingUpdate.eventType,
  )[0]

  const selectedReasonCode = reasonCodeChoices.filter(
    choice => choice?.value == trackingUpdate.reasonCode,
  )[0]

  const updateTrackInfo = (field: string, value: any) => {
    dispatch(
      setTrackInfo({
        ...trackInfo,
        [field]: value,
      }),
    )
  }

  const emailContent = load?.trackingRequest?.emails
    ? `to the following email(s)
                            ${load.trackingRequest?.emails} `
    : ''

  const tooltipContent = load?.trackingRequest?.code
    ? `Turn off tracking to change carrier or driver details. This action will also disable current tracking updates. Updates will be sent ${emailContent}every ${load.trackingRequest?.duration} minutes.`
    : `Your request is pending. To cancel your request, turn off tracking. Updates will be sent ${emailContent}every ${load?.trackingRequest?.duration} minutes.`

  if (loading.getLoadDetail || loading.getTrackingUpdate) return <LoaderSpinner className='mt-20' />

  return (
    <div className='overflow-auto lg:max-h-[70vh] max-md:pb-4'>
      <div className='bg-light-blue px-6 py-4'>
        <div className='flex flex-col lg:flex-row'>
          <div className='whitespace-nowrap w-full flex items-center'>
            {load?.orderType !== 4 && (
              <div className='flex items-center'>
                <div className='mb-0.5 text-end text-xs text-dark-gray pr-1 font-semibold'>
                  Tracking Updates
                </div>
                <div className='flex justify-end items-center'>
                  {load?.trackingRequest && (
                    <>
                      <div className='mr-1.5'>
                        {load.trackingRequest.code ? (
                          <a className='underline text-link' href={load.trackingRequest.url}>
                            Shipment Updates
                          </a>
                        ) : (
                          <span className='underline'>Request is pending</span>
                        )}
                      </div>
                      <Tooltip
                        content={<div className='break-words max-w-[300px]'>{tooltipContent}</div>}
                      >
                        <InformationCircleIcon className='w-5 h-5' />
                      </Tooltip>
                    </>
                  )}
                  <div className='ml-3'>
                    <Toggle
                      value={trackInfo.isTrack}
                      onChange={(isTrack: boolean) => updateTrackInfo('isTrack', isTrack)}
                    />
                  </div>
                </div>
              </div>
            )}
            <div className='w-1/2 flex justify-start'>
              {loadStatusHistoryLoading && <LoaderSpinner className='mt-4 ml-4' />}
              {loadStatusHistory && !loadStatusHistoryLoading && (
                <div className='w-1/2 mt-5 ml-4 font-semibold'>
                  <div className='text-xs text-dark-gray uppercase'>latest status update</div>
                  <div className='mt-0.5'>
                    {dayjs(loadStatusHistory).format('ddd MM/DD/YYYY hh:mm A')}
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
        {!load?.trackingRequest && trackInfo.isTrack && (
          <div className='flex mt-4'>
            <div className='w-1/2 mr-6'>
              {trackInfo.notifyEmails.map((notifyEmail, index) => (
                <Form key={index} className='flex items-center mb-3'>
                  <TextInput
                    sm
                    className='w-full'
                    label={index ? '' : 'Send Updates To'}
                    type='email'
                    value={notifyEmail}
                    onChange={(notifyEmail: string) =>
                      updateTrackInfo(
                        'notifyEmails',
                        trackInfo.notifyEmails.map((val, i) => (i === index ? notifyEmail : val)),
                      )
                    }
                  />
                  {!!index && (
                    <IconButton
                      className='ml-2'
                      icon='delete'
                      type='danger'
                      onClick={() =>
                        updateTrackInfo(
                          'notifyEmails',
                          trackInfo.notifyEmails.filter((_, i) => i !== index),
                        )
                      }
                    />
                  )}
                </Form>
              ))}
              <div className='flex justify-end'>
                <div
                  className='underline text-link cursor-pointer'
                  onClick={() => updateTrackInfo('notifyEmails', [...trackInfo.notifyEmails, ''])}
                >
                  Add another email address
                </div>
              </div>
            </div>
            <div className='w-1/2'>
              <Select
                sm
                choices={trackShipmentUpdateFrequencies}
                className='w-full'
                field='name'
                label='Expected Update Frequency'
                value={trackInfo.updateFrequency}
                onChange={({ value }: { value: number }) =>
                  updateTrackInfo('updateFrequency', value)
                }
              />
            </div>
          </div>
        )}
      </div>
      <Form setFormValid={setFormValid} className='px-6 pb-6'>
        <Subtitle>edi update</Subtitle>
        <div className='grid grid-cols-2 gap-4'>
          <Select
            extractValue
            sm
            choices={ediEventTypeChoices}
            className='w-full'
            field='name'
            label='EDI Event Type'
            value={selectedEventType}
            required={
              !!selectedReasonCode || !!trackingUpdate.stopDate || !!trackingUpdate.stopTime
            }
            onChange={(value: number) => setTrackingUpdateData('eventType', value || 0)}
          />
          <Select
            extractValue
            sm
            required={!!selectedEventType || !!trackingUpdate.stopDate || !!trackingUpdate.stopTime}
            choices={reasonCodeChoices as Array<object>}
            className='w-full'
            field='name'
            label='EDI Reason Code'
            value={selectedReasonCode || {}}
            onChange={(value: number) => setTrackingUpdateData('reasonCode', value || 0)}
          />
          <DateInput
            className='w-full'
            required={!!selectedEventType || !!trackingUpdate.stopTime || !!selectedReasonCode}
            label='Date'
            size='sm'
            value={
              trackingUpdate.stopDate && trackingUpdate.stopDate !== 'Invalid Date'
                ? trackingUpdate.stopDate
                : null
            }
            onChange={(stopDate: any) => setTrackingUpdateData('stopDate', stopDate)}
          />
          <TextInput
            sm
            className='w-full'
            label='Time'
            type='time'
            unmask={false}
            required={!!selectedEventType || !!trackingUpdate.stopDate || !!selectedReasonCode}
            value={trackingUpdate.stopTime}
            onChange={stopTime => setTrackingUpdateData('stopTime', stopTime)}
          />
        </div>
        <Subtitle>check call</Subtitle>
        <div className='flex mb-4 gap-4'>
          <TextInput
            sm
            label='City'
            className='w-full'
            value={trackingUpdate.city}
            onChange={(value: string) => setTrackingUpdateData('city', value)}
          />
          <TextInput
            sm
            label='State'
            mask='aa'
            className='w-full'
            value={trackingUpdate.stateProvinceRegion}
            onChange={(value: string) =>
              setTrackingUpdateData('stateProvinceRegion', value.toUpperCase())
            }
          />
          <TextInput
            sm
            label='Postal Code'
            className='w-full'
            value={trackingUpdate.postalCode}
            onChange={(value: string) => setTrackingUpdateData('postalCode', value)}
          />
        </div>
        <Select
          sm
          className='w-full'
          value={trackingUpdate.callOutcome}
          choices={callOutcomeChoices}
          label='Call Outcome'
          onChange={(choice?: { value: string }) =>
            setTrackingUpdateData('callOutcome', choice?.value)
          }
        />
        <TextArea
          sm
          className='w-full mt-4'
          value={trackingUpdate.notes}
          label='Call Notes'
          onChange={(value: string) => setTrackingUpdateData('notes', value)}
        />
      </Form>
    </div>
  )
}

const Subtitle = tw.div`
  pt-6
  pb-4
  text-xs
  font-semibold
  uppercase
`
