import { Button } from '@components'
import { useEffect } from 'react'
import tw from 'tailwind-styled-components'

import { trackEvent } from '../../common/tracking'

const hardResetState = () => {
  // Erase the bad data condition and force a rebuild of state
  localStorage.removeItem('persist:root')
  window.location.reload()
  trackEvent('Hard reset state')
}

export const FallBackScreen = () => {
  useEffect(() => {
    trackEvent('Triggered error fallback screen', { state: localStorage.getItem('persist:root') })
  }, [])

  return (
    <Container>
      <img alt='EXO Freight Logo' className='w-20 md:w-24 mb-8' src='/logo512.png' />
      <h1 className='mb-4 text-2xl font-bold'>Oops! That wasn&apos;t supposed to happen.</h1>
      <h2 className='mb-2'>
        Please click the reload button below to trigger a refresh and try again. If the problem
        persists, send a Slack message
      </h2>
      <Button className='mt-8' type='warn' onClick={hardResetState}>
        Reload
      </Button>
    </Container>
  )
}

const Container = tw.div`
  flex
  flex-col
  items-center
  justify-center
  h-full
  text-center
  mx-8
`
