import { CatchError, formatAxiosErrorToPayload, getErrorString } from '@common'
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { toast } from 'react-toastify'

import { api } from '../api/api'
import { initialFilters } from '../common/constants'
import { ReceivableCarrier, RootState, SearchFilters } from '../common/types'
import { keysToCamelCase, keysToSnakeCase } from '../common/utils'

type ReceivablesState = {
  receivables: Array<any>
  loading: {
    receivables: boolean
    receivePayment: boolean
    updateReceivable: boolean
  }
  count: number
  offset: number
  size: number
  filters: SearchFilters
  updatedCarrier: ReceivableCarrier
  currentPayment: { price: number; customer: string; id: number }
}

const initialState: ReceivablesState = {
  receivables: [],
  loading: {
    receivables: false,
    receivePayment: false,
    updateReceivable: false,
  },
  count: 0,
  offset: 0,
  size: 50,
  filters: initialFilters,
  updatedCarrier: {
    paymentType: 0,
    carrier: null,
    factoringCompany: null,
    remitEmail: '',
    payTerms: '',
    quickbooksId: '',
    carrierName: '',
    carrierAddress: null,
    carrierARName: '',
    id: null,
    phone: '',
    email: '',
    contactName: '',
  },
  currentPayment: { price: 0, customer: '', id: 0 },
}

export const getReceivables = createAsyncThunk(
  'receivables/getReceivables',
  async (_, { getState }) => {
    const { filters, size = 50, offset = 0 } = (getState() as RootState).receivables

    const response = await api.get('/billing/api/load-receivables/', {
      params: {
        limit: size,
        offset,
        ordering: 'delivery_date',
        id: filters.loadId || null,
        customer_reference_id__icontains: filters.refId || null,
        customer_company: filters.customerCompany || null,
      },
    })

    return response.data
  },
)

export const receivePayment = createAsyncThunk(
  'receivables/receivePayment',
  async (id: number, { dispatch, rejectWithValue }) => {
    try {
      const response = await api.put(`/billing/api/receive-load-payment/${id}/`, {
        payment_received: true,
      })

      dispatch(getReceivables())

      return response.data
    } catch (err: CatchError) {
      return rejectWithValue(formatAxiosErrorToPayload(err))
    }
  },
)

export const updateReceivable = createAsyncThunk(
  'receivables/updateReceivable',
  async (updatedCarrier: ReceivableCarrier, { dispatch }) => {
    const isFactoring = !updatedCarrier.paymentType

    const {
      factoringCompany,
      carrierAddress,
      carrier,
      phone,
      email,
      contactName,
      carrierName,
      remitEmail,
      payTerms,
      quickbooksId,
    } = updatedCarrier

    const payload = {
      arName: isFactoring ? factoringCompany.businessName : carrierName,
      arAddress: isFactoring
        ? factoringCompany.addressLine1
        : (carrierAddress?.house ? `${carrierAddress.house}, ` : '') + carrierAddress?.street,
      arCity: isFactoring ? factoringCompany.city : carrierAddress.city,
      arState: isFactoring ? factoringCompany.state : carrierAddress.stateCode,
      arZipcode: isFactoring ? factoringCompany.postalCode : carrierAddress.postalCode,
      arCountry: isFactoring ? factoringCompany.country : carrierAddress.countryCode,
      arPhone: isFactoring ? factoringCompany.phone : phone,
      arEmail: isFactoring ? factoringCompany.email : email,
      arContactName: isFactoring
        ? (factoringCompany.contactName ?? carrier?.arContactName ?? '')
        : contactName,
      factoringCompany: isFactoring ? factoringCompany.id : null,
      arRemitEmail: remitEmail,
      payTerms: payTerms,
      quickbooksId: quickbooksId,
    }

    const response = await api.put(
      `/accounts/api/carrier/detail/${updatedCarrier.id}/`,
      keysToSnakeCase(payload),
    )

    dispatch(getReceivables())

    return response.data
  },
)

const receivablesSlice = createSlice({
  name: 'receivables',
  initialState,
  reducers: {
    setSize(state, { payload }) {
      state.size = payload
    },
    setOffset(state, { payload }) {
      state.offset = payload
    },
    setFilters(state, { payload }) {
      state.filters = payload
    },
    setUpdatedCarrier(state, { payload }) {
      state.updatedCarrier = payload
    },
    setCurrentPayment(state, { payload }) {
      state.currentPayment = payload
    },
  },
  extraReducers(builder) {
    builder
      .addCase(getReceivables.pending, state => {
        state.loading.receivables = true
      })
      .addCase(getReceivables.fulfilled, (state, action) => {
        const { count, results } = action.payload
        state.receivables = keysToCamelCase(results)
        state.count = count
        state.loading.receivables = false
      })
      .addCase(getReceivables.rejected, state => {
        toast.error('Failed to get receivables')
        state.loading.receivables = false
      })
      .addCase(receivePayment.pending, state => {
        state.loading.receivePayment = true
      })
      .addCase(receivePayment.fulfilled, state => {
        state.loading.receivePayment = false
        toast.success('Successfully received payment')
      })
      .addCase(receivePayment.rejected, state => {
        toast.error('Failed to get receivables')
        state.loading.receivePayment = false
      })
      .addCase(updateReceivable.pending, state => {
        state.loading.updateReceivable = true
      })
      .addCase(updateReceivable.fulfilled, state => {
        state.loading.updateReceivable = false
        toast.success('Successfully updated carrier')
      })
      .addCase(updateReceivable.rejected, (state, action) => {
        toast.error(getErrorString(action.payload, 'Failed to update carrier'))
        state.loading.updateReceivable = false
      })
  },
})

export const { setSize, setOffset, setFilters, setUpdatedCarrier, setCurrentPayment } =
  receivablesSlice.actions

export default receivablesSlice.reducer
