import { Select } from '@components'

import { useAppSelector, useAppThunkDispatch } from '../../app/hooks'
import { autoCompleteManagerSearch } from '../../redux/autocompleteSlice'

export const AccountManagerSelect = ({
  value = null,
  onChange,
  withLabel,
  withPlaceholder,
  className = '',
  required,
  ...props
}: {
  value?: { id?: string | number; text?: string } | null
  onChange: (value: { id: string | number; text: string }) => void
  withLabel?: boolean
  withPlaceholder?: boolean
  className?: string
  required?: boolean
}) => {
  const dispatch = useAppThunkDispatch()

  const managerSearchOptions = useAppSelector(state => state.autocomplete.managerSearchOptions)
  const loading = useAppSelector(state => state.autocomplete.loading.managerSearchOptions)

  const text = 'Acquisition Rep'

  const onSearch = (query: string) => {
    query && dispatch(autoCompleteManagerSearch(query))
  }

  return (
    <Select
      hideDropdownIndicator
      sm
      choices={managerSearchOptions}
      className={className}
      field='text'
      loading={loading}
      openMenuOnClick={!!managerSearchOptions.length}
      required={required}
      value={value || {}}
      onChange={onChange}
      onInputChange={onSearch}
      {...(withLabel && { label: text })}
      {...(withPlaceholder && { placeholder: text })}
      {...props}
    />
  )
}
