import { Button, ConfirmationDialog } from '@components'
import { isEqual } from 'lodash-es'
import { useMemo, useState } from 'react'
import tw from 'tailwind-styled-components'

import { useAppSelector, useAppThunkDispatch } from '../../../app/hooks'
import { CustomerNote } from '../../../common/types'
import { displayDateTime, useCustomerType } from '../../../common/utils'
import {
  deleteCustomerNote,
  setCustomerInfo,
  updateCustomerNote,
} from '../../../redux/customersSlice'
import { MoreDropdown } from '../../MoreDropdown'
import { DeleteButton } from './DeleteButton'
import { EditNote } from './EditNote'
import { SectionHeader } from './SectionHeader'

export const Note = ({ note, primary }: { note: CustomerNote; primary?: boolean }) => {
  const [isModalVisible, setModalVisible] = useState(false)
  const [isEditing, setEditing] = useState(false)
  const [changedNote, setChangedNote] = useState(note)

  const type = useCustomerType()

  const dispatch = useAppThunkDispatch()

  const notes = useAppSelector(state => state.customers[type].notes)
  const deleteLoading = useAppSelector(state => state.customers.loading.deleteNote)
  const updateLoading = useAppSelector(state => state.customers.loading.updateNote)

  const deleteNoteLocal = () =>
    dispatch(
      setCustomerInfo({
        notes: notes.filter(nt => note.id !== nt.id),
      }),
    )

  const deleteNoteDB = () => dispatch(deleteCustomerNote(note.id))

  const updateNote = () =>
    dispatch(updateCustomerNote(changedNote))
      .unwrap()
      .then(() => setEditing(false))
      .catch(() => {})

  const handleCancel = () => {
    setChangedNote(note)
    setEditing(false)
  }

  const isOnboarding = type === 'onboardingCustomer'

  const changesMade = useMemo(() => !isEqual(note, changedNote), [note, changedNote])

  return isEditing ? (
    <>
      <EditNote note={changedNote} setNote={setChangedNote} className='mt-4' />
      <div className='flex justify-end gap-x-4 mt-4'>
        <Button type='secondary' onClick={handleCancel}>
          Cancel
        </Button>
        <Button
          type='success'
          loading={updateLoading}
          disabled={!changesMade || !changedNote.note}
          onClick={updateNote}
        >
          Save
        </Button>
      </div>
    </>
  ) : (
    <>
      <OuterContainer>
        <InnerContainer $primary={primary}>
          <div className='flex justify-between'>
            <SectionHeader className='mb-2'>{note.subject || 'no subject'}</SectionHeader>
            {!isOnboarding && !primary && (
              <MoreDropdown
                dropdownClassName='right-5'
                items={[
                  { label: 'Edit', onClick: () => setEditing(true) },
                  { label: 'Delete', onClick: () => setModalVisible(true) },
                ]}
              />
            )}
          </div>
          {!isOnboarding && !primary && (
            <div className='mb-2 text-dark-gray text-[10px]'>
              {displayDateTime(note.createdAt)} {note.createdByDisplay}
            </div>
          )}
          <div>{note.note}</div>
        </InnerContainer>
      </OuterContainer>
      {isOnboarding && <DeleteButton onClick={deleteNoteLocal} />}
      <ConfirmationDialog
        isVisible={isModalVisible}
        setVisible={setModalVisible}
        title='Are you sure you want to delete this note?'
        confirmButtonLoading={deleteLoading}
        confirmButtonType='danger'
        confirmButtonText='Delete'
        onConfirm={deleteNoteDB}
      />
    </>
  )
}

const OuterContainer = tw.div`
  rounded-lg
  mt-4
  border
  border-light-blue
`

const InnerContainer = tw.div<{ $primary?: boolean }>`
  border-l-4
  rounded-lg
  p-3
  border-link
  ${({ $primary }) => $primary && 'border-dark-blue'}
`
