/**
 * Masks in this file are to be used to format a string to conform into a specified pattern.
 * The way to use is by calling the resolve function, or pass directly to the mask prop in the TextInput component.
 * See https://imask.js.org/guide.html for documentation
 *
 * Example: phoneMask.resolve('8888888888') -> +1 (888) 888-8888
 */

import { merge } from 'lodash-es'
import { IMask } from 'react-imask'

// Turns a string '8888888888' into '+1 (888) 888-8888'
export const getPhoneMaskOptions = (countryCode = '1') => ({
  mask: `+${countryCode} (000) 000-0000`,
  lazy: true,
})

export const getTimeMaskOptions = () => ({
  overwrite: true,
  autofix: true,
  mask: 'HH:MM',
  blocks: {
    HH: {
      mask: IMask.MaskedRange,
      from: 0,
      to: 23,
      maxLength: 2,
    },
    MM: {
      mask: IMask.MaskedRange,
      from: 0,
      to: 59,
      maxLength: 2,
    },
  },
})

export const getSimpleNumberMaskOptions = () => ({
  mask: 'num',
  lazy: false,
  blocks: {
    num: {
      mask: Number,
      radix: '.',
      min: -10000,
    },
  },
})

export const getNumberMaskOptions = (maxLength = 4, decimalLimit = 2) => ({
  mask: 'num',
  lazy: false,
  blocks: {
    num: {
      mask: Number,
      thousandsSeparator: ',',
      radix: '.',
      normalizeZeros: false,
      scale: decimalLimit,
      min: parseFloat(`-${'9'.repeat(maxLength)}.${'9'.repeat(decimalLimit)}`),
      max: parseFloat(`${'9'.repeat(maxLength)}.${'9'.repeat(decimalLimit)}`),
    },
  },
})

export const getCurrencyMaskOptions = (maxLength = 8, decimalLimit = 2) => ({
  mask: '$num',
  lazy: false,
  blocks: {
    num: {
      mask: Number,
      thousandsSeparator: ',',
      radix: '.',
      normalizeZeros: false,
      scale: decimalLimit,
      max: parseFloat(`${'9'.repeat(maxLength)}.${'9'.repeat(decimalLimit)}`),
      signed: true,
    },
  },
})

export const getMarginMaskOptions = (maxLength = 8, decimalLimit = 2) => ({
  mask: 'num%',
  lazy: false,
  blocks: {
    num: {
      mask: Number,
      thousandsSeparator: ',',
      radix: '.',
      scale: decimalLimit,
      max: parseFloat(`${'9'.repeat(maxLength)}.${'9'.repeat(decimalLimit)}`),
      signed: true,
    },
  },
})

export const getEmailMaskOptions = () => ({ mask: /^\S*@?\S*$/, autofix: true })

export const formatPhone = (value?: string): string =>
  !value ? '—' : IMask.createMask(getPhoneMaskOptions()).resolve(String(value))

/**
 * Extended mask for dollar currency which pads 0s at the end for display purposes only
 * Turns a string '100000' into '$ 100,000.00'
 */
export const formatCurrency = (value?: string | number): string =>
  typeof value === 'undefined' || value === null
    ? ''
    : IMask.createMask(
        merge(getCurrencyMaskOptions(), {
          blocks: {
            num: {
              padFractionalZeros: true,
            },
          },
        } as Partial<IMask.AnyMaskedOptions>),
      ).resolve(String(value))

export const formatPercentage = (value?: string | number): string =>
  typeof value === 'undefined' || value === null
    ? ''
    : IMask.createMask(
        merge(getMarginMaskOptions(), {
          blocks: {
            num: {
              padFractionalZeros: true,
            },
          },
        } as Partial<IMask.AnyMaskedOptions>),
      ).resolve(String(value))
