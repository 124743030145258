import { NavLink } from 'react-router-dom'
import tw from 'tailwind-styled-components'

import { PageContainer } from '../../components/PageContainer'

export const NotFoundScreen = () => (
  <Container>
    <div>
      <div className='font-semibold mb-2 text-base'>Page not found</div>
      <div>
        The page you are looking for doesn&apos;t exist. Go to{' '}
        <NavLink className='hover:text-link transition-all underline' to='/customers'>
          Customers
        </NavLink>
      </div>
    </div>
  </Container>
)

const Container = tw(PageContainer)`
  h-full
  flex
  items-center
  justify-center
  text-center
`
