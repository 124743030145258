import {
  AtSymbolIcon,
  BanknotesIcon as BanknotesIconOutline,
  ClockIcon as ClockIconOutline,
  TrashIcon,
} from '@heroicons/react/24/outline'
import {
  ArchiveBoxIcon,
  ArrowDownTrayIcon,
  ArrowUpTrayIcon,
  BanknotesIcon,
  BellIcon,
  ChartBarIcon,
  CheckCircleIcon,
  ClockIcon,
  Cog6ToothIcon,
  CubeIcon,
  CurrencyDollarIcon,
  DocumentDuplicateIcon,
  DocumentIcon,
  EnvelopeIcon,
  ExclamationTriangleIcon,
  InformationCircleIcon,
  LockClosedIcon,
  MapPinIcon,
  PhoneIcon,
  ScaleIcon,
  Squares2X2Icon,
  TruckIcon,
  UserIcon,
  UsersIcon,
} from '@heroicons/react/24/solid'
import React from 'react'

export const getIcon = (icon: string, className: string) => {
  const iconProps = { className, 'data-testid': 'icon' }

  switch (icon) {
    case 'time':
      return <ClockIcon {...iconProps} />
    case 'time-outline':
      return <ClockIconOutline {...iconProps} />
    case 'phone':
      return <PhoneIcon {...iconProps} />
    case 'email':
      return <AtSymbolIcon {...iconProps} />
    case 'location':
      return <MapPinIcon {...iconProps} />
    case 'info':
      return <InformationCircleIcon {...iconProps} />
    case 'contact':
      return <UserIcon {...iconProps} />
    case 'scale':
      return <ScaleIcon {...iconProps} />
    case 'cube':
      return <CubeIcon {...iconProps} />
    case 'squares':
      return <Squares2X2Icon {...iconProps} />
    case 'settings':
      return <Cog6ToothIcon {...iconProps} />
    case 'dollar':
      return <CurrencyDollarIcon {...iconProps} />
    case 'chart':
      return <ChartBarIcon {...iconProps} />
    case 'money':
      return <BanknotesIcon {...iconProps} />
    case 'money-outline':
      return <BanknotesIconOutline {...iconProps} />
    case 'document':
      return <DocumentIcon {...iconProps} />
    case 'download':
      return <ArrowDownTrayIcon {...iconProps} />
    case 'clock':
      return <ClockIcon {...iconProps} />
    case 'bell':
      return <BellIcon {...iconProps} />
    case 'truck':
      return <TruckIcon {...iconProps} />
    case 'upload':
      return <ArrowUpTrayIcon {...iconProps} />
    case 'users':
      return <UsersIcon {...iconProps} />
    case 'check':
      return <CheckCircleIcon {...iconProps} />
    case 'envelope':
      return <EnvelopeIcon {...iconProps} />
    case 'delete':
      return <TrashIcon {...iconProps} />
    case 'warn':
      return <ExclamationTriangleIcon {...iconProps} />
    case 'lock-closed':
      return <LockClosedIcon {...iconProps} />
    case 'archive':
      return <ArchiveBoxIcon {...iconProps} />
    case 'document-duplicate':
      return <DocumentDuplicateIcon {...iconProps} />
    default:
      return <div {...iconProps} />
  }
}
