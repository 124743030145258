import { OnboardingCustomerContact } from '../../common/types'

export const getCheckboxes = (contact: OnboardingCustomerContact) => [
  {
    label: 'Accounting',
    key: 'accounting',
    value: contact.accounting,
  },
  {
    label: 'Invoice',
    key: 'invoice',
    value: contact.invoice,
    isAccounting: true,
  },
  {
    label: 'Pickup Notifications',
    key: 'pickupNotifications',
    value: contact.pickupNotifications,
  },
  {
    label: 'Delivery Notifications',
    key: 'deliveryNotifications',
    value: contact.deliveryNotifications,
  },
  {
    label: 'Driver Info Notifications',
    key: 'driverInfoNotifications',
    value: contact.driverInfoNotifications,
  },
  {
    label: 'Receive NOA',
    key: 'receiveNOA',
    value: contact.receiveNOA,
  },
  {
    label: 'Marketing Contact',
    key: 'marketingContact',
    value: contact.marketingContact,
  },
  {
    label: 'Receive Marketing Emails',
    key: 'receiveMarketingEmails',
    value: contact.receiveMarketingEmails,
  },
]
