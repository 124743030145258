import { TextArea, TextInput } from '@components'
import tw from 'tailwind-styled-components'

import { CustomerNote } from '../../../common/types'

export const EditNote = ({
  note,
  setNote,
  className,
}: {
  note: CustomerNote
  setNote: (value: CustomerNote) => void
  className?: string
}) => (
  <Container className={className}>
    <TextInput
      sm
      noStyles
      valueClassName='font-semibold'
      value={note.subject}
      placeholder='Subject'
      className='border-b border-border-gray'
      onChange={subject => setNote({ ...note, subject })}
    />
    <TextArea
      sm
      noStyles
      value={note.note}
      placeholder='Note'
      onChange={text => setNote({ ...note, note: text })}
    />
  </Container>
)

const Container = tw.div`
  border
  border-border-gray
  rounded-lg
  bg-white
`
