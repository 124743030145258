import { Form, Select, TextInput } from '@components'
import { useEffect } from 'react'

import { useAppSelector, useAppThunkDispatch } from '../../app/hooks'
import { chargeTypes } from '../../common/constants'
import { AccessorialType, NewAccessorial } from '../../common/types'
import { formatBoolean, getCurrencyMaskOptions } from '../../common/utils'
import { getAccessorialTypes } from '../../redux/autocompleteSlice'
import { CheckboxInput } from '../CheckboxInput'

export const EditAccessorialForm = ({
  accessorial,
  setAccessorial,
  setFormValid,
}: {
  accessorial: NewAccessorial
  setAccessorial: (field: string, value: any) => void
  setFormValid: (value: boolean) => void
}) => {
  const dispatch = useAppThunkDispatch()

  const accessorialTypes = useAppSelector(state => state.autocomplete.accessorialTypes)
  const isCarrierAccessorial = accessorial.chargeTo?.id === 2

  useEffect(() => {
    dispatch(getAccessorialTypes())
  }, [])

  return (
    <Form setFormValid={setFormValid}>
      <Select
        required
        sm
        choices={accessorialTypes}
        className='w-full mb-2'
        field='name'
        label='Accessorial Type'
        value={accessorial.accessorialType?.name ? accessorial.accessorialType : {}}
        onChange={(accessorialType: AccessorialType) =>
          setAccessorial('accessorialType', accessorialType)
        }
      />
      {accessorial.accessorialType && (
        <div className='mt-2'>
          <span className='text-dark-gray'>Reimbursement:</span>
          <span className='font-semibold capitalize ml-2'>
            {formatBoolean(accessorial.accessorialType?.reimbursement)}
          </span>
        </div>
      )}
      <div className='mt-4 grid grid-cols-2 gap-x-4 gap-y-3'>
        <Select
          required
          sm
          choices={chargeTypes}
          className='w-full'
          field='name'
          label='Charge Type'
          value={accessorial?.chargeType}
          onChange={(chargeType: { id: number; name: string }) =>
            setAccessorial('chargeType', chargeType)
          }
        />
        <TextInput
          required
          sm
          className='w-full'
          label='Quantity'
          min={1}
          type='number'
          value={accessorial.quantity}
          onChange={(quantity: string) => setAccessorial('quantity', quantity)}
        />
        <TextInput
          required
          sm
          className='w-full'
          label='Amount'
          type='number'
          maskOptions={getCurrencyMaskOptions(4, 3)}
          value={accessorial.amount}
          onChange={(amount: string) => setAccessorial('amount', amount)}
        />
        <TextInput
          sm
          className='w-full'
          label='Reason'
          value={accessorial.reason}
          onChange={(reason: string) => setAccessorial('reason', reason)}
        />
        {isCarrierAccessorial && (
          <CheckboxInput
            className='mt-1'
            isChecked={accessorial.prepaid || false}
            text='Prepaid'
            onClick={() => setAccessorial('prepaid', !accessorial.prepaid)}
          />
        )}
        {isCarrierAccessorial && (
          <CheckboxInput
            className='mt-1'
            isChecked={accessorial.carrierDeduction || false}
            text='Carrier Deduction'
            onClick={() => setAccessorial('carrierDeduction', !accessorial.carrierDeduction)}
          />
        )}
      </div>
    </Form>
  )
}
