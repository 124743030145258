import { ReactNode } from 'react'
import tw from 'tailwind-styled-components'

export const PageContainer = ({
  children,
  className = '',
}: {
  children: ReactNode
  className?: string
}) => (
  <Container className={className} data-testid='page-container'>
    {children}
  </Container>
)

const Container = tw.div`
  lg:pl-[76px]
  pt-[72px]
  lg:pt-0
  lg:h-[calc(100vh-72px)]
  flex
  flex-col
`
