import { Button, FileInput } from '@components'
import tw from 'tailwind-styled-components'

import { useAppSelector, useAppThunkDispatch } from '../../../app/hooks'
import { CustomerDocument } from '../../../common/types'
import { randomString, useCustomerType } from '../../../common/utils'
import { setCustomerInfo } from '../../../redux/customersSlice'
import { Document, SectionHeader } from '../components'

export const Documents = () => {
  const type = useCustomerType()

  const dispatch = useAppThunkDispatch()

  const documents = useAppSelector(state => state.customers[type].documents)

  const addNewDocument = (file: CustomerDocument) => {
    const match = file.name.match(/(\.[^.]+)$/)
    const name = match ? file.name.replace(match[1], '') : file.name
    const extension = match?.[1] || ''
    dispatch(
      setCustomerInfo({
        documents: [{ file: file.file, name, id: randomString(), extension }, ...documents],
      }),
    )
  }

  return (
    <div className='w-full'>
      <SectionHeader>Documents</SectionHeader>
      <FileOuterContainer>
        <FileInnerContainer>
          <div className='flex justify-between items-center'>
            <span className='font-semibold'>Upload document</span>
            <FileInput
              base64
              mimeType='application/pdf,image/png,image/jpg,image/jpeg'
              onChange={addNewDocument}
            >
              <Button>upload file</Button>
            </FileInput>
          </div>
        </FileInnerContainer>
      </FileOuterContainer>
      {documents.map(document => (
        <Document key={document.id} document={document} />
      ))}
    </div>
  )
}

const FileOuterContainer = tw.div`
  border
  border-lighter-blue
  rounded-lg
  mt-4
`

const FileInnerContainer = tw.div`
  border-l-4
  border-link
  p-3
  rounded-lg
  bg-lighter-blue
`
