import 'react-loading-skeleton/dist/skeleton.css'

import { TopLevelConfirmDialog } from '@components'
import * as Sentry from '@sentry/react'
import { ReactNode } from 'react'

import { FallBackScreen } from './components/FallbackScreen'
import { SwitchRouter } from './components/SwitchRouter'

export const App = () => (
  <CustomErrorBoundary fallback={<FallBackScreen />}>
    <div className='app-container'>
      <SwitchRouter />
      <TopLevelConfirmDialog />
    </div>
  </CustomErrorBoundary>
)

const CustomErrorBoundary = ({
  children,
  fallback,
}: {
  children: ReactNode
  fallback: JSX.Element
}) => {
  const beforeCapture = (scope: Sentry.Scope, error: Error | null) => {
    if (error && error.message.includes('Failed to fetch dynamically imported module'))
      location.reload()
  }

  return (
    <Sentry.ErrorBoundary beforeCapture={beforeCapture} fallback={fallback}>
      {children}
    </Sentry.ErrorBoundary>
  )
}

export default App
